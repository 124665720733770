// GraphQl
import { gql } from 'apollo-boost';

export const LIST_SAFI_RELATIONS = gql`
  {
    listSafiRelations {
      edges {
        node {
          id
          name
          typeRelation
        }
      }
    }
  }
`;

export const GET_SAFI_RELATION = gql`
  query getSafiRelation($id: ID!) {
    safiRelation(id: $id) {
      id
      name
      typeRelation
    }
  }
`;

export const CREATE_SAFI_RELATION = gql`
  mutation createSafiRelation($input: SafiRelationInput!) {
    createSafiRelation(input: $input) {
      safiRelation {
        id
        name
        typeRelation
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_SAFI_RELATION = gql`
  mutation updateSafiRelation($id: ID!, $input: SafiRelationInput!) {
    updateSafiRelation(id: $id, input: $input) {
      safiRelation {
        id
        name
        typeRelation
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_SAFI_RELATION = gql`
  mutation deleteSafiRelation($id: ID!) {
    deleteSafiRelation(id: $id) {
      safiRelation {
        id
        name
        typeRelation
      }
      errors {
        field
        message
      }
    }
  }
`;
