import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
    },
  },
  root: {
    flexGrow: 1,
  },
  appToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarItem: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 55,
    position: 'relative',
  },
  tableTitle: {
    fontWeight: 500,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: theme.spacing(0.5),
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
  modalIframe: {
    width: '100%',
    height: 800,
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  changePasswordPage: {
    display: 'flex',
    height: '100vh',
    padding: '5%',
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.main} 90%)`,

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.primary.dark,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.dark,
    },
  },

  card: {
    border: '8px solid',
    borderRadius: 0,
    borderColor: theme.palette.secondary.main,
    background: theme.palette.primary,
    textAlign: 'center',
  },
  errorMessage: {
    color: theme.palette.colors.error,
    fontWeight: 600,
  },
  successMessage: {
    fontWeight: 600,
  },
  modalIframe: {
    width: '100%',
    height: 800,
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(2),
    },
  },
  buttonSuccess: {
    backgroundColor: theme.palette.colors.green,
    display: 'block',
    margin: theme.spacing(1),
  },
  check: {
    color: theme.palette.colors.white,
  },
  buttonResend: {
    margin: '0 1rem 0 auto',
  },
  modalContent: {
    padding: '2rem',
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
