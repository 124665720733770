import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  simpleFormContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  labelContainer: {
    alignItems: 'center',
  },
  errorTypo: {
    color: theme.palette.common.red,
    marginTop: '5px',
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
}));
