import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../../LoadingIndicator';
import AdministratorForm from '../AdministratorForm';
import { GET_USER } from '../../queries';

function AdministratorEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get administrators', error);
    history.goBack();
  }

  const formatValue = { ...data.user };
  if (!data.user) {
    history.goBack();
  }

  return <AdministratorForm administrator={formatValue} id={id} isEdit />;
}

AdministratorEdit.propTypes = {};

export default AdministratorEdit;
