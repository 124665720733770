import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useSnackbar } from 'notistack';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import LoadingIndicator from '../../../LoadingIndicator';
import {
  generateColumn,
  edgeToList,
  parseSignatureState,
} from '../../../../utils/commonFunctions';
import { IconButton, Tooltip, TableCell, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { LIST_SIGNATURES } from '../queries';
import { REACT_APP_SERVER_LINK } from '../../../../utils/constants';
import PDFViewerModal from '../../../PDFViewerModal';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';

// styles and icons
import useStyles from './styles';

function ProcessList() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [processList, setProcessList] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [url, setUrl] = useState('');

  const { loading: loadindList, error, data: list, refetch } = useQuery(
    LIST_SIGNATURES,
  );

  useEffect(() => {
    if (!!list) {
      let signatures_process = edgeToList(list, 'listSigningDocuments');
      signatures_process = signatures_process.sort(
        (a, b) => a.createdAt < b.createdAt,
      );
      const resultProcess = signatures_process.filter(
        process =>
          process.signedPdfStatus === 'BEFORE_SIGNATURE' ||
          process.signedPdfStatus === 'IN_PROCESS',
      );
      setProcessList(resultProcess);
    }
    refetch();
  }, [list]);

  const handleRowClick = (rowData, rowMeta) => {
    const foundId = processList.find(
      (element, index) => index === rowMeta.dataIndex,
    );
    history.push(`${history.location.pathname}/${foundId.id}`);
  };

  let columns = [];
  if (processList && processList.length > 0) {
    columns = [
      generateColumn('Fecha', 'createdAt', {
        customBodyRender: value => moment(value).format('DD-MM-YYYY'),
        display: true,
      }),
      generateColumn('Documento', 'pdfSheet', {
        customBodyRender: value => {
          let last_position = parseInt(value.lastIndexOf('/')) + 1;
          return value.slice(last_position);
        },
      }),
      generateColumn('Clientes', 'id', {
        customBodyRender: value => {
          let result = '';
          processList
            .filter(process => process.id == value)
            .forEach(sign => {
              if (sign.signingProcess.clients.edges.length > 0) {
                sign.signingProcess.clients.edges.forEach(client => {
                  result +=
                    client.node.user.firstName +
                    ' ' +
                    client.node.user.lastName +
                    ', ';
                });
              }
              if (sign.signingProcess.externalSigners.edges.length > 0) {
                sign.signingProcess.externalSigners.edges.forEach(external => {
                  result +=
                    external.node.firstName +
                    ' ' +
                    external.node.lastName +
                    ', ';
                });
              }
            });
          return result;
        },
      }),
      generateColumn('Estado', 'signedPdfStatus', {
        customBodyRender: value => {
          return <p>{parseSignatureState(value)}</p>;
        },
      }),
      {
        name: 'id',
        label: 'Opciones',
        options: {
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const id = value;
            return (
              <>
                <Tooltip title="Visualizar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setShowModal(true);
                      setUrl(
                        `${REACT_APP_SERVER_LINK}/media/${tableMeta.rowData[1]}`,
                      );
                    }}
                    className={classes.optionButtons}
                  >
                    <PageviewOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar" placement="top" arrow>
                  <Link
                    href={`${REACT_APP_SERVER_LINK}/media/${tableMeta.rowData[1]}`}
                    target="_blank"
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={e => e.stopPropagation()}
                      className={classes.optionButtons}
                    >
                      <CloudDownloadIcon className={classes.optionIcon} />
                    </IconButton>
                  </Link>
                </Tooltip>
              </>
            );
          },
          customHeadRender: columnMeta => {
            return (
              <TableCell key={7} style={{ borderBottom: 'none' }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.tableTitle}
                >
                  {columnMeta.label}
                </Typography>
              </TableCell>
            );
          },
        },
      },
    ];
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    download: true,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Nuevo Proceso de Firma">
        <IconButton
          color="secondary"
          onClick={() => history.push(`${history.location.pathname}/crear`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    // onRowClick: (rowData, rowMeta) => handleRowClick(rowData, rowMeta),
  };

  return (
    <>
      <MUIDataTable
        title="Estados de Firmas"
        data={processList}
        columns={columns}
        options={options}
      />
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={false}
      />
    </>
  );
}

export default ProcessList;
