import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import AccountTypeForm from '../AccountTypeForm';
import { GET_ACCOUNT_TYPE } from '../queries';

function AccountTypeEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_ACCOUNT_TYPE, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get account', error);
    history.goBack();
  }

  const formatValue = { ...data.accountType };
  if (!data.accountType) {
    history.goBack();
  }

  return <AccountTypeForm accountType={formatValue} id={id} isEdit />;
}

AccountTypeEdit.propTypes = {};

export default AccountTypeEdit;
