import { gql } from 'apollo-boost';

// eslint-disable-next-line import/prefer-default-export
export const GET_RAISING_AND_FUND_INFORMATION = gql`
  query getRaisingAndFundInformation($raisingId: ID) {
    raising(id: $raisingId) {
      id
      classes {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      valueFee
      valueFeeNominal
      minimumInvestment
      targetCapitalIncrease
      initialParticipationOrderReceptionDate
      lastParticipationOrderReceptionDate
      initialFeeAdjudicationDate
      finalFeeAdjudicationDate
      initialFeePaymentDate
      lastFeePaymentDate
      dateInitialContract
      dateEndContract
      dateEmissionFee
      dateInitialCertificateSent
      dateEndCertificateSent
      wheelSet {
        edges {
          node {
            id
            name
            number
            dateInitial
            dateEnd
            dateAward
          }
        }
      }
      fund {
        id
        hasClasses
        businessName
        fundType
        minimumInvestment
        currency {
          code
          symbol
        }
        funddocumentSet {
          edges {
            node {
              fileType
              file
              date
              state
            }
          }
        }
      }
    }
  }
`;

export const GET_FUND = gql`
  query getFund($id: ID!) {
    fund(id: $id) {
      id
      hasClasses
    }
  }
`;

export const GET_CLIENT_BY_DOCUMENT_AND_RAISING = gql`
  query getClientByDocumentAndRaising(
    $raisingId: ID
    $documentNumber: String
    $documentType: String
  ) {
    getClientByDocumentAndRaising(
      raisingId: $raisingId
      documentNumber: $documentNumber
      documentType: $documentType
    ) {
      id
      phone
      cellphone
      communicationCellphone
      communicationEmail
      user {
        lastName
        maternalSurname
        firstName
        emailValidated
      }
    }
  }
`;

export const SUBMIT_RAISING_PARTICIPATION = gql`
  mutation createParticipation(
    $raisingId: ID
    $input: CreateParticipationInput
  ) {
    createParticipation(raisingId: $raisingId, input: $input) {
      created
    }
  }
`;

export const GET_MAX_FEE_COUNT = gql`
  query totalFundFeeCount($raisingId: ID) {
    totalFundFeeCount(raisingId: $raisingId) {
      fundFeeCount
    }
  }
`;

export const GET_CLIENT_PARTICIPANT_FUNDS = gql`
  query getClientParticipantFunds($clientId: ID!) {
    listParticipant(client: $clientId) {
      edges {
        node {
          id
          state
          wheel {
            raising {
              acronym
              number
              fund {
                id
                businessName
              }
            }
          }
        }
      }
    }
  }
`;
