import React, { useEffect, useState, useRef } from 'react';

// GraphQl
import { gql } from 'apollo-boost';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';

// Material resources
import { Grid, Typography, Button, MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

// Components & Others
import PropTypes from 'prop-types';
import cx from 'clsx';
import MUIRichTextEditor from 'mui-rte';
import { Formik } from 'formik';
import Yup from 'src/utils/yup';
import { edgeToList, getOptions } from 'src/utils/commonFunctions';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { CREATE_AFFIDAVIT_RULES, CONDITIONS } from './constants';
import CustomSelect from 'src/components/FormComponents/CustomSelect';
import { parseAutocomplete } from '../../Contract/ContractForm/constants';
import {
  LIST_SWORNDECLARATIONS,
  UPDATE_SWORNDECLARATION,
  CREATE_SWORNDECLARATION,
  GET_SWORNDECLARATION,
} from '../queries';
import { LIST_FUNDS } from '../../Fund/queries';
import LoadingIndicator from 'src/components/LoadingIndicator';

// Styles & Images
import useStyles from './styles';

function SwornDeclarationForm(props) {
  const { id } = props;

  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [swornDeclarationData, setSwornDeclarationData] = useState(null);
  const [swornDeclarationValue, setSwornDeclarationValue] = useState(null);
  const [formValues, setformValues] = useState(null);

  const swornDeclarationRef = useRef(null);

  const [loadSwornDeclaration, { loading, data: queryData }] = useLazyQuery(
    GET_SWORNDECLARATION,
    {
      variables: {
        id,
      },
    }
  );

  const {
    loading: loadingFunds,
    error: fundsError,
    data: fundsData,
  } = useQuery(LIST_FUNDS);

  const [updateMutation] = useMutation(
    UPDATE_SWORNDECLARATION,
    getOptions({
      mutationName: 'updateTypeSwornDeclaration',
      modelName: 'typeSwornDeclaration',
      message: 'Declaración jurada actualizada',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_SWORNDECLARATIONS },
        { query: GET_SWORNDECLARATION, variables: { id } },
      ],
      completeCallback: () => history.goBack(),
    })
  );
  const [createMutation] = useMutation(
    CREATE_SWORNDECLARATION,
    getOptions({
      mutationName: 'createTypeSwornDeclaration',
      modelName: 'typeSwornDeclaration',
      message: 'Declaración jurada creada con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SWORNDECLARATIONS }],
      completeCallback: () => history.goBack(),
    })
  );

  useEffect(() => {
    loadSwornDeclaration();
  }, [id]);
  useEffect(() => {
    if (queryData && queryData.typeSwornDeclaration) {
      const { typeSwornDeclaration } = queryData;
      const tmpData = typeSwornDeclaration;

      setSwornDeclarationData(tmpData);
    }
  }, [queryData]);

  useEffect(() => {
    if (formValues && swornDeclarationValue) {
      if (id !== '') {
        updateMutation({
          variables: {
            input: {
              name: formValues.name,
              condition: CONDITIONS.find(c => c.value === formValues.condition)
                .enum,
              text: swornDeclarationValue,
              fund: formValues.fund,
            },
            id,
          },
        });
      } else {
        createMutation({
          variables: {
            input: {
              name: formValues.name,
              condition: CONDITIONS.find(c => c.value === formValues.condition)
                .enum,
              text: swornDeclarationValue,
              fund: formValues.fund,
            },
          },
        });
      }
    }
  }, [formValues, swornDeclarationValue]);

  const CONTROLS = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'undo',
    'redo',
    'numberList',
    'bulletList',
    'clear',
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    condition: Yup.string().required(),
    fund: Yup.string().required(),
  });

  const formInitialValues = {
    name: '',
    condition: '',
    fund: '',
  };

  return loading || (id && !swornDeclarationData) ? (
    <LoadingIndicator />
  ) : (
    <Formik
      initialValues={
        id !== ''
          ? {
              name: swornDeclarationData.name,
              condition: swornDeclarationData.condition,
              fund: swornDeclarationData.fund
                ? swornDeclarationData.fund.id
                : null,
            }
          : formInitialValues
      }
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values, { setSubmitting }) => {
        swornDeclarationRef.current.save();
        setformValues(values);
        setSubmitting(false);
      }}
    >
      {({ submitForm }) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Crear declaración jurada
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <TextField
                  label="Nombre"
                  name="name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <CustomSelect
                  fullWidth
                  label="Condición"
                  name="condition"
                  data={CONDITIONS}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <CustomSelect
                  fullWidth
                  label="Fondo"
                  name="fund"
                  data={edgeToList(fundsData, 'listFunds')}
                  mapData={{ value: 'id', label: 'businessName' }}
                />
              </Grid>
            </Grid>

            <Grid item xs={6} className={classes.columnContainer}>
              <Grid item xs={12} className={classes.editorContainer}>
                <MUIRichTextEditor
                  ref={swornDeclarationRef}
                  defaultValue={id !== '' && swornDeclarationData.text}
                  autocomplete={{
                    strategies: [
                      {
                        items: parseAutocomplete(),
                        triggerChar: ':',
                      },
                    ],
                  }}
                  label="Texto del contrato"
                  onSave={data => {
                    setSwornDeclarationValue(data);
                  }}
                  inlineToolbar
                  controls={CONTROLS}
                  customControls={[
                    {
                      name: 'numberCList',
                      type: 'inline',
                      inlineStyle: {
                        display: 'inline-block',
                        width: '3em',
                        verticalAlign: 'top',
                      },
                    },
                    {
                      name: 'alphaCList',
                      type: 'inline',
                      inlineStyle: {
                        display: 'inline-block',
                        width: 'calc(100% - 3em)',
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.columnContainer}>
              <Grid
                item
                xs={12}
                className={cx(classes.infoContainer, classes.editorContainer)}
              >
                <MUIRichTextEditor
                  readOnly
                  defaultValue={CREATE_AFFIDAVIT_RULES}
                  controls={[]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
              <Grid item>
                <Button
                  onClick={() => history.goBack()}
                  variant="contained"
                  color="primary"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="secondary"
                >
                  {id !== '' ? 'Guardar' : 'Crear'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

SwornDeclarationForm.propTypes = {
  id: PropTypes.string,
};

SwornDeclarationForm.defaultProps = {
  id: '',
};

export default SwornDeclarationForm;

export function SwornDeclarationEdit() {
  const { id } = useParams();

  return <SwornDeclarationForm id={id} />;
}
