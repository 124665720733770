import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import ProcessList from '../../../components/Models/EmployeeSignatures/ProcessList';
import ProcessForm from '../../../components/Models/EmployeeSignatures/Form';

function EmployeeSignaturePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ProcessList} />
      <Route path={`${path}/:id`} component={ProcessForm} />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

EmployeeSignaturePage.propTypes = {};

export default EmployeeSignaturePage;
