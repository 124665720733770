/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';
// Components & Others
import NotFound from '../../NotFoundPage';
import InvestmentAccountStateList from '../../../components/Models/InvestmentAccountState/List';
import { Grid } from '@material-ui/core';

function InvestmentAccountStatePage() {
  const { path } = useRouteMatch();


  return (
    // <Switch>
    //   <Route exact path={path} component={InvestmentAccountStateList} />
    //   <Route path="" component={NotFound} />
    // </Switch>
    <Grid container alignItems="start">
      <Grid item xs={12}>
        <Route exact path={path} component={InvestmentAccountStateList} />
      </Grid>
    </Grid>
  );
}

export default InvestmentAccountStatePage;
