import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_FUND_SOURCE,
  CREATE_FUND_SOURCE,
  DELETE_FUND_SOURCE,
  LIST_FUND_SOURCE,
} from '../queries';
import {
  validationFunctionFundSource,
  validationSchemaFundSource,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function FundSourceForm(props) {
  const { fundSource, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateFundSource = useMutation(
    UPDATE_FUND_SOURCE,
    getOptions({
      mutationName: 'updateFundSource',
      modelName: 'fundSource',
      message: 'Fuente de fondos actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const createFundSource = useMutation(
    CREATE_FUND_SOURCE,
    getOptions({
      mutationName: 'createFundSource',
      modelName: 'fundSource',
      message: 'Fuente de fondos creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createFundSourceData }) {
        if (cache.data.data.ROOT_QUERY.listFundSource) {
          const { listFundSource } = cache.readQuery({
            query: LIST_FUND_SOURCE,
          });
          listFundSource.edges.unshift({
            // Be careful with types
            __typename: 'FundSourceTypeEdge',
            node: createFundSourceData.createFundSource.fundSource,
          });
          cache.writeQuery({
            query: LIST_FUND_SOURCE,
            data: { listFundSource },
          });
        }
        history.push('/administrador/fuentes');
      },
    })
  );
  const deleteFundSource = useMutation(
    DELETE_FUND_SOURCE,
    getOptions({
      mutationName: 'deleteFundSource',
      message: 'Fuente de fondos eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listFundSource) {
          const { listFundSource } = cache.readQuery({
            query: LIST_FUND_SOURCE,
          });
          listFundSource.edges = listFundSource.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_FUND_SOURCE,
            data: { listFundSource },
          });
        }
        history.push('/administrador/fuentes');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionFundSource}
        validateSchema={validationSchemaFundSource}
        data={data}
        model={fundSource}
        id={id}
        modelName="Fuentes"
        routeName="/administrador/fuentes"
        isEdit={isEdit}
        updateMutation={updateFundSource}
        createMutation={createFundSource}
        deleteMutation={deleteFundSource}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

FundSourceForm.propTypes = {
  fundSource: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

FundSourceForm.defaultProps = {
  fundSource: null,
  id: '',
  isEdit: false,
};

export default FundSourceForm;
