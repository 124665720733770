import { fromJS } from 'immutable';

import { ACTIONS } from './actions';

// The initial state of the App
const initialState = fromJS({
  permissions: [],
  userPermissions: [],
  clientPermissions: [],
});

function SharedStoreReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_PERMISSIONS:
      return state.set('permissions', action.permissions);
    case ACTIONS.SET_USER_PERMISSIONS:
      return state.set('userPermissions', action.userPermissions);
    case ACTIONS.SET_CLIENT_PERMISSIONS:
      action.clientPermissions.sort();
      return state.set('clientPermissions', action.clientPermissions);
    case ACTIONS.REMOVE_CLIENT_PERMISSIONS:
      return state.set('clientPermissions', []);
    default:
      return state;
  }
}

export default SharedStoreReducer;
