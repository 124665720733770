// eslint-disable-next-line import/prefer-default-export
export const FORM_OPTIONS = {
  yesNo: [
    { value: 'true', label: 'Si' },
    { value: 'false', label: 'No' },
  ],

  directaIndirecta: [
    { value: 'true', label: 'Directa' },
    { value: 'false', label: 'Indirecta' },
  ],

  sex: [
    { value: 'M', label: 'Masculino' },
    { value: 'F', label: 'Femenino' },
  ],

  documentType: [
    { value: 'dni', label: 'DNI' },
    { value: 'card', label: 'Carné de Extranjeriab' },
    { value: 'pasport', label: 'Pasaportec' },
    { value: '', label: 'Otros' },
  ],

  domiciled: [
    { value: 'true', label: 'Domiciliado' },
    { value: 'false', label: 'No domiciliado' },
  ],

  civilStates: [
    { value: 'single', label: 'Soltero' },
    { value: 'married', label: 'Casado' },
    { value: 'divorced', label: 'Divorciado' },
    { value: 'widowed', label: 'Viudo' },
    { value: 'union', label: 'Unión de hecho o conviviente' },
  ],

  patrimonialRegime: [
    { value: 'society', label: 'Sociedad de gananciales' },
    { value: 'separation', label: 'Separación de patrimonios' },
    { value: 'particiation', label: 'Unión de hecho' },
  ],
};
