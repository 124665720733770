import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { LIST_BANKS } from '../../Bank/queries';
import {
  CREATE_PAYMENT,
  LIST_CLIENT_PAYMENTS,
  UPDATE_PAYMENT,
} from '../queries';
import { GET_CLIENT_PARTICIPANT_FUNDS } from '../../../Cognito/queries';
import { getClientSelector } from '../../../../store/sharedStore/selectors';
import {
  edgeToList,
  errorMessage,
  getOptions,
} from '../../../../utils/commonFunctions';
import LoadingIndicator from '../../../LoadingIndicator';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import Yup from '../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

const ADJUDICATED = 'AD';
const PAGO_LIGADO = 'LP';

const startValues = {
  participant: '',
  bank: '',
  amount: null,
  operationNumber: '',
};

function PaymentForm({ payment, id, hasCancel, isEdit, updateCallback }) {
  const client = useSelector(getClientSelector());
  const [banksList, setBanksList] = useState([]);
  const [fundsList, setFundsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [
    getClientBanks,
    { data: banksData, loading: banksLoading, error: banksError },
  ] = useLazyQuery(LIST_BANKS);
  const [
    getClientFunds,
    { data: fundsData, loading: fundsLoading, error: fundsError },
  ] = useLazyQuery(GET_CLIENT_PARTICIPANT_FUNDS, {
    fetchPolicy: 'no-cache',
  });

  const validationSchema = Yup.object().shape({
    participant: Yup.string().required(),
    bank: Yup.string().required(),
    amount: Yup.number().required(),
    operationNumber: Yup.string().required(),
    voucherPhoto: isEdit ? Yup.string().nullable() : Yup.string().required(),
  });

  const createPayment = useMutation(
    CREATE_PAYMENT,
    getOptions({
      mutationName: 'createPayment',
      modelName: 'payment',
      message: 'Pago registrado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_CLIENT_PAYMENTS, variables: { clientId: client.id } },
      ],
      completeCallback: history.goBack,
    })
  );

  const updatePayment = useMutation(
    UPDATE_PAYMENT,
    getOptions({
      mutationName: 'updatePayment',
      modelName: 'payment',
      message: 'Pago actualizado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_CLIENT_PAYMENTS, variables: { clientId: client.id } },
      ],
      completeCallback: updateCallback,
    })
  );

  useEffect(() => {
    if (client) {
      getClientBanks({ variables: { clientId: client.id } });
      getClientFunds({ variables: { clientId: client.id } });
    }
  }, [client, getClientBanks, getClientFunds]);

  useEffect(() => {
    if (banksData) {
      const banks = edgeToList(banksData, 'listBank');
      setBanksList(banks);
    }
    if (fundsData) {
      const participants = edgeToList(fundsData, 'listParticipant');
      setFundsList(
        participants
          .filter(
            ({ state }) =>
              isEdit || state === ADJUDICATED || state === PAGO_LIGADO
          )
          .map(({ wheel, id }) => ({
            displayName: wheel.raising.fund.businessName,
            id: id,
          }))
      );
    }
    const error = fundsError || banksError;
    if (error) {
      errorMessage(error, enqueueSnackbar);
    }
  }, [banksData, fundsData, fundsError, banksError, enqueueSnackbar]);

  const formStructure = [
    {
      label: 'Fondo',
      name: 'participant',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: fundsList,
      mapData: { value: 'id', label: 'displayName' },
      disabled: isEdit,
    },
    {
      label: 'Banco',
      name: 'bank',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: banksList,
      mapData: { value: 'id', label: 'name' },
      disabled: isEdit,
    },
    {
      label: 'Monto',
      name: 'amount',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
      },
    },
    {
      label: 'Número de Operación',
      name: 'operationNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Foto del Voucher',
      name: 'voucherPhoto',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.image,
    },
  ];
  const formatBeforeSubmit = values => {
    const formatedValues = { ...values };
    if (isEdit && !values.voucherPhoto) {
      delete formatedValues.voucherPhoto;
    }
    return formatedValues;
  };
  return banksLoading || fundsLoading ? (
    <LoadingIndicator />
  ) : (
    <SimpleForm
      initialValues={startValues}
      validateSchema={validationSchema}
      formatBeforeSubmit={formatBeforeSubmit}
      data={formStructure}
      model={payment}
      id={id}
      isEdit={isEdit}
      modelName="Registro de Pago"
      updateMutation={updatePayment}
      createMutation={createPayment}
      canBeDeleted={false}
      hasCancel={hasCancel}
      onCancel={history.goBack}
    />
  );
}

PaymentForm.propTypes = {
  payment: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
  hasCancel: PropTypes.bool,
  updateCallback: PropTypes.func,
};

PaymentForm.defaultProps = {
  payment: null,
  id: '',
  isEdit: false,
  hasCancel: true,
  updateCallback: () => {},
};
export default PaymentForm;
