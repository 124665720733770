import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Grid,
} from '@material-ui/core';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

// Others
import PropTypes from 'prop-types';
import { Field } from 'formik';
import moment from 'moment';

// Styles & Images
import useStyles from './styles';
import DatePickerField from '../DatePicker';

function DateRangePicker(props) {
  const { fieldData } = props;
  const classes = useStyles();

  return (
    <div className={classes.inputContainer}>
      <Field
        name={fieldData.startDate.name}
        label={fieldData.startDate.label}
        variant="standard"
        component={DatePickerField}
        fullWidth
      />
      <TrendingFlatIcon color="primary" className={classes.arrowIcon} />
      <Field
        name={fieldData.endDate.name}
        label={fieldData.endDate.label}
        variant="standard"
        component={DatePickerField}
        fullWidth
      />
    </div>
  );
}
DateRangePicker.propTypes = {
  fieldData: PropTypes.shape().isRequired,
};
DateRangePicker.defaultProps = {};

export default DateRangePicker;
