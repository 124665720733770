import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'start',
    color: '#0F1729',
    margin: 0,
  },
  titleDark: {
    fontWeight: 700,
    fontSize: '20px',
    textAlign: 'start',
    color: '#BEBEBE',
    margin: 0,
  },
  titleDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    paddingLeft: '10px',
  },

  profile: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTypography-root': {
      fontSize: 14,
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },


  simpleForm: {
    width: '100%',
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  btn: {
    width: '100%',
    marginBottom: 10,
    textTransform: 'capitalize',
    borderRadius: '8px',
    background: '#007DFA',
    height: '35px',
    '&:hover': {
      background: '#007DFA',
    },
    textAlign: 'center',
  },
  main: {
    position: 'relative',
    zIndex: 999,
  },
  formDark: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '20px',
    flexDirection: 'column',
    background: '#212121',
    height: 'fit-content',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formLight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '20px',
    flexDirection: 'column',
    background: '#fff',
    height: 'fit-content',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  containerPassword: {
    width: 'fit-content',
    minWidth: ' 30%',
    height: 'fit-content',
    borderRadius: ' 10px',
    background: '#F4FBFF',
    boxShadow: ' 0px 4px 10px 0px rgba(8, 71, 143, 0.25)',
    justifyContent: ' space-around',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px',
  },

  layout: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      ' linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },

  layoutDark: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      'linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.40) 100%)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },

  mssgDiv: {
    border: '2px solid #82A9C9',
    background: '#D0DCE6',
    width: '100%',
    borderRadius: '5px',
    alignItems: 'center',
    textAlign: 'start',
    padding: '5px',
  },
  mssgText: {
    color: '#0E2639',
    fontSize: '0.80rem',
    fontWeight: 500,
    margin: 0,
  },
}));

