/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import FundClassList from '../../../components/Models/FundClass/FundClassList';
import FundClassCreatedEdit from '../../../components/Models/FundClass/FundClassCreateEdit/edit';
import FundClassForm from '../../../components/Models/FundClass/FunClassForm';

function FoundClassPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={FundClassList} />
      <Route path={`${path}/crear`} component={FundClassForm} />
      <Route path={`${path}/:id`} component={FundClassCreatedEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default FoundClassPage;
