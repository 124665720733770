import React, { useState } from 'react';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import Yup from '../../../../utils/yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_BANK_ACCOUNT,
  CREATE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT,
  LIST_BANK_ACCOUNTS,
} from '../queries';
import {
  edgeToList,
  parseEquivalent,
  getOptions,
} from '../../../../utils/commonFunctions';
import { BUSINESS_PROMOTER } from '../../PromoterComponent/Form/index';
import { PROMOTER } from '../../Promoter/Form/index';
import { LIST_BANKS } from '../../Bank/queries';
import { LIST_CURRENCIES } from '../../Currency/queries';
import { LIST_ACCOUNT_TYPES } from '../../AccountType/queries';
import { FORM_OPTIONS } from '../../../StepsComponents/constants';
import {
  setBankAccountIdPromoterCompany,
  setBankAccountIdPromoter,
} from '../../../../store/sharedStore/actions';

const startValues = {
  bank: '',
  currency: '',
  accountType: '',
  accountNumber: '',
  cci: '',
  aba: '',
  swift: '',
  country: '',
  jointAccount: 'false',
  observations: '',
};

function BankAccountForm(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { bankAccount, id, isEdit } = props;
  const [isPeru, setIsPeru] = useState(false);
  const history = useHistory();
  const updateBankAccount = useMutation(
    UPDATE_BANK_ACCOUNT,
    getOptions({
      mutationName: 'updateBankAccount',
      modelName: 'bankAccount',
      message: 'Cuenta bancaria actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const createBankAccount = useMutation(
    CREATE_BANK_ACCOUNT,
    getOptions({
      enqueueSnackbar,
      modelName: 'bankAccount',
      mutationName: 'createBankAccount',
      message: 'Cuenta bancaria creada con éxito.',
      refetchQueries: [{ query: LIST_BANK_ACCOUNTS }],
      completeCallback: (data, errors) => {
        const {
          location: { state },
        } = history;
        if (state && state.precedence === BUSINESS_PROMOTER) {
          dispatch(setBankAccountIdPromoterCompany(data.id));
        }
        if (state && state.precedence === PROMOTER) {
          dispatch(setBankAccountIdPromoter(data.id));
        }
        history.goBack();
      },
    })
  );
  const deleteBankAccount = useMutation(
    DELETE_BANK_ACCOUNT,
    getOptions({
      enqueueSnackbar,
      modelName: 'bankAccount',
      mutationName: 'deleteBankAccount',
      message: 'Cuenta bancaria eliminada.',
      refetchQueries: [{ query: LIST_BANK_ACCOUNTS }],
      completeCallback: () => history.goBack(),
    })
  );

  // SELECT QUERIES
  const {
    loading: BanksLoading,
    error: BanksError,
    data: BanksData,
  } = useQuery(LIST_BANKS);

  const {
    loading: AccountTypesLoading,
    error: AccountTypesError,
    data: AccountTypesData,
  } = useQuery(LIST_ACCOUNT_TYPES);

  const {
    loading: CurrenciesLoading,
    error: CurrenciesError,
    data: CurrenciesData,
  } = useQuery(LIST_CURRENCIES);

  if (BanksError) {
    console.error(BanksError);
  }
  if (AccountTypesError) {
    console.error(AccountTypesError);
  }
  if (CurrenciesError) {
    console.error(CurrenciesError);
  }

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.jointAccount = parseEquivalent(newValues.jointAccount);
    return newValues;
  }

  const data = [
    {
      label: 'Banco',
      name: 'bank',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(BanksData, 'listBank'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Tipo de cuenta',
      name: 'accountType',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(AccountTypesData, 'listAccountType'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Moneda',
      name: 'currency',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(CurrenciesData, 'listCurrencies'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Número de cuenta',
      name: 'accountNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        allowLeadingZeros: true,
        allowNegative: false,
      },
    },
    {
      label: 'Código de cuenta interbancario',
      name: 'cci',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Swift',
      name: 'swift',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'ABA',
      name: 'aba',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'País',
      name: 'country',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.country,
      checkPeru: val => setIsPeru(val),
    },
    {
      label: '¿Es una cuenta mancomunada?',
      name: 'jointAccount',
      gridMD: 6,
      data: FORM_OPTIONS.yesNo,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
    },
    {
      label: 'Observaciones',
      name: 'observations',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  const validationSchemaBankAccount = Yup.object().shape({
    bank: Yup.string().required(),
    currency: Yup.string().required(),
    accountType: Yup.string().required(),
    accountNumber: Yup.string().required(),
    cci: Yup.string().required(),
    swift: Yup.string().when({
      is: () => !isPeru,
      then: Yup.string().required(
        'Este campo es requerido para cuentas extranjeras'
      ),
    }),
    country: Yup.string().required(),
    jointAccount: Yup.string().required(),
  });

  function validationFunctionBankAccount(values) {
    const errors = {};
    // Custom Validations
    return errors;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionBankAccount}
        validateSchema={validationSchemaBankAccount}
        data={data}
        model={bankAccount}
        id={id}
        modelName="Cuentas de banco"
        routeName="/administrador/cuentas-bancarias"
        isEdit={isEdit}
        updateMutation={updateBankAccount}
        createMutation={createBankAccount}
        deleteMutation={deleteBankAccount}
        formatBeforeSubmit={formatBeforeSubmit}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

BankAccountForm.propTypes = {
  bankAccount: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

BankAccountForm.defaultProps = {
  bankAccount: null,
  id: '',
  isEdit: false,
};

export default BankAccountForm;
