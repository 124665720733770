// GraphQl
import { gql } from 'apollo-boost';

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      frontendPermissions {
        edges {
          node {
            id
            displayName
            slug
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      user {
        frontendPermissions {
          edges {
            node {
              id
              displayName
              slug
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
