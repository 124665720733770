/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';

// Material Resources

// Components & Others
import { useSelector } from 'react-redux';

// Styles & Images
import useStyles from './styles';

import { getClientSelector } from 'src/store/sharedStore/selectors';
import { LIST_PENDINGS } from 'src/containers/ClientPage/queries';
import { useQuery } from '@apollo/react-hooks';
import NotificationsIcon from '../svg/notificationsIcon';
import { Dialog, Popover } from '@material-ui/core';
import getClientMode from 'src/store/utils-store/selectors';
const mockNotifications = [
  {
    type: 'signing',
    title: 'Documento Disponible para Firmar',
    content:
      'Tienes un nuevo documento pendiente de firma. Por favor, revisa y firma el documento en la sección de Documentos para mantener tus registros al día.',
  },
  {
    type: 'files',
    title: 'Archivo Recientemente Cargado',
    content:
      'Tienes un nuevo archivo cargado. Por favor, revísalo en la sección de Repositorios.',
  },
];
function PopUpNotifications(props) {
  const { open, handleClose, anchorEl } = props;
  const classes = useStyles();
  const modeClient = useSelector(getClientMode());
  const client = useSelector(getClientSelector());
  const { data } = useQuery(LIST_PENDINGS, {
    variables: { id: client.id },
  });
  const [hasSigning, setHasSigning] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);

  useEffect(() => {
    if (data) {
      setHasSigning(data.hasPendingSignatures);
      setHasFiles(data.hasNewFiles);
    }
  }, [data]);

  const mainDiv = modeClient ? classes.mainDivDark : classes.mainDiv;
  const itemStyle = modeClient ? classes.itemDark : classes.item;
  const titleStyle = modeClient ? classes.titleDark : classes.title;
  const contentStyle = modeClient ? classes.contentDark : classes.content;
  const popUpStyle = modeClient ? classes.popUpDark : classes.popUp;

  return (
    <>
      <div className={popUpStyle}>
        {(hasSigning || hasFiles) && (
          <>
            {mockNotifications.length > 0 &&
              hasSigning &&
              mockNotifications.map(item => {
                return (
                  item.type == 'signing' && (
                    <>
                      <div className={itemStyle}>
                        <p className={titleStyle}>{item.title}</p>
                        <p className={contentStyle}> {item.content}</p>
                      </div>
                    </>
                  )
                );
              })}
            {mockNotifications.length > 0 &&
              hasFiles &&
              mockNotifications.map(item => {
                return (
                  item.type == 'files' && (
                    <>
                      <div className={itemStyle}>
                        <p className={titleStyle}>{item.title}</p>
                        <p className={contentStyle}> {item.content}</p>
                      </div>
                    </>
                  )
                );
              })}
          </>
        )}
        {!hasSigning && !hasFiles && (
          <>
            <div
              className={mainDiv}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'fit-content',
                  alignItems: 'center',
                  gap: '5px',
                  alignContent: 'center',
                }}
              >
                <p className={contentStyle}>No hay notificaciones!</p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default PopUpNotifications;
