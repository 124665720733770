// GraphQl
import { gql } from 'apollo-boost';

export const LIST_FUND_SOURCE = gql`
  {
    listFundSource {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_FUND_SOURCE = gql`
  query getFundSource($id: ID!) {
    fundSource(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_FUND_SOURCE = gql`
  mutation createFundSource($input: FundSourceInput!) {
    createFundSource(input: $input) {
      fundSource {
        id
        name
      }
    }
  }
`;

export const UPDATE_FUND_SOURCE = gql`
  mutation updateFundSource($id: ID!, $input: FundSourceInput!) {
    updateFundSource(id: $id, input: $input) {
      fundSource {
        id
        name
      }
    }
  }
`;

export const DELETE_FUND_SOURCE = gql`
  mutation deleteFundSource($id: ID!) {
    deleteFundSource(id: $id) {
      ok
      message
    }
  }
`;
