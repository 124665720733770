import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_CLIENT_ATTORNEY_LABOR_DATA,
  UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import LoadingIndicator from '../../../LoadingIndicator';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import {
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';

function LaborDataAttorney(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object().shape({
    occupation: Yup.string().required(),
  });

  const startValues = {
    occupation: '',
  };

  const { loading, data: attorneyData } = useQuery(
    GET_CLIENT_ATTORNEY_LABOR_DATA,
    {
      variables: { id: clientID },
    }
  );

  const updateClient = useMutation(UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage('Se guardó datos laborales.', enqueueSnackbar);
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  const partialUpdateClient = useMutation(
    UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT,
    {
      onError(error) {
        const { graphQLErrors } = error;
        errorMessage(graphQLErrors, enqueueSnackbar);
      },
      onCompleted(data) {
        const { client, errors } = data.updateClient;
        if (client) {
          successMessage('Se guardó datos laborales.', enqueueSnackbar);
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    }
  );

  function validationFunction() {
    const errors = {};
    return errors;
  }

  let assignee = null;
  if (loading) {
    return <LoadingIndicator />;
  }
  if (attorneyData.client.assignee) {
    assignee = attorneyData.client.assignee;
    startValues.occupation = assignee.occupation;
  }

  const data = [
    {
      isField: true,
      label: '* Profesión u ocupación',
      name: 'occupation',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
  ];

  return (
    <StepsForm
      id={assignee.id}
      mainClientID={clientID}
      initialValues={startValues}
      validateFunction={validationFunction}
      validateSchema={validationSchema}
      data={data}
      title="Datos del apoderado o mandatorio / Datos laborales"
      nextForm={MENU_ITEMS_ID.ClientBankData}
      updateMutation={updateClient}
      partialUpdateMutation={partialUpdateClient}
    />
  );
}

LaborDataAttorney.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default LaborDataAttorney;
