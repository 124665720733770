/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';

// Material Resources
import CssBaseline from '@material-ui/core/CssBaseline';

// Resources
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Styles & Images
import Favicon from '../../images/brand/CorePartners/CP_logo.png';

// Import Components & Pages
import NotFoundPage from '../NotFoundPage/Loadable';
import LoginPage from '../LoginPage/Loadable';
import PrivateRoute, { ROL_GROUP } from '../../components/PrivateRoute';
import AdminPage from '../AdminPage';
import ClientPage from '../ClientPage';
import ActivateAccountPage from '../ActivateAccountPage';
// import SendRecoverMailPage from '../SendRecoverMailPage';
import ChangePasswordPage from '../ChangePasswordPage';
import CognitoPage from '../CognitoPage';
import RepurchaseCognitoPage from '../RepurchaseCognitoPage';
import ReturnDocusignPage from '../ReturnDocusignPage';
import ExternalSignerPage from '../ExternalSignerPage';
import TestingLoginPage from '../LoginPage/testingLogin';
// import SendRecoverMailPageOriginal from '../SendRecoverMailPage/indexT';

function App() {

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Intranet Core Capital</title>
        <link rel="icon" type="image/png" href={Favicon} sizes="16x16" />
      </Helmet>
      <CssBaseline />
      <Switch>
        <Route
          path="/external-signer/:documentId/:signerId/:token"
          component={ExternalSignerPage}
        />
        <Route exact path="/digits/:token" component={LoginPage} />
        <Route exact path="/" component={LoginPage} />
        <PrivateRoute
          path="/administrador"
          component={AdminPage}
          usergroup={ROL_GROUP.admin}
        />
        <PrivateRoute
          path="/cliente"
          component={ClientPage}
          usergroup={ROL_GROUP.client}
        />
        {/* <Route path="/enviar-mail-contrasena" component={SendRecoverMailPageOriginal} /> */}
        <Route path="/testing-login" component={TestingLoginPage} />
        <Route
          path="/cambiar-contrasena/:id/:token"
          component={ChangePasswordPage}
        />
        <Route
          path="/activar-cuenta/:id/:token"
          component={ActivateAccountPage}
        />
        <Route path="/registro/:raisingID" component={CognitoPage} />
        <Route
          path="/registro-recompra/:repurchaseID"
          component={RepurchaseCognitoPage}
        />
        <Route path="/regreso-de-docusign" component={ReturnDocusignPage} />
        <Route path="" component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default App;
