import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';

// Material Resources
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Grid,
} from '@material-ui/core';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';

// GraphQl
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

// Components & Others
import CustomCard from '../../../CustomCard';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import { GET_INVESTMENT_REPORTS } from '../queries';
import { GET_CLIENT_LEGAL_DATA } from '@queries/Client';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import { edgeToList } from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { getClientSelector } from '../../../../store/sharedStore/selectors';
import { REACT_APP_SERVER_LINK } from '../../../../utils/constants';

// Styles & Images
import useStyles from './styles';
import PDFViewerModal from 'src/components/PDFViewerModal';
import CustomModal from '../../../CustomModal';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function InvestmentAccountStateList() {
  const classes = useStyles();
  const client = useSelector(getClientSelector());
  const [docNumber, setDocNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState('');
  const [reportList, setReportList] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState('0');
  const [endCursor, setEndCursor] = useState('');
  const [startCursor, setStartCursor] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);

  const [getInvestmentReports, { loading, data, error }] = useLazyQuery(
    GET_INVESTMENT_REPORTS,
    {
      onCompleted: data => {
        setStartCursor(data.investmentReports.pageInfo.startCursor);
        setEndCursor(data.investmentReports.pageInfo.endCursor);
        setTotalCount(data.investmentReports.totalCount);
        setReportList(edgeToList(data, 'investmentReports'));
        setFirstLoad(false);
      },
      onError: error => {
        console.error('list clients', error);
      },
    },
  );

  const [
    getDocNumber,
    { loading: loadingClient, data: dataClient },
  ] = useLazyQuery(GET_CLIENT_LEGAL_DATA, {
    onCompleted: data => {
      if (data?.client.user) {
        setDocNumber(dataClient.client.user.documentNumber);
      }
    },
  });

  useEffect(() => {
    if (client) {
      getDocNumber({
        variables: { id: client.id },
      });
    }
  }, [client, getDocNumber]);

  useEffect(() => {
    if (docNumber) {
      getInvestmentReports({
        variables: { documentNumber: docNumber, first: rowsPerPage },
      });
    }
  }, [client, docNumber, getInvestmentReports]);

  const columns = [
    {
      name: 'date',
      label: 'Fecha',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];

  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        const report = edgeToList(data, 'investmentReports').find(
          e => e.id === id,
        );
        let options = ['ocustom', 'oattached'];
        return (
          <CustomOptions
            id={id}
            key={id}
            model="Report"
            options={options}
            handleAttach={() => {
              const link = document.createElement('a');

              if (report.downloadLink.includes('amazonaws'))
                link.href = `${report.downloadLink}`;
              else link.href = `${REACT_APP_SERVER_LINK}${report.downloadLink}`;
              link.click();
            }}
            titleAttach="Descargar PDF"
            customOption={
              <span>
                <Tooltip arrow title="Ver documento" placement="top">
                  <IconButton
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {
                      setPreview(report.previewLink);
                      setShowModal(true);
                    }}
                    disabled={report && !report.previewLink}
                  >
                    <RecentActorsOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={7} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10],
    page: currentPage,
    count: totalCount,
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'propsUpdate':
          setCurrentPage(tableState.page);
          break;
        case 'changePage':
          if (currentPage < tableState.page) {
            getInvestmentReports({
              variables: {
                documentNumber: docNumber,
                first: rowsPerPage,
                after: endCursor,
              },
            });
          } else if (currentPage > tableState.page) {
            getInvestmentReports({
              variables: {
                documentNumber: docNumber,
                before: startCursor,
                last: rowsPerPage,
              },
            });
          }
          setCurrentPage(tableState.page);
          break;
        case 'changeRowsPerPage':
          setCurrentPage(0);
          getInvestmentReports({
            variables: {
              documentNumber: docNumber,
              first: tableState.rowsPerPage,
            },
          });
          setRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  function callbackProxy(callback, password) {
    if (password === null) {
      setShowModal(false);
      return;
    }
    callback(password);
  }

  const onPassword = (callback, reason) => {
    switch (reason) {
      case 1: {
        const password = prompt(
          'Ingrese la contraseña para abrir este archivo PDF.',
        );
        callbackProxy(callback, password);
        break;
      }
      case 2: {
        const password = prompt('Contraseña invalida. Inténtalo de nuevo.');
        callbackProxy(callback, password);
        break;
      }
      default:
    }
  };

  return (
    <CustomCard
      disableFlex
      showHeader={false}
      content={
        firstLoad ? (
          <LoadingIndicator />
        ) : (
          <>
            <MUIDataTable
              title="Reportes"
              data={reportList}
              columns={columns}
              options={options}
            />
            {preview && preview.includes('amazonaws') ? (
              <PDFViewerModal
                showModal={showModal && preview.includes('amazonaws')}
                onClosePress={() => setShowModal(false)}
                fileURL={preview}
              />
            ) : (
              <CustomModal
                maxWidth="md"
                showModal={showModal}
                title="Documento"
                onClosePress={() => {
                  setShowModal(false);
                }}
                hideScroll={true}
              >
                <div className={classes.modalContent}>
                  <Document
                    file={`${REACT_APP_SERVER_LINK}${preview}`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<LoadingIndicator />}
                    error={<div>Error al cargar el documento</div>}
                    onPassword={onPassword}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <>
                        <p className={classes.pageContent}>
                          Página {index + 1} de {numPages}
                        </p>
                        <Page
                          renderTextLayer={false}
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      </>
                    ))}
                  </Document>
                </div>
              </CustomModal>
            )}
            {loading ? <LoadingIndicator /> : null}
          </>
        )
      }
    />
  );
}

export default InvestmentAccountStateList;
