import React, { useState, useEffect } from 'react';
import ClearIcon from './svg/clearIcon';
import TrashIcon from './svg/trashIcon';
import useStyles from './styles';
import { TextField } from '@material-ui/core';
const Entrydigits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const shuffleds = [...Entrydigits].sort(() => Math.random() - 0.5);

function PasswordKeyboard(props) {
  const { handlerPassword } = props;
  const classes = useStyles();
  const [digits, setDigits] = useState(shuffleds);
  const [password, setPassword] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const handleDigitClick = digit => {
    if (password.length < 6) {
      setPassword(password + digit);
      handlerPassword(password + digit);
    }
  };

  const handleDelete = () => {
    setPassword(password.slice(0, -1));
    handlerPassword(password.slice(0, -1));
  };

  const handleClear = () => {
    setPassword('');
    handlerPassword('');
  };

  useEffect(() => {
    const shuffleDigits = () => {
      const shuffled = shuffleds.sort(() => Math.random() - 0.8);
      setDigits(shuffled);
    };

    const intervalId = setInterval(shuffleDigits, 90000);

    return () => {
      shuffleDigits();
      clearInterval(intervalId);
    };
  }, [digits]);

  return (
    <>
      <div className={classes.container}>
        {digits.slice(0, 9).map((digit, index) => (
          <button
            className={classes.btn}
            key={index}
            onClick={() => handleDigitClick(digit)}
          >
            {digit}
          </button>
        ))}

        <button className={classes.btn} onClick={handleClear}>
          <TrashIcon />
        </button>
        <button
          className={classes.btn}
          onClick={() => handleDigitClick(digits[9])}
        >
          {digits[9]}
        </button>

        <button className={classes.btn} onClick={handleDelete}>
          <ClearIcon />
        </button>
      </div>
    </>
  );
}

export default PasswordKeyboard;
