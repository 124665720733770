import React from 'react';

function ClearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      fill="none"
      viewBox="0 0 28 20"
    >
      <path
        fill="#686565"
        fillRule="evenodd"
        d="M19.817 12.037a.938.938 0 010 1.291.853.853 0 01-1.238 0l-1.959-2.036-1.984 2.063a.866.866 0 01-1.249 0 .946.946 0 010-1.3l1.985-2.063-1.96-2.027a.94.94 0 010-1.29.854.854 0 011.239 0L16.61 8.71l2.012-2.09a.866.866 0 011.248 0 .944.944 0 010 1.3l-2.012 2.09 1.959 2.027zM24.497.002h-15.7a.859.859 0 00-.683.255L.252 9.292a.919.919 0 00-.25.691.927.927 0 00.25.7l7.862 9.035a.875.875 0 00.62.264V20h15.763C26.43 20 28 18.373 28 16.364V3.638C28 1.63 26.431.002 24.497.002z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ClearIcon;
