import { createSelector } from 'reselect';

function selectApp(state) {
  return state.get('clientForm');
}

function createClientFormSelector(name) {
  return createSelector(selectApp, appState => appState.get(name));
}

export function getActiveFormSelector() {
  return createClientFormSelector('activeForm');
}
export function getMarriedSelector() {
  return createClientFormSelector('married');
}
export function getSocietySelector() {
  return createClientFormSelector('society');
}
export function getAttorneySelector() {
  return createClientFormSelector('attorney');
}
export function getAnnexBSelector() {
  return createClientFormSelector('annexB');
}
export function getNodeListSelector() {
  return createClientFormSelector('nodeList');
}
export function getClientPEPSelector() {
  return createClientFormSelector('clientPEP');
}
export function getSpousePEPSelector() {
  return createClientFormSelector('spousePEP');
}
export function getRelativePEPSelector() {
  return createClientFormSelector('relativePEP');
}
export function getEditionSelector() {
  return createClientFormSelector('edition');
}
export function getIsPeruvianSelector() {
  return createClientFormSelector('isPeruvian');
}
export function getMissingStepsSelector() {
  return createClientFormSelector('missingSteps');
}
