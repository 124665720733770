import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_EDUCATION_LEVEL,
  CREATE_EDUCATION_LEVEL,
  DELETE_EDUCATION_LEVEL,
  LIST_EDUCATION_LEVELS,
} from '../queries';
import {
  validationFunctionEducationLevel,
  validationSchemaEducationLevel,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function EducationLevelForm(props) {
  const { educationLevel, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateEducationLevel = useMutation(
    UPDATE_EDUCATION_LEVEL,
    getOptions({
      mutationName: 'updateEducationLevel',
      modelName: 'educationLevel',
      message: 'Nivel de instrucción actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createEducationLevel = useMutation(
    CREATE_EDUCATION_LEVEL,
    getOptions({
      mutationName: 'createEducationLevel',
      modelName: 'educationLevel',
      message: 'Nivel de instrucción creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createEducationLevelData }) {
        if (cache.data.data.ROOT_QUERY.listEducationLevels) {
          const { listEducationLevels } = cache.readQuery({
            query: LIST_EDUCATION_LEVELS,
          });
          listEducationLevels.edges.unshift({
            // Be careful with types
            __typename: 'EducationLevelTypeEdge',
            node: createEducationLevelData.createEducationLevel.educationLevel,
          });
          cache.writeQuery({
            query: LIST_EDUCATION_LEVELS,
            data: { listEducationLevels },
          });
        }
        history.push('/administrador/niveles-de-instruccion');
      },
    })
  );
  const deleteEducationLevel = useMutation(
    DELETE_EDUCATION_LEVEL,
    getOptions({
      mutationName: 'deleteEducationLevel',
      modelName: 'educationLevel',
      message: 'Nivel de instrucción eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listEducationLevels) {
          const { listEducationLevels } = cache.readQuery({
            query: LIST_EDUCATION_LEVELS,
          });
          listEducationLevels.edges = listEducationLevels.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_EDUCATION_LEVELS,
            data: { listEducationLevels },
          });
        }
        history.push('/administrador/niveles-de-instruccion');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionEducationLevel}
        validateSchema={validationSchemaEducationLevel}
        data={data}
        model={educationLevel}
        id={id}
        modelName="Niveles de instrucción"
        routeName="/administrador/niveles-de-instruccion"
        isEdit={isEdit}
        updateMutation={updateEducationLevel}
        createMutation={createEducationLevel}
        deleteMutation={deleteEducationLevel}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

EducationLevelForm.propTypes = {
  educationLevel: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

EducationLevelForm.defaultProps = {
  educationLevel: null,
  id: '',
  isEdit: false,
};

export default EducationLevelForm;
