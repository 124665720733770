import React from 'react';

import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { getAuthUser } from '../../utils/storage';

export const ROL_GROUP = {
  admin: 'admin_group',
  client: 'client_group',
};

export const GROUP_ROL = {
  admin_group: 'administrador',
  client_group: 'cliente',
};

function PrivateRoute({ component: Component, usergroup, ...rest }) {
  const authUser = getAuthUser();
  return (
    <Route
      {...rest}
      render={props =>
        authUser !== null ? (
          authUser.usergroup === usergroup ? (
            <Component {...props} />
          ) : (
            <Redirect to={`/${GROUP_ROL[authUser.usergroup]}`} />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}
PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  usergroup: PropTypes.string.isRequired,
};

export default PrivateRoute;
