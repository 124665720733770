import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_CLIENT_RELATIVE,
  CREATE_CLIENT_RELATIVE,
  UPDATE_CLIENT_RELATIVE,
  DELETE_CLIENT_RELATIVE,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import useStyles from './styles';
import SimpleForm from '../../../Forms/SimpleForm';
import LoadingIndicator from '../../../LoadingIndicator';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { LIST_DOCUMENT_TYPES } from '../../../Models/DocumentType/queries';
import { LIST_MARITAL_STATUS } from '../../../Models/MaritalStatus/queries';

export const RELATION_TYPE = [
  {
    value: 'SP',
    label: 'Cónyuge',
    enum: 'SPOUSE',
  },
  {
    value: 'PGP',
    label: 'Abuelo/a Paterno',
    enum: 'PATERNAL_GRAND_PARENT',
  },
  {
    value: 'MGP',
    label: 'Abuelo/a Materno',
    enum: 'MATERNAL_GRAND_PARENT',
  },
  {
    value: 'P',
    label: 'Padre/Madre',
    enum: 'PARENT',
  },
  {
    value: 'PIL',
    label: 'Suegro/a',
    enum: 'PARENT_IN_LAW',
  },
  {
    value: 'S',
    label: 'Hermano/a',
    enum: 'SIBLING',
  },
  {
    value: 'SIL',
    label: 'Cuñado/a',
    enum: 'SIBLING_IN_LAW',
  },
  {
    value: 'C',
    label: 'Hijo/a',
    enum: 'CHILD',
  },
  {
    value: 'F',
    label: 'Amigo/a',
    enum: 'FRIEND',
  },
  {
    value: 'O',
    label: 'Otro',
    enum: 'OTHER',
  },
];

const relativeValidationSchema = Yup.object().shape({
  typeRelation: Yup.string().required(),
  fullName: Yup.string().required(),
  documentType: Yup.string().required(),
  documentNumber: Yup.string().required(),
  birthday: Yup.string().required(),
  maritalStatus: Yup.string().required(),
});

function RelativeForm(props) {
  const { relativeData, clientId, closeForm, setCurrentRelative } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [relativeId, setRelativeId] = useState(null);
  const startRelativeData = {
    typeRelation: '',
    fullName: '',
    documentType: '',
    documentNumber: '',
    birthday: '',
    maritalStatus: '',
  };

  const { loading: documentTypeLoading, data: documentTypeData } = useQuery(
    LIST_DOCUMENT_TYPES,
  );
  const {
    error: MaritalStatusError,
    data: MaritalStatusData,
    loading: MaritalStatusLoading,
  } = useQuery(LIST_MARITAL_STATUS);

  const createRelativeMutation = useMutation(
    CREATE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'createRelative',
      modelName: 'relative',
      message: 'Se guardó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: data => {
        setRelativeId(data.id);
        setCurrentRelative(data.id);
        closeForm();
      },
    }),
  );

  const updateRelativeMutation = useMutation(
    UPDATE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'updateRelative',
      modelName: 'relative',
      message: 'Se actualizó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const deleteRelativeMutation = useMutation(
    DELETE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'deleteRelative',
      modelName: 'relative',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const data = [
    {
      isField: true,
      label: 'Parentesco',
      name: 'typeRelation',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: RELATION_TYPE,
      mapData: { value: 'value', label: 'label' },
    },
    {
      isField: true,
      label: 'Nombres Completos',
      name: 'fullName',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: true,
      label: 'Tipo de documento',
      name: 'documentType',
      inputVariant: INPUT_VARIANT.select,
      gridMD: 6,
      data: edgeToList(documentTypeData, 'listDocumentTypes'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      isField: true,
      label: 'Número de documento',
      name: 'documentNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.number,
    },
    {
      isField: true,
      label: '* Estado civil',
      name: 'maritalStatus',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: edgeToList(MaritalStatusData, 'listMaritalStatus'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      isField: true,
      label: '* Fecha de Nacimiento',
      name: 'birthday',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
    },
    {
      isField: true,
      label: 'Ocupación',
      name: 'occupation',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: true,
      label: 'Dirección',
      name: 'address',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
    },
    // {
    //   isField: false,
    //   section: 'Domicilio',
    //   fields: [
    //     {
    //       label: '* Places',
    //       name: 'places',
    //       inputVariant: INPUT_VARIANT.places,
    //       gridMD: [12, 12, 12, 12],
    //       indicators: {
    //         country: { name: 'country', label: '* País' },
    //         county: { name: 'county', label: '* Region' },
    //         city: { name: 'city', label: '* Provincia' },
    //         district: { name: 'district', label: '* Distrito' },
    //       },
    //     },
    //     {
    //       label: '* Avenida, Jirón, Calle ...',
    //       name: 'detail',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.text,
    //     },
    //   ],
    // },
  ];

  const validateRelativeData = () => {};
  const formatBeforeSubmit = v => {
    const newValues = { ...v };
    delete newValues.participations;
    delete newValues.publicFunctions;

    return { ...newValues, client: clientId };
  };

  useEffect(() => {
    if (relativeData) {
      setRelativeId(relativeData.id);
      if (relativeData.documentType?.hasOwnProperty('id')) {
        relativeData.documentType = relativeData.documentType.id;
      }
      if (relativeData.maritalStatus?.hasOwnProperty('id')) {
        relativeData.maritalStatus = relativeData.maritalStatus.id;
      }
      if (relativeData.typeRelation) {
        relativeData.typeRelation = relativeData.typeRelation;
      }
    }
  }, [relativeData]);

  if (documentTypeLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Container>
      <div className={classes.formContainer}>
        <SimpleForm
          model={relativeData}
          initialValues={startRelativeData}
          validateFunction={validateRelativeData}
          validateSchema={relativeValidationSchema}
          data={data}
          modelName="Familiar"
          hasCancel
          isEdit={!!relativeId}
          id={relativeId}
          routeName=""
          onCancel={closeForm}
          updateMutation={updateRelativeMutation}
          createMutation={createRelativeMutation}
          deleteMutation={deleteRelativeMutation}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      </div>
    </Container>
  );
}

RelativeForm.propTypes = {
  relativeData: PropTypes.shape().isRequired,
  clientId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setCurrentRelative: PropTypes.func.isRequired,
};

export default RelativeForm;
