import React, { useEffect, useState } from 'react';

// GraphQl
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

// Material resources
import {
  Grid,
  Button,
  Typography,
  InputAdornment,
  Container,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from 'formik-material-ui';
import TodayIcon from '@material-ui/icons/Today';
import MUIDataTable from 'mui-datatables';

// Components & Others
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  getOptions,
  edgeToList,
  errorMessage,
  generateColumn,
  stringToDate,
} from 'src/utils/commonFunctions';
import { useSnackbar } from 'notistack';
import Yup from 'src/utils/yup';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator';
import CustomSelect from 'src/components/FormComponents/CustomSelect';
import CustomCard from 'src/components/CustomCard';
import { LIST_FUNDS } from '../../Fund/queries';
import { LIST_CLIENT_USERS } from '../../Users/queries';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  LIST_PARTICIPANTS_BY_FUND,
  GET_RAISING_FEES,
  CREATE_SECONDARY_MARKET,
  GET_SECONDARY_MARKET,
  LIST_SECONDARY_MARKETS,
  UPDATE_SECONDARY_MARKET,
} from '../queries';
import { DatePicker } from '@material-ui/pickers';
import StepTwoForm from 'src/components/Cognito/StepTwoForm';
import SecondaryMarketCoParticipantList from './CoparticipantList';
import InputNumber from 'src/components/FormComponents/InputNumber';

// Styles & Images
import useStyles from './styles';
import NumberFormatInput from 'src/components/FormComponents/NumberFormatInput';
import NumberFormat from 'react-number-format';
import { defaultDate } from 'src/components/FormComponents/DatePicker';
import { SECONDARY_MARKET_STATES } from '..';

const vectorSum = v => {
  let totalFees = 0;
  v.forEach(i => {
    totalFees += i;
  });
  return totalFees;
};

function SecondaryMarketForm(props) {
  const { id } = props;
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [marketMetaData, setMarketMetaData] = useState(null);
  const [marketData, setMarketData] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [funds, setFunds] = useState([]);
  const [clients, setClients] = useState([]);

  const [raisingFeesMetaData, setRaisingFeesMetaData] = useState(null);
  const [raisingFeesColumns, setRaisingFeesColumns] = useState([]);
  const [raisingFeesData, setRaisingFeesData] = useState([]);
  const [raisingFeesDataMax, setRaisingFeesDataMax] = useState(0);

  const [fundSymbol, setFundSymbol] = useState('');
  const [sellerFees, setSellerFees] = useState(0);

  const [showRaisingFeesTable, setShowRaisingFeesTable] = useState(false);

  const [getRaisingFees, { loading: loadingGetRaisingFees }] = useLazyQuery(
    GET_RAISING_FEES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        if (data && data.listRaisingFees) {
          const raisings = [];
          const fees = [];
          data.listRaisingFees.forEach(item => {
            raisings.push(item.raising);
            fees.push(item.fees);
          });
          setRaisingFeesMetaData({
            raisings,
            fees,
          });
          const totalFees = vectorSum(fees);
          setRaisingFeesDataMax(totalFees);
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al obtener las cuotas por levantamiento',
          enqueueSnackbar,
        );
      },
    },
  );

  const [listParticipantsByFund, { loading: sellersLoading }] = useLazyQuery(
    LIST_PARTICIPANTS_BY_FUND,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (data && data.listParticipantsByFund) {
          const parseClients = data.listParticipantsByFund.map(item => {
            const IDs = [];
            let names = '';
            item.participants.forEach((p, index) => {
              IDs.push(p.id);
              if (!index) {
                names += `${p.user.firstName} ${p.user.lastName}`;
              } else {
                names += ` / ${p.user.firstName} ${p.user.lastName}`;
              }
            });
            return {
              ids: IDs,
              names,
            };
          });
          if (isEdit) {
            const tmpData = { ...marketMetaData };
            parseClients.some(c => {
              let search = true;
              tmpData.sellers.forEach(seller => {
                search = search && !!c.ids.find(ID => ID === seller.id);
              });
              if (search) {
                tmpData.client = c;
                return search;
              }
            });
            delete tmpData.sellers;
            getRaisingFees({
              variables: {
                coParticipantsIds: tmpData.client.ids,
                fundId: tmpData.fund.id,
              },
            });
            setMarketData(tmpData);
          }

          setClients(parseClients);
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al obtener los contratos por firmar.',
          enqueueSnackbar,
        );
      },
    },
  );

  const [
    getSecondaryMarket,
    { loading, data: SecondaryMarketData },
  ] = useLazyQuery(GET_SECONDARY_MARKET, {
    variables: {
      id,
    },
  });

  const [
    createSecondaryMarket,
    { loading: loadingCreateSecondaryMarket },
  ] = useMutation(
    CREATE_SECONDARY_MARKET,
    getOptions({
      mutationName: 'createSecondaryMarket',
      modelName: 'secondaryMarket',
      message: 'Mercado secundario creado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SECONDARY_MARKETS }],
      completeCallback: () => history.goBack(),
    }),
  );

  useEffect(() => {
    if (raisingFeesMetaData) {
      const { raisings, fees } = raisingFeesMetaData;
      const columnOptions = {
        filter: false,
        sort: true,
      };
      const feesRow = { name: 'Cuotas' };
      const salesRow = { name: 'Venta de cuotas' };
      const netRow = { name: 'Cuotas Netas' };
      const listColumns = [generateColumn('', 'name', columnOptions)];
      const totalFees = vectorSum(fees);
      let totalSales = 0;
      let totalNet = 0;
      raisings.forEach((item, index) => {
        const name = `L${item}`;
        listColumns.push(
          generateColumn(name, name, {
            ...columnOptions,
            customBodyRender: value => {
              return (
                <NumberFormat
                  displayType="text"
                  thousandSeparator
                  value={value}
                />
              );
            },
          }),
        );
        const sales = Math.round((fees[index] / totalFees) * sellerFees);
        feesRow[name] = fees[index];
        salesRow[name] = sales;
        netRow[name] = fees[index] - sales;
        totalSales += sales;
        totalNet += fees[index] - sales;
      });

      let raisingNumberID = 0;
      while (totalSales !== sellerFees) {
        const name = `L${raisings[raisingNumberID]}`;
        if (totalSales < sellerFees) {
          salesRow[name] += 1;
          netRow[name] -= 1;
          totalSales += 1;
        }
        if (totalSales > sellerFees) {
          salesRow[name] -= 1;
          netRow[name] += 1;
          totalSales -= 1;
        }
        raisingNumberID += 1;
        if (raisingNumberID === raisings.length) {
          raisingNumberID = 0;
        }
      }

      feesRow.total = totalFees;
      salesRow.total = totalSales;
      netRow.total = totalNet;

      setRaisingFeesDataMax(totalFees);

      listColumns.push(
        generateColumn('Total', 'total', {
          ...columnOptions,
          customBodyRender: value => {
            return (
              <NumberFormat
                displayType="text"
                thousandSeparator
                value={value}
              />
            );
          },
        }),
      );
      setRaisingFeesColumns(listColumns);
      setRaisingFeesData([feesRow, salesRow, netRow]);
    }
  }, [sellerFees]);

  const {
    loading: loadingFunds,
    error: errorFunds,
    data: dataFunds,
  } = useQuery(LIST_FUNDS);

  const [updateSecondaryMarket] = useMutation(
    UPDATE_SECONDARY_MARKET,
    getOptions({
      mutationName: 'updateSecondaryMarket',
      modelName: 'secondaryMarket',
      message: 'Mercado secundario actualizado.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SECONDARY_MARKETS }],
      completeCallback: () => history.goBack(),
    }),
  );

  useEffect(() => {
    if (dataFunds && dataFunds.listFunds) {
      const parseFunds = edgeToList(dataFunds, 'listFunds').map(item => {
        return {
          id: item.id,
          name: item.businessName,
          currency: item.currency.symbol,
        };
      });
      if (SecondaryMarketData && SecondaryMarketData.secondaryMarket) {
        setIsEdit(true);
        const rawData = { ...SecondaryMarketData.secondaryMarket };
        if (rawData.state !== 'P') {
          setCanEdit(false);
        }
        const tmpData = {
          fees: rawData.fee,
          sellFee: rawData.feeSaleValue,
          registrationDate: rawData.registrationDate,
          sellers: edgeToList(rawData, 'seller'),
        };
        const participants = [];
        const buyersMetadata = edgeToList(rawData, 'buyer');
        buyersMetadata.forEach(b => {
          const participantData = {
            clientID: b.client.id,
            documentNumber: b.client.user.documentNumber,
            clientName: `${b.client.user.firstName} ${b.client.user.lastName}`,
            communicationCellphone: b.client.communicationCellphone,
            communicationEmail: b.client.communicationEmail,
            participationPercentage: b.participationPercentage,
            isRepresentative: !!b.representant,
          };
          participants.push(participantData);
        });

        const rawFund = parseFunds.find(i => i.id === rawData.fund.id);

        tmpData.fund = rawFund;
        tmpData.participants = participants;

        listParticipantsByFund({
          variables: { fundId: rawFund.id },
        });
        setFundSymbol(`${tmpData.fund.currency}  `);
        setMarketMetaData(tmpData);
      }
      setFunds(parseFunds);
    }
  }, [dataFunds, SecondaryMarketData]);

  useEffect(() => {
    getSecondaryMarket();
  }, [id]);

  const validationSchema = Yup.object().shape({
    fees: Yup.number().required(),
    sellFee: Yup.number().required(),
    fund: Yup.string().required(),
    client: Yup.string().required(),
  });

  const validateFunction = values => {
    const errors = {};
    if (values.fees > raisingFeesDataMax) {
      errors.fees = `El maximo numero de cuotas permitido es ${raisingFeesDataMax}`;
    }
    if (!values.participants.length) {
      errors.participants = 'Debe agregar al menos un participante';
    } else {
      const percentages = values.participants.map(
        p => p.participationPercentage,
      );
      if (vectorSum(percentages) != 100) {
        errors.participants =
          'Los porcentajes de participación deben sumar 100%';
      } else {
        let hasRepresentant = [];
        values.participants.forEach(p => {
          if (p.isRepresentative) {
            hasRepresentant.push(true);
          }
        });
        if (!hasRepresentant.length) {
          errors.participants = 'Debe haber un participante como representante';
        } else if (hasRepresentant.length !== 1) {
          errors.participants =
            'Solo debe haber un participante como representante';
        }
      }
    }
    return errors;
  };

  const formInitialValues = {
    fees: 0,
    sellFee: 0,
    fund: '',
    client: '',
    registrationDate: defaultDate,
    participants: [],
  };

  const raisingFeesOptions = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
  };

  return loading || loadingFunds || (id && !marketData) ? (
    <LoadingIndicator />
  ) : (
    <Formik
      initialValues={id !== '' ? marketData : formInitialValues}
      validationSchema={validationSchema}
      validate={validateFunction}
      validateOnChange
      validateOnBlur
      onSubmit={(values, { setSubmitting }) => {
        const buyersIDS = values.participants.map(i => i.clientID);

        const buyersInput = values.participants.map(i => {
          return {
            client: i.clientID,
            participationPercentage: i.participationPercentage,
            representant: i.isRepresentative,
          };
        });
        const input = {
          fund: values.fund.id,
          seller: values.client.ids,
          buyer: buyersIDS,
          fee: parseInt(values.fees, 10),
          feeSaleValue: parseFloat(values.sellFee),
          registrationDate: stringToDate(values.registrationDate),
        };
        if (isEdit) {
          updateSecondaryMarket({ variables: { id, input, buyersInput } });
        } else {
          createSecondaryMarket({ variables: { input, buyersInput } });
        }

        setSubmitting(false);
      }}
    >
      {({ values, errors, submitForm, setFieldValue }) => {
        return (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  {id ? 'Editar tipo de contrato' : 'Crear tipo de contrato'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomCard
                  subtitle="Vendedores"
                  content={
                    <>
                      <Grid item xs={12} className={classes.autocomplete}>
                        <Grid item xs={6} className={classes.columnContainer}>
                          <Autocomplete
                            loading={loadingFunds}
                            loadingText="Cargando ..."
                            clearText="Borrar filtro"
                            closeText="Cerrar"
                            openText="Abrir"
                            noOptionsText="Sin coincidencias"
                            options={funds}
                            value={values.fund}
                            disabled={!canEdit}
                            getOptionLabel={option =>
                              option.name ? option.name : ''
                            }
                            onChange={(event, value) => {
                              event.preventDefault();
                              setFieldValue('fund', value);
                              setShowRaisingFeesTable(false);
                              setFieldValue('client', '');
                              if (value) {
                                listParticipantsByFund({
                                  variables: { fundId: value.id },
                                });
                                setFundSymbol(`${value.currency}  `);
                              } else {
                                setFundSymbol('');
                                setClients([]);
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Fondos"
                                name="fund"
                                placeholder="Fondos"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className={classes.autocomplete}>
                        <Grid item xs={6} className={classes.columnContainer}>
                          <Autocomplete
                            loading={sellersLoading}
                            loadingText="Cargando ..."
                            clearText="Borrar filtro"
                            closeText="Cerrar"
                            openText="Abrir"
                            noOptionsText="Sin coincidencias"
                            options={clients}
                            value={values.client}
                            disabled={!clients.length || !canEdit}
                            getOptionLabel={option =>
                              option.names ? option.names : ''
                            }
                            onChange={(event, value) => {
                              event.preventDefault();
                              setFieldValue('client', value);
                              setShowRaisingFeesTable(false);
                              if (value) {
                                getRaisingFees({
                                  variables: {
                                    coParticipantsIds: value.ids,
                                    fundId: values.fund.id,
                                  },
                                });
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label="Clientes"
                                name="client"
                                placeholder="Clientes"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6} className={classes.columnContainer}>
                          <NumberFormatInput
                            label="Número de cuotas"
                            name="fees"
                            variant="outlined"
                            fullWidth
                            disabled={!canEdit}
                            formatprops={{
                              thousandSeparator: true,
                              decimalScale: 0,
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                        {raisingFeesDataMax > 0 ? (
                          <Grid item xs={6} className={classes.columnContainer}>
                            <p>
                              {`El maximo numero de cuotas permitido es ${raisingFeesDataMax}`}
                            </p>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={6} className={classes.columnContainer}>
                          <NumberFormatInput
                            label="Valor cuota de venta"
                            name="sellFee"
                            variant="outlined"
                            fullWidth
                            disabled={!canEdit}
                            formatprops={{
                              thousandSeparator: true,
                              decimalScale: 2,
                              fixedDecimalScale: true,
                              prefix: fundSymbol,
                              allowNegative: false,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={6} className={classes.columnContainer}>
                          <DatePicker
                            autoOk
                            fullWidth
                            format="YYYY-MM-DD"
                            name="registrationDate"
                            label="Fecha de registro"
                            emptyLabel="Fecha de registro"
                            inputVariant="outlined"
                            color="secondary"
                            disabled={!canEdit}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <TodayIcon color="secondary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  }
                />
              </Grid>
              <SecondaryMarketCoParticipantList
                data={values.participants}
                readOnly={!canEdit}
              />
              <Grid container justify="space-between">
                <Grid item>
                  {canEdit && (
                    <Button
                      onClick={() => history.goBack()}
                      variant="contained"
                      color="primary"
                    >
                      Cancelar
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        onClick={() => {
                          setShowRaisingFeesTable(true);
                          setSellerFees(parseFloat(values.fees));
                        }}
                        variant="contained"
                        color="secondary"
                        disabled={!values.client}
                      >
                        {showRaisingFeesTable
                          ? 'Actualizar cuotas por levantamineto'
                          : 'Cuotas por levantamineto'}
                      </Button>
                    </Grid>{' '}
                    <Grid item>
                      {canEdit ? (
                        <Button
                          onClick={submitForm}
                          variant="contained"
                          color="secondary"
                          disabled={!canEdit}
                        >
                          {id !== '' ? 'Guardar' : 'Crear'}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => history.goBack()}
                          variant="contained"
                          color="primary"
                        >
                          Atras
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {showRaisingFeesTable &&
              (loadingGetRaisingFees ? (
                <LoadingIndicator />
              ) : (
                <div className={classes.raisingFees}>
                  <CustomCard title="Cuotas por levantamiento" disableFlex>
                    <Grid container>
                      <Grid item md={2} xs={12}>
                        <p>Monto a vender:</p>
                      </Grid>
                      <Grid item md={10} xs={12}>
                        <p>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator
                            decimalScale={2}
                            fixedDecimalScale
                            prefix={fundSymbol}
                            value={values.fees * values.sellFee}
                          />
                        </p>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <p>Cuotas a vender:</p>
                      </Grid>
                      <Grid item md={10} xs={12}>
                        <p>
                          <NumberFormat
                            displayType="text"
                            thousandSeparator
                            value={values.fees}
                          />
                        </p>
                      </Grid>
                    </Grid>
                    <MUIDataTable
                      data={raisingFeesData}
                      columns={raisingFeesColumns}
                      options={raisingFeesOptions}
                    />
                  </CustomCard>
                </div>
              ))}
          </>
        );
      }}
    </Formik>
  );
}

SecondaryMarketForm.propTypes = {
  id: PropTypes.string,
};

SecondaryMarketForm.defaultProps = {
  id: '',
};
export default SecondaryMarketForm;

export function SecondaryMarketEdit() {
  const { id } = useParams();

  return <SecondaryMarketForm id={id} />;
}
