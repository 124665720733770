import React from 'react';
import logoCoreNegativo from './LogoCoreNegativo1.png';
import CoreA1 from '../../svg/CoreA1_positivo.png';
function LogoCoreCapitalNegativo() {
  return (
    <div
      style={{
        borderRadius: '5px',
        border: '0.5px solid #BEBEBE',
      }}
    >
      <div
        style={{
          padding: '3px',
        }}
      >
        <img src={CoreA1} width="97" height="26" alt="CORE Capital SAF" />
      </div>
    </div>
  );
}

export default LogoCoreCapitalNegativo;
