// GraphQl
import { gql } from 'apollo-boost';

export const LIST_FUNDS = gql`
  {
    listFunds {
      edges {
        node {
          id
          businessName
          ruc
          address
          nominalFeeValue
          state
          hasClasses
          logo
          currency {
            id
            symbol
          }
        }
      }
    }
  }
`;

export const LIST_BONDS = gql`
  {
    listBonds {
      edges {
        node {
          id
          name
          program
          release
          className
          state
        }
      }
    }
  }
`;

export const GET_FUND = gql`
  query getFund($id: ID!) {
    fund(id: $id) {
      id
      logo
      state
      hasClasses
      fundType
      incomeType
      tirCalculus
      fixedRate
      ruc
      businessName
      acronym
      address
      subscriptionRight
      performanceGoal
      currency {
        id
        name
      }
      category {
        id
        name
      }
      fundCapitalGoal
      phoneNumber
      maximumCoParticipants
      allocationMechanisms
      startRegulationAgreementDate
      endRegulationAgreementDate
      nominalFeeValue
      subscribedPaidFees
      lifetime
      minimumInvestment
      coParticipation
      exchangeRateRisk
      assemblyDelegationDate
      stockMarketRepresentative
      createdAt
      endedAt
      accounts {
        edges {
          node {
            id
          }
        }
      }
      instrumentfundSet {
        edges {
          node {
            id
            instrument {
              id
              name
            }
            minimum
            maximum
          }
        }
      }
    }
  }
`;

export const CREATE_FUND = gql`
  mutation createFund($input: FundInput!) {
    createFund(input: $input) {
      fund {
        id
        logo
        state
        hasClasses
        fundType
        incomeType
        tirCalculus
        fixedRate
        ruc
        businessName
        acronym
        address
        subscriptionRight
        performanceGoal
        currency {
          id
          name
        }
        category {
          id
          name
        }
        fundCapitalGoal
        phoneNumber
        maximumCoParticipants
        allocationMechanisms
        startRegulationAgreementDate
        endRegulationAgreementDate
        nominalFeeValue
        subscribedPaidFees
        lifetime
        minimumInvestment
        coParticipation
        exchangeRateRisk
        assemblyDelegationDate
        stockMarketRepresentative
        createdAt
        endedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_FUND = gql`
  mutation updateFund($id: ID!, $input: FundInput!) {
    updateFund(id: $id, input: $input) {
      fund {
        id
        logo
        state
        hasClasses
        fundType
        incomeType
        tirCalculus
        fixedRate
        ruc
        businessName
        acronym
        address
        subscriptionRight
        performanceGoal
        currency {
          id
          name
        }
        category {
          id
          name
        }
        fundCapitalGoal
        phoneNumber
        maximumCoParticipants
        allocationMechanisms
        startRegulationAgreementDate
        endRegulationAgreementDate
        nominalFeeValue
        subscribedPaidFees
        lifetime
        minimumInvestment
        coParticipation
        exchangeRateRisk
        assemblyDelegationDate
        stockMarketRepresentative
        createdAt
        endedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_FUND = gql`
  mutation deleteFund($id: ID!) {
    deleteFund(id: $id) {
      fund {
        id
        logo
        state
        hasClasses
        fundType
        incomeType
        tirCalculus
        fixedRate
        ruc
        businessName
        acronym
        address
        subscriptionRight
        performanceGoal
        currency {
          id
          name
        }
        category {
          id
          name
        }
        fundCapitalGoal
        phoneNumber
        maximumCoParticipants
        allocationMechanisms
        startRegulationAgreementDate
        endRegulationAgreementDate
        nominalFeeValue
        subscribedPaidFees
        lifetime
        minimumInvestment
        coParticipation
        exchangeRateRisk
        assemblyDelegationDate
        stockMarketRepresentative
        createdAt
        endedAt
      }
      errors {
        field
        message
      }
    }
  }
`;

// INSTRUMENTS
export const GET_INSTRUMENT_FUND = gql`
  query instrumentFund($id: ID!) {
    instrumentFund(id: $id) {
      id
      instrument {
        id
        name
      }
      maximum
      minimum
    }
  }
`;

export const CREATE_INSTRUMENT_FUND = gql`
  mutation createInstrumentFund($input: InstrumentFundInput!) {
    createInstrumentFund(input: $input) {
      instrumentFund {
        id
        instrument {
          id
          name
        }
        maximum
        minimum
      }
    }
  }
`;

export const UPDATE_INSTRUMENT_FUND = gql`
  mutation updateInstrumentFund($id: ID!, $input: InstrumentFundInput!) {
    updateInstrumentFund(id: $id, input: $input) {
      instrumentFund {
        id
        instrument {
          id
          name
        }
        maximum
        minimum
      }
    }
  }
`;

export const DELETE_INSTRUMENT_FUND = gql`
  mutation deleteInstrumentFund($id: ID!) {
    deleteInstrumentFund(id: $id) {
      instrumentFund {
        id
        instrument {
          id
          name
        }
        maximum
        minimum
      }
      errors {
        field
        message
      }
    }
  }
`;
