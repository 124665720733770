/*
 * HomePage
 */

import React from 'react';

// Material Resources

import { Grid } from '@material-ui/core';

// Components & Others

// Styles & Images
import useStyles from './styles';
import blueLogo from '../../images/brand/CoreCapitalSAFI_LogoRGB_positivo.png';

function DashboardPage() {
  const classes = useStyles();

  return (
    <div className={classes.dashboardPage}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <img src={blueLogo} alt="logo" className={classes.logo} />
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardPage;
