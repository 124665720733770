import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { Tooltip, Button, Container } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomCard from '../../../../CustomCard';
import CustomModal from '../../../../CustomModal';
import StepTwoForm from '../../../StepTwoForm';
import { textLabels } from '../../../../../translations/components/MUIDataTable';
import useStyles from './styles';
import { generateColumn } from 'src/utils/commonFunctions';
import CustomOptions from 'src/components/CustomOptions';

function CoParticipantList(props) {
  const { setParticipants, data } = props;
  const styles = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [isEditParticipant, setIsEditParticipant] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [participantsList, setParticipantsList] = useState(data);
  const [mainClient, setMainClientID] = useState(data[0]);
  const [lenList, setLenList] = useState(1);
  const [lastEditID, setLastEditID] = useState('');
  const { setFieldValue } = useFormikContext();

  const handleDeleteRow = id => {
    const exists = participantsList.find(p => p.clientID === id);
    if (exists || currentParticipant) {
      const index = participantsList.findIndex(v => v.clientID === id);
      participantsList.splice(index, 1);
      setParticipantsList([...participantsList]);
    }
  };

  const columns = [
    generateColumn('Nombre', 'clientName'),
    generateColumn('Porcentaje de participación', 'participationPercentage', {
      sort: false,
      filter: false,
      customBodyRender: value => `${value} %`,
    }),
    generateColumn('Representante', 'isRepresentative', {
      sort: true,
      filter: false,
      customBodyRender: value => (
        <CheckCircleIcon color={value ? 'secondary' : 'disabled'} />
      ),
    }),
    generateColumn('Opciones', 'clientID', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        const id = value;
        return mainClient.clientID !== id ? (
          <CustomOptions
            id={id}
            model="Participante"
            options={['odelete']}
            handleDelete={() => {
              handleDeleteRow(id);
            }}
          />
        ) : (
          <></>
        );
      },
    }),
  ];

  const sumPercentage = list => {
    const percentage = list.reduce((prev, curr) => {
      return prev + curr.participationPercentage;
    }, 0);
    return percentage;
  };

  const sumPercentageExcluded = (list, idExcluded) => {
    let sum = 0;
    for (const participant of list) {
      if (participant.clientID != idExcluded) {
        sum += participant.participationPercentage;
      }
    }
    return sum;
  };

  const roundToTwo = num => {
    return +(Math.round(num + 'e+2') + 'e-2');
  };

  const updatePercentage = () => {
    const newLenList = participantsList.length;
    const sumList = sumPercentage(participantsList);
    setLenList(newLenList);
    if (newLenList == 1) {
      participantsList[0].participationPercentage = 100;
      return;
    }
    if (newLenList < lenList) {
      if (newLenList > 1 && sumList > 0) {
        for (let i = 0; i < newLenList; i++) {
          participantsList[i].participationPercentage *= 100 / sumList;
          participantsList[i].participationPercentage = roundToTwo(
            participantsList[i].participationPercentage
          );
        }
      }
    } else {
      if (lastEditID) {
        const index = participantsList.findIndex(
          v => v.clientID === lastEditID
        );
        const participantEditPercentage =
          participantsList[index].participationPercentage;
        const sumExc = sumPercentageExcluded(participantsList, lastEditID);
        for (let i = 0; i < newLenList; i++) {
          if (i != index) {
            participantsList[i].participationPercentage *=
              (100 - participantEditPercentage) / sumExc;
            participantsList[i].participationPercentage = roundToTwo(
              participantsList[i].participationPercentage
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    updatePercentage();
    setParticipants(participantsList);
  }, [participantsList]);

  const addParticipant = () => {
    setIsEditParticipant(false);
    setCurrentParticipant(null);
    setFieldValue('documentNumber', null);
    setFieldValue('documentType', null);
    setShowModal(true);
  };

  const options = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Agregar copartícipe">
        <Button variant="contained" color="secondary" onClick={addParticipant}>
          Agregar copartícipe
        </Button>
      </Tooltip>
    ),
    onRowClick: rowData => {
      const [participant = {}] = participantsList.filter(
        v => v.clientName === rowData[0]
      );
      Object.keys(participant).map(k => setFieldValue(k, participant[k]));
      setCurrentParticipant(participant);
      setIsEditParticipant(true);
      setShowModal(true);
    },
  };

  const setParticipantInList = participantData => {
    setLastEditID(participantData.clientID);
    const exists = participantsList.find(
      p => p.clientID === participantData.clientID
    );
    if (exists || currentParticipant) {
      const index = participantsList.findIndex(
        v => v.clientID === participantData.clientID
      );
      // Replacing existing participant with new information
      participantsList.splice(index, 1, participantData);
      setParticipantsList([...participantsList]);
    } else {
      setParticipantsList([...participantsList, participantData]);
    }
    setShowModal(false);
  };

  return (
    <>
      <CustomCard disableFlex>
        <MUIDataTable
          data={participantsList}
          columns={columns}
          options={options}
        />
      </CustomCard>
      <CustomModal
        fullWidth
        maxWidth="lg"
        showModal={showModal}
        title="Participante"
        onClosePress={() => setShowModal(false)}
        disableFlex
      >
        <Container className={styles.container}>
          <StepTwoForm
            setParticipantInList={setParticipantInList}
            data={participantsList}
            isEdit={isEditParticipant}
            closeForm={() => setShowModal(false)}
          />
        </Container>
      </CustomModal>
    </>
  );
}

CoParticipantList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setParticipants: PropTypes.func.isRequired,
};

export default CoParticipantList;
