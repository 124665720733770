import React, { useState } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';
import { TextField } from 'formik-material-ui';

// Styles & Images
import CustomSelect from '../CustomSelect';

function SelectOtherInput(props) {
  const {
    id,
    disabled,
    name,
    nameText,
    label,
    labelText,
    data,
    mapData,
    gridMD,
    error,
    defaultOther,
  } = props;
  const [inSelect, setInSelect] = useState(defaultOther);

  function handleChange(event) {
    if (event.target.value === 'other') setInSelect(false);
    else setInSelect(true);
  }
console.log("name  SelectOtherInput",name)
  return (
    <>
      <Grid item md={gridMD[0]} xs={12}>
        <CustomSelect
          name={name}
          label={label}
          disabled={disabled}
          variant="outlined"
          id={id}
          error={error}
          data={data ? data : []}
          mapData={mapData}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={gridMD[1]} xs={12}>
        <TextField
          name={nameText}
          disabled={inSelect || disabled}
          label={labelText}
          variant="outlined"
          fullWidth
        />
      </Grid>
    </>
  );
}
SelectOtherInput.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameText: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  gridMD: PropTypes.arrayOf(PropTypes.number),
  defaultOther: PropTypes.bool,
  disabled: PropTypes.bool,
};
SelectOtherInput.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  gridMD: [3, 3],
  defaultOther: true,
  disabled: false,
};

export default SelectOtherInput;
