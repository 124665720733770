/*
 * HomePage
 */

import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { LIST_PENDINGS } from 'src/containers/ClientPage/queries';
// Material Resources

// Components & Others

// Styles & Images
import useStyles from './styles';
import NotificationsIcon from '../svg/notificationsIcon';
import getClientMode from 'src/store/utils-store/selectors';
import { getClientSelector } from 'src/store/sharedStore/selectors';

function InvestorNotifications() {
  const mockNotifications = [
    {
      type: 'signing',
      title: 'Documento Disponible para Firmar',
      content:
        'Tienes un nuevo documento pendiente de firma. Por favor, revisa y firma el documento en la sección de Documentos para mantener tus registros al día.',
    },
    {
      type: 'files',
      title: 'Archivo Recientemente Cargado',
      content:
        'Tienes un nuevo archivo cargado. Por favor, revísalo en la sección de Repositorios.',
    },
  ];
  const classes = useStyles();
  const client = useSelector(getClientSelector());
  const modeClient = useSelector(getClientMode());
  const mainDiv = modeClient ? classes.mainDivDark : classes.mainDiv;
  const itemStyle = modeClient ? classes.itemDark : classes.item;
  const titleStyle = modeClient ? classes.titleDark : classes.title;
  const contentStyle = modeClient ? classes.contentDark : classes.content;
  const titleHStyle = modeClient ? classes.title1Dark : classes.title1;

  const [hasSigning, setHasSigning] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  // const clientId = localStorage.getItem('id');
  const { data } = useQuery(LIST_PENDINGS, {
    variables: { id: client?.id },
  });

  useEffect(() => {
    if (data) {
      setHasSigning(data.hasPendingSignatures);
      setHasFiles(data.hasNewFiles);
    }
  }, [data]);

  return (
    <>
      {(hasSigning || hasFiles) && (
        <>
          <div className={classes.layout}>
            <div className={classes.notificationsDiv}>
              <NotificationsIcon bg={modeClient ? '#ECECEC' : '#292D32'} />
              <p className={titleHStyle}>Notificaciones</p>
            </div>
            <div className={mainDiv}>
              <div className={classes.DIV}>
                {mockNotifications.length > 0 &&
                  hasSigning &&
                  mockNotifications.map(item => {
                    return (
                      item.type == 'signing' && (
                        <>
                          <div className={itemStyle}>
                            <p className={titleStyle}>{item.title}</p>
                            <p className={contentStyle}> {item.content}</p>
                          </div>
                        </>
                      )
                    );
                  })}
                {mockNotifications.length > 0 &&
                  hasFiles &&
                  mockNotifications.map(item => {
                    return (
                      item.type == 'files' && (
                        <>
                          <div className={itemStyle}>
                            <p className={titleStyle}>{item.title}</p>
                            <p className={contentStyle}> {item.content}</p>
                          </div>
                        </>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
        </>
      )}
      {!hasSigning && !hasFiles && (
        <>
          <div className={classes.layout2}>
            <div className={classes.notificationsDiv}>
              <NotificationsIcon bg={modeClient ? '#fff' : '#000'} />
              <p className={titleHStyle}>Notificaciones</p>
            </div>
            <div className={mainDiv}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <p className={contentStyle}>No hay notificaciones!</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InvestorNotifications;
