// GraphQl
import { gql } from 'apollo-boost';

export const LIST_RAISING_FUND = gql`
  query getFundRaisings($id: ID!) {
    fund(id: $id) {
      id
      raisingSet {
        edges {
          node {
            id
            number
            acronym
            targetCapitalIncrease
            valueFee
            valueFeeNominal
            fees
            missingFees
            minimumInvestment
            classes {
              edges {
                node {
                  id
                  name
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_CLASS_FUND = gql`
  {
    listClassFund {
      edges {
        node {
          id
          name
          state
        }
      }
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export const GET_RAISING_AND_FUND_INFORMATION = gql`
  query getRaisingAndFundInformation($raisingId: ID) {
    raising(id: $raisingId) {
      id
      classes {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      acronym
      valueFee
      valueFeeNominal
      targetCapitalIncrease
      minimumInvestment
      initialParticipationOrderReceptionDate
      lastParticipationOrderReceptionDate
      initialFeePaymentDate
      lastFeePaymentDate
      dateInitialContract
      dateEndContract
      dateEmissionFee
      dateInitialCertificateSent
      dateEndCertificateSent
      wheelSet {
        edges {
          node {
            id
            dateInitial
            dateEnd
            dateAward
          }
        }
      }
      fund {
        id
        hasClasses
        businessName
        fundType
        minimumInvestment
        currency {
          code
          symbol
        }
        funddocumentSet {
          edges {
            node {
              fileType
              file
              date
              state
            }
          }
        }
      }
    }
  }
`;

export const GET_RAISING_FUND = gql`
  query getRaising($id: ID!) {
    raising(id: $id) {
      id
      classes {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      acronym
      number
      targetCapitalIncrease
      valueFee
      valueFeeNominal
      minimumInvestment
      dateInitialCertificateSent
      dateEndCertificateSent
      dateEmissionFee
      initialFeePaymentDate
      lastFeePaymentDate
      raisingNoticeDate
      initialParticipationOrderReceptionDate
      lastParticipationOrderReceptionDate
      initialFeeAdjudicationDate
      finalFeeAdjudicationDate
      valorizationDate
      preferredSubscriptionDate
      importantFactDate
      dateInitialContract
      dateEndContract
      fees
      missingFees
    }
  }
`;

export const CREATE_RAISING_FUND = gql`
  mutation createRaising($input: RaisingInput!) {
    createRaising(input: $input) {
      raising {
        id
        classes {
          edges {
            node {
              id
              name
              state
            }
          }
        }
        acronym
        number
        targetCapitalIncrease
        valueFee
        valueFeeNominal
        minimumInvestment
        dateInitialCertificateSent
        dateEndCertificateSent
        dateEmissionFee
        initialFeePaymentDate
        lastFeePaymentDate
        raisingNoticeDate
        initialParticipationOrderReceptionDate
        lastParticipationOrderReceptionDate
        initialFeeAdjudicationDate
        finalFeeAdjudicationDate
        valorizationDate
        preferredSubscriptionDate
        importantFactDate
        dateInitialContract
        dateEndContract
        fees
        missingFees
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_RAISING_FUND = gql`
  mutation updateRaising($id: ID!, $input: RaisingInput!) {
    updateRaising(id: $id, input: $input) {
      raising {
        id
        classes {
          edges {
            node {
              id
              name
              state
            }
          }
        }
        number
        acronym
        targetCapitalIncrease
        valueFee
        valueFeeNominal
        minimumInvestment
        dateInitialCertificateSent
        dateEndCertificateSent
        dateEmissionFee
        initialFeePaymentDate
        lastFeePaymentDate
        raisingNoticeDate
        initialParticipationOrderReceptionDate
        lastParticipationOrderReceptionDate
        initialFeeAdjudicationDate
        finalFeeAdjudicationDate
        valorizationDate
        preferredSubscriptionDate
        importantFactDate
        dateInitialContract
        dateEndContract
        fees
        missingFees
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_RAISING_FUND = gql`
  mutation deleteRaising($id: ID!) {
    deleteRaising(id: $id) {
      raising {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const RAISING_PREVIEW = gql`
  query raisingNotice($id: ID, $isAlternative: Boolean) {
    raisingNotice(id: $id, isAlternative: $isAlternative) {
      pdf
      filename
    }
  }
`;

export const SEND_QUOTA_VALORIZATION_NOTICE = gql`
  mutation sendQuotaValorizationNotice(
    $raisingId: ID!
    $recipients: [RecipientEmailInput]
  ) {
    sendQuotaValorizationNotice(
      raisingId: $raisingId
      recipients: $recipients
    ) {
      sent
    }
  }
`;

export const GET_FUND = gql`
  query getFund($id: ID!) {
    fund(id: $id) {
      id
      logo
      state
      hasClasses
      fundType
      ruc
      businessName
      acronym
      address
      subscriptionRight
      performanceGoal
      currency {
        id
        name
      }
      category {
        id
        name
      }
      fundCapitalGoal
      phoneNumber
      maximumCoParticipants
      allocationMechanisms
      startRegulationAgreementDate
      endRegulationAgreementDate
      nominalFeeValue
      subscribedPaidFees
      lifetime
      minimumInvestment
      coParticipation
      exchangeRateRisk
      createdAt
      endedAt
      accounts {
        edges {
          node {
            id
          }
        }
      }
      instrumentfundSet {
        edges {
          node {
            id
            instrument {
              id
              name
            }
            minimum
            maximum
          }
        }
      }
    }
  }
`;
