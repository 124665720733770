import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import { UPDATE_BANK, CREATE_BANK, DELETE_BANK, LIST_BANKS } from '../queries';
import { validationFunctionBank, validationSchemaBank } from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function BankForm(props) {
  const { bank, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateBank = useMutation(
    UPDATE_BANK,
    getOptions({
      mutationName: 'updateBank',
      modelName: 'bank',
      message: 'Banco actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createBank = useMutation(
    CREATE_BANK,
    getOptions({
      mutationName: 'createBank',
      modelName: 'bank',
      message: 'Banco creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createBankData }) {
        if (cache.data.data.ROOT_QUERY.listBank) {
          const { listBank } = cache.readQuery({ query: LIST_BANKS });
          listBank.edges.unshift({
            // Be careful with types
            __typename: 'BankTypeEdge',
            node: createBankData.createBank.bank,
          });
          cache.writeQuery({
            query: LIST_BANKS,
            data: { listBank },
          });
        }
        history.push('/administrador/bancos');
      },
    })
  );
  const deleteBank = useMutation(
    DELETE_BANK,
    getOptions({
      mutationName: 'deleteBank',
      message: 'Banco eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listBank) {
          const { listBank } = cache.readQuery({ query: LIST_BANKS });
          listBank.edges = listBank.edges.filter(e => e.node.id !== id);
          cache.writeQuery({
            query: LIST_BANKS,
            data: { listBank },
          });
        }
        history.push('/administrador/bancos');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionBank}
        validateSchema={validationSchemaBank}
        data={data}
        model={bank}
        id={id}
        modelName="Bancos"
        routeName="/administrador/bancos"
        isEdit={isEdit}
        updateMutation={updateBank}
        createMutation={createBank}
        deleteMutation={deleteBank}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

BankForm.propTypes = {
  bank: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

BankForm.defaultProps = {
  bank: null,
  id: '',
  isEdit: false,
};

export default BankForm;
