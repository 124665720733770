import React, { useState, useEffect } from 'react';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import Yup from '../../../../utils/yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_BANK_ACCOUNT_CLIENT,
  CREATE_BANK_ACCOUNT_CLIENT,
  DELETE_BANK_ACCOUNT_CLIENT,
  LIST_BANK_ACCOUNTS_CLIENT,
} from '../queries';
import { validationFunctionBankAccount } from './validation';
import { FORM_OPTIONS } from '../../constants';
import {
  edgeToList,
  parseEquivalent,
  getOptions,
} from '../../../../utils/commonFunctions';
import { LIST_BANKS } from '../../../Models/Bank/queries';
import { LIST_ACCOUNT_TYPES } from '../../../Models/AccountType/queries';
import { LIST_CURRENCIES } from '../../../Models/Currency/queries';
import { LIST_FUNDS } from '../../../Models/Fund/queries';
import { INPUT_VARIANT } from 'src/components/Forms/StepsForm/fieldsSelector';

const startValues = {
  bank: '',
  currency: '',
  accountType: '',
  accountNumber: '',
  cci: '',
  aba: '',
  swift: '',
  country: '',
  jointAccount: 'true',
  fund: 'availableForAllFunds',
  observations: '',
  otherBank: '',
};

function BankDataBankAccountForm(props) {
  const { bankAccount, id, isEdit, afterEditCreate, clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isPeru, setIsPeru] = useState(true);
  const [fundList, setFundList] = useState([]);
  const [defaultOther, setDefaultOther] = useState(true);

  const updateBankAccount = useMutation(
    UPDATE_BANK_ACCOUNT_CLIENT,
    getOptions({
      mutationName: 'updateBankAccountClient',
      modelName: 'bankAccountClient',
      message: 'Cuenta bancaria actualizada con éxito.',
      completeCallback: () => afterEditCreate(),
      enqueueSnackbar,
    }),
  );
  const createBankAccount = useMutation(
    CREATE_BANK_ACCOUNT_CLIENT,
    getOptions({
      mutationName: 'createBankAccountClient',
      modelName: 'bankAccountClient',
      message: 'Cuenta bancaria creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createBankAccountClientData }) {
        if (
          cache.data.data.ROOT_QUERY[
            `listBankAccountClient({"client":"${clientID}"})`
          ]
        ) {
          const { listBankAccountClient } = cache.readQuery({
            query: LIST_BANK_ACCOUNTS_CLIENT,
            variables: { id: clientID },
          });
          listBankAccountClient.edges.unshift({
            // Be careful with types
            __typename: 'BankAccountClientTypeEdge',
            node:
              createBankAccountClientData.createBankAccountClient
                .bankAccountClient,
          });
          cache.writeQuery({
            query: LIST_BANK_ACCOUNTS_CLIENT,
            variables: { id: clientID },
            data: { listBankAccountClient },
          });
        }
        afterEditCreate();
      },
    }),
  );
  const deleteBankAccount = useMutation(
    DELETE_BANK_ACCOUNT_CLIENT,
    getOptions({
      mutationName: 'deleteBankAccountClient',
      modelName: 'bankAccountClient',
      message: 'Cuenta bancaria eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (
          cache.data.data.ROOT_QUERY[
            `listBankAccountClient({"client":"${clientID}"})`
          ]
        ) {
          const { listBankAccountClient } = cache.readQuery({
            query: LIST_BANK_ACCOUNTS_CLIENT,
            variables: { id: clientID },
          });
          listBankAccountClient.edges = listBankAccountClient.edges.filter(
            e => e.node.id !== id,
          );
          cache.writeQuery({
            query: LIST_BANK_ACCOUNTS_CLIENT,
            variables: { id: clientID },
            data: { listBankAccountClient },
          });
        }
      },
    }),
  );

  // SELECT QUERIES
  const {
    loading: BanksLoading,
    error: BanksError,
    data: BanksData,
  } = useQuery(LIST_BANKS);

  const {
    loading: AccountTypesLoading,
    error: AccountTypesError,
    data: AccountTypesData,
  } = useQuery(LIST_ACCOUNT_TYPES);
  const {
    loading: CurrenciesLoading,
    error: CurrenciesError,
    data: CurrenciesData,
  } = useQuery(LIST_CURRENCIES);

  const {
    loading: FundsLoading,
    error: FundsError,
    data: FundsData,
  } = useQuery(LIST_FUNDS);

  if (BanksError) {
    console.error('Client board bank data - Banks', BanksError);
  }
  if (AccountTypesError) {
    console.error('Client board bank data - Accounts', AccountTypesError);
  }
  if (CurrenciesError) {
    console.error('Client board bank data - Currencies', CurrenciesError);
  }
  if (FundsError) {
    console.error('Client board bank data - Funds', FundsError);
  }

  useEffect(() => {
    if (FundsData) {
      const list = edgeToList(FundsData, 'listFunds');
      list.unshift({
        id: 'availableForAllFunds',
        businessName: 'Todos los fondos',
      });
      setFundList(list);
    }
  }, [FundsData]);

  useEffect(() => {
    if (bankAccount) {
      if (bankAccount.otherBank && bankAccount.otherBank !== '') {
        setDefaultOther(false);
      }
    }
  }, [bankAccount]);

  const data = [
    {
      label: '* País',
      name: 'country',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.country,
      checkPeru: val => setIsPeru(val),
    },
    {
      label: '* Banco',
      name: 'bank',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(BanksData, 'listBank'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: '* Tipo de cuenta',
      name: 'accountType',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(AccountTypesData, 'listAccountType'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: '* Moneda',
      name: 'currency',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(CurrenciesData, 'listCurrencies'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: '* Número de cuenta',
      name: 'accountNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: '* Código de cuenta interbancario',
      name: 'cci',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: `${isPeru ? '' : '* '}Swift (sólo bancos fuera del Perú)`,
      name: 'swift',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: `${isPeru ? '' : '* '}ABA (sólo bancos fuera del Perú)`,
      name: 'aba',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: '* ¿Es una cuenta mancomunada?',
      name: 'jointAccount',
      gridMD: 6,
      data: FORM_OPTIONS.yesNo,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
    },
    {
      label: '* Fondo de depósito',
      name: 'fund',
      gridMD: 6,
      data: fundList,
      mapData: { value: 'id', label: 'businessName' },
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
    },
  ];

  if (!isPeru) {
    data[1] = {
      label: 'Banco',
      name: 'bank',
      labelText: 'Otro banco',
      nameText: 'otherBank',
      inputVariant: INPUT_VARIANT.otherSelect,
      gridMD: [6, 6],
      data: edgeToList(BanksData, 'listBank').concat([
        { id: 'other', name: 'Otro  banco' },
      ]),
      mapData: { value: 'id', label: 'name' },
      defaultOther,
    };
  }

  function formatBeforeSubmit(values) {
    const { fund, ...newValues } = values;
    newValues.jointAccount = parseEquivalent(newValues.jointAccount);
    const formatValues = {};
    formatValues.client = clientID;

    if (fund === 'availableForAllFunds') {
      formatValues.availableForAllFunds = true;
      formatValues.fund = null;
    } else {
      formatValues.availableForAllFunds = false;
      formatValues.fund = fund;
    }

    if (newValues.otherBank !== '') {
      newValues.bank = null;
    }
    formatValues.account = newValues;

    return formatValues;
  }
  const validationSchemaBankAccount = Yup.object().shape({
    bank: Yup.string().required(),
    currency: Yup.string().required(),
    accountType: Yup.string().required(),
    accountNumber: Yup.string().required(),
    cci: Yup.string().required(),
    country: Yup.string().required(),
    jointAccount: Yup.string().required(),
    fund: Yup.string().required(),
    swift: Yup.string().when({
      is: () => !isPeru,
      then: Yup.string().required(
        'Este campo es requerido para cuentas extranjeras',
      ),
    }),
    aba: Yup.string().when({
      is: () => !isPeru,
      then: Yup.string().required(
        'Este campo es requerido para cuentas extranjeras',
      ),
    }),
    otherBank: Yup.string().when('bank', {
      is: 'other',
      then: Yup.string().required(),
    }),
  });

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionBankAccount}
        validateSchema={validationSchemaBankAccount}
        data={data}
        model={bankAccount}
        id={id}
        modelName="Cuenta bancaria"
        routeName=""
        isEdit={isEdit}
        hasCancel
        canBeDeleted={false}
        onCancel={afterEditCreate}
        updateMutation={updateBankAccount}
        createMutation={createBankAccount}
        deleteMutation={deleteBankAccount}
        formatBeforeSubmit={formatBeforeSubmit}
        isStepForm
      />
    </>
  );
}

BankDataBankAccountForm.propTypes = {
  bankAccount: PropTypes.shape(),
  id: PropTypes.string,
  clientID: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  afterEditCreate: PropTypes.func.isRequired,
};

BankDataBankAccountForm.defaultProps = {
  bankAccount: null,
  id: '',
  isEdit: false,
};

export default BankDataBankAccountForm;
