import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  fileIcon: {
    fontSize: '7rem',
    paddingBottom: '1rem',
  },
  GroupsIcon: {
    fontSize: '7rem',
    paddingBottom: '1rem',
  },
}));
