import React, { useState, useEffect } from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import LoadingIndicator from '../../../LoadingIndicator';
import PaymentForm from '../PaymentForm';
import { GET_PAYMENT } from '../queries';

function PaymentsEdit({ id, updateCallback }) {
  const { loading, error, data: paymentData } = useQuery(GET_PAYMENT, {
    variables: { id },
  });

  function formatData(data) {
    if (data) {
      const { payment } = data;
      let newPayment = {
        operationNumber: payment.operationNumber,
        voucherPhoto: payment.voucherPhoto,
        amount: payment.amount,
        bank: payment.bank.id,
        participant: payment.participant.id,
      };
      return newPayment;
    }
  }

  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get payment', error);
  }

  return (
    <PaymentForm
      payment={formatData(paymentData)}
      id={id}
      hasCancel={false}
      isEdit
      updateCallback={updateCallback}
    />
  );
}

PaymentsEdit.propTypes = {};

export default PaymentsEdit;
