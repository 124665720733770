/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';

// Material Resources

// Others
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

// Components & Others
import NotFound from '../NotFoundPage';
import EditMenu from '../../components/EditMenu';
import CognitoForm from '../../components/RepurchaseCognito';
import { GET_RAISING_AND_FUND_INFORMATION } from '../../components/RepurchaseCognito/queries';
import LoadingIndicator from '../../components/LoadingIndicator';
import { edgeToList } from '../../utils/commonFunctions';

function RepurchaseCognitoPage() {
  const { repurchaseID } = useParams();
  const [repurchase, setRepurchase] = useState(null);
  const [wheels, setWheels] = useState([]);

  const { data, loading } = useQuery(GET_RAISING_AND_FUND_INFORMATION, {
    variables: { repurchaseId: repurchaseID },
  });

  useEffect(() => {
    if (data) {
      setRepurchase(data.repurchase);
      setWheels(edgeToList(data.repurchase, 'repurchasewheelSet'));
    }
  }, [data]);

  function hasActiveWheel() {
    const nowDate = moment();

    if (wheels && wheels.length) {
      return wheels.find(wheel =>
        nowDate.isBetween(wheel.dateInitial, wheel.dateEnd, 'days', '[]'),
      );
    }
    return false;
  }

  function notStartedWheel() {
    const nowDate = moment();
    if (wheels && wheels.length) {
      return wheels.find(wheel => nowDate.isBefore(wheel.dateInitial, 'days'));
    }
    return false;
  }

  function formatDate(date) {
    return moment(date.dateInitial).format('DD MMMM YYYY');
  }

  return loading ? (
    <LoadingIndicator />
  ) : !repurchase ? (
    <NotFound message="La recompra ha finalizado." />
  ) : hasActiveWheel(wheels) ? (
    <CognitoForm repurchase={repurchase} />
  ) : notStartedWheel(wheels) ? (
    <NotFound
      message={'La recompra empieza el ' + formatDate(notStartedWheel(wheels))}
    />
  ) : (
    <NotFound message="La recompra ha finalizado." />
  );
}

export default RepurchaseCognitoPage;
