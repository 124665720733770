import React from 'react';

const ProgressCircle = ({ percentage }) => {
  const radius = 80;
  const strokeWidth = 14;
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop stopColor="#F97A00" />
          <stop offset="1" stopColor="#F9C18C" />
        </linearGradient>
      </defs>
      <circle
        stroke="#CDDAE2"
        fill="transparent"
        strokeWidth={strokeWidth}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        stroke="url(#gradient)"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={(circumference, circumference)}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fontSize="32px"
        fontWeight="bold"
        fill="#292D32"
      >
        {`${percentage}%`}
      </text>
    </svg>
  );
};
export default ProgressCircle;
