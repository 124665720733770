import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btn: {
    // boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    boxShadow: ' 4px 4px 15px 0px rgba(166, 193, 211, 0.25)',
    borderRadius: '5px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    background: '#fff',
    border: '0.3px solid #E3E1E0',
    padding: '10px',
    color: '#686565',
    cursor: 'pointer',
    '&:hover': {
      // boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.25)',
      // boxShadow: ' 4px 4px 15px 0px rgba(166, 193, 211, 0.50)',
      background: 'rgba(0, 0, 0, 0.08)',
    },
  },
  container: {
    position: 'absolute',
    top: '25%',
    left: '1%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    background: '#FFF',
    borderRadius: '5px',
    // boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    boxShadow: ' 4px 4px 15px 0px rgba(166, 193, 211, 0.50)',
    padding: '10px',
    width: '100%',
  },
  main: {
    position: 'relative',
    width: '100%',
    zIndex: 999,
    height: 'fit-content',
  },
}));
