import { gql } from 'apollo-boost';

// eslint-disable-next-line import/prefer-default-export
export const LIST_SECONDARY_MARKETS = gql`
  query listSecondaryMarkets(
    $initialDate: DateTime
    $finalDate: DateTime
    $fund: ID
    $clientId: ID
  ) {
    listSecondaryMarkets(
      initialDate: $initialDate
      finalDate: $finalDate
      fund: $fund
      clientId: $clientId
    ) {
      edges {
        node {
          id
          fee
          registrationDate
          exchangeRateRisk
          feeSaleValue
          state
          fund {
            id
            businessName
            currency {
              id
              symbol
              code
            }
          }
          buyer {
            edges {
              node {
                id
                participationPercentage
                client {
                  id
                  user {
                    id
                    firstName
                    lastName
                    maternalSurname
                  }
                }
              }
            }
          }
          seller {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  maternalSurname
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SEND_SECONDARY_MARKET_CONTRACT = gql`
  mutation sendSecondaryMarketContractMutation($id: ID) {
    sendSecondaryMarketContractMutation(id: $id) {
      secondaryMarket {
        id
        state
      }
    }
  }
`;

export const GET_SECONDARY_MARKET_REPORT = gql`
  query secondaryMarketReport(
    $initialDate: DateTime
    $finalDate: DateTime
    $fund: ID
    $clientId: ID
  ) {
    secondaryMarketReport(
      initialDate: $initialDate
      finalDate: $finalDate
      fundId: $fund
      clientId: $clientId
    ) {
      csv
      filename
    }
  }
`;

export const GET_SECONDARY_MARKET = gql`
  query secondaryMarket($id: ID) {
    secondaryMarket(id: $id) {
      id
      fee
      registrationDate
      feeSaleValue
      state
      fund {
        id
        businessName
        currency {
          id
          symbol
          code
        }
      }
      buyer {
        edges {
          node {
            id
            participationPercentage
            representant
            client {
              id
              communicationEmail
              communicationCellphone
              user {
                id
                documentNumber
                firstName
                lastName
              }
            }
          }
        }
      }
      seller {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_SECONDARY_MARKET = gql`
  mutation createSecondaryMarket(
    $buyersInput: [SecondaryMarketParticipantInput]
    $input: SecondaryMarketInput!
  ) {
    createSecondaryMarket(buyersInput: $buyersInput, input: $input) {
      secondaryMarket {
        id
        registrationDate
        contractSigningDate
        fund {
          id
          businessName
          currency {
            id
            symbol
            code
          }
        }
        fee
        feeSaleValue
        registrationDate
        state
        buyer {
          edges {
            node {
              id
              participationPercentage
              representant
              client {
                id
                user {
                  id
                  firstName
                  lastName
                  maternalSurname
                }
              }
            }
          }
        }
        seller {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                maternalSurname
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SECONDARY_MARKET = gql`
  mutation updateSecondaryMarket(
    $id: ID
    $buyersInput: [SecondaryMarketParticipantInput]
    $input: SecondaryMarketInput!
  ) {
    updateSecondaryMarket(id: $id, buyersInput: $buyersInput, input: $input) {
      secondaryMarket {
        id
        registrationDate
        contractSigningDate
        fund {
          id
          businessName
          currency {
            id
            symbol
            code
          }
        }
        fee
        feeSaleValue
        registrationDate
        state
        buyer {
          edges {
            node {
              id
              participationPercentage
              representant
              client {
                id
                user {
                  id
                  firstName
                  lastName
                  maternalSurname
                }
              }
            }
          }
        }
        seller {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
                maternalSurname
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_PARTICIPANTS_BY_FUND = gql`
  query listParticipantsByFund($fundId: ID) {
    listParticipantsByFund(fundId: $fundId) {
      participants {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_RAISING_FEES = gql`
  query listRaisingFees($fundId: ID, $coParticipantsIds: [ID]) {
    listRaisingFees(fundId: $fundId, coParticipantsIds: $coParticipantsIds) {
      raising
      fees
    }
  }
`;

export const GET_CLIENT_BY_DOCUMENT = gql`
  query getClientByDocument($documentNumber: String) {
    getClientByDocument(documentNumber: $documentNumber) {
      id
      phone
      cellphone
      communicationCellphone
      communicationEmail
      user {
        lastName
        maternalSurname
        firstName
        emailValidated
      }
    }
  }
`;

export const DELETE_SECONDARY_MARKET = gql`
  mutation deleteSecondaryMarket($id: ID) {
    deleteSecondaryMarket(id: $id) {
      secondaryMarket {
        id
      }
    }
  }
`;
