import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btn: {
    width: '100%',
    marginBottom: 10,
    textTransform: 'capitalize',
    borderRadius: '8px',
    background: '#007DFA',
    height: '40px',
    textAlign: 'center',
    border: ' 0.5px solid  #9D9D9D',
    '&:hover': {
      background: '#007DFA',
    },
  },
  form: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',
    flexDirection: 'column',
    height: '100%',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  layout: {
    width: '100%',
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    flexDirection: 'column',
  },
  main: {
    position: 'relative',
    zIndex: 999,
  },

  errorText: {
    fontSize: '1.6vh',
    color: '#FD7272',
    textAlign: 'center',
    fontWeight: '400',
  },
  divResetPass: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1vh',
    width: '100%',
    zIndex: '10',
    textAlign: 'center',
    justifyContent: 'center',
  },

  forgetPass: {
    color: '#007DFA',
    fontSize: '1.7vh',
    fontWeight: '500',
    cursor: 'pointer',
  },
}));
