import React from 'react';
import PropTypes from 'prop-types';

import CustomModal from '../CustomModal';
import LoadingIndicator from '../LoadingIndicator';

// Styles & Images
import useStyles from './styles';
import getClientMode from 'src/store/utils-store/selectors';
import { useSelector } from 'react-redux';

function PDFViewerModal(props) {
  const {
    showModal,
    fileName,
    onClosePress,
    fileB64,
    fileURL,
    loading,
    errorMessage,
  } = props;

  const classes = useStyles();
  const isModeDark = useSelector(getClientMode());
  const documentOptions = fileB64 || fileURL;
  return (
    <CustomModal
      showModal={showModal}
      title={fileName}
      onClosePress={onClosePress}
      maxWidth="lg"
      fullWidth
      hideScroll
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <LoadingIndicator />
        </div>
      ) : documentOptions ? (
        <object
          data={documentOptions}
          type="application/pdf"
          className={isModeDark ? classes.pdfContaierDark : classes.pdfContaier}
        >
          <embed src={documentOptions} type="application/pdf"></embed>
          <div className={classes.loadingContainer}>
            <h4>
              En caso no pueda ver el documento, pruebe con un navegador
              diferente.
            </h4>
          </div>
        </object>
      ) : (
        <div className={classes.loadingContainer}>
          <h4 className={classes.error}>{errorMessage}</h4>
        </div>
      )}
    </CustomModal>
  );
}

PDFViewerModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClosePress: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  fileB64: PropTypes.string,
  fileURL: PropTypes.string,
  loading: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

PDFViewerModal.defaultProps = {
  fileB64: '',
  fileURL: '',
  errorMessage:
    'Ocurrió un error obteniendo el documento, inténtelo más tarde.',
};

export default PDFViewerModal;
