import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import LoadingIndicator from '../../../LoadingIndicator';
import {
  LIST_COMPLIANCE_CLIENTS,
  REJECTED_CLIENT,
  APPROVE_COMPLIANCE_CLIENT,
} from '../queries';
import {
  generateColumn,
  edgeToList,
  getOptions,
} from '../../../../utils/commonFunctions';
import { IconButton, Tooltip, TableCell, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSnackbar } from 'notistack';

import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { textLabels } from '../../../../translations/components/MUIDataTable';

// styles and icons
import useStyles from './styles';

function ComplianceList() {
  const [complianceClientList, setComplianceClientList] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { loading: listLoading, error: listError, data: listData } = useQuery(
    LIST_COMPLIANCE_CLIENTS,
  );

  const [rejectedClient] = useMutation(
    REJECTED_CLIENT,
    getOptions({
      mutationName: 'rejectClient',
      message: 'Cliente Desaprobado',
      modelName: 'rejected',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_COMPLIANCE_CLIENTS }],
    }),
  );

  const [approveComplianceClient] = useMutation(
    APPROVE_COMPLIANCE_CLIENT,
    getOptions({
      mutationName: 'approveComplianceClient',
      message: 'Cliente Aprobado',
      modelName: 'approved',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_COMPLIANCE_CLIENTS }],
    }),
  );

  if (listError) {
    console.error('list clients', listError);
  }

  useEffect(() => {
    if (listData) {
      let newList = edgeToList(listData, 'listClients').map(element => {
        let { user } = element;
        user = {
          ...user,
          promoter: element.promoter
            ? element.promoter.user.firstName +
              ' ' +
              element.promoter.user.lastName
            : '',
        };
        user.optionValue = {
          id: user.id,
          urlRiskReport: element.urlRiskReport,
        };
        return user;
      });
      setComplianceClientList(newList);
    }
  }, [listData]);

  const columns = [
    generateColumn('Nombres', 'firstName'),
    generateColumn('Apellidos Paterno', 'lastName'),
    generateColumn('Apellidos Materno', 'maternalSurname'),
    {
      name: 'documentType',
      label: 'Tipo de documento',
      options: {
        sort: false,
        customBodyRender: value => {
          return (
            <Typography variant="subtitle1" align="left">
              {value.name}
            </Typography>
          );
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    generateColumn('Número de documento', 'documentNumber'),
    generateColumn('Promotor', 'promoter'),
    {
      name: 'optionValue',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const { id, urlRiskReport } = value;
          return (
            <CustomOptions
              id={id}
              model="Banco"
              options={['ocustom', 'oconfirm', 'odeny']}
              customOption={
                <span>
                  <Tooltip title={'Reporte de Riesgo'} placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => window.open(urlRiskReport, '_blank')}
                    >
                      <DescriptionIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              handleConfirmOption={() =>
                approveComplianceClient({ variables: { userId: id } })
              }
              handleDenyOption={() =>
                rejectedClient({ variables: { userId: id } })
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={4} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
  };

  return listLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <MUIDataTable
        title="Aprobación LAFT"
        data={complianceClientList}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default ComplianceList;
