import React, { useEffect, useState } from 'react';

// Material Resources
import { Typography, TableCell } from '@material-ui/core';

// GraphQl
import { useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import moment from 'moment';
import CustomOptions from '../../../../../CustomOptions';
import LoadingIndicator from '../../../../../LoadingIndicator';
import CustomColumnHeader from '../../../../../TableComponents/CustomColumnHeader';
import {
  getOptions,
  generateColumn,
  edgeToList,
  stringToDate,
} from '../../../../../../utils/commonFunctions';

// Styles & Images
import useStyles from '../../styles';
import CustomSubmodelList from '../../../../../InnerModels/CustomSubmodel/CustomSubmodelList';
import Yup from '../../../../../../utils/yup';
import { INPUT_VARIANT } from '../../../../../Forms/StepsForm/fieldsSelector';
import {
  LIST_RWHEEL_RAISING,
  GET_RWHEEL_RAISING,
  UPDATE_RWHEEL_RAISING,
  CREATE_RWHEEL_RAISING,
  DELETE_RWHEEL_RAISING,
} from './queries';
import {
  defaultDate,
  defaultDateTomorrow,
} from '../../../../../FormComponents/DatePicker';
import { dateRangeValidator } from '../../../../../Validations';

function RaisingsWheels(props) {
  const { raisingID } = props;
  const classes = useStyles();
  const [wheelCount, setWheelCount] = useState(0);

  const [getWheels, { error, data, loading }] = useLazyQuery(
    LIST_RWHEEL_RAISING,
    {
      variables: { repurchase: raisingID },
    },
  );

  useEffect(() => {
    raisingID && getWheels();
  }, [raisingID]);

  useEffect(() => {
    if (data) {
      setWheelCount(data.listRepurchaseWheel.edges.length + 1);
    }
  }, [data]);

  const mutationsData = {
    update: {
      query: UPDATE_RWHEEL_RAISING,
      mutationName: 'updateRepurchaseWheel',
      modelName: 'repurchaseWheel',
      message: 'Rueda actualizada con éxito.',
      refetchQueries: [
        { query: LIST_RWHEEL_RAISING, variables: { repurchase: raisingID } },
      ],
    },
    create: {
      query: CREATE_RWHEEL_RAISING,
      mutationName: 'createRepurchaseWheel',
      modelName: 'repurchaseWheel',
      message: 'Rueda creada con éxito.',
      refetchQueries: [
        { query: LIST_RWHEEL_RAISING, variables: { repurchase: raisingID } },
      ],
    },
    delete: {
      query: DELETE_RWHEEL_RAISING,
      mutationName: 'deleteRepurchaseWheel',
      modelName: 'repurchaseWheel',
      message: 'Rueda eliminada con éxito.',
      refetchQueries: [
        { query: LIST_RWHEEL_RAISING, variables: { repurchase: raisingID } },
      ],
    },
  };

  const columns = [
    generateColumn('Nombre', 'name'),
    {
      name: 'dateAward',
      label: 'Fecha Adjudicación',
      options: {
        sort: false,
        customBodyRender: value => moment(value).format('YYYY-MM-DD'),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'dateInitial',
      label: 'Fecha inicial',
      options: {
        sort: false,
        customBodyRender: value => moment(value).format('YYYY-MM-DD'),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'dateEnd',
      label: 'Fecha final',
      options: {
        sort: false,
        customBodyRender: value => moment(value).format('YYYY-MM-DD'),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    generateColumn('A Enviar', 'numToSend'),
    generateColumn('Enviados', 'numSent'),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        display: false,
        customBodyRender: value => {
          const id = value;
          return <CustomOptions id={id} model="Wheel" options={[]} />;
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  // Form data
  const startValues = {
    name: `Rueda ${wheelCount}`,
    dateAward: defaultDate,
    dateInitial: defaultDate,
    dateEnd: defaultDateTomorrow,
    fundInvestorsOtherFunds: '',
    fundInvestors: '',
    fundInvestorsPastWheel: '',
    interestedInvestors: '',
  };
  function validationFunction(values) {
    const errors = {};
    // Custom Validations

    dateRangeValidator('dateInitial', 'dateEnd', values, errors);

    return errors;
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    dateAward: Yup.string().required(),
    dateInitial: Yup.string().required(),
    dateEnd: Yup.string().required(),
  });

  const formStructure = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      label: 'Fecha',
      name: 'dateAward',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
    },
    {
      gridMD: 12,
      inputVariant: INPUT_VARIANT.dateRange,
      fieldData: {
        startDate: {
          label: 'Fecha inicial',
          name: 'dateInitial',
        },
        endDate: {
          label: 'Fecha final',
          name: 'dateEnd',
        },
      },
    },
    {
      isField: false,
      section: 'Participantes',
      gridMD: 12,
      fields: [
        // {
        //   label: 'Inversionistas de Otros Fondos Core',
        //   name: 'fundInvestorsOtherFunds',
        //   inputVariant: INPUT_VARIANT.singleCheckbox,
        //   gridMD: 12,
        // },
        {
          label: 'Inversionistas del Fondo',
          name: 'fundInvestors',
          inputVariant: INPUT_VARIANT.singleCheckbox,
          gridMD: 12,
        },
        {
          label: 'Inversionistas del Fondo - Rueda Anterior',
          name: 'fundInvestorsPastWheel',
          inputVariant: INPUT_VARIANT.singleCheckbox,
          gridMD: 12,
        },
        // {
        //   label: 'Lista de interesados',
        //   name: 'interestedInvestors',
        //   inputVariant: INPUT_VARIANT.singleCheckbox,
        //   gridMD: 12,
        // },
      ],
    },
  ];

  function formatQuery(wheel) {
    const formatValue = { ...wheel.repurchaseWheel };
    return formatValue;
  }

  function formatBeforeSubmit(values) {
    const { participants, ...newValues } = values;

    newValues.dateAward = stringToDate(newValues.dateAward).toISOString();
    newValues.dateInitial = stringToDate(newValues.dateInitial).toISOString();
    newValues.dateEnd = stringToDate(newValues.dateEnd).toISOString();

    newValues.repurchase = raisingID;
    return newValues;
  }

  return (
    <div className={classes.tableContainer}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        raisingID && (
          <CustomSubmodelList
            submodelName="Rueda"
            submodelList={data && edgeToList(data, 'listRepurchaseWheel')}
            columns={columns}
            updateModelFunction={() => {}}
            // Form props
            canBeDeleted={false}
            getQuery={GET_RWHEEL_RAISING}
            formatQuery={formatQuery}
            mutationsData={mutationsData}
            startValues={startValues}
            validationSchema={validationSchema}
            validationFunction={validationFunction}
            formStructure={formStructure}
            formatBeforeSubmit={formatBeforeSubmit}
          />
        )
      )}
    </div>
  );
}

RaisingsWheels.propTypes = {
  raisingID: PropTypes.string.isRequired,
};

export default RaisingsWheels;
