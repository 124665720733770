// GraphQl
import { gql } from 'apollo-boost';

export const GET_INVESTMENT_REPORTS = gql`
  query getinvestmentReports(
    $documentNumber: String!
    $date: Date
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    investmentReports(
      documentNumber: $documentNumber
      date: $date
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          date
          previewLink
          downloadLink
        }
      }
    }
  }
`;
