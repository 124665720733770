import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  modalContent: {
    padding: '2rem',
    margin: 'auto',
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  image: {
    height: 150,
    alignSelf: 'center',
  },
  video: {
    padding: '1rem',
  },
  webcam: {
    width: '100%',
  },
  select: {
    padding: '0.5rem',
  },
  timerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  timer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
