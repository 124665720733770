import React, { useEffect, useState } from 'react';

// Others
import Yup from '../../../../utils/yup';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  GET_CLIENT_SPOUSE_LABOR_DATA,
  UPDATE_CLIENT_LABOR_DATA,
} from '@queries/Client';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import {
  errorMessage,
  edgeToList,
  successMessage,
} from '../../../../utils/commonFunctions';
import { LIST_MONTHLY_INCOME_RANGES } from '../../../Models/MonthlyIncomeRange/queries';
import { LIST_EDUCATION_LEVELS } from '../../../Models/EducationLevel/queries';
import { rucValidator } from '../../../Validations';
import LoadingIndicator from '../../../LoadingIndicator';
import { setActiveFormAction } from '../../../../containers/ModelsPages/ClientForm/actions';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import { FORM_OPTIONS } from '../../constants';
import { LIST_COUNTRIES } from 'src/components/FormComponents/PlacesSelects';

function LaborDataSpouse(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [hasRuc, setHasRuc] = useState(false);
  const [isPeruvian, setIsPeruvian] = useState(false);

  const { loading, error: spouseError, data: spouseData } = useQuery(
    GET_CLIENT_SPOUSE_LABOR_DATA,
    {
      variables: { id: clientID },
    },
  );

  const updateClient = useMutation(UPDATE_CLIENT_LABOR_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage('Se guardó datos laborales.', enqueueSnackbar);
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_LABOR_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó datos laborales parcialmente.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  // SELECT QUERIES
  const {
    loading: monthlyIncomeRangeLoading,
    error: monthlyIncomeRangeError,
    data: monthlyIncomeRangeData,
  } = useQuery(LIST_MONTHLY_INCOME_RANGES);
  const {
    loading: educationLevelsLoading,
    error: educationLevelsError,
    data: educationLevelsData,
  } = useQuery(LIST_EDUCATION_LEVELS);

  const {
    loading: countryLoading,
    data: countryData,
    error: countryError,
  } = useQuery(LIST_COUNTRIES);

  const isPeru = id => {
    if (
      countryData &&
      countryData.listCountries &&
      countryData.listCountries.edges
    ) {
      return !!countryData.listCountries.edges.find(val => {
        return val.node && val.node.id === id && val.node.code === 'PE';
      });
    }
    return false;
  };

  useEffect(() => {
    if (spouseData && spouseData.client && spouseData.client.spouse) {
      setHasRuc(!!spouseData.client.spouse.ruc);
      if (
        countryData &&
        !countryLoading &&
        spouseData.client.spouse.birthplace
      ) {
        setIsPeruvian(isPeru(spouseData.client.spouse.birthplace.country.id));
      }
    }
  }, [spouseData, countryLoading]);

  function handleHasRucChange(event) {
    event.preventDefault();
    if (event.target.value) {
      setHasRuc(event.target.value === 'true');
    }
  }

  function monthlyIncomeRangeEdgeToList(data, listName) {
    if (data) {
      const sortByName = data[listName].edges;
      sortByName.sort((a, b) => a.node.name.localeCompare(b.node.name));
      return sortByName.map(element => {
        const formatElement = { ...element.node };
        const name = formatElement.name || '';
        if (name.length > 1) {
          formatElement.text = name[0].toUpperCase() + name.slice(1);
        }
        return formatElement;
      });
    }
    return [];
  }

  function monthlyIncomeRangeParagraph(data, listName) {
    if (data) {
      const sortByName = data[listName].edges;
      sortByName.sort((a, b) => a.node.name.localeCompare(b.node.name));
      return sortByName
        .map(element => {
          const formatElement = { ...element.node };
          let name = formatElement.name || '';
          if (name.length > 1) {
            name = name[0].toUpperCase() + name.slice(1);
          }
          if (formatElement.minimum === null) {
            return `${name}: Menos de ${formatElement.maximum} dólares`;
          }
          if (formatElement.maximum === null) {
            return `${name}: Más de ${formatElement.minimum} dólares`;
          }
          return `${name}: De ${formatElement.minimum} dólares a ${formatElement.maximum} dólares`;
        })
        .join('\n');
    }
    return '';
  }

  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    if (values.hasRuc === 'true') {
      rucValidator(values.ruc, errors);
    }
    return errors;
  }

  const validationSchema = Yup.object().shape({
    occupation: Yup.string().required(),
    workplace: Yup.string().required(),
    role: Yup.string().required(),
    years: Yup.number().integer(),
    months: Yup.number().integer(),
    // averageMonthIncome: Yup.string().required(),
    educationLevel: Yup.string().required(),
    taxNumber: Yup.number().when({
      is: () => !isPeruvian,
      then: Yup.number().required(),
    }),
  });

  let startValues = {};

  if (loading) return <LoadingIndicator />;

  if (
    spouseError ||
    countryError ||
    monthlyIncomeRangeError ||
    educationLevelsError
  ) {
    errorMessage(
      'Hubo un problema al recibir algunos datos, por favor inténtelo de nuevo.',
      enqueueSnackbar,
    );
  }

  const { spouse } = spouseData.client;
  if (spouse) {
    const data = spouse;
    data.years = Math.floor(data.workingTime / 12);
    data.months = data.workingTime % 12;
    let averageMonthIncomeId = data.averageMonthIncome;
    if (data.averageMonthIncome && data.averageMonthIncome.id) {
      averageMonthIncomeId = data.averageMonthIncome.id;
    }
    data.averageMonthIncome = averageMonthIncomeId;
    let educationLevelId = data.educationLevel;
    if (data.educationLevel && data.educationLevel.id) {
      educationLevelId = data.educationLevel.id;
    }
    data.educationLevel = educationLevelId;
    data.hasRuc = (!!data.ruc).toString();
    startValues = { ...data };
  } else {
    startValues = {
      occupation: '',
      workplace: '',
      role: '',
      years: 0,
      months: 0,
      averageMonthIncome: '',
      educationLevel: '',
      ruc: '',
      taxNumber: '',
    };
  }

  const data = [
    {
      isField: false,
      section: 'RUC',
      fields: [
        {
          name: 'hasRuc',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
          onChange: handleHasRucChange,
        },
      ],
    },
    {
      isField: true,
      label: '* Profesión u ocupación',
      name: 'occupation',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Centro de labores (de ser el caso)',
      name: 'workplace',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Cargo',
      name: 'role',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: false,
      section: 'Tiempo de servicio',
      fields: [
        {
          label: 'Años',
          name: 'years',
          labelMonth: 'Meses',
          nameMonth: 'months',
          inputVariant: INPUT_VARIANT.serviceTime,
          gridMD: [6, 6],
        },
      ],
    },
    // {
    //   isField: false,
    //   section: 'Ingreso promedio mensual',
    //   fields: [
    //     {
    //       label: '* Ingreso promedio mensual',
    //       name: 'averageMonthIncome',
    //       inputVariant: INPUT_VARIANT.select,
    //       gridMD: 12,
    //       data: monthlyIncomeRangeEdgeToList(
    //         monthlyIncomeRangeData,
    //         'listMonthlyIncomeRanges'
    //       ),
    //       mapData: { value: 'id', label: 'text' },
    //     },
    //     {
    //       name: 'average-income-text',
    //       text: monthlyIncomeRangeParagraph(
    //         monthlyIncomeRangeData,
    //         'listMonthlyIncomeRanges'
    //       ),
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //     },
    //   ],
    // },
    {
      isField: true,
      label: '* Nivel de Instrucción',
      name: 'educationLevel',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.select,
      data: edgeToList(educationLevelsData, 'listEducationLevels'),
      mapData: { value: 'id', label: 'name' },
    },
  ];

  if (!isPeruvian) {
    data.push({
      isField: true,
      label: '* Número de identificación tributaria',
      name: 'taxNumber',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.number,
    });
  }

  if (hasRuc) {
    data[0].fields.push({
      isField: true,
      label: '* Registro Único de Contribuyente',
      name: 'ruc',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    });
  }

  function formatBeforeSubmit(values) {
    const {
      years,
      months,
      user,
      hasRuc: hasRucData,
      ruc,
      ...newValues
    } = values;
    delete newValues.birthplace;
    newValues.workingTime = Number(months) + Number(years) * 12;
    newValues.ruc = hasRucData === 'true' ? ruc : '';

    return newValues;
  }

  return loading || educationLevelsLoading || monthlyIncomeRangeLoading ? (
    <LoadingIndicator />
  ) : (
    <StepsForm
      id={spouse.id}
      mainClientID={clientID}
      initialValues={startValues}
      validateFunction={validationFunction}
      validateSchema={validationSchema}
      data={data}
      title="Datos del cónyuge o apoderado / Datos laborales"
      nextForm={MENU_ITEMS_ID.ClientLabourData}
      updateMutation={updateClient}
      partialUpdateMutation={partialUpdateClient}
      formatBeforeSubmit={formatBeforeSubmit}
    />
  );
}

export default LaborDataSpouse;
