import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  divider: {
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.colors.darkGray,
    margin: '10px 0',
  },
}));
