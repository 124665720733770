import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    container: {
      backgroundColor: theme.palette.colors.gray,
    },
    header: {
      height: 180,
      backgroundColor: theme.palette.primary.main,
    },
    image: {
      margin: 25,
      height: 100,
      alignSelf: 'center',
    },
    steps: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: '8%',
        marginRight: '8%',
      },
      paddingBottom: '3%',
      backgroundColor: theme.palette.colors.white,
    },
    stepper: {
      width: '100%',
      justifyContent: 'center',
    },
    icon: {
      height: '2rem',
      width: '2rem',
      textAlign: 'center',
      borderRadius: 100,
      fontWeight: 800,
      fontSize: '1.25rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    inactive: {
      backgroundColor: theme.palette.primary.contrastText,
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
    },
    horizontal: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    buttons: {
      marginTop: 25,
    },
    stepLabel: {
      color: theme.palette.secondary.contrastText,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
  })
);
