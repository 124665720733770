import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import {
  Button,
  Tooltip,
  Typography,
  TableCell,
  Dialog,
  Toolbar,
  useMediaQuery,
  IconButton,
  Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

// GraphQl
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import {
  UPDATE_BANK_ACCOUNT,
  LIST_BANK_ACCOUNTS,
  LIST_BANK_ACCOUNTS_BY_IDS,
} from '../../../Models/BankAccount/queries';
import { textLabels } from '../../../../translations/components/MUIDataTable';

// Styles & Images
import useStyles from './styles';

import InnerBankAccountForm from '../BankAccountForm';
import InnerBankAccountEdit from '../BankAccountCreateEdit/edit';
import { getOptions, generateColumn } from '../../../../utils/commonFunctions';
import LoadingIndicator from 'src/components/LoadingIndicator';

function CustomToolbar(props) {
  const { addOnClick } = props;

  return (
    <Button variant="contained" color="secondary" onClick={addOnClick}>
      Añadir otra cuenta bancaria
    </Button>
  );
}
CustomToolbar.propTypes = {
  addOnClick: PropTypes.func.isRequired,
};

function InnerBankDataBankAccountList(props) {
  const { fundID, accountIdList, onBankAccountCreated } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [accountId, setAccountID] = useState('');

  const [
    loadBankAccount,
    { loading: bankAccountsLoading, data: bankAccountsData },
  ] = useLazyQuery(LIST_BANK_ACCOUNTS_BY_IDS);

  useEffect(() => {
    if (accountIdList.length) {
      loadBankAccount({
        variables: { id: accountIdList },
      });
    }
  }, [accountIdList]);

  const [updateBankAccount] = useMutation(
    UPDATE_BANK_ACCOUNT,
    getOptions({
      mutationName: 'updateBankAccount',
      modelName: 'bankAccount',
      message: 'Estado de la cuenta actualizado con éxito.',
      enqueueSnackbar,
    }),
  );

  function edgeToListBankAccounts(bankAccounts) {
    if (bankAccounts) {
      return bankAccounts.listBankAccount.edges.map(element => {
        const bankAccount = {
          id: element.node.id,
          bank: element.node.bank.name,
          accountNumber: element.node.accountNumber,
          currency: element.node.currency.name,
          accountType: element.node.accountType.name,
          options: {
            id: element.node.id,
            enabled: element.node.isActive,
          },
        };

        return bankAccount;
      });
    }
    return [];
  }

  function handleEnabled(e, id, enabled) {
    updateBankAccount({
      variables: {
        id,
        input: {
          fund: fundID,
          isActive: !enabled,
        },
      },
    });
  }

  const columns = [
    generateColumn('Banco', 'bank'),
    generateColumn('Número de cuenta', 'accountNumber'),
    generateColumn('Moneda', 'currency'),
    generateColumn('Tipo de cuenta', 'accountType'),
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const { id, enabled } = value;
        return (
          <CustomOptions
            id={id}
            key={id}
            model="Cuenta de Banco"
            options={['cedit', 'oenable']}
            extraData={{ oenable: enabled }}
            extraFunctions={{ oenable: e => handleEnabled(e, id, enabled) }}
            handleEdit={() => {
              setAccountID(id);
              setIsEdit(true);
              setShowForm(true);
            }}
            customOption={
              <span>
                <Tooltip
                  title={enabled ? 'Activar cuenta' : 'Descativar cuenta'}
                  placement="top"
                  arrow
                >
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {}}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell
            id={columnMeta.index}
            key={columnMeta.index}
            style={{ borderBottom: 'none' }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      setAccountID(rowData[rowData.length - 1].props.id);
      setIsEdit(true);
      setShowForm(true);
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <CustomToolbar
          addOnClick={() => {
            setIsEdit(false);
            setShowForm(true);
          }}
        />
      );
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return bankAccountsLoading ? (
    <LoadingIndicator />
  ) : (
    <Grid md={12}>
      <MUIDataTable
        title="Cuentas bancarias"
        data={edgeToListBankAccounts(bankAccountsData)}
        columns={columns}
        options={options}
      />
      <Dialog
        open={showForm}
        onClose={() => true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="lg"
      >
        <Toolbar className={classes.toolbar}>
          <Typography color="secondary" className={classes.title}>
            Fondos
          </Typography>
          <IconButton
            variant="contained"
            color="secondary"
            onClick={() => setShowForm(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <div className={classes.formContainer}>
          {isEdit && accountId !== '' ? (
            <InnerBankAccountEdit
              id={accountId}
              fundID={fundID}
              afterEditCreate={() => setShowForm(false)}
            />
          ) : (
            <InnerBankAccountForm
              fundID={fundID}
              afterEditCreate={() => setShowForm(false)}
              onBankAccountCreated={onBankAccountCreated}
            />
          )}
        </div>
      </Dialog>
    </Grid>
  );
}

InnerBankDataBankAccountList.propTypes = {
  fundID: PropTypes.string.isRequired,
  accountIdList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onBankAccountCreated: PropTypes.func.isRequired,
};

export default InnerBankDataBankAccountList;
