import React from 'react';

// Material Resources
import { Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_MONTHLY_INCOME_RANGE,
  CREATE_MONTHLY_INCOME_RANGE,
  DELETE_MONTHLY_INCOME_RANGE,
  LIST_MONTHLY_INCOME_RANGES,
} from '../queries';
import {
  validationFunctionMonthlyIncomeRange,
  validationSchemaMonthlyIncomeRange,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
  minimum: '',
  maximum: '',
};

function MonthlyIncomeRangeForm(props) {
  const { monthlyIncomeRange, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateMonthlyIncomeRange = useMutation(
    UPDATE_MONTHLY_INCOME_RANGE,
    getOptions({
      mutationName: 'updateMonthlyIncomeRange',
      modelName: 'monthlyIncomeRange',
      message: 'Rango de ingreso mensual actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createMonthlyIncomeRange = useMutation(
    CREATE_MONTHLY_INCOME_RANGE,
    getOptions({
      mutationName: 'createMonthlyIncomeRange',
      modelName: 'monthlyIncomeRange',
      message: 'Rango de ingreso mensual creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createMonthlyIncomeRangeData }) {
        if (cache.data.data.ROOT_QUERY.listMonthlyIncomeRanges) {
          const { listMonthlyIncomeRanges } = cache.readQuery({
            query: LIST_MONTHLY_INCOME_RANGES,
          });
          listMonthlyIncomeRanges.edges.unshift({
            // Be careful with types
            __typename: 'MonthlyIncomeRangeTypeEdge',
            node:
              createMonthlyIncomeRangeData.createMonthlyIncomeRange
                .monthlyIncomeRange,
          });
          cache.writeQuery({
            query: LIST_MONTHLY_INCOME_RANGES,
            data: { listMonthlyIncomeRanges },
          });
        }
        history.push('/administrador/ingresos-mensuales');
      },
    })
  );
  const deleteMonthlyIncomeRange = useMutation(
    DELETE_MONTHLY_INCOME_RANGE,
    getOptions({
      mutationName: 'deleteMonthlyIncomeRange',
      modelName: 'monthlyIncomeRange',
      message: 'Rango de ingreso mensual eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listMonthlyIncomeRanges) {
          const { listMonthlyIncomeRanges } = cache.readQuery({
            query: LIST_MONTHLY_INCOME_RANGES,
          });
          listMonthlyIncomeRanges.edges = listMonthlyIncomeRanges.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_MONTHLY_INCOME_RANGES,
            data: { listMonthlyIncomeRanges },
          });
        }
        history.push('/administrador/ingresos-mensuales');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Mínimo',
      name: 'minimum',
      gridMD: 11,
      gridXS: 11,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      name: 'minimum-tooltip',
      gridMD: 1,
      gridXS: 1,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.node,
      node: (
        <>
          <Tooltip title='Si este campo esta vacio, se considerara como "a menos" '>
            <HelpOutlineIcon />
          </Tooltip>
        </>
      ),
    },
    {
      label: 'Máximo',
      name: 'maximum',
      gridMD: 11,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      name: 'maximun-tooltip',
      gridMD: 1,
      gridXS: 1,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.node,
      node: (
        <>
          <Tooltip title='Si este campo esta vacio, se considerara como "a más" '>
            <HelpOutlineIcon />
          </Tooltip>
        </>
      ),
    },
  ];

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    if (newValues.minimum === '') newValues.minimum = null;
    if (newValues.maximum === '') newValues.maximum = null;
    return newValues;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionMonthlyIncomeRange}
        validateSchema={validationSchemaMonthlyIncomeRange}
        data={data}
        model={monthlyIncomeRange}
        id={id}
        modelName="Rangos de ingresos mensuales"
        routeName="/administrador/ingresos-mensuales"
        isEdit={isEdit}
        updateMutation={updateMonthlyIncomeRange}
        createMutation={createMonthlyIncomeRange}
        deleteMutation={deleteMonthlyIncomeRange}
        formatBeforeSubmit={formatBeforeSubmit}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

MonthlyIncomeRangeForm.propTypes = {
  monthlyIncomeRange: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

MonthlyIncomeRangeForm.defaultProps = {
  monthlyIncomeRange: null,
  id: '',
  isEdit: false,
};

export default MonthlyIncomeRangeForm;
