import React from 'react';

function ProfileIcon(props) {
  const { w, h, bg } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={w}
      height={h}
      fill="none"
      viewBox="0 0 133 131"
    >
      <path
        fill={bg}
        fillOpacity="0.3"
        d="M66.5 0C30.068 0 .5 29.344.5 65.5s29.568 65.5 66 65.5 66-29.344 66-65.5S102.932 0 66.5 0zm0 19.65c10.956 0 19.8 8.777 19.8 19.65s-8.844 19.65-19.8 19.65c-10.956 0-19.8-8.777-19.8-19.65s8.844-19.65 19.8-19.65zm0 93.01a47.618 47.618 0 01-39.6-21.091c.198-13.035 26.4-20.174 39.6-20.174 13.134 0 39.402 7.14 39.6 20.174a47.618 47.618 0 01-39.6 21.091z"
      />
    </svg>
  );
}

export default ProfileIcon;
