import { gql } from 'apollo-boost';

export const GET_CLIENT_RISK_PROFILE = gql`
  query RiskProfile($id: ID) {
    riskProfile(id: $id) {
      id
      registeredAnswers {
        edges {
          node {
            id
            preferenceOrder
            answer {
              id
              question {
                id
                questionType
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_RISK_PROFILE = gql`
  mutation RiskProfile($id: ID!, $input: RiskProfileInput!) {
    updateRiskProfile(id: $id, input: $input) {
      riskProfile {
        id
        inversionForm
        experience
        horizon
        analysisLevel
      }
    }
  }
`;

export const UPDATE_RISK = gql`
  mutation createMultipleRegisteredAnswers($input: RegisteredAnswersInput!) {
    createMultipleRegisteredAnswers(input: $input) {
      registeredAnswers {
        id
        preferenceOrder
        answer {
          id
          answer
          order
          active
          question {
            id
            question
            order
            active
            section {
              id
              name
              order
              active
            }
          }
        }
      }
    }
  }
`;

export const GET_RISK_SECTION = gql`
  query getAllSections($name: String) {
    listSections(name: $name) {
      edges {
        node {
          id
          name
          order
          active
          questions {
            edges {
              node {
                id
                question
                order
                active
                questionType
                enableOn {
                  id
                  order
                  answer
                  question {
                    id
                    question
                    order
                  }
                }
                answers {
                  edges {
                    node {
                      id
                      answer
                      order
                      active
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
