// GraphQl
import { gql } from 'apollo-boost';

export const LIST_BANK_ACCOUNTS_CLIENT = gql`
  query listBankAccountClient($id: ID!) {
    listBankAccountClient(client: $id) {
      edges {
        node {
          id
          availableForAllFunds
          client {
            id
          }
          account {
            id
            bank {
              id
              name
            }
            currency {
              id
              name
            }
            accountType {
              id
            }
            country {
              id
            }
            accountNumber
            cci
            swift
            aba
            jointAccount
            observations
            otherBank
            isActive
          }
          fund {
            id
            businessName
          }
        }
      }
    }
  }
`;

export const GET_BANK_ACCOUNT_CLIENT = gql`
  query getBankAccountClient($id: ID!) {
    bankAccountClient(id: $id) {
      id
      availableForAllFunds
      account {
        id
        bank {
          id
          name
        }
        currency {
          id
          name
        }
        accountType {
          id
        }
        country {
          id
        }
        accountNumber
        cci
        swift
        aba
        jointAccount
        observations
        otherBank
        isActive
      }
      fund {
        id
        businessName
      }
    }
  }
`;

export const CREATE_BANK_ACCOUNT_CLIENT = gql`
  mutation createBankAccountClient($input: BankAccountClientInput!) {
    createBankAccountClient(input: $input) {
      bankAccountClient {
        id
        availableForAllFunds
        client {
          id
        }
        account {
          id
          bank {
            id
            name
          }
          currency {
            id
            name
          }
          accountType {
            id
          }
          country {
            id
          }
          accountNumber
          cci
          swift
          aba
          jointAccount
          observations
          otherBank
          isActive
        }
        fund {
          id
          businessName
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_BANK_ACCOUNT_CLIENT = gql`
  mutation updateBankAccountClient($id: ID!, $input: BankAccountClientInput!) {
    updateBankAccountClient(id: $id, input: $input) {
      bankAccountClient {
        id
        availableForAllFunds
        client {
          id
        }
        account {
          id
          bank {
            id
            name
          }
          currency {
            id
            name
          }
          accountType {
            id
          }
          country {
            id
          }
          accountNumber
          cci
          swift
          aba
          jointAccount
          observations
          otherBank
          isActive
        }
        fund {
          id
          businessName
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_BANK_ACCOUNT_CLIENT = gql`
  mutation deleteBankAccountClient($id: ID!) {
    deleteBankAccountClient(id: $id) {
      bankAccountClient {
        id
        availableForAllFunds
        client {
          id
        }
        account {
          id
          bank {
            id
            name
          }
          currency {
            id
            name
          }
          accountType {
            id
          }
          country {
            id
          }
          accountNumber
          cci
          swift
          aba
          jointAccount
          observations
          isActive
        }
        fund {
          id
          businessName
        }
      }
      errors {
        field
        message
      }
    }
  }
`;
