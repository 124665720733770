import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import BankForm from '../BankForm';
import { GET_BANK } from '../queries';

function BankEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_BANK, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get bank', error);
    history.goBack();
  }

  const formatValue = { ...data.bank };
  if (!data.bank) {
    history.goBack();
  }

  return <BankForm bank={formatValue} id={id} isEdit />;
}

BankEdit.propTypes = {};

export default BankEdit;
