import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_MARITAL_STATUS,
  CREATE_MARITAL_STATUS,
  DELETE_MARITAL_STATUS,
  LIST_MARITAL_STATUS,
} from '../queries';
import {
  validationFunctionMaritalStatus,
  validationSchemaMaritalStatus,
} from './validation';
import { FORM_OPTIONS } from '../../../StepsComponents/constants';
import { parseEquivalent, getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
  spouse: 'false',
};

function MaritalStatusForm(props) {
  const { maritalStatus, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateMaritalStatus = useMutation(
    UPDATE_MARITAL_STATUS,
    getOptions({
      mutationName: 'updateMaritalStatus',
      modelName: 'maritalStatus',
      message: 'Estado civil actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createMaritalStatus = useMutation(
    CREATE_MARITAL_STATUS,
    getOptions({
      mutationName: 'createMaritalStatus',
      modelName: 'maritalStatus',
      message: 'Estado civil creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createMaritalStatusData }) {
        if (cache.data.data.ROOT_QUERY.listMaritalStatus) {
          const { listMaritalStatus } = cache.readQuery({
            query: LIST_MARITAL_STATUS,
          });
          listMaritalStatus.edges.unshift({
            // Be careful with types
            __typename: 'MaritalStatusTypeEdge',
            node: createMaritalStatusData.createMaritalStatus.maritalStatus,
          });
          cache.writeQuery({
            query: LIST_MARITAL_STATUS,
            data: { listMaritalStatus },
          });
        }
        history.push('/administrador/estados-civiles');
      },
    })
  );
  const deleteMaritalStatus = useMutation(
    DELETE_MARITAL_STATUS,
    getOptions({
      mutationName: 'deleteMaritalStatus',
      modelName: 'maritalStatus',
      message: 'Estado civil eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listMaritalStatus) {
          const { listMaritalStatus } = cache.readQuery({
            query: LIST_MARITAL_STATUS,
          });
          listMaritalStatus.edges = listMaritalStatus.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_MARITAL_STATUS,
            data: { listMaritalStatus },
          });
        }
        history.push('/administrador/estados-civiles');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Permite cónyuge?',
      name: 'spouse',
      gridMD: 6,
      data: FORM_OPTIONS.yesNo,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.radio,
    },
  ];

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.spouse = parseEquivalent(newValues.spouse);
    return newValues;
  }
  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionMaritalStatus}
        validateSchema={validationSchemaMaritalStatus}
        data={data}
        model={maritalStatus}
        id={id}
        modelName="Categorías"
        routeName="/administrador/estados-civiles"
        isEdit={isEdit}
        formatBeforeSubmit={formatBeforeSubmit}
        updateMutation={updateMaritalStatus}
        createMutation={createMaritalStatus}
        deleteMutation={deleteMaritalStatus}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

MaritalStatusForm.propTypes = {
  maritalStatus: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

MaritalStatusForm.defaultProps = {
  maritalStatus: null,
  id: '',
  isEdit: false,
};

export default MaritalStatusForm;
