import React from 'react';

function NotificationsIcon(props) {
  const { bg } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={bg}
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M6.517 1c-2.27 0-4.114 1.468-4.114 3.274v1.577c0 .333-.178.84-.391 1.125l-.788 1.042c-.487.644-.151 1.359.74 1.599 2.955.786 6.144.786 9.099 0 .83-.218 1.193-.999.74-1.599l-.788-1.042c-.206-.284-.384-.792-.384-1.125V4.274C10.631 2.474 8.78 1 6.517 1zM7.896 11.526c-1.235.777-2.057.777-3.292 0"
      />
    </svg>
  );
}

export default NotificationsIcon;
