import Yup from '../../../../../utils/yup';
import { dniValidator } from '../../../../Validations';

export function validationFunctionClient(values) {
  const errors = {};
  // Custom Validations

  return errors;
}

export const validationSchemaClient = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  maternalSurname: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  documentType: Yup.string().required(),
  documentNumber: Yup.string().required(),
  userType: Yup.string().required(),
});
