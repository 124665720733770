import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

// GraphQl
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import { useSnackbar } from 'notistack';
import Yup from 'src/utils/yup';
import PropTypes from 'prop-types';
import { BLOCK_TYPES } from '.';
import ReadOnlyEditor from 'src/components/ReadOnlyEditor';
import RadioButtons from 'src/components/FormComponents/RadioButtons';
import Checkboxes from 'src/components/FormComponents/Checkboxes';
import { TextField } from 'formik-material-ui';
import { Button, Grid } from '@material-ui/core';
import { errorMessage, edgeToList } from 'src/utils/commonFunctions';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { LIST_FUND_SOURCE } from '../FundSource/queries';
import {
  GET_CONTRACT_BY_PARTICIPANT_COPARTICIPANTS,
  GET_SWORN_DECLARATION_BY_FUND,
} from './queries';

// Styles & Images
import useClasses from './styles';
import {
  GET_CONTRACT_BY_SECONDARY_MARKET_COPARTICIPANTS,
  GET_SWORN_DECLARATION_BY_SECONDARY_MARKET,
} from '../ContracSignSecondaryMarket/queries';
import { getClientSelector } from 'src/store/sharedStore/selectors';

export const yesNoOptions = {
  yes: 'YES',
  no: 'NO',
};

function GenerateContract(props) {
  const {
    isSecondaryMarket,
    contractData,
    setFieldValue,
    nextStep,
    validateForm,
    setFormValidation,
    rewriteBlocks,
    values,
    errors,
  } = props;

  const classes = useClasses();
  const { enqueueSnackbar } = useSnackbar();
  const [metaData, setMetaData] = useState(null);
  const client = useSelector(getClientSelector());
  const {
    loading: FundSourcesLoading,
    error: FundSourcesError,
    data: FundSourcesData,
  } = useQuery(LIST_FUND_SOURCE);

  const [getCoparticipantAnex2, { loading: loadingAnnex }] = useLazyQuery(
    isSecondaryMarket
      ? GET_CONTRACT_BY_SECONDARY_MARKET_COPARTICIPANTS
      : GET_CONTRACT_BY_PARTICIPANT_COPARTICIPANTS,
    {
      onError: error =>
        errorMessage(
          'Ocurrió un error al obtener la información del contrato',
          enqueueSnackbar
        ),
      onCompleted: data => {
        let contractData = null;
        if (data && isSecondaryMarket) {
          contractData = data.contractTypeBySecondaryMarket;
        } else {
          contractData = data.contractTypeByParticipant;
        }
        if (contractData) {
          const {
            nodeId,
            coparticipantID,
            state,
            active,
            labelText,
            hasSign,
          } = contractData;

          const startDataValidations = {};
          const obj = JSON.parse(contractData.annexText2);
          const blocks = [];
          const necessaryInputs = [];
          let startIDX = 0;
          obj.blocks.forEach((b, blockIndx) => {
            if (b.text.includes('%tratamiento_datos%')) {
              const inputName = `data-treatment-${nodeId}-${blockIndx}`;
              blocks.push({
                type: BLOCK_TYPES.PLAIN_TEXT,
                text: JSON.stringify({
                  blocks: obj.blocks.slice(startIDX, blockIndx),
                  entityMap: {},
                }),
              });
              startIDX = blockIndx + 1;
              blocks.push({
                type: BLOCK_TYPES.TRATAMIENTO_DATOS,
                id: blockIndx,
                name: inputName,
              });
              necessaryInputs.push(inputName);
              setFieldValue(inputName, yesNoOptions.yes);
              startDataValidations[inputName] = Yup.string().required();
            } else if (b.text.includes('%fuentes_fondo%')) {
              const inputName = `found-source-${nodeId}-${blockIndx}`;
              blocks.push({
                type: BLOCK_TYPES.PLAIN_TEXT,
                text: JSON.stringify({
                  blocks: obj.blocks.slice(startIDX, blockIndx),
                  entityMap: {},
                }),
              });
              startIDX = blockIndx + 1;
              blocks.push({
                type: BLOCK_TYPES.FUENTES_FONDOS,
                id: blockIndx,
                name: inputName,
                otherName: `other-${inputName}`,
                explicationName: `explication-${inputName}`,
              });
              necessaryInputs.push(inputName);
              necessaryInputs.push(`other-${inputName}`);
              necessaryInputs.push(`explication-${inputName}`);

              setFieldValue(inputName, []);
              setFieldValue(`explication-${inputName}`, '');
              startDataValidations[inputName] = Yup.array().required();
              startDataValidations[`other-${inputName}`] = Yup.string().when(
                inputName,
                {
                  is: sourcesListValues => {
                    if (sourcesListValues.findIndex(i => i === 'other') !== -1)
                      return true;
                    return false;
                  },
                  then: Yup.string().required(),
                }
              );
              startDataValidations[
                `explication-${inputName}`
              ] = Yup.string().required();
            }
          });
          if (startIDX < obj.blocks.length) {
            blocks.push({
              type: BLOCK_TYPES.PLAIN_TEXT,
              text: JSON.stringify({
                blocks: obj.blocks.slice(startIDX, obj.blocks.length),
                entityMap: {},
              }),
            });
          }
          setFormValidation(startDataValidations);

          rewriteBlocks(nodeId, {
            isCoparticipant: false,
            coparticipantID,
            nodeId,
            labelText,
            state,
            active,
            hasSign,
            blockData: blocks,
            necessaryInputs,
          });
        }
      },
    }
  );
  const [getSwornDeclaration, { loading: loadSwornDeclaration }] = useLazyQuery(
    GET_SWORN_DECLARATION_BY_FUND,
    {
      onError: error =>
        errorMessage(
          'Ocurrió un error al obtener la información de la declaración jurada',
          enqueueSnackbar
        ),
      onCompleted: data => {
        if (data && data.clientTypeSwornDeclaration) {
          const { nodeId, state, active, labelText, hasSign } = contractData;
          rewriteBlocks(nodeId, {
            isCoparticipant: false,
            isSwornDeclaration: false,
            nodeId,
            labelText,
            state,
            active,
            hasSign,
            hasSignSwornDeclaration: true,
            blockData: [
              {
                type: BLOCK_TYPES.PLAIN_TEXT,
                text: data.clientTypeSwornDeclaration.text,
              },
            ],
            necessaryInputs: [],
          });
        }
      },
    }
  );

  const [
    getSwornDeclarationSecondaryMarket,
    { loading: loadSwornDeclarationSecondaryMarket },
  ] = useLazyQuery(GET_SWORN_DECLARATION_BY_SECONDARY_MARKET, {
    onError: error =>
      errorMessage(
        'Ocurrió un error al obtener la información de la declaración jurada',
        enqueueSnackbar
      ),
    onCompleted: data => {
      if (data && data.clientTypeSecondaryMarketSwornDeclaration) {
        const { nodeId, state, active, labelText, hasSign } = contractData;
        rewriteBlocks(nodeId, {
          isCoparticipant: false,
          isSwornDeclaration: false,
          nodeId,
          labelText,
          state,
          active,
          hasSign,
          hasSignSwornDeclaration: true,
          blockData: [
            {
              type: BLOCK_TYPES.PLAIN_TEXT,
              text: data.clientTypeSecondaryMarketSwornDeclaration.text,
            },
          ],
          necessaryInputs: [],
        });
      }
    },
  });

  useEffect(() => {
    const {
      isCoparticipant,
      isSwornDeclaration,
      coparticipantID,
      participantID,
    } = contractData;
    if (isCoparticipant) {
      getCoparticipantAnex2({ variables: { idParticipant: coparticipantID } });
    } else if (isSwornDeclaration) {
      if (isSecondaryMarket) {
        getSwornDeclarationSecondaryMarket({
          variables: { secondaryMarketId: participantID },
        });
      } else {
        getSwornDeclaration({
          variables: { idParticipant: participantID },
        });
      }
    } else {
      setMetaData(contractData);
    }

    validateForm();
  }, [contractData]);

  const checkValidation = () => {
    let val = true;
    for (let i = 0; i < metaData.necessaryInputs.length; i++) {
      if (!!errors[metaData.necessaryInputs[i]]) {
        val = false;
        break;
      }
    }
    return val;
  };

  return (
    <div>
      {!metaData ||
      loadingAnnex ||
      loadSwornDeclaration ||
      loadSwornDeclarationSecondaryMarket ||
      FundSourcesLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {metaData.blockData.map(block => {
            if (block.type === BLOCK_TYPES.PLAIN_TEXT) {
              return <ReadOnlyEditor text={block.text} />;
            }
            if (block.type === BLOCK_TYPES.TRATAMIENTO_DATOS) {
              return (
                <div className={classes.inputContainer}>
                  <RadioButtons
                    id="contract-radios"
                    name={block.name}
                    label=""
                    data={[
                      {
                        value: yesNoOptions.yes,
                        label:
                          'Autorizo el tratamiento de mis datos para el cumplimiento de finalidades adicionales.',
                      },
                      {
                        value: yesNoOptions.no,
                        label:
                          'No autorizo el tratamiento de mis datos para el cumplimiento de finalidades adicionales.',
                      },
                    ]}
                    vertical
                  />
                </div>
              );
            }
            if (block.type === BLOCK_TYPES.FUENTES_FONDOS) {
              return (
                <div className={classes.inputContainer}>
                  <Checkboxes
                    label=""
                    name={block.name}
                    data={edgeToList(FundSourcesData, 'listFundSource')}
                    mapData={{ value: 'id', label: 'name' }}
                    other
                    otherData={{
                      name: block.otherName,
                      label: 'Especificar otras fuentes',
                    }}
                    selected={values[block.name]}
                    error={errors[block.name] ? errors[block.name] : ''}
                    setFieldValue={setFieldValue}
                  />
                  <div className={classes.explicationContainer}>
                    <TextField
                      name={block.explicationName}
                      label="Breve explicación de la(s) fuente(s)"
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </div>
                </div>
              );
            }
          })}
          {!metaData.hasSign && (
            <Grid container justify="flex-end" alignItems="center" item>
              <div className={classes.nextContainer}>
                <Button
                  onClick={() => checkValidation() && nextStep()}
                  variant="contained"
                  color={checkValidation() ? 'secondary' : 'primary'}
                >
                  Siguiente
                </Button>
              </div>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

GenerateContract.propTypes = {
  isSecondaryMarket: PropTypes.bool,
  contractData: PropTypes.shape.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  setFormValidation: PropTypes.func.isRequired,
  rewriteBlocks: PropTypes.func.isRequired,
  values: PropTypes.shape.isRequired,
  errors: PropTypes.shape.isRequired,
};

GenerateContract.defaultProps = {
  isSecondaryMarket: false,
};
export default GenerateContract;
