import sign from 'jwt-encode';

function tokenGenerator({
  payload,
  lifeTime = 2,
  secret = 'mz88A5pCTtXJG5vUaPsARjhMN8SmUBOUEk',
}) {
  const date = Math.floor(Date.now() / 1000);

  const iat = date;
  const exp = date + Number(lifeTime) * 60 * 60;

  return sign({ ...payload, iat, exp }, secret);
}

export default tokenGenerator;
