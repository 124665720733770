// GraphQl
import { gql } from 'apollo-boost';

export const LIST_WHEEL_RAISING = gql`
  query getRaisingsWheels($raising: ID) {
    listWheel(raising: $raising) {
      edges {
        node {
          id
          name
          numSent
          dateInitial
          dateEnd
          dateAward
          numToSend
          fundInvestors
          fundInvestorsPastWheel
          fundInvestorsOtherFunds
          interestedInvestors
        }
      }
    }
  }
`;

export const GET_WHEEL_RAISING = gql`
  query getWheel($id: ID!) {
    wheel(id: $id) {
      id
      name
      numSent
      dateInitial
      dateEnd
      dateAward
      numToSend
      fundInvestors
      fundInvestorsPastWheel
      fundInvestorsOtherFunds
      interestedInvestors
    }
  }
`;

export const CREATE_WHEEL_RAISING = gql`
  mutation createWheel($input: WheelInput!) {
    createWheel(input: $input) {
      wheel {
        id
        name
        numSent
        dateInitial
        dateEnd
        dateAward
        numToSend
        fundInvestors
        fundInvestorsPastWheel
        fundInvestorsOtherFunds
        interestedInvestors
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_WHEEL_RAISING = gql`
  mutation updateWheel($id: ID!, $input: WheelInput!) {
    updateWheel(id: $id, input: $input) {
      wheel {
        id
        name
        numSent
        dateInitial
        dateEnd
        dateAward
        numToSend
        fundInvestors
        fundInvestorsPastWheel
        fundInvestorsOtherFunds
        interestedInvestors
      }

      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_WHEEL_RAISING = gql`
  mutation deleteWheel($id: ID!) {
    deleteWheel(id: $id) {
      wheel {
        id
      }

      errors {
        field
        message
      }
    }
  }
`;
