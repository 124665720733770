import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  editorContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 5,
    padding: 20,
    marginBottom: 20,
  },
  infoContainer: {
    '& #mui-rte-toolbar': {
      border: 'none',
    },
    '& #mui-rte-root': {
      textAling: 'justify',
    },
    '& .public-DraftEditor-content': {
      textAlign: 'justify',
      whiteSpace: 'unset !important',
    },
    '& span > *': {
      whiteSpace: 'unset',
    },
  },
}));
