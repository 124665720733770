import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import {
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

import StopIcon from '@material-ui/icons/Stop';
import { Select, Switch } from 'formik-material-ui';
import { useFormikContext, ErrorMessage } from 'formik';

import useStyles from './styles';
import {
  parseDatesInObject,
  decimalAdjust,
  numberWithCommas,
} from '../../../utils/commonFunctions';
import CoParticipantList from './Coparticipant/CoparticipantList';
import {
  GET_MAX_FEE_COUNT,
  GET_RAISING_AND_FUND_INFORMATION,
} from '../queries';
import { useQuery } from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import NumberFormatInput from 'src/components/FormComponents/NumberFormatInput';
import { edgeToList } from '../../../utils/commonFunctions';
import { LIST_CLASS_FUND } from '../../Models/FundClass/queries';

export function Title(props) {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <StopIcon className={styles.squareBullet} />
      <ListItemText className={styles.listTitle}>{children}</ListItemText>
    </ListItem>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

const AMOUNT_OR_FEE = [
  { value: 'amount', label: 'Monto' },
  { value: 'fee', label: 'Cuotas' },
];

function StepThreeForm(props) {
  const errorPercentage = 'La suma de los porcentajes debe ser igual a 100%.';
  const errorOneRepresentative = 'Solo debe existir un representante';
  const errorCoParticipation = 'Debe de agregarse una coparticipación.';

  let { raising } = props;
  const { data: existingList } = props;
  const {
    values: formValues,
    errors: formErrors,
    setFieldValue,
    validateForm,
  } = useFormikContext();
  const styles = useStyles();
  const [participantsList, setParticipantsList] = useState([]);
  const [classFundList, setClassFundList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [enabledClass, setEnableClass] = useState(true);
  const [investmentValueText, setInvestmentValueText] = useState(null);
  const [maxFeeText, setMaxFeeText] = useState(null);
  const [maxValues, setMaxValues] = useState({ amount: -1, fee: -1 });
  const [minimumInvestmentValue, setMinimumInvestmentValue] = useState(null);
  const { loading, error, data: listClassFund } = useQuery(LIST_CLASS_FUND);

  raising = parseDatesInObject(raising);
  const fund = parseDatesInObject(raising.fund);

  const {
    loading: maxValueLoading,
    error: maxValueError,
    data: maxValueData,
  } = useQuery(GET_MAX_FEE_COUNT, {
    variables: { raisingId: raising.id },
  });
  if (maxValueError) {
    console.error('Cognito step 3: max fee count', maxValueError);
  }

  const {
    loading: raisingClassesLoading,
    error: raisingClassesError,
    data: raisingClasses,
  } = useQuery(GET_RAISING_AND_FUND_INFORMATION, {
    variables: { raisingId: raising.id },
  });

  useEffect(() => {
    if (raisingClasses && fund && raising) {
      const parserlistClassFund = raisingClasses?.raising?.classes?.edges.map(
        item => item.node,
      );
      setMinimumInvestmentValue(raising?.minimumInvestment);
      if (!fund.hasClasses) setEnableClass(false);
      setClassFundList(parserlistClassFund);
    }
  }, [raisingClasses]);

  useEffect(() => {
    if (maxValueData && maxValueData.totalFundFeeCount) {
      setMaxValues({
        amount: maxValueData.totalFundFeeCount.fundFeeCount * raising.valueFee,
        fee: maxValueData.totalFundFeeCount.fundFeeCount,
      });
    }
  }, [maxValueData]);

  useEffect(() => {
    setFieldValue('coparticipants', participantsList);
  }, [participantsList]);

  useEffect(() => {
    if (errors.length) {
      setFieldValue('mainUser', 'error');
    } else {
      setFieldValue('mainUser', 'ok');
    }
    validateForm();
  }, [errors]);

  useEffect(() => {
    setParticipants(existingList);
  }, [formValues.hasCoParticipation]);

  const addError = (error, errorList) => {
    if (errorList.indexOf(error) === -1) {
      errorList.push(error);
    }
    return errorList;
  };
  const deleteError = (error, errorList) => {
    const index = errorList.indexOf(error);
    if (index > -1) {
      errorList.splice(index, 1);
    }
    return errorList;
  };

  const isPercentageValid = list => {
    const percentage = list.reduce((prev, curr) => {
      return Number(prev) + Number(curr.participationPercentage);
    }, 0);
    return percentage === 100;
  };

  const hasOnlyOneRepresentative = list =>
    list.filter(v => v.isRepresentative).length === 1;

  const setParticipants = list => {
    let newErrors = [...errors];
    newErrors = deleteError(errorPercentage, newErrors);
    newErrors = deleteError(errorOneRepresentative, newErrors);
    newErrors = deleteError(errorCoParticipation, newErrors);
    if (formValues.hasCoParticipation) {
      if (!isPercentageValid(list)) {
        addError(errorPercentage, newErrors);
      }
      if (!hasOnlyOneRepresentative(list)) {
        addError(errorOneRepresentative, newErrors);
      }
      if (list.length < 2) {
        addError(errorCoParticipation, newErrors);
      }
    }
    setErrors(newErrors);
    setParticipantsList(list);
  };

  useEffect(() => {
    let text = {};
    const feeCount = Math.floor(formValues.investmentValue / raising?.valueFee);
    const minimumFee = formValues.investmentValue * raising.valueFee;
    const minimumValue = feeCount * raising.valueFee;

    const investmentValue = decimalAdjust(
      'ceil',
      formValues.investmentValue * raising.valueFee,
      -2,
    );
    let newErrors = [...errors];
    newErrors = deleteError('El monto excede el máximo permitido', newErrors);
    newErrors = deleteError(
      'El número de cuotas excede el máximo permitido',
      newErrors,
    );
    if (formValues.participationAmountOrFee === 'amount') {
      text = {
        text1: `Representa `,
        value: feeCount,
        text2: ' cuotas a invertir.',
      };

      setFieldValue('feeCount', feeCount);
      setFieldValue('amountToInvest', formValues.investmentValue);
      if (
        maxValues.amount > 0 &&
        formValues.investmentValue > maxValues.amount
      ) {
        newErrors = addError('El monto excede el máximo permitido', newErrors);
      } else if (
        minimumInvestmentValue &&
        minimumValue < minimumInvestmentValue
      ) {
        formValues.errors = '';
        newErrors.length = 0;
        formValues.errors = 'minimum ticket error';
        newErrors = addError(
          `El ticket mínimo es de ${fund.currency.code} ${numberWithCommas(
            minimumInvestmentValue,
          )}`,
          newErrors,
        );
      } else {
        formValues.errors = '';
        newErrors.length = 0;
      }
    }
    if (formValues.participationAmountOrFee === 'fee') {
      text = {
        text1: `Representa ${fund.currency.code} `,
        value: investmentValue,
        text2: ' a invertir.',
      };
      setFieldValue('feeCount', formValues.investmentValue);
      setFieldValue('amountToInvest', investmentValue);
      if (maxValues.fee > 0 && formValues.investmentValue > maxValues.fee) {
        newErrors = addError(
          'El número de cuotas excede el máximo permitido',
          newErrors,
        );
      } else if (
        minimumInvestmentValue &&
        minimumFee < minimumInvestmentValue
      ) {
        formValues.errors = '';
        newErrors.length = 0;
        formValues.errors = 'minimum ticket error';
        newErrors = addError(
          `El ticket mínimo en cuotas es de ${numberWithCommas(
            Math.ceil(minimumInvestmentValue / raising?.valueFee),
          )}`,
          newErrors,
        );
      } else {
        formValues.errors = '';
        newErrors.length = 0;
      }
    }
    setErrors(newErrors);
    setInvestmentValueText(text);
  }, [
    formValues.investmentValue,
    formValues.participationAmountOrFee,
    minimumInvestmentValue,
  ]);

  const defineMaxInvestmet = e => {
    let maxText = {};
    let state = formValues.participationAmountOrFee;
    let newErrors = [...errors];
    newErrors = deleteError('El monto excede el máximo permitido', newErrors);
    newErrors = deleteError(
      'El número de cuotas excede el máximo permitido',
      newErrors,
    );
    if (e) {
      state = e.target.value;
    }
    if (state === 'amount') {
      maxText = {
        text1: `El monto máximo a invertir es ${fund.currency.code} `,
        value: maxValues.amount,
      };
    }
    if (state === 'fee') {
      maxText = {
        text1: `El máximo número de cuotas a invertir es de `,
        value: maxValues.fee,
      };
    }
    setErrors(newErrors);
    setMaxFeeText(maxText);
  };

  const handleChange = e => {
    setFieldValue(
      'className',
      classFundList.filter(item => item.id == e.target.value)[0].name,
    );
    setFieldValue('classFund', e.target.value);
  };

  useEffect(() => {
    defineMaxInvestmet();
  }, [maxValues]);

  return (
    <Grid container justify="center">
      <Grid
        container
        item
        justify="space-around"
        xs={12}
        className={styles.containerShadow}
      >
        <Grid item xs={12} className={cx(styles.sectionText, styles.tableHead)}>
          <h3>PARTICIPACIÓN</h3>
        </Grid>
        {maxValues.amount > 0 && maxValues.fee > 0 && maxFeeText && (
          <Grid item xs={10} className={styles.infoSection}>
            <Alert variant="outlined" color="warning" severity="error">
              {formValues.participationAmountOrFee === 'amount' ? (
                <div>
                  {maxFeeText.text1}
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    value={maxFeeText.value}
                  />
                </div>
              ) : (
                <div>
                  {maxFeeText.text1}
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    value={maxFeeText.value}
                  />
                </div>
              )}
            </Alert>
          </Grid>
        )}
        <Grid container item justify="center" direction="row" xs={12}>
          <Grid item md={4} xs={4} className={styles.inputContainer}>
            <Select
              fullWidth
              name="participationAmountOrFee"
              variant="outlined"
              inputProps={{
                onChange: defineMaxInvestmet,
              }}
            >
              {AMOUNT_OR_FEE.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item md={2} xs={2} className={styles.inputContainer}>
            <p>a invertir:</p>
          </Grid>
          <Grid item md={4} xs={4} className={styles.inputContainer}>
            {formValues.participationAmountOrFee === 'fee' ? (
              <NumberFormatInput
                name="investmentValue"
                label="cuotas"
                variant="outlined"
                fullWidth
                formatprops={{
                  thousandSeparator: true,
                }}
              />
            ) : (
              <NumberFormatInput
                name="investmentValue"
                label="monto"
                variant="outlined"
                fullWidth
                formatprops={{
                  thousandSeparator: true,
                  decimalScale: 2,
                  fixedDecimalScale: true,
                  prefix: fund.currency.code,
                }}
              />
            )}
          </Grid>
        </Grid>
        {enabledClass && (
          <Grid container item justify="center" direction="row" xs={12}>
            <Grid item md={3} xs={3} className={styles.inputContainer} />
            <Grid item md={3} xs={3} className={styles.inputContainer}>
              <p>Seleccione la clase del Fondo:</p>
            </Grid>
            <Grid item md={4} xs={4} className={styles.inputContainer}>
              <Select
                fullWidth
                name="classFund"
                variant="outlined"
                inputProps={{
                  onChange: handleChange,
                }}
              >
                {classFundList?.map(item => (
                  <MenuItem key={item.value} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography className={styles.errorTypo}>
                {formErrors.classFund}
              </Typography>
            </Grid>
          </Grid>
        )}
        {investmentValueText && (
          <Grid item xs={10} className={styles.infoSection}>
            <Alert variant="outlined" color="info">
              {formValues.participationAmountOrFee === 'fee' ? (
                <div>
                  {investmentValueText.text1}
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    value={investmentValueText.value}
                  />
                  {investmentValueText.text2}
                </div>
              ) : (
                <div>
                  {investmentValueText.text1}
                  <NumberFormat
                    displayType="text"
                    thousandSeparator
                    value={investmentValueText.value}
                  />
                  {investmentValueText.text2}
                </div>
              )}
            </Alert>
          </Grid>
        )}
        <Grid container item justify="center" direction="row" xs={12}>
          <Grid item xs={8} className={styles.inputContainer}>
            <Title>¿Tomará el servicio de riesgo cambiario?</Title>
          </Grid>
          <Grid item xs={2} className={styles.inputContainer}>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item>No</Grid>
              <Grid item>
                <Switch name="riskExchange" />
              </Grid>
              <Grid item>Sí</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={styles.containerShadow}>
        <Grid item xs={12} className={cx(styles.sectionText, styles.tableHead)}>
          <h3>COPARTÍCIPES</h3>
        </Grid>
        <Grid container item justify="center" direction="row" xs={12}>
          <Grid item xs={8} className={styles.inputContainer}>
            <Title>¿Tendrá coparticipación?</Title>
          </Grid>
          <Grid item xs={2} className={styles.inputContainer}>
            <Grid container item alignItems="center" spacing={1}>
              <Grid item>No</Grid>
              <Grid item>
                <Switch name="hasCoParticipation" />
              </Grid>
              <Grid item>Sí</Grid>
            </Grid>
          </Grid>
          {formValues.hasCoParticipation ? (
            <Grid
              item
              xs={12}
              className={cx(styles.sectionText, styles.tableHead)}
            >
              <CoParticipantList
                data={existingList}
                setParticipants={setParticipants}
              />
            </Grid>
          ) : null}
          {!!errors.length &&
            errors.map(error => <Alert severity="error">{error}</Alert>)}
        </Grid>
      </Grid>
    </Grid>
  );
}

StepThreeForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  raising: PropTypes.shape().isRequired,
};

export default StepThreeForm;
