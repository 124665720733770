import { gql } from 'apollo-boost';

export const GET_SCORES = gql`
  query getScores {
    scoresAnnexA {
      id
      experienceLimited
      experienceModerate
      experienceExtensive
      inversionBank
      inversionBond
      inversionActions
      inversionEtf
      horizonLess
      horizonBetween
      horizonGreater
      analysisChoiceNo
      analysisInformation
      analysisAdvisers
      analysisNone
      attitudeHigher
      attitudeNormal
      attitudeLess
      attitudeNone
      objectivePreservation
      objectiveConservative
      objectiveModerate
      objectiveAggressive
      case1Inversion
      case1Worry
      case1Transfer
      case1Ransom
      case2None
      case2Initial
      case2Percentage
      case2All
    }
  }
`;
export const CREATE_SCORES = gql`
  mutation createScore($input: ScoresAnnexAInput!) {
    createScoreAnnexA(input: $input) {
      scoresAnnexA {
        id
        experienceLimited
        experienceModerate
        experienceExtensive
        inversionBank
        inversionBond
        inversionActions
        inversionEtf
        horizonLess
        horizonBetween
        horizonGreater
        analysisChoiceNo
        analysisInformation
        analysisAdvisers
        analysisNone
        attitudeHigher
        attitudeNormal
        attitudeLess
        attitudeNone
        objectivePreservation
        objectiveConservative
        objectiveModerate
        objectiveAggressive
        case1Inversion
        case1Worry
        case1Transfer
        case1Ransom
        case2None
        case2Initial
        case2Percentage
        case2All
      }
      errors {
        field
        message
      }
    }
  }
`;
export const UPDATES_SCORES = gql`
  mutation updateScore($id: ID!, $input: ScoresAnnexAInput!) {
    updateScoreAnnexA(id: $id, input: $input) {
      scoresAnnexA {
        id
        experienceLimited
        experienceModerate
        experienceExtensive
        inversionBank
        inversionBond
        inversionActions
        inversionEtf
        horizonLess
        horizonBetween
        horizonGreater
        analysisChoiceNo
        analysisInformation
        analysisAdvisers
        analysisNone
        attitudeHigher
        attitudeNormal
        attitudeLess
        attitudeNone
        objectivePreservation
        objectiveConservative
        objectiveModerate
        objectiveAggressive
        case1Inversion
        case1Worry
        case1Transfer
        case1Ransom
        case2None
        case2Initial
        case2Percentage
        case2All
      }
      errors {
        field
        message
      }
    }
  }
`;
