// GraphQl
import { gql } from 'apollo-boost';

export const LIST_BANKS = gql`
  query listBanks {
    listBank {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_BANK = gql`
  query getBank($id: ID!) {
    bank(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_BANK = gql`
  mutation createBank($input: BankInput!) {
    createBank(input: $input) {
      bank {
        id
        name
      }
    }
  }
`;

export const UPDATE_BANK = gql`
  mutation updateBank($id: ID!, $input: BankInput!) {
    updateBank(id: $id, input: $input) {
      bank {
        id
        name
      }
    }
  }
`;

export const DELETE_BANK = gql`
  mutation deleteBank($id: ID!) {
    deleteBank(id: $id) {
      ok
      message
    }
  }
`;
