import React from 'react';

import Yup from '../../../../utils/yup';

import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

export const validationSchema = Yup.object().shape({
  experienceLimited: Yup.number().required(),
  experienceModerate: Yup.number().required(),
  experienceExtensive: Yup.number().required(),
  inversionBank: Yup.number().required(),
  inversionBond: Yup.number().required(),
  inversionActions: Yup.number().required(),
  inversionEtf: Yup.number().required(),
  horizonLess: Yup.number().required(),
  horizonBetween: Yup.number().required(),
  horizonGreater: Yup.number().required(),
  analysisChoiceNo: Yup.number().required(),
  analysisInformation: Yup.number().required(),
  analysisAdvisers: Yup.number().required(),
  analysisNone: Yup.number().required(),
  attitudeHigher: Yup.number().required(),
  attitudeNormal: Yup.number().required(),
  attitudeLess: Yup.number().required(),
  attitudeNone: Yup.number().required(),
  objectivePreservation: Yup.number().required(),
  objectiveConservative: Yup.number().required(),
  objectiveModerate: Yup.number().required(),
  objectiveAggressive: Yup.number().required(),
  case1Inversion: Yup.number().required(),
  case1Worry: Yup.number().required(),
  case1Transfer: Yup.number().required(),
  case1Ransom: Yup.number().required(),
  case2None: Yup.number().required(),
  case2Initial: Yup.number().required(),
  case2Percentage: Yup.number().required(),
  case2All: Yup.number().required(),
});

export const formStructure = [
  {
    node: <h2>Conocimiento del cliente</h2>,
    name: 'knowledge',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.node,
  },
  {
    node: <h3>Experiencia como inversionista</h3>,
    name: 'investmentExperience',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'Limitada: Tengo muy poca experiencia en inversiones',
    name: 'experienceLimited',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Moderada: Tengo alguna experiencia en inversiones y me gustaría recibir asesoría adicional.',
    name: 'experienceModerate',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Extensa: Soy un inversionista activo y experto, y me siento cómodo tomando mis propias decisiones de inversión.',
    name: 'experienceExtensive',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h3>Formas de Inversión</h3>,
    name: 'investmentForms',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'Inversión en bancos: Ahorros, depósito a plazo, etc.',
    name: 'inversionBank',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Inversión en bonos, papeles comerciales, fondos mutuos o de inversión.',
    name: 'inversionBond',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Inversión en acciones en el mercado nacional o extranjero.',
    name: 'inversionActions',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      "Inversion en ETF's, derivados financieros, comodities, contratos por diferencia (CFDs).",
    name: 'inversionEtf',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h3>¿Cuál es su horizonte de inversión, en general?</h3>,
    name: 'investmentHorizon',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'Menor de (01) año.',
    name: 'horizonLess',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Entre uno (01) y tres (3) años.',
    name: 'horizonBetween',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Más de tres (03) años.',
    name: 'horizonGreater',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: (
      <h3>
        ¿Cual es su nivel de análisis y/o uso de información financiera y
        bursátil?
      </h3>
    ),
    name: 'analisisLevel',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'No tengo acceso a información',
    name: 'analysisChoiceNo',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Tengo acceso a la información pero no realizo ningún tipo de análisis.',
    name: 'analysisNone',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Tengo acceso a la información por medio del análisis que me brindan mis asesores especializados.',
    name: 'analysisAdvisers',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Tengo acceso a la información y realizo el análisis de la misma.',
    name: 'analysisInformation',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h2>Perfil de riesgo de inversionista</h2>,
    name: 'perfil',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.node,
  },
  {
    node: (
      <h3>¿Como describiría usted mejor su actitud en relación al riesgo?</h3>
    ),
    name: 'attitude',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label:
      'Estoy dispuesto a asumir riesgos mayores en búsqueda de un rendimiento más alto',
    name: 'attitudeHigher',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Estoy dispuesto a asumir ciertos riesgos en búsqueda de un mejor rendimiento',
    name: 'attitudeNormal',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Estoy dispuesto a asumir muy poco riesgo aunque eso me signifique menores retornos',
    name: 'attitudeLess',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'No puedo aceptar una rentabilidad negativa, por lo que no estoy dispuesto a asumir riesgos',
    name: 'attitudeNone',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h3>Objetivo de inversión</h3>,
    name: 'inversionaGoal',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label:
      'Preservación del capital: No quiero arriesgar mi inversión inicial y no me siento cómodo con las fluctuaciones de corto plazo.',
    name: 'objectivePreservation',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Conservador: Me gustaría preservar mi inversión, pero estoy dispuesto a aceptar pequeñas fluctuaciones en el valor incluyendo posibles pérdidas en mi inversión inicial por periodos menores a un año.',
    name: 'objectiveConservative',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Moderado: Puedo aceptar fluctuaciones negativas, incluyendo posibles pérdidas en mi inversión inicial en un mediano plazo (1 a 2 años), con el fin de obtener tasas considerablemente mayores a certificados de depósitos a término.',
    name: 'objectiveModerate',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label:
      'Agresivo: Me gustaría que mis inversiones generaran los mayores rendimientos posibles. Estoy dispuesto a aceptar fluctuaciones negativas por periodos mayores a dos años, incluyendo la posible pérdida de mi inversión inicial.',
    name: 'objectiveAggressive',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h3>Caso 1</h3>,
    name: 'case1',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'Invertiría más',
    name: 'case1Inversion',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Se preocuparía, pero no tomaría ninguna acción',
    name: 'case1Worry',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Transferiría parte de sus recursos hacia activos de menor riesgo',
    name: 'case1Transfer',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Redimiría la totalidad de su inversión',
    name: 'case1Ransom',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    node: <h3>Caso 2</h3>,
    name: 'caso2',
    inputVariant: INPUT_VARIANT.node,
  },
  {
    label: 'No tomar ninguna acción',
    name: 'case2None',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Retirar las ganancias de la inversión inicial',
    name: 'case2Initial',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Retirar hasta el 30% de la inversión',
    name: 'case2Percentage',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
  {
    label: 'Retirar toda mi inversión (utilidad + inversión inicial)',
    name: 'case2All',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.inputBox,
  },
];
