import Yup from '../../../../utils/yup';
import { rucValidator } from '../../../Validations';

export function validationFunctionCurrency(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaCurrency = Yup.object().shape({
  name: Yup.string().required(),
  code: Yup.string()
    .max(3, 'Maximo 3 caracteres')
    .required(),
  symbol: Yup.string()
    .max(1, 'Maximo 1 caracteres')
    .required(),
});
