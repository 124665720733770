import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_DOCUMENT_TYPE,
  CREATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
  LIST_DOCUMENT_TYPES,
} from '../queries';
import {
  validationFunctionDocumentType,
  validationSchemaDocumentType,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function DocumentTypeForm(props) {
  const { documentType, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateDocumentType = useMutation(
    UPDATE_DOCUMENT_TYPE,
    getOptions({
      mutationName: 'updateDocumentType',
      modelName: 'documentType',
      message: 'Tipo de documento actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createDocumentType = useMutation(
    CREATE_DOCUMENT_TYPE,
    getOptions({
      mutationName: 'createDocumentType',
      modelName: 'documentType',
      message: 'Banco creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createDocumentTypeData }) {
        if (cache.data.data.ROOT_QUERY.listDocumentTypes) {
          const { listDocumentTypes } = cache.readQuery({
            query: LIST_DOCUMENT_TYPES,
          });
          listDocumentTypes.edges.unshift({
            // Be careful with types
            __typename: 'DocumentTypeTypeEdge',
            node: createDocumentTypeData.createDocumentType.documentType,
          });
          cache.writeQuery({
            query: LIST_DOCUMENT_TYPES,
            data: { listDocumentTypes },
          });
        }
        history.push('/administrador/tipos-de-documento');
      },
    })
  );
  const deleteDocumentType = useMutation(
    DELETE_DOCUMENT_TYPE,
    getOptions({
      mutationName: 'deleteDocumentType',
      modelName: 'documentType',
      message: 'Tipo de documento eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listDocumentTypes) {
          const { listDocumentTypes } = cache.readQuery({
            query: LIST_DOCUMENT_TYPES,
          });
          listDocumentTypes.edges = listDocumentTypes.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_DOCUMENT_TYPES,
            data: { listDocumentTypes },
          });
        }
        history.push('/administrador/tipos-de-documento');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionDocumentType}
        validateSchema={validationSchemaDocumentType}
        data={data}
        model={documentType}
        id={id}
        modelName="Tipos de documento"
        routeName="/administrador/tipos-de-documento"
        isEdit={isEdit}
        updateMutation={updateDocumentType}
        createMutation={createDocumentType}
        deleteMutation={deleteDocumentType}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

DocumentTypeForm.propTypes = {
  documentType: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

DocumentTypeForm.defaultProps = {
  documentType: null,
  id: '',
  isEdit: false,
};

export default DocumentTypeForm;
