import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import cx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Button,
} from '@material-ui/core';

import CustomOptions from 'src/components/CustomOptions';
import {
  edgeToList,
  errorMessage,
  successMessage,
} from 'src/utils/commonFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import { LIST_EMPLOYEES, LIST_S3FILES, LIST_RAZON_SOCIAL } from './queries';
import { FILE_DOWNLOAD } from '../EmployeeFiles/queries';
import { textLabels } from '../../../translations/components/MUIDataTable';

import useStyles from './styles';
import PDFViewerModal from 'src/components/PDFViewerModal';
import CustomColumnHeader from '../../TableComponents/CustomColumnHeader';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from 'file-saver';

function Employee(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [url, setUrl] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_RAZON_SOCIAL);
  const { loading: listLoading, error: listError, data: listData } = useQuery(
    LIST_EMPLOYEES,
  );
  const { error: error, data: data, loading: fileLoading } = useQuery(
    LIST_S3FILES,
  );
  const [downloadFile, { loadingFile, dataFile }] = useMutation(FILE_DOWNLOAD, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (!!data && !isDownload) {
        setUrl(data?.fileDownload?.url);
        setShowModal(true);
      } else {
        setShowModal(false);
        saveAs(data?.fileDownload?.url, data?.fileDownload?.fileName);
        successMessage('Archivo descargado.', enqueueSnackbar);
      }
    },
  });

  const getUrl = (id, isDownload) => {
    downloadFile({
      variables: { id, isDownload },
    });
  };

  function edgeToListEmployee(data) {
    if (data) {
      return data.listEmployees.edges.map(element => {
        const complete = {
          ...element.node,
        };
        complete.options = {
          clientId: element?.node?.id,
          fileId: '',
        };
        filesList.forEach(file => {
          if (file?.client?.id == element?.node?.id) {
            complete.options.fileId = file?.id;
          }
        });
        return complete;
      });
    }
    return [];
  }

  useEffect(() => {
    setEmployeeList(edgeToListEmployee(listData));
    if (!!data) {
      setFilesList(edgeToList(data, 'listEmployeeSheets'));
    }
    if (!!TypesData) {
      setTypeList(TypesData.razonSocialChoices);
    }
  }, [listData, data, TypesData]);

  const columns = [
    {
      name: 'user',
      label: 'Nombres',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return value.firstName;
        },
      },
    },
    {
      name: 'user',
      label: 'Apellidos',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return value.lastName + ' ' + value.maternalSurname;
        },
      },
    },
    {
      name: 'occupation',
      label: 'Cargo',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return value;
        },
      },
    },
    {
      name: 'razonSocial',
      label: 'Razón Social',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return typeList?.find(i => i.value == value)?.name;
        },
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        sort: false,
        customBodyRender: (value, row) => {
          return (
            <Tooltip title="Estado" placement="top">
              <Button
                variant="contained"
                size="small"
                className={cx(
                  classes.stateButton,
                  value == 'employee_completed' ? classes.orange : classes.gray,
                )}
              >
                <CheckCircleIcon />
              </Button>
            </Tooltip>
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={0} style={{ borderBottom: 'none', width: '10px' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
    {
      name: 'options',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const employee = value.clientId;
          const file = value.fileId;
          let options = [
            'oedit',
            file && 'oadditional',
            file && 'osecondadditional',
          ];
          return (
            <CustomOptions
              id={employee}
              model="Colaborador"
              options={options}
              additionalOption={
                <span>
                  <Tooltip title="Visualizar" placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={$ev => {
                        $ev.stopPropagation();
                        setIsDownload(false);
                        getUrl(file, false);
                      }}
                      className={classes.optionButtons}
                    >
                      <PageviewOutlinedIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              secondAdditionalOption={
                <span>
                  <Tooltip title="Descargar" placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={$ev => {
                        $ev.stopPropagation();
                        setIsDownload(true);
                        getUrl(file, true);
                      }}
                      className={classes.optionButtons}
                    >
                      <CloudDownloadIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={7} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `${history.location.pathname}/${rowData[rowData.length - 1].props.id}`,
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Nuevo Colaborador">
        <IconButton
          color="secondary"
          onClick={() => history.push(`${history.location.pathname}/crear`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    onRowClick: rowData => handleRowClick(rowData),
  };

  return (
    <>
      {!listLoading ? (
        <MUIDataTable
          title="Colaboradores"
          data={employeeList}
          columns={columns}
          options={options}
        />
      ) : (
        <LoadingIndicator />
      )}
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={loadingFile}
      />
    </>
  );
}

export default Employee;
