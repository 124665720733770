import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import { useHistory, Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

// GraphQl
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

// Components & Others

import CustomCard from '../../../CustomCard';
import {
  generateColumn,
  edgeToList,
  errorMessage,
} from '../../../../utils/commonFunctions';
import { LIST_CLIENT_CONTRACTS } from '../queries';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { getClientSelector } from '../../../../store/sharedStore/selectors';
import LoadingIndicator from '../../../LoadingIndicator';
import ParticipantDetail from '../../Fund/FundList/Raisings/Participants/ParticipantDetail';

// Styles & Images
import useClasses from './styles';

function ContracSignList() {
  const [contractsList, setContractsList] = useState([]);
  const client = useSelector(getClientSelector());
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [getContracts, { loading, error, data }] = useLazyQuery(
    LIST_CLIENT_CONTRACTS,
    {
      onError(error) {
        errorMessage(
          'Ocurrió un error al obtener los contratos por firmar.',
          enqueueSnackbar
        );
      },
    }
  );

  useEffect(() => {
    const assignees = edgeToList(client, 'clientAssignee');
    getContracts();
  }, [client]);

  if (error) {
    errorMessage(
      'Error al obtener la lista de tipos de contrato',
      enqueueSnackbar
    );
  }

  useEffect(() => {
    if (data) {
      const contracts = [];
      const participations = edgeToList(data, 'listParticipantVerifiedPayment');
      participations.forEach(participant => {
        const raisingParticipants = edgeToList(
          participant,
          'raisingparticipantSet'
        );
        if (raisingParticipants && raisingParticipants.length > 0) {
          contracts.push({
            id: participant.id,
            raisingNumber: raisingParticipants[0].raising.number,
            fundName: raisingParticipants[0].raising.fund.businessName,
          });
        }
      });
      setContractsList(contracts);
    }
  }, [data]);

  const columnOptions = {
    filter: false,
    sort: true,
  };

  const listColumns = [
    generateColumn('Fondo', 'fundName', columnOptions),
    generateColumn('Levantamiento', 'raisingNumber', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        return `Levantamiento ${value}`;
      },
    }),
    generateColumn('Opciones', 'id', {
      sort: false,
      filter: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={!value}
            component={Link}
            to={`/cliente/firma-contratos/${value}`}
          >
            Firmar contrato
          </Button>
        );
      },
    }),
  ];
  const listOptions = {
    responsive: 'standard',
    selectableRows: 'none',
    filter: false,
    print: false,
    download: false,
    textLabels,
  };

  return (
    <CustomCard
      disableFlex
      showHeader={false}
      content={
        loading ? (
          <LoadingIndicator />
        ) : (
          <MUIDataTable
            options={listOptions}
            title="Contratos"
            columns={listColumns}
            data={contractsList}
          />
        )
      }
    />
  );
}

export default ContracSignList;
