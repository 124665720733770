import { gql } from 'apollo-boost';

export const LIST_PREFERRED_SUBSCRIPTIONS_RAISING = gql`
  query listPreferredSubscriptionsRaising($raising: ID) {
    listPreferredSubscriptionClients(raisingId: $raising) {
      id
      user {
        username
        fullName
      }
    }
  }
`;

export const GET_PREFERRED_SUBSCRIPTION_CSV = gql`
  query getPreferredSubscriptionCsv($raising: ID) {
    preferredSubscriptionList(raisingId: $raising) {
      csv
      filename
    }
  }
`;
