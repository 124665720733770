// GraphQl
import { gql } from 'apollo-boost';

export const LIST_INSTRUMENTS = gql`
  {
    listInstrument {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_INSTRUMENT = gql`
  query getInstrument($id: ID!) {
    instrument(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_INSTRUMENT = gql`
  mutation createInstrument($input: InstrumentInput!) {
    createInstrument(input: $input) {
      instrument {
        id
        name
      }
    }
  }
`;

export const UPDATE_INSTRUMENT = gql`
  mutation updateInstrument($id: ID!, $input: InstrumentInput!) {
    updateInstrument(id: $id, input: $input) {
      instrument {
        id
        name
      }
    }
  }
`;

export const DELETE_INSTRUMENT = gql`
  mutation deleteInstrument($id: ID!) {
    deleteInstrument(id: $id) {
      ok
      message
    }
  }
`;
