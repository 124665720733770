import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paragraph: {
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    padding: 5,
    margin: 0,
    fontSize: '1rem',
  },
}));
