import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  cursorPointer: {
    cursor: 'pointer',
  },
  container: {
    padding: '.5rem',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  modalContent: {
    padding: '1.5rem',
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  image: {
    width: '40rem',
    height: 'auto',
  },
}));
