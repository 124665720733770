import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  GET_CLIENT_RELATIVE,
  CREATE_RELATIVE_PUBLIC_FUNCTIONS,
  UPDATE_RELATIVE_PUBLIC_FUNCTIONS,
  DELETE_RELATIVE_PUBLIC_FUNCTION,
} from '@queries/Client';
import Yup from '../../../../../utils/yup';
import { getOptions } from '../../../../../utils/commonFunctions';
import CreateEditTable from '../../../../CreateEditTable';
import { INPUT_VARIANT } from '../../../../Forms/StepsForm/fieldsSelector';

const validationSchema = Yup.object().shape({
  entity: Yup.string().required(),
  lastRole: Yup.string().required(),
  timeRole: Yup.number().required(),
});

function PublicFunctionsTable(props) {
  const { clientId, relativeId, data: publicFunctionsData } = props;
  const { enqueueSnackbar } = useSnackbar();

  const createPublicFunctions = useMutation(
    CREATE_RELATIVE_PUBLIC_FUNCTIONS,
    getOptions({
      mutationName: 'createRelativePublicFunction',
      modelName: 'publicFunction',
      message: 'Se creó la función pública del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
    })
  );
  const updatePublicFunctions = useMutation(
    UPDATE_RELATIVE_PUBLIC_FUNCTIONS,
    getOptions({
      mutationName: 'updateRelativePublicFunction',
      modelName: 'publicFunction',
      message: 'Función pública actualizada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
    })
  );
  const deletePublicFunctions = useMutation(
    DELETE_RELATIVE_PUBLIC_FUNCTION,
    getOptions({
      mutationName: 'createRelativePublicFunction',
      modelName: 'publicFunction',
      message: 'Función pública eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
    })
  );

  function formatBeforeSubmit(values) {
    const formatValues = { ...values };
    formatValues.relative = relativeId;
    formatValues.relation = 'HOLDER';
    return formatValues;
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const formStucture = [
    {
      label: 'Entidad',
      name: 'entity',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      label: 'Última función (cargo)',
      name: 'lastRole',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      label: 'Tiempo en el cargo (años)',
      name: 'timeRole',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.number,
    },
  ];

  const startValuesParser = values => {
    if (!values) {
      return {
        entity: '',
        lastRole: '',
        timeRole: '',
      };
    }
    return values;
  };

  return (
    <CreateEditTable
      table={{
        columns: [
          { label: 'Entidad', name: 'entity' },
          { label: 'Última función (Cargo)', name: 'lastRole' },
          { label: 'Tiempo', name: 'timeRole' },
        ],
        data: publicFunctionsData,
      }}
      mutations={{
        create: createPublicFunctions,
        update: updatePublicFunctions,
        _delete: deletePublicFunctions,
      }}
      strings={{
        tableName: 'Funciones Públicas',
        modelName: 'Función pública',
        createButtonText: 'Añadir función pública',
      }}
      form={{
        startValuesParser,
        validationFunction,
        validationSchema,
        formatBeforeSubmit,
        structure: formStucture,
      }}
    />
  );
}

PublicFunctionsTable.propTypes = {
  clientId: PropTypes.string.isRequired,
  relativeId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PublicFunctionsTable;
