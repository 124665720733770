import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem, List, Checkbox } from '@material-ui/core';

import useStyles from './styles';

function PermissionsMenu(props) {
  const { data, setData, userPermissions } = props;
  const classes = useStyles();

  useEffect(() => {
    if (userPermissions) {
      const tmpData = { ...data };
      userPermissions.forEach(permission => {
        let module = permission.slug.split('__')[0];
        tmpData[module].subModules[permission.slug].enable = true;
      });
      Object.entries(tmpData).map(module => {
        module[1].enable = Object.entries(module[1].subModules).every(
          subModule => subModule[1].enable
        );
      });
      setData(tmpData);
    }
  }, []);

  const handleModuleCheck = (e, module) => {
    const tmpData = { ...data };
    tmpData[module].enable = e.target.checked;
    Object.entries(tmpData[module].subModules).forEach(
      subModule => (subModule[1].enable = e.target.checked)
    );
    setData(tmpData);
  };

  const handleSubmoduleCheck = (e, slug) => {
    const tmpData = { ...data };
    const module = slug.split('__')[0];
    tmpData[module].subModules[slug].enable = e.target.checked;
    tmpData[module].enable = Object.entries(tmpData[module].subModules).every(
      subModule => subModule[1].enable
    );
    setData(tmpData);
  };

  return (
    <div className={classes.PermissionsMenu}>
      <Grid container>
        <Grid item xs={6} className={`${classes.title} ${classes.leftTitle}`}>
          Menu
        </Grid>
        <Grid item xs={6} className={`${classes.title} ${classes.rightTitle}`}>
          Submenu
        </Grid>
        {Object.entries(data).map(module => (
          <>
            <Grid
              item
              xs={6}
              className={`${classes.content} ${classes.leftPanel}`}
            >
              <Grid item xs={12}>
                <Checkbox
                  checked={module[1].enable}
                  onChange={e => handleModuleCheck(e, module[0])}
                />
                {module[1].name}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              className={`${classes.content} ${classes.rightPanel}`}
            >
              <List dense className={classes.main}>
                {Object.entries(module[1].subModules).map(subModule => {
                  return (
                    <ListItem key={subModule[0]}>
                      <Checkbox
                        onChange={e => handleSubmoduleCheck(e, subModule[0])}
                        checked={subModule[1].enable}
                      />
                      {subModule[1].name}
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}

PermissionsMenu.propTypes = {
  userPermissions: PropTypes.array.isRequired,
  data: PropTypes.shape().isRequired,
  setData: PropTypes.func.isRequired,
};

PermissionsMenu.defaultProps = {};

export default PermissionsMenu;
