import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btn: {
    width: '100%',
    marginBottom: 10,
    textTransform: 'capitalize',
    borderRadius: '8px',
    background: '#007DFA',
    height: '40px',
    textAlign: 'center',
    '&:hover': {
      background: '#007DFA',
    },
  },
  form: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',
    flexDirection: 'column',
    height: '100%',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  layout: {
    width: '100%',
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    flexDirection: 'column',
  },
  main: {
    position: 'relative',
    zIndex: 999,
  },
  mssgDiv: {
    border: '2px solid #82A9C9',
    background: '#D0DCE6',
    width: '100%',
    borderRadius: '5px',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5px',
  },
  mssgText: {
    color: '#0E2639',
    fontSize: '0.80rem',
    margin: 0,
  },
  forgetPass: {
    color: '#007DFA',
    fontSize: '1.7vh',
    fontWeight: '500',
    cursor: 'pointer',
  },
  errorText: {
    fontSize: '1.6vh',
    color: '#FD7272',
    textAlign: 'center',
    fontWeight: '400',
  },
}));
