// GraphQl
import { gql } from 'apollo-boost';

export const LIST_ACCOUNT_TYPES = gql`
  {
    listAccountType {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TYPE = gql`
  query getAccountType($id: ID!) {
    accountType(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_ACCOUNT_TYPE = gql`
  mutation createAccountType($input: AccountTypeInput!) {
    createAccountType(input: $input) {
      accountType {
        id
        name
      }
    }
  }
`;

export const UPDATE_ACCOUNT_TYPE = gql`
  mutation updateAccountType($id: ID!, $input: AccountTypeInput!) {
    updateAccountType(id: $id, input: $input) {
      accountType {
        id
        name
      }
    }
  }
`;

export const DELETE_ACCOUNT_TYPE = gql`
  mutation deleteAccountType($id: ID!) {
    deleteAccountType(id: $id) {
      ok
      message
    }
  }
`;
