import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import DocumentTypeForm from '../DocumentTypeForm';
import { GET_DOCUMENT_TYPE } from '../queries';

function DocumentTypeEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_DOCUMENT_TYPE, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error(error);
    history.goBack();
  }

  const formatValue = { ...data.documentType };
  if (!data.documentType) {
    history.goBack();
  }
  return <DocumentTypeForm documentType={formatValue} id={id} isEdit />;
}

DocumentTypeEdit.propTypes = {};

export default DocumentTypeEdit;
