import { useQuery } from '@apollo/react-hooks';
import { LIST_NEWS } from '@queries/Client';

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import BuhoIcon from './svg/buhoIcon';

export default function SimpleNoteCard() {
  const classes = useStyles();
  const { loading, data: list } = useQuery(LIST_NEWS);
  const apiData = list?.listNews?.edges;
  const data = apiData?.filter(
    item => item?.node?.newsType === 'SN' && item?.node?.state === true,
  );

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === data?.length - 1 ? 0 : prevIndex + 1,
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [data?.length]);

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === data?.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? data?.length - 1 : prevIndex - 1,
    );
  };

  return (
    <>
      {data?.length > 0 && (
        <div className={classes.card}>
          <div className={classes.sliderContainer}>
            <div className={classes.cardContainer}>
              {data.map(({ node }, index) => (
                <>
                  {node.newsType === 'SN' && node.state && (
                    <div
                      key={node.id}
                      className={`${classes.newsDiv} ${
                        index === currentIndex
                          ? classes.active
                          : classes.inactive
                      }`}
                      style={{
                        display: index === currentIndex ? 'block' : 'none',
                      }}
                    >
                      <div className={classes.container}>
                        <div className={classes.buhoDiv}>
                          <BuhoIcon />
                        </div>

                        <p className={classes.boldText}>{node.title}</p>
                        <p className={classes.description}>
                          {node.description}
                        </p>

                        {node.hasButton && (
                          <div className={classes.buttonDiv}>
                            <button
                              onClick={() => {
                                window.open(node.link, '_blank');
                              }}
                              className={classes.buttom}
                            >
                              {node.buttonTitle}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className={classes.arrowContainer}>
              <button className={classes.arrowButton} onClick={handlePrev}>
                {'<'}
              </button>
              <button className={classes.arrowButton} onClick={handleNext}>
                {'>'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
