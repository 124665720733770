import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../../Forms/SimpleForm';
import {
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  LIST_USERS,
} from '../../queries';
import {
  validationFunctionAdministrator,
  validationSchemaAdministrator,
} from './validation';
import { getOptions } from '../../../../../utils/commonFunctions';

const USER_TYPE_ADMINISTRATIVO = 'ADMINISTRATIVO';

const startValues = {
  firstName: '',
  lastName: '',
  maternalSurname: '',
  email: '',
  documentNumber: '',
  isStaff: 'true',
};

function AdministratorForm(props) {
  const { administrator, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateAdministrator = useMutation(
    UPDATE_USER,
    getOptions({
      mutationName: 'updateUser',
      modelName: 'user',
      message: 'Administrador actualizado con éxito.',
      enqueueSnackbar,
    }),
  );
  const createAdministrator = useMutation(
    CREATE_USER,
    getOptions({
      mutationName: 'createUser',
      modelName: 'user',
      message: 'Administrador creado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_USERS, variables: { userType: 'AD' } }],
      completeCallback: () => history.push('/administrador/administradores'),
    }),
  );
  const deleteAdministrator = useMutation(
    DELETE_USER,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Administrador eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_USERS, variables: { userType: 'AD' } }],
      completeCallback: () => history.push('/administrador/administradores'),
    }),
  );

  const data = [
    {
      label: 'Nombre',
      name: 'firstName',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Apellido paterno',
      name: 'lastName',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Apellido materno',
      name: 'maternalSurname',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Email',
      name: 'email',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'DNI',
      name: 'documentNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
  ];

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.userType = USER_TYPE_ADMINISTRATIVO;
    newValues.documentType = newValues.documentType?.id;
    delete newValues.documentType?.__typename;
    delete newValues.clientSet;
    return newValues;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionAdministrator}
        validateSchema={validationSchemaAdministrator}
        formatBeforeSubmit={formatBeforeSubmit}
        data={data}
        model={administrator}
        id={id}
        modelName="Administrador"
        routeName="/administrador/administradores"
        isEdit={isEdit}
        updateMutation={updateAdministrator}
        createMutation={createAdministrator}
        deleteMutation={deleteAdministrator}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

AdministratorForm.propTypes = {
  administrator: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

AdministratorForm.defaultProps = {
  administrator: null,
  id: '',
  isEdit: false,
};

export default AdministratorForm;
