/*
 * HomePage
 */

import React from 'react';

// Othersimport { useSelector } from 'react-redux';

// Components & Others
import SignatureClientList from 'src/components/Models/Signatures/SignatureClientList';
import { Grid } from '@material-ui/core';

function SignatureClientPage() {
  return (
    <Grid container alignItems="start">
      <Grid item xs={12}>
        <SignatureClientList />
      </Grid>
    </Grid>
  );
}

SignatureClientPage.propTypes = {};

export default SignatureClientPage;
