import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Button,
} from '@material-ui/core';

import CustomCard from '../../CustomCard';
import CustomOptions from '../../CustomOptions';
import LoadingIndicator from '../../LoadingIndicator';
import {
  LIST_PROMOTER_COMPANIES,
  DELETE_PROMOTER_COMPANY,
  UPDATE_PROMOTER_COMPANY,
} from './queries';
import {
  edgeToList,
  generateColumn,
  getOptions,
} from '../../../utils/commonFunctions';
import { textLabels } from '../../../translations/components/MUIDataTable';

import useStyles from './styles';

function PromoterBusiness(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [promoterList, setPromoterList] = useState([]);

  const [deletePromoter] = useMutation(
    DELETE_PROMOTER_COMPANY,
    getOptions({
      mutationName: 'deletePromoterCompany',
      modelName: 'promoterCompany',
      message: 'Empresa promotora eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTER_COMPANIES }],
    })
  );
  const [updatePromoterState] = useMutation(
    UPDATE_PROMOTER_COMPANY,
    getOptions({
      mutationName: 'updatePromoterCompany',
      modelName: 'promoterCompany',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTER_COMPANIES }],
    })
  );
  const { loading: listLoading, error: listError, data: listData } = useQuery(
    LIST_PROMOTER_COMPANIES
  );

  useEffect(() => {
    setPromoterList(edgeToList(listData, 'listPromoterCompany'));
  }, [listData]);

  const changeState = (enabled, id) => {
    updatePromoterState({
      variables: {
        id,
        input: {
          enabled: !enabled,
        },
      },
    });
  };

  const columns = [
    {
      name: 'enabled',
      label: 'Estado',
      options: {
        sort: false,
        customBodyRender: (enabled, row) => (
          <Tooltip title="Estado" placement="top">
            <Button
              variant="contained"
              size="small"
              className={`${classes.stateButton} ${
                enabled ? classes.orange : classes.gray
              }`}
              onClick={$ev => {
                $ev.stopPropagation();
                changeState(enabled, row.rowData[3]);
              }}
            >
              <CheckCircleIcon />
            </Button>
          </Tooltip>
        ),
        customHeadRender: columnMeta => {
          return (
            <TableCell key={0} style={{ borderBottom: 'none', width: '10px' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
    generateColumn('Razón Social', 'businessName'),
    generateColumn('RUC', 'ruc'),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Banco"
              options={['oedit', 'odelete']}
              handleDelete={() => {
                deletePromoter({ variables: { id } });
              }}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={4} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `${history.location.pathname}/${rowData[rowData.length - 1].props.id}`
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Nueva Empresa Promotora">
        <IconButton
          color="secondary"
          onClick={() => history.push(`${history.location.pathname}/crear`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    onRowClick: rowData => handleRowClick(rowData),
  };

  return listLoading ? (
    <LoadingIndicator />
  ) : (
    <CustomCard disableFlex title="Empresa Promotora">
      <MUIDataTable
        title="Empresas Promotoras"
        data={promoterList}
        columns={columns}
        options={options}
      />
    </CustomCard>
  );
}

export default PromoterBusiness;
