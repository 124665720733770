/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import SafiRelationList from '../../../components/Models/SafiRelation/SafiRelationList';
import SafiRelationEdit from '../../../components/Models/SafiRelation/SafiRelationCreateEdit/edit';
import SafiRelationForm from '../../../components/Models/SafiRelation/SafiRelationForm';

function SafiRelationPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SafiRelationList} />
      <Route path={`${path}/crear`} component={SafiRelationForm} />
      <Route path={`${path}/:id`} component={SafiRelationEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default SafiRelationPage;
