import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { useFormikContext } from 'formik';

function Checkboxes(props) {
  const {
    label,
    name,
    other,
    otherData,
    otherFund,
    otherFundData,
    data,
    mapData,
    error,
    selected,
    setFieldValue: setFieldValues,
  } = props;
  const { setFieldValue } = useFormikContext();
  const [values, setValues] = useState([]);
  const classes = useStyles();

  useEffect(() => {

    if (selected.length >= 0) {
      setValues(selected);
    }
  }, [selected, name]);

  function handleChange(event) {
    const { checked, value } = event.target;
    let updatedValues = [...values];

    if (checked) {
      if (!updatedValues.includes(value)) {
        updatedValues.push(value);
      }
    } else {
      updatedValues = updatedValues.filter(v => v !== value);
    }

    if (value === 'other' && !checked) {
      setFieldValue(otherData.name, '');
    }
    if (value === 'otherFund' && !checked) {
      setFieldValue(otherFundData.name, '');
    }
    setValues(updatedValues);
  }

  useEffect(() => {
    if (values.length > 0) {
      setFieldValue(name, values);
    }
  }, [values]);

  return (
    <div className={classes.formControl}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.checkboxGroup}>
        {data.map(element => (
          <div key={element[mapData.value]} className={classes.checkboxItem}>
            <input
              type="checkbox"
              checked={values.includes(element[mapData.value])}
              value={element[mapData.value]}
              onChange={handleChange}
              id={element[mapData.value]}
            />
            <label htmlFor={element[mapData.value]}>
              {element[mapData.label]}
            </label>
          </div>
        ))}
        {other && (
          <div className={classes.other}>
            <input
              type="checkbox"
              checked={values.includes('other')}
              onChange={handleChange}
              value="other"
              id="other"
            />
            <label htmlFor="other">{otherData.label}</label>
            <input
              type="text"
              disabled={!values.includes('other')}
              name={otherData.name}
              placeholder={otherData.label}
              className={classes.otherInput}
            />
          </div>
        )}
        {otherFund && (
          <div className={classes.other}>
            <input
              type="checkbox"
              checked={values.includes('otherFund')}
              onChange={handleChange}
              value="otherFund"
              id="otherFund"
            />
            <label htmlFor="otherFund">{otherFundData.label}</label>
            <input
              type="text"
              disabled={!values.includes('otherFund')}
              name={otherFundData.name}
              placeholder={otherFundData.label}
              className={classes.otherInput}
            />
          </div>
        )}
        {error && <span className={classes.errorText}>{error}</span>}
      </div>
    </div>
  );
}

Checkboxes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  selected: PropTypes.array,
  other: PropTypes.bool,
  otherData: PropTypes.shape(),
  otherFund: PropTypes.bool,
  otherFundData: PropTypes.shape(),
};

Checkboxes.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  label: '',
  error: '',
  selected: [],
  other: false,
  otherData: {
    name: 'otherSafiRelation',
    label: 'Otro tipo de vinculación o relación',
  },
  otherFund: false,
  otherFundData: {
    name: 'otherFund',
    label: 'Otro fondo de inversión',
  },
};

export default Checkboxes;
