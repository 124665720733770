import React, { useEffect, useState } from 'react';
import {
  REACT_APP_LINK_FORM_F2,
  REACT_APP_ASAMBLEA,
} from '../../utils/constants';
import { useSelector } from 'react-redux';
import CustomCard from '../CustomCard';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import GroupsIcon from '@material-ui/icons/GroupOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CreateIcon from '@material-ui/icons/Create';

import useStyles from './styles';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getClientSelector } from '../../store/sharedStore/selectors';
import CryptoJS from 'crypto-js';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { getDBIdFromGraphqlId } from '../../utils/commonFunctions';
import InvestorDashboardPage from './investorDashboard';
const estadosDeCuenta = ['estados_de_cuenta_de_inversiones'];

const firmas_cliente = ['firmas_cliente'];
const documentos = ['documentos'];
const documentos_generales = ['documentos_generales'];
const fichaCliente = ['ficha_cliente'];
const fichaColaborador = ['ficha_colaborador'];
const fondosDeInversion = [
  'firma_contratos',
  'firma_contratos_mercado_secundario',
  'registro_pagos',
  'documentos',
];

function ClientDashboard(props) {
  const { setMenu, setSecondLogo, newClient } = props;
  const classes = useStyles();
  const history = useHistory();
  const client = useSelector(getClientSelector());
  const [isEmployee, setIsEmployee] = useState(false);
  const setCustomMenu = rutas => {
    setMenu(rutas);
    // Save menu data in cache memory
    localStorage.setItem('menu', JSON.stringify(rutas));
  };
  const base64url = source => {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  };
  useEffect(() => {
    if (client && client.isEmployee) {
      setIsEmployee(true);
    }
  }, [client]);

  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const token = () => {
    let header = {
      alg: 'HS256',
      typ: 'JWT',
    };

    let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    let encodedHeader = base64url(stringifiedHeader);
    let id = '';
    if (clients && clients.currentUser)
      id = getDBIdFromGraphqlId(clients?.currentUser?.id, 'UserType');
    let data = {
      id: id,
      documentType: client?.user?.documentType.code,
      documentNumber: client?.user?.documentNumber,
      communicationEmail: clients?.currentUser?.email,
      firstName: client?.user?.firstName,
      lastName: client?.user?.lastName,
      maternalSurname: client?.user?.maternalSurname,
      role: 'investor',
    };
    let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    let encodedData = base64url(stringifiedData);
    let secret = 'core2';
    let unsigned_token = encodedHeader + '.' + encodedData;
    let signature = CryptoJS.HmacSHA256(unsigned_token, secret);
    signature = base64url(signature);
    let signedToken = unsigned_token + '.' + signature;

    return signedToken;
  };

  const UserToken = token();
  const url = `${REACT_APP_LINK_FORM_F2}?token=${UserToken}`;
  const asamblea_url = REACT_APP_ASAMBLEA;

  return (
    <>
      <InvestorDashboardPage userUrlF2={url} />
    </>
  );
}

ClientDashboard.propTypes = {};

export default ClientDashboard;
