import Yup from '../../../../utils/yup';

export function validationFunctionNews(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaNews = Yup.object().shape({
  title: Yup.string().required(),
  newsType: Yup.string().required(),
});
