import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';
import { IconButton, Tooltip, TableCell, Typography } from '@material-ui/core';
import {
  Description as DescriptionIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
// Components & Others
import LoadingIndicator from '../../../../LoadingIndicator';
import {
  APPROVE_COMPLIANCE_CLIENT,
  REFRESH_RISK_PROFILE_CLIENT,
} from '../../../Compliance/queries';
import { LIST_REJECTED_CLIENT_USERS } from '../../queries';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../../CustomOptions';
import {
  edgeToList,
  getOptions,
  successMessage,
} from '../../../../../utils/commonFunctions';
import { textLabels } from '../../../../../translations/components/MUIDataTable';

import useStyles from './styles';

function RejectedClientList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [rejectedClientList, setRejectedClientList] = useState([]);
  const [disabledButtons, setDisabledButtons] = useState(true);
  const { loading: listLoading, error, data: listData } = useQuery(
    LIST_REJECTED_CLIENT_USERS
  );

  const [approveComplianceClient] = useMutation(
    APPROVE_COMPLIANCE_CLIENT,
    getOptions({
      mutationName: 'approveComplianceClient',
      message: 'Cliente Aprobado',
      modelName: 'approved',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_REJECTED_CLIENT_USERS }],
    })
  );
  const [
    refreshRiskProfileClient,
    {
      data: dataRefreshProfile,
      loading: loadingRefreshProfile,
      error: errorRefreshProfile,
    },
  ] = useMutation(
    REFRESH_RISK_PROFILE_CLIENT,
    getOptions({
      mutationName: 'refreshRiskProfileClient',
      message: 'Reporte LAFT actualizado',
      modelName: 'refreshed',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_REJECTED_CLIENT_USERS }],
    })
  );

  useEffect(() => {
    if (dataRefreshProfile && !loadingRefreshProfile) {
      setDisabledButtons(false);
    }
  }, [loadingRefreshProfile, dataRefreshProfile]);

  if (error) {
    console.error('list clients', error);
  }

  function getUsersByClients(clients) {
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user, promoter } = element;
      user = {
        ...user,
        promoter: `${promoter.firstName} ${promoter.lastName}`,
      };
      user.optionValue = {
        id: user.id,
        urlRiskReport: element.urlRiskReport,
      };
      return user;
    });
  }

  useEffect(() => {
    if (listData) {
      setRejectedClientList(
        getUsersByClients(edgeToList(listData, 'listClients'))
      );
    }
  }, [listData]);

  const columns = [
    {
      name: 'firstName',
      label: 'Nombre',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'lastName',
      label: 'Apellido Paterno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={1}
              key={1}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'maternalSurname',
      label: 'Apellido Materno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={2}
              key={2}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={3}
              key={3}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'promoter',
      label: 'People Leader',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={3}
              key={3}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'optionValue',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const { id, urlRiskReport } = value;
          return (
            <CustomOptions
              id={id}
              model="Banco"
              options={['oadditional', 'ocustom', 'oconfirm', 'odeny']}
              disabledButtons={disabledButtons}
              additionalOption={
                <span>
                  <Tooltip title={'Recargar'} placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        successMessage(
                          'Actualizando Reporte LAFT ...',
                          enqueueSnackbar
                        );
                        refreshRiskProfileClient({ variables: { userId: id } });
                      }}
                    >
                      <RefreshIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              customOption={
                <span>
                  <Tooltip title={'Reporte de Riesgo'} placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => window.open(urlRiskReport, '_blank')}
                    >
                      <DescriptionIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              handleConfirmOption={() =>
                approveComplianceClient({ variables: { userId: id } })
              }
              handleDenyOption={() => setDisabledButtons(true)}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={4} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
  };

  return listLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <MUIDataTable
        title="Inversionistas desaprobados"
        data={rejectedClientList}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default RejectedClientList;
