/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable';

import loginReducer from '../containers/LoginPage/reducer';
import clientFormReducer from '../containers/ModelsPages/ClientForm/reducer';
import SharedStoreReducer from './sharedStore/reducer';
import PermissionsStoreReducer from './permissionsStore/reducer';
import UtilsStoreReducer from './utils-store/reducer';

export default function createReducer() {
  return combineReducers({
    // Add here all the reducers you want to use.
    sharedStore: SharedStoreReducer,
    permissionsStore: PermissionsStoreReducer,
    login: loginReducer,
    clientForm: clientFormReducer,
    utils: UtilsStoreReducer,
  });
}
