import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_CLIENT_ACADEMIC_BACKGROUND,
  CREATE_CLIENT_ACADEMIC_BACKGROUND,
  UPDATE_CLIENT_ACADEMIC_BACKGROUND,
  DELETE_CLIENT_ACADEMIC_BACKGROUND,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import useStyles from './styles';
import SimpleForm from '../../../Forms/SimpleForm';
import LoadingIndicator from '../../../LoadingIndicator';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { LIST_DOCUMENT_TYPES } from '../../../Models/DocumentType/queries';
import { LIST_EDUCATION_LEVELS } from '../../../Models/EducationLevel/queries';

const relativeValidationSchema = Yup.object().shape({
  degree: Yup.string().required(),
  educationalInstitution: Yup.string().required(),
  educationLevel: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
});

function AcademicBackgroundForm(props) {
  const {
    academicBackgroundData,
    clientId,
    closeForm,
    setCurrentAcademicBackground,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [academicBackgroundId, setAcademicBackgroundId] = useState(null);
  const startAcademicBackgroundData = {
    educationLevel: '',
    degree: '',
    educationalInstitution: '',
    startDate: '',
    endDate: '',
  };

  const { loading: documentTypeLoading, data: documentTypeData } = useQuery(
    LIST_DOCUMENT_TYPES,
  );
  const {
    error: EducationLevelError,
    data: EducationLevelData,
    loading: EducationLevelLoading,
  } = useQuery(LIST_EDUCATION_LEVELS);

  const createAcademicBackgroundMutation = useMutation(
    CREATE_CLIENT_ACADEMIC_BACKGROUND,
    getOptions({
      mutationName: 'createAcademicBackground',
      modelName: 'academicBackground',
      message: 'Se guardó datos de la formacion academica con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_ACADEMIC_BACKGROUND, variables: { id: clientId } },
      ],
      completeCallback: data => {
        setAcademicBackgroundId(data.id);
        setCurrentAcademicBackground(data.id);
        closeForm();
      },
    }),
  );

  const updateAcademicBackgroundMutation = useMutation(
    UPDATE_CLIENT_ACADEMIC_BACKGROUND,
    getOptions({
      mutationName: 'updateAcademicBackground',
      modelName: 'academicBackground',
      message: 'Se actualizó datos de la formacion academica con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_ACADEMIC_BACKGROUND, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const deleteAcademicBackgroundMutation = useMutation(
    DELETE_CLIENT_ACADEMIC_BACKGROUND,
    getOptions({
      mutationName: 'deleteAcademicBackground',
      modelName: 'academicBackground',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_ACADEMIC_BACKGROUND, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const data = [
    {
      isField: true,
      label: '* Nivel de Instruccion',
      name: 'educationLevel',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.select,
      data: edgeToList(EducationLevelData, 'listEducationLevels'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      isField: true,
      label: 'Grado Obtenido',
      name: 'degree',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: true,
      label: 'Centro de estudios',
      name: 'educationalInstitution',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: true,
      label: '* Fecha de Inicio',
      name: 'startDate',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
    },
    {
      isField: true,
      label: '* Fecha de Término',
      name: 'endDate',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
    },
  ];

  const validateAcademicBackgroundData = () => {};
  const formatBeforeSubmit = v => {
    const newValues = { ...v };
    return { ...newValues, client: clientId };
  };

  useEffect(() => {
    if (academicBackgroundData) {
      setAcademicBackgroundId(academicBackgroundData.id);
      if (academicBackgroundData.educationLevel?.hasOwnProperty('id')) {
        academicBackgroundData.educationLevel =
          academicBackgroundData.educationLevel.id;
      }
    }
  }, [academicBackgroundData]);

  if (EducationLevelLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Container>
      <div className={classes.formContainer}>
        <SimpleForm
          model={academicBackgroundData}
          initialValues={startAcademicBackgroundData}
          validateFunction={validateAcademicBackgroundData}
          validateSchema={relativeValidationSchema}
          data={data}
          modelName="Formación Académica"
          hasCancel
          isEdit={!!academicBackgroundId}
          id={academicBackgroundId}
          routeName=""
          onCancel={closeForm}
          updateMutation={updateAcademicBackgroundMutation}
          createMutation={createAcademicBackgroundMutation}
          deleteMutation={deleteAcademicBackgroundMutation}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      </div>
    </Container>
  );
}

AcademicBackgroundForm.propTypes = {
  academicBackgroundData: PropTypes.shape().isRequired,
  clientId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setCurrentAcademicBackground: PropTypes.func.isRequired,
};

export default AcademicBackgroundForm;
