import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import {
  IconButton,
  Input,
  Button,
  Tooltip,
  Typography,
  TableCell,
} from '@material-ui/core';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import RefreshIcon from '@material-ui/icons/Refresh';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';

// GraphQl
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../../CustomOptions';
import {
  LIST_CLIENT_USERS,
  DELETE_USER,
  GET_REPORT_EXCEL,
} from '../../queries';
import {
  LIST_CLIENT_SHEETS,
  FILE_DOWNLOAD,
} from 'src/components/Models/Files/queries';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import {
  downloadBase64Document,
  edgeToList,
  getOptions,
  errorMessage,
} from '../../../../../utils/commonFunctions';
import { textLabels } from '../../../../../translations/components/MUIDataTable';
import { GET_CLIENT_PDF } from '../../../../../containers/ModelsPages/ClientForm/queries';

// Styles & Images
import useStyles from './styles';
import CustomModal from '../../../../CustomModal';
import {
  REACT_APP_SERVER_LINK,
  REACT_APP_LINK_FORM_F2,
} from '../../../../../utils/constants';
import TokenGen from '../../../../../containers/ClientPage/utils/TokenGe';
import PDFViewerModal from '../../../../PDFViewerModal';

function CustomToolbar() {
  const history = useHistory();

  const [getReport] = useLazyQuery(GET_REPORT_EXCEL, {
    fetchPolicy: 'no-cache',
    onCompleted: pdfResponse => {
      if (pdfResponse.clientsExcel) {
        downloadBase64Document(
          pdfResponse.clientsExcel.xlsx,
          pdfResponse.clientsExcel.filename,
          'xlsx',
        );
      }
    },
  });

  const handleClick = () => {
    history.push('/administrador/clientes/crear');
  };
  const handleReport = () => {
    getReport();
  };

  return (
    <Tooltip title="Descargar reporte">
      <IconButton color="secondary" onClick={handleReport}>
        <AssignmentReturnedIcon />
      </IconButton>
    </Tooltip>
  );
}

function ClientList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [photoDocument, setPhotoDocument] = useState({ front: '', back: '' });
  const [clientList, setClientList] = useState([]);
  const [pageInfo, setPageInfo] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [listLength, setListLength] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchTextInput, setSearchTextInput] = useState('');
  const [filesList, setFilesList] = useState([]);
  const [url, setUrl] = useState('');

  const [deleteClient] = useMutation(
    DELETE_USER,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Cliente eliminado con éxito.',
      refetchQueries: [{ query: LIST_CLIENT_USERS }],
      enqueueSnackbar,
    }),
  );
  const { loading, error, data, fetchMore, refetch } = useQuery(
    LIST_CLIENT_USERS,
    {
      variables: {
        search: searchText ? searchText : null,
        first: 10,
        afterCursor: null,
        last: null,
        beforeCursor: null,
      },
    },
  );

  const [getPDF, { loading: pdfLoading, data: pdfData }] = useLazyQuery(
    GET_CLIENT_PDF,
    {
      fetchPolicy: 'no-cache',
      onCompleted: pdfResponse => {
        if (pdfResponse.pdfClient) {
          downloadBase64Document(
            pdfResponse.pdfClient.pdf,
            pdfResponse.pdfClient.filename,
            'pdf',
          );
        }
      },
    },
  );

  const {
    error: errorSheets,
    data: dataSheets,
    loading: fileLoading,
  } = useQuery(LIST_CLIENT_SHEETS);

  const [downloadFile, { loadingFile, dataFile }] = useMutation(FILE_DOWNLOAD, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (!!data) {
        setUrl(data?.fileDownload?.url);
        setShowModal(true);
      }
    },
  });

  const getUrl = (id, isDownload) => {
    downloadFile({
      variables: { id, isDownload },
    });
  };

  const downloadFromLink = link => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = link;
    a.setAttribute('download', true);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
  };

  if (error) {
    console.error('list clients', error);
  }

  useEffect(() => {
    if (!!data) {
      setTotalCount(data.listClients.totalCount);
      setPageInfo(data.listClients.pageInfo);
      setClientList(getUsersByClients(edgeToList(data, 'listClients')));
    }
    if (!!dataSheets) {
      setFilesList(edgeToList(dataSheets, 'listClientSheets'));
    }
  }, [data, dataSheets]);

  const columns = [
    {
      name: 'firstName',
      label: 'Nombre',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'lastName',
      label: 'Apellido Paterno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={1}
              key={1}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'maternalSurname',
      label: 'Apellido Materno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={2}
              key={2}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={3}
              key={3}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'promoter',
      label: 'Promotor',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return (
            <Typography variant="subtitle1" align="left">
              {value && `${value.firstName} ${value.lastName}`}
            </Typography>
          );
        },
      },
    },
    {
      name: 'coreConsultant',
      label: 'Asesor',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return (
            <Typography variant="subtitle1" align="left">
              {value && `${value.firstName} ${value.lastName}`}
            </Typography>
          );
        },
      },
    },
    {
      name: 'isActive',
      label: 'Activo',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={6}
              key={6}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => (value ? 'Sí' : 'No'),
      },
    },
    {
      name: 'core2Uuid',
      label: 'Ficha 2',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={7}
              key={7}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => (value ? 'Si' : 'No'),
      },
    },
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const values = value;
        const client = edgeToList(data, 'listClients').find(
          e => e.id === values?.clientId,
        );
        const showAttached = values.clientId && values.lastSignatureDate;
        const showFR = values.clientId && values.fileId;
        const isActive = values.clientId && values.isActive;
        let options = [
          isActive && 'oedit',
          'osecondadditional',
          'ocustom',
          'oadditional',
          'othirdadditional',
          showFR && 'ofourthadditional',
          // 'odelete',
        ];
        if (showAttached) options.push('oattached');
        return (
          <CustomOptions
            id={values?.id}
            key={values?.id}
            model="Cliente"
            options={options}
            handleEdit={() => {
              history.push(`/administrador/clientes/${values?.clientId}`);
            }}
            handleDelete={() => {
              deleteClient({ variables: { id: values?.id } });
              data.listClients.edges = data.listClients.edges.filter(
                e => e.node.id !== values?.id,
              );
            }}
            handleAttach={() => {
              getPDF({ variables: { id: values?.clientId } });
            }}
            secondAdditionalOption={
              <span>
                <Tooltip arrow title="Validacion LAFT" placement="top">
                  <IconButton
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => window.open(values?.urlRiskReport, '_blank')}
                    disabled={values?.clientId && !values?.urlRiskReport}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
            customOption={
              <span>
                <Tooltip arrow title="Ver documento" placement="top">
                  <IconButton
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {
                      setPhotoDocument({
                        front: values?.photoDocumentFront,
                        back: values?.photoDocumentBack,
                      });
                      setShowModal(true);
                    }}
                    disabled={values?.clientId && !values?.photoDocumentFront}
                  >
                    <RecentActorsOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
            additionalOption={
              <span>
                <Tooltip arrow title="Descargar documentos" placement="top">
                  <IconButton
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() =>
                      downloadFromLink(
                        `${REACT_APP_SERVER_LINK}${values?.photoDocumentsUrl}`,
                      )
                    }
                    disabled={values?.clientId && !values?.photoDocumentFront}
                  >
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
            thirdAdditionalOption={
              <span>
                <Tooltip arrow title="Actualizar FC" placement="top">
                  <IconButton
                    color="secondary"
                    size="large"
                    className={classes.optionIcon}
                    onClick={() => {
                      const UserToken = TokenGen(client, [], 'admin');
                      const urlF2 = `${REACT_APP_LINK_FORM_F2}?token=${UserToken}`;
                      window.open(urlF2, '_blank');
                    }}
                  >
                    <PersonIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
            fourthAdditionalOption={
              <span>
                <Tooltip arrow title="Ficha Reniec" placement="top">
                  <IconButton
                    color="secondary"
                    size="large"
                    className={classes.optionIcon}
                    onClick={$ev => {
                      $ev.stopPropagation();
                      getUrl(values?.fileId, false);
                    }}
                  >
                    <PageviewOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={7} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    pagination: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <CustomToolbar />
          <Tooltip title="Refrescar">
            <IconButton
              color="secondary"
              onClick={e => {
                setPageLength(10);
                setSearchText('');
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customSearchRender: () => {
      return (
        <div>
          <Input
            autoFocus
            className={classes.searchInput}
            variant="outlined"
            type="text"
            onChange={e => {
              setSearchTextInput(e.target.value);
            }}
          ></Input>
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={() => {
              setSearchText(searchTextInput);
            }}
          >
            {' '}
            Buscar{' '}
          </Button>
        </div>
      );
    },
    onSearchClose: () => {
      setPageLength(10);
      setSearchText('');
      refetch();
    },
  };

  const parseResults = (result1, result2) => {
    let tmpResult1 = '-';
    let tmpResult2 = '-';
    if (result1 <= 10) {
      tmpResult1 = 'Bajo';
    } else if (result1 > 10 && result1 <= 25) {
      tmpResult1 = 'Moderado';
    } else if (result1 > 25 && result1 <= 32) {
      tmpResult1 = 'Arriesgado';
    }
    if (result2 <= 10) {
      tmpResult2 = 'Bajo';
    } else if (result2 > 10 && result2 <= 30) {
      tmpResult2 = 'Moderado';
    } else if (result2 > 30 && result2 <= 40) {
      tmpResult2 = 'Arriesgado';
    }
    return { result1: tmpResult1, result2: tmpResult2 };
  };

  function getUsersByClients(clients) {
    setListLength(clients.length);
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user } = element;
      user = {
        ...user,
        ...parseResults(element.result1, element.result2),
        ...{ core2Uuid: element.core2Uuid },
        ...{ promoter: element.promoter },
        ...{ coreConsultant: element.coreConsultant },
      };
      user.options = {
        id: user.id,
        clientId: element.id,
        photoDocumentFront: element.photoDocumentFront,
        photoDocumentBack: element.photoDocumentBack,
        photoDocumentsUrl: element.photoDocumentsUrl,
        urlRiskReport: element.urlRiskReport,
        lastSignatureDate: element.lastSignatureDate,
        isActive: user.isActive,
        fileId: '',
      };
      filesList.forEach(file => {
        if (file.client.id == element.id) {
          user.options.fileId = file.id;
        }
      });
      return user;
    });
  }

  return (
    <>
      <MUIDataTable
        title={searchText.length > 0 ? 'Resultados: ' + searchText : 'Clientes'}
        data={clientList}
        columns={columns}
        options={options}
      />
      <div className={classes.buttonContainer}>
        {listLength < pageLength && pageLength > totalCount ? (
          <div className={classes.paginationText}>
            {totalCount} de {totalCount}
          </div>
        ) : (
          <div className={classes.paginationText}>
            {pageLength} de {totalCount}
          </div>
        )}
        {pageLength - listLength > 0 && listLength != totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              if (pageLength > totalCount) setPageLength(pageLength - 10);
              else setPageLength(pageLength - listLength);
              if (pageInfo?.startCursor) {
                fetchMore({
                  variables: {
                    beforeCursor: pageInfo.startCursor,
                    last: 10,
                    afterCursor: null,
                    first: null,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              }
            }}
          >
            {' '}
            Anterior{' '}
          </div>
        ) : (
          <span></span>
        )}
        {pageLength <= totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              setPageLength(pageLength + listLength);
              fetchMore({
                variables: {
                  afterCursor: pageInfo?.endCursor,
                  first: 10,
                  last: null,
                  beforeCursor: null,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return fetchMoreResult;
                },
              });
            }}
          >
            {' '}
            Siguiente{' '}
          </div>
        ) : (
          <span></span>
        )}
      </div>
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={loadingFile}
      />
      {photoDocument.front && (
        <CustomModal
          maxWidth="lg"
          showModal={showModal}
          title="Documento"
          onClosePress={() => {
            setShowModal(false);
          }}
        >
          <div className={classes.photoDocuments}>
            <img
              alt="front"
              src={`${REACT_APP_SERVER_LINK}/media/${photoDocument.front}`}
            />

            <img
              alt="back"
              src={`${REACT_APP_SERVER_LINK}/media/${photoDocument.back}`}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
}

export default ClientList;
