import React, { useCallback } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { useFieldToTextField } from 'formik-material-ui';

// Components & Others
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import useStyles from './styles';

function CustomNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}
CustomNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function InputBox(props) {
  const { text, format } = props;
  const classes = useStyles();
  const customize = useCallback(([, , helpers]) => {
    return {
      onChange: event => {
        const { value } = event.target;
        const reg = /^-?\d+(\.\d+)?$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
          helpers.setValue(value);
        }
      },
    };
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={1}>
        <MuiTextField
          {...useFieldToTextField(props, customize)}
          InputProps={{
            inputComponent: CustomNumberFormat,
            inputProps: format,
          }}
        />
      </Grid>
      <Grid item xs={8} className={classes.alternativeText}>
        {text}
      </Grid>
    </Grid>
  );
}
InputBox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  format: PropTypes.shape().isRequired,
};
InputBox.defaultProps = {
  text: '',
};

export default InputBox;
