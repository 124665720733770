import React from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import LoadingIndicator from '../../../LoadingIndicator';
import { getOptions } from '../../../../utils/commonFunctions';
import SimpleForm from '../../../Forms/SimpleForm';
import { CREATE_SCORES, GET_SCORES, UPDATES_SCORES } from '../queries';
import { formStructure, validationSchema } from './formInfo';

function ScoreForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error: scoreError, data: scoreData } = useQuery(GET_SCORES);
  const createScores = useMutation(
    CREATE_SCORES,
    getOptions({
      mutationName: 'createScoreAnnexA',
      modelName: 'scoresAnnexA',
      message: 'Se creó correctamente',
      enqueueSnackbar,
    })
  );
  const updateScores = useMutation(
    UPDATES_SCORES,
    getOptions({
      mutationName: 'updateScoreAnnexA',
      modelName: 'scoresAnnexA',
      message: 'Se actualizó correctamente',
      enqueueSnackbar,
    })
  );

  if (loading) return <LoadingIndicator />;
  if (scoreError) {
    console.error(scoreError);
  }

  let initialValue = {};
  let isEdit = false;

  if (scoreData) {
    initialValue = { ...scoreData.scoresAnnexA };
    isEdit = true;
  }

  return (
    <SimpleForm
      initialValues={initialValue}
      validateFunction={() => {}}
      validateSchema={validationSchema}
      data={formStructure}
      id={initialValue.id}
      isEdit={isEdit}
      modelName="Puntaje ficha Cliente"
      updateMutation={updateScores}
      createMutation={createScores}
      canBeDeleted={false}
      routeName=""
    />
  );
}

export default ScoreForm;
