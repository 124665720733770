import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_NEWS, DELETE_NEWS, UPDATE_NEWS } from '../queries';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { TYPES } from '../NewsForm';
// Styles & Images
import useStyles from './styles';

function CustomToolbar() {
  const history = useHistory();

  const handleClick = () => {
    history.push('/administrador/news/crear');
  };

  return (
    <Tooltip title="Nueva novedad">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function NewsList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [newsList, setNewsList] = useState([]);

  const [deleteIntrument] = useMutation(
    DELETE_NEWS,
    getOptions({
      mutationName: 'deleteNews',
      message: 'Novedad eliminada con éxito.',
      enqueueSnackbar,
    }),
  );
  const { loading, error, data } = useQuery(LIST_NEWS);

  if (error) {
    console.error('list news', error);
  }

  useEffect(() => {
    if (data) {
      const parserNews = NewsEdgeToList(data, 'listNews');
      setNewsList(parserNews);
    }
  }, [data]);

  function NewsEdgeToList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.options = {
          id: formatElement.id,
          state: formatElement.state,
        };
        return formatElement;
      });
    }
    return [];
  }

  const [updateNews] = useMutation(
    UPDATE_NEWS,
    getOptions({
      mutationName: 'updateNews',
      modelName: 'news',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
    }),
  );

  function handleState(e, id, state) {
    updateNews({
      variables: {
        id,
        input: {
          state: !state,
        },
      },
    });
  }

  const columns = [
    {
      name: 'title',
      label: 'Título',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'subtitle',
      label: 'Subítulo',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'newsType',
      label: 'Tipo',
      options: {
        sort: true,
        customBodyRender: value => {
          return TYPES?.find(el => el?.value === value)?.label;
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const { id, state } = value;
        return (
          <CustomOptions
            id={id}
            model="Novedad"
            options={['oedit', 'odelete', 'oenable']}
            extraData={{ oenable: state }}
            extraFunctions={{ oenable: e => handleState(e, id, state) }}
            handleDelete={() => {
              deleteIntrument({ variables: { id } });
              data.listNews.edges = data.listNews.edges.filter(
                e => e.node.id !== id,
              );
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={1} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `/administrador/news/${rowData[rowData.length - 1].props.id}`,
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <MUIDataTable
          title="Novedades"
          data={newsList}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}

export default NewsList;
