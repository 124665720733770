import Yup from '../../../../utils/yup';
import { rucValidator } from '../../../Validations';

export function validationFunctionBank(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaBank = Yup.object().shape({
  name: Yup.string().required(),
});
