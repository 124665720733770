import React from 'react';
import { generateColumn } from '../../../../utils/commonFunctions';
import Yup from '../../../../utils/yup';
import { INPUT_VARIANT_SIMPLE_FORM } from '../../../Forms/SimpleForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import {
  DELETE_INSTRUMENT_FUND,
  UPDATE_INSTRUMENT_FUND,
  CREATE_INSTRUMENT_FUND,
  GET_FUND,
} from '../queries';
import CustomOptions from '../../../CustomOptions';
import { TableCell, Typography } from '@material-ui/core';

export function instrumentColumns(deleteMutation) {
  return [
    generateColumn('Nombre', 'instrument'),
    generateColumn('Mínimo', 'minimum'),
    generateColumn('Máximo', 'maximum'),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Instrumento"
              options={['odelete']}
              handleDelete={() => {
                const [deleteInstrument] = deleteMutation;
                deleteInstrument({ variables: { id } });
              }}
              handleEdit={() => {}}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={{ fontWeight: 500 }}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];
}

export function instrumentValidationFunction(values) {
  const errors = {};
  // Custom Validations
  return errors;
}
export const instrumentValidationSchema = Yup.object().shape({
  instrument: Yup.string().required(),
  minimum: Yup.string().required(),
  maximum: Yup.string().required(),
});

export const instrumentStartValues = {
  instrument: '',
  minimum: '',
  maximum: '',
};
export function instrumentFormStructure(instruments) {
  return [
    {
      label: 'Instrumento',
      name: 'instrument',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: instruments,
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Mínimo',
      name: 'minimum',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      label: 'Máximo',
      name: 'maximum',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
  ];
}

export function instrumentMutationsData(fundID) {
  return {
    update: {
      query: UPDATE_INSTRUMENT_FUND,
      mutationName: 'updateInstrumentFund',
      modelName: 'instrumentFund',
      message: 'Instrumento actualizado con éxito.',
    },
    create: {
      query: CREATE_INSTRUMENT_FUND,
      mutationName: 'createInstrumentFund',
      modelName: 'instrumentFund',
      message: 'Instrumento creado con éxito.',
      refetchQueries: [{ query: GET_FUND, variables: { id: fundID } }],
    },
    delete: {
      query: DELETE_INSTRUMENT_FUND,
      mutationName: 'deleteInstrumentFund',
      modelName: 'instrumentFund',
      message: 'Instrumento eliminado con éxito.',
      refetchQueries: [{ query: GET_FUND, variables: { id: fundID } }],
    },
  };
}
