import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

import BankAccountList from '../../BankAccount/BankAccountList';
import SimpleForm from '../../../Forms/SimpleForm';
import {
  GET_PROMOTER_COMPANY_INFO,
  CREATE_PROMOTER_COMPANY,
  LIST_PROMOTER_COMPANIES,
  DELETE_PROMOTER_COMPANY,
  UPDATE_PROMOTER_COMPANY,
} from '../queries';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_DOCUMENT_TYPES } from '../../DocumentType/queries';
import {
  formStructure,
  validationSchema,
  startData,
  ORIGIN,
  QUOTA_PROMOTION_CATEGORY,
  PERSON_TYPE,
  SAFI_TYPE,
} from './formInfo';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import InnerBankDataBankAccountList from '../../../InnerModels/BankAccount/BankAccountList';

export const BUSINESS_PROMOTER = 'businessPromoter';

function PromoterCompanyForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [promoterData, setPromoterData] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);

  const [loadPromoter, { loading, data }] = useLazyQuery(
    GET_PROMOTER_COMPANY_INFO,
    {
      variables: {
        id,
      },
    },
  );
  const {
    loading: documentTypesLoading,
    error: documentTypesError,
    data: documentTypesData,
  } = useQuery(LIST_DOCUMENT_TYPES);

  const updateMutation = useMutation(
    UPDATE_PROMOTER_COMPANY,
    getOptions({
      mutationName: 'updatePromoterCompany',
      modelName: 'promoterCompany',
      message: 'Empresa promotora actualizada.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_PROMOTER_COMPANIES },
        { query: GET_PROMOTER_COMPANY_INFO, variables: { id } },
      ],
      completeCallback: () => history.goBack(),
    }),
  );
  const createMutation = useMutation(
    CREATE_PROMOTER_COMPANY,
    getOptions({
      mutationName: 'createPromoterCompany',
      modelName: 'promoterCompany',
      message: 'Empresa promotora creada con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTER_COMPANIES }],
      completeCallback: () => history.goBack(),
    }),
  );
  const deleteMutation = useMutation(
    DELETE_PROMOTER_COMPANY,
    getOptions({
      mutationName: 'deletePromoterCompany',
      modelName: 'promoterCompany',
      message: 'Empresa promotora eliminada.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTER_COMPANIES }],
      completeCallback: () => history.goBack(),
    }),
  );

  const onBankAccountCreated = accountId => {
    if (!accountId) {
      return;
    }
    const [updateModel] = updateMutation;
    const tmpBankAccounts = [...bankAccounts, accountId];
    setBankAccounts(tmpBankAccounts);
    updateModel({
      variables: {
        id,
        input: {
          bankAccounts: tmpBankAccounts,
        },
      },
    });
  };

  useEffect(() => loadPromoter(), [id]);
  useEffect(() => {
    if (data && data.promoterCompany) {
      const { promoterCompany } = data;
      const { address } = promoterCompany;
      const tmpBankAccounts = edgeToList(promoterCompany, 'bankAccounts').map(
        v => v.id,
      );
      const tmpData = {
        ...promoterCompany,
        country: address?.country?.id,
        county: { id: address?.county?.id, name: address?.county?.name },
        city: address?.city?.id,
        district: address?.district?.id,
        detail: address?.detail,
        ubigeo: address?.district?.ubigeo,
        representativeDocumentType:
          promoterCompany?.representativeDocumentType?.id,
        contactDocumentType: promoterCompany?.contactDocumentType?.id,
      };
      setBankAccounts(tmpBankAccounts);
      setPromoterData(tmpData);
    }
  }, [data]);

  const formatBeforeSubmit = values => {
    const {
      country,
      county,
      city,
      district,
      detail,
      ubigeo,
      ...otherValues
    } = values;
    const address = {
      country,
      county,
      city,
      district,
      detail,
    };

    otherValues.origin = Object.values(ORIGIN).find(
      item => item.id === otherValues.origin,
    ).form;
    otherValues.quotaPromotionCategory = Object.values(
      QUOTA_PROMOTION_CATEGORY,
    ).find(item => item.id === otherValues.quotaPromotionCategory).form;
    if (
      otherValues.quotaPromotionCategory ===
      QUOTA_PROMOTION_CATEGORY.EXTERN.form
    ) {
      otherValues.personType = Object.values(PERSON_TYPE).find(
        item => item.id === otherValues.personType,
      ).form;
      otherValues.safiType = null;
    } else {
      otherValues.safiType = Object.values(SAFI_TYPE).find(
        item => item.id === otherValues.safiType,
      ).form;
      otherValues.personType = null;
    }
    const input = {
      ...otherValues,
      bankAccounts: [],
      address,
      enabled: true,
    };
    return input;
  };
  const validate = () => ({});

  return loading && documentTypesLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container>
        <SimpleForm
          model={promoterData || startData}
          initialValues={startData}
          validateFunction={validate}
          validateSchema={validationSchema}
          formatBeforeSubmit={formatBeforeSubmit}
          data={formStructure(
            edgeToList(documentTypesData, 'listDocumentTypes'),
            data &&
              data.promoterCompany &&
              data.promoterCompany.quotaPromotionCategory,
          )}
          modelName="Empresa Promotora"
          hasCancel
          isEdit={!!promoterData}
          id={id}
          onCancel={() => history.goBack()}
          canBeDeleted
          updateMutation={updateMutation}
          createMutation={createMutation}
          deleteMutation={deleteMutation}
        />
      </Grid>
      <br />
      <br />
      <Grid container>
        {promoterData && (
          <InnerBankDataBankAccountList
            accountIdList={bankAccounts}
            onBankAccountCreated={onBankAccountCreated}
          />
        )}
      </Grid>
    </>
  );
}

export default PromoterCompanyForm;
