import React, { useEffect, useState } from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import FundForm from '../FundForm';
import { GET_FUND } from '../queries';
import { parseBool, parseEquivalent } from '../../../../utils/commonFunctions';

function FundEdit() {
  const { id } = useParams();
  const history = useHistory();

  const { loading, error, data } = useQuery(GET_FUND, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get fund', error);
    history.goBack();
  }

  const myVal = { ...data.fund };
  if (!data.fund) {
    history.goBack();
  }

  myVal.fundType = parseEquivalent(myVal.fundType);
  myVal.incomeType = parseEquivalent(myVal.incomeType);
  myVal.tirCalculus = parseEquivalent(myVal.tirCalculus);
  myVal.currency = myVal.currency.id;
  myVal.category = myVal.category.id;
  myVal.hasClasses = parseBool(myVal.hasClasses);
  myVal.subscriptionRight = parseBool(myVal.subscriptionRight);
  myVal.coParticipation = parseBool(myVal.coParticipation);
  myVal.exchangeRateRisk = parseBool(myVal.exchangeRateRisk);

  return <FundForm fund={myVal} id={id} isEdit />;
}

FundEdit.propTypes = {};

export default FundEdit;
