/*
 * HomePage
 */

import React, { useEffect } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// Components & Others
import ClientDashboard from '../../../components/ClientDashboard';

// Styles & Images
import useStyles from './styles';
import EmployeeDashboardPage from './employeeDashboard';

function ClientDashboardPage(props) {
  const { setMenu, setSecondLogo, newClient, isEmployee } = props;
  const classes = useStyles();

  return (
    <>
      {isEmployee ? (
        <EmployeeDashboardPage />
      ) : (
        <ClientDashboard
          setMenu={setMenu}
          setSecondLogo={setSecondLogo}
          newClient={newClient}
        />
      )}
    </>
  );
}

export default ClientDashboardPage;
