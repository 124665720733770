import { makeStyles } from '@material-ui/core/styles';
import { LineStyle } from '@material-ui/icons';

const drawerWidth = 260;
export default makeStyles(theme => ({
  //   clientPage: {
  //     display: 'flex',
  //     background: '#fff',

  //     [theme.breakpoints.up('lg')]: {
  //       paddingLeft: '8%',
  //       paddingRight: '8%',
  //     },
  //   },
  main: {
    position: ' absolute',
    bottom: 0,
    display: 'flex',
    background: '#f0f3f5',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainDark: {
    position: ' absolute',
    bottom: 0,
    display: 'flex',
    background:
      ' linear-gradient(180deg, rgba(47, 47, 47, 0.20) 0%, #2F2F2F 100%)',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  legalContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: 'transparent',
  },
  legalPdfContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
  },
  legalcontent: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    margin: 0,
    borderBottom: 'none',
    textDecoration: 'none',
  },
  legalcontentDark: {
    color: '#D9D9D9',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    margin: 0,
    borderBottom: 'none',
    textDecoration: 'none',
  },
  copyright: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    margin: 0,
  },
  copyrightDark: {
    color: '#D9D9D9',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    margin: 0,
  },
  adviceText: {
    color: '#434040',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    margin: 0,
  },
  adviceTextDark: {
    color: '#B6B6B6',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '15px',
    margin: 0,
  },
  legalContainer1: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
  },
  legalAdviceContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
  },
  containerLogos: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '20px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: 'transparent',
  },
}));
