import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_CLIENT_RELATIVE,
  CREATE_CLIENT_RELATIVE,
  UPDATE_CLIENT_RELATIVE,
  DELETE_CLIENT_RELATIVE,
} from '@queries/Client';
import Yup from '../../../../../utils/yup';
import { edgeToList, getOptions } from '../../../../../utils/commonFunctions';
import useStyles from './styles';
import SimpleForm from '../../../../Forms/SimpleForm';
import LoadingIndicator from '../../../../LoadingIndicator';
import { INPUT_VARIANT } from '../../../../Forms/StepsForm/fieldsSelector';
import { LIST_DOCUMENT_TYPES } from '../../../../Models/DocumentType/queries';
import PublicFunctionsTable from './publicFunctions';
import ParticipationTable from './participation';
import { RELATION_TYPE } from 'src/components/StepsComponents/PersonalData/RelativeForm';

const relativeValidationSchema = Yup.object().shape({
  typeRelation: Yup.string().required(),
  fullName: Yup.string().required(),
  documentType: Yup.string().required(),
  // alternativeDocumentType: Yup.string().when('documentType', {
  //   is: 'other',
  //   then: Yup.string().required(),
  // }),
  documentNumber: Yup.string().required(),
});

function RelativeForm(props) {
  const { relativeData, clientId, closeForm, setCurrentRelative } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [relativeId, setRelativeId] = useState(null);
  const [publicFunctions, setPublicFunctions] = useState([]);
  const [participations, setParticipations] = useState([]);
  const startRelativeData = {
    typeRelation: '',
    fullName: '',
    documentType: '',
    documentNumber: '',
    alternativeDocumentType: '',
  };

  const { loading: documentTypeLoading, data: documentTypeData } = useQuery(
    LIST_DOCUMENT_TYPES,
  );

  const createRelativeMutation = useMutation(
    CREATE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'createRelative',
      modelName: 'relative',
      message: 'Se guardó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: data => {
        setRelativeId(data.id);
        setCurrentRelative(data.id);
      },
    }),
  );

  const updateRelativeMutation = useMutation(
    UPDATE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'updateRelative',
      modelName: 'relative',
      message: 'Se actualizó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const deleteRelativeMutation = useMutation(
    DELETE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'deleteRelative',
      modelName: 'relative',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const data = [
    {
      isField: true,
      label: 'Parentesco',
      name: 'typeRelation',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: RELATION_TYPE,
      mapData: { value: 'value', label: 'label' },
    },
    {
      isField: true,
      label: 'Nombres Completos',
      name: 'fullName',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: false,
      section: 'Documento de identidad',
      fields: [
        {
          label: 'Tipo de documento',
          name: 'documentType',
          inputVariant: INPUT_VARIANT.select,
          gridMD: 4,
          data: edgeToList(documentTypeData, 'listDocumentTypes'),
          mapData: { value: 'id', label: 'name' },
        },
        {
          label: 'Número de documento',
          name: 'documentNumber',
          gridMD: 8,
          inputVariant: INPUT_VARIANT.number,
        },
      ],
    },
  ];

  const validateRelativeData = () => {};
  const formatBeforeSubmit = v => {
    const newValues = { ...v };
    delete newValues.participations;
    delete newValues.publicFunctions;
    return { ...newValues, client: clientId };
  };

  useEffect(() => {
    if (relativeData) {
      setRelativeId(relativeData.id);
      if (relativeData.documentType.hasOwnProperty('id')) {
        relativeData.documentType = relativeData.documentType.id;
      }
      if (relativeData.maritalStatus.hasOwnProperty('id')) {
        relativeData.maritalStatus = relativeData.maritalStatus.id;
      }
      setPublicFunctions(edgeToList(relativeData, 'publicFunctions'));
      setParticipations(edgeToList(relativeData, 'participations'));
    }
  }, [relativeData]);

  if (documentTypeLoading) {
    return <LoadingIndicator />;
  }
  return (
    <Container>
      <div className={classes.formContainer}>
        <SimpleForm
          model={relativeData}
          initialValues={startRelativeData}
          validateFunction={validateRelativeData}
          validateSchema={relativeValidationSchema}
          data={data}
          modelName="Pariente"
          hasCancel
          isEdit={!!relativeId}
          id={relativeId}
          routeName=""
          onCancel={closeForm}
          updateMutation={updateRelativeMutation}
          createMutation={createRelativeMutation}
          deleteMutation={deleteRelativeMutation}
          formatBeforeSubmit={formatBeforeSubmit}
          extaNode={
            relativeId ? (
              <>
                <PublicFunctionsTable
                  clientId={clientId}
                  relativeId={relativeId}
                  data={publicFunctions}
                />
                <ParticipationTable
                  clientId={clientId}
                  relativeId={relativeId}
                  data={participations}
                />
              </>
            ) : (
              <></>
            )
          }
        />
      </div>
    </Container>
  );
}

RelativeForm.propTypes = {
  relativeData: PropTypes.shape().isRequired,
  clientId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setCurrentRelative: PropTypes.func.isRequired,
};

export default RelativeForm;
