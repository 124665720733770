import React, { useState, useEffect, useRef } from 'react';
import PasswordKeyboard from './numericKeyboard';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { useSession } from 'src/providers/SessionProvider';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { gql } from 'apollo-boost';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { successMessage, errorMessage } from 'src/utils/commonFunctions';

// import useStyles from './styles';
function NewPasswordFormLogin(props) {
  const { documentNumbers, handlerChangePassword, handlerRecoverPass } = props;
  const inputRef = useRef(null);
  const keyboardRef = useRef(null);
  const [focusInput, setFocusInput] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { setRefreshToken } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState({
    oldpassword: false,
    digits: false,
  });
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    oldpassword: '',
  });
  const [loading, setLoading] = useState(false);

  const { userLoading, data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        payload
        refreshExpiresIn
      }
    }
  `;

  const [
    loginMutation,
    { data: loginData, error: loginError, client },
  ] = useMutation(LOGIN, {
    onCompleted(data) {
      const {
        tokenAuth: { payload, refreshExpiresIn },
      } = data;
      setRefreshToken({ ...payload, refreshExpiresIn });
      client.resetStore();
      setLoading(false);
      // handlerChangePassword();
    },
    onError(error) {
      console.error(error);
      setLoading(false);
    },
  });

  const CHANGE_PASSWORD_DIGITS = gql`
    mutation changeUserPasswordToDigits(
      $documentNumber: String!
      $currentPassword: String!
      $newPassword: String!
    ) {
      changeUserPasswordToDigits(
        documentNumber: $documentNumber
        currentPassword: $currentPassword
        newPassword: $newPassword
      ) {
        ok
        message
      }
    }
  `;

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD_DIGITS, {
    onError(error) {
      const { graphQLErrors } = error;
      if (graphQLErrors[0]?.message?.includes('común')) {
        errorMessage(
          'Contraseña no válida. Evita usar números consecutivos o repetidos.',
          enqueueSnackbar,
        );
      } else errorMessage(graphQLErrors, enqueueSnackbar);
      setLoading(false);
    },
    onCompleted(data) {
      const response = data.changeUserPasswordToDigits;
      if (response.ok) {
        successMessage('Contraseña cambiada con éxito', enqueueSnackbar);
        history.push('/');
        handlerChangePassword();
      } else {
        errorMessage(response.message, enqueueSnackbar);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (loginData) {
      const {
        tokenAuth: { payload },
      } = loginData;
      if (payload.usergroup === 'admin_group') {
        history.push('/administrador');
      } else if (payload.usergroup === 'client_group') {
        if (user && user.currentUser)
          payload.userFullName = `${user?.currentUser?.firstName} ${user?.currentUser?.lastName}`;
        window.dataLayer.push({ event: 'Login Intranet', info: payload });
        history.push('/cliente');
      }
    }
  }, [loginData, user]);

  const onSubmit = () => {
    setLoading(true);
    changePasswordMutation({
      variables: {
        documentNumber: documentNumbers,
        currentPassword: formData.oldpassword,
        newPassword: formData.password,
      },
    });
  };

  const handlerPassword = value => {
    setFormData({
      ...formData,
      password: value,
    });
  };
  useEffect(() => {
    if (formData.password.length === 6) {
      setShowPassword({
        ...showPassword,
        digits: false,
      });
      setFocusInput(false);
    }
  }, [formData.password]);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = field => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const handleClickOutside = event => {
    if (
      keyboardRef.current &&
      !keyboardRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setFocusInput(false);
      setShowPassword(false);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlerOpenKeyboard = () => {
    setFocusInput(true);
    setFormData({
      ...formData,
      password: '',
    });
  };
  const linkPassword = () => {
    handlerRecoverPass();
  };
  return (
    <>
      <>
        <TextField
          type={showPassword.oldpassword ? 'text' : 'password'}
          label="Ingrese su antigua contraseña"
          name="oldpassword"
          variant="outlined"
          value={formData.oldpassword}
          onChange={handleChange}
          autoComplete="off"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility('oldpassword')}
                >
                  {showPassword.oldpassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          ref={inputRef}
          type={showPassword.digits ? 'text' : 'password'}
          label="Clave de 6 dígitos"
          sx={{ pointerEvents: 'none' }}
          name="digits"
          variant="outlined"
          value={formData.password}
          onFocus={handlerOpenKeyboard}
          autoComplete="off"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => togglePasswordVisibility('digits')}>
                  {showPassword.digits ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {focusInput && (
          <div ref={keyboardRef} className={classes.main}>
            <PasswordKeyboard
              handlerPassword={handlerPassword}
              focusInput={focusInput}
            />
          </div>
        )}
        <div className={classes.mssgDiv}>
          <p className={classes.mssgText}>
            <strong>Recomendación</strong>
            <br />
            Tu contraseña debe tener 6 dígitos, no consecutivos ni repetidos.
            Asegúrate de que sea una combinación única.
          </p>
        </div>
        <Button
          className={classes.btn}
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={onSubmit}
          type="submit"
        >
          Actualizar Contraseña a Dígitos
        </Button>
      </>
      <div className={classes.divResetPass}>
        <p
          id="forgot_password_link"
          onClick={linkPassword}
          className={classes.forgetPass}
        >
          {' '}
          ¿Olvidaste tu contraseña?
        </p>
      </div>
      {loginError && (
        <p
          style={{
            fontSize: '1.6vh',
            color: '#FD7272',
            textAlign: 'center',
            fontWeight: '400',
          }}
        >
          Credenciales incorrectas
        </p>
      )}
    </>
  );
}

export default NewPasswordFormLogin;
