/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

// Material Resources
import { Grid, Link } from '@material-ui/core';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  stringToDate,
  getOptions,
  edgeToList,
} from '../../../../../../utils/commonFunctions';
import { dateRangeValidator } from '../../../../../Validations';
import SimpleForm from '../../../../../Forms/SimpleForm';
import ShareLink from '../../../../../ShareLink';
import { INPUT_VARIANT } from '../../../../../Forms/StepsForm/fieldsSelector';

import { formStructure, validationSchema, startValues } from './formStructure';
import {
  DELETE_RAISING_FUND,
  LIST_RAISING_FUND,
  UPDATE_RAISING_FUND,
  CREATE_RAISING_FUND,
  LIST_CLASS_FUND,
  GET_FUND,
} from '../queries';

function RaisingForm(props) {
  const { raising, isEdit } = props;
  const { fundId, id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, error, data: fundClass } = useQuery(LIST_CLASS_FUND);
  const { data: infoFund } = useQuery(GET_FUND, {
    variables: { id: fundId },
  });

  const deleteRaising = useMutation(
    DELETE_RAISING_FUND,
    getOptions({
      mutationName: 'deleteRaisingCompany',
      modelName: 'raising',
      message: 'Levantamiento eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_RAISING_FUND, variables: { id: fundId } }],
      completeCallback: () => history.goBack(),
    }),
  );

  const updateRaising = useMutation(
    UPDATE_RAISING_FUND,
    getOptions({
      mutationName: 'updateRaising',
      modelName: 'raising',
      message: 'Levantamiento actualizado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_RAISING_FUND, variables: { id: fundId } }],
      completeCallback: () => history.goBack(),
    }),
  );

  const createRaising = useMutation(
    CREATE_RAISING_FUND,
    getOptions({
      mutationName: 'createRaising',
      modelName: 'raising',
      message: 'Levantamiento creado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_RAISING_FUND, variables: { id: fundId } }],
      completeCallback: () => history.goBack(),
    }),
  );

  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    dateRangeValidator(
      'dateInitialCertificateSent',
      'dateEndCertificateSent',
      values,
      errors,
    );
    dateRangeValidator(
      'dateInitialContract',
      'dateEndContract',
      values,
      errors,
    );
    dateRangeValidator(
      'initialFeePaymentDate',
      'lastFeePaymentDate',
      values,
      errors,
    );
    dateRangeValidator(
      'initialParticipationOrderReceptionDate',
      'lastParticipationOrderReceptionDate',
      values,
      errors,
    );
    dateRangeValidator(
      'initialFeeAdjudicationDate',
      'finalFeeAdjudicationDate',
      values,
      errors,
    );
    return errors;
  }

  function formatQuery() {
    if (!raising) {
      return null;
    }
    const formatValue = raising;

    Object.keys(formatValue).forEach(k => {
      if (k.match(/date|Date/)) {
        formatValue[k] = stringToDate(formatValue[k]);
      }
    });

    formatValue.targetCapitalIncrease = formatValue.targetCapitalIncrease.toString();
    formatValue.valueFee = formatValue.valueFee.toString();
    formatValue.valueFeeNominal = formatValue.valueFeeNominal.toString();

    return formatValue;
  }

  function formatBeforeSubmit(values) {
    const { number, missingFees, fees, ...newValues } = values;
    Object.keys(newValues).forEach(k => {
      if (k.match(/date|Date/)) {
        newValues[k] = stringToDate(newValues[k]).toISOString();
      }
    });

    newValues.fund = fundId;
    return newValues;
  }

  const handleFundChange = (setFieldValue, e) => {
    setFieldValue('classes', e.target.value);
  };

  function customOnChange(values) {
    const { targetCapitalIncrease, valueFee, fees, missingFees } = values;
    let newFees = fees;
    let newMissingFees = missingFees;

    if (targetCapitalIncrease && valueFee) {
      let feeNumber = Math.trunc(targetCapitalIncrease / valueFee);
      newFees = feeNumber;
      if (!missingFees) {
        newMissingFees = feeNumber;
      } else {
        newMissingFees = newFees - (fees - missingFees);
      }
    }

    return {
      fees: newFees,
      missingFees: newMissingFees,
    };
  }

  const parserClasses = raising?.classes?.edges?.map(item => item.node);

  let customMultiSelectSearch = {
    label: 'Clases de Fondo',
    id: 'classes',
    name: 'classes',
    inputVariant: INPUT_VARIANT.multiSelectSearch,
    gridMD: 12,
    defaultValues: parserClasses ? parserClasses : [],
    disabled: !infoFund?.fund?.hasClasses,
    data: edgeToList(fundClass, 'listClassFund'),
    onChange: handleFundChange,
  };

  const customFormStructure = [customMultiSelectSearch, ...formStructure];

  return (
    <>
      <Grid>
        {isEdit && (
          <ShareLink
            title="Link de cógnito"
            link={`${window.location.origin}/registro/${raising.id}`}
          />
        )}
        <SimpleForm
          initialValues={startValues}
          validateFunction={validationFunction}
          validateSchema={validationSchema}
          formatBeforeSubmit={formatBeforeSubmit}
          data={customFormStructure}
          model={formatQuery()}
          id={id}
          modelName={`Levantamiento - ${infoFund?.fund?.businessName}`}
          routeName=""
          isEdit={isEdit}
          updateMutation={updateRaising}
          createMutation={createRaising}
          deleteMutation={deleteRaising}
          hasCancel
          onCancel={() => history.goBack()}
          customOnChange={customOnChange}
          customOnChangeFields={['targetCapitalIncrease', 'valueFee']}
          customFieldSearchMulti={infoFund?.fund?.hasClasses}
        />
      </Grid>
    </>
  );
}

RaisingForm.propTypes = {
  raising: PropTypes.shape(),
  isEdit: PropTypes.bool,
};

RaisingForm.defaultProps = {
  raising: null,
  isEdit: false,
};

export default RaisingForm;
