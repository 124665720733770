import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  group: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  divInput: {
    paddingLeft: '10px',
  },
  inputLabel: {
    color: ' #7a7a7a',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    marginBottom: theme.spacing(1),
    margin: 0,
  },

  customSelect: {
    width: '100%',
    // position: 'relative',
    border: '1px solid #bebebe',
    padding: '14px 14px',
    color: ' #7a7a7a',
    fontWeight: 400,
    fontFamily: 'Montserrat',
    borderRadius: 10,
    backgroundColor: '#fff',
    fontSize: '1rem',
    transition: 'border-color 0.3s',
    appearance: 'none',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 10px center',
    backgroundSize: '16px',
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: 'none',
    },
  },
  open: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  optionsList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #bebebe',
    borderTop: 'none',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontFamily: 'Montserrat',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    listStyle: 'none',
    padding: 0,
    margin: 0,
    fontSize: '1rem',
  },
  option: {
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    padding: '10px 14px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: '#f97a00',
      color: '#fff',
    },
  },
  error: {
    borderColor: '#f44336',
  },
}));
