import React from 'react';

function ArrowDwnIcon(props) {
  const {  bg } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      fill="none"
      viewBox="0 0 11 7"
    >
      <g>
        <path
          stroke={bg}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1l4.167 4.167L9.333 1"
        />
      </g>
    </svg>
  );
}

export default ArrowDwnIcon;
