import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { TableCell, Typography, Button } from '@material-ui/core';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import CustomCard from '../../../CustomCard';
import {
  GET_CLIENT_LEGAL_DATA,
  UPDATE_CLIENT_LEGAL_DATA,
  GET_CLIENT_PROPERTY,
  DELETE_CLIENT_PROPERTY,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import {
  setSocietyAction,
  setMarriedAction,
  setStatus,
  setMissingSteps,
} from '../../../../containers/ModelsPages/ClientForm/actions';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import {
  edgeToList,
  parseEquivalent,
  parseBool,
  errorMessage,
  successMessage,
  getOptions,
} from '../../../../utils/commonFunctions';
import { LIST_MARITAL_STATUS } from '../../../Models/MaritalStatus/queries';
import { LIST_DOCUMENT_TYPES } from '../../../Models/DocumentType/queries';
import {
  MENU_ITEMS_ID,
  NODE_STATES,
} from '../../../../containers/ModelsPages/ClientForm/constants';
import { LIST_PROPERTY_REGIME } from '../../../Models/PropertyRegime/queries';
import LoadingIndicator from '../../../LoadingIndicator';
import { FORM_OPTIONS } from '../../constants';
import { defaultDate } from 'src/components/FormComponents/DatePicker';
import { getMissingStepsSelector } from 'src/containers/ModelsPages/ClientForm/selectors';
import CustomModal from '../../../CustomModal';
import PropertyForm from '../PropertyForm';
import useStyles from '../../PEP/Relative/styles';
import { TYPES } from '../PropertyForm';

function LegalDataClient(props) {
  const { isMarried, isSociety, clientID } = props;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [civilState, setCivilState] = useState(isMarried);
  const [savePropertyRegime, setSavePropertyRegime] = useState(null);
  const [isEditDocuments, setIsEditDocuments] = useState(false);
  const [propertyRegimeList, setPropertyRegimeList] = useState([]);
  const [startValues, setStartValues] = useState({
    documentType: '',
    alternativeDocumentType: '',
    documentNumber: '',
    photoDocumentFront: null,
    photoDocumentBack: null,
    domiciled: 'true',
    countryAddress: '',
    usPerson: 'true',
    nationality: [],
    maritalStatus: '',
    propertyRegime: '',
    startDatePropertyRegime: defaultDate,
    ficha: 'true',
  });

  const [defaultOther, setDefaultOther] = useState(true);
  const missingSteps = useSelector(getMissingStepsSelector());

  const [currentProperty, setCurrentProperty] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [properties, setProperties] = useState([]);
  const [client, setClient] = useState(null);
  const classes = useStyles();

  const {
    loading: loadingData,
    error: clientDataError,
    data: clientDataProperty,
  } = useQuery(GET_CLIENT_PROPERTY, {
    variables: { id: clientID },
  });

  const deletePropertyMutation = useMutation(
    DELETE_CLIENT_PROPERTY,
    getOptions({
      mutationName: 'createProperty',
      modelName: 'property',
      message: 'Se guardó datos del inmueble con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_PROPERTY, variables: { id: clientID } },
      ],
      onCompleted: data => {
        const { errors } = data.deleteProperty;
        if (errors) {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    }),
  );

  useEffect(() => {
    if (clientDataProperty) {
      setClient(clientDataProperty.client);
      const tmpProperties = edgeToList(clientDataProperty.client, 'properties');
      setProperties(tmpProperties);
      if (currentProperty) {
        let tmpProperty = tmpProperties.find(v => v.id === currentProperty.id);
        setCurrentProperty(tmpProperty);
      }
    }
  }, [clientDataProperty, currentProperty]);

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_LEGAL_DATA, {
    variables: { id: clientID },
  });

  const updateClient = useMutation(UPDATE_CLIENT_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;
        if (client) {
          successMessage('Se guardó datos legales.', enqueueSnackbar);
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;

        if (client) {
          successMessage(
            'Se guardó datos legales parcialmente.',
            enqueueSnackbar,
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  // SELECT QUERIES
  const {
    error: DocumentTypesError,
    data: DocumentTypesData,
    loading: DocumentTypesLoading,
  } = useQuery(LIST_DOCUMENT_TYPES);
  const {
    error: MaritalStatusError,
    data: MaritalStatusData,
    loading: MaritalStatusLoading,
  } = useQuery(LIST_MARITAL_STATUS);
  const {
    error: PropertyRegimeError,
    data: PropertyRegimeData,
    loading: PropertyRegimeLoading,
  } = useQuery(LIST_PROPERTY_REGIME);

  if (DocumentTypesError) {
    console.error('Legal data - list document types', DocumentTypesError);
  }
  if (MaritalStatusError) {
    console.error('Legal data  - list marital status', MaritalStatusError);
  }
  if (PropertyRegimeError) {
    console.error('Legal data  - list property regimes', PropertyRegimeError);
  }

  function onCivilStateChange(event) {
    event.preventDefault();
    const steps = [...missingSteps];
    if (MaritalStatusData) {
      const findSpouse = edgeToList(
        MaritalStatusData,
        'listMaritalStatus',
      ).find(ms => ms.id === event.target.value);

      // if (findSpouse.commonLaw) {
      //   const propertyRegime = edgeToList(
      //     PropertyRegimeData,
      //     'listPropertyRegimes',
      //   ).filter(item => item.commonLaw);
      //   setPropertyRegimeList(propertyRegime);
      // } else {
      //   const propertyRegime = edgeToList(
      //     PropertyRegimeData,
      //     'listPropertyRegimes',
      //   ).filter(item => !item.commonLaw);
      //   setPropertyRegimeList(propertyRegime);
      // }

      // if (findSpouse) {
      //   if (!isMarried && findSpouse.spouse) {
      //     steps.push(MENU_ITEMS_ID.SpousePartnerPersonalData);
      //     steps.push(MENU_ITEMS_ID.SpousePartnerLegalData);
      //   } else {
      //     let index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerPersonalData);
      //     if (index > -1) {
      //       steps.splice(index, 1);
      //     }
      //     index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerLegalData);
      //     if (index > -1) {
      //       steps.splice(index, 1);
      //     }
      //     index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerLaborData);
      //     if (index > -1) {
      //       steps.splice(index, 1);
      //     }
      //     // index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerSafiRelation);
      //     // if (index > -1) {
      //     //   steps.splice(index, 1);
      //     // }
      dispatch(setSocietyAction(false));
      //   }
      //   if (PropertyRegimeData && savePropertyRegime) {
      //     const findRegime = edgeToList(
      //       PropertyRegimeData,
      //       'listPropertyRegimes',
      //     ).find(pr => pr.id === savePropertyRegime.id);
      //     if (!isSociety && findRegime && findRegime.society) {
      //       steps.push(MENU_ITEMS_ID.SpousePartnerLaborData);
      //       // steps.push(MENU_ITEMS_ID.SpousePartnerSafiRelation);
      //     }
      //   }
      //   dispatch(setMarriedAction(findSpouse.spouse));
      //   setCivilState(findSpouse.spouse);
      // } else {
      //   let index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerPersonalData);
      //   if (index > -1) {
      //     steps.splice(index, 1);
      //   }
      //   index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerLegalData);
      //   if (index > -1) {
      //     steps.splice(index, 1);
      //   }
      //   index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerLaborData);
      //   if (index > -1) {
      //     steps.splice(index, 1);
      //   }
      //   // index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerSafiRelation);
      //   // if (index > -1) {
      //   //   steps.splice(index, 1);
      //   // }
      dispatch(setSocietyAction(false));
      dispatch(setMarriedAction(false));
      setCivilState(false);
      // }
    }
    dispatch(setMissingSteps(steps));
  }

  function onPropertyRegimeChange(event) {
    event.preventDefault();
    const steps = [...missingSteps];
    // if (PropertyRegimeData) {
    //   const findRegime = edgeToList(
    //     PropertyRegimeData,
    //     'listPropertyRegimes',
    //   ).find(pr => pr.id === event.target.value);
    //   if (!isSociety && findRegime.society) {
    //     steps.push(MENU_ITEMS_ID.SpousePartnerLaborData);
    //     // steps.push(MENU_ITEMS_ID.SpousePartnerSafiRelation);
    //   }

    //   if (!findRegime.society) {
    //     let index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerLaborData);
    //     if (index > -1) {
    //       steps.splice(index, 1);
    //     }
    //     // index = steps.indexOf(MENU_ITEMS_ID.SpousePartnerSafiRelation);
    //     // if (index > -1) {
    //     //   steps.splice(index, 1);
    //     // }
    //   }
    //   dispatch(setSocietyAction(findRegime.society));
    // } else {
    dispatch(setSocietyAction(false));
    // }
    dispatch(setMissingSteps(steps));
  }

  let data = [
    {
      isField: false,
      section: 'Documento de identidad',
      fields: [
        {
          label: '* Tipo de documento',
          name: 'documentType',
          labelText: 'Otro tipo de documento',
          nameText: 'alternativeDocumentType',
          inputVariant: INPUT_VARIANT.otherSelect,
          gridMD: [12, 12],
          data: edgeToList(DocumentTypesData, 'listDocumentTypes').concat([
            { id: 'other', name: 'Otro  documento' },
          ]),
          mapData: { value: 'id', label: 'name' },
          disabled: true,
          defaultOther,
        },
        {
          label: '* Número de documento',
          name: 'documentNumber',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.text,
          disabled: true,
        },
        {
          label: '* Foto del anverso del documento',
          name: 'photoDocumentFront',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.image,
        },
        {
          label: '* Foto del reverso del documento',
          name: 'photoDocumentBack',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.image,
        },
      ],
    },
    // {
    //   isField: false,
    //   section: 'Calidad de domicilio (fines tributarios)',
    //   fields: [
    //     {
    //       name: 'domiciled',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.radio,
    //       data: [
    //         { value: 'true', label: 'Domiciliado' },
    //         { value: 'false', label: 'No domiciliado' },
    //       ],
    //     },
    //     {
    //       name: 'domiciledText',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'domiciled',
    //     },
    //   ],
    // },
    {
      isField: true,
      label: '* País de residencia',
      name: 'countryAddress',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.country,
    },
    // {
    //   isField: false,
    //   section: '¿Es usted una "US PERSON"?',
    //   fields: [
    //     {
    //       name: 'usPerson',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.radio,
    //       data: FORM_OPTIONS.yesNo,
    //     },
    //     {
    //       name: 'usPersonText',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'usPerson',
    //     },
    //   ],
    // },
    {
      isField: false,
      section: 'Nacionalidades',
      fields: [
        {
          label: '* Nacionalidad',
          name: 'nationality',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.nationality,
        },
      ],
    },
    {
      isField: true,
      label: '* Estado civil',
      name: 'maritalStatus',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.select,
      data: edgeToList(MaritalStatusData, 'listMaritalStatus'),
      mapData: { value: 'id', label: 'name' },
      onChange: onCivilStateChange,
    },
  ];

  useEffect(() => {
    setPropertyRegimeList(
      edgeToList(PropertyRegimeData, 'listPropertyRegimes'),
    );
    if (clientError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar,
      );
    }

    if (clientData) {
      const { client } = clientData;
      const { id, __typename, user, ...otherValues } = client;
      const { id: userId, __typename: userTypename, ...userData } = user;
      const currentData = Object.assign(otherValues, userData);

      if (currentData.documentType && currentData.documentType !== '') {
        currentData.documentType = currentData.documentType
          ? currentData.documentType.id
          : startValues.documentType;
      } else {
        currentData.documentType = 'other';
        setDefaultOther(false);
      }

      currentData.alternativeDocumentType =
        currentData.alternativeDocumentType ||
        startValues.alternativeDocumentType;
      currentData.documentNumber =
        currentData.documentNumber || startValues.documentNumber;
      currentData.domiciled =
        currentData.domiciled !== null
          ? parseBool(currentData.domiciled)
          : startValues.domiciled;
      currentData.countryAddress = currentData.countryAddress
        ? currentData.countryAddress.id
        : startValues.countryAddress;
      currentData.usPerson =
        currentData.usPerson !== null
          ? parseBool(currentData.usPerson)
          : startValues.usPerson;

      currentData.nationality = currentData.nationalitySet.edges.map(nat => {
        return nat.node.isActive ? nat.node.nationality : '';
      });
      delete currentData.nationalitySet;

      if (currentData.propertyRegime && currentData.maritalStatus.commonLaw) {
        const propertyRegime = edgeToList(
          PropertyRegimeData,
          'listPropertyRegimes',
        ).filter(item => item.commonLaw);
        setPropertyRegimeList(propertyRegime);
      }
      if (
        currentData.propertyRegime &&
        currentData.maritalStatus.commonLaw == false
      ) {
        const propertyRegime = edgeToList(
          PropertyRegimeData,
          'listPropertyRegimes',
        ).filter(item => !item.commonLaw);
        setPropertyRegimeList(propertyRegime);
      }

      currentData.maritalStatus = currentData.maritalStatus
        ? currentData.maritalStatus.id
        : startValues.maritalStatus;
      setSavePropertyRegime(currentData.propertyRegime);
      currentData.propertyRegime = currentData.propertyRegime
        ? currentData.propertyRegime.id
        : startValues.propertyRegime;
      currentData.startDatePropertyRegime =
        currentData.startDatePropertyRegime ||
        startValues.startDatePropertyRegime;

      if (!!currentData.photoDocumentFront && !!currentData.photoDocumentBack) {
        setIsEditDocuments(true);
      }

      setStartValues(currentData);
    }
  }, [clientData, clientLoading, PropertyRegimeData]);

  if (civilState) {
    data = data.concat([
      {
        isField: true,
        label: '* Régimen patrimonial',
        name: 'propertyRegime',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.select,
        data: propertyRegimeList,
        mapData: { value: 'id', label: 'name' },
        onChange: onPropertyRegimeChange,
      },
      {
        isField: true,
        label: '* Fecha de inicio del régimen patrimonial',
        name: 'startDatePropertyRegime',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.date,
      },
    ]);
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }
  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required(),
    alternativeDocumentType: Yup.string().when('documentType', {
      is: 'other',
      then: Yup.string().required(),
    }),
    documentNumber: Yup.string().required(),
    photoDocumentFront: isEditDocuments
      ? Yup.string().nullable()
      : Yup.string()
          .typeError('Este campo es requerido')
          .required(),
    photoDocumentBack: isEditDocuments
      ? Yup.string().nullable()
      : Yup.string()
          .typeError('Este campo es requerido')
          .required(),
    domiciled: Yup.string().required(),
    countryAddress: Yup.string().required(),
    usPerson: Yup.string().required(),
    maritalStatus: Yup.string().required(),
    propertyRegime: Yup.string().when({
      is: () => civilState,
      then: Yup.string().required(),
    }),
    nationality: Yup.array().required(),
    startDatePropertyRegime: Yup.string().when({
      is: () => civilState,
      then: Yup.string().required(),
    }),
  });

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.ficha = true;
    newValues.domiciled = parseEquivalent(newValues.domiciled);
    newValues.usPerson = parseEquivalent(newValues.usPerson);
    if (newValues.documentType === 'other') {
      newValues.documentType = '';
    } else {
      newValues.alternativeDocumentType = '';
    }

    if (!newValues.maritalStatus) {
      delete newValues.propertyRegime;
      delete newValues.startDatePropertyRegime;
    }
    if (!newValues.propertyRegime) {
      delete newValues.propertyRegime;
    }
    if (newValues.photoDocumentFront === null) {
      delete newValues.photoDocumentFront;
    }
    if (newValues.photoDocumentBack === null) {
      delete newValues.photoDocumentBack;
    }

    return newValues;
  }

  const columns = [
    {
      name: 'propertyType',
      label: 'Tipo',
      options: {
        sort: true,
        customBodyRender: value => {
          return TYPES?.find(el => el?.value === value)?.label;
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    // {
    //   name: 'detail',
    //   label: 'Detalle',
    //   options: {
    //     sort: true,
    //     customHeadRender: (columnMeta, updateDirection) => {
    //       return (
    //         <CustomColumnHeader
    //           id={columnMeta.index}
    //           key={columnMeta.index}
    //           columnMeta={columnMeta}
    //           updateDirection={updateDirection}
    //         />
    //       );
    //     },
    //   },
    // },
    {
      name: 'approximateValue',
      label: 'Valor Aproximado',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Bienes Inmuebles"
            options={['cedit', 'odelete']}
            handleDelete={() =>
              deletePropertyMutation[0]({ variables: { id } })
            }
            handleEdit={() => {
              const property = properties.filter(v => id === v.id)[0];
              setShowForm(true);
              setCurrentProperty(property);
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentProperty(null);
            setShowForm(true);
          }}
        >
          Añadir Inmueble
        </Button>
      );
    },
    onRowClick: rowData => {
      setCurrentProperty(properties.find(v => rowData[2].props.id === v.id));
      setShowForm(true);
    },
  };

  const closeForm = () => {
    setCurrentProperty(null);
    setShowForm(false);
  };

  return (
    <>
      {!startValues.documentNumber ||
      !client ||
      clientLoading ||
      MaritalStatusLoading ||
      DocumentTypesLoading ||
      PropertyRegimeLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <StepsForm
            initialValues={startValues}
            validateFunction={validationFunction}
            validateSchema={validationSchema}
            data={data}
            id={clientID}
            mainClientID={clientID}
            title="Datos legales"
            nextForm={
              civilState
                ? MENU_ITEMS_ID.SpousePartnerPersonalData
                : MENU_ITEMS_ID.ClientLabourData
            }
            updateMutation={updateClient}
            partialUpdateMutation={partialUpdateClient}
            formatBeforeSubmit={formatBeforeSubmit}
            subtitle="Información Patrimonial"
            dataInsideCard={properties}
            columns={columns}
            options={options}
            emergencyContact={false}
          />
          <CustomModal
            title="Información Patrimonial"
            showModal={showForm}
            onClosePress={() => closeForm()}
          >
            <PropertyForm
              clientId={client.id}
              propertyData={currentProperty ? currentProperty : {}}
              closeForm={() => closeForm()}
              setCurrentProperty={id =>
                setCurrentProperty(properties.filter(v => v.id === id)[0])
              }
            />
          </CustomModal>
        </>
      )}
    </>
  );
}

LegalDataClient.propTypes = {
  isMarried: PropTypes.bool.isRequired,
  isSociety: PropTypes.bool.isRequired,
  clientID: PropTypes.string.isRequired,
};

export default LegalDataClient;
