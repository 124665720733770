import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  GET_CLIENT_SPOUSE_DATA,
  CREATE_CLIENT_PUBLIC_FUNCTIONS,
  UPDATE_CLIENT_PUBLIC_FUNCTIONS,
  DELETE_CLIENT_PUBLIC_FUNCTIONS,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import { getOptions } from '../../../../utils/commonFunctions';
import CreateEditTable from '../../../CreateEditTable';
import { INPUT_VARIANT_SIMPLE_FORM } from '../../../Forms/SimpleForm';

const validationSchema = Yup.object().shape({
  entity: Yup.string().required(),
  lastRole: Yup.string().required(),
  timeRole: Yup.number().required(),
});
function ClientPEPPublicFunctions(props) {
  const { clientId, data: publicFunctions } = props;
  const { enqueueSnackbar } = useSnackbar();

  const createPublicFunctions = useMutation(
    CREATE_CLIENT_PUBLIC_FUNCTIONS,
    getOptions({
      mutationName: 'createClientPublicFunction',
      modelName: 'publicFunction',
      message: 'Se creó la función pública con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_SPOUSE_DATA, variables: { id: clientId } },
      ],
    })
  );
  const updatePublicFunctions = useMutation(
    UPDATE_CLIENT_PUBLIC_FUNCTIONS,
    getOptions({
      mutationName: 'updateClientPublicFunction',
      modelName: 'publicFunction',
      message: 'Función pública actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const deletePublicFunctions = useMutation(
    DELETE_CLIENT_PUBLIC_FUNCTIONS,
    getOptions({
      mutationName: 'deleteClientPublicFunction',
      modelName: 'publicFunction',
      message: 'Función pública eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_SPOUSE_DATA, variables: { id: clientId } },
      ],
    })
  );

  function formatBeforeSubmit(values) {
    const formatValues = { ...values };
    formatValues.client = clientId;
    formatValues.relation = 'SPOUSE';
    return formatValues;
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const data = [
    {
      label: 'Entidad',
      name: 'entity',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Última función (cargo)',
      name: 'lastRole',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Tiempo en el cargo (años)',
      name: 'timeRole',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
  ];

  const startValuesParser = values => {
    if (!values) {
      return {
        entity: '',
        lastRole: '',
        timeRole: '',
      };
    }
    return values;
  };

  return (
    <CreateEditTable
      table={{
        columns: [
          { label: 'Entidad', name: 'entity' },
          { label: 'Última función (Cargo)', name: 'lastRole' },
          { label: 'Tiempo', name: 'timeRole' },
        ],
        data: publicFunctions,
      }}
      mutations={{
        create: createPublicFunctions,
        update: updatePublicFunctions,
        _delete: deletePublicFunctions,
      }}
      strings={{
        tableName: 'Funciones Públicas',
        modelName: 'Función pública',
        createButtonText: 'Añadir función pública',
      }}
      form={{
        startValuesParser,
        validationFunction,
        validationSchema,
        formatBeforeSubmit,
        structure: data,
      }}
    />
  );
}

ClientPEPPublicFunctions.propTypes = {
  clientId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ClientPEPPublicFunctions;
