// GraphQl
import { gql } from 'apollo-boost';

export const LIST_MARITAL_STATUS = gql`
  {
    listMaritalStatus {
      edges {
        node {
          id
          name
          spouse
          commonLaw
        }
      }
    }
  }
`;

export const GET_MARITAL_STATUS = gql`
  query getMaritalStatus($id: ID!) {
    maritalStatus(id: $id) {
      id
      name
      spouse
    }
  }
`;

export const CREATE_MARITAL_STATUS = gql`
  mutation createMaritalStatus($input: MaritalStatusInput!) {
    createMaritalStatus(input: $input) {
      maritalStatus {
        id
        name
        spouse
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_MARITAL_STATUS = gql`
  mutation updateMaritalStatus($id: ID!, $input: MaritalStatusInput!) {
    updateMaritalStatus(id: $id, input: $input) {
      maritalStatus {
        id
        name
        spouse
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_MARITAL_STATUS = gql`
  mutation deleteMaritalStatus($id: ID!) {
    deleteMaritalStatus(id: $id) {
      maritalStatus {
        id
        name
        spouse
      }
      errors {
        field
        message
      }
    }
  }
`;
