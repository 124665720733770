import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NotFound from '../../NotFoundPage';
import Promoter from '../../../components/Models/Promoter';
import PromoterForm from '../../../components/Models/Promoter/Form';

/**
 * @component
 */
function PromoterPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Promoter} />
      <Route path={`${path}/:id`} component={PromoterForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default PromoterPage;
