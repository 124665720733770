/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import FundList from '../../../components/Models/Fund/FundList';
import FundEdit from '../../../components/Models/Fund/FundCreateEdit/edit';
import FundForm from '../../../components/Models/Fund/FundForm';
import FundRaisings from '../../../components/Models/Fund/FundList/Raisings';
import FundAttachments from '../../../components/Models/Fund/FundList/Attachements';
import RaisingCreateOrEdit from '../../../components/Models/Fund/FundList/Raisings/RaisingEdit';
import RaisingForm from '../../../components/Models/Fund/FundList/Raisings/RaisingForm';
import RaisingAttachments from '../../../components/Models/Fund/FundList/Raisings/RaisingAttachments';
import FundRepurchases from '../../../components/Models/Fund/FundList/Repurchases';
import RepurchaseCreateOrEdit from '../../../components/Models/Fund/FundList/Repurchases/RepurchaseEdit';
import RepurchaseForm from '../../../components/Models/Fund/FundList/Repurchases/RepurchaseForm';

function FundPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={FundList} />
      <Route path={`${path}/crear`} component={FundForm} />
      <Route path={`${path}/adjuntos/:id`} component={FundAttachments} />
      <Route
        path={`${path}/:fundId/levantamientos/crear`}
        component={RaisingForm}
      />
      <Route
        path={`${path}/:fundId/levantamientos/:id`}
        component={RaisingCreateOrEdit}
      />
      <Route path={`${path}/:fundId/levantamientos`} component={FundRaisings} />
      <Route
        path={`${path}/:fundId/recompras/crear`}
        component={RepurchaseForm}
      />
      <Route
        path={`${path}/:fundId/recompras/:id`}
        component={RepurchaseCreateOrEdit}
      />
      <Route path={`${path}/:fundId/recompras`} component={FundRepurchases} />
      <Route
        path={`${path}/:fundId/levantamientos-adjuntos/:id`}
        component={RaisingAttachments}
      />
      <Route path={`${path}/:id`} component={FundEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

FundPage.propTypes = {};

export default FundPage;
