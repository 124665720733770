import { gql } from 'apollo-boost';

export const CREATE_VIDEO_VERIFICATION = gql`
  mutation createVideoVerification($input: VideoVerificationInput!) {
    createVideoVerification(input: $input) {
      videoVerification {
        id
        video
        client {
          id
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_VIDEO_VERIFICATION = gql`
  query getVideoVerification($videoVerificationId: ID!) {
    videoVerification(id: $videoVerificationId) {
      id
      video
      client {
        id
      }
      identityChecker {
        id
        active
        status
      }
    }
  }
`;
