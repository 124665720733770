import React, { useEffect, useState } from 'react';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

// GraphQl
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  LIST_PREFERRED_SUBSCRIPTIONS_RAISING,
  GET_PREFERRED_SUBSCRIPTION_CSV,
} from '@queries/Raisings';
import { MASIVE_MAIL_PSC } from './queries';

// Components & Others
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../../../CustomOptions';
import LoadingIndicator from '../../../../../LoadingIndicator';
import {
  generateColumn,
  downloadBase64Document,
  downloadBase64xlsx,
  errorMessage,
} from '../../../../../../utils/commonFunctions';
import { textLabels } from '../../../../../../translations/components/MUIDataTable';
import LoadingWithMessage from '../../../../../LoadingWithMessage';
import { GET_PREFERENTIAL_SUBSCRIPTION_CERTIFICATE } from '../../../../Documents/queries';

// Styles & Images
import useStyles from '../../styles';

function RaisingsPreferredSubscriptions(props) {
  const { raisingID } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingResult, setLoadingResult] = useState('');

  const [participants, setParticipants] = useState([]);

  const { loading, error: userListError, data: userList } = useQuery(
    LIST_PREFERRED_SUBSCRIPTIONS_RAISING,
    {
      variables: { raising: raisingID },
    },
  );

  if (userListError) {
    errorMessage(
      'Ocurrió un error al solicitar los datos, inténtelo más tarde',
      enqueueSnackbar,
    );
  }

  const [sendPSCEmails] = useMutation(MASIVE_MAIL_PSC, {
    onError: error => {
      setLoadingResult(false);
      setLoadingState(false);
    },
    onCompleted: response => {
      setLoadingResult(response.emailPrefetentialSubscriptionCertificate.sent);
      setLoadingState(false);
    },
  });

  useEffect(() => {
    if (userList) {
      const clients = userList.listPreferredSubscriptionClients;
      const users = [];
      clients.forEach(client =>
        users.push({
          name: client.user.fullName,
          id: client.id,
        }),
      );
      setParticipants(users);
    }
  }, [userList]);

  const [getCsv] = useLazyQuery(GET_PREFERRED_SUBSCRIPTION_CSV, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (
        data.preferredSubscriptionList.csv &&
        data.preferredSubscriptionList.filename
      ) {
        downloadBase64xlsx(
          data.preferredSubscriptionList.csv,
          data.preferredSubscriptionList.filename,
        );
      }
    },
    onError(error) {
      errorMessage(
        'Ocurrió un error al solicitar el archivo, inténtelo más tarde',
        enqueueSnackbar,
      );
    },
  });

  const [getpreferentialSubscriptionCertificate] = useLazyQuery(
    GET_PREFERENTIAL_SUBSCRIPTION_CERTIFICATE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: pdfResponse => {
        if (pdfResponse.preferentialSubscriptionCertificate) {
          downloadBase64Document(
            `b'${pdfResponse.preferentialSubscriptionCertificate.pdf}'`,
            pdfResponse.preferentialSubscriptionCertificate.filename,
            'pdf',
          );
        } else {
          errorMessage(
            'El cliente no tiene participaciones firmadas en el levantamiento actual',
            enqueueSnackbar,
          );
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al solicitar el archivo, inténtelo más tarde',
          enqueueSnackbar,
        );
      },
    },
  );

  const columns = [
    generateColumn('Nombre', 'name'),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Certificado de suscripción preferente"
              options={['ocustom']}
              customOption={
                <span>
                  <Tooltip
                    arrow
                    title="Certificado de suscripción preferente"
                    placement="top"
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        getpreferentialSubscriptionCertificate({
                          variables: { clientId: id, raisingId: raisingID },
                        });
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    viewColumns: false,
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Envio masivo" style={{ order: -1 }}>
            <IconButton
              color="secondary"
              onClick={() => {
                sendPSCEmails({
                  variables: {
                    raisingId: raisingID,
                  },
                });
                setLoadingState(true);
                setLoadingModal(true);
              }}
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Descarga masiva" style={{ order: -1 }}>
            <IconButton
              color="secondary"
              onClick={() => {
                getCsv({ variables: { raising: raisingID } });
              }}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MUIDataTable
            title="Suscripciones preferentes"
            data={participants}
            columns={columns}
            options={options}
          />
          <LoadingWithMessage
            visible={loadingModal}
            loading={loadingState}
            result={loadingResult}
            title="Envío de correos masivo"
            onClose={() => setLoadingModal(false)}
          />
        </>
      )}
    </>
  );
}

RaisingsPreferredSubscriptions.propTypes = {
  raisingID: PropTypes.string.isRequired,
};

export default RaisingsPreferredSubscriptions;
