import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_SWORNDECLARATIONS, DELETE_SWORNDECLARATION } from '../queries';

import {
  edgeToList,
  generateColumn,
  errorMessage,
  getOptions,
} from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { CONDITIONS } from '../SwornDeclarationForm/constants';

// Styles & Images
import useStyles from './styles';

function SwornDeclarationList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [typeSwornDeclarationList, setTypeSwornDeclarationList] = useState([]);

  const classes = useStyles();

  const [deleteTypeSwornDeclaration] = useMutation(
    DELETE_SWORNDECLARATION,
    getOptions({
      mutationName: 'deleteTypeSwornDeclaration',
      modelName: 'typeSwornDeclaration',
      message: 'Declaración jurada eliminada con éxito',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SWORNDECLARATIONS }],
    })
  );

  const { loading, error, data } = useQuery(LIST_SWORNDECLARATIONS);
  if (error) {
    errorMessage(
      'Error al obtener la lista de declaraciones juradas',
      enqueueSnackbar
    );
  }
  useEffect(() => {
    if (data) {
      setTypeSwornDeclarationList(edgeToList(data, 'listTypeSwornDeclaration'));
    }
  }, [data]);

  const columns = [
    generateColumn('Nombre', 'name'),
    generateColumn('Estado civil', 'condition', {
      sort: true,
      filter: false,
      customBodyRender: value => CONDITIONS.find(c => c.value === value).label,
    }),
    generateColumn('Fondo', 'fund', {
      sort: true,
      filter: false,
      customBodyRender: value => (value ? value.businessName : '-'),
    }),
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Declaración jurada"
            options={['odelete']}
            handleDelete={() => {
              deleteTypeSwornDeclaration({ variables: { id } });
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={4} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `${history.location.pathname}/${rowData[rowData.length - 1].props.id}`
      );
    }
  };

  const options = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
    customToolbar: () => {
      const handleClick = () => {
        history.push(`/administrador/declaraciones-juradas/crear`);
      };
      return (
        <Tooltip title="Nuevo Levantamiento">
          <IconButton color="secondary" onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onRowClick: rowData => handleRowClick(rowData),
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <MUIDataTable
          title="Declaraciones juradas"
          data={typeSwornDeclarationList}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}

export default SwornDeclarationList;
