import Yup from '../../../../utils/yup';

export function validationFunctionMonthlyIncomeRange(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaMonthlyIncomeRange = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Maximo 50 caracteres')
    .required(),
  maximum: Yup.number()
    .moreThan(-1, 'El valor deber ser 0 o mayor')
    .integer(),
  minimum: Yup.number()
    .moreThan(-1, 'El valor deber ser 0 o mayor')
    .when('maximum', {
      is: val => {
        return !val;
      },
      then: Yup.number()
        .moreThan(-1, 'El valor deber ser 0 o mayor')
        .required('Debe de llenar al menos un campo (máximo o mínimo)'),
    }),
});
