import React, { useState } from 'react';

// Material Resources
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Button,
} from '@material-ui/core';

import TollIcon from '@material-ui/icons/Toll';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupIcon from '@material-ui/icons/Group';
import AttachEmailIcon from '@material-ui/icons/AttachFile';

// GraphQl
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import MUIDataTable from 'mui-datatables';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { textLabels } from '../../../../../translations/components/MUIDataTable';
import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';
import CustomOptions from '../../../../CustomOptions';
import LoadingIndicator from '../../../../LoadingIndicator';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import {
  getOptions,
  generateColumn,
  edgeToList,
  scientificToDecimal,
} from '../../../../../utils/commonFunctions';

import {
  LIST_RAISING_FUND,
  DELETE_RAISING_FUND,
  RAISING_PREVIEW,
  SEND_QUOTA_VALORIZATION_NOTICE,
  GET_FUND,
  SEND_EMAILS_RAISING,
} from './queries';
import CustomModal from '../../../../CustomModal';
import RaisingsWheels from './Wheels';
import RaisingsParticipants from './Participants';
import RaisingsIndividualEmails from './IndividualEmails';
import LoadingWithMessage from '../../../../LoadingWithMessage';
import PDFViewerModal from '../../../../PDFViewerModal';
import RaisingsPreferredSubscriptions from '../Raisings/PreferredSubscriptions';

// Styles & Images
import useStyles from '../styles';

const RAISINGS_SUBMODELS = {
  PreferredSubscriptions: 1,
  IndividualEmails: 2,
};

const BELOW_LISTS = {
  WHEELS: 1,
  PARTICIPANTS: 2,
};

function FundRepurchases() {
  const { fundId: fundID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const history = useHistory();

  const [belowListRaisingID, setBelowListRaisingID] = useState(false);
  const [selectedBelowList, setSelectedBelowList] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [raisingID, setRaisingID] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingResult, setLoadingResult] = useState('');

  const [previewModal, setPreviewModal] = useState(false);

  const [selectedSubmodel, setSelectedSubmodel] = useState(
    RAISINGS_SUBMODELS.Wheels,
  );
  const [isQuotaValorizationNotice, setIsQuotaValorizationNotice] = useState(
    false,
  );

  const { loading, data } = useQuery(LIST_RAISING_FUND, {
    variables: { id: fundID },
  });

  const [
    getPreview,
    { loading: previewLoading, error: previewError, data: previewData },
  ] = useLazyQuery(RAISING_PREVIEW, {
    variables: { id: raisingID },
  });

  const { data: infoFund } = useQuery(GET_FUND, {
    variables: { id: fundID },
  });

  const [deleteRaising] = useMutation(
    DELETE_RAISING_FUND,
    getOptions({
      mutationName: 'deleteRaisingCompany',
      modelName: 'repurchase',
      message: 'Recompras eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_RAISING_FUND, variables: { id: fundID } }],
    }),
  );

  const [sendEmails] = useMutation(
    SEND_EMAILS_RAISING,
    getOptions({
      mutationName: 'emailRaisingNotice',
      modelName: 'sent',
      message: 'Aviso de Recompras enviado con éxito',
      completeCallback: (data, error) => {
        setLoadingResult(data);
        setLoadingState(false);
        setLoadingModal(false);
      },
      errorCallback: error => {
        setLoadingResult(false);
        setLoadingState(false);
        setLoadingModal(false);
      },
      completeErrorCallback: (data, error) => {
        setLoadingResult(false);
        setLoadingState(false);
        setLoadingModal(false);
      },
      enqueueSnackbar,
    }),
  );

  const [sendQuotaValorizationNotice] = useMutation(
    SEND_QUOTA_VALORIZATION_NOTICE,
    getOptions({
      mutationName: 'sendQuotaValorizationNotice',
      modelName: 'sent',
      message: 'Aviso de valorización de cuotas enviado con éxito',
      completeCallback: (data, error) => {
        setLoadingResult(data);
        setLoadingState(false);
        setLoadingModal(false);
      },
      errorCallback: error => {
        setLoadingResult(false);
        setLoadingState(false);
        setLoadingModal(false);
      },
      completeErrorCallback: (data, error) => {
        setLoadingResult(false);
        setLoadingState(false);
        setLoadingModal(false);
      },
      enqueueSnackbar,
    }),
  );

  const columnOptions = {
    filter: false,
    sort: true,
  };
  const columns = [
    generateColumn('Número de recompra', 'number', columnOptions),
    generateColumn('Capital objetivo', 'targetCapitalIncrease', {
      ...columnOptions,
      customBodyRender: fees => (
        <NumberFormat thousandSeparator displayType="text" value={fees} />
      ),
    }),
    {
      name: 'valueFee',
      label: 'Valor cuota',
      options: {
        sort: true,
        customBodyRender: value => scientificToDecimal(value),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    generateColumn('Clases', 'classes', {
      customBodyRender: value => {
        let result = '';
        value.edges.forEach(clase => {
          result += clase.node.name + ', ';
        });
        return result;
      },
    }),
    generateColumn('Cuotas', 'fees', {
      ...columnOptions,
      customBodyRender: fees => (
        <NumberFormat thousandSeparator displayType="text" value={fees} />
      ),
    }),
    {
      name: 'missingFees',
      label: 'Cuotas Faltantes',
      options: {
        sort: true,
        customBodyRender: value => (
          <NumberFormat thousandSeparator displayType="text" value={value} />
        ),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Recompras"
              options={['ocustom', 'odelete']}
              handleDelete={() => {
                deleteRaising({ variables: { id } });
              }}
              fullWidth
              customOption={
                <>
                  <Tooltip arrow title="Participantes" placement="top">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setBelowListRaisingID(id);
                        setSelectedBelowList(BELOW_LISTS.PARTICIPANTS);
                      }}
                    >
                      <GroupIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Ruedas" placement="top">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setBelowListRaisingID(id);
                        setSelectedBelowList(BELOW_LISTS.WHEELS);
                      }}
                    >
                      <TollIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
              separatedOptions={
                <>
                  <Tooltip
                    arrow
                    title="Envío de correos múltiples"
                    placement="top"
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        sendEmails({
                          variables: {
                            repurchaseId: id,
                            recipients: [],
                          },
                        });
                        setLoadingState(true);
                        setLoadingModal(true);
                      }}
                    >
                      <MailOutlineOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    arrow
                    title="Envío de correos individuales"
                    placement="top"
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setIsQuotaValorizationNotice(false);
                        setSelectedSubmodel(
                          RAISINGS_SUBMODELS.IndividualEmails,
                        );
                        setRaisingID(id);
                        setShowModal(true);
                      }}
                    >
                      <ContactMailOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Pre visualización" placement="top">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setRaisingID(id);
                        getPreview();
                        setPreviewModal(true);
                      }}
                    >
                      <PageviewOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      const handleClick = () => {
        history.push(`/administrador/fondos/${fundID}/recompras/crear`);
      };
      return (
        <Tooltip title="Nueva Recompra">
          <IconButton color="secondary" onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onRowClick: rowData => {
      if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
        history.push(
          `/administrador/fondos/${fundID}/recompras/${
            rowData[rowData.length - 1].props.id
          }`,
        );
      }
    },
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MUIDataTable
            title={`Recompras - ${infoFund?.fund?.businessName}`}
            data={data && edgeToList(data.fund, 'repurchaseSet')}
            columns={columns}
            options={options}
          />
          {selectedBelowList === BELOW_LISTS.WHEELS && (
            <RaisingsWheels raisingID={belowListRaisingID} />
          )}
          {selectedBelowList === BELOW_LISTS.PARTICIPANTS && (
            <RaisingsParticipants raisingID={belowListRaisingID} />
          )}

          <CustomModal
            maxWidth="lg"
            showModal={showModal}
            title="Recompras"
            onClosePress={() => {
              setShowModal(false);
            }}
            fullWidth
          >
            {selectedSubmodel === RAISINGS_SUBMODELS.Wheels && (
              <RaisingsWheels raisingID={raisingID} />
            )}
            {selectedSubmodel === RAISINGS_SUBMODELS.PreferredSubscriptions && (
              <RaisingsPreferredSubscriptions raisingID={raisingID} />
            )}
            {selectedSubmodel === RAISINGS_SUBMODELS.IndividualEmails && (
              <RaisingsIndividualEmails
                raisingID={raisingID}
                isQuotaValorizationNotice={isQuotaValorizationNotice}
                onFinish={() => setShowModal(false)}
              />
            )}
          </CustomModal>
          <LoadingWithMessage
            visible={loadingModal}
            loading={loadingState}
            result={loadingResult}
            title="Envío de correos múltiples"
            onClose={() => setLoadingModal(false)}
          />

          <PDFViewerModal
            showModal={previewModal}
            onClosePress={() => setPreviewModal(false)}
            fileB64={
              previewData &&
              previewData.repurchaseNotice &&
              `data:application/pdf;base64,${previewData.repurchaseNotice.pdf}`
            }
            loading={previewLoading}
          />
        </>
      )}
    </>
  );
}

FundRepurchases.propTypes = {};

export default FundRepurchases;
