// GraphQl
import { gql } from 'apollo-boost';

export const LIST_RAISING_FUND = gql`
  query getFundRaisings($id: ID!) {
    fund(id: $id) {
      id
      repurchaseSet {
        edges {
          node {
            id
            number
            targetCapitalIncrease
            valueFee
            valueFeeNominal
            fees
            missingFees
            classes {
              edges {
                node {
                  id
                  name
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const LIST_CLASS_FUND = gql`
  {
    listClassFund {
      edges {
        node {
          id
          name
          state
        }
      }
    }
  }
`;
// eslint-disable-next-line import/prefer-default-export
export const GET_REPURCHASE_AND_FUND_INFORMATION = gql`
  query getRepurchaseAndFundInformation($repurchaseId: ID) {
    repurchase(id: $repurchaseId) {
      id
      classes {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      valueFee
      valueFeeNominal
      targetCapitalIncrease
      initialParticipationOrderReceptionDate
      lastParticipationOrderReceptionDate
      initialFeePaymentDate
      lastFeePaymentDate
      dateEmissionFee
      wheelSet {
        edges {
          node {
            id
            dateInitial
            dateEnd
            dateAward
          }
        }
      }
      fund {
        id
        hasClasses
        businessName
        fundType
        minimumInvestment
        currency {
          code
          symbol
        }
        funddocumentSet {
          edges {
            node {
              fileType
              file
              date
              state
            }
          }
        }
      }
    }
  }
`;

export const GET_RAISING_FUND = gql`
  query getRaising($id: ID!) {
    repurchase(id: $id) {
      id
      classes {
        edges {
          node {
            id
            name
            state
          }
        }
      }
      number
      targetCapitalIncrease
      valueFee
      valueFeeNominal
      dateEmissionFee
      initialFeePaymentDate
      lastFeePaymentDate
      repurchaseNoticeDate
      initialParticipationOrderReceptionDate
      lastParticipationOrderReceptionDate
      initialFeeAdjudicationDate
      finalFeeAdjudicationDate
      valorizationDate
      importantFactDate
      fees
      missingFees
    }
  }
`;

export const CREATE_RAISING_FUND = gql`
  mutation createRepurchase($input: RepurchaseInput!) {
    createRepurchase(input: $input) {
      repurchase {
        id
        classes {
          edges {
            node {
              id
              name
              state
            }
          }
        }
        number
        targetCapitalIncrease
        valueFee
        valueFeeNominal
        dateEmissionFee
        initialFeePaymentDate
        lastFeePaymentDate
        repurchaseNoticeDate
        initialParticipationOrderReceptionDate
        lastParticipationOrderReceptionDate
        initialFeeAdjudicationDate
        finalFeeAdjudicationDate
        valorizationDate
        importantFactDate
        fees
        missingFees
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_RAISING_FUND = gql`
  mutation updateRaising($id: ID!, $input: RepurchaseInput!) {
    updateRepurchase(id: $id, input: $input) {
      repurchase {
        id
        classes {
          edges {
            node {
              id
              name
              state
            }
          }
        }
        number
        targetCapitalIncrease
        valueFee
        valueFeeNominal
        dateEmissionFee
        initialFeePaymentDate
        lastFeePaymentDate
        repurchaseNoticeDate
        initialParticipationOrderReceptionDate
        lastParticipationOrderReceptionDate
        initialFeeAdjudicationDate
        finalFeeAdjudicationDate
        valorizationDate
        importantFactDate
        fees
        missingFees
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_RAISING_FUND = gql`
  mutation deleteRaising($id: ID!) {
    deleteRepurchase(id: $id) {
      repurchase {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const RAISING_PREVIEW = gql`
  query repurchaseNotice($id: ID) {
    repurchaseNotice(id: $id) {
      pdf
      filename
    }
  }
`;

export const SEND_QUOTA_VALORIZATION_NOTICE = gql`
  mutation sendQuotaValorizationNoticeRepurchase(
    $repurchaseId: ID!
    $recipients: [RecipientEmailInput]
  ) {
    sendQuotaValorizationNoticeRepurchase(
      repurchaseId: $repurchaseId
      recipients: $recipients
    ) {
      sent
    }
  }
`;

export const SEND_EMAILS_RAISING = gql`
  mutation emailRepurchaseNotice(
    $repurchaseId: ID!
    $recipients: [RecipientEmailInput]
  ) {
    emailRepurchaseNotice(
      repurchaseId: $repurchaseId
      recipients: $recipients
    ) {
      sent
    }
  }
`;

export const GET_FUND = gql`
  query getFund($id: ID!) {
    fund(id: $id) {
      id
      logo
      state
      hasClasses
      fundType
      ruc
      businessName
      acronym
      address
      subscriptionRight
      performanceGoal
      currency {
        id
        name
      }
      category {
        id
        name
      }
      fundCapitalGoal
      phoneNumber
      maximumCoParticipants
      allocationMechanisms
      startRegulationAgreementDate
      endRegulationAgreementDate
      nominalFeeValue
      subscribedPaidFees
      lifetime
      minimumInvestment
      coParticipation
      exchangeRateRisk
      createdAt
      endedAt
      accounts {
        edges {
          node {
            id
          }
        }
      }
      instrumentfundSet {
        edges {
          node {
            id
            instrument {
              id
              name
            }
            minimum
            maximum
          }
        }
      }
    }
  }
`;
