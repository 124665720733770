/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import ComplianceList from '../../../components/Models/Compliance/ComplianceList';

function CompliancePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ComplianceList} />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

CompliancePage.propTypes = {};

export default CompliancePage;
