import { gql } from 'apollo-boost';

export const LIST_COMPLIANCE_CLIENTS = gql`
  query listComplianceClients {
    listClients(state: "compliance") {
      edges {
        node {
          id
          urlRiskReport
          user {
            id
            firstName
            lastName
            maternalSurname
            isStaff
            isActive
            email
            userType
            documentType {
              id
              name
            }
            documentNumber
          }
          promoter {
            user {
              id
              firstName
              lastName
              maternalSurname
            }
          }
          state
        }
      }
    }
  }
`;

export const REJECTED_CLIENT = gql`
  mutation rejectedClient($userId: ID!) {
    rejectClient(userId: $userId) {
      rejected
    }
  }
`;

export const APPROVE_COMPLIANCE_CLIENT = gql`
  mutation approveComplianceClient($userId: ID!) {
    approveComplianceClient(userId: $userId) {
      approved
    }
  }
`;

export const REFRESH_RISK_PROFILE_CLIENT = gql`
  mutation refreshRiskProfileClient($userId: ID!) {
    refreshRiskProfileClient(userId: $userId) {
      refreshed
    }
  }
`;
