import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dashboardPage: {},
  logo: {
    width: 180,
  },
  container: {
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    alignItems: 'flex-start',
  },
  content: {
    overflow: 'scroll',
    display: 'flex',
    width: '100%',
    justifyContent: 'start',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    padding: '5px',
  },

  card: {
    background: '#fff',
    borderLeft: 'solid 4px #F97A00',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    borderRadius: '10px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    boxShadow: '0px 4px 10px 0px rgba(166, 193, 211, 0.60)',
    minWidth: '20.81vh',
    minHeight: '10.81vh',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',

    padding: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      minHeight: 'fit-content',
      justifyContent: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    '&:hover': {
      boxShadow: '0px 8px 20px 0px rgba(166, 193, 211, 0.65)',
    },
  },

  cardDark: {
    background: '#1b1b1b',
    borderLeft: 'solid 4px #A6C1D3',
    boxShadow: '0px 4px 10px 0px rgb(166 193 211 / 16%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    borderRadius: '10px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    minWidth: '20.81vh',
    minHeight: '10.81vh',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',

    padding: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      minHeight: 'fit-content',
      justifyContent: 'center',
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    '&:hover': {
      boxShadow: ' 0px 4px 10px 0px rgba(166, 193, 211, 0.50)',
    },
  },
  title: {
    fontSize: '0.70rem',
    color: '#292D32',
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
  },
  titleDark: {
    fontSize: '0.70rem',
    color: '#C7C5C5',
    fontWeight: 700,
    textTransform: 'uppercase',
    margin: 0,
  },
  nameCard: {
    fontSize: '1.320rem',
    color: '#F97A00',
    fontWeight: 800,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    margin: 0,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  nameCardDark: {
    fontSize: '1.320rem',
    color: '#A6C1D3',
    fontWeight: 800,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    margin: 0,
    overflowWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },

  sectionDiv: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  cardsDiv: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    // gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
}));
