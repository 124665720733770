import React from 'react';
import PropTypes from 'prop-types';

import { toMoneyRepresentation } from '../../utils/commonFunctions';

function Money(props) {
  const { currency, amount } = props;
  return (
    <span>
      {currency} {toMoneyRepresentation(amount.toFixed(2))}
    </span>
  );
}

Money.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default Money;
