import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// GraphQl
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
// Styles
import useStyles from './styles';
import { useFormikContext } from 'formik';

const LIST_NATIONALITIES = gql`
  {
    nationalities {
      code
      name
    }
  }
`;

export const reorderNationalities = nationalityList => {
  const newList = [...nationalityList];
  const index = newList.findIndex(value => value.code === 'PE');

  if (index !== -1) {
    newList.unshift(newList.splice(index, 1)[0]);
  }
  return newList;
};

const NationalitySelect = ({
  id,
  name,
  label,
  error,
  defaultValue,
  setFieldValue: setFieldValueIntern,
}) => {
  const { setFieldValue, values: formValues } = useFormikContext();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(defaultValue || []);
  const [inputValue, setInputValue] = useState('');
  const selectRef = useRef(null);

  const {
    error: nationalityError,
    data: nationalityData,
    loading: nationalityLoading,
  } = useQuery(LIST_NATIONALITIES);

  if (nationalityError) {
    console.error('nationalityError', nationalityError);
  }

  useEffect(() => {

    if (nationalityData && !nationalityLoading) {
      const initialValue = [];
      const defaultValueApi = formValues[name];
      defaultValueApi.forEach(dv => {
        const nat = nationalityData.nationalities.find(
          item => item.code === dv,
        );
        if (nat) {
          const { __typename, ...natVal } = nat;
          initialValue.push(natVal);
        }
      });

      setSelectedValues(initialValue);
      setFilteredOptions(nationalityData.nationalities);
    }
  }, [nationalityLoading, defaultValue]);

  const handleSelect = option => {
    const updatedValues = [...selectedValues, option];

    setSelectedValues(updatedValues);
    setFieldValue(
      name,
      updatedValues.map(val => val.code),
    );
    setFieldValueIntern(
      name,
      updatedValues.map(val => val.code),
    );
  };

  const handleRemove = option => {
    const updatedValues = selectedValues.filter(
      val => val.code !== option.code,
    );

    setSelectedValues(updatedValues);
    setFieldValue(
      name,
      updatedValues.map(val => val.code),
    );
    setFieldValueIntern(
      name,
      updatedValues.map(val => val.code),
    );
  };

  useEffect(() => {
    if (nationalityData && inputValue) {
      const filtered = nationalityData.nationalities.filter(option =>
        option.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setFilteredOptions(filtered);
    } else if (nationalityData) {
      setFilteredOptions(nationalityData.nationalities);
    }
  }, [inputValue, nationalityData]);

  const classes = useStyles();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.divInput}>
      <label htmlFor={id} className={classes.inputLabel}>
        {label}
      </label>

      <div className={classes.formControl} ref={selectRef}>
        <div
          className={`${classes.customSelect} ${isOpen ? classes.open : ''}`}
          onClick={handleToggle}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
            }}
          >
            <input
              type="text"
              value={inputValue}
              style={{
                width: '100%',
                height: '100%',
                outline: 'none',
                border: 'none',
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                fontWeight: 400,
              }}
              onChange={e => setInputValue(e.target.value)}
              placeholder="Escribe para filtrar..."
              className={classes.inputField}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '4px',
              }}
            >
              {selectedValues.length > 0 &&
                selectedValues.map(option => (
                  <span
                    key={option.code}
                    style={{
                      backgroundColor: '#f97a00',
                      color: '#fff',
                      padding: '4px',
                      fontSize: '0.90rem',
                      fontWeight: 400,
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '2px',
                    }}
                  >
                    {option.name}
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        color: '#fff',
                        border: 'none',
                        outline: 'none',
                      }}
                      onClick={() => handleRemove(option)}
                    >
                      x
                    </button>
                  </span>
                ))}
            </div>
          </div>
        </div>

        {isOpen && (
          <ul className={classes.optionsList}>
            {filteredOptions.length > 0 &&
              filteredOptions.map(option => (
                <li
                  className={classes.option}
                  key={option.code}
                  onClick={() => handleSelect(option)}
                >
                  {option.name}
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

NationalitySelect.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
};

export default NationalitySelect;
