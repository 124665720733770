import { gql } from 'apollo-boost';

export const GET_CLIENT_DATA = gql`
  query currentUser {
    currentUser {
      id
      clientSet {
        edges {
          node {
            id
            enviarComunicacion
            nombreComunicacion
            nombreCanalActual
            nombreCanalOrigen
            correoIntermediario
            canalActual
            canalOrigen
            maritalStatus {
              id
              spouse
            }
            propertyRegime {
              id
              society
            }
            assignee {
              id
            }
            birthplace {
              id
              country {
                id
              }
            }
            missingSteps {
              edges {
                node {
                  id
                  step
                }
              }
            }
            pep
            spousePep
            relativePep
            pdfSheet
            treeStatus
            isMain
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_PDF = gql`
  query PDFAsBase64($id: ID!) {
    pdfClient(id: $id) {
      pdf
      filename
    }
  }
`;

export const LIST_MISSING_STEP = gql`
  query listMissingStep {
    listMissingStep {
      edges {
        node {
          id
          step
        }
      }
    }
  }
`;
