/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import CategoryList from '../../../components/Models/Category/CategoryList';
import CategoryEdit from '../../../components/Models/Category/CategoryCreateEdit/edit';
import CategoryForm from '../../../components/Models/Category/CategoryForm';

function CategoryPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={CategoryList} />
      <Route path={`${path}/crear`} component={CategoryForm} />
      <Route path={`${path}/:id`} component={CategoryEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default CategoryPage;
