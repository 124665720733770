import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import BankAccountForm from '../BankAccountForm';
import { GET_BANK_ACCOUNT } from '../queries';
import { parseBool } from '../../../../utils/commonFunctions';

function BankAccountEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_BANK_ACCOUNT, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('list bank account', error);
    history.goBack();
  }

  const formatValue = { ...data.bankAccount };
  if (!data.bankAccount) {
    history.goBack();
  }

  formatValue.bank = formatValue.bank ? formatValue.bank.id : '';
  formatValue.currency = formatValue.currency.id;
  formatValue.country = formatValue.country ? formatValue.country.id : '';
  formatValue.accountType = formatValue.accountType.id;
  formatValue.jointAccount = parseBool(formatValue.jointAccount);

  return <BankAccountForm bankAccount={formatValue} id={id} isEdit />;
}

BankAccountEdit.propTypes = {};

export default BankAccountEdit;
