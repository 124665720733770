/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import MonthlyIncomeRangeList from '../../../components/Models/MonthlyIncomeRange/MonthlyIncomeRangeList';
import MonthlyIncomeRangeEdit from '../../../components/Models/MonthlyIncomeRange/MonthlyIncomeRangeCreateEdit/edit';
import MonthlyIncomeRangeForm from '../../../components/Models/MonthlyIncomeRange/MonthlyIncomeRangeForm';

function MonthlyIncomeRangePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={MonthlyIncomeRangeList} />
      <Route path={`${path}/crear`} component={MonthlyIncomeRangeForm} />
      <Route path={`${path}/:id`} component={MonthlyIncomeRangeEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default MonthlyIncomeRangePage;
