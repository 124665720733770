/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Components & Others

// Styles & Images
import useStyles from './styles';
import InvestorProfile from './profile';
import InvestorCards from './cards';

function InvestorDashboardPage(props) {
  const classes = useStyles();
  const { userUrlF2 } = props;
  // layoutDark

  return (
    <>
      <div className={classes.content}>
        <InvestorCards urlF2={userUrlF2} />
      </div>
      <div className={classes.contentProfile}>
        <InvestorProfile />
      </div>
    </>
  );
}

export default InvestorDashboardPage;
