// GraphQl
import { gql } from 'apollo-boost';

export const LIST_PROPERTY_REGIME = gql`
  {
    listPropertyRegimes {
      edges {
        node {
          id
          name
          society
          commonLaw
        }
      }
    }
  }
`;

export const GET_PROPERTY_REGIME = gql`
  query getPropertyRegime($id: ID!) {
    propertyRegime(id: $id) {
      id
      name
      society
    }
  }
`;

export const CREATE_PROPERTY_REGIME = gql`
  mutation createPropertyRegime($input: PropertyRegimeInput!) {
    createPropertyRegime(input: $input) {
      propertyRegime {
        id
        name
        society
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROPERTY_REGIME = gql`
  mutation updatePropertyRegime($id: ID!, $input: PropertyRegimeInput!) {
    updatePropertyRegime(id: $id, input: $input) {
      propertyRegime {
        id
        name
        society
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_PROPERTY_REGIME = gql`
  mutation deletePropertyRegime($id: ID!) {
    deletePropertyRegime(id: $id) {
      propertyRegime {
        id
        name
        society
      }
      errors {
        field
        message
      }
    }
  }
`;
