export const ACTIONS = {
  PROMOTER_COMPANY_BANK_ACCOUNT_ID: 'PROMOTER_COMPANY_BANK_ACCOUNT_ID',
  PROMOTER_BANK_ACCOUNT_ID: 'PROMOTER_BANK_ACCOUNT_ID',
  SET_CLIENT: 'SET_CLIENT',
  REMOVE_CLIENT: 'REMOVE_CLIENT',
  SET_PROMOTER: 'SET_PROMOTER',
};

export function setBankAccountIdPromoterCompany(bankAccountId) {
  return {
    type: ACTIONS.PROMOTER_COMPANY_BANK_ACCOUNT_ID,
    bankAccountId,
  };
}

export function setBankAccountIdPromoter(bankAccountId) {
  return {
    type: ACTIONS.PROMOTER_BANK_ACCOUNT_ID,
    bankAccountId,
  };
}

export function setClient(client) {
  return {
    type: ACTIONS.SET_CLIENT,
    client,
  };
}

export function removeClient() {
  return {
    type: ACTIONS.REMOVE_CLIENT,
  };
}

export function setPromoter(promoter) {
  return {
    type: ACTIONS.SET_PROMOTER,
    promoter,
  };
}
