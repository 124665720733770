// GraphQl
import { gql } from 'apollo-boost';

export const LIST_EDUCATION_LEVELS = gql`
  {
    listEducationLevels {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_EDUCATION_LEVEL = gql`
  query getEducationLevel($id: ID!) {
    educationLevel(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_EDUCATION_LEVEL = gql`
  mutation createEducationLevel($input: EducationLevelInput!) {
    createEducationLevel(input: $input) {
      educationLevel {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_EDUCATION_LEVEL = gql`
  mutation updateEducationLevel($id: ID!, $input: EducationLevelInput!) {
    updateEducationLevel(id: $id, input: $input) {
      educationLevel {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_EDUCATION_LEVEL = gql`
  mutation deleteEducationLevel($id: ID!) {
    deleteEducationLevel(id: $id) {
      educationLevel {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;
