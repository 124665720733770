import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.colors.white,
  },
  titleLight: {
    fontSize: 20,
    fontWeight: 600,
    color: '#0F1729',
  },
  titleDark: {
    fontSize: 20,
    fontWeight: 600,
    color: '#BEBEBE',
  },
  formContainer: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  children: {
    overflowY: 'scroll',
  },
  customToolbar: {
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '10px',
  },
}));
