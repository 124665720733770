import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';

export default makeStyles(theme => ({
  mainDiv: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
      alignItems: 'flex-start',
      height: 'fit-content',
    },
  },
  welcomeDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
  },
  profileDiv: {
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'start',
      height: 'fit-content',
      paddingLeft: '10px',
    },
  },

  name: {
    fontWeight: '700',
    fontSize: '1.2rem',
    color: '#292D32',
  },
  welcome: {
    fontWeight: '500',
    fontSize: '1.2rem',
    color: '#292D32',
  },
  nameDark: {
    fontWeight: '700',
    fontSize: '1.2rem',
    color: '#ECECEC',
  },
  welcomeDark: {
    fontWeight: '500',
    fontSize: '1.2rem',
    color: '#ECECEC',
  },
  editCover: {
    height: 'fit-content',
    zIndex: 99,
    width: 'fit-content',
    borderRadius: '50%',
    background: 'transparent',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: 'rgba(41, 45, 50, 0.26)',
      backdropFilter: 'blur(2px)',
    },
  },
  contentHover: {
    top: 50,
    left: 40,
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    color: '#fff',
    fontSize: '0.80rem',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));
