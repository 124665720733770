import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    paddingTop: '2rem',
    padding: '1rem',
  },
  tabContent: {
    paddingTop: '2rem',
    backgroundColor: theme.palette.colors.white,
  },
  tableTitle: {
    fontWeight: 500,
  },
  detailText: {
    fontWeight: 500,
    fontSize: 16,
  },
  responseText: {
    fontWeight: 600,
    fontSize: 17,
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  titleContainer: {
    marginBottom: 25,
    marginTop: 20,
  },
  infoSection: {
    paddingBottom: 25,
  },
  error: {
    textAlign: 'center',
    color: theme.palette.colors.red,
    fontWeight: 400,
  },
}));
