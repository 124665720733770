import React from 'react';

// Material Resources
import {
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  FormHelperText,
} from '@material-ui/core';

// Others
import { RadioGroup } from 'formik-material-ui';
import PropTypes from 'prop-types';

// Styles & Images
import useStyles from './styles';

function RadioButtons(props) {
  const {
    id,
    data,
    name,
    label,
    error,
    labelMD,
    vertical,
    mapData,
    onChange,
    disabled,
  } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {label !== '' && (
        <Grid item md={labelMD} xs={12}>
          <Typography className={classes.label}>{label}</Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.radiosContainer}>
        <RadioGroup name={name} row={!vertical} id={id} onClick={onChange}>
          {data.map(element => (
            <FormControlLabel
              key={element[mapData.value]}
              value={element[mapData.value]}
              control={<Radio />}
              label={element[mapData.label]}
              disabled={disabled}
            />
          ))}
        </RadioGroup>
        {error !== '' && <FormHelperText error>{error}</FormHelperText>}
      </Grid>
    </Grid>
  );
}

RadioButtons.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  label: PropTypes.string,
  labelMD: PropTypes.number,
  vertical: PropTypes.bool,
  name: PropTypes.string.isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  error: PropTypes.string,
};
RadioButtons.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  label: '',
  labelMD: 12,
  vertical: false,
  onChange: () => null,
  error: '',
};

export default RadioButtons;
