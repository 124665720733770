import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Grid,
  // Select,
} from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
import { Select } from 'formik-material-ui';
import { ErrorMessage, useFormikContext } from 'formik';
// Styles & Images
import useStyles from './styles';

function CustomSelectMaterialCoreClient(props) {
  // data : Array of elements, example: [{ country_name: "Peru", country_val: "PE" }]
  // mapData: Define which object property use as value and label, example: {value: "country_val", label: "country_name"}
  // mapData default => {value: "value", label: "label"}
  const {
    id,
    disabled,
    name,
    label,
    data,
    mapData,
    onChange,
    error,
    isStepForm,
 
  } = props;
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const selectRef = useRef(null);
  const { setFieldValue, values: formValues } = useFormikContext();
  useEffect(() => {
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (value, label) => {
    setSelectedOption(label);
    setIsOpen(false);
    const mockEvent = {
      target: {
        name,
        value,
      },
      preventDefault: () => {},
    };

    onChange(mockEvent);
    setFieldValue(name, value);
  };
  useEffect(() => {
    if (data && data.length > 0 && selectedOption === '') {
      const initialValue = formValues[name];
      const selectedOptions = data.find(
        element => element[mapData.value] === initialValue,
      );

      if (selectedOptions) {
        setSelectedOption(selectedOptions[mapData.label]);
      } else {
        setSelectedOption('Seleciona una opción');
      }
    }
  }, [data, mapData]);



  return (
    <>
      <div className={classes.divInput}>
        <label htmlFor={id} className={classes.inputLabel}>
          {label}
        </label>
      </div>
      <div className={classes.formControl} ref={selectRef}>
        <div
          className={`${classes.customSelect} ${isOpen ? classes.open : ''} ${
            error ? classes.error : ''
          }`}
          onClick={handleToggle}
        >
          {selectedOption}
        </div>
        {isOpen && (
          <ul className={classes.optionsList}>
            {data &&
              data.length > 0 &&
              data.map(element => (
                <li
                  key={element[mapData.value]}
                  className={classes.option}
                  onClick={() =>
                    handleSelect(element[mapData.value], element[mapData.label])
                  }
                >
                  {element[mapData.label]}
                </li>
              ))}
          </ul>
        )}
        {error && <div className={classes.errorText}>{error}</div>}
      </div>
    </>
  );
}
CustomSelectMaterialCoreClient.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isStepForm: PropTypes.bool,
  error: PropTypes.string,
};
CustomSelectMaterialCoreClient.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  onChange: () => null,
  disabled: false,
  error: '',
  isStepForm: false,
};

export default CustomSelectMaterialCoreClient;
