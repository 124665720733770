const textLabels = {
  body: {
    noMatch: 'Lo sentimos, no se encontraron registros.',
    toolTip: 'Ordenar',
  },
  pagination: {
    next: 'Siguiente Página',
    previous: 'Página anterior',
    rowsPerPage: 'Filas por Página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver Columnas',
    filterTable: 'Filtrar Tabla',
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'REINICAR',
  },
  viewColumns: {
    title: 'Mostrar Columnas',
    titleAria: 'Mostrar/Ocultar Columnas',
  },
  selectedRows: {
    text: 'Filas(s) seleccionadas',
    delete: 'Borrar',
    deleteAria: 'Borrar Fila Seleccionada',
  },
};

export { textLabels };
