import React, { useState, useEffect } from 'react';
// Material Resources
import { TableCell, Typography } from '@material-ui/core';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  getOptions,
  edgeToList,
  stringToDate,
} from '../../../../../utils/commonFunctions';
import Yup from '../../../../../utils/yup';
import { INPUT_VARIANT_SIMPLE_FORM } from '../../../../Forms/SimpleForm';
import { INPUT_VARIANT } from '../../../../Forms/StepsForm/fieldsSelector';
import {
  DELETE_ATTACHMENT_FUND,
  UPDATE_ATTACHMENT_FUND,
  CREATE_ATTACHMENT_FUND,
  GET_ATTACHMENT_FUND,
  LIST_ATTACHMENT_FUND,
} from './queries';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../../CustomOptions';

import CustomSubmodelList from '../../../../InnerModels/CustomSubmodel/CustomSubmodelList';
import LoadingIndicator from '../../../../LoadingIndicator';

// Styles & Images
import useStyles from '../styles';

export const FILE_TYPES = {
  REGLAMENTO: {
    id: 'R',
    name: 'Reglamento',
    form: 'REGLAMENTO',
  },
  RESUMEN: {
    id: 'R_1',
    name: 'Resumen',
    form: 'RESUMEN',
  },
  PPT: {
    id: 'P',
    name: 'Ppt',
    form: 'PPT',
  },
};

function FundAttachments() {
  const { id: fundID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { loading, error, data } = useQuery(LIST_ATTACHMENT_FUND, {
    variables: { id: fundID },
  });

  function formatAttachmentList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.options = {
          id: formatElement.id,
          state: formatElement.state,
        };
        return formatElement;
      });
    }
  }

  const mutationsData = {
    update: {
      query: UPDATE_ATTACHMENT_FUND,
      mutationName: 'updateFundDocument',
      modelName: 'fundDocument',
      message: 'Adjunto actualizado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_FUND, variables: { id: fundID } },
      ],
    },
    create: {
      query: CREATE_ATTACHMENT_FUND,
      mutationName: 'createFundDocument',
      modelName: 'fundDocument',
      message: 'Adjunto creado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_FUND, variables: { id: fundID } },
      ],
    },
    delete: {
      query: DELETE_ATTACHMENT_FUND,
      mutationName: 'deleteFundDocument',
      modelName: 'fundDocument',
      message: 'Adjunto eliminado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_FUND, variables: { id: fundID } },
      ],
    },
  };

  const [updateFundDocument] = useMutation(
    UPDATE_ATTACHMENT_FUND,
    getOptions({
      mutationName: 'updateFundDocument',
      modelName: 'fundDocument',
      message: 'Estado actualizado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_FUND, variables: { id: fundID } },
      ],
      enqueueSnackbar,
    })
  );

  function handleState(e, id, state) {
    updateFundDocument({
      variables: {
        id,
        input: {
          state: !state,
        },
      },
    });
  }

  const columns = [
    {
      name: 'file',
      label: 'Documento',
      options: {
        sort: false,
        customBodyRender: value => value.split('/').slice(-1)[0],
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'fileType',
      label: 'Tipo de adjunto',
      options: {
        sort: false,
        customBodyRender: value =>
          Object.values(FILE_TYPES).find(type => type.id === value).name,
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        sort: false,
        customBodyRender: value => stringToDate(value).toLocaleDateString(),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'date',
      label: 'Hora',
      options: {
        sort: false,
        customBodyRender: value => new Date(value).toLocaleTimeString(),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        sort: false,
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
        customBodyRender: value => {
          const state = value;
          return <p>{state ? 'Activo' : 'Inactivo'} </p>;
        },
      },
    },
    {
      name: 'options',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const { id, state } = value;
          return (
            <CustomOptions
              id={id}
              model="Adjunto"
              options={['oenable']}
              extraData={{ oenable: state }}
              extraFunctions={{ oenable: e => handleState(e, id, state) }}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const startValues = {
    fileType: '',
    file: '',
  };

  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    return errors;
  }

  const validationSchema = Yup.object().shape({
    fileType: Yup.string().required(),
  });
  const formStructure = [
    {
      label: 'Tipo de documento',
      name: 'fileType',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: Object.values(FILE_TYPES),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Documento',
      name: 'file',
      accept: 'application/pdf',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.file,
    },
  ];

  function formatBeforeSubmit(values) {
    const { date, ...newValues } = values;
    newValues.fileType = Object.values(FILE_TYPES).find(
      item => item.id === newValues.fileType
    ).form;
    if (newValues.file === null) {
      delete newValues.file;
    }
    newValues.fund = fundID;
    return newValues;
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <CustomSubmodelList
      submodelName="Adjunto"
      submodelList={formatAttachmentList(data.fund, 'funddocumentSet')}
      columns={columns}
      updateModelFunction={() => {}}
      // Form props
      canBeDeleted={false}
      getQuery={GET_ATTACHMENT_FUND}
      formatQuery={document => {
        const formatValue = { ...document.fundDocument };
        return formatValue;
      }}
      buttonLoader
      mutationsData={mutationsData}
      startValues={startValues}
      validationSchema={validationSchema}
      validationFunction={validationFunction}
      formStructure={formStructure}
      formatBeforeSubmit={formatBeforeSubmit}
    />
  );
}

FundAttachments.propTypes = {
  fundID: PropTypes.string.isRequired,
};

export default FundAttachments;
