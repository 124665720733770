import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: 'Este campo es requerido',
    notType: 'Valor incorrecto',
  },
  string: {
    email: 'Ingrese un email válido',
  },
});

export default Yup;
