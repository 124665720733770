import React from 'react';

export default function LogoEdifica() {
  return (
    <img
      data-src="https://cdnweb.edifica.com.pe/uploads/logonuevo-mesa-de-trabajo-1.svg"
      width="115"
      height="40"
      alt="Edifica Inmobiliaria"
      src="https://cdnweb.edifica.com.pe/uploads/logonuevo-mesa-de-trabajo-1.svg"
  
      />
  );
}
