import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    item: {
      textTransform: 'capitalize',
    },
    title: {
      fontWeight: 600,
      fontSize: 18,
      textTransform: 'uppercase',
    },
    gridRoot: {
      fontSize: 16,
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: 3,
        borderColor: `${theme.palette.primary.contrastText} !important`,
      },
    },
    formControl: {
      width: '100%',
      '& .MuiSvgIcon-root': {
        color: 'white',
        backgroundColor: theme.palette.primary.contrastText,
        top: 0,
        height: '99%',
        right: 0,
        width: 40,
        borderTopRightRadius: 18,
        borderBottomRightRadius: 18,
      },
      '& .MuiSelect-iconOpen': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 18,
        borderBottomLeftRadius: 18,
      },
    },
    textDisabled: {
      '& .Mui-disabled': {
        color: theme.palette.colors.text.secondary,
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: 3,
          borderColor: `${theme.palette.colors.darkGray} !important`,
        },
      },
    },
    textLabel: {
      fontWeight: 500,
    },
    errorMessage: {
      all: 'initial',
    },
  }),
);
