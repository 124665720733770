/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Components & Others


// Styles & Images
import useStyles from './styles';
import EmployeeProfile from './profile';
import EmployeeCards from './cards';

function EmployeeDashboardPage() {
  const classes = useStyles();

  return (
    <>

      <div className={classes.content}>
        <EmployeeCards />
      </div>
      <div className={classes.contentProfile}>
        <EmployeeProfile />
      </div>

    </>
  );
}

export default EmployeeDashboardPage;
