import React from 'react'

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      fill="none"
      viewBox="0 0 16 13"
    >
      <path
        fill="#F4FBFF"
        d="M16 1.625C16 .731 15.28 0 14.4 0H1.6C.72 0 0 .731 0 1.625v9.75C0 12.269.72 13 1.6 13h12.8c.88 0 1.6-.731 1.6-1.625v-9.75zm-1.6 0L8 5.688 1.6 1.625h12.8zm0 9.75H1.6V3.25L8 7.313l6.4-4.063v8.125z"
      ></path>
    </svg>
  );
}

export default PhoneIcon