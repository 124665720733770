import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { useSession } from 'src/providers/SessionProvider';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { gql } from 'apollo-boost';
import NumericFormLogin from './numericLogin';
import NormalFormLogin from './view';
import NewPasswordFormLogin from './setNewNumericPassword';
import SendRecoverMailPage from 'src/containers/SendRecoverMailPage';
// import useStyles from './styles';
function TestingFormLogin() {
  const [loginType, setLoginType] = useState(1);
  const handleChange = event => {
    setLoginType(event.target.value);
  };
  return (
    <>
      {loginType == 1 ? (
        <NormalFormLogin />
      ) : loginType == 2 ? (
        <NumericFormLogin />
      ) : loginType == 3 ? (
        <NewPasswordFormLogin />
      ) : loginType == 4 ? (
        <SendRecoverMailPage />
      ) : (
        <></>
      )}

      <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
        <select value={loginType} onChange={handleChange}>
          <option value={1}>Login Normal</option>
          <option value={2}>Login Numérico</option>
          <option value={3}>Login Cambio a Dígitos</option>
          <option value={4}>Recuperar Contraseña</option>
        </select>
      </div>
    </>
  );
}

export default TestingFormLogin;
