import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  resetPasswordPage: {
    display: 'flex',
    height: '100vh',
    padding: '5%',
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.main} 90%)`,
  },
  card: {
    border: '8px solid',
    borderRadius: 0,
    borderColor: theme.palette.secondary.main,
    background: theme.palette.primary,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 160,
    width: '75%',
  },
  backtoLogin: {
    width: '100%',
    display: 'flex',
    gap: '1vh',
    padding: '0.5vh',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#525659',
    fontWeight: '500',
    fontSize: '1.7vh',
    cursor: 'pointer',
  },
  btn: {
    width: '100%',
    marginBottom: 10,
    textTransform: 'capitalize',
    borderRadius: '8px',
    background: '#007DFA',
    height: '35px',
    '&:hover': {
      background: '#007DFA',
    },
    textAlign: 'center',
  },
  main: {
    position: 'relative',
    zIndex: 999,
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    padding: '10px',
    flexDirection: 'column',
    height: '100%',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  containerPassword: {
    width: 'fit-content',
    minWidth: ' 30%',
    height: 'fit-content',
    borderRadius: ' 10px',
    background: '#F4FBFF',
    boxShadow: ' 0px 4px 10px 0px rgba(8, 71, 143, 0.25)',
    justifyContent: ' space-around',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
  },
  text: {
    color: '#272F34',
    fontWeight: 700,
    fontSize: '1rem',
    textAlign: 'center',
  },
  mssgDiv: {
    border: '2px solid #82A9C9',
    background: '#D0DCE6',
    width: '100%',
    borderRadius: '5px',
    alignItems: 'center',
    textAlign: 'start',
    padding: '5px',
  },
  mssgText: {
    color: '#0E2639',
    fontSize: '0.80rem',
    fontWeight: 500,
    margin: 0,
  },
}));
