import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NotFound from '../../NotFoundPage';
import PaymentList from '../../../components/Models/Payment';
import PaymentForm from '../../../components/Models/Payment/PaymentForm';

function PaymentPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={PaymentList} />
      <Route path={`${path}/crear`} component={PaymentForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default PaymentPage;
