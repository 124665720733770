import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import BankAccountForm from '../BankAccountForm';
import { parseBool } from '../../../../utils/commonFunctions';
import { GET_BANK_ACCOUNT_CLIENT } from '../queries';

function BankDataBankAccountEdit(props) {
  const { id, clientID, afterEditCreate } = props;

  const history = useHistory();
  const { loading, error, data } = useQuery(GET_BANK_ACCOUNT_CLIENT, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('list bank account', error);
    history.goBack();
  }

  const { account, ...otherValues } = data.bankAccountClient;

  if (!data.bankAccountClient) {
    history.goBack();
  }

  const formatValue = { ...account, ...otherValues };
  formatValue.bank = formatValue.bank ? formatValue.bank.id : 'other';
  formatValue.currency = formatValue.currency.id;
  formatValue.accountType = formatValue.accountType.id;
  formatValue.country = formatValue.country ? formatValue.country.id : null;
  if (formatValue.availableForAllFunds) {
    formatValue.fund = 'availableForAllFunds';
  } else if (formatValue.fund) {
    formatValue.fund = formatValue.fund.id;
  } else {
    formatValue.fund = '';
  }
  delete formatValue.availableForAllFunds;

  formatValue.jointAccount = parseBool(formatValue.jointAccount);

  return (
    <BankAccountForm
      bankAccount={formatValue}
      id={id}
      isEdit
      afterEditCreate={afterEditCreate}
      clientID={clientID}
    />
  );
}

BankDataBankAccountEdit.propTypes = {
  id: PropTypes.string.isRequired,
  clientID: PropTypes.string.isRequired,
  afterEditCreate: PropTypes.func.isRequired,
};

export default BankDataBankAccountEdit;
