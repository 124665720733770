/**
 *
 * Validations
 *
 * All common validator for the forms are declared here
 *
 * "erros" is the object used by formik to display de errors
 */

import moment from 'moment';

// List all the required fields
export function requiredValidator(options, values, errors) {
  options.forEach(val => {
    // eslint-disable-next-line no-param-reassign
    if (!values[val] && values[val] === '') errors[val] = 'Requerido';
  });
}

// Check 11 digits for RUC field
export function rucValidator(ruc, errors) {
  if (ruc.length !== 11) {
    // eslint-disable-next-line no-param-reassign
    errors.ruc = 'El RUC debe tener 11 dígitos';
  }
}

// Check 0-100 for percentage
export function percentageValidator(percentage, name, errors) {
  if (percentage > 100 || percentage < 0) {
    // eslint-disable-next-line no-param-reassign
    errors[name] = 'Ingrese un porcentaje válido [0-100]';
  }
}

// Check the integer length
export function integerCountValidator(number, limit, name, errors) {
  if (Math.trunc(number).toString().length > limit) {
    // eslint-disable-next-line no-param-reassign
    errors[name] = `La parte entera no debe de exceder de ${limit} dígitos`;
  }
}

// Validator for Date Range Inputs
export function dateRangeValidator(startName, endName, values, errors) {
  if (
    moment(moment(values[startName]).format('YYYY-MM-DD')).isAfter(
      moment(values[endName]).format('YYYY-MM-DD')
    )
  ) {
    // eslint-disable-next-line no-param-reassign
    errors[startName] = 'La fecha debe ser anterior o igual a la fecha final';
  }
  if (
    moment(moment(values[endName]).format('YYYY-MM-DD')).isBefore(
      moment(values[startName]).format('YYYY-MM-DD')
    )
  ) {
    // eslint-disable-next-line no-param-reassign
    errors[endName] = 'La fecha debe ser posterior o igual a la fecha inicial';
  }
}
