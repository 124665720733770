import React from 'react'

function BackArrowIcon() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="14"
          fill="none"
          viewBox="0 0 18 14"
        >
          <path
            fill="#0D263B"
            d="M7.922.323a.994.994 0 01.298.706.973.973 0 01-.303.703L3.521 5.985l12.504.048c.27.001.53.107.72.294.191.188.298.441.297.705a.983.983 0 01-.302.702c-.192.186-.452.29-.723.29L3.514 7.974l4.363 4.289a.984.984 0 01.297.706.984.984 0 01-.302.703c-.193.186-.453.29-.725.29a1.036 1.036 0 01-.721-.295L.324 7.674a.994.994 0 01-.297-.706.973.973 0 01.302-.704L6.476.317a1.023 1.023 0 01.725-.29 1.044 1.044 0 01.721.296z"
          ></path>
        </svg>
      );
}

export default BackArrowIcon