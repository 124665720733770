import React, { useEffect, useState } from 'react';

// Material Resources
import { CircularProgress, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

// Others
import PropTypes from 'prop-types';

// Styles & Images
import useStyles from './styles';
import CustomModal from '../CustomModal';

function LoadingWithMessage(props) {
  const { onClose, visible, title, loading, result } = props;

  const classes = useStyles();

  return (
    <CustomModal
      fullWidth
      showModal={visible}
      title={title}
      maxWidth="xs"
      onClosePress={onClose}
      hideScroll
      hideClose
    >
      <div className={classes.container}>
        <h3>La operación esta siendo procesada, espere un momento.</h3>
        {loading ? (
          <CircularProgress
            style={{ display: 'block', margin: 'auto' }}
            size={50}
          />
        ) : (
          <>
            {result ? (
              <CheckCircleOutlineIcon
                fontSize="large"
                className={classes.succes}
              />
            ) : (
              <ErrorOutlineIcon fontSize="large" className={classes.error} />
            )}
            {result ? (
              <h4 className={classes.succes}>Operación realizada con éxito</h4>
            ) : (
              <h4 className={classes.error}>
                Ocurrió un error, inténtelo más tarde
              </h4>
            )}
            <Button variant="contained" color="primary" onClick={onClose}>
              Ok
            </Button>
          </>
        )}
      </div>
    </CustomModal>
  );
}

LoadingWithMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  result: PropTypes.bool.isRequired,
};

export default LoadingWithMessage;
