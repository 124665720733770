import React from 'react';
import useStyles from './styles';
import NewCard from '../type-cards/news';
import SimpleNoteCard from '../type-cards/simple-note';

export default function NewsModuleCards() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.newsDiv}>
        <NewCard />
      </div>
      <div className={classes.simpleNoteDiv}>
        <SimpleNoteCard />
      </div>
    </div>
  );
}
