import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import {
  GET_ASSIGNEE_PERSONAL_DATA,
  UPDATE_CLIENT_PERSONAL_DATA,
} from '@queries/Client';
import StepsForm from '../../../Forms/StepsForm';
import {
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import Yup from '../../../../utils/yup';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  defaultBeforeSubmitFormat,
  defaultFormatData,
  getValidationSchema,
  PERSONAL_DATA_DEFAULT,
  PERSONAL_FORM_DATA,
} from '../personalData';

const schema = getValidationSchema();
const validationSchema = Yup.object().shape({ ...schema });

function PersonalDataAttorney(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [startValues, setStartValues] = useState({ ...PERSONAL_DATA_DEFAULT });
  const [assigneeID, setAssigneeID] = useState();

  const { loading, error: assigneeError, data: assigneeData } = useQuery(
    GET_ASSIGNEE_PERSONAL_DATA,
    {
      variables: { id: clientID },
    },
  );
  const updateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó datos personales de apoderado.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
    refetchQueries: [
      { query: GET_ASSIGNEE_PERSONAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó datos personales de apoderado parcialmente.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
    refetchQueries: [
      { query: GET_ASSIGNEE_PERSONAL_DATA, variables: { id: clientID } },
    ],
  });

  useEffect(() => {
    if (assigneeError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar,
      );
    }
    if (assigneeData) {
      const { assignee } = assigneeData.client;
      if (assignee) {
        setAssigneeID(assignee.id);
        setStartValues(defaultFormatData(assignee));
      }
    }
  }, [assigneeData, loading]);

  function validationFunction() {
    const errors = {};
    return errors;
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <StepsForm
        initialValues={startValues}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        id={assigneeID}
        mainClientID={clientID}
        nextForm={MENU_ITEMS_ID.AttorneyLegalData}
        data={PERSONAL_FORM_DATA()}
        title="Datos del apoderado o mandatorio / Datos personales"
        updateMutation={updateClient}
        partialUpdateMutation={partialUpdateClient}
        formatBeforeSubmit={defaultBeforeSubmitFormat}
      />
    </>
  );
}

PersonalDataAttorney.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default PersonalDataAttorney;
