import React from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_BANKS, DELETE_BANK } from '../queries';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';

// Styles & Images
import useStyles from './styles';

function CustomToolbar() {
  const history = useHistory();
  const handleClick = () => {
    history.push('/administrador/bancos/crear');
  };

  return (
    <Tooltip title="Nuevo banco">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function BankList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [deleteBank] = useMutation(
    DELETE_BANK,
    getOptions({
      mutationName: 'deleteBank',
      message: 'Banco eliminado con éxito.',
      enqueueSnackbar,
    })
  );
  const { loading, error, data } = useQuery(LIST_BANKS);

  if (error) {
    console.error('list banks', error);
  }

  const columns = [
    {
      name: 'name',
      label: 'Nombre',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Banco"
            options={['oedit', 'odelete']}
            handleDelete={() => {
              deleteBank({ variables: { id } });
              data.listBank.edges = data.listBank.edges.filter(
                e => e.node.id !== id
              );
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={4} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `/administrador/bancos/${rowData[rowData.length - 1].props.id}`
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <MUIDataTable
          title="banco"
          data={edgeToList(data, 'listBank')}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}

export default BankList;
