import React, { useState, useEffect } from 'react';
import { Grid, Button, Typography, FormHelperText } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import SectionDivider from '../../FormComponents/SectionDivider';
import PlacesSelects from '../../FormComponents/PlacesSelects';
import CustomButton from '../../FormComponents/CustomButton';
import DeleteModal from '../../DeleteModal';
import useStyles from './styles';
import FieldSelect from '../StepsForm/fieldsSelector';
import CustomCard from '../../CustomCard';

export const INPUT_VARIANT_SIMPLE_FORM = {
  text: 'TextField',
  number: 'InputNumber',
  formatNumber: 'InputFormatNumber',
  select: 'CustomSelect',
  radio: 'RadioButtons',
  country: 'CountrySelect',
  node: 'CustomNode',
  inputBox: 'InputBox',
  image: 'Image',
};

function SimpleForm(props) {
  const {
    initialValues,
    validateFunction,
    validateSchema,
    formatBeforeSubmit,
    data,
    model,
    id,
    secondModelID,
    modelName,
    routeName,
    isEdit,
    hasCancel,
    onCancel,
    canBeDeleted,
    hasCountryComponent,
    updateMutation,
    createMutation,
    deleteMutation,
    afterCloseEvents,
    buttonLoader,
    customOnChange,
    customOnChangeFields,
    isStepForm,
    extaNode,
    customFieldSearchMulti,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [
    updateModel,
    { data: updateData, loading: updateLoading },
  ] = updateMutation;
  const [createModel, { loading: createLoading }] = createMutation;

  // DeleteMutation is disables when canBeDeleted is true
  const [deleteModel, { loading: deleteLoading }] = deleteMutation;

  const [id0 = id, id1 = id] = secondModelID;

  useEffect(() => {
    setFormTouched(false);
  }, [initialValues, model]);

  useEffect(() => {
    if (updateData && routeName) {
      history.push(`${routeName}`);
    }
  }, [updateData, routeName, history]);

  useEffect(() => {
    if (!updateLoading && !createLoading && !deleteLoading) {
      setLoading(false);
    }
  }, [updateLoading, createLoading, deleteLoading]);

  function handleDelete(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  function handleModalClose() {
    setOpenModal(false);
  }
  function handleModalOK() {
    deleteModel({
      variables: { id: id1 || id },
    });
    setOpenModal(false);
    afterCloseEvents();
  }

  return (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={model || initialValues}
        validate={validateFunction}
        validationSchema={validateSchema}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          setFormTouched(true);
          // eslint-disable-next-line prefer-const
          let { id: modelID, __typename, ...formatValues } = values;

          if (customFieldSearchMulti) {
            if (formatValues.classes && formatValues.classes.length > 0) {
              const fundClassParser = formatValues.classes.map(item => item.id);
              formatValues.classes = fundClassParser;
            }
          } else {
            delete formatValues.classes;
          }

          if ('county' in formatValues && 'id' in formatValues['county']) {
            formatValues['county'] = formatValues['county']['id'];
          }
          formatValues = formatBeforeSubmit(formatValues);

          let parserTrim = {};
          Object.entries(formatValues).forEach(([key, value]) => {
            if (typeof value === 'string') {
              parserTrim[key] = value.trim();
            } else {
              parserTrim[key] = value;
            }
          });

          if (isEdit) {
            updateModel({
              variables: {
                id: id0 || id,
                input: parserTrim,
              },
            });
          } else {
            createModel({ variables: { input: parserTrim } });
          }
          setSubmitting(false);
          afterCloseEvents();
        }}
        validateOnBlur={formTouched}
        validateOnChange={formTouched}
      >
        {({ submitForm, setFieldValue, errors, values }) => (
          <Form className={classes.simpleForm}>
            <Grid container>
              <Grid container alignItems="center">
                <Grid item xs={12}>
                  {isEdit ? (
                    <Typography variant="h6" className={classes.title}>
                      {`Editar ${modelName}`}
                    </Typography>
                  ) : (
                    <Typography variant="h6" className={classes.title}>
                      {`Crear ${modelName}`}
                    </Typography>
                  )}
                </Grid>
                <SectionDivider />
                {data?.map(element => {
                  let { isField } = element;
                  if (isField === undefined) {
                    isField = true;
                  }
                  return isField ? (
                    <FieldSelect
                      key={element.name}
                      field={element}
                      errors={errors}
                      model={model || initialValues}
                      initialValues={initialValues}
                      setFieldValue={setFieldValue}
                      customOnChange={customOnChange}
                      customOnChangeFields={customOnChangeFields}
                      isStepForm={isStepForm}
                    />
                  ) : (
                    <CustomCard
                      subtitle={element.section}
                      content={
                        <Grid container>
                          {element.fields.map(field => (
                            <FieldSelect
                              key={field.name}
                              field={field}
                              errors={errors}
                              model={model || initialValues}
                              initialValues={initialValues}
                              setFieldValue={setFieldValue}
                              isStepForm={isStepForm}
                            />
                          ))}
                        </Grid>
                      }
                    />
                  );
                })}
              </Grid>
              {hasCountryComponent && (
                <PlacesSelects
                  country={model ? model.country : initialValues.country}
                  county={model ? model.county : initialValues.county}
                  city={model ? model.city : initialValues.city}
                  district={model ? model.district : initialValues.district}
                  errors={{
                    country: errors.country,
                    county: errors.county,
                    city: errors.city,
                    district: errors.district,
                  }}
                  setFieldValue={setFieldValue}
                />
              )}
              <SectionDivider />
              <div className={classes.extraNodeContainer}>{extaNode}</div>
              {isStepForm && (
                <Grid container justify="flex-start" alignItems="center">
                  <FormHelperText
                    className={classes.formText}
                    error={Object.keys(errors).length !== 0}
                  >
                    Los campos marcados con * son obligatorios.
                  </FormHelperText>
                </Grid>
              )}
              {isEdit ? (
                <Grid container justify="flex-end" alignItems="center">
                  {hasCancel && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={onCancel}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  )}
                  {canBeDeleted && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={handleDelete}
                      >
                        Eliminar
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <CustomButton
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      loading={(loading && buttonLoader) || updateLoading}
                      onClick={() => {
                        setFormTouched(true);
                        submitForm();
                      }}
                    >
                      Guardar
                    </CustomButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify="flex-end" alignItems="center">
                  {hasCancel && (
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={onCancel}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <CustomButton
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      loading={(loading && buttonLoader) || createLoading}
                      onClick={() => {
                        setFormTouched(true);
                        submitForm();
                      }}
                    >
                      Crear
                    </CustomButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      <DeleteModal
        model={modelName}
        open={openModal}
        handleClose={handleModalClose}
        handleOK={handleModalOK}
      />
    </div>
  );
}

SimpleForm.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  validateFunction: PropTypes.func.isRequired,
  validateSchema: PropTypes.shape().isRequired,
  formatBeforeSubmit: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  model: PropTypes.shape(),
  id: PropTypes.string,
  secondModelID: PropTypes.arrayOf(PropTypes.string),
  modelName: PropTypes.string.isRequired,
  routeName: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  hasCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  canBeDeleted: PropTypes.bool,
  hasCountryComponent: PropTypes.bool,
  afterCloseEvents: PropTypes.func,
  updateMutation: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  createMutation: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deleteMutation: PropTypes.arrayOf(PropTypes.shape()),
  buttonLoader: PropTypes.bool,
  customFieldSearchMulti: PropTypes.bool,
  customOnChange: PropTypes.func,
  customOnChangeFields: PropTypes.array,
  isStepForm: PropTypes.bool,
  extaNode: PropTypes.node,
};

SimpleForm.defaultProps = {
  model: null,
  customFieldSearchMulti: false,
  id: '',
  secondModelID: ['', ''],
  isEdit: false,
  buttonLoader: false,
  hasCancel: false,
  canBeDeleted: true,
  hasCountryComponent: false,
  afterCloseEvents: () => {},
  onCancel: () => false,
  formatBeforeSubmit: values => values,
  deleteMutation: [() => {}, { loading: false }],
  isStepForm: false,
  extaNode: <></>,
};

export default SimpleForm;
