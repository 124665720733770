import React, { useEffect } from 'react';
import cx from 'clsx';
// Material Resources
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { useSelector } from 'react-redux';
// Others
import PropTypes from 'prop-types';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';

// Styles & Images
import useStyles from './styles';
import getClientMode from '../../store/utils-store/selectors';

function CustomCard(props) {
  const classes = useStyles();
  const isModeDark = useSelector(getClientMode());
  const { content, title, subtitle, disableFlex, children, showHeader } = props;
  const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  const cardHeaderShadowStyles = useFadedShadowStyles();
  const cardStyle = isModeDark ? classes.cardDark : classes.card;

  return (
    <Card className={cx(cardStyle, cardShadowStyles.root)}>
      {showHeader || title ? (
        <CardHeader
          className={cx(classes.cardHeaderRoot, cardHeaderShadowStyles.root)}
          classes={{
            root: cardHeaderStyles.root,
            title: classes.title,
            content: classes.subtitle,
          }}
          title={title}
          subheader={subtitle}
        />
      ) : null}
      <CardContent
        className={disableFlex ? classes.content : classes.flexContent}
      >
        {content || children}
      </CardContent>
    </Card>
  );
}

CustomCard.propTypes = {
  content: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  disableFlex: PropTypes.bool,
  showHeader: PropTypes.bool,
};

CustomCard.defaultProps = {
  content: null,
  children: null,
  disableFlex: false,
  title: '',
  subtitle: '',
  showHeader: true,
};

export default CustomCard;
