import { gql } from 'apollo-boost';

export const GET_CLIENT_PDF = gql`
  query PDFAsBase64($id: ID!) {
    pdfClient(id: $id) {
      pdf
      filename
    }
  }
`;

export const GET_PREFERENTIAL_SUBSCRIPTION_CERTIFICATE = gql`
  query getPSC($clientId: ID!, $raisingId: ID!) {
    preferentialSubscriptionCertificate(
      clientId: $clientId
      raisingId: $raisingId
    ) {
      pdf
      filename
    }
  }
`;

export const GET_CLIENT_PARTICIPANT_DATA = gql`
  query getClientParticipantData($id: ID!) {
    client(id: $id) {
      id
      participantSet {
        edges {
          node {
            wheel {
              raising {
                id
                fundType
                number
                acronym
                fund {
                  id
                  businessName
                  fundType
                  funddocumentSet {
                    edges {
                      node {
                        fileType
                        file
                        state
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_PARTICIPATION = gql`
  query getClientParticipation($clientId: ID!) {
    listParticipant(client: $clientId) {
      edges {
        node {
          id
          state
          wheel {
            raising {
              id
              fundType
              number
              acronym
              fund {
                id
                businessName
                funddocumentSet {
                  edges {
                    node {
                      fileType
                      file
                      state
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_SECONDARY_MARKET = gql`
  query getClientSecondaryMarket($clientId: ID!) {
    listSecondaryMarkets(clientId: $clientId) {
      edges {
        node {
          id
          state
          seller {
            edges {
              node {
                id
              }
            }
          }
          buyer {
            edges {
              node {
                client {
                  id
                }
                representant
              }
            }
          }
          fund {
            id
            businessName
            funddocumentSet {
              edges {
                node {
                  fileType
                  file
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTRACT = gql`
  query getContractPdf($participantId: ID!) {
    contractPdf(participantId: $participantId) {
      pdf
      filename
    }
  }
`;

export const GET_PARTICIPATION_CERTIFICATE = gql`
  query getParticipationCertificate($participantId: ID!) {
    participationCertificatePdf(participantId: $participantId) {
      pdf
      filename
    }
  }
`;

export const GET_SWORN_DECLARATION = gql`
  query getSwornDeclarationPdf($fundId: ID!, $clientId: ID!) {
    swornDeclarationPdf(fundId: $fundId, clientId: $clientId) {
      pdf
      filename
    }
  }
`;

export const GET_SM_CONTRACT = gql`
  query getSecondarymarketContractPdf($secondaryMarketId: ID!, $clientId: ID!) {
    secondaryMarketContractPdf(
      secondaryMarketId: $secondaryMarketId
      clientId: $clientId
    ) {
      pdf
      filename
    }
  }
`;

export const GET_SM_PARTICIPATION_CERTIFICATE = gql`
  query getParticipationCertificate($secondaryMarketId: ID!) {
    secondaryMarketParticipationCertificatePdf(
      secondaryMarketId: $secondaryMarketId
    ) {
      pdf
      filename
    }
  }
`;

export const GET_DOCUMENT_IMAGE_LINKS = gql`
  query client($clientId: ID!) {
    client(id: $clientId) {
      id
      photoDocumentsUrl
    }
  }
`;
