import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_PROPERTY_REGIME,
  CREATE_PROPERTY_REGIME,
  DELETE_PROPERTY_REGIME,
  LIST_PROPERTY_REGIME,
} from '../queries';
import {
  validationFunctionPropertyRegime,
  validationSchemaPropertyRegime,
} from './validation';
import { FORM_OPTIONS } from '../../../StepsComponents/constants';
import { parseEquivalent, getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
  society: 'false',
};

function PropertyRegimeForm(props) {
  const { propertyRegime, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updatePropertyRegime = useMutation(
    UPDATE_PROPERTY_REGIME,
    getOptions({
      mutationName: 'updatePropertyRegime',
      modelName: 'propertyRegime',
      message: 'Régimen patrimonial actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createPropertyRegime = useMutation(
    CREATE_PROPERTY_REGIME,
    getOptions({
      mutationName: 'createPropertyRegime',
      modelName: 'propertyRegime',
      message: 'Régimen patrimonial creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createPropertyRegimeData }) {
        if (cache.data.data.ROOT_QUERY.listPropertyRegimes) {
          const { listPropertyRegimes } = cache.readQuery({
            query: LIST_PROPERTY_REGIME,
          });
          listPropertyRegimes.edges.unshift({
            // Be careful with types
            __typename: 'PropertyRegimeTypeEdge',
            node: createPropertyRegimeData.createPropertyRegime.propertyRegime,
          });
          cache.writeQuery({
            query: LIST_PROPERTY_REGIME,
            data: { listPropertyRegimes },
          });
        }
        history.push('/administrador/regimenes-patrimoniales');
      },
    })
  );
  const deletePropertyRegime = useMutation(
    DELETE_PROPERTY_REGIME,
    getOptions({
      mutationName: 'deletePropertyRegime',
      modelName: 'propertyRegime',
      message: 'Régimen patrimonial eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listPropertyRegimes) {
          const { listPropertyRegimes } = cache.readQuery({
            query: LIST_PROPERTY_REGIME,
          });
          listPropertyRegimes.edges = listPropertyRegimes.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_PROPERTY_REGIME,
            data: { listPropertyRegimes },
          });
        }
        history.push('/administrador/regimenes-patrimoniales');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Permite cónyuge?',
      name: 'society',
      gridMD: 3,
      data: FORM_OPTIONS.yesNo,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.radio,
    },
  ];

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.society = parseEquivalent(newValues.society);
    return newValues;
  }
  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionPropertyRegime}
        validateSchema={validationSchemaPropertyRegime}
        data={data}
        model={propertyRegime}
        id={id}
        modelName="Categorías"
        routeName="/administrador/regimenes-patrimoniales"
        isEdit={isEdit}
        formatBeforeSubmit={formatBeforeSubmit}
        updateMutation={updatePropertyRegime}
        createMutation={createPropertyRegime}
        deleteMutation={deletePropertyRegime}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

PropertyRegimeForm.propTypes = {
  propertyRegime: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

PropertyRegimeForm.defaultProps = {
  propertyRegime: null,
  id: '',
  isEdit: false,
};

export default PropertyRegimeForm;
