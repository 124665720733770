import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingLeft: 10,
  },
  columnContainer: {
    padding: 10,
  },
  editorContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 5,
    padding: 10,
    minHeight: 300,
    marginBottom: 20,
  },
  tableTitle: {
    fontWeight: 500,
  },
  infoContainer: {
    '& #mui-rte-toolbar': {
      border: 'none',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
  raisingFees: {
    marginTop: theme.spacing(6),
  },
}));
