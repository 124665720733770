import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import {
  GET_SPOUSE_PERSONAL_DATA,
  GET_SPOUSE_DATA_NOT_VINCULATED,
  UPDATE_CLIENT_PERSONAL_DATA,
  LIST_COUNTRIES,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { FORM_OPTIONS } from '../../constants';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  errorMessage,
  parseEquivalent,
  successMessage,
} from '../../../../utils/commonFunctions';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import { PERSONAL_DATA_DEFAULT } from '../personalData';

function PersonalDataSpouse(props) {
  const { isSociety, clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [startValues, setStartValues] = useState({ ...PERSONAL_DATA_DEFAULT });
  const [spouseID, setSpouseID] = useState();

  const { loading, error: spouseDataError, data: spouseData } = useQuery(
    GET_SPOUSE_PERSONAL_DATA,
    {
      variables: { id: clientID },
    },
  );

  const updateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó datos personales de conyuge.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
    refetchQueries: [
      { query: GET_SPOUSE_PERSONAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó datos personales de conyuge parcialmente.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
    refetchQueries: [
      { query: GET_SPOUSE_PERSONAL_DATA, variables: { id: clientID } },
    ],
  });

  const {
    loading: countryLoading,
    data: countryData,
    error: countryError,
  } = useQuery(LIST_COUNTRIES);

  const isPeru = id => {
    if (
      countryData &&
      countryData.listCountries &&
      countryData.listCountries.edges
    ) {
      return !!countryData.listCountries.edges.find(val => {
        return val.node && val.node.id === id && val.node.code === 'PE';
      });
    }
    return false;
  };

  useEffect(() => {
    if (spouseDataError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar,
      );
    }
    if (spouseData) {
      const { spouse } = spouseData.client;
      if (spouse) {
        setSpouseID(spouse.id);
        const {
          user: userSpouse,
          address,
          birthplace,
          ...currentClient
        } = spouse;
        const currentData = { ...PERSONAL_DATA_DEFAULT };
        if (userSpouse) {
          currentData.firstName = userSpouse.firstName;
          currentData.lastName = userSpouse.lastName;
          currentData.maternalSurname = userSpouse.maternalSurname;
          currentData.email = userSpouse.isEmailAutogenerated
            ? ''
            : userSpouse.email;
        }
        if (birthplace) {
          currentData.birthplaceCountry = birthplace.country
            ? birthplace.country.id
            : '';
          currentData.birthplaceCounty = birthplace.county
            ? { id: birthplace.county.id, name: birthplace.county.name }
            : null;
        }

        if (address) {
          currentData.country = address.country ? address.country.id : '';
          currentData.county = address.county
            ? { id: address.county.id, name: address.county.name }
            : null;
          if (address.country && isPeru(address.country.id)) {
            currentData.city = address.city ? address.city.id : '';
            currentData.district = address.district ? address.district.id : '';
          } else {
            currentData.city = '';
            currentData.district = '';
          }
          currentData.detail = address.detail;
        }
        setStartValues({ ...currentData, ...currentClient });
      }
    }
  }, [spouseData, loading, spouseDataError]);

  function validationFunction() {
    const errors = {};
    return errors;
  }
  const formatData = data => {
    const {
      country,
      county,
      city,
      district,
      detail,
      birthplaceCountry,
      birthplaceCounty,
      ...formattedData
    } = data;
    if (country && county) {
      const address = {
        country,
        county,
        detail,
      };
      if (city && district && county) {
        address.county = county.id;
        address.city = city;
        address.district = district;
      }
      formattedData.address = address;
    }
    if (birthplaceCountry && birthplaceCounty) {
      const birthplace = {
        country: birthplaceCountry,
        county: birthplaceCounty.id,
      };
      formattedData.birthplace = birthplace;
    }
    formattedData.sex = parseEquivalent(formattedData.sex);

    return formattedData;
  };

  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(),
    maternalSurname: Yup.string().required(),
    firstName: Yup.string().required(),
    sex: Yup.string().required(),
    birthday: Yup.string().required(),
    birthplaceCountry: Yup.string().required(),
    birthplaceCounty: Yup.string().required(),
    country: Yup.string().when({
      is: () => isSociety,
      then: Yup.string().required(),
    }),
    county: Yup.string().when({
      is: () => isSociety,
      then: Yup.string().required(),
    }),
    detail: Yup.string().when({
      is: () => isSociety,
      then: Yup.string().required(),
    }),
    phone: Yup.string(),
    cellphone: Yup.string().when({
      is: () => isSociety,
      then: Yup.string().required(),
    }),
    email: Yup.string()
      .email()
      .when({
        is: () => isSociety,
        then: Yup.string().required(),
      }),
  });

  let data = [
    {
      isField: true,
      label: '* Apellido Paterno',
      name: 'lastName',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Apellido Materno',
      name: 'maternalSurname',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Nombres',
      name: 'firstName',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Sexo',
      name: 'sex',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.select,
      data: FORM_OPTIONS.sex,
    },
    {
      isField: true,
      label: '* Fecha de Nacimiento',
      name: 'birthday',
      dateT: 'year',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.date,
    },
    {
      isField: false,
      section: 'Lugar de nacimiento',
      fields: [
        {
          label: '* Places',
          name: 'places',
          inputVariant: INPUT_VARIANT.places,
          gridMD: [12, 12, 12, 12],
          compact: true,
          indicators: {
            country: { name: 'birthplaceCountry', label: '* País' },
            county: { name: 'birthplaceCounty', label: '* Ciudad' },
          },
        },
      ],
    },
  ];

  if (isSociety) {
    data = data.concat([
      {
        isField: false,
        section: 'Domicilio',
        fields: [
          {
            label: '* Places',
            name: 'places',
            inputVariant: INPUT_VARIANT.places,
            gridMD: [12, 12, 12, 12],
            indicators: {
              country: { name: 'country', label: '* País' },
              county: { name: 'county', label: '* Region' },
              city: { name: 'city', label: '* Provincia' },
              district: { name: 'district', label: '* Distrito' },
            },
          },
          {
            label: '* Avenida, Jirón, Calle ...',
            name: 'detail',
            gridMD: 12,
            inputVariant: INPUT_VARIANT.text,
          },
        ],
      },
      {
        isField: false,
        section: 'Teléfonos',
        fields: [
          {
            label: 'Casa',
            name: 'phone',
            gridMD: 12,
            inputVariant: INPUT_VARIANT.text,
          },
          {
            label: '* Celular',
            name: 'cellphone',
            gridMD: 12,
            inputVariant: INPUT_VARIANT.text,
          },
        ],
      },
      {
        isField: true,
        label: '* Email',
        name: 'email',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
    ]);
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <StepsForm
        initialValues={startValues}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        data={data}
        title="Datos del cónyuge o apoderado / Datos personales"
        nextForm={MENU_ITEMS_ID.SpousePartnerLegalData}
        updateMutation={updateClient}
        partialUpdateMutation={partialUpdateClient}
        formatBeforeSubmit={formatData}
        id={spouseID}
        mainClientID={clientID}
      />
    </>
  );
}

PersonalDataSpouse.propTypes = {
  isSociety: PropTypes.bool,
  clientID: PropTypes.string.isRequired,
};

PersonalDataSpouse.defaultProps = {
  isSociety: false,
};

export default PersonalDataSpouse;
