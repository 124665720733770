import React, { useState, useEffect } from 'react';

// Material Resources
import { Typography, TableCell } from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Others
import PropTypes from 'prop-types';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Styles & Images
import useStyles from './styles';

function CustomColumnHeader(props) {
  const { id, columnMeta, updateDirection, sort } = props;
  const classes = useStyles();

  return (
    <TableCell
      key={id}
      onClick={() => sort && updateDirection(id)}
      variant="head"
      className={classes.customColumnHeader}
    >
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.tableTitle}
      >
        {columnMeta.label}
        {sort &&
          {
            none: <UnfoldMoreIcon />,
            asc: <KeyboardArrowUpIcon />,
            desc: <KeyboardArrowDownIcon />,
          }[columnMeta.sortDirection]}
      </Typography>
    </TableCell>
  );
}

CustomColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  columnMeta: PropTypes.shape().isRequired,
  updateDirection: PropTypes.func.isRequired,
  sort: PropTypes.bool,
};

export default CustomColumnHeader;
