import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import { useFormikContext, ErrorMessage } from 'formik';

import {
  Tooltip,
  Button,
  Container,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomCard from '../../../../CustomCard';
import CustomModal from '../../../../CustomModal';

import { textLabels } from '../../../../../translations/components/MUIDataTable';
import useStyles from '../../styles';
import { generateColumn } from 'src/utils/commonFunctions';
import StepTwoForm from 'src/components/Cognito/StepTwoForm';
import AddParticpant from '../AddParticpant';
import CustomOptions from 'src/components/CustomOptions';
import { Alert } from '@material-ui/lab';

function SecondaryMarketCoParticipantList(props) {
  const { data, readOnly } = props;
  const styles = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [participantsList, setParticipantsList] = useState(data);
  const {
    values: formValues,
    errors: formErrors,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    setFieldValue('participants', participantsList);
  }, [participantsList]);

  const addParticipant = () => {
    setCurrentParticipant(null);
    setFieldValue('documentNumber', null);
    setShowModal(true);
  };

  const setParticipantInList = participantData => {
    const exists = participantsList.find(
      p => p.clientID === participantData.clientID
    );
    if (exists || currentParticipant) {
      const index = participantsList.findIndex(
        v => v.clientID === participantData.clientID
      );
      participantsList.splice(index, 1, participantData);
      setParticipantsList([...participantsList]);
    } else {
      setParticipantsList([...participantsList, participantData]);
    }
    setShowModal(false);
  };

  const handleDeleteRow = id => {
    const exists = participantsList.find(p => p.clientID === id);
    if (exists || currentParticipant) {
      const index = participantsList.findIndex(v => v.clientID === id);
      participantsList.splice(index, 1);
      setParticipantsList([...participantsList]);
    }
  };

  const columns = [
    generateColumn('Nombre', 'clientName'),
    generateColumn('Porcentaje de participación', 'participationPercentage', {
      sort: false,
      filter: false,
      customBodyRender: value => `${value} %`,
    }),
    generateColumn('Representante', 'isRepresentative', {
      sort: true,
      filter: false,
      customBodyRender: value => (
        <CheckCircleIcon color={value ? 'secondary' : 'disabled'} />
      ),
    }),
    generateColumn('Opciones', 'clientID', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Participante"
            options={['odelete']}
            handleDelete={() => {
              handleDeleteRow(id);
            }}
          />
        );
      },
    }),
  ];

  const options = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Agregar copartícipe">
        <Button variant="contained" color="secondary" onClick={addParticipant}>
          Agregar copartícipe
        </Button>
      </Tooltip>
    ),
    onRowClick: rowData => {
      const [participant = {}] = participantsList.filter(
        v => v.clientName === rowData[0]
      );
      Object.keys(participant).map(k => setFieldValue(k, participant[k]));
      setCurrentParticipant(participant);
      setShowModal(true);
    },
  };

  return (
    <>
      <CustomCard title="Compradores" disableFlex>
        <MUIDataTable
          data={formValues.participants}
          columns={columns}
          options={options}
        />

        {formErrors.participants && (
          <Alert severity="error">{formErrors.participants}</Alert>
        )}
      </CustomCard>
      <CustomModal
        fullWidth
        maxWidth="lg"
        showModal={showModal}
        title="Participante"
        onClosePress={() => setShowModal(false)}
        disableFlex
      >
        <Container className={styles.container}>
          <AddParticpant
            setParticipantInList={setParticipantInList}
            closeForm={() => setShowModal(false)}
            readOnly={readOnly}
          />
        </Container>
      </CustomModal>
    </>
  );
}

SecondaryMarketCoParticipantList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  readOnly: PropTypes.bool,
};

SecondaryMarketCoParticipantList.defaultProps = {
  readOnly: false,
};

export default SecondaryMarketCoParticipantList;
