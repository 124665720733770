/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import DocumentTypeList from '../../../components/Models/DocumentType/DocumentTypeList';
import DocumentTypeEdit from '../../../components/Models/DocumentType/DocumentTypeCreateEdit/edit';
import DocumentTypeForm from '../../../components/Models/DocumentType/DocumentTypeForm';

function DocumentTypePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={DocumentTypeList} />
      <Route path={`${path}/crear`} component={DocumentTypeForm} />
      <Route path={`${path}/:id`} component={DocumentTypeEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default DocumentTypePage;
