import { makeStyles } from '@material-ui/core/styles';
import blueLogo from '../../images/brand/LogoFinal_Positivo_Azul.png';

const drawerWidth = 260;

export default makeStyles(theme => ({
  adminPage: {
    backgroundColor: theme.palette.colors.withe,
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
    },
  },
  welcome: {
    fontWeight: 500,
  },
  userIcon: {
    border: 2,
    borderStyle: 'solid',
    color: theme.palette.primary.contrastText,
  },
  icon: { minWidth: theme.spacing(5) },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
    },
  },
  appToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarItem: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaperBigScreen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.colors.gray,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.gray,
  },
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    marginBottom: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: '100vh',
    backgroundColor: theme.palette.colors.white,
    borderLeft: '2px solid',
    borderRight: '2px solid',
    borderLeftColor: theme.palette.colors.darkGray,
    borderRightColor: theme.palette.colors.darkGray,
  },
}));
