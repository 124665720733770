import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  optionButtons: {
    margin: 0,
    minHeight: 30,
    minWidth: 30,
    padding: 0,
    '& .MuiIconButton-root': {
      margin: 0,
      minHeight: 30,
      minWidth: 30,
      padding: 0,
    },
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    width: 2,
    height: 36,
    marginRight: 10,
    display: 'inline-flex',
    backgroundColor: theme.palette.secondary.main,
  },
  yellow: {
    backgroundColor: theme.palette.colors.yellow,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.yellow,
    },
  },
  gray: {
    backgroundColor: theme.palette.colors.gray,
    color: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.gray,
    },
  },
  green: {
    backgroundColor: theme.palette.colors.green,
    color: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.green,
    },
  },
  blue: {
    backgroundColor: theme.palette.colors.blue,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.blue,
    },
  },
  orange: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.colors.white,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.secondary,
    },
  },
  singleOption: {
    display: 'flex',
  },
  // Icons
  igray: {
    color: theme.palette.colors.gray,
  },
  igreen: {
    color: theme.palette.colors.green,
  },
  iorange: {
    color: theme.palette.secondary.main,
  },
}));
