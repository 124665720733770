import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  gray: {
    backgroundColor: theme.palette.colors.gray,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.gray,
    },
  },
  green: {
    backgroundColor: theme.palette.colors.green,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.green,
    },
  },

  stateButton: {
    padding: 0,
    minWidth: 0,
  },
  tableTitle: {
    fontWeight: 500,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: '4px 4px',
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  greenIcon: {
    color: theme.palette.colors.green,
  },
  redIcon: {
    color: theme.palette.colors.red,
  },
  orangeIcon: {
    color: theme.palette.colors.orange,
  },
  blueIcon: {
    color: theme.palette.primary.main,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paginationButton: {
    width: '10%',
    marginTop: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    padding: '8px 18px',
    background: '#f97a00',
    borderRadius: '6px',
    cursor: 'pointer',
    color: 'white',
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center',

    '&:hover': {
      background: '#e6e6e6',
    },

    '&:active': {
      background: '#c6c6c6',
    },
  },
  paginationText: {
    marginTop: '10px',
    marginLeft: '5px',
    marginRight: '5px',
    padding: '8px 18px',
    color: '#f97a00',
    fontSize: 15,
    fontWeight: 500,
    textAlign: 'center',
  },
  searchButton: {
    background: '#f97a00',
    marginLeft: '10px',
  },
  searchInput: {
    width: '70%',
  },
}));
