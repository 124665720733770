import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import { GET_CLIENT_SPOUSE_DATA } from '@queries/Client';
import useStyles from './styles';
import CustomCard from '../../../CustomCard';
import PublicFunctions from './publicFunctions';
import ParticipationTable from './participation';
import ClientTexts from '../../../FormComponents/ClientTexts';
import LoadingIndicator from '../../../LoadingIndicator';
import { edgeToList } from '../../../../utils/commonFunctions';
import NextStepButton from '../../../FormComponents/NextStepButton';
import { getRelativePEPSelector } from '../../../../containers/ModelsPages/ClientForm/selectors';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';

function SpousePEP(props) {
  const { clientID } = props;
  const classes = useStyles();
  const [participations, setParticipations] = useState([]);
  const [publicFunctions, setPublicFunctions] = useState([]);
  const [client, setClient] = useState(null);
  const { clientLoading, data: clientData } = useQuery(GET_CLIENT_SPOUSE_DATA, {
    variables: { id: clientID },
  });
  const relativePEP = useSelector(getRelativePEPSelector());

  useEffect(() => {
    if (clientData) {
      setClient(clientData.client);
      setParticipations(edgeToList(clientData.client, 'spouseParticipations'));
      setPublicFunctions(
        edgeToList(clientData.client, 'spousePublicFunctions')
      );
    }
  }, [clientData]);

  if (clientLoading) {
    return <LoadingIndicator />;
  }

  if (client && !client.spouse) {
    return (
      <ClientTexts text='Para ver esta sección debe primero haber declarado tener un cónyuge, en el apartado "Estado civil" en datos legales.' />
    );
  }

  return (
    <div className={classes.stepsFormContainer}>
      <CustomCard
        title="CÓNYUGE PEP"
        content={
          <>
            <ClientTexts text="Son aquellas Personas Naturales o Extranjeras que en los últimos 5 años cumplen o hayan cumplido funciones públicas destacadas o funciones prominentes en una en el territorio nacional o extranjero." />
            {client && (
              <>
                <ClientTexts
                  text={`Yo, ${client.user.firstName} ${client.user.lastName} ${
                    client.user.maternalSurname
                  }, identificado con ${
                    client.user.documentType
                      ? client.user.documentType.name
                      : client.alternativeDocumentType
                  } Nro ${
                    client.user.documentNumber
                  } declaro que toda la información que se indica líneas abajo en relación a la condición de PEP son ciertas.`}
                />
                <CustomCard
                  subtitle="Funciones públicas"
                  disableFlex
                  content={
                    <>
                      <ClientTexts
                        text="Datos de las funciones públicas desempeñadas en los últimos 5
                  años"
                      />
                      <PublicFunctions
                        clientId={clientID}
                        data={publicFunctions}
                      />
                    </>
                  }
                />
                <CustomCard
                  subtitle="Participación"
                  disableFlex
                  content={
                    <>
                      <ClientTexts text="Respecto del PEP. ¿En qué personas jurídicas o entes jurídicos mantiene una participación igual o superior al 25%?" />
                      <ParticipationTable
                        clientId={clientID}
                        spouseId={client.spouse.id}
                        data={participations}
                      />
                    </>
                  }
                />
              </>
            )}
            <NextStepButton
              clientID={clientID}
              nextForm={
                relativePEP
                  ? MENU_ITEMS_ID.RelativePEP
                  : MENU_ITEMS_ID.ClientTermsConditions
              }
            />
          </>
        }
      />
    </div>
  );
}

SpousePEP.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default SpousePEP;
