// GraphQl
import { gql } from 'apollo-boost';

export const LIST_MONTHLY_INCOME_RANGES = gql`
  {
    listMonthlyIncomeRanges {
      edges {
        node {
          id
          name
          minimum
          maximum
        }
      }
    }
  }
`;

export const GET_MONTHLY_INCOME_RANGE = gql`
  query getMonthlyIncomeRange($id: ID!) {
    monthlyIncomeRange(id: $id) {
      id
      name
      minimum
      maximum
    }
  }
`;

export const CREATE_MONTHLY_INCOME_RANGE = gql`
  mutation createMonthlyIncomeRange($input: MonthlyIncomeRangeInput!) {
    createMonthlyIncomeRange(input: $input) {
      monthlyIncomeRange {
        id
        name
        minimum
        maximum
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_MONTHLY_INCOME_RANGE = gql`
  mutation updateMonthlyIncomeRange(
    $id: ID!
    $input: MonthlyIncomeRangeInput!
  ) {
    updateMonthlyIncomeRange(id: $id, input: $input) {
      monthlyIncomeRange {
        id
        name
        minimum
        maximum
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_MONTHLY_INCOME_RANGE = gql`
  mutation deleteMonthlyIncomeRange($id: ID!) {
    deleteMonthlyIncomeRange(id: $id) {
      monthlyIncomeRange {
        id
        name
        minimum
        maximum
      }
      errors {
        field
        message
      }
    }
  }
`;
