import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import CheckIcon from '@material-ui/icons/Check';
import { Button, Grid, Box } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { useSnackbar } from 'notistack';

import {
  CONFIRM_CLIENT_SIGNATURE,
  GET_CLIENT_TERMS_AND_CONDITIONS,
  CREATE_CLIENT_SIGNATURE,
  RESEND_CLIENT_SIGNATURE_KEY,
} from '@queries/Client';
import { DOWNLOAD_CLIENT_SHEET_MUTATION } from './queries';
import CustomCard from '../../CustomCard';
import ClientTexts from '../../FormComponents/ClientTexts';
import LoadingIndicator from '../../LoadingIndicator';
import {
  errorMessage,
  successMessage,
  edgeToList,
} from '../../../utils/commonFunctions';
import Yup from '../../../utils/yup';
import { parseDate } from '../../../utils/date';
import { setClientPermissions } from '../../../store/permissionsStore/actions';
import useStyles from './styles';
import { getMissingStepsSelector } from 'src/containers/ModelsPages/ClientForm/selectors';
import CustomButton from 'src/components/FormComponents/CustomButton';
import CustomModal from '../../../components/CustomModal';
import { useHistory } from 'react-router-dom';
import BiometricModal from '../../BiometricModal';

function TermsConditions(props) {
  const { clientID } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [signSent, setSignSent] = useState(false);
  const [signedData, setSignedData] = useState({
    isSigned: false,
    date: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [dsSigningUrl, setDsSigningUrl] = useState('');
  const [loadFrame, setLoadFrame] = useState(false);
  const [showBio, setShowBio] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [keynuaToken, setKeynuaToken] = useState('');
  const [openKeynua, setOpenKeynua] = useState(false);
  const [divId, setDivId] = useState('');

  const history = useHistory();
  const missingSteps = useSelector(getMissingStepsSelector());

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_TERMS_AND_CONDITIONS, {
    variables: { id: clientID },
  });
  const [sign, { loading: signLoading }] = useMutation(
    CREATE_CLIENT_SIGNATURE,
    {
      onError(error) {
        setSignSent(false);
        errorMessage(error, enqueueSnackbar);
      },
      onCompleted(data) {
        const { clientSheetSignature, errors } = data.createClientSignature;

        if (clientSheetSignature) {
          confirmSign({
            variables: { input: { client: clientID } },
          });
          setSignSent(true);
          successMessage(
            'Se le redijira al proceso de Keynua.',
            enqueueSnackbar,
          );
        } else if (errors) {
          setSignSent(false);
          setSignedData({
            isSigned: false,
            date: '',
          });
          errorMessage(errors, enqueueSnackbar);
        } else {
          setSignSent(false);
          setSignedData({
            isSigned: false,
            date: '',
          });
          errorMessage('Error en la operación', enqueueSnackbar);
        }
      },
    },
  );
  const [resend, { loading: resendLoading }] = useMutation(
    RESEND_CLIENT_SIGNATURE_KEY,
    {
      onError(error) {
        errorMessage(error, enqueueSnackbar);
      },
      onCompleted(data) {
        const { clientSheetSignature, errors } = data.resendClientSignatureKey;
        if (clientSheetSignature) {
          successMessage(
            'Se le ha enviado un nuevo código a su correo y celular.',
            enqueueSnackbar,
            {
              persist: true,
            },
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    },
  );
  const [confirmSign, { loading: confirmSignLoading }] = useMutation(
    CONFIRM_CLIENT_SIGNATURE,
    {
      onError(error) {
        if (error.message.match('duplicate')) {
          errorMessage(
            'El participante ya ha sido registrado.',
            enqueueSnackbar,
            {},
          );
        } else {
          errorMessage(error, enqueueSnackbar);
        }
        setSignSent(true);
      },
      onCompleted(data) {
        const {
          keynuaProcess,
          clientSheetSignature,
          errors,
        } = data.confirmClientSignatureKeynua;

        if (keynuaProcess && keynuaProcess?.token) {
          const token = keynuaProcess?.token;
          setKeynuaToken(token);
          setOpenKeynua(true);
        }

        if (clientSheetSignature) {
          // setDsSigningUrl(clientSheetSignature.dsSigningUrl);
          setSignSent(false);
          setSignedData({
            isSigned: false,
            date: clientSheetSignature.updatedAt,
          });
          const permissions = edgeToList(
            clientSheetSignature.client.user,
            'frontendPermissions',
          ).map(p => p.slug);
          dispatch(setClientPermissions(permissions));
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      },
      refetchQueries: [
        { query: GET_CLIENT_TERMS_AND_CONDITIONS, variables: { id: clientID } },
      ],
    },
  );

  const [downloadSheet] = useMutation(DOWNLOAD_CLIENT_SHEET_MUTATION, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (
        data.downloadClientSheet.sent &&
        clientData.client.signedPdfStatus == 'SIGNED'
      ) {
        successMessage(
          'Firma exitosa, se le enviará su ficha de colaborador por correo.',
          enqueueSnackbar,
          {
            persist: true,
          },
        );
      }
    },
  });

  useEffect(() => {
    if (clientData && clientData.client) {
      if (
        clientData.client.signedPdfStatus == 'SIGNED' ||
        clientData.client.signedPdfSheet != '' ||
        clientData.client.state == 'employee_completed'
      )
        setIsSigned(true);
      setSignedData({
        isSigned:
          clientData.client.signedPdfStatus == 'SIGNED' ||
          clientData.client.state == 'employee_completed' ||
          clientData.client.signedPdfSheet != '',
        date: clientData.client.lastSignatureDate,
      });
    }
  }, [clientData]);

  const loadKeynuaModal = () => {
    const widgetId = Math.floor(Math.random() * 1000);
    if (divId && divId !== '') {
      const element = document.getElementById(divId);
      if (element) element.remove();
    }
    setDivId(widgetId);
    setTimeout(() => {
      const newKeynuaDiv = document.createElement('div');
      newKeynuaDiv.setAttribute('id', widgetId);
      newKeynuaDiv.innerHTML = '';
      document.getElementById('keynuaDiv').appendChild(newKeynuaDiv);
      window.Keynua.run({
        token: keynuaToken,
        widgetContainerId: widgetId,
        uiCallback: ev => console.log({ ev }),
      });
    }, 0);
  };

  useEffect(() => {
    if (keynuaToken && openKeynua) {
      loadKeynuaModal();
    }
  }, [keynuaToken, openKeynua]);

  if (clientLoading) {
    return <LoadingIndicator />;
  }
  if (clientError) {
    errorMessage('Hubo un error, intente de nuevo por favor.', enqueueSnackbar);
  }

  const buttonClassName = clsx({
    [classes.buttonSuccess]: signedData.isSigned,
  });

  const handleSendSignClick = () => {
    sign({ variables: { input: { client: clientID } } });
  };

  const handleResendClick = () => {
    resend({ variables: { input: { client: clientID } } });
  };

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
  });

  const loadIframe = () => {
    if (!loadFrame) {
      setLoadFrame(true);
    } else {
      setLoadFrame(false);
      setTimeout(() => {
        history.push('/cliente');
      }, 2000);
      setShowModal(false);
      downloadSheet({
        variables: { clientId: clientID },
      });
      if (isSigned) {
        setSignedData({
          isSigned: true,
          date: signedData.date,
        });
      } else {
        setSignedData({
          isSigned: false,
          date: '',
        });
      }
    }
  };

  return (
    <div className={classes.stepsFormContainer}>
      <CustomCard
        title="TÉRMINOS Y CONDICIONES "
        content={
          <Formik
            initialValues={{ code: '' }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSendSignClick();
              setSubmitting(false);
            }}
            validateOnChange
            validateOnblur
          >
            {({ submitForm }) => (
              <form className={classes.simpleForm}>
                <Grid container>
                  <Grid container alignItems="center">
                    <Grid item md={12} xs={12}>
                      <p className={classes.paragraph}>
                        {'a)'} Declaro bajo juramento que los datos y demás
                        información consignada en el presente documento son
                        verdaderos y actuales, obligándome frente a mi empleador
                        a presentarla anualmente, durante el mes de enero del
                        año calendario siguiente, con datos actualizados a la
                        fecha de presentación, de conformidad con lo dispuesto
                        por el artículo 8° de la Resolución SBS N° 486-2008 y
                        sus normas modificatorias, en concordancia con la Ley N°
                        27693 y sus normas modificatorias. En caso de variación
                        de alguno de ellos, me obligo frente a mi empleador a
                        comunicarlo en un plazo máximo que no excederá de quince
                        (15) días calendario de producido el cambio.
                      </p>
                      <p className={classes.paragraph}>
                        {'b)'} Mediante la suscripción de la presente Ficha de
                        Antecedentes Personales, la persona cuyos datos han sido
                        consignados en el numeral 1 de la empresa; declara bajo
                        juramento que no posee ningún tipo de antecedente penal,
                        judicial o policial.
                      </p>
                      <p className={classes.paragraph}>
                        {'c)'} En ese sentido la persona que firma la presente
                        ficha ratifica la veracidad de la presente declaración
                        jurada, sometiéndose de no ser así, a las acciones
                        administrativas y de Ley que correspondan.
                      </p>
                    </Grid>
                    {/* {signSent ? (
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        item
                      >
                        <TextField
                          name="code"
                          label="Ingrese código"
                          variant="outlined"
                          helperText="El código enviado tiene una vigencia de tres (3) minutos."
                        />
                        <Button
                          variant="contained"
                          onClick={handleResendClick}
                          disabled={resendLoading}
                          className={classes.buttonResend}
                          color="secondary"
                        >
                          Reenviar código
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setShowModal(true);
                            submitForm();
                          }}
                          disabled={confirmSignLoading}
                        >
                          Firmar
                        </Button>
                        {confirmSignLoading ? (
                          <LoadingIndicator size="sm" />
                        ) : null}
                      </Grid>
                    ) : ( */}
                    <Grid
                      container
                      justify="space-between"
                      alignItems="center"
                      item
                    >
                      <Grid>
                        <Fab className={buttonClassName}>
                          <CheckIcon className={classes.check} />
                        </Fab>
                        {isSigned && (
                          <>
                            Firmado en{' '}
                            {parseDate(clientData.client.lastSignatureDate)}
                          </>
                        )}
                      </Grid>
                      <CustomButton></CustomButton>
                      {/* {!isSigned && ( */}
                      <Button
                        variant="contained"
                        // onClick={() => setShowBio(true)}
                        onClick={() => {
                          handleSendSignClick();
                          setShowModal(true);
                          submitForm();
                        }}
                        disabled={signLoading}
                        color="secondary"
                      >
                        Firmar electrónicamente
                      </Button>
                      {/* )} */}
                    </Grid>
                    {/* )} */}
                    {keynuaToken != '' && openKeynua ? (
                      <CustomModal
                        fullWidth
                        maxWidth="lg"
                        showModal={openKeynua}
                        title="Firma"
                        onClosePress={() => {
                          setOpenKeynua(false);
                        }}
                        disableFlex
                      >
                        <div className={classes.modalContent} justify="center">
                          <Box id="keynuaDiv" sx={{ flexGrow: 1 }}></Box>
                          <Box
                            display="flex"
                            marginTop="20px"
                            justifyContent="end"
                          >
                            <Button
                              variant="contained"
                              // onClick={() => setShowBio(true)}
                              onClick={() => loadKeynuaModal()}
                              //disabled={}
                              color="secondary"
                            >
                              Actualizar documento
                            </Button>
                          </Box>
                        </div>
                      </CustomModal>
                    ) : null}
                    {dsSigningUrl != '' && !signSent ? (
                      <CustomModal
                        fullWidth
                        maxWidth="lg"
                        showModal={showModal}
                        title="Firma"
                        onClosePress={() => {
                          setShowModal(false);
                          setLoadFrame(false);
                        }}
                        disableFlex
                      >
                        <div className={classes.modalContent} justify="center">
                          <iframe
                            id="DocuSignFrame"
                            title="DocuSignFrame"
                            width="100%"
                            height="800"
                            src={dsSigningUrl}
                            onLoad={loadIframe}
                          ></iframe>
                        </div>
                      </CustomModal>
                    ) : null}
                    {/* <BiometricModal
                      onClosePress={handleSendSignClick}
                      showModal={showBio}
                      setShowModal={setShowBio}
                      clientID={clientID}
                    /> */}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        }
      />
    </div>
  );
}

TermsConditions.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default TermsConditions;
