import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingLeft: 10,
  },
  columnContainer: {
    padding: 10,
  },
  editorContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 5,
    padding: 10,
    minHeight: 300,
    marginBottom: 20,
  },
  tableTitle: {
    fontWeight: 500,
  },
  infoContainer: {
    '& #mui-rte-toolbar': {
      border: 'none',
    },
  },
}));
