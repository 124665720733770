import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

import SimpleForm from '../../../Forms/SimpleForm';
import {
  GET_PROMOTER_INFO,
  CREATE_PROMOTER,
  LIST_PROMOTERS,
  DELETE_PROMOTER,
  UPDATE_PROMOTER,
} from '../queries';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_DOCUMENT_TYPES } from '../../DocumentType/queries';
import { formStructure, validationSchema, startData } from './formInfo';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import { LIST_PROMOTER_COMPANIES } from '../../PromoterComponent/queries';
import InnerBankDataBankAccountList from '../../../InnerModels/BankAccount/BankAccountList';

export const PROMOTER = 'Promoter';

function PromoterForm(props) {
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [investorData, setInvestorData] = useState(null);
  const [initData, setInitData] = useState(startData);
  const [bankAccounts, setBankAccounts] = useState([]);

  // RELATED QUERIES
  const [loadPromoter, { loading, data: promoterData }] = useLazyQuery(
    GET_PROMOTER_INFO,
    {
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    }
  );
  const {
    loading: promoterCompaniesLoading,
    error: promoterCompaniesError,
    data: promoterCompaniesData,
  } = useQuery(LIST_PROMOTER_COMPANIES);
  const {
    loading: documentTypesLoading,
    error: documentTypesError,
    data: documentTypesData,
  } = useQuery(LIST_DOCUMENT_TYPES);

  if (promoterCompaniesError) {
    console.error(
      'Interested Investor - list promoter companies',
      promoterCompaniesError
    );
  }
  if (documentTypesError) {
    console.error(
      'Interested Investor - list document types',
      documentTypesError
    );
  }

  function promoterEdgeToList(promotersList) {
    if (promotersList) {
      return promotersList.edges.map(element => {
        const newNode = {
          id: element.node.id,
          fullName: `${element.node.firstName} ${element.node.lastName}`,
        };
        return newNode;
      });
    }
    return [];
  }

  const updateMutation = useMutation(
    UPDATE_PROMOTER,
    getOptions({
      mutationName: 'updatePromoter',
      modelName: 'promoter',
      message: 'Promotor actualizado.',
      completeCallback: () => history.goBack(),
      enqueueSnackbar,
    })
  );
  const createMutation = useMutation(
    CREATE_PROMOTER,
    getOptions({
      mutationName: 'createPromoter',
      modelName: 'promoter',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [{ query: LIST_PROMOTERS }],
    })
  );
  const deleteMutation = useMutation(
    DELETE_PROMOTER,
    getOptions({
      mutationName: 'deletePromoter',
      modelName: 'promoter',
      message: 'Promotor eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTERS }],
      completeCallback: () => history.goBack(),
    })
  );

  useEffect(() => loadPromoter(), [id]);

  useEffect(() => {
    if (promoterData && promoterData.promoter) {
      const promoter = { ...promoterData.promoter };
      delete promoter.address;
      const complete = { ...promoter, ...promoterData.promoter.address };
      complete.country = complete.country ? complete.country.id : '';
      complete.county = complete.county
        ? { id: complete.county.id, name: complete.county.name }
        : '';
      complete.city = complete.city ? complete.city.id : '';
      complete.ubigeo = complete.district ? complete.district.ubigeo : '';
      complete.district = complete.district ? complete.district.id : '';

      complete.promoterCompanies = complete.promoterCompanies.edges.map(
        val => val.node.id
      );

      complete.representativeDocumentType =
        complete.representativeDocumentType.id;

      const tmpBankAccounts = edgeToList(complete, 'bankAccounts').map(
        v => v.id
      );
      setBankAccounts(tmpBankAccounts);
      delete complete.bankAccounts;

      setInvestorData(complete);
    }
  }, [promoterData]);

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    const address = {
      country: newValues.country,
      county: newValues.county,
      detail: newValues.detail,
    };
    if (newValues.city) address.city = newValues.city;
    if (newValues.district) address.district = newValues.district;
    delete newValues.country;
    delete newValues.county;
    delete newValues.city;
    delete newValues.district;
    delete newValues.detail;
    delete newValues.ubigeo;
    newValues.address = address;

    if (id === 'crear') {
      newValues.enabled = true;
    }

    return newValues;
  }
  const validate = () => ({});

  return loading && documentTypesLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container>
        <SimpleForm
          model={investorData || startData}
          initialValues={initData}
          validateFunction={validate}
          validateSchema={validationSchema}
          formatBeforeSubmit={formatBeforeSubmit}
          data={formStructure(
            edgeToList(promoterCompaniesData, 'listPromoterCompany'),
            edgeToList(documentTypesData, 'listDocumentTypes')
          )}
          modelName="Promotor"
          hasCancel
          routeName=""
          isEdit={id !== 'crear'}
          id={id}
          onCancel={() => history.goBack()}
          canBeDeleted
          updateMutation={updateMutation}
          createMutation={createMutation}
          deleteMutation={deleteMutation}
        />
      </Grid>
      <br />
      <br />
      <Grid container>
        {promoterData && (
          <InnerBankDataBankAccountList
            accountIdList={bankAccounts}
            onBankAccountCreated={accountId => {
              if (!accountId) {
                return;
              }
              const [updateModel] = updateMutation;
              const tmpBankAccounts = [...bankAccounts, accountId];
              setBankAccounts(tmpBankAccounts);
              updateModel({
                variables: {
                  id,
                  input: {
                    bankAccounts: tmpBankAccounts,
                  },
                },
              });
            }}
          />
        )}
      </Grid>
    </>
  );
}

export default PromoterForm;
