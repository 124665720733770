import React, { useEffect, useState } from 'react';

// Material Resources
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Avatar,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MultilineChart from '@material-ui/icons/MultilineChart';
import AttachEmailIcon from '@material-ui/icons/AttachFile';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import CustomOptions from '../../../CustomOptions';
import CustomModal from '../../../CustomModal';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_FUNDS, DELETE_FUND, UPDATE_FUND } from '../queries';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { getOptions, generateColumn } from '../../../../utils/commonFunctions';
import { REACT_APP_SERVER_LINK } from '../../../../utils/constants';

// Styles & Images
import useStyles from './styles';
import FundVCC from './VCC';

function CustomToolbar() {
  const history = useHistory();
  const handleClick = () => {
    history.push('/administrador/fondos/crear');
  };

  return (
    <Tooltip title="Nuevo fondo">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function FundList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [selectedFundId, setSelectedFundId] = useState('');
  const [fundsList, setFundsList] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [deleteFund] = useMutation(
    DELETE_FUND,
    getOptions({
      mutationName: 'deleteFund',
      modelName: 'fund',
      message: 'Fondo eliminado con éxito.',
      enqueueSnackbar,
    }),
  );

  const [updateFund] = useMutation(
    UPDATE_FUND,
    getOptions({
      mutationName: 'updateFund',
      modelName: 'fund',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
    }),
  );

  const { loading, error, data } = useQuery(LIST_FUNDS);
  if (error) {
    console.error('list fund', error);
  }

  function FundEdgeToList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.options = {
          id: formatElement.id,
          state: formatElement.state,
        };
        return formatElement;
      });
    }
    return [];
  }

  useEffect(() => {
    let list = [];
    if (data) {
      list = FundEdgeToList(data, 'listFunds');
      setFundsList(list);
    }
  }, [data]);

  function handleState(e, id, state) {
    updateFund({
      variables: {
        id,
        input: {
          state: !state,
        },
      },
    });
  }

  const columns = [
    {
      name: 'logo',
      label: 'Logo',
      options: {
        sort: false,
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
        customBodyRender: value => {
          return (
            <Avatar
              alt="logo"
              variant="square"
              src={`${REACT_APP_SERVER_LINK}/media/${value}`}
            />
          );
        },
      },
    },
    generateColumn('RUC', 'ruc'),
    generateColumn('Nombre de la compañía', 'businessName'),
    generateColumn('Valor Cuota Nominal', 'nominalFeeValue'),
    {
      name: 'state',
      label: 'Estado',
      options: {
        sort: false,
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
        customBodyRender: value => {
          const state = value;
          return <p>{state ? 'Activo' : 'Inactivo'} </p>;
        },
      },
    },
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customHeadRender: columnMeta => {
        return (
          <TableCell key={5} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
      customBodyRender: value => {
        const { id, state } = value;
        return (
          <CustomOptions
            id={id}
            model="Fondo"
            options={['ocustom', 'oenable']}
            extraData={{ oenable: state }}
            extraFunctions={{ oenable: e => handleState(e, id, state) }}
            customOption={
              <>
                <Tooltip arrow title="Levantamientos" placement="top">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    href={`/administrador/fondos/${id}/levantamientos/`}
                    rel="noopener noreferrer"
                  >
                    <TrendingUpIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Recompras" placement="top">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    href={`/administrador/fondos/${id}/recompras/`}
                    rel="noopener noreferrer"
                  >
                    <MultilineChart />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Adjuntos" placement="top">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    href={`/administrador/fondos/adjuntos/${id}`}
                    rel="noopener noreferrer"
                  >
                    <AttachEmailIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Añadir VCC del mes" placement="top">
                  <span className={classes.optionButtons}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setSelectedFundId(id);
                        setShowModal(true);
                      }}
                    >
                      VCC
                    </Button>
                  </span>
                </Tooltip>
              </>
            }
          />
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `/administrador/fondos/${rowData[rowData.length - 1].props.id}`,
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MUIDataTable
            title="Fondos"
            data={fundsList}
            columns={columns}
            options={options}
          />

          {selectedFundId && (
            <CustomModal
              maxWidth="lg"
              showModal={showModal}
              title="Fondos"
              onClosePress={() => {
                setShowModal(false);
              }}
            >
              <FundVCC fundID={selectedFundId} />
            </CustomModal>
          )}
        </>
      )}
    </>
  );
}

export default FundList;
