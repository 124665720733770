import { fromJS } from 'immutable';

import { SET_LOGIN_DATA } from './constants';

// The initial state of the App
const initialState = fromJS({
  token: '',
  user: null,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_DATA:
      return state.set('token', action.token).set('user', action.user);
    default:
      return state;
  }
}

export default loginReducer;
