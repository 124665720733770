import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  formContainer: {
    paddingBottom: 20,
    paddingTop: 20,
  },
}));
