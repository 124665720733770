import React, { useState, useEffect } from 'react';

import Yup from '../../../../utils/yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { parserData, beforeSubmit, parseRecoveryData } from '../common';
import LoadingIndicator from '../../../LoadingIndicator';
import StepsForm from '../../../Forms/StepsForm';
import { edgeToList } from '../../../../utils/commonFunctions';
import {
  GET_CLIENT_RISK_PROFILE,
  GET_RISK_SECTION,
  UPDATE_RISK,
} from '../queries';
import {
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';

function InvestorKnowledge(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { clientID } = props;
  const [riskProfileId, setRiskProfileId] = useState(null);
  const [startValues, setStartValues] = useState({});
  const [dataList, setDataList] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [recoveryData, setRecoveryData] = useState({});
  const [typeList, setTypeList] = useState([]);
  const [deleteQ, setDeleteQ] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [initialHide, setInitialHide] = useState(true);

  const {
    loading: sectionLoading,
    error: sectionError,
    data: sectionData,
  } = useQuery(GET_RISK_SECTION, {
    fetchPolicy: 'no-cache',
    variables: { name: 'Conocimiento del cliente' },
  });

  const updateRiskRegister = useMutation(UPDATE_RISK, {
    onError(error) {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors) {
        errorMessage(graphQLErrors, enqueueSnackbar);
      }
      if (networkError) {
        errorMessage(networkError, enqueueSnackbar);
      }
    },
    onCompleted(data) {
      if (data.updateRiskProfile) {
        if (data.updateRiskProfile.errors) {
          errorMessage(data.updateRiskProfile.errors, enqueueSnackbar);
        }
      } else {
        successMessage('Se guardó la información.', enqueueSnackbar);
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_RISK_PROFILE, variables: { id: clientID } },
    ],
  });

  const handleChange = (event, dependency) => {
    event.preventDefault();
    if (event.target.value) {
      if (dependency[event.target.name].answersID !== event.target.value) {
        setDeleteQ([...deleteQ, dependency[event.target.name].questionID]);
      } else {
        setDeleteQ(
          deleteQ.filter(
            element => element !== dependency[event.target.name].questionID,
          ),
        );
      }
    }
  };

  useEffect(() => {
    if (sectionData && sectionData.listSections) {
      parserData(
        sectionData,
        setStartValues,
        setOriginalData,
        setDataList,
        setTypeList,
        handleChange,
      );
      setLoadingData(false);
    }
  }, [sectionData]);

  const [
    getRiskProfile,
    { loading, error: riskProfileError, data: clientRiskProfile },
  ] = useLazyQuery(GET_CLIENT_RISK_PROFILE, {
    fetchPolicy: 'no-cache',
    variables: { id: clientID },
  });

  const validationSchema = Yup.object().shape({});

  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    return errors;
  }

  function formatBeforeSubmit(values) {
    const format = beforeSubmit(values, riskProfileId, typeList);
    return format;
  }

  useEffect(() => getRiskProfile(), []);

  useEffect(() => {
    if (
      clientRiskProfile &&
      clientRiskProfile.riskProfile &&
      Object.keys(startValues).length !== 0
    ) {
      setRiskProfileId(clientRiskProfile.riskProfile.id);
      const recovery = parseRecoveryData(clientRiskProfile.riskProfile);
      setRecoveryData({
        ...startValues,
        ...recovery,
      });
    }
  }, [clientRiskProfile, startValues]);

  useEffect(() => {
    if (
      initialHide &&
      sectionData !== undefined &&
      clientRiskProfile !== undefined
    ) {
      let registeredAnswers = edgeToList(
        clientRiskProfile.riskProfile,
        'registeredAnswers',
      ).map(ans => ans.answer.id);
      if (registeredAnswers.length === 0) {
        setInitialHide(false);
        return;
      }
      edgeToList(edgeToList(sectionData, 'listSections')[0], 'questions').map(
        question => {
          if (question.enableOn) {
            let onAnswers = true;
            onAnswers = registeredAnswers.find(regAns => {
              return regAns === question.enableOn.id;
            });
            if (onAnswers === question.enableOn.id) {
              setDeleteQ(deleteQ.filter(element => element !== question.id));
            } else {
              setDeleteQ([...deleteQ, question.id]);
            }
            setInitialHide(false);
          }
        },
      );
    }
  });

  useEffect(() => {
    setDataList(prevState => {
      const delQuestion = originalData.filter(
        e => !deleteQ.find(q => e.fields[0].name === q),
      );
      return delQuestion;
    });
  }, [deleteQ]);

  return loading || loadingData || Object.keys(recoveryData).length === 0 ? (
    <LoadingIndicator />
  ) : (
    <StepsForm
      id={riskProfileId}
      model={recoveryData}
      mainClientID={clientID}
      initialValues={recoveryData}
      validateFunction={validationFunction}
      validateSchema={validationSchema}
      data={dataList}
      title="Conocimiento del cliente"
      nextForm={MENU_ITEMS_ID.ClientTermsConditions}
      updateMutation={updateRiskRegister}
      partialUpdateMutation={updateRiskRegister}
      formatBeforeSubmit={formatBeforeSubmit}
      answerSet={true}
    />
  );
}

InvestorKnowledge.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default InvestorKnowledge;
