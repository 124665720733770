import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useBeforeunload } from 'react-beforeunload';

// Material Resources
import { Grid, Button, FormHelperText } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
// Components & Others
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FieldSelect from './fieldsSelector';
import UPDATE_TREE_STATUS from './queries';
import { errorMessage, edgeToList } from '../../../utils/commonFunctions';

import {
  setVisible,
  setStatus,
  setMissingSteps,
} from '../../../containers/ModelsPages/ClientForm/actions';

import {
  getActiveFormSelector,
  getMissingStepsSelector,
} from '../../../containers/ModelsPages/ClientForm/selectors';
import { NODE_STATES } from '../../../containers/ModelsPages/ClientForm/constants';

import CustomModal from '../../CustomModal';
import CustomCard from '../../CustomCard';
import SectionDivider from '../../FormComponents/SectionDivider';
import { getEditionSelector } from '../../../containers/ModelsPages/ClientForm/selectors';

// Styles & Images
import useStyles from './styles';

import { setActiveFormAction } from '../../../containers/ModelsPages/ClientForm/actions';
import { MENU_ITEMS_ID } from '../../../containers/ModelsPages/ClientForm/constants';
import {
  LIST_MISSING_STEP,
  GET_CLIENT_DATA,
} from '../../../containers/ModelsPages/ClientForm/queries';
import { INPUT_VARIANT } from './fieldsSelector';
import { RELATION_TYPE } from 'src/components/StepsComponents/PersonalData/RelativeForm';

function StepsForm(props) {
  const {
    initialValues,
    validateFunction,
    validateSchema,
    data,
    model,
    id,
    mainClientID,
    title,
    nextForm,
    updateMutation,
    partialUpdateMutation,
    formatBeforeSubmit,
    answerSet,
    subtitle,
    dataInsideCard,
    columns,
    options,
    emergencyContact,
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const activeForm = useSelector(getActiveFormSelector());
  const missingSteps = useSelector(getMissingStepsSelector());
  const isEdition = useSelector(getEditionSelector());
  const history = useHistory();

  const [startValuesData, setStartValuesData] = useState(null);
  const [formTouched, setFormTouched] = useState(false);
  const [targetLocation, setTargetLocation] = useState('');
  const formRef = useRef();
  const unblockHandle = useRef();

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_DATA);

  const {
    loading: missingStepLoading,
    data: missingStepData,
    error: missingStepError,
  } = useQuery(LIST_MISSING_STEP);

  useEffect(() => {
    setStartValuesData(initialValues);
    setFormTouched(false);
  }, [initialValues, model]);

  const getMainClient = data => {
    const client = data.currentUser.clientSet.edges.find(
      node => node.node.isMain === true,
    );
    return client ? client.node : null;
  };

  const [updateTreeStatus] = useMutation(UPDATE_TREE_STATUS, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(completeData) {
      const { client, errors } = completeData.updateClient;
      if (!client) {
        errorMessage(errors, enqueueSnackbar);
      }
    },
    refetchQueries: [{ query: GET_CLIENT_DATA }],
  });

  const [showModal, setShowModal] = useState(false);

  const goToNextStep = () => {
    dispatch(setActiveFormAction(nextForm));
  };
  const goToSignAndSave = () => {
    dispatch(setActiveFormAction(MENU_ITEMS_ID.ClientTermsConditions));
  };

  const savePartial = async (values, errors) => {
    const { id: modelID, __typename, ...otherValues } = values;
    const formattedValues = formatBeforeSubmit(otherValues);
    Object.keys(errors).forEach(value => {
      delete formattedValues[value];
    });

    if (partialUpdateMutation && partialUpdateMutation.length > 0) {
      const [
        partialUpdate,
        {
          data: partialClientData,
          error: partialRespondeError,
          loading: partialLoading,
        },
      ] = partialUpdateMutation;
      let formError = false;
      if (!answerSet) {
        try {
          let response = await partialUpdate({
            variables: { id, input: formattedValues },
          });
        } catch {
          formError = true;
        }
      } else {
        try {
          let response = await partialUpdate({
            variables: { input: formattedValues },
          });
        } catch {
          formError = true;
        }
      }

      const steps = [...missingSteps];

      if (steps.indexOf(activeForm) === -1) {
        steps.push(activeForm);
      }
      const clientMissingSteps = [];
      if (missingStepData) {
        const allSteps = edgeToList(missingStepData, 'listMissingStep');
        allSteps.forEach(item => {
          if (steps.find(i => i === item.step)) {
            clientMissingSteps.push(item.id);
          }
        });
      }
      dispatch(setMissingSteps(steps));
      updateTreeStatus({
        variables: {
          id: mainClientID,
          input: { missingSteps: clientMissingSteps },
        },
      });
      dispatch(setStatus(activeForm, NODE_STATES.incomplete));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitForm = async (values, { setSubmitting }) => {
    setFormTouched(true);
    if (updateMutation && updateMutation.length > 0) {
      const [
        updateClient,
        { data: clientData, error: responseError, loading: updateLoading },
      ] = updateMutation;

      const { id: modelID, __typename, ...otherValues } = values;
      const formattedValues = formatBeforeSubmit(otherValues);
      let formError = false;
      let response;
      if (!answerSet) {
        try {
          response = await updateClient({
            variables: { id, input: formattedValues },
          });
        } catch {
          formError = true;
        }
      } else {
        try {
          response = await updateClient({
            variables: { input: formattedValues },
          });
        } catch {
          formError = true;
        }
      }
      const clientMissingSteps = [];
      const steps = [...missingSteps];
      const index = steps.indexOf(activeForm);
      if (index > -1) {
        steps.splice(index, 1);
      }
      if (!isEdition) {
        if (
          steps.indexOf(nextForm) === -1 &&
          nextForm !== MENU_ITEMS_ID.ClientTermsConditions
        ) {
          steps.push(nextForm);
        }
      }
      if (missingStepData) {
        const allSteps = edgeToList(missingStepData, 'listMissingStep');
        allSteps.forEach(item => {
          if (steps.find(i => i === item.step)) {
            clientMissingSteps.push(item.id);
          }
        });
      }
      dispatch(setStatus(activeForm, NODE_STATES.complete));
      dispatch(setMissingSteps(steps));
      updateTreeStatus({
        variables: {
          id: mainClientID,
          input: {
            treeStatus: nextForm.replace(/-/g, '_'),
            missingSteps: clientMissingSteps,
          },
        },
      });
      dispatch(setVisible(nextForm, true));
      if (!formError && response) {
        goToNextStep();
      }
      setSubmitting(false);
    }
  };

  useEffect(() => {
    unblockHandle.current = history.block((location, action) => {
      setTargetLocation(location.pathname);
      setShowModal(true);
      return false;
    });
    return () => {
      unblockHandle.current.current && unblockHandle.current.current();
    };
  }, []);

  useBeforeunload(event => {
    if (isEdition) {
      event.preventDefault();
    }
  });

  return (
    <div className={classes.stepsFormContainer}>
      <CustomModal
        maxWidth="md"
        showModal={showModal}
        title="¿ Estas seguro de salir ?"
        onClosePress={() => setShowModal(false)}
        hideScroll
      >
        <div className={classes.modalContainer}>
          <p className={classes.paragraph}>
            Estimado Partner, es importante que firme electrónicamente la ficha
            colaborador para registrar sus cambios. Asegúrese de aceptar los
            términos y condiciones antes de proceder. ¡Gracias por su
            colaboración!
          </p>
          <Grid container justify="space-between">
            {getMainClient(clientData) && (
              <Button
                variant="contained"
                color="secondary"
                onClick={e => {
                  e.preventDefault();
                  goToSignAndSave();
                }}
              >
                Firmar Ficha
              </Button>
            )}

            <div className={classes.btnMobile}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowModal(false);
                  if (unblockHandle) {
                    unblockHandle.current();
                  }
                  Object.entries(formRef.current.values).forEach(val =>
                    formRef.current.setFieldTouched(val[0], true),
                  );
                  setFormTouched(true);
                  formRef.current.handleSubmit();
                  history.push(targetLocation);
                }}
              >
                Continuar mas tarde
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowModal(false)}
              >
                Continuar Editando
              </Button>
            </div>
          </Grid>
        </div>
      </CustomModal>
      {startValuesData && data && (
        <CustomCard
          title={title.toUpperCase()}
          content={
            <Formik
              initialValues={model || startValuesData}
              validate={validateFunction}
              validationSchema={validateSchema}
              onSubmit={submitForm}
              enableReinitialize
              validateOnBlur={formTouched}
              validateOnChange={formTouched}
              innerRef={formRef}
            >
              {({
                setFieldValue,
                isSubmitting,
                errors,
                values,
                validateForm,
                setFieldTouched,
              }) => (
                <Form className={classes.simpleForm}>
                  <Grid container>
                    <Grid container alignItems="center">
                      {data.map(element => {
                        let { isField } = element;
                        if (isField === undefined) {
                          isField = true;
                        }
                        return (
                          <Grid
                            container
                            key={isField ? element.name : element.section}
                          >
                            {isField ? (
                              <FieldSelect
                                field={element}
                                errors={errors}
                                model={model || startValuesData}
                                initialValues={startValuesData}
                                setFieldValue={setFieldValue}
                                isStepForm
                              />
                            ) : (
                              <CustomCard
                                subtitle={element.section}
                                content={element.fields.map(field => (
                                  <FieldSelect
                                    key={field.name}
                                    field={field}
                                    errors={errors}
                                    model={model || startValuesData}
                                    initialValues={startValuesData}
                                    setFieldValue={setFieldValue}
                                    isStepForm
                                  />
                                ))}
                              />
                            )}
                          </Grid>
                        );
                      })}
                      {subtitle && (
                        <CustomCard
                          subtitle={subtitle}
                          disableFlex
                          content={
                            <MUIDataTable
                              title={subtitle}
                              data={dataInsideCard}
                              columns={columns}
                              options={options}
                            />
                          }
                        />
                      )}
                      {emergencyContact && (
                        <CustomCard
                          subtitle={'Contacto de Emergencia'}
                          content={
                            <>
                              <FieldSelect
                                key={'fullnameEmergency'}
                                field={{
                                  label: 'Nombre Completo de Contacto',
                                  name: 'fullnameEmergency',
                                  gridMD: 12,
                                  inputVariant: INPUT_VARIANT.onlyText,
                                }}
                                errors={errors}
                                model={model || startValuesData}
                                initialValues={startValuesData}
                                setFieldValue={setFieldValue}
                                isStepForm
                              />
                              <FieldSelect
                                key={'cellphoneEmergency'}
                                field={{
                                  label: 'Celular de Contacto',
                                  name: 'cellphoneEmergency',
                                  gridMD: 12,
                                  inputVariant: INPUT_VARIANT.text,
                                }}
                                errors={errors}
                                model={model || startValuesData}
                                initialValues={startValuesData}
                                setFieldValue={setFieldValue}
                                isStepForm
                              />
                              <FieldSelect
                                key={'relationEmergency'}
                                field={{
                                  label: 'Parentesco',
                                  name: 'relationEmergency',
                                  gridMD: 12,
                                  inputVariant: INPUT_VARIANT.select,
                                  data: RELATION_TYPE,
                                  mapData: { value: 'value', label: 'label' },
                                }}
                                errors={errors}
                                model={model || startValuesData}
                                initialValues={startValuesData}
                                setFieldValue={setFieldValue}
                                isStepForm
                              />
                            </>
                          }
                        />
                      )}
                      <SectionDivider />
                      {Object.keys(errors).length !== 0 && (
                        <Grid
                          container
                          justify="flex-start"
                          alignItems="center"
                        >
                          <FormHelperText className={classes.formText} error>
                            Faltan campos por completar
                          </FormHelperText>
                        </Grid>
                      )}
                      <Grid container justify="flex-start" alignItems="center">
                        <FormHelperText
                          className={classes.formText}
                          error={Object.keys(errors).length !== 0}
                        >
                          Los campos marcados con * son obligatorios.
                        </FormHelperText>
                      </Grid>
                      <Grid container justify="flex-end" alignItems="center">
                        {Object.keys(errors).length === 0 ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            type="submit"
                            onClick={() => {
                              Object.entries(values).forEach(val =>
                                setFieldTouched(val[0], true),
                              );
                              setFormTouched(true);
                            }}
                          >
                            Siguiente
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => {
                              Object.entries(values).forEach(val =>
                                setFieldTouched(val[0], true),
                              );
                              savePartial(values, errors);
                              setFormTouched(true);
                              validateForm();
                            }}
                          >
                            Continuar más tarde
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          }
        />
      )}
    </div>
  );
}

StepsForm.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  validateFunction: PropTypes.func.isRequired,
  validateSchema: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  model: PropTypes.shape(),
  id: PropTypes.string.isRequired,
  mainClientID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  nextForm: PropTypes.string.isRequired,
  updateMutation: PropTypes.array,
  formatBeforeSubmit: PropTypes.func,
  partialUpdateMutation: PropTypes.array,
};

StepsForm.defaultProps = {
  model: null,
  formatBeforeSubmit: values => values,
  updateMutation: [values => values, {}],
  partialUpdateMutation: [values => values, {}],
  answerSet: false,
};

export default StepsForm;
