import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_SAFI_RELATION,
  CREATE_SAFI_RELATION,
  DELETE_SAFI_RELATION,
  LIST_SAFI_RELATIONS,
} from '../queries';
import {
  validationFunctionSafiRelation,
  validationSchemaSafiRelation,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const RELATIONS_TYPES = {
  A_1: 'TYPE_1',
  A_2: 'TYPE_2',
  A_3: 'TYPE_3',
  A_4: 'TYPE_4',
};

const relationTypesOptions = [
  { value: 'A_1', label: '1' },
  { value: 'A_2', label: '2' },
  { value: 'A_3', label: '3' },
  { value: 'A_4', label: '4' },
];

const startValues = {
  name: '',
  typeRelation: '',
};

function SafiRelationForm(props) {
  const { safiRelation, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateSafiRelation = useMutation(
    UPDATE_SAFI_RELATION,
    getOptions({
      mutationName: 'updateSafiRelation',
      modelName: 'safiRelation',
      message: 'Relación con la SAF actualizada con éxito.',
      enqueueSnackbar,
    }),
  );
  const createSafiRelation = useMutation(
    CREATE_SAFI_RELATION,
    getOptions({
      mutationName: 'createSafiRelation',
      modelName: 'safiRelation',
      message: 'Relación con la SAF creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createSafiRelationData }) {
        if (cache.data.data.ROOT_QUERY.listSafiRelations) {
          const { listSafiRelations } = cache.readQuery({
            query: LIST_SAFI_RELATIONS,
          });
          listSafiRelations.edges.unshift({
            // Be careful with types
            __typename: 'SafiRelationTypeEdge',
            node: createSafiRelationData.createSafiRelation.safiRelation,
          });
          cache.writeQuery({
            query: LIST_SAFI_RELATIONS,
            data: { listSafiRelations },
          });
        }
        history.push('/administrador/relacion-con-la-safi');
      },
    }),
  );
  const deleteSafiRelation = useMutation(
    DELETE_SAFI_RELATION,
    getOptions({
      mutationName: 'deleteSafiRelation',
      modelName: 'safiRelation',
      message: 'Relación con la SAF eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listSafiRelations) {
          const { listSafiRelations } = cache.readQuery({
            query: LIST_SAFI_RELATIONS,
          });
          listSafiRelations.edges = listSafiRelations.edges.filter(
            e => e.node.id !== id,
          );
          cache.writeQuery({
            query: LIST_SAFI_RELATIONS,
            data: { listSafiRelations },
          });
        }
        history.push('/administrador/relacion-con-la-safi');
      },
    }),
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 8,
      gridXL: 8,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Tipo de relación',
      name: 'typeRelation',
      gridMD: 4,
      gridXL: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: relationTypesOptions,
    },
  ];
  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.typeRelation = RELATIONS_TYPES[newValues.typeRelation];
    return newValues;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionSafiRelation}
        validateSchema={validationSchemaSafiRelation}
        data={data}
        model={safiRelation}
        id={id}
        modelName="Relación con la SAF"
        routeName="/administrador/relacion-con-la-safi"
        isEdit={isEdit}
        formatBeforeSubmit={formatBeforeSubmit}
        updateMutation={updateSafiRelation}
        createMutation={createSafiRelation}
        deleteMutation={deleteSafiRelation}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

SafiRelationForm.propTypes = {
  safiRelation: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

SafiRelationForm.defaultProps = {
  safiRelation: null,
  id: '',
  isEdit: false,
};

export default SafiRelationForm;
