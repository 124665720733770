// GraphQl
import { gql } from 'apollo-boost';

export const LIST_ATTACHMENT_FUND = gql`
  query getFundDocuments($id: ID!) {
    fund(id: $id) {
      funddocumentSet {
        edges {
          node {
            id
            fileType
            file
            date
            state
          }
        }
      }
    }
  }
`;

export const GET_ATTACHMENT_FUND = gql`
  query getFundDocument($id: ID!) {
    fundDocument(id: $id) {
      id
      fileType
      file
      date
      state
    }
  }
`;

export const CREATE_ATTACHMENT_FUND = gql`
  mutation createFundDocument($input: FundDocumentInput!) {
    createFundDocument(input: $input) {
      fundDocument {
        id
        fileType
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_ATTACHMENT_FUND = gql`
  mutation updateFundDocument($id: ID!, $input: FundDocumentInput!) {
    updateFundDocument(id: $id, input: $input) {
      fundDocument {
        id
        fileType
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_ATTACHMENT_FUND = gql`
  mutation deleteFundDocument($id: ID!) {
    deleteFundDocument(id: $id) {
      fundDocument {
        id
        fileType
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;
