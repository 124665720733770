import React, { useState, useEffect } from 'react';
// Material Resources
import { TableCell, Typography } from '@material-ui/core';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  getOptions,
  stringToDate,
} from '../../../../../../utils/commonFunctions';
import Yup from '../../../../../../utils/yup';
import { INPUT_VARIANT } from '../../../../../Forms/StepsForm/fieldsSelector';
import {
  DELETE_ATTACHMENT_RAISING,
  UPDATE_ATTACHMENT_RAISING,
  CREATE_ATTACHMENT_RAISING,
  GET_ATTACHMENT_RAISING,
  LIST_ATTACHMENT_RAISING,
} from './queries';
import CustomColumnHeader from '../../../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../../../CustomOptions';

import CustomSubmodelList from '../../../../../InnerModels/CustomSubmodel/CustomSubmodelList';
import LoadingIndicator from '../../../../../LoadingIndicator';

// Styles & Images
import useStyles from '../../styles';

function RaisingAttachments() {
  const { fundId: fundID, id: raisingID } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { loading, error, data } = useQuery(LIST_ATTACHMENT_RAISING, {
    variables: { id: raisingID },
  });

  function formatAttachmentList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.options = {
          id: formatElement.id,
          state: formatElement.state,
        };
        return formatElement;
      });
    }
  }

  const mutationsData = {
    update: {
      query: UPDATE_ATTACHMENT_RAISING,
      mutationName: 'updateRaisingDocument',
      modelName: 'raisingDocument',
      message: 'Adjunto actualizado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_RAISING, variables: { id: raisingID } },
      ],
    },
    create: {
      query: CREATE_ATTACHMENT_RAISING,
      mutationName: 'createRaisingDocument',
      modelName: 'raisingDocument',
      message: 'Adjunto creado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_RAISING, variables: { id: raisingID } },
      ],
    },
    delete: {
      query: DELETE_ATTACHMENT_RAISING,
      mutationName: 'deleteRaisingDocument',
      modelName: 'raisingDocument',
      message: 'Adjunto eliminado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_RAISING, variables: { id: raisingID } },
      ],
    },
  };

  const [updateRaisingDocumentState] = useMutation(
    UPDATE_ATTACHMENT_RAISING,
    getOptions({
      mutationName: 'updateRaisingDocument',
      modelName: 'raisingDocument',
      message: 'Estado actualizado con éxito.',
      refetchQueries: [
        { query: LIST_ATTACHMENT_RAISING, variables: { id: raisingID } },
      ],
      enqueueSnackbar,
    })
  );

  function handleState(e, id, state) {
    updateRaisingDocumentState({
      variables: {
        id,
        input: {
          state: !state,
        },
      },
    });
  }

  const columns = [
    {
      name: 'file',
      label: 'Documento',
      options: {
        sort: false,
        customBodyRender: value => value.split('/').slice(-1)[0],
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        sort: false,
        customBodyRender: value => stringToDate(value).toLocaleDateString(),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'date',
      label: 'Hora',
      options: {
        sort: false,
        customBodyRender: value => new Date(value).toLocaleTimeString(),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'state',
      label: 'Estado',
      options: {
        sort: false,
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
        customBodyRender: value => {
          const state = value;
          return <p>{state ? 'Activo' : 'Inactivo'} </p>;
        },
      },
    },
    {
      name: 'options',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const { id, state } = value;
          return (
            <CustomOptions
              id={id}
              model="Adjunto"
              options={['oenable']}
              extraData={{ oenable: state }}
              extraFunctions={{ oenable: e => handleState(e, id, state) }}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const startValues = {
    file: '',
  };

  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    return errors;
  }

  const formStructure = [
    {
      label: 'Documento',
      name: 'file',
      accept: 'application/pdf',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.file,
    },
  ];

  function formatBeforeSubmit(values) {
    const { date, ...newValues } = values;
    if (newValues.file === null) {
      delete newValues.file;
    }
    newValues.raising = raisingID;
    return newValues;
  }

  return loading ? (
    <LoadingIndicator />
  ) : (
    <CustomSubmodelList
      mainID={raisingID}
      submodelName="Adjunto"
      submodelList={formatAttachmentList(data.raising, 'raisingdocumentSet')}
      columns={columns}
      updateModelFunction={() => {}}
      // Form props
      canBeDeleted={false}
      getQuery={GET_ATTACHMENT_RAISING}
      formatQuery={document => {
        const formatValue = { ...document.raisingDocument };
        return formatValue;
      }}
      buttonLoader
      mutationsData={mutationsData}
      startValues={startValues}
      validationFunction={validationFunction}
      formStructure={formStructure}
      formatBeforeSubmit={formatBeforeSubmit}
    />
  );
}

RaisingAttachments.propTypes = {
  raisingID: PropTypes.string.isRequired,
};

export default RaisingAttachments;
