// GraphQl
import { gql } from 'apollo-boost';

export const LIST_CATEGORIES = gql`
  {
    listCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($id: ID!) {
    category(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      category {
        id
        name
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($id: ID!, $input: CategoryInput!) {
    updateCategory(id: $id, input: $input) {
      category {
        id
        name
      }
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      ok
      message
    }
  }
`;
