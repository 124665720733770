/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';

import ContractList from '../../../components/Models/Contract/ContractList';
import ContractForm, {
  ContractEdit,
} from '../../../components/Models/Contract/ContractForm';

function ContractsPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ContractList} />
      <Route path={`${path}/crear`} component={ContractForm} />
      <Route path={`${path}/:id`} component={ContractEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

ContractsPage.propTypes = {};

export default ContractsPage;
