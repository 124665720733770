import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
}));
