/*
 * HomePage
 */

import React from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// Components & Others
// import Documents from '../../../components/Documents';
import DocumentsGeneric from '../../../components/Models/Documents/documentsGeneric';

// Styles & Images

function DocumentsGenericPage() {
  return (
    <Grid container alignItems="start">
      <Grid item xs={12}>
        <DocumentsGeneric />
      </Grid>
    </Grid>
  );
}

export default DocumentsGenericPage;
