import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { UPDATE_NEWS, CREATE_NEWS, DELETE_NEWS, LIST_NEWS } from '../queries';
import { validationFunctionNews, validationSchemaNews } from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  title: '',
  subtitle: '',
  description: '',
  newsImage: null,
  buttonTitle: '',
  link: '',
};

export const TYPES = [
  {
    value: 'N',
    label: 'Novedades',
    enum: 'NEWS',
  },
  {
    value: 'SN',
    label: 'Nota Simple',
    enum: 'SIMPLE_NOTE',
  },
  {
    value: 'I',
    label: 'Imágen',
    enum: 'IMAGE',
  },
];

function NewsForm(props) {
  const { news, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateNews = useMutation(
    UPDATE_NEWS,
    getOptions({
      mutationName: 'updateNews',
      modelName: 'news',
      message: 'Novedad actualizada con éxito.',
      enqueueSnackbar,
    }),
  );
  const createNews = useMutation(
    CREATE_NEWS,
    getOptions({
      mutationName: 'createNews',
      modelName: 'news',
      message: 'Novedad creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createNewsData }) {
        if (cache.data.data.ROOT_QUERY.listNews) {
          const { listNews } = cache.readQuery({
            query: LIST_NEWS,
          });
          listNews.edges.unshift({
            // Be careful with types
            __typename: 'NewsTypeEdge',
            node: createNewsData.createNews.news,
          });
          cache.writeQuery({
            query: LIST_NEWS,
            data: { listNews },
          });
        }
        history.push('/administrador/news');
      },
    }),
  );
  const deleteNews = useMutation(
    DELETE_NEWS,
    getOptions({
      mutationName: 'deleteNews',
      message: 'Novedad eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listNews) {
          const { listNews } = cache.readQuery({
            query: LIST_NEWS,
          });
          listNews.edges = listNews.edges.filter(e => e.node.id !== id);
          cache.writeQuery({
            query: LIST_NEWS,
            data: { listNews },
          });
        }
        history.push('/administrador/news');
      },
    }),
  );

  function formatBeforeSubmit(values) {
    const { newsImage, ...newValues } = { ...values };
    if (newsImage !== null) {
      newValues.newsImage = newsImage;
    }
    return newValues;
  }

  const data = [
    {
      label: 'Tipo',
      name: 'newsType',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: TYPES,
      mapData: { value: 'value', label: 'label' },
    },
    {
      label: 'Título',
      name: 'title',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Subítulo',
      name: 'subtitle',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Descripción',
      name: 'description',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Imágen',
      name: 'newsImage',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.image,
    },
    {
      label: 'Tiene botón?',
      name: 'hasButton',
      inputVariant: INPUT_VARIANT.singleCheckbox,
      gridMD: 6,
    },
    {
      label: 'Título del botón',
      name: 'buttonTitle',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Tiene link?',
      name: 'hasLink',
      inputVariant: INPUT_VARIANT.singleCheckbox,
      gridMD: 6,
    },
    {
      label: 'Link',
      name: 'link',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionNews}
        validateSchema={validationSchemaNews}
        formatBeforeSubmit={formatBeforeSubmit}
        data={data}
        model={news}
        id={id}
        modelName="Novedades"
        routeName="/administrador/news"
        isEdit={isEdit}
        updateMutation={updateNews}
        createMutation={createNews}
        deleteMutation={deleteNews}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

NewsForm.propTypes = {
  news: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

NewsForm.defaultProps = {
  news: null,
  id: '',
  isEdit: false,
};

export default NewsForm;
