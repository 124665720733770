import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import MaritalStatusForm from '../MaritalStatusForm';
import { GET_MARITAL_STATUS } from '../queries';
import { parseBool } from '../../../../utils/commonFunctions';

function MaritalStatusEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_MARITAL_STATUS, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error(error);
    history.goBack();
  }

  const formatValue = { ...data.maritalStatus };
  if (!data.maritalStatus) {
    history.goBack();
  }

  formatValue.spouse = parseBool(formatValue.spouse);

  return <MaritalStatusForm maritalStatus={formatValue} id={id} isEdit />;
}

MaritalStatusEdit.propTypes = {};

export default MaritalStatusEdit;
