import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 260;
export default makeStyles(theme => ({
  clientPage: {
    display: 'flex',
    background: '#fff',
    height: '100vh',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
      paddingTop: '4%',
    },
  },
  clientPageEmployee: {
    overflow: 'hidden',
    display: 'flex',
    background: '#f0f3f5',
    height: '100vh',
    // #efefef
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
      paddingTop: '4%',
    },
  },
  clientPageDark: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    background: '#2F2F2F',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
      paddingTop: '4%',
    },
  },

  userIcon: {
    border: 2,
    borderStyle: 'solid',
    color: theme.palette.primary.contrastText,
  },
  welcome: {
    fontWeight: 500,
  },
  icon: { minWidth: theme.spacing(5) },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '8%',
      paddingRight: '8%',
    },
  },
  appBarEmployee: {
    zIndex: theme.zIndex.drawer + 1,
    // border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: '4%',
      paddingRight: '6%',
    },
  },
  appBarEmployeeDark: {
    zIndex: theme.zIndex.drawer + 1,
    // border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#212121',
    // boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: '4%',
      paddingRight: '6%',
    },
  },
  appToolbar: {
    background: '#A6C1D3',
    display: 'flex',
    justifyContent: 'space-between',
  },
  appToolbarEmployee: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
  },
  appToolbarDark: {
    background: '#212121',
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarItem: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenDark: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenEmployee: {
    width: drawerWidth,
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerCloseLight: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) - 1,
    borderRadius: '10px',
    zIndex: 1202,
    // border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerCloseDark: {
    zIndex: 1202,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) - 2,
    borderRadius: '2px',

    // border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#212121',
    boxShadow: '1px 1px 4px 0px rgba(166, 193, 211, 0.20)',
    // boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaperBigScreen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.colors.gray,
    overflow: 'hidden',
  },
  drawerPaperBigScreenLight: {
    width: 'fit-content',
    zIndex: 9999,
    borderRadius: '10px',
    border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.colors.white,
    overflow: 'hidden',
  },
  drawerPaperBigScreenDark: {
    width: 'fit-content',
    zIndex: 9999,
    borderRadius: '2px',
    border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#212121',
    // boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    overflow: 'hidden',
  },
  drawerPaper: {
    width: 'fit-content',
    zIndex: 9999,
    backgroundColor: theme.palette.background.gray,
  },
  drawerPaperEmployee: {
    width: 'fit-content',
    zIndex: 9999,
    border: '2px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    backgroundColor: '#FFF',
    boxShadow: '2px 2px 4px 0px rgba(166, 193, 211, 0.50)',
  },
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    marginBottom: 20,
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1),
    },
    height: '100%',
    backgroundColor: '#ffffff',
    borderLeft: '2px solid',
    borderRight: '2px solid',
    borderLeftColor: theme.palette.colors.darkGray,
    borderRightColor: theme.palette.colors.darkGray,
  },

  contentLayout: {
    position: 'relative',
    background: 'transparent',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: '20px',
  },

  IconDiv: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  layoutLight: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '90%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      ' linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

    // 'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 76%, rgba(255, 255, 255, 0.00) 100%)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',
      width: '90vw',
    },
  },
  layoutDark: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '90%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      'linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.40) 100%)',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0px',
      justifyContent: 'space-between',
      flexDirection: 'column-reverse',
      width: '90vw',
    },
  },
  layoutLightForSections: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      ' linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

    // 'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 76%, rgba(255, 255, 255, 0.00) 100%)',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '90vw',
    },
  },
  layoutDarkForSections: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      'linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.40) 100%)',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      justifyContent: 'space-between',
      flexDirection: 'column',
      width: '90vw',
    },

    isotypeOpen: {
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    isotypeClose: {
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
    },
    menuDiv: {
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'end',
    },
  },
}));
