/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';
// Material Resources
import { useDispatch } from 'react-redux';
import { setModeClient } from 'src/store/utils-store/actions';
// Components & Others
import NewsModuleCards from './news/view';
import SectionsCards from './sections-card/view';
import Banners from './banners';
// Styles & Images
import useStyles from './styles';
import ModeIcon from './svg/modeIcon';
import EventsCard from './news/type-cards/event';

function InvestorCards(props) {
  const { urlF2 } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mode, setMode] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlerMode = state => {
    setMode(state);
    localStorage.setItem('mode', state);
    dispatch(setModeClient(state));
  };
  return (
    <>
      <div className={classes.layout}>
        {windowWidth <= 980 && (
          <div className={classes.modeDiv}>
            <div className={classes.modeIcon}>
              <div
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer' }}
                onClick={() => handlerMode(!mode)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handlerMode(!mode);
                  }
                }}
              >
                <ModeIcon />
              </div>
            </div>
          </div>
        )}

        <Banners urlF2={urlF2} />
        {/* windowWidth < 768  */}
        <SectionsCards urlF2={urlF2} />
        <NewsModuleCards />
        {windowWidth <= 980 && <EventsCard />}
      </div>
    </>
  );
}

export default InvestorCards;
