import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import {
  Button,
  Tooltip,
  Typography,
  Dialog,
  Toolbar,
  useMediaQuery,
  IconButton,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import CustomSubmodelEdit from '../CustomSubmodelCreateEdit/edit';
import CustomSubmodelForm from '../CustomSubmodelForm';

// Styles & Images
import useStyles from './styles';

function CustomToolbar(props) {
  const {
    addOnClick,
    submodelName,
    otherToolbarOption,
    disableAddButton,
  } = props;

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={disableAddButton}
        onClick={addOnClick}
      >
        {`Añadir ${submodelName}`}
      </Button>

      {otherToolbarOption && otherToolbarOption}
    </>
  );
}

CustomToolbar.propTypes = {
  addOnClick: PropTypes.func.isRequired,
  submodelName: PropTypes.string.isRequired,
  otherToolbarOption: PropTypes.node,
  disableAddButton: PropTypes.bool,
};

CustomToolbar.defaultProps = {
  otherToolbarOption: null,
  disableAddButton: false,
};

function CustomSubmodelList(props) {
  const {
    mainID,
    canBeDeleted,
    submodelName,
    submodelList,
    columns,
    otherToolbarOption,
    updateModelFunction,
    getQuery,
    formatQuery,
    disableAddButton,
    //Form props
    buttonLoader,
    mutationsData,
    startValues,
    validationSchema,
    validationFunction,
    formStructure,
    formatBeforeSubmit,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showForm, setShowForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [accountId, setAccountID] = useState('');

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      setAccountID(rowData[rowData.length - 1].props.id);
      setIsEdit(true);
      setShowForm(true);
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <CustomToolbar
          submodelName={submodelName}
          otherToolbarOption={otherToolbarOption}
          disableAddButton={disableAddButton}
          addOnClick={() => {
            setIsEdit(false);
            setShowForm(true);
          }}
        />
      );
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return (
    <>
      <MUIDataTable
        title={
          submodelName.split(' ').length > 1 ? submodelName : `${submodelName}s`
        }
        data={submodelList}
        columns={columns}
        options={options}
      />
      <Dialog
        open={showForm}
        onClose={() => true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth="lg"
      >
        <Toolbar className={classes.toolbar}>
          <Typography color="secondary" className={classes.title}>
            {`${submodelName}s`}
          </Typography>
          <IconButton
            variant="contained"
            color="secondary"
            onClick={() => setShowForm(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <div className={classes.formContainer}>
          {isEdit && accountId !== '' ? (
            <CustomSubmodelEdit
              submodelName={submodelName}
              getQuery={getQuery}
              formatQuery={formatQuery}
              canBeDeleted={canBeDeleted}
              id={accountId}
              afterEditCreate={() => setShowForm(false)}
              mutationsData={mutationsData}
              startValues={startValues}
              validationSchema={validationSchema}
              validationFunction={validationFunction}
              formStructure={formStructure}
              formatBeforeSubmit={formatBeforeSubmit}
              buttonLoader={buttonLoader}
            />
          ) : (
            <CustomSubmodelForm
              mainID={mainID}
              submodelName={submodelName}
              afterEditCreate={() => setShowForm(false)}
              mutationsData={mutationsData}
              updateModelFunction={updateModelFunction}
              startValues={startValues}
              validationSchema={validationSchema}
              validationFunction={validationFunction}
              formStructure={formStructure}
              formatBeforeSubmit={formatBeforeSubmit}
              buttonLoader={buttonLoader}
            />
          )}
        </div>
      </Dialog>
    </>
  );
}

CustomSubmodelList.propTypes = {
  mainID: PropTypes.string.isRequired,
  submodelName: PropTypes.string.isRequired,
  submodelList: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  updateModelFunction: PropTypes.func.isRequired,
  otherToolbarOption: PropTypes.node,
  buttonLoader: PropTypes.bool,
  canBeDeleted: PropTypes.bool,
  disableAddButton: PropTypes.bool,
};

CustomSubmodelList.defaultProps = {
  otherToolbarOption: null,
  canBeDeleted: true,
};

export default CustomSubmodelList;
