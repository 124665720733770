import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getClientMode from 'src/store/utils-store/selectors';
import useStyles from './styles';
const cards = [
  {
    section: 'Repositorios',
    cards: [{ title: 'Repositorio Personal', path: '/cliente/documentos' }],
    path: '',
  },
  {
    section: 'Documentos',
    cards: [
      { title: 'Ficha Cliente', path: '' },
      { title: 'Firmas Pendientes', path: '/cliente/firmas' },
      {
        title: 'Estado de Cuenta',
        path: '/cliente/estados-de-cuenta-de-inversiones',
      },
    ],
    path: '',
  },
];

export default function SectionsCards(props) {
  const { urlF2 } = props;
  const classes = useStyles();
  const history = useHistory();
  const modeClient = useSelector(getClientMode());
  const cardStyle = modeClient ? classes.cardDark : classes.card;
  const nameStyle = modeClient ? classes.nameCardDark : classes.nameCard;
  const titleStyle = modeClient ? classes.titleDark : classes.title;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {cards.map(item => {
          return (
            <>
              {item.cards.map(items => {
                return (
                  <>
                    <div
                      className={cardStyle}
                      onClick={() => {
                        if (items.title === 'Ficha Cliente') {
                          window.location.href = urlF2;
                        } else {
                          history.push(items.path);
                        }
                      }}
                    >
                      <p className={titleStyle}>{item.section}</p>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <p className={nameStyle}>{items.title}</p>
                      </div>
                    </div>
                    {/* </div> */}
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </div>
  );
}
