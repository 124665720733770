import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tableContainer: {
    paddingTop: '2rem',
  },
  modalBody: {
    padding: '1rem',
    overflowY: 'scroll',
  },
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  optionButtons: {
    minHeight: 30,
    minWidth: 30,
    padding: theme.spacing(0.5),
    '& .MuiButton-label': {
      color: theme.palette.secondary.main,
    },
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  greenIcon: {
    color: theme.palette.colors.green,
  },
  // Options separator
  separator: {
    display: 'inline-flex',
  },
  multilineCell: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
