import React, { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';
import Yup from '../../../../utils/yup';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { Grid, Button, Typography, Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import DatePickerField from '../../../FormComponents/DatePicker';

import SectionDivider from '../../../FormComponents/SectionDivider';
import { FILE_UPLOAD, LIST_TYPES_CHOICES, LIST_S3FILES } from '../queries';
import { LIST_FUNDS, LIST_BONDS } from '../../Fund/queries';
import { LIST_CLIENT_USERS } from '../../Users/queries';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import LoadingIndicator from '../../../LoadingIndicator';
import useStyles from './styles';

function ProcessFormFiles(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [clientList, setClientList] = useState([]);
  const [fundList, setFundList] = useState([]);
  const [bondList, setBondList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fundSelected, setFundSelected] = useState(false);
  const [bondSelected, setBondSelected] = useState(false);
  const [fichaReniecSelected, setFichaReniecSelected] = useState(false);
  const [fichaClienteSelected, setFichaClienteSelected] = useState(false);
  const [fileEncode, setFileEncode] = useState();
  const [fileName, setFileName] = useState('');
  const [limitGlobal, setLimitGlobal] = useState('');
  const [initialValues, setInitialValues] = useState({
    client: '',
    file: '',
    type: '',
    fund: '',
    bond: '',
  });
  const { loading: listClientLoading, error, data } = useQuery(
    LIST_CLIENT_USERS,
  );
  const { error: FundsError, data: FundsData } = useQuery(LIST_FUNDS);
  const { error: BondsError, data: BondsData } = useQuery(LIST_BONDS);
  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES);
  const [uploadFile, { loadingFile, dataFile }] = useMutation(
    FILE_UPLOAD,
    getOptions({
      enqueueSnackbar,
      refetchQueries: [
        {
          query: LIST_S3FILES,
          variables: {
            search: null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
    }),
  );

  useEffect(() => {
    if (!!data) {
      setClientList(getUsersByClients(edgeToList(data, 'listClients')));
    }
    if (!!FundsData) {
      setFundList(
        edgeToList(FundsData, 'listFunds').filter(item => item?.state === true),
      );
    }
    if (!!BondsData) {
      setBondList(edgeToList(BondsData, 'listBonds'));
    }
    if (!!TypesData) {
      setTypeList(TypesData.typesChoices);
    }
  }, [data, FundsData, BondsData, TypesData]);

  function getUsersByClients(clients) {
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user } = element;
      user = {
        ...user,
        clientId: element.id,
        fullName: user.firstName + ' ' + user.lastName,
      };
      return user;
    });
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async (e, propsForm) => {
    try {
      let name = e.target.files[0].name;
      let limit = e.target.files[0].size;
      const encoded = await toBase64(e.target.files[0]);
      propsForm.setFieldValue('file', encoded);
      setLimitGlobal(limit);
      setFileEncode(encoded);
      setFileName(name);
    } catch (error) {
      console.log('error', error);
    }
  };

  const validationSchema = Yup.object().shape({
    file: Yup.string().required(),
    client: Yup.string().required(),
    fund: Yup.string().when({
      is: () =>
        !fundSelected &&
        !bondSelected &&
        !fichaReniecSelected &&
        !fichaClienteSelected,
      then: Yup.string().required('Elige un Fondo o un Bono'),
    }),
    bond: Yup.string().when({
      is: () =>
        !fundSelected &&
        !bondSelected &&
        !fichaReniecSelected &&
        !fichaClienteSelected,
      then: Yup.string().required('Elige un Fondo o un Bono'),
    }),
    type: Yup.string().required(),
    csSigningDate: Yup.string().when({
      is: () => fichaClienteSelected,
      then: Yup.string().required(
        'Ingresa la fecha de firma de la Ficha Cliente',
      ),
    }),
  });

  return listClientLoading ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          uploadFile({
            variables: {
              input: {
                file: values.file,
                client: values.client.clientId,
                fund: values?.fund?.id,
                bond: values?.bond?.id,
                type: values.type,
                fileName: fileName,
                csSigningDate: values?.csSigningDate,
              },
            },
          });
          history.goBack();
        }}
      >
        {propsForm => (
          <Form className={classes.simpleForm}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Cargar Archivo
                </Typography>
                <SectionDivider />
                <Grid container alignItems="center">
                  <>
                    <Grid className={classes.labelContainer} item xs={6}>
                      <InputLabel>Archivo</InputLabel>
                      {fileName && (
                        <div>
                          <small>{fileName}</small>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        component="label"
                        color="primary"
                        name="file"
                        error={propsForm.errors.file}
                        required
                      >
                        Buscar Archivo
                        <input
                          id="file"
                          name="file"
                          type="file"
                          style={{ display: 'none' }}
                          onChange={e => handleChange(e, propsForm)}
                        />
                      </Button>
                      {limitGlobal / 1000000 > 100 && (
                        <div>
                          <small style={{ color: 'red' }}>
                            El límite es 100 MG, sumas {limitGlobal / 1000000}MG
                          </small>
                        </div>
                      )}
                      <ErrorMessage name="file">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="client"
                      fullWidth
                      value={propsForm.values.client}
                      error={propsForm.errors.client}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('client', value);
                      }}
                      options={clientList}
                      getOptionLabel={option =>
                        option.fullName ? option.fullName : ''
                      }
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.client}
                          value={propsForm.values.client}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Cliente"
                          placeholder="Cliente"
                        />
                      )}
                    />
                    <ErrorMessage name="client">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="fund"
                      fullWidth
                      value={propsForm.values.fund}
                      error={propsForm.errors.fund}
                      onChange={(e, value) => {
                        if (value == null) {
                          propsForm.setFieldValue('fund', '');
                          setFundSelected(false);
                        } else {
                          propsForm.setFieldValue('fund', value);
                          setFundSelected(true);
                        }
                      }}
                      options={fundList}
                      getOptionLabel={option =>
                        option.businessName ? option.businessName : ''
                      }
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.fund}
                          value={propsForm.values.fund}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Fondo"
                          placeholder="Fondo"
                        />
                      )}
                    />
                    <ErrorMessage name="fund">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="bond"
                      fullWidth
                      value={propsForm.values.bond}
                      error={propsForm.errors.bond}
                      onChange={(e, value) => {
                        if (value == null) {
                          propsForm.setFieldValue('bond', '');
                          setBondSelected(false);
                        } else {
                          propsForm.setFieldValue('bond', value);
                          setBondSelected(true);
                        }
                      }}
                      options={bondList}
                      getOptionLabel={option => {
                        if (option) {
                          return (
                            option?.name +
                            ' ' +
                            option?.program +
                            ' ' +
                            option?.release +
                            ' ' +
                            option?.className
                          );
                        } else return '';
                      }}
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.bond}
                          value={propsForm.values.bond}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Bono"
                          placeholder="Bono"
                        />
                      )}
                    />
                    <ErrorMessage name="bond">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="type">Tipo</InputLabel>
                    <Select
                      fullWidth
                      labelId="type"
                      name="type"
                      variant="outlined"
                      value={propsForm.values.type}
                      error={propsForm.errors.type}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('type', value.key);
                        if (value.key == 'FR') {
                          setFichaReniecSelected(true);
                        } else setFichaReniecSelected(false);
                        if (value.key == 'FC') {
                          setFichaClienteSelected(true);
                        } else setFichaClienteSelected(false);
                      }}
                    >
                      {typeList.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="type">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  {fichaClienteSelected && (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Field
                        name="csSigningDate"
                        label="Fecha de Firma"
                        variant="outlined"
                        component={DatePickerField}
                        fullWidth
                      />
                      <ErrorMessage name="csSigningDate">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )}
                  <SectionDivider />
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={
                          (loading && limitGlobal / 1000000 > 100) ||
                          (fundSelected && bondSelected)
                        }
                      >
                        Guardar
                      </Button>
                    </Grid>
                    {fundSelected && bondSelected && (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        justify="flex-end"
                        alignItems="right"
                      >
                        <p style={{ color: 'orange', fontWeight: 'bolder' }}>
                          Sólo seleccionar Fondo o Bono
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProcessFormFiles;
