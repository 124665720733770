import { gql } from 'apollo-boost';

export const FILE_UPLOAD = gql`
  mutation fileUpload($input: S3FileInput!) {
    fileUpload(input: $input) {
      success
    }
  }
`;

export const FILES_UPLOAD = gql`
  mutation filesUpload($input: S3FilesInput!) {
    filesUpload(input: $input) {
      success
      error
    }
  }
`;

export const FILE_DOWNLOAD = gql`
  mutation fileDownload($id: ID!, $isDownload: Boolean!) {
    fileDownload(id: $id, isDownload: $isDownload) {
      url
      fileName
    }
  }
`;

export const FILE_UPDATE = gql`
  mutation fileUpdate($id: ID!) {
    fileUpdate(id: $id) {
      s3file {
        id
        state
      }
    }
  }
`;

export const LIST_TYPES_CHOICES = gql`
  query listTypesChoices {
    typesChoices {
      name
      value
    }
  }
`;

export const LIST_TYPES_CHOICES_EMPLOYEE = gql`
  query listTypesChoices {
    typesChoicesEmployee {
      name
      value
    }
  }
`;

export const LIST_S3FILES = gql`
  query listS3Files(
    $search: String
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
  ) {
    listS3Files(
      state: true
      search: $search
      first: $first
      last: $last
      before: $beforeCursor
      after: $afterCursor
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          state
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          externalSigner {
            id
            firstName
            lastName
          }
          fund {
            id
            businessName
          }
          bond {
            id
            name
            program
            release
            className
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_CLIENT_SHEETS = gql`
  query listClientSheets {
    listClientSheets {
      edges {
        node {
          id
          state
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_S3FILES_BY_CLIENT = gql`
  query listS3FilesByClient($clientId: ID!) {
    listS3FilesByClient(clientId: $clientId) {
      edges {
        node {
          id
          state
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          externalSigner {
            id
            firstName
            lastName
          }
          fund {
            id
            businessName
          }
          bond {
            id
            name
            program
            release
            className
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_S3FILES_BY_EMPLOYEE = gql`
  query listS3FilesByEmployee($clientId: ID!) {
    listS3FilesByEmployee(clientId: $clientId) {
      edges {
        node {
          id
          state
          isGeneric
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          classification
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;
