import axios from 'axios';
import {
  REACT_APP_BASE_URL_BACKEND_INVESTOR,
  REACT_APP_BASE_URL_BACKEND_INVESTOR_API_KEY,
} from 'src/utils/constants';

const getUserPercentageF2 = async dniUser => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL_BACKEND_INVESTOR}/information/scoring?documentNumber=${dniUser}`,
      {
        headers: {
          'x-api-key': `${REACT_APP_BASE_URL_BACKEND_INVESTOR_API_KEY} `,
        },
      },
    );

    if (response.data) {
      return response.data.registrationPercentage;
    }
  } catch (error) {
    return error.response?.data || error;
  }
};

export default getUserPercentageF2;
