/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../../NotFoundPage';
import AdministratorList from '../../../../components/Models/Users/Administrator/AdministratorList';
import AdministratorEdit from '../../../../components/Models/Users/Administrator/AdministratorCreateEdit/edit';
import AdministratorForm from '../../../../components/Models/Users/Administrator/AdministratorForm';

function AdministratorPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AdministratorList} />
      <Route path={`${path}/crear`} component={AdministratorForm} />
      <Route path={`${path}/:id`} component={AdministratorEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default AdministratorPage;
