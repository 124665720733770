/*
 * RejectedInvestorPage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../../NotFoundPage';
import RejectedClientList from '../../../../components/Models/Users/RejectedClient/RejectedClientList';

function ClientPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={RejectedClientList} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default ClientPage;
