import React, { useEffect, useState } from 'react';

// Material Resources
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  TableCell,
  Grid,
  Button,
  TextField,
  Container,
  Tooltip,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// GraphQl
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes, { shape } from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useSnackbar } from 'notistack';
import { textLabels } from 'src/translations/components/MUIDataTable';
import {
  generateColumn,
  getOptions,
  edgeToList,
  errorMessage,
} from 'src/utils/commonFunctions';
import CustomColumnHeader from 'src/components/TableComponents/CustomColumnHeader';
import { Formik, Form } from 'formik';
import { UPDATE_PARTICIPANT, GET_PARTICIPANT_WHEELS_DATA } from './queries';
import InputNumber from 'src/components/FormComponents/InputNumber';
import SectionDivider from 'src/components/FormComponents/SectionDivider';
import { LIST_PARTICIPANT_RAISING } from '../queries';
import { GET_MAX_FEE_COUNT } from 'src/components/Cognito/queries';
import { Alert } from '@material-ui/lab';
import LoadingIndicator from 'src/components/LoadingIndicator';

// Styles & Images
import useStyles from './styles';
import NotFound from 'src/containers/NotFoundPage';
import CustomModal from 'src/components/CustomModal';
import { REACT_APP_SERVER_LINK } from 'src/utils/constants';
import NumberFormat from 'react-number-format';

function TabPanel(props) {
  const {
    participantion,
    participants,
    payments,
    clientID,
    raisingID,
    tabID,
    index,
    onClose,
    ...other
  } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [maxValue, setMaxValue] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [voucherPhoto, setVoucherPhoto] = useState('');

  const {
    loading: maxValueLoading,
    error: maxValueError,
    data: maxValueData,
  } = useQuery(GET_MAX_FEE_COUNT, {
    variables: { raisingId: raisingID },
  });

  if (maxValueError) {
    errorMessage(
      'Error al obtener el número máximo de coutas',
      enqueueSnackbar,
    );
  }
  useEffect(() => {
    if (maxValueData && maxValueData.totalFundFeeCount) {
      setMaxValue(maxValueData.totalFundFeeCount.fundFeeCount);
    }
  }, [maxValueData]);

  const [updateParticipant] = useMutation(
    UPDATE_PARTICIPANT,
    getOptions({
      mutationName: 'updateParticipant',
      modelName: 'participant',
      message: 'Participación actualizada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_PARTICIPANT_WHEELS_DATA, variables: { clientID } },
      ],
      completeCallback: () => {
        onClose();
      },
    }),
  );

  const paymentsColumns = [
    generateColumn('Monto', 'amount', {
      sort: true,
      filter: false,
      customBodyRender: value => (
        <NumberFormat displayType="text" thousandSeparator value={value} />
      ),
    }),
    generateColumn('Número de operación', 'operationNumber'),
    generateColumn('Banco', 'bank', {
      sort: true,
      filter: false,
      customBodyRender: value => `${value.name}`,
    }),
    generateColumn('Opciones', 'voucherPhoto', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setVoucherPhoto(value);
              setShowModal(true);
            }}
            disabled={!value}
          >
            Ver foto del voucher
          </Button>
        );
      },
    }),
  ];

  const participantsColumns = [
    generateColumn('Nombre', 'client', {
      sort: true,
      filter: false,
      customBodyRender: value =>
        `${value.user.firstName} ${value.user.lastName} ${value.user.maternalSurname}`,
    }),
    generateColumn('Tipo de documento', 'client', {
      sort: true,
      filter: false,
      customBodyRender: value => `${value.user.documentType.name}`,
    }),
    generateColumn('Número de documento', 'client', {
      sort: true,
      filter: false,
      customBodyRender: value => `${value.user.documentNumber}`,
    }),
    generateColumn('Correo', 'client', {
      sort: true,
      filter: false,
      customBodyRender: value => `${value.communicationEmail}`,
    }),
    generateColumn('Porcentaje de participación', 'participationPercentage', {
      sort: true,
      filter: false,
      customBodyRender: value => `${value} %`,
    }),
    {
      name: 'isRepresentative',
      label: 'Representante',
      options: {
        sort: false,
        customBodyRender: value => (
          <CheckCircleIcon color={value ? 'primary' : 'disabled'} />
        ),
        customHeadRender: columnMeta => {
          return (
            <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
  };

  return (
    <div
      role="tabpanel"
      hidden={tabID !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabID === index && (
        <Box p={3} className={classes.tabContent}>
          <Formik
            initialValues={{ fees: participantion.fees }}
            validate={values => {
              const errors = {};
              if (maxValue > 0 && values.fees > maxValue) {
                errors.fees = 'Ingrese un número de cuotas válido';
              }
              return errors;
            }}
            validateOnChange
            validateOnBlur
            onSubmit={(values, { setSubmitting }) => {
              updateParticipant({
                variables: {
                  id: participantion.id,
                  input: {
                    numFee: values.fees,
                    client: clientID,
                    wheel: participantion.weelID,
                  },
                },
              });
              setSubmitting(false);
            }}
          >
            {({ submitForm }) => {
              return (
                <Form>
                  <Grid container className={classes.titleContainer}>
                    <Typography variant="h6" className={classes.title}>
                      Participación
                    </Typography>
                    <SectionDivider />
                  </Grid>
                  {maxValue > 0 && (
                    <Grid
                      container
                      item
                      justify="center"
                      direction="row"
                      xs={12}
                    >
                      <Grid item xs={10} className={classes.infoSection}>
                        <Alert
                          variant="outlined"
                          color="warning"
                          severity="error"
                        >
                          {`El máximo número de cuotas a invertir es de ${maxValue}`}
                        </Alert>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    item
                    justify="flex-start"
                    direction="row"
                    xs={12}
                  >
                    <Grid item md={4} xs={4}>
                      <p className={classes.detailText}>Número de cuotas:</p>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <InputNumber
                        label="Número de cuotas:"
                        name="fees"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    justify="flex-start"
                    direction="row"
                    xs={12}
                  >
                    <Grid item md={4} xs={4}>
                      <p className={classes.detailText}>
                        Servicio de Riesgo Cambiario:
                      </p>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <p className={classes.responseText}>
                        {participantion.isRiskExchange ? 'Si' : 'No'}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.titleContainer}
                  >
                    <Typography variant="h6" className={classes.title}>
                      Pagos
                    </Typography>
                    <SectionDivider />
                  </Grid>

                  <MUIDataTable
                    data={payments}
                    columns={paymentsColumns}
                    options={options}
                  />

                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.titleContainer}
                  >
                    <Typography variant="h6" className={classes.title}>
                      Copartícipes
                    </Typography>
                    <SectionDivider />
                  </Grid>
                  <MUIDataTable
                    data={participants}
                    columns={participantsColumns}
                    options={options}
                  />

                  <Grid
                    container
                    justify="flex-end"
                    className={classes.titleContainer}
                    spacing={2}
                    xs={12}
                  >
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={onClose}
                      >
                        Cerrar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
      {voucherPhoto && (
        <CustomModal
          maxWidth="lg"
          showModal={showModal}
          title="Voucher"
          onClosePress={() => {
            setShowModal(false);
          }}
        >
          <div className={classes.photoDocuments}>
            <img
              alt="voucher"
              src={`${REACT_APP_SERVER_LINK}/media/${voucherPhoto}`}
            />
          </div>
        </CustomModal>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  participantion: PropTypes.shape().isRequired,
  participants: PropTypes.arrayOf(shape()).isRequired,
  raisingID: PropTypes.string.isRequired,
  clientID: PropTypes.string.isRequired,
  tabID: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ParticipantDetail(props) {
  const { clientID, raisingID, onClose } = props;
  const classes = useStyles();
  const [participantionsData, setParticipantionData] = useState([]);
  const [tabID, setTabID] = useState(0);

  const {
    loading: participantionsDataLoading,
    error: participantionsDataError,
    data: participantionDataAll,
  } = useQuery(GET_PARTICIPANT_WHEELS_DATA, {
    variables: { clientID },
  });

  useEffect(() => {
    if (participantionDataAll) {
      const participantions = edgeToList(
        participantionDataAll,
        'listParticipant',
      );
      const filteredParticipations = participantions.filter(
        p => p.wheel?.raising?.id === raisingID,
      );

      const formatedParticipantions = filteredParticipations
        .sort((a, b) => {
          return a.wheel.number - b.wheel.number;
        })
        .map(p => {
          const formatedW = { ...p };
          let participants = [];

          const mainClient = {
            client: p.client,
            participationPercentage: p.participationPercentage,
            isRiskExchange: p.isRiskExchange,
            isRepresentative: p.isRepresentative,
          };
          participants.push(mainClient);
          participants = participants.concat(
            edgeToList(formatedW, 'coParticipants'),
          );
          formatedW.participants = participants;

          formatedW.paymentSet = edgeToList(formatedW, 'paymentSet');

          return formatedW;
        });

      setTabID(formatedParticipantions.length - 1);
      setParticipantionData(formatedParticipantions);
    }
  }, [participantionDataAll]);

  const handleChange = (event, newValue) => {
    setTabID(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.container}>
      {participantionsDataLoading ? (
        <LoadingIndicator />
      ) : participantionsDataError ? (
        <Grid item xs={12}>
          <h4 className={classes.error}>
            Ocurrió un error, inténtelo más tarde
          </h4>
        </Grid>
      ) : (
        <>
          <AppBar position="static">
            <Tabs
              value={tabID}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              {participantionsData.map(p => (
                <Tab
                  key={p.wheel.number}
                  label={`Rueda ${p.wheel.number}`}
                  {...a11yProps(p.wheel.number - 1)}
                />
              ))}
            </Tabs>
          </AppBar>
          {participantionsData.map((p, index) => (
            <TabPanel
              key={p.wheel.number}
              participantion={{
                id: p.id,
                fees: p.numFee,
                isRiskExchange: p.isRiskExchange,
                weelID: p.wheel.id,
              }}
              clientID={clientID}
              raisingID={raisingID}
              participants={p.participants}
              payments={p.paymentSet}
              tabID={tabID}
              index={index}
              onClose={onClose}
            />
          ))}
        </>
      )}
    </div>
  );
}

ParticipantDetail.propTypes = {
  raisingID: PropTypes.string.isRequired,
  clientID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ParticipantDetail;
