import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Yup from '../../utils/yup';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Icon,
  Typography,
  Button,
  Container,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';

import Logo from '../../images/brand/CoreCapitalSAFI_LogoRGB_positivo.png';
import useStyles from './styles';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThreeForm from './StepThreeForm';
import { successMessage, errorMessage } from '../../utils/commonFunctions';
import { SUBMIT_RAISING_PARTICIPATION, GET_FUND } from './queries';
import StepFourForm from './StepFourForm';

function CustomStepIcon(props) {
  const { children, active, completed } = props;
  const styles = useStyles();

  const classes = cx(
    styles.icon,
    active || completed ? styles.active : styles.inactive,
  );

  return (
    <Icon className={classes}>
      <Typography variant="inherit">{children}</Typography>
    </Icon>
  );
}

CustomStepIcon.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};

const validationSchema = Yup.object().shape({
  clientID: Yup.string().required(),
  clientName: Yup.string().required(),
  documentNumber: Yup.string().required(),
  classFund: Yup.string().required(),
  participationPercentage: Yup.number().required(),
  isRepresentative: Yup.bool().required(),
  communicationCellphone: Yup.string().required(),
  communicationEmail: Yup.string()
    .email()
    .required(),
  participationAmountOrFee: Yup.string().required(),
  investmentValue: Yup.number()
    .positive('Ingrese un valor positivo mayor a cero')
    .required(),
  riskExchange: Yup.bool().required(),
  hasCoParticipation: Yup.bool().required(),
});

const STEP_PERSONAL_DATA = 3;
const FINAL_STEP = 4;

function CognitoForm(props) {
  const steps = ['C', 'O', 'R', 'E'];
  const { repurchase } = props;
  const { fund } = repurchase;

  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [mainParticipant, setMainParticipant] = useState({
    documentNumber: '',
    clientID: '',
    clientName: '',
    participationPercentage: 0,
    isRepresentative: true,
    communicationCellphone: '',
    communicationEmail: '',
    mainUser: [], // Accept only verified users
  });

  const formInitialValues = {
    participationAmountOrFee: 'fee',
    investmentValue: 1,
    feeCount: 0,
    riskExchange: false,
    hasCoParticipation: false,
    participationPercentage: 100,
    isRepresentative: true,
    communicationCellphone: '',
    communicationEmail: '',
    coparticipants: [],
    mainUser: [],
    classFund: '',
  };

  const { data: infoFund } = useQuery(GET_FUND, {
    variables: { id: fund.id },
  });

  const [submitData] = useMutation(SUBMIT_RAISING_PARTICIPATION, {
    onError: error => {
      if (error.message.match('duplicate')) {
        errorMessage(
          'El participante ya es parte de esta recompra en la rueda activa.',
          enqueueSnackbar,
        );
      } else {
        error.graphQLErrors.map(function({ message }, i) {
          errorMessage(message, enqueueSnackbar);
        });
      }
    },
    onCompleted: ({ createParticipationRepurchase }) => {
      if (createParticipationRepurchase.created) {
        successMessage('Participación creada con éxito.', enqueueSnackbar, {
          callback: () => setSubmitted(true),
        });
        setActiveStep(activeStep + 1);
      }
    },
  });

  const getCurrentStep = participants => {
    switch (activeStep) {
      case 0:
        return <StepOneForm repurchase={repurchase} />;
      case 1:
        return <StepTwoForm repurchase={repurchase} data={participants} />;
      case 2:
        return (
          <StepThreeForm
            repurchase={repurchase}
            data={participants}
            mainParticipant={mainParticipant}
          />
        );
      case 3:
        return <StepFourForm repurchase={repurchase} />;
      default:
        return <></>;
    }
  };

  const getFundType = () => {
    switch (fund.fundType) {
      case 'PR':
        return 'Privada';
      case 'PB':
        return 'Pública';
      default:
        return '';
    }
  };

  const getStepTitle = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <b className={styles.stepLabel}>Paso 1:</b> Información de la
            recompra de cuotas del {fund.businessName}
          </>
        );
      case 1:
        return (
          <>
            <b className={styles.stepLabel}>Paso 2:</b> Información del
            partícipe
          </>
        );
      case 2:
        return (
          <>
            <b className={styles.stepLabel}>Paso 3:</b> Datos sobre la
            participación de la recompra de cuotas del {fund.businessName}
          </>
        );
      case 3:
        return (
          <>
            <b className={styles.stepLabel}>Paso 4:</b> Confirmación de los
            datos
          </>
        );
      case 4:
        return (
          <>
            Muchas gracias por realizar su registro para la recompra de cuotas
            del <b className={styles.stepLabel}> {fund.businessName}</b>, nos
            contactaremos con usted para comunicarle el resultado de la
            adjudicación.
          </>
        );
      default:
        return '';
    }
  };

  const nextStep = form => {
    const { values, setFieldValue } = form;
    if (activeStep === 0) {
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      const participantData = {
        documentType: values.documentType,
        documentNumber: values.documentNumber,
        clientID: values.clientID,
        clientName: values.clientName,
        communicationCellphone: values.communicationCellphone,
        communicationEmail: values.communicationEmail,
        participationPercentage: 100,
        isRepresentative: true,
      };
      setMainParticipant(participantData);
      setFieldValue(
        'coparticipants',
        form.values.coparticipants.length
          ? form.values.coparticipants
          : [participantData],
      );
      delete form.errors.classFund;
      if (Object.keys(form.errors).length === 0) {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 2) {
      if (infoFund?.fund.hasClasses) {
        if (form.errors.classFund) {
          setActiveStep(activeStep);
        } else {
          setActiveStep(activeStep + 1);
        }
      } else {
        setActiveStep(activeStep + 1);
      }
    }
  };

  const prevStep = form => {
    if (activeStep === 2) {
      Object.keys(mainParticipant).map(k =>
        form.setFieldValue(k, mainParticipant[k]),
      );
    }
    form.setErrors({});
    setActiveStep(activeStep - 1);
  };

  const onSubmit = values => {
    const participants = values.coparticipants.map(p => ({
      clientId: p.clientID,
      participationPercentage: p.participationPercentage,
      isRepresentative: p.isRepresentative,
      communicationCellphone: p.communicationCellphone,
      communicationEmail: p.communicationEmail,
    }));
    submitData({
      variables: {
        repurchaseId: repurchase.id,
        input: {
          feeNumber: values.feeCount,
          isRiskExchange: values.riskExchange,
          participants,
          classFund: values.classFund,
        },
      },
    });
  };

  return (
    <Grid container classes={{ root: styles.container }} justify="center">
      <Grid container item className={styles.header} justify="center">
        <img src={Logo} alt="Logo Dorado" className={styles.image} />
      </Grid>
      <Grid container className={styles.steps}>
        <Stepper
          activeStep={activeStep}
          className={styles.stepper}
          connector={null}
        >
          {steps.map(label => (
            <Step key={label} classes={{ horizontal: styles.horizontal }}>
              <StepLabel
                classes={{
                  active: styles.active,
                  completed: styles.completed,
                  root: styles.icon,
                }}
                StepIconComponent={CustomStepIcon}
                StepIconProps={{ children: label }}
              />
            </Step>
          ))}
        </Stepper>
        <Grid container className={styles.title} justify="center">
          Recompra de Cuotas
        </Grid>
        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
        >
          {form => {
            return (
              <Container>
                <Typography align="center">{getStepTitle()}</Typography>
                <Form>
                  {getCurrentStep(form.values.coparticipants)}
                  <Grid
                    container
                    x1={12}
                    direction="row"
                    className={styles.buttons}
                  >
                    <Grid container item xs={6} justify="flex-start">
                      {activeStep > 0 && activeStep !== FINAL_STEP ? (
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={submitted}
                          onClick={() => prevStep(form)}
                        >
                          Volver
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid container item xs={6} justify="flex-end">
                      {activeStep === STEP_PERSONAL_DATA ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => onSubmit(form.values)}
                        >
                          Subir
                        </Button>
                      ) : activeStep < FINAL_STEP ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => nextStep(form)}
                        >
                          Siguiente
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
}

CognitoForm.propTypes = {
  repurchase: PropTypes.object.isRequired,
};

export default CognitoForm;
