import Yup from '../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

const userTypes = [
  { token: 'PN', id: 'PERSONA_NATURAL', name: 'Persona natural' },
  { token: 'PJ', id: 'PERSONA_JURIDICA', name: 'Persona jurídica' },
  { token: 'AU', id: 'PERSONA_AUTONOMA', name: 'Persona autónoma' },
];

export const startData = {
  promoterCompany: '',
  promoter: '',
  coreConsultant: '',
  documentType: '',
  documentNumber: '',
  firstName: '',
  lastName: '',
  maternalSurname: '',
  email: '',
  cellphone: '',
  userType: '',
  leadsProducts: [],
  // leadsChannel: '',
  // leadsChannelDetail: '',
  leadsRange: '',
  canalActual: '',
  canalOrigen: '',
  enviarComunicacion: '',
  nombreComunicacion: '',
  nombreCanalActual: '',
  nombreCanalOrigen: '',
  correoIntermediario: '',
  sex: '',
  photoDocumentFront: null,
  photoDocumentBack: null,
};

export const formStructure = (
  isEdit,
  isOnlyCore,
  promoterCompanies,
  promoters,
  corePromoters,
  documentTypes,
  leadsProducts,
  onChangePromoterCompany,
  onChangeDocumentType,
  isNational,
  // leadsChannels,
  // leadsChannelsDetail,
  leadsRanges,
  canalActual,
  canalOrigen,
) => [
  {
    label: 'Empresa Promotora',
    name: 'promoterCompany',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: promoterCompanies,
    mapData: { value: 'id', label: 'businessName' },
    onChange: onChangePromoterCompany,
  },
  {
    label: 'Promotor',
    name: 'promoter',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: promoters,
    mapData: { value: 'id', label: 'fullName' },
    disabled: isOnlyCore,
  },
  {
    label: 'Asesor de Core',
    name: 'coreConsultant',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: corePromoters,
    mapData: { value: 'id', label: 'fullName' },
  },
  {
    label: 'Nombres',
    name: 'firstName',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
    disabled: isEdit,
  },
  {
    label: 'Apellido Paterno',
    name: 'lastName',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
    disabled: isEdit,
  },
  {
    label: 'Apellido Materno',
    name: 'maternalSurname',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
    disabled: isEdit,
  },
  {
    label: 'Tipo de documento',
    name: 'documentType',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.select,
    data: documentTypes,
    mapData: { value: 'id', label: 'name' },
    disabled: isEdit,
    onChange: onChangeDocumentType,
  },
  {
    label: 'Número de documento',
    name: 'documentNumber',
    gridMD: 6,
    inputVariant: isNational ? INPUT_VARIANT.number : INPUT_VARIANT.text,
    disabled: isEdit,
  },
  {
    label: 'Email',
    name: 'email',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Celular',
    name: 'cellphone',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Rol',
    name: 'userType',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.select,
    data: userTypes,
    mapData: { value: 'id', label: 'name' },
  },
  // {
  //   label: 'Canal',
  //   name: 'leadsChannel',
  //   inputVariant: INPUT_VARIANT.select,
  //   gridMD: 6,
  //   data: leadsChannels,
  //   mapData: { value: 'value', label: 'name' },
  // },
  // {
  //   label: 'Detalle Canal',
  //   name: 'leadsChannelDetail',
  //   inputVariant: INPUT_VARIANT.select,
  //   gridMD: 6,
  //   data: leadsChannelsDetail,
  //   mapData: { value: 'value', label: 'name' },
  // },
  {
    label: 'Rangos',
    name: 'leadsRange',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: leadsRanges,
    mapData: { value: 'value', label: 'name' },
  },
  {
    label: 'Género',
    name: 'sex',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: [
      { value: 'M', name: 'Masculino' },
      { value: 'F', name: 'Femenino' },
    ],
    mapData: { value: 'value', label: 'name' },
  },
  {
    label: 'Foto del anverso del documento',
    name: 'photoDocumentFront',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.image,
  },
  {
    label: 'Foto del reverso del documento',
    name: 'photoDocumentBack',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.image,
  },
  {
    isField: false,
    section: 'Radiografía',
    fields: [
      {
        label: 'Nombre de Comunicación',
        name: 'nombreComunicacion',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Enviar Comunicación',
        name: 'enviarComunicacion',
        inputVariant: INPUT_VARIANT.select,
        gridMD: 6,
        data: [
          { value: true, name: 'Si' },
          { value: false, name: 'No' },
        ],
        mapData: { value: 'value', label: 'name' },
      },
      {
        label: 'Nombre Canal Actual',
        name: 'nombreCanalActual',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Canal Actual',
        name: 'canalActual',
        inputVariant: INPUT_VARIANT.select,
        gridMD: 6,
        data: canalActual,
        mapData: { value: 'value', label: 'name' },
      },
      {
        label: 'Nombre Canal Origen',
        name: 'nombreCanalOrigen',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Canal de Origen',
        name: 'canalOrigen',
        inputVariant: INPUT_VARIANT.select,
        gridMD: 6,
        data: canalOrigen,
        mapData: { value: 'value', label: 'name' },
      },
      {
        label: 'Correo Intermediario',
        name: 'correoIntermediario',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
    ],
  },
  {
    isField: false,
    section: 'Productos de Interés',
    fields: [
      {
        name: 'fundRelationText',
        text:
          'A continuación indique los productos de su interés (Puedes marcar más de una opción): ',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.paragraph,
        type: 'relation',
      },
      {
        label: '',
        data: leadsProducts,
        name: 'leadsProducts',
        mapData: { value: 'id', label: 'name' },
        inputVariant: INPUT_VARIANT.checkbox,
        other: false,
        gridMD: 12,
      },
    ],
  },
];

export const bankAccountValidationSchema = Yup.object().shape({});

export const bankAccountFormStructure = [];
