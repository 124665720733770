import React, { useEffect, useState } from 'react';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaymentIcon from '@material-ui/icons/Payment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DescriptionIcon from '@material-ui/icons/Description';

// GraphQl
import { useLazyQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import CustomOptions from '../../../../../CustomOptions';
import LoadingIndicator from '../../../../../LoadingIndicator';
import { useSnackbar } from 'notistack';
import {
  getOptions,
  generateColumn,
  successMessage,
  errorMessage,
  edgeToList,
  decimalAdjust,
  downloadBase64xlsx,
  downloadBase64Document,
} from '../../../../../../utils/commonFunctions';
import { textLabels } from '../../../../../../translations/components/MUIDataTable';
import {
  LIST_PARTICIPANT_RAISING,
  DELETE_PARTICIPANT_RAISING,
  ADJUDICATE_PARTICIPANT_RAISING,
  VERIFY_PARTICIPANT_PAYMENT,
  PARTICIPANT_RAISING_REPORT,
  GET_PARTICIPANT_CONTRACT,
  RAISING_ORDER,
  GENERATE_CONTRACT,
} from './queries';
import CustomModal from 'src/components/CustomModal';
import ParticipantDetail from './ParticipantDetail';
import PDFViewerModal from '../../../../../PDFViewerModal';

// Styles & Images
import useStyles from '../../styles';

export const PARTICIPANT_STATES = {
  NA: 'No adjudicado',
  AD: 'Adjudicado',
  LP: 'Pago ligado',
  PV: 'Pago Verificado',
  SG: 'Firmado',
  DS: 'Declaración Jurada firmada',
};

export const PARTICIPANT_STATES_VALUES = {
  NO_ADJUDICADO: 'NA',
  ADJUDICADO: 'AD',
  PAGO_LIGADO: 'LP',
  PAGO_VERIFICADO: 'PV',
  FIRMADO: 'SG',
  DECLARACION_JURADA_FIRMADA: 'DS',
};

function RaisingsParticipants(props) {
  const { raisingID } = props;
  const classes = useStyles();
  const [participationList, setParticipationList] = useState([]);
  const [parserParticipationList, setParserParticipationList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [clientID, setClientID] = useState(null);
  const [participantID, setParticipantID] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [
    getPreview,
    { loading: previewLoading, error: previewError, data: previewData },
  ] = useLazyQuery(RAISING_ORDER, {
    variables: { raisingId: raisingID, clientId: participantID },
  });

  const [getParticipants, { error, loading }] = useLazyQuery(
    LIST_PARTICIPANT_RAISING,
    {
      fetchPolicy: 'no-cache',
      variables: { raising: raisingID },
      onCompleted: data => {
        if (data) {
          const participationListTmp = edgeToList(
            data,
            'listParticipantRaising',
          )
            .filter(({ lastParticipation }) => !!lastParticipation)
            .map(participation => {
              const {
                lastParticipation,
                createdDate,
                numFee,
                amount,
                client,
                raising,
                classFund,
                validated,
                participationType,
              } = participation;
              setCurrencySymbol(raising.fund.currency.symbol);
              const newParticipation = {
                options: {
                  id: lastParticipation.id,
                  state: lastParticipation.state,
                  validated: validated,
                  participationType: participationType,
                  participantID: client.id,
                  contractGenerated: lastParticipation.contractGenerated,
                },
                order: lastParticipation.order,
                clientID: client.id,
                state: lastParticipation.state,
                dateEntry: createdDate,
                quotes: numFee,
                validated: validated,
                participationType: participationType,
                classFund: classFund ? classFund.name : '',
                amount: decimalAdjust('ceil', amount, -2),
                participants: [],
                percentajes: [],
              };
              newParticipation.participants.push(client.user);
              newParticipation.percentajes.push(
                lastParticipation.participationPercentage,
              );
              edgeToList(lastParticipation, 'coParticipants').forEach(
                ({ client: subClient, participationPercentage }) => {
                  newParticipation.participants.push(subClient.user);
                  newParticipation.percentajes.push(participationPercentage);
                },
              );
              return newParticipation;
            });
          setParticipationList(participationListTmp);
        }
      },
    },
  );

  useEffect(() => {
    raisingID && getParticipants();
  }, [raisingID]);

  // change number order in list
  useEffect(() => {
    if (participationList && participationList.length > 0) {
      let cont = 0;
      const resultParser = participationList.map(item => {
        cont += 1;
        return {
          ...item,
          order: cont,
        };
      });
      setParserParticipationList(resultParser);
    } else {
      setParserParticipationList([]);
    }
  }, [participationList]);

  const [deleteParticipantRaising] = useMutation(
    DELETE_PARTICIPANT_RAISING,
    getOptions({
      mutationName: 'deleteParticipant',
      modelName: 'participant',
      message: 'Participante eliminado con éxito.',
      enqueueSnackbar,
      completeCallback: () => getParticipants(),
    }),
  );

  const [
    adjudicateParticipantRaising,
    { loading: loadingAdjudicate },
  ] = useMutation(
    ADJUDICATE_PARTICIPANT_RAISING,
    getOptions({
      mutationName: 'adjudication',
      modelName: 'participation',
      message: 'Participante adjudicado con éxito.',
      enqueueSnackbar,
      completeCallback: () => getParticipants(),
    }),
  );

  const [generateContract, { loading: loadingGenerateContract }] = useMutation(
    GENERATE_CONTRACT,
    {
      onCompleted(data) {
        getParticipants();
        if (data?.generateParticipantContract?.created) {
          successMessage('Contrato generado con éxito', enqueueSnackbar);
        } else {
          errorMessage(
            'El cliente aún no firmó su ficha cliente ó caducó la fecha de su ficha cliente',
            enqueueSnackbar,
          );
        }
      },
      onError(error) {
        errorMessage(error, enqueueSnackbar);
      },
    },
  );

  const [verifyParticipantPayment, { loading: loadingVerify }] = useMutation(
    VERIFY_PARTICIPANT_PAYMENT,
    getOptions({
      mutationName: 'updateParticipantStatusVerify',
      modelName: 'participant',
      message: 'Pago verificado con éxito.',
      enqueueSnackbar,
      completeCallback: () => getParticipants(),
    }),
  );

  const [getCsv, { loading: loadingReport }] = useLazyQuery(
    PARTICIPANT_RAISING_REPORT,
    {
      fetchPolicy: 'no-cache',
      onCompleted(data) {
        if (
          data.participantRaisingReport.csv &&
          data.participantRaisingReport.filename
        ) {
          downloadBase64xlsx(
            data.participantRaisingReport.csv,
            data.participantRaisingReport.filename,
          );
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al solicitar el archivo, inténtelo más tarde',
          enqueueSnackbar,
        );
      },
    },
  );

  const [getContract, { loading: loadingContractDownload }] = useLazyQuery(
    GET_PARTICIPANT_CONTRACT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: pdfResponse => {
        if (pdfResponse.contractPdf) {
          downloadBase64Document(
            `b'${pdfResponse.contractPdf.pdf}'`,
            pdfResponse.contractPdf.filename,
            'pdf',
          );
        }
      },
      onError(error) {
        errorMessage(
          'Ocurrió un error al solicitar el archivo, inténtelo más tarde',
          enqueueSnackbar,
        );
      },
    },
  );

  const columnOptions = {
    filter: false,
    sort: true,
  };

  const columns = [
    generateColumn('Orden', 'order', {
      customBodyRender: value => <span>{value}</span>,
    }),
    generateColumn('Nombres y Apellidos', 'participants', {
      ...columnOptions,
      customBodyRender: participants => (
        <div className={classes.multilineCell}>
          {participants.map(({ firstName, lastName, maternalSurname }) => (
            <span>{`${firstName} ${lastName} ${maternalSurname}`}</span>
          ))}
        </div>
      ),
    }),
    generateColumn('Clase', 'classFund'),
    generateColumn('Cuotas', 'quotes'),
    generateColumn('Monto', 'amount', {
      ...columnOptions,
      customBodyRender: amount => (
        <>
          {`${currencySymbol} `}
          <NumberFormat
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator
            value={amount}
          />
        </>
      ),
    }),
    generateColumn('Fecha y Hora', 'dateEntry', {
      ...columnOptions,
      customBodyRender: value => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    }),
    generateColumn('Porcentaje', 'percentajes', {
      ...columnOptions,
      customBodyRender: percentajes => (
        <div className={classes.multilineCell}>
          {percentajes.map(percentaje => (
            <span>{percentaje}%</span>
          ))}
        </div>
      ),
    }),
    generateColumn('Estado', 'state', {
      ...columnOptions,
      customBodyRender: state => PARTICIPANT_STATES[state],
    }),
    generateColumn('Validada', 'validated', {
      ...columnOptions,
      customBodyRender: validated => (validated ? 'Si' : 'No'),
    }),
    generateColumn('Opciones', 'options', {
      ...columnOptions,
      customBodyRender: value => {
        const {
          id,
          state,
          validated,
          participationType,
          participantID,
          contractGenerated,
        } = value;
        let options = ['ocustom'];
        if (state === 'NA' && !contractGenerated) {
          options.push('odelete');
        }
        return (
          <CustomOptions
            id={id}
            model="Participante"
            options={options}
            handleDelete={() => {
              deleteParticipantRaising({ variables: { id } });
            }}
            fullWidth
            customOption={
              <>
                <Tooltip arrow title="Orden de Compra" placement="top">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {
                      setParticipantID(participantID);
                      getPreview();
                      setPreviewModal(true);
                    }}
                  >
                    <PageviewOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Generar Contrato" placement="top">
                  <span>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      disabled={
                        loadingGenerateContract ||
                        !validated ||
                        contractGenerated ||
                        participationType == 'CP'
                      }
                      onClick={() => {
                        generateContract({ variables: { id } });
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip arrow title="Adjudicar" placement="top">
                  <span>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      disabled={
                        !(state === PARTICIPANT_STATES_VALUES.NO_ADJUDICADO) ||
                        loadingAdjudicate ||
                        !validated
                      }
                      onClick={() => {
                        adjudicateParticipantRaising({ variables: { id } });
                      }}
                    >
                      <VerifiedUserIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip arrow title="Verificar Pago" placement="top">
                  <span>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      disabled={
                        !(state === PARTICIPANT_STATES_VALUES.PAGO_LIGADO) ||
                        loadingVerify
                      }
                      onClick={() => {
                        verifyParticipantPayment({ variables: { id } });
                      }}
                    >
                      <PaymentIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {state === PARTICIPANT_STATES_VALUES.PAGO_VERIFICADO && (
                  <Tooltip arrow title="Ver contrato" placement="top">
                    <span>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.optionButtons}
                        disabled={loadingContractDownload}
                        onClick={() => {
                          getContract({ variables: { participantId: id } });
                        }}
                      >
                        <AssignmentTurnedInIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell
            id={columnMeta.index}
            key={columnMeta.index}
            style={{ borderBottom: 'none' }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    }),

    {
      name: 'clientID',
      label: 'Cliente',
      options: {
        sort: false,
        display: false,
        customBodyRender: value => {
          const id = value;
          return <CustomOptions id={id} model="Wheel" options={[]} />;
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: true,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Descargar reporte" style={{ order: -1 }}>
            <IconButton
              color="secondary"
              disabled={loadingReport}
              onClick={() => {
                getCsv({ variables: { raising: raisingID } });
              }}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    onRowClick: rowData => {
      if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
        setClientID(rowData[rowData.length - 1].props.id);
        setShowModal(true);
      }
    },
  };

  return (
    <div className={classes.tableContainer}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        raisingID && (
          <>
            <MUIDataTable
              title="Participantes"
              data={parserParticipationList}
              columns={columns}
              options={options}
            />
            {clientID && (
              <CustomModal
                maxWidth="lg"
                showModal={showModal}
                title="Detalle del participante"
                onClosePress={() => {
                  setShowModal(false);
                }}
                fullWidth
              >
                <ParticipantDetail
                  raisingID={raisingID}
                  clientID={clientID}
                  onClose={() => {
                    setShowModal(false);
                    getParticipants();
                  }}
                />
              </CustomModal>
            )}
            <PDFViewerModal
              showModal={previewModal}
              onClosePress={() => setPreviewModal(false)}
              fileB64={
                previewData &&
                previewData.raisingOrder &&
                `data:application/pdf;base64,${previewData.raisingOrder.pdf}`
              }
              loading={previewLoading}
            />
          </>
        )
      )}
    </div>
  );
}

RaisingsParticipants.propTypes = {
  raisingID: PropTypes.string.isRequired,
};

export default RaisingsParticipants;
