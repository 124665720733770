import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  gray: {
    backgroundColor: theme.palette.colors.gray,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.gray,
    },
  },
  orange: {
    backgroundColor: theme.palette.colors.orange,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.orange,
    },
  },
  stateButton: {
    padding: 0,
    minWidth: 0,
  },
  tableTitle: {
    fontWeight: 500,
  },
}));
