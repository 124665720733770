import React from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import useStyles from './styles';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'src/utils/storage';
import { getClientSelector } from 'src/store/sharedStore/selectors';
import getClientMode from 'src/store/utils-store/selectors';

function CustomModal(props) {
  const user = useSelector(getClientSelector());
  const authUser = getAuthUser();
  const isNormalUser = user && authUser.usergroup === 'client_group';
  const modeClient = useSelector(getClientMode());
  const {
    showModal,
    title,
    onClosePress,
    children,
    maxWidth,
    fullWidth,
    hideScroll,
    hideClose,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const TitleTheme = modeClient ? classes.titleDark : classes.titleLight;
  return (
    <Dialog
      open={showModal}
      onClose={() => false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
      maxWidth={maxWidth || 'lg'}
      fullWidth={fullWidth}
      scroll="body"
    >
      {isNormalUser ? (
        <div className={classes.customToolbar}>
          <Typography className={TitleTheme}>{title}</Typography>
          {!hideClose && (
            <IconButton
              variant="contained"
              color={modeClient ? 'primary' : 'secondary'}
              onClick={onClosePress}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
      ) : (
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title}>{title}</Typography>
          {!hideClose && (
            <IconButton
              variant="contained"
              color="secondary"
              onClick={onClosePress}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      )}

      <div className={!hideScroll && classes.children}>{children}</div>
    </Dialog>
  );
}

CustomModal.propTypes = {
  fullWidth: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClosePress: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
  hideScroll: PropTypes.bool,
  hideClose: PropTypes.bool,
};

CustomModal.defaultProps = {
  fullWidth: false,
  maxWidth: '',
  hideScroll: false,
  hideClose: false,
};

export default CustomModal;
