// GraphQl
import { gql } from 'apollo-boost';

export const LIST_FUNDS = gql`
  {
    listFunds {
      edges {
        node {
          id
          businessName
          ruc
          address
          nominalFeeValue
          state
          hasClasses
          logo
          currency {
            symbol
          }
        }
      }
    }
  }
`;

export const LIST_CLASS_FUND = gql`
  {
    listClassFund {
      edges {
        node {
          id
          name
          state
          minimumTir
          maximumTir
          geographicalFocus
          assetClass
        }
      }
    }
  }
`;

export const GET_CLASS_FUND = gql`
  query getClassFundt($id: ID!) {
    classFund(id: $id) {
      id
      name
      state
      minimumTir
      maximumTir
      geographicalFocus
      assetClass
    }
  }
`;

export const CREATE_CLASS_FUND = gql`
  mutation createClassFund($input: ClassFundInput!) {
    createClassFund(input: $input) {
      classFund {
        id
        name
        state
        minimumTir
        maximumTir
        geographicalFocus
        assetClass
      }
    }
  }
`;

export const UPDATE_CLASS_FUND = gql`
  mutation updateClassFund($id: ID!, $input: ClassFundInput!) {
    updateClassFund(id: $id, input: $input) {
      classFund {
        id
        name
        state
        minimumTir
        maximumTir
        geographicalFocus
        assetClass
      }
    }
  }
`;

export const DELETE_CLASS_FUND = gql`
  mutation deleteClassFund($id: ID!) {
    deleteClassFund(id: $id) {
      classFund {
        id
        name
        state
        minimumTir
        maximumTir
        geographicalFocus
        assetClass
      }
      errors {
        field
        message
      }
    }
  }
`;
