import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import LoadingIndicator from '../../../../../LoadingIndicator';
import RaisingForm from '../RaisingForm';
import { GET_RAISING_FUND } from '../queries';
import { errorMessage } from '../../../../../../utils/commonFunctions';

function CreateEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, data } = useQuery(GET_RAISING_FUND, {
    variables: { id },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    errorMessage(
      { message: 'Error al obtener levantamiento.' },
      enqueueSnackbar,
      { callback: history.goBack() }
    );
  }
  const { raising } = data;
  if (!raising) {
    history.goBack();
  }

  return <RaisingForm raising={raising} id={id} isEdit />;
}

export default CreateEdit;
