import { makeStyles } from '@material-ui/core/styles';
import backgrdBanner from '../svg/PartnersBgIcon.svg';
export default makeStyles(theme => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },

  title: {
    fontWeight: 700,
    fontFamily: 'Montserrat',
    color: '#292D32',
    fontSize: '3rem',
    margin: 0,
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.365rem',
    },
  },
  content: {
    fontWeight: 500,
    fontFamily: 'Montserrat',
    color: '#292D32',
    textAlign: 'start',
    fontSize: '1rem',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2,
    },
  },
  btnDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    width: '100%',
    gap: '10px',
    paddingTop: '10px',
  },
  btn: {
    backgroundColor: '#262626',
    borderRadius: '8px',
    cursor: 'pointer',
    padding: '8px',
    color: '#FFF',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#3b3b3b',
    },
  },
  btnMoreInfo: {
    fontSize: '12px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#262626',
  },

  moreInfoDiv: {
    display: 'flex',
    // width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
  },
  moreInfoDivProgress: {
    display: 'flex',
    // width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  divProgress: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'start',
    },
  },
  devTextProgress: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bannerDiv: {
    width: '100%',
    padding: '10px',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
    },
  },
  contentBannersIcon: {
    display: 'flex',
    width: '100%',
    // height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: '20px',
    gap: '10px',
    background: `
    url(${backgrdBanner}),
    linear-gradient(180deg, #A6C1D3 46.5%, rgba(166, 193, 211, 0.20) 100%)
  `,
    backgroundSize: 'fit',
    backgroundPosition: '98% 120%',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
  contentBannersDarkIcon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    background: `
    url(${backgrdBanner}),
    linear-gradient(180deg, #A6C1D3 46.5%, #a6c1d3a1 100%)
  
  `,
    backgroundSize: 'auto',
    backgroundPosition: '98% 140%',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
  contentBanners: {
    display: 'flex',
    width: '100%',
    // height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '20px',
    gap: '10px',
    background: `
  
    linear-gradient(180deg, #A6C1D3 46.5%, rgba(166, 193, 211, 0.20) 100%)
  `,
    backgroundSize: 'auto',
    backgroundPosition: '98% 80%',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
  contentBannersDark: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    background: `
   
    linear-gradient(180deg, #A6C1D3 46.5%, rgba(166, 193, 211, 0.20) 100%)
  `,
    backgroundSize: 'auto',
    backgroundPosition: '98% 80%',
    backgroundRepeat: 'no-repeat',
    padding: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },

  img: {
    height: '70px',
    // marginBottom: '10px',
    alignSelf: 'center',
  },

  bannerDivText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}));
