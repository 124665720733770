import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PolicyIcon from '@material-ui/icons/Policy';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../../CustomOptions';
import PermissionsModal from '../../../../PermissionsModal';
import LoadingIndicator from '../../../../LoadingIndicator';
import { LIST_USERS, LIST_USERS_PROMOTERS, DELETE_USER } from '../../queries';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import {
  edgeToList,
  getOptions,
  errorMessage,
} from '../../../../../utils/commonFunctions';
import { textLabels } from '../../../../../translations/components/MUIDataTable';

// Styles & Images
import useStyles from './styles';
import { useEffect } from 'react';

function CustomToolbar() {
  const history = useHistory();

  const handleClick = () => {
    history.push('/administrador/administradores/crear');
  };

  return (
    <Tooltip title="Nuevo administrador">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function AdministratorList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [permissionsModalVisibility, setPermissionsModalVisibility] = useState(
    false
  );
  const [permissionsModalUser, setPermissionsModalUser] = useState({});
  const [administratorList, setAdministratorList] = useState([]);

  const [deleteAdministrator] = useMutation(
    DELETE_USER,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Administrador eliminado con éxito.',
      refetchQueries: [
        { query: LIST_USERS_PROMOTERS, variables: { userTypes: ['AD', 'JI'] } },
      ],
      enqueueSnackbar,
    })
  );
  const { loading, error, data } = useQuery(LIST_USERS_PROMOTERS, {
    variables: { userTypes: ['AD', 'JI'] },
  });

  if (error) {
    console.error('list administrators', error);
  }

  useEffect(() => {
    if (data) {
      setAdministratorList(edgeToList(data, 'listUsers'));
    }
  }, [data]);

  const columns = [
    {
      name: 'firstName',
      label: 'Nombre',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={0}
              key={0}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'lastName',
      label: 'Apellido Paterno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={1}
              key={1}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'maternalSurname',
      label: 'Apellido Materno',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={2}
              key={2}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={3}
              key={3}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'isActive',
      label: 'Activo',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={4}
              key={4}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => (value ? 'Sí' : 'No'),
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        const administrator = administratorList.find(e => e.id === id);
        const isActive = administrator && administrator.isActive;
        let options = ['ocustom', 'oedit', 'odelete'];
        if (!isActive) {
          options = ['odelete'];
        }
        return (
          <CustomOptions
            id={id}
            model="Administrador"
            options={options}
            handleDelete={() => {
              deleteAdministrator({ variables: { id } });
            }}
            customOption={
              <Tooltip title="Permisos" placement="top" arrow>
                <IconButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.optionButtons}
                  onClick={() => showPermissionsModal(id)}
                >
                  <PolicyIcon />
                </IconButton>
              </Tooltip>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={5} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      const userID = rowData[rowData.length - 1].props.id;
      const { isActive } = administratorList.find(({ id }) => id === userID);
      if (isActive) {
        history.push(
          `/administrador/administradores/${
            rowData[rowData.length - 1].props.id
          }`
        );
      } else {
        errorMessage(
          {
            message: 'El administrador debe estar activo para poder editarlo.',
          },
          enqueueSnackbar
        );
      }
    }
  };

  const showPermissionsModal = id => {
    const user = edgeToList(data, 'listUsers').find(user => user.id === id);
    setPermissionsModalUser(user);
    setPermissionsModalVisibility(true);
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };

  function filterUsers(users) {}

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PermissionsModal
            user={permissionsModalUser}
            showModal={permissionsModalVisibility}
            dismiss={() => setPermissionsModalVisibility(false)}
          />
          <MUIDataTable
            title="Administradores"
            data={administratorList}
            columns={columns}
            options={options}
          />
        </>
      )}
    </>
  );
}

export default AdministratorList;
