import Yup from '../../../utils/yup';
import { INPUT_VARIANT } from '../../Forms/StepsForm/fieldsSelector';
import { FORM_OPTIONS } from '../constants';
import { parseEquivalent } from '../../../utils/commonFunctions';

export const PERSONAL_DATA_DEFAULT = {
  lastName: '',
  maternalSurname: '',
  firstName: '',
  sex: '',
  birthday: '',
  birthplaceCountry: '',
  birthplaceCounty: '',
  country: '',
  county: '',
  city: '',
  district: '',
  detail: '',
  phone: '',
  cellphone: '',
  email: '',
  fullnameEmergency: '',
  cellphoneEmergency: '',
  relationEmergency: '',
  addressConditionChoice: '',
};

export const CONDITION_TYPES = [
  {
    value: 'OH',
    label: 'Casa propia',
    enum: 'OWN_HOUSE',
  },
  {
    value: 'RH',
    label: 'Vivienda alquilada',
    enum: 'RENTED_HOUSE',
  },
  {
    value: 'MH',
    label: 'Vivienda conyugal',
    enum: 'MARITAL_HOUSE',
  },
  {
    value: 'PTRH',
    label: 'Propiedas de los concubinos',
    enum: 'PARTNER_HOUSE',
  },
  {
    value: 'PRTH',
    label: 'Propiedad de los padres',
    enum: 'PARENT_HOUSE',
  },
  {
    value: 'TIU',
    label: 'Cedida en uso',
    enum: 'TRANSFERRED_IN_USE',
  },
  {
    value: 'O',
    label: 'Otro',
    enum: 'OTHER',
  },
];

export const PERSONAL_FORM_DATA = disableNames => [
  {
    isField: true,
    label: '* Apellido Paterno',
    name: 'lastName',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
    disabled: disableNames,
  },
  {
    isField: true,
    label: '* Apellido Materno',
    name: 'maternalSurname',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
    disabled: disableNames,
  },
  {
    isField: true,
    label: '* Nombres',
    name: 'firstName',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
    disabled: disableNames,
  },
  {
    isField: true,
    label: '* Sexo',
    name: 'sex',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.select,
    data: FORM_OPTIONS.sex,
  },
  {
    isField: true,
    label: '* Fecha de Nacimiento',
    name: 'birthday',
    dateT: 'year',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.date,
  },
  {
    isField: false,
    section: 'Lugar de nacimiento',
    fields: [
      {
        label: '* Places',
        name: 'places',
        inputVariant: INPUT_VARIANT.places,
        gridMD: [12, 12, 12, 12],
        compact: true,
        indicators: {
          country: { name: 'birthplaceCountry', label: '* País' },
          county: { name: 'birthplaceCounty', label: '* Ciudad' },
        },
      },
    ],
  },

  {
    isField: false,
    section: 'Domicilio',
    fields: [
      {
        label: '* Places',
        name: 'places',
        inputVariant: INPUT_VARIANT.places,
        gridMD: [12, 12, 12, 12],
        indicators: {
          country: { name: 'country', label: '* País' },
          county: { name: 'county', label: '* Region' },
          city: { name: 'city', label: '* Provincia' },
          district: { name: 'district', label: '* Distrito' },
        },
      },
      {
        label: '* Avenida, Jirón, Calle ...',
        name: 'detail',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Código postal',
        name: 'zipCode',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        isField: true,
        label: 'Condición del inmueble donde vive',
        name: 'addressConditionChoice',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.select,
        data: CONDITION_TYPES,
        mapData: { value: 'value', label: 'label' },
      },
      {
        label: 'Especifique otro',
        name: 'addressCondition',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
    ],
  },
  {
    isField: false,
    section: 'Teléfonos/Email',
    fields: [
      {
        label: 'Casa',
        name: 'phone',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: '* Celular',
        name: 'cellphone',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: '* Email',
        name: 'email',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
    ],
  },
];

export const getValidationSchema = () => {
  return {
    lastName: Yup.string().required(),
    maternalSurname: Yup.string().required(),
    firstName: Yup.string().required(),
    email: Yup.string()
      .email()
      .required(),
    sex: Yup.string().required(),
    birthday: Yup.string().required(),
    birthplaceCountry: Yup.string().required(),
    birthplaceCounty: Yup.string().required(),
    country: Yup.string().required(),
    county: Yup.string().required(),
    detail: Yup.string().required(),
    zipCode: Yup.string(),
    addressCondition: Yup.string(),
    addressConditionChoice: Yup.string().required(),
    phone: Yup.string(),
    cellphone: Yup.string().required(),
  };
};

export const defaultFormatData = client => {
  const { user, address, birthplace, ...currentClient } = client;
  const currentData = { ...PERSONAL_DATA_DEFAULT };
  if (user) {
    currentData.firstName = user.firstName;
    currentData.lastName = user.lastName;
    currentData.maternalSurname = user.maternalSurname;
    currentData.email = user.email;
    currentData.email = user.isEmailAutogenerated ? '' : user.email;
  }
  if (birthplace) {
    currentData.birthplaceCountry = birthplace.country
      ? birthplace.country.id
      : '';
    currentData.birthplaceCounty = birthplace.county
      ? { id: birthplace.county.id, name: birthplace.county.name }
      : '';
  }

  if (address) {
    currentData.country = address.country ? address.country.id : '';
    currentData.county = address.county
      ? { id: address.county.id, name: address.county.name }
      : '';
    currentData.city = address.city ? address.city.id : '';
    currentData.district = address.district ? address.district.id : '';
    currentData.detail = address.detail;
    if (address.zipCode) currentData.zipCode = address.zipCode;
  }

  return { ...currentData, ...currentClient };
};

export const defaultBeforeSubmitFormat = data => {
  const {
    country,
    county,
    city,
    district,
    detail,
    zipCode,
    number,
    secondNumber,
    birthplaceCountry,
    birthplaceCounty,
    ...formattedData
  } = data;
  if (country && county) {
    const address = {
      country,
      county,
      detail,
      zipCode,
    };
    if (city && district && county) {
      address.county = county.id;
      address.city = city;
      address.district = district;
    }
    formattedData.address = address;
  }
  if (birthplaceCountry && birthplaceCounty) {
    const birthplace = {
      country: birthplaceCountry,
      county: birthplaceCounty.id,
    };
    formattedData.birthplace = birthplace;
  }
  formattedData.sex = parseEquivalent(formattedData.sex);
  return formattedData;
};
