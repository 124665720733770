import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes, { bool } from 'prop-types';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { TableCell, Typography, Button } from '@material-ui/core';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  GET_CLIENT_PERSONAL_DATA,
  UPDATE_CLIENT_PERSONAL_DATA,
  LIST_COUNTRIES,
  GET_CLIENT_RELATIVE,
  DELETE_CLIENT_RELATIVE,
} from '@queries/Client';
import CustomCard from '../../../CustomCard';
import StepsForm from '../../../Forms/StepsForm';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  errorMessage,
  edgeToList,
  getOptions,
} from '../../../../utils/commonFunctions';
import Yup from '../../../../utils/yup';
import { NOTIFICATION_DURATION } from '../../../../utils/constants';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import {
  defaultBeforeSubmitFormat,
  getValidationSchema,
  PERSONAL_DATA_DEFAULT,
  PERSONAL_FORM_DATA,
} from '../personalData';
import CustomModal from '../../../CustomModal';
import RelativeForm from '../RelativeForm';
import useStyles from '../../PEP/Relative/styles';
import { RELATION_TYPE } from '../RelativeForm';

const schema = getValidationSchema();
const validationSchema = Yup.object().shape({ ...schema });

function PersonalDataClient(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [startValues, setStartValues] = useState({ ...PERSONAL_DATA_DEFAULT });
  const [disableNames, setDisableNames] = useState(false);
  const [currentRelative, setCurrentRelative] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [relatives, setRelatives] = useState([]);
  const [client, setClient] = useState(null);
  const classes = useStyles();
  const { loading, error: clientError, data: responseData } = useQuery(
    GET_CLIENT_PERSONAL_DATA,
    {
      variables: { id: clientID },
    },
  );
  const {
    loading: loadingData,
    error: clientDataError,
    data: clientData,
  } = useQuery(GET_CLIENT_RELATIVE, {
    variables: { id: clientID },
  });

  const deleteRelativeMutation = useMutation(
    DELETE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'createRelative',
      modelName: 'relative',
      message: 'Se guardó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientID } },
      ],
      onCompleted: data => {
        const { errors } = data.deleteRelative;
        if (errors) {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    }),
  );

  useEffect(() => {
    if (clientData) {
      setClient(clientData.client);
      const tmpRelatives = edgeToList(clientData.client, 'relatives');
      setRelatives(tmpRelatives);
      if (currentRelative) {
        let tmpRelative = tmpRelatives.find(v => v.id === currentRelative.id);
        setCurrentRelative(tmpRelative);
      }
    }
  }, [clientData, currentRelative]);

  const updateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors && graphQLErrors.length > 0) {
        errorMessage(graphQLErrors, enqueueSnackbar);
      }
      if (networkError) {
        errorMessage(networkError, enqueueSnackbar);
      }
    },
    onCompleted(data) {
      const { errors } = data.updateClient;
      if (errors && errors.length > 0) {
        errorMessage(errors, enqueueSnackbar);
      } else {
        enqueueSnackbar('Se guardó datos personales.', {
          autoHideDuration: NOTIFICATION_DURATION,
          variant: 'success',
        });
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_PERSONAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_PERSONAL_DATA, {
    onError(error) {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors && graphQLErrors.length > 0) {
        errorMessage(graphQLErrors, enqueueSnackbar);
      }
      if (networkError) {
        errorMessage(networkError, enqueueSnackbar);
      }
    },
    onCompleted(data) {
      const { errors } = data.updateClient;
      if (errors && errors.length > 0) {
        errorMessage(errors, enqueueSnackbar);
      } else {
        enqueueSnackbar('Se guardó datos personales parcialmente.', {
          autoHideDuration: NOTIFICATION_DURATION,
          variant: 'success',
        });
      }
    },
  });

  const {
    loading: countryLoading,
    data: countryData,
    error: countryError,
  } = useQuery(LIST_COUNTRIES);

  const isPeru = id => {
    if (
      countryData &&
      countryData.listCountries &&
      countryData.listCountries.edges
    ) {
      return !!countryData.listCountries.edges.find(val => {
        return val.node && val.node.id === id && val.node.code === 'PE';
      });
    }
    return false;
  };

  useEffect(() => {
    if (clientError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar,
      );
    }
    if (responseData) {
      const { client } = responseData;

      if (client) {
        const {
          user: userClient,
          address,
          addressCondition,
          birthplace,
          ...currentClient
        } = client;
        const currentData = { ...PERSONAL_DATA_DEFAULT };
        if (userClient) {
          currentData.firstName = userClient.firstName;
          currentData.lastName = userClient.lastName;
          currentData.maternalSurname = userClient.maternalSurname;
          currentData.email = userClient.email;
          if (userClient?.documentType?.name)
            setDisableNames(userClient?.documentType?.name === 'DNI');
        }
        currentData.addressCondition = addressCondition ? addressCondition : '';
        if (birthplace) {
          currentData.birthplaceCountry = birthplace.country
            ? birthplace.country.id
            : null;
          currentData.birthplaceCounty = birthplace.county
            ? birthplace.county.id
            : null;
        }

        if (address) {
          if (address.zipCode) currentData.zipCode = address.zipCode;
          currentData.country = address.country ? address.country.id : null;
          currentData.county = address.county ? address.county.id : null;
          if (address.country && isPeru(address.country.id)) {
            currentData.city = address.city ? address.city.id : null;
            currentData.district = address.district
              ? address.district.id
              : null;
          } else {
            currentData.city = null;
            currentData.district = null;
          }
          currentData.detail = address.detail;
        }
        setStartValues({ ...currentData, ...currentClient });
      }
    }
  }, [responseData, clientError, enqueueSnackbar]);

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const columns = [
    {
      name: 'typeRelation',
      label: 'Parentesco',
      options: {
        sort: true,
        customBodyRender: value => {
          return RELATION_TYPE?.find(el => el?.value === value)?.label;
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'fullName',
      label: 'Nombres y Apellidos',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Parientes"
            options={['cedit', 'odelete']}
            handleDelete={() =>
              deleteRelativeMutation[0]({ variables: { id } })
            }
            handleEdit={() => {
              const relative = relatives.filter(v => id === v.id)[0];
              setShowForm(true);
              setCurrentRelative(relative);
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentRelative(null);
            setShowForm(true);
          }}
        >
          Añadir pariente
        </Button>
      );
    },
    onRowClick: rowData => {
      setCurrentRelative(relatives.find(v => rowData[2].props.id === v.id));
      setShowForm(true);
    },
  };

  const closeForm = () => {
    setCurrentRelative(null);
    setShowForm(false);
  };

  return loading || !client ? (
    <LoadingIndicator />
  ) : (
    <>
      <StepsForm
        initialValues={startValues}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        data={PERSONAL_FORM_DATA(disableNames)}
        id={clientID}
        mainClientID={clientID}
        title="Datos personales"
        nextForm={MENU_ITEMS_ID.ClientLegalData}
        updateMutation={updateClient}
        partialUpdateMutation={partialUpdateClient}
        formatBeforeSubmit={defaultBeforeSubmitFormat}
        subtitle="Información Familiar"
        dataInsideCard={relatives}
        columns={columns}
        options={options}
        emergencyContact={true}
      />
      <CustomModal
        title="Familiar"
        showModal={showForm}
        onClosePress={() => closeForm()}
      >
        <RelativeForm
          clientId={client.id}
          relativeData={currentRelative ? currentRelative : {}}
          closeForm={() => closeForm()}
          setCurrentRelative={id =>
            setCurrentRelative(relatives.filter(v => v.id === id)[0])
          }
        />
      </CustomModal>
    </>
  );
}

PersonalDataClient.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default PersonalDataClient;
