/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import LogoPositivo from '../../images/brand/CoreCapital/CoreA1_positivo.png';
import LogoNegativo from '../../images/brand/CoreCapital/CoreA1_negativo.png';

import useStyles from './styles';
import { getClientSelector } from '../../store/sharedStore/selectors';
import { getAuthUser } from 'src/utils/storage';
import getClientMode from 'src/store/utils-store/selectors';

export default function NotFound(props) {
  const { message } = props;
  const classes = useStyles();
  const [hasMessage, setHasMessage] = useState(false);
  const client = useSelector(getClientSelector());
  const isModeDark = useSelector(getClientMode());
  const authUser = getAuthUser();

  const selectColorTitle1 = isModeDark ? classes.oopsDark : classes.oops;
  const selectColorSubTitle = isModeDark
    ? classes.subTitleDark
    : classes.subTitle;

  const selectColorMessage = isModeDark
    ? classes.messageDark
    : classes.messageLight;

  const selectColorLogo = isModeDark ? LogoNegativo : LogoPositivo;
  useEffect(() => {
    if (client && !client.isMain && !client.isEmployee) {
      setHasMessage(true);
    } else {
      setHasMessage(false);
    }
  }, [client]);

  return (
    <>
      {!client?.isMain ? (
        <div
          className={classes.main}
          style={{
            backgroundColor: isModeDark ? '#21212191' : '#ffffff7d',
          }}
        >
          <div className={classes.container}>
            <div
              style={{
                width: '100%',
                display: 'flex',
              }}
            >
              <img
                src={selectColorLogo}
                alt="Logo Dorado"
                className={classes.img}
              />
            </div>

            <h1 className={selectColorTitle1}>Oops!</h1>
            {!message && !hasMessage && (
              <h1 className={selectColorSubTitle}>
                404 Página no encontrada...
              </h1>
            )}
            {message && <h1 className={selectColorMessage}> {message} </h1>}
            {hasMessage && (
              <h2 className={selectColorMessage}>
                Antes de poder realizar otra acción debe completar su ficha
                cliente.
              </h2>
            )}
          </div>
        </div>
      ) : (
        <Grid container item justify="center">
          <Grid container className={classes.notFoundPage}>
            <Grid container item className={classes.header} justify="center">
              <img
                src={LogoNegativo}
                alt="Logo Dorado"
                className={classes.image}
              />
            </Grid>
            <Grid container justify="center">
              <article>
                {hasMessage ? (
                  <h2>
                    Antes de poder realizar otra acción debe completar su ficha
                    cliente.
                  </h2>
                ) : message ? (
                  <h1> {message} </h1>
                ) : (
                  <h1>404 Página no encontrada</h1>
                )}
              </article>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}
