import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createMuiTheme } from '@material-ui/core/styles';

import {
  MontserratThin,
  MontserratThinItalic,
  MontserratExtraLight,
  MontserratExtraLightItalic,
  MontserratLight,
  MontserratLightItalic,
  MontserratMedium,
  MontserratMediumItalic,
  Montserrat,
  MontserratItalic,
  MontserratBold,
  MontserratBoldItalic,
  MontserratBlack,
  MontserratBlackItalic,
} from '../fonts';

import { lightBlue } from '@material-ui/core/colors';

const breakpoints = createBreakpoints({});
const bgTable = '#323232';
const tableText = '#FFF';
const positionDrawer = '0%';
const tableIcon = '#A6C1D3';
const tableColor = '#A6C1D3';
export default createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Montserrat'].join(','),
    body1: {
      fontSize: '1rem', // Equivalente a 'base'
    },
    body2: {
      fontSize: '0.875rem', // Equivalente a 'sm'
    },
    caption: {
      fontSize: '0.70rem', // Equivalente a 'xs'
    },
    overline: {
      fontSize: '0.65rem', // Equivalente a 'xxs'
    },
  },
  // Here you can set or override all your variables and settings related to styling.
  // https://material-ui.com/customization/themes/
  palette: {
    primary: {
      light: '#d8f4ff',
      main: '#a6c1d3',
      dark: '#7691a2',
      contrastText: '#282828',
    },
    secondary: {
      light: '#ffab42',
      main: '#f97a00',
      dark: '#bf4b00',
      contrastText: '#282828',
    },
    info: {
      main: '#7691a2',
    },
    colors: {
      primary: '#15264A',
      secondary: '#D5B65B',
      tercer: '#A6C1D3',
      black: '#000000',
      gray: '#efefef',
      darkGray: '#cccccc',
      lightGray: '#e4e4e4',
      white: '#ffffff',
      blue: '#A6C1D3',
      error: '#f5222d',
      warning: '#f96c37',
      green: '#33a559',
      yellow: '#f39c12',
      orange: '#f97a00',
      red: '#e12b39',
      textPrimary: '#15264A',
      text: {
        primary: '#282828',
        secondary: '#3D3D3D',
      },
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    hoverColors: {
      yellow: '#f5af41',
      green: '#5bb77a',
      blue: '#3b5f7c',
      secondary: '#ffab42',
    },
    background: {
      default: '#ffffff',
      paper: 'white',
      transparent: 'transparent',
    },
    text: {
      primary: '#282828',
      secondary: '#8A8C8E',
      disabled: 'rgba(150, 109, 109, 0.38)',
      hint: 'rgba(119, 255, 0, 0.38)',
    },
  },

  // Overrides
  overrides: {
    // Global font family
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#fff',
        },

        '@font-face': [
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 100,
            src: `url(${MontserratThin}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 100,
            src: `url(${MontserratThinItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 200,
            src: `url(${MontserratExtraLight}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 200,
            src: `url(${MontserratExtraLightItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 300,
            src: `url(${MontserratLight}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 300,
            src: `url(${MontserratLightItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            src: `url(${Montserrat}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 400,
            src: `url(${MontserratItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 500,
            src: `url(${MontserratMedium}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 500,
            src: `url(${MontserratMediumItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 700,
            src: `url(${MontserratBold}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 700,
            src: `url(${MontserratBoldItalic}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 800,
            src: `url(${MontserratBlack}) format('truetype')`,
          },
          {
            fontFamily: 'Montserrat',
            fontStyle: 'italic',
            fontWeight: 800,
            src: `url(${MontserratBlackItalic}) format('truetype')`,
          },
        ],
      },
    },
    // Drawer
    MuiPaper: {
      root: {
        backgroundColor: '#212121',
        color: '#fff',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        [breakpoints.up('lg')]: {
          left: `${positionDrawer}`,
        },
        background: '#212121',
        color: '#fff',
        borderRight: 'none',
        maxHeight: '100vh',
      },
    },
    // Tables
    MUIDataTable: {
      paper: {
        backgroundColor: `${bgTable}`,
        boxShadow: 'none',
      },
    },
    MUIDataTableHead: {
      main: {
        borderBottom: '1px solid #9B9B9B',
        paddingTop: 40,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        fontSize: 16,
        fontWeight: 300,
        borderColor: '#9B9B9B',
        color: `${tableText}`,
      },
    },
    MUIDataTableToolbar: {
      root: {
        paddingBottom: 40,
        paddingTop: 4,
        color: `${tableText}`,
      },
      icon: {
        color: `${tableIcon}`,
      },
      titleText: {
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      actions: {
        paddingLeft: 20,
        '@media (max-width: 720px)': {
          justifyContent: 'center',
          paddingLeft: 0,
        },
      },
    },
    MuiTableCell: {
      head: {
        '& .MuiTypography-root': {
          fontSize: '16px !important',
          fontWeight: '600 !important',
          color: '#fff',
        },
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTableFooter: {
      root: {
        border: 'none',
        color: `${tableText}`,
        '& .MuiTableCell-root': {
          border: 'none',
        },
      },
    },
    MuiTablePagination: {
      selectIcon: {
        color: `${tableColor}`,
      },
      actions: {
        color: `${tableColor}`,
        '& .MuiSvgIcon-root': {
          color: `${tableColor}`,
          fontSize: '2rem',
        },
        '& .Mui-disabled .MuiSvgIcon-root': {
          color: `${tableColor}`,
          fontSize: '2rem',
        },
      },
      caption: {
        color: `${tableText}`,
      },
      selectRoot: {
        border: '1px solid',
        borderRadius: 12,
        '& .MuiSelect-select': {
          paddingLeft: 16,
          paddingRight: 36,
          color: `${tableText}`,
        },
      },
      toolbar: {
        paddingTop: 30,
      },
    },

    // Inputs
    MuiInputBase: {
      input: {
        pointerEvents: 'none',
        color: '#FFF',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#BEBEBF',
        borderRadius: 8,
      },
      input: {
        fontWeight: 300,
        padding: '14px 14px',
        color: '#fff',
      },
      root: {
        pointerEvents: 'none',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#A8A8BF',
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        color: '#FFF',
        fontWeight: 400,
        transform: 'translate(14px, 15px) scale(1)',
      },
      root: {
        pointerEvents: 'none',
        '&$focused': {
          color: '#7691a2',
        },
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#00395C',
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#00395C',
        color: '#FFFFFF',
      },
    },
    // Buttons
    MuiButton: {
      root: {
        padding: '10px 30px',
      },

      contained: {
        borderRadius: 25,
        boxShadow:
          '0 2px 2px 0 rgba(0, 57, 92, .14),0 3px 1px -2px rgba(0, 57, 92, .2),0 1px 5px 0 rgba(0, 57, 92, .12)',
        cursor: 'pointer',
        '&:hover': {
          boxShadow:
            '0 14px 26px -12px rgba(0, 57, 92, .42),0 4px 23px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(0, 57, 92, .2)',
        },
      },
      containedSecondary: {
        backgroundColor: '#A6C1D3',
        '&:hover': {
          backgroundColor: '#A6C1D3',
        },
      },
      label: {
        color: '#292D32',
        fontWeight: 600,
        fontSize: '.85rem',
        lineHeight: '1.3  ',
      },
    },
    // Editor
    MUIRichTextEditor: {
      editor: {
        backgroundColor: '#ffffff',
      },
      toolbar: {
        borderBottom: '1px solid',
        borderColor: '#00395C',
        backgroundColor: '#ebebeb',
      },
      placeHolder: {
        fontStyle: 'italic',
      },
    },

    // Cards
    MuiCardContent: {
      root: {
        color: '#FFF',
      },
    },
    MuiContainer: {
      root: {
        background: '#323232',
        color: '#fff',
      },
    },
    // BUTTOMS
    MuiIconButton: {
      root: {
        color: '#FFF',
      },
      colorSecondary: {
        color: '#A6C1D3',
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: '#D5B75B',
      },
    },

    MuiBackdrop: {
      root: {
        backgroundColor: '#21212191',
      },
    },
  },
});
