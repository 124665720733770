import React, { useEffect, useRef, useState } from 'react';

// Material Resources
import {
  Grid,
  InputAdornment,
  IconButton,
  Button,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// GraphQl
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Others

import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// Styles & Images
import useStyles from './styles';
import { successMessage, errorMessage } from 'src/utils/commonFunctions';
import PasswordKeyboard from './numericKeyboard';
import { useSelector } from 'react-redux';
import getClientMode from 'src/store/utils-store/selectors';

function Profile() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [resetLoading, setResetLoading] = useState(false);

  const [focusedInput, setFocusedInput] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
    newpassword: '',
    confirmpassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
    newpassword: false,
  });

  const CHANGE_PASSWORD = gql`
    mutation changeUserPassword($input: ResetPasswordConfirmationInput!) {
      changeUserPassword(input: $input) {
        ok
        message
      }
    }
  `;

  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
    onError(error) {
      const { graphQLErrors } = error;
      if (graphQLErrors[0]?.message?.includes('común')) {
        errorMessage(
          'Contraseña no válida. Evita usar números consecutivos o repetidos.',
          enqueueSnackbar,
        );
      } else errorMessage(graphQLErrors, enqueueSnackbar);
      setResetLoading(false);
    },
    onCompleted(data) {
      const response = data.changeUserPassword;
      if (response.ok) {
        successMessage('Contraseña cambiada con éxito', enqueueSnackbar);
      } else {
        errorMessage(response.message, enqueueSnackbar);
      }
      setResetLoading(false);
    },
  });

  const inputRef = useRef(null);
  const keyboardRef = useRef(null);

  const inputRef2 = useRef(null);
  const keyboardRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const keyboardRef3 = useRef(null);
  const handleClickOutside = event => {
    if (
      keyboardRef.current &&
      !keyboardRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
    if (
      keyboardRef2.current &&
      !keyboardRef2.current.contains(event.target) &&
      inputRef2.current &&
      !inputRef2.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
        newpassword: false,
      });
    }
    if (
      keyboardRef3.current &&
      !keyboardRef3.current.contains(event.target) &&
      inputRef3.current &&
      !inputRef3.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        newpassword: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (formData.password.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.confirmpassword.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
      });
    }
  }, [formData.confirmpassword]);
  useEffect(() => {
    if (formData.newpassword.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        newpassword: false,
      });
    }
  }, [formData.newpassword]);

  const handlerOpenKeyboardpassword = () => {
    setFocusedInput('password');
    setFormData({
      ...formData,
      password: '',
    });
  };

  const handlerOpenKeyboardconfirmpass = () => {
    setFocusedInput('confirmpassword');
    setFormData({
      ...formData,
      confirmpassword: '',
    });
  };
  const handlerOpenKeyboardnewpass = () => {
    setFocusedInput('newpassword');
    setFormData({
      ...formData,
      newpassword: '',
    });
  };

  const togglePasswordVisibility = field => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const handleConfirmPasswordChange = value => {
    setFormData({
      ...formData,
      confirmpassword: value,
    });
  };
  const handlePasswordChange = value => {
    setFormData({
      ...formData,
      password: value,
    });
  };
  const handleNewPasswordChange = value => {
    setFormData({
      ...formData,
      newpassword: value,
    });
  };

  const onSubmit = () => {
    if (formData.newpassword !== formData.confirmpassword) {
      errorMessage('Las contraseñas no coinciden.', enqueueSnackbar);
      return;
    }

    changePasswordMutation({
      variables: {
        input: {
          currentPassword: formData.password,
          newPassword: formData.newpassword,
        },
      },
    });
  };
  const modeClient = useSelector(getClientMode());
  const themeSelect = modeClient ? classes.titleDark : classes.title;
  const themeSelectForm = modeClient ? classes.formDark : classes.formLight;
  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div className={classes.profile}>
          <div className={themeSelectForm}>
            <div className={classes.titleDiv}>
              <h1 className={themeSelect}>Cambio de Contraseña</h1>
            </div>
            <TextField
              ref={inputRef}
              type={showPassword.password ? 'text' : 'password'}
              label="Ingrese su clave actual"
              name="password"
              variant="outlined"
              value={formData.password}
              onFocus={handlerOpenKeyboardpassword}
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => togglePasswordVisibility('password')}
                    >
                      {showPassword.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {focusedInput === 'password' && (
              <div ref={keyboardRef} className={classes.main}>
                <PasswordKeyboard handlerPassword={handlePasswordChange} />
              </div>
            )}
            <TextField
              ref={inputRef3}
              type={showPassword.newpassword ? 'text' : 'password'}
              label="Ingrese su nueva clave"
              name="newpassword"
              variant="outlined"
              value={formData.newpassword}
              onFocus={handlerOpenKeyboardnewpass}
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => togglePasswordVisibility('newpassword')}
                    >
                      {showPassword.newpassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {focusedInput === 'newpassword' && (
              <div ref={keyboardRef3} className={classes.main}>
                <PasswordKeyboard handlerPassword={handleNewPasswordChange} />
              </div>
            )}
            <TextField
              ref={inputRef2}
              type={showPassword.confirmpassword ? 'text' : 'password'}
              label="Confirme su nueva clave"
              name="confirmpassword"
              variant="outlined"
              value={formData.confirmpassword}
              onFocus={handlerOpenKeyboardconfirmpass}
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        togglePasswordVisibility('confirmpassword')
                      }
                    >
                      {showPassword.confirmpassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {focusedInput === 'confirmpassword' && (
              <div ref={keyboardRef2} className={classes.main}>
                <PasswordKeyboard
                  handlerPassword={handleConfirmPasswordChange}
                />
              </div>
            )}
            <div className={classes.mssgDiv}>
              <p className={classes.mssgText}>
                <strong>Recomendación</strong>
                <br />
                Tu contraseña debe tener 6 dígitos, no consecutivos ni
                repetidos. Asegúrate de que sea una combinación única.
              </p>
            </div>
            <div
              style={{
                width: '100%',
                padding: '1vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  width: 'fit-content',
                }}
              >
                <Button
                  // className={classes.btn}
                  variant="contained"
                  color={modeClient ? 'secondary' : 'secondary'}
                  disabled={resetLoading}
                  onClick={onSubmit}
                  type="submit"
                >
                  Actualizar Contraseña
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
Profile.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default Profile;
