// GraphQl
import { gql } from 'apollo-boost';

export const LIST_RWHEEL_RAISING = gql`
  query getRepurchaseWheels($repurchase: ID) {
    listRepurchaseWheel(repurchase: $repurchase) {
      edges {
        node {
          id
          name
          numSent
          dateInitial
          dateEnd
          dateAward
          numToSend
          fundInvestors
          fundInvestorsPastWheel
          fundInvestorsOtherFunds
          interestedInvestors
        }
      }
    }
  }
`;

export const GET_RWHEEL_RAISING = gql`
  query getRepurchaseWheel($id: ID!) {
    repurchaseWheel(id: $id) {
      id
      name
      numSent
      dateInitial
      dateEnd
      dateAward
      numToSend
      fundInvestors
      fundInvestorsPastWheel
      fundInvestorsOtherFunds
      interestedInvestors
    }
  }
`;

export const CREATE_RWHEEL_RAISING = gql`
  mutation createRepurchaseWheel($input: RepurchaseWheelInput!) {
    createRepurchaseWheel(input: $input) {
      repurchaseWheel {
        id
        name
        numSent
        dateInitial
        dateEnd
        dateAward
        numToSend
        fundInvestors
        fundInvestorsPastWheel
        fundInvestorsOtherFunds
        interestedInvestors
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_RWHEEL_RAISING = gql`
  mutation updateRepurchaseWheel($id: ID!, $input: RepurchaseWheelInput!) {
    updateRepurchaseWheel(id: $id, input: $input) {
      repurchaseWheel {
        id
        name
        numSent
        dateInitial
        dateEnd
        dateAward
        numToSend
        fundInvestors
        fundInvestorsPastWheel
        fundInvestorsOtherFunds
        interestedInvestors
      }

      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_RWHEEL_RAISING = gql`
  mutation deleteRepurchaseWheel($id: ID!) {
    deleteRepurchaseWheel(id: $id) {
      repurchaseWheel {
        id
      }

      errors {
        field
        message
      }
    }
  }
`;
