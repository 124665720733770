export const ACTIONS = {
  SET_MODE: 'SET_MODE',
};

export function setModeClient(state) {
  return {
    type: ACTIONS.SET_MODE,
    state,
  };
}
