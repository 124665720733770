import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { TreeView, TreeItem } from '@material-ui/lab';
import {
  Mail as MailIcon,
  MenuOpen as MenuOpenIcon,
  CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
  CheckBoxOutlined as CheckBoxOutlinedIcon,
  Check as CheckIcon,
} from '@material-ui/icons';
import { Grid, SvgIcon, Hidden, Popover, Fab } from '@material-ui/core';
import moment from 'moment';
import LoadingIndicator from '../../LoadingIndicator';
import ReadOnlyEditor from 'src/components/ReadOnlyEditor';
import { TextField } from 'formik-material-ui';
import RadioButtons from 'src/components/FormComponents/RadioButtons';
import Checkboxes from 'src/components/FormComponents/Checkboxes';
import { Formik, Form } from 'formik';
import Yup from 'src/utils/yup';
import clsx from 'clsx';
import { parseDate } from 'src/utils/date';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  edgeToList,
  errorMessage,
  successMessage,
} from 'src/utils/commonFunctions';

import { getClientSelector } from 'src/store/sharedStore/selectors';
import {
  CREATE_CLIENT_SIGNATURE,
  RESEND_CLIENT_SIGNATURE_KEY,
} from '@queries/Client';
import { LIST_FUND_SOURCE } from '../FundSource/queries';
import GenerateContract, {
  yesNoOptions,
} from '../ContracSign/GenerateContract';
import { PARTICIPANT_STATES_VALUES } from '../Fund/FundList/Raisings/Participants';
import useClasses from './styles';
import {
  GET_CONTRACT_BY_SECONDARY_MARKET,
  GET_CONTRACT_SECONDARY_MARKET_METADATA,
  SAVE_SECONDARY_MARKET_CONTRACT_DATA,
  CONFIRM_SECONDARY_MARKET_CONTRACT_SIGNATURE,
  CREATE_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE,
  CONFIRM_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE,
} from './queries';
import { SECONDARY_MARKET_STATES } from '../SecondaryMarket';
import { HAS_SWORN_DECLARATION } from '../ContracSign/queries';

function StyledTreeItem(props) {
  const classes = useClasses();
  const {
    nodeId,
    active,
    state,
    disabled,
    labelText,
    setActive,
    ...other
  } = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {state ? (
            <CheckBoxOutlinedIcon
              color="inherit"
              className={classes.labelIcon}
            />
          ) : (
            <CheckBoxOutlineBlankOutlinedIcon
              color="inherit"
              className={classes.labelIcon}
            />
          )}
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
        </div>
      }
      onClick={e => {
        e.preventDefault();
        if (state && !disabled) setActive(nodeId);
      }}
      className={active && classes.treeItem}
      classes={{
        root: classes.itemRoot,
        group: classes.group,
        label: disabled ? classes.itemLabelDisabled : classes.itemLabel,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  nodeId: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  state: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  labelText: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};

export const BLOCK_TYPES = {
  PLAIN_TEXT: 0,
  TRATAMIENTO_DATOS: 1,
  FUENTES_FONDOS: 2,
};

function ContracSignSecondaryMarketForm() {
  const [contractData, setContractData] = useState(null);
  const [contractMetaData, setContractMetaData] = useState(null);
  const [fundId, setFundId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeNode, setActiveNode] = useState(0);
  const [titleData, setTitleData] = useState(null);
  const [signedData, setSignedData] = useState({
    isSigned: false,
    date: '',
  });
  const [signSent, setSignSent] = useState(false);
  const [formStartData, setFormStartData] = useState(null);
  const [formValidation, setFormValidation] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const client = useSelector(getClientSelector());
  const classes = useClasses();
  const history = useHistory();

  const {
    loading: LoadingContractMetadata,
    error: errorContractMetadata,
    data: dataContractMetadata,
  } = useQuery(GET_CONTRACT_SECONDARY_MARKET_METADATA, {
    variables: {
      id,
    },
  });

  const [
    getHasSwornDeclaration,
    { loading: LoadingHasSwornDeclaration },
  ] = useLazyQuery(HAS_SWORN_DECLARATION, {
    fetchPolicy: 'no-cache',
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data) {
        const {
          data: rawContractData,
          dataContractMetadata: rawContractMetaData,
        } = contractMetaData;

        const hasSD = data.hasSwornDeclaration.result;

        const sellers = edgeToList(
          rawContractMetaData.secondaryMarket,
          'seller'
        );
        let coparticipants = [];
        let buyers = edgeToList(rawContractMetaData.secondaryMarket, 'buyer');
        let isSeller = false;
        sellers.forEach(item => {
          if (item.id === client.id) {
            isSeller = true;
          }
        });
        if (isSeller) {
          coparticipants = sellers.filter(i => i.id !== client.id);
        } else {
          buyers = buyers.map(i => i.client);
          coparticipants = buyers.filter(i => i.id !== client.id);
        }

        setTitleData({
          fundName: rawContractMetaData.secondaryMarket.fund.businessName,
        });

        let swornDeclarationBlock = null;
        if (
          rawContractMetaData.secondaryMarket.fund.fundType === 'PR' &&
          !hasSD
        ) {
          swornDeclarationBlock = {
            isSwornDeclaration: true,
            participantID: id,
            labelText: 'Declaración Jurada',
            state: false,
            active: false,
            hasSign: true,
          };
        }
        // SetMetadata
        const formatedData = [
          {
            nodeId: 0,
            labelText: 'Contrato de suscripción',
            state: false,
            active: true,
            text: rawContractData.contractTypeBySecondaryMarket.contractText,
            hasSign: false,
          },
          {
            nodeId: 1,
            labelText: 'Anexo 1',
            state: false,
            active: false,
            text: rawContractData.contractTypeBySecondaryMarket.annexText1,
            hasSign: false,
          },
        ];
        const coparticipantsData = [];
        if (
          rawContractData.contractTypeBySecondaryMarket.condition === 'CO' &&
          coparticipants.length > 0
        ) {
          formatedData.push({
            nodeId: 2,
            labelText: 'Anexo 2a',
            state: false,
            active: false,
            text: rawContractData.contractTypeBySecondaryMarket.annexText2,
            hasSign: !(coparticipants.length > 0),
          });
          coparticipants.forEach((coparticipant, index) => {
            coparticipantsData.push({
              isCoparticipant: true,
              coparticipantID: coparticipant.id,
              nodeId: 3 + index,
              labelText: `Anexo 2${String.fromCharCode(98 + index)}`,
              state: false,
              active: false,
              hasSign: index === coparticipants.length - 1,
            });
          });
        } else {
          formatedData.push({
            nodeId: 2,
            labelText: 'Anexo 2',
            state: false,
            active: false,
            text: rawContractData.contractTypeBySecondaryMarket.annexText2,
            hasSign: true,
          });
        }
        let parsedContracts = [];
        const startDataInputs = {};
        let startDataValidations = {};
        formatedData.forEach((contract, index) => {
          if (contract.text) {
            const obj = JSON.parse(contract.text);
            const blocks = [];
            const necessaryInputs = [];
            let startIDX = 0;
            obj.blocks.forEach((b, blockIndx) => {
              if (b.text.includes('%tratamiento_datos%')) {
                const inputName = `data-treatment-${index}-${blockIndx}`;
                blocks.push({
                  type: BLOCK_TYPES.PLAIN_TEXT,
                  text: JSON.stringify({
                    blocks: obj.blocks.slice(startIDX, blockIndx),
                    entityMap: {},
                  }),
                });
                startIDX = blockIndx + 1;
                blocks.push({
                  type: BLOCK_TYPES.TRATAMIENTO_DATOS,
                  id: blockIndx,
                  name: inputName,
                });
                necessaryInputs.push(inputName);
                startDataInputs[inputName] = yesNoOptions.yes;
                startDataValidations[inputName] = Yup.string().required();
              }
              if (b.text.includes('%fuentes_fondo%')) {
                const inputName = `found-source-${index}-${blockIndx}`;
                blocks.push({
                  type: BLOCK_TYPES.PLAIN_TEXT,
                  text: JSON.stringify({
                    blocks: obj.blocks.slice(startIDX, blockIndx),
                    entityMap: {},
                  }),
                });
                startIDX = blockIndx + 1;
                blocks.push({
                  type: BLOCK_TYPES.FUENTES_FONDOS,
                  id: blockIndx,
                  name: inputName,
                  otherName: `other-${inputName}`,
                  explicationName: `explication-${inputName}`,
                });
                necessaryInputs.push(inputName);
                necessaryInputs.push(`other-${inputName}`);
                necessaryInputs.push(`explication-${inputName}`);

                startDataInputs[inputName] = [];
                startDataInputs[`explication-${inputName}`] = '';
                startDataValidations[inputName] = Yup.array().required();
                startDataValidations[`other-${inputName}`] = Yup.string().when(
                  inputName,
                  {
                    is: sourcesListValues => {
                      if (
                        sourcesListValues.findIndex(i => i === 'other') !== -1
                      )
                        return true;
                      return false;
                    },
                    then: Yup.string().required(),
                  }
                );
                startDataValidations[
                  `explication-${inputName}`
                ] = Yup.string().required();
              }
            });
            if (startIDX < obj.blocks.length) {
              blocks.push({
                type: BLOCK_TYPES.PLAIN_TEXT,
                text: JSON.stringify({
                  blocks: obj.blocks.slice(startIDX, obj.blocks.length),
                  entityMap: {},
                }),
              });
            }
            parsedContracts.push({
              isCoparticipant: false,
              nodeId: index,
              labelText: contract.labelText,
              state: contract.state,
              active: contract.active,
              hasSign: contract.hasSign,
              blockData: blocks,
              necessaryInputs,
            });
          }
        });
        if (rawContractData.contractTypeBySecondaryMarket.condition === 'CO') {
          parsedContracts = parsedContracts.concat(coparticipantsData);
        }
        if (swornDeclarationBlock) {
          parsedContracts.push({
            ...swornDeclarationBlock,
            nodeId: parsedContracts.length,
          });
        }
        startDataInputs.code = '';
        parsedContracts.push({
          labelText: 'Mensaje Final',
          nodeId: parsedContracts.length,
        });
        if (rawContractMetaData.secondaryMarket.contractSigningDate) {
          if (
            rawContractData.contractTypeBySecondaryMarket.condition === 'CO'
          ) {
            setActiveNode(parsedContracts.length - 2);
            parsedContracts[parsedContracts.length - 2].active = true;
            parsedContracts = parsedContracts.map((item, index) => {
              const newItem = { ...item };
              if (index < parsedContracts.length - 2) {
                newItem.active = false;
                newItem.state = true;
                newItem.disabled = true;
              }
              return newItem;
            });
            setSignedData({
              isSigned: false,
              date: '',
            });
          } else {
            setActiveNode(parsedContracts.length - 1);
            parsedContracts = parsedContracts.map(item => {
              const newItem = { ...item };
              newItem.state = true;
              newItem.disabled = true;
              return newItem;
            });
          }
          startDataValidations = {};
        }
        if (rawContractMetaData.secondaryMarket.state === 'S') {
          setActiveNode(parsedContracts.length - 1);
          parsedContracts = parsedContracts.map(item => {
            const newItem = { ...item };
            newItem.state = true;
            newItem.disabled = true;
            return newItem;
          });
        }
        setContractData(parsedContracts);
        setFormStartData(startDataInputs);
        setFormValidation(startDataValidations);
      }
    },
  });

  const [loadContracts, { loading, data }] = useLazyQuery(
    GET_CONTRACT_BY_SECONDARY_MARKET,
    {
      variables: {
        secondaryMarketId: id,
      },
      onError: error =>
        errorMessage(
          'Ocurrió un error al obtener la información del contrato',
          enqueueSnackbar
        ),
    }
  );

  if (errorContractMetadata) {
    errorMessage(
      'Ocurrió un error al obtener la información del contrato',
      enqueueSnackbar
    );
  }

  useEffect(() => loadContracts(), [id]);
  useEffect(() => {
    if (
      data &&
      data.contractTypeBySecondaryMarket &&
      dataContractMetadata &&
      dataContractMetadata.secondaryMarket
    ) {
      setContractMetaData({ data, dataContractMetadata });
      setFundId(dataContractMetadata.secondaryMarket.fund.id);
      getHasSwornDeclaration({
        variables: { fundId: dataContractMetadata.secondaryMarket.fund.id },
      });
    }
  }, [dataContractMetadata, data]);

  function MinusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
      </SvgIcon>
    );
  }

  function PlusSquare(props) {
    return (
      <SvgIcon fontSize="inherit" {...props}>
        {/* tslint:disable-next-line: max-line-length */}
        <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
      </SvgIcon>
    );
  }
  const handleChange = (event, nodes) => {
    event.preventDefault();
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const nextStep = () => {
    const contracts = [...contractData];
    contracts[activeNode].state = true;
    contracts[activeNode].active = false;
    contracts[activeNode + 1].active = true;
    setContractData(contracts);
    setActiveNode(activeNode + 1);
  };

  const clickTreeItem = id => {
    const contracts = [...contractData];
    contracts[activeNode].active = false;
    contracts[id].active = true;
    setContractData(contracts);
    setActiveNode(id);
  };
  const rewriteBlocks = (id, newMetadata) => {
    const contracts = [...contractData];
    contracts[id] = newMetadata;
    setContractData(contracts);
  };

  const [saveContractData, { loading: signLoading }] = useMutation(
    SAVE_SECONDARY_MARKET_CONTRACT_DATA,
    {
      onError(error) {
        setSignSent(false);
        errorMessage(error, enqueueSnackbar);
      },
      onCompleted(data) {
        const { secondaryMarket } = data.saveSecondaryMarketContractData;
        if (secondaryMarket) {
          setSignSent(true);
          successMessage(
            'Se le ha enviado un código a su correo y celular.',
            enqueueSnackbar,
            {
              persist: true,
            }
          );
        }
      },
    }
  );
  const handleSendSignClick = values => {
    let isDataTreatment = true;
    contractData.forEach(node => {
      if (node.necessaryInputs) {
        node.necessaryInputs.forEach(input => {
          if (input.startsWith('data-treatment')) {
            isDataTreatment = values[input] === yesNoOptions.yes;
          }
        });
      }
    });
    const participants = [];
    contractData.forEach(node => {
      let fundSources = [];
      let otherFundSources = '';
      let fundSourceExplication = '';
      if (node.labelText.startsWith('Anexo 2') && node.necessaryInputs) {
        node.necessaryInputs.forEach(input => {
          if (input.startsWith('found-source')) {
            fundSources = values[input];
            const index = fundSources.indexOf('other');
            if (index > -1) {
              fundSources.splice(index, 1);
            }
          }
          if (input.startsWith('other')) {
            otherFundSources = values[input];
          }
          if (input.startsWith('explication')) {
            fundSourceExplication = values[input];
          }
        });
        participants.push({
          id: node.coparticipantID ? node.coparticipantID : client.id,
          fundSources,
          otherFundSources: '',
          fundSourceExplication,
        });
      }
    });

    saveContractData({
      variables: { id, clients: participants, isDataTreatment },
    });
  };

  const [
    confirmContractSign,
    { loading: confirmContractSignLoading },
  ] = useMutation(CONFIRM_SECONDARY_MARKET_CONTRACT_SIGNATURE, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { secondaryMarket } = data.signSecondaryMarketContract;
      if (secondaryMarket) {
        setSignSent(false);
        setSignedData({
          isSigned: true,
          date: secondaryMarket.contractSigningDate,
        });

        successMessage(
          'Firma exitosa, puede ver el documento en su correo electrónico.',
          enqueueSnackbar,
          {
            persist: true,
          }
        );
        const contracts = [...contractData];
        contracts[activeNode].state = true;
        contracts[activeNode].active = false;
        setActiveNode(activeNode + 1);
        setContractData(contracts);
      }
    },
  });

  const [
    createSwornDeclarationSignature,
    { loading: createSwornDeclarationSignatureLoading },
  ] = useMutation(CREATE_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE, {
    onError(error) {
      setSignSent(false);
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { created } = data.createSecondaryMarketSwornDeclarationSignature;
      if (created) {
        setSignSent(true);
        successMessage(
          'Se le ha enviado un código a su correo y celular.',
          enqueueSnackbar,
          {
            persist: true,
          }
        );
      }
    },
  });

  const handleCreateSwornDeclarationSignature = () => {
    createSwornDeclarationSignature({ variables: { secondaryMarketId: id } });
  };

  const [
    confirmSwornDeclarationSign,
    { loading: confirmSwornDeclarationSignLoading },
  ] = useMutation(CONFIRM_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { secondaryMarket } = data.signSecondaryMarketSwornDeclaration;
      if (secondaryMarket) {
        setSignSent(false);
        setSignedData({
          isSigned: true,
          date: moment(),
        });
        if (secondaryMarket.state === 'S') {
          successMessage(
            'Firma exitosa, puede ver los documentos en su correo electrónico.',
            enqueueSnackbar,
            {
              persist: true,
            }
          );
          const contracts = [...contractData];
          contracts[activeNode].state = true;
          contracts[activeNode].active = false;
          setActiveNode(activeNode + 1);
          setContractData(contracts);
        } else {
          errorMessage(
            'Ocurrió un error en el proceso, inténtelo más tarde',
            enqueueSnackbar
          );
        }
      }
    },
  });

  return loading ||
    LoadingContractMetadata ||
    LoadingHasSwornDeclaration ||
    !contractData ||
    !titleData ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.clientFormPage}>
      <Formik
        initialValues={formStartData}
        validationSchema={Yup.object().shape(formValidation)}
        onSubmit={(values, { setSubmitting }) => {
          if (contractData[activeNode].labelText === 'Declaración Jurada') {
            confirmSwornDeclarationSign({
              variables: {
                fundId,
                key: values.code,
              },
            });
          } else {
            confirmContractSign({
              variables: {
                clientId: client.id,
                secondaryMarketId: id,
                key: values.code,
              },
            });
          }

          setSubmitting(false);
        }}
        validateOnblur
        validateOnChange
      >
        {({
          submitForm,
          setFieldValue,
          validateForm,
          isSubmitting,
          values,
          errors,
        }) => (
          <Form className={classes.simpleForm}>
            <Grid container>
              <Hidden lgUp>
                <Fab
                  color="primary"
                  aria-label="show-tree"
                  onClick={handlePopoverOpen}
                  className={classes.floatingButton}
                >
                  <MenuOpenIcon />
                </Fab>

                <Popover
                  id="show-tree"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <TreeView
                    className={classes.rootMenuTree}
                    onNodeToggle={handleChange}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                  >
                    {contractData.map(item =>
                      item.labelText !== 'Mensaje Final' ? (
                        <StyledTreeItem
                          key={item.nodeId}
                          nodeId={item.nodeId}
                          labelText={item.labelText}
                          active={item.active}
                          state={item.state}
                          disabled={item.disabled}
                          setActive={clickTreeItem}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </TreeView>
                </Popover>
              </Hidden>
              <Hidden mdDown>
                <Grid item lg={4} xs={12} className={classes.treeContainer}>
                  <TreeView
                    className={classes.root}
                    onNodeToggle={handleChange}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                  >
                    {contractData.map(item =>
                      item.labelText !== 'Mensaje Final' ? (
                        <StyledTreeItem
                          key={item.nodeId}
                          nodeId={item.nodeId}
                          labelText={item.labelText}
                          active={item.active}
                          state={item.state}
                          disabled={item.disabled}
                          setActive={clickTreeItem}
                        />
                      ) : (
                        <></>
                      )
                    )}
                  </TreeView>
                </Grid>
              </Hidden>
              <Grid item lg={8} xs={12} className={classes.forms}>
                <Grid container>
                  <Grid item xs={12} className={classes.title}>
                    <p>{`Firma de contrato: ${titleData.fundName}`}</p>
                  </Grid>
                </Grid>
                <Grid item className={classes.contractContainer}>
                  {contractData[activeNode].labelText === 'Mensaje Final' ? (
                    <div className={classes.endMessage}>
                      <p>
                        El proceso de firma de contrato ha finalizado
                        correctamente, se le enviará un correo con los
                        documentos generados
                      </p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/cliente')}
                      >
                        Terminar
                      </Button>
                    </div>
                  ) : (
                    <GenerateContract
                      isSecondaryMarket
                      contractData={contractData[activeNode]}
                      setFieldValue={setFieldValue}
                      validateForm={validateForm}
                      errors={errors}
                      values={values}
                      nextStep={nextStep}
                      setFormValidation={validations =>
                        setFormValidation({ ...formValidation, ...validations })
                      }
                      rewriteBlocks={rewriteBlocks}
                    />
                  )}
                </Grid>
                {contractData[activeNode].hasSign && (
                  <Grid item xs={12}>
                    {signSent ? (
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        item
                        className={classes.explicationContainer}
                      >
                        <Grid item>
                          <TextField
                            name="code"
                            label="Ingrese código"
                            variant="outlined"
                            helperText="El código enviado tiene una vigencia de tres (3) minutos."
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              if (
                                contractData[activeNode].labelText ===
                                'Declaración Jurada'
                              ) {
                                handleCreateSwornDeclarationSignature();
                              } else {
                                handleSendSignClick(values);
                              }
                            }}
                          >
                            Reenviar código
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={submitForm}
                            disabled={confirmContractSignLoading}
                          >
                            Confirmar firma
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="flex-end"
                          item
                          className={classes.explicationContainer}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (
                                contractData[activeNode].labelText ===
                                'Declaración Jurada'
                              ) {
                                handleCreateSwornDeclarationSignature();
                              } else {
                                handleSendSignClick(values);
                              }
                            }}
                            disabled={
                              signLoading ||
                              createSwornDeclarationSignatureLoading
                            }
                            color="primary"
                          >
                            Firmar electrónicamente
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContracSignSecondaryMarketForm;
