import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import SafiRelationForm from '../SafiRelationForm';
import { GET_SAFI_RELATION } from '../queries';

function SafiRelationEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_SAFI_RELATION, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error(error);
    history.goBack();
  }

  const formatValue = { ...data.safiRelation };
  if (!data.safiRelation) {
    history.goBack();
  }

  return <SafiRelationForm safiRelation={formatValue} id={id} isEdit />;
}

SafiRelationEdit.propTypes = {};

export default SafiRelationEdit;
