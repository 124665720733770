import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Material Resources
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Hidden,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useLocation } from 'react-router-dom';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { GET_CURRENT_USER } from '@queries/Client';
import { LIST_CLIENT_CONTRACTS } from 'src/components/Models/ContracSign/queries';
import {
  NOTIFICATION_MAX_STACK,
  REACT_APP_LINK_FORM_F2,
} from 'src/utils/constants';
import { delAuthUser, getAuthUser } from '../../utils/storage';
import ClientFormPage from '../ModelsPages/ClientForm';
import PaymentPage from '../ModelsPages/PaymentPage';
import ClientDashboardPage from '../ModelsPages/ClientDashboard';
import DocumentsPage from '../ModelsPages/DocumentsPage';
import SignatureClientPage from '../ModelsPages/SignatureClientPage';
import NotFound from '../NotFoundPage/Loadable';
import ClientMenu from '../../components/ClientMenu';
import CustomTitle from '../../components/CustomTitle';
import { removeClient, setClient } from '../../store/sharedStore/actions';
import { edgeToList, errorMessage } from '../../utils/commonFunctions';
import LoadingIndicator from '../../components/LoadingIndicator';
import { getClientPermissionsSelector } from '../../store/permissionsStore/selector';
import { setClientPermissions } from '../../store/permissionsStore/actions';
import ContractSignPage from '../ModelsPages/ContractSignPage';
import Profile from 'src/components/Profile';

import useStyles from './styles';
import ContractSignSecondaryMarketPage from '../ModelsPages/ContractSignSecondaryMarketPage';
import InvestmentAccountStatePage from '../ModelsPages/InvestmentAccountStatePage';
import { LIST_ALL_CLIENT_CONTRACTS } from './queries';
import { Day } from '@material-ui/pickers';
import TokenGen from './utils/TokenGe';
import { getClientSelector } from '../../store/sharedStore/selectors';
import getClientMode from '../../store/utils-store/selectors';
import background from './svg/header-deco.svg';
import backgroundMobile from './svg/header-deco-mobile.svg';
import ProfileTopBar from './utils/profileTopbar';
import DocumentsGenericPage from '../ModelsPages/DocumentsGeneric';
import PartnersIcon from './svg/partnersIcon';
import Footer from './footer';
import BuhoIcon from './svg/buhoIcon_positivo';
const getClient = data => {
  const clients = edgeToList(data.currentUser, 'clientSet');
  const client = clients.find(c => c.isMain === false);
  return client || clients[0];
};

const permissionRoutes = {
  ficha_cliente: path => (
    <Route
      key={path}
      path={`${path}/ficha-cliente`}
      component={ClientFormPage}
    />
  ),
  registro_pagos: path => (
    <Route key={path} path={`${path}/registro-pagos`} component={PaymentPage} />
  ),
  dashboard: path => (
    <Route key={path} exact path={path} component={ClientDashboardPage} />
  ),
  listado_archivos: path => (
    <Route key={path} path={`${path}/documentos`} component={DocumentsPage} />
  ),
  firma_contratos: path => (
    <Route
      key={path}
      path={`${path}/firma-contratos`}
      component={ContractSignPage}
    />
  ),
  firma_contratos_mercado_secundario: path => (
    <Route
      key={path}
      path={`${path}/firma-contratos-mercado-secundario`}
      component={ContractSignSecondaryMarketPage}
    />
  ),
  estados_de_cuenta_de_inversiones: path => (
    <Route
      key={path}
      path={`${path}/estados-de-cuenta-de-inversiones`}
      component={InvestmentAccountStatePage}
    />
  ),
  documentos: path => (
    <Route key={path} path={`${path}/documentos`} component={DocumentsPage} />
  ),
  documentos: path => (
    <Route
      key={path}
      path={`${path}/documentos-generales`}
      component={DocumentsGenericPage}
    />
  ),
  firmas_cliente: path => (
    <Route key={path} path={`${path}/firmas`} component={SignatureClientPage} />
  ),
};

function ClientPage() {
  const [open, setOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isAssignee, setIsAssignee] = useState(false);
  const [idClient, setIdClient] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const clientPermissions = useSelector(getClientPermissionsSelector());
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const client = useSelector(getClientSelector());
  const [menu, setMenu] = useState([]);
  const [secondLogo, setSecondLogo] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);

  useEffect(() => {
    if (client && client.isEmployee) {
      setIsEmployee(true);
    }
  }, [client]);

  const [getContracts] = useLazyQuery(LIST_ALL_CLIENT_CONTRACTS, {
    variables: { id: idClient },
    onCompleted(data) {
      if (
        data.listParticipantVerifiedPayment &&
        data.listSecondaryMarketVerified
      ) {
        const contracts = [];
        const participations = edgeToList(
          data,
          'listParticipantVerifiedPayment',
        );
        const secondaryMarkets = edgeToList(
          data,
          'listSecondaryMarketVerified',
        );
        const pendingSignatures = data.hasPendingSignatures;
        if (pendingSignatures && !isEmployee) {
          contracts.push({
            id: '',
            isSecondaryMarket: false,
            fundName: '',
          });
        }
        participations.forEach(participant => {
          const raisingParticipants = edgeToList(
            participant,
            'raisingparticipantSet',
          );
          if (raisingParticipants && raisingParticipants.length > 0) {
            contracts.push({
              id: participant.id,
              raisingNumber: raisingParticipants[0].raising.number,
              fundName: raisingParticipants[0].raising.fund.businessName,
            });
          }
        });

        secondaryMarkets.forEach(sm => {
          contracts.push({
            id: sm.id,
            isSecondaryMarket: true,
            fundName: sm.fund.businessName,
          });
        });
        contracts.forEach((contract, index) => {
          // The max number of alerts for the app is 3
          if (index < NOTIFICATION_MAX_STACK) {
            enqueueSnackbar(
              contract.fundName
                ? `El contrato para el fondo ${contract.fundName} está listo para firmarse`
                : 'Tiene procesos de firma pendientes',
              {
                persist: true,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                action: key => (
                  <>
                    <Button
                      variant="outlined"
                      // Inline styles because is out of the navigation
                      style={{
                        borderColor: '#ffffff',
                      }}
                      onClick={() => {
                        if (contract.isSecondaryMarket) {
                          history.push(
                            `/cliente/firma-contratos-mercado-secundario/${contract.id}`,
                          );
                        } else if (contract.id) {
                          history.push(
                            `/cliente/firma-contratos/${contract.id}`,
                          );
                        } else {
                          history.push(`/cliente/firmas`);
                        }
                      }}
                    >
                      Aquí
                    </Button>
                    <IconButton
                      variant="contained"
                      size="small"
                      // Inline styles because is out of the navigation
                      style={{
                        color: '#ffffff',
                      }}
                      onClick={() => {
                        closeSnackbar(key);
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </>
                ),
              },
            );
          }
        });
        if (isAssignee) {
          history.push('/cliente/firma-contratos');
        }
      }
    },
  });

  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  // Function to setMenu of localStorage
  useEffect(() => {
    const menu = localStorage.getItem('menu');
    setMenu(JSON.parse(menu));
  }, []);

  useEffect(() => {
    if (clients) {
      const currentClient = getClient(clients);
      const assignees = edgeToList(currentClient, 'clientAssignee');
      if (assignees.length) {
        setIsAssignee(true);
      }
      getContracts();
      setIdClient(currentClient.id);
      dispatch(setClient(currentClient));
      const permissions = edgeToList(
        clients.currentUser,
        'frontendPermissions',
      ).map(p => p.slug);
      dispatch(setClientPermissions(permissions));
      if (permissions.length == 1 && permissions[0] == ['ficha_cliente']) {
        const UserToken = TokenGen(client, clients, 'investor');
        const url = `${REACT_APP_LINK_FORM_F2}?token=${UserToken}`;
        window.location.href = url;
        setMenu(['ficha_cliente']);
        setNewClient(true);
      }
    }
  }, [clients, dispatch]);

  useEffect(() => {
    if (clientPermissions.length != 1) {
      setNewClient(false);
    }
  }, [clientPermissions]);

  const handleClickUser = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    handleDrawerToggle();
  };
  const handleDrawerClose = () => {
    setOpen(false);
    handleDrawerToggle();
  };
  const handleProfileUser = () => {
    history.push('/cliente/perfil');
    handleCloseUser();
  };
  const handleLogOut = () => {
    delAuthUser();
    dispatch(removeClient());
    window.location.href = '/';
  };

  const resetMenu = () => {
    setMenu([]);
    setSecondLogo(false);
  };
  const authUser = getAuthUser();
  const modeClient = useSelector(getClientMode());
  const drawer = (
    <div style={{ alignItems: 'center' }}>
      {authUser.usergroup === 'client_group' && (
        <div className={classes.IconDiv}>
          <div
            style={{
              cursor: 'pointer',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={() => {
              history.push('/cliente');
            }}
          >
            <BuhoIcon color={modeClient ? '#A6C1D3' : '#111918'} />
          </div>

          {open && (
            <div className={classes.menuDiv}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
              >
                <MenuIcon color={modeClient ? 'primary' : 'secondary'} />
              </IconButton>
            </div>
          )}
        </div>
      )}
      <div className={classes.customToolbar} />
      <ClientMenu open={open} menu={menu} resetMenu={resetMenu} />
    </div>
  );

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const divStyleDesktop = {
    backgroundImage: `url(${background})`,
    backgroundSize: 'contain',
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    // height: '100vh',
    // width: '100vw',
  };
  const divStyleMobile = {
    backgroundImage: `url(${backgroundMobile})`,
    backgroundSize: 'contain',

    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    // height: '100vh',
    // width: '100vw',
  };

  const adaptBackgorund = windowWidth < 680 ? divStyleMobile : divStyleDesktop;

  const styleSelector = modeClient
    ? classes.appBarEmployeeDark
    : classes.appBarEmployee;

  const styleSelectordrawerPaperBigScreen = modeClient
    ? classes.drawerPaperBigScreenDark
    : classes.drawerPaperBigScreenLight;

  const styleSelectorDrawerClose = modeClient
    ? classes.drawerCloseDark
    : classes.drawerCloseLight;

  const styleSelectorContent = classes.contentLayout;

  const changeColors = modeClient
    ? classes.clientPageDark
    : classes.clientPageEmployee;

  const styleModeSelector = modeClient
    ? classes.appToolbarDark
    : classes.appToolbarEmployee;
  const [currentPath, setCurrentPath] = useState('');
  const layoutOutOfDashboard = currentPath !== '/cliente';
  const layout = layoutOutOfDashboard
    ? modeClient
      ? classes.layoutDarkForSections
      : classes.layoutLightForSections
    : modeClient
    ? classes.layoutDark
    : classes.layoutLight;
  const location = useLocation();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <div className={changeColors} style={adaptBackgorund}>
        <AppBar position="fixed" className={styleSelector}>
          <Toolbar className={styleModeSelector}>
            <div className={classes.toolbarItem}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
              >
                <MenuIcon color={modeClient ? 'primary' : 'secondary'} />
              </IconButton>
              <CustomTitle secondLogo={secondLogo} />
            </div>
            {currentPath !== '/cliente' && (
              <div className={classes.toolbarItem}>
                <ProfileTopBar />
              </div>
            )}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseUser}
            >
              <MenuItem onClick={handleProfileUser} id="cambio_contrasena_menu">
                Cambio de contraseña
              </MenuItem>
              <MenuItem onClick={handleLogOut} id="salir_menu">
                Salir
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            classes={{
              paper: clsx({
                [styleSelectordrawerPaperBigScreen]: open,
                [styleSelectorDrawerClose]: !open,
              }),
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={styleSelectorContent}>
          {/* <div className={classes.toolbar} /> */}
          <div className={layout}>
            <Switch>
              {clientPermissions.map(p => {
                if (p === 'dashboard') {
                  return null;
                } else if (p === 'ficha_cliente' && !isEmployee) {
                  return null;
                } else if (p === 'ficha_cliente' && isEmployee) {
                  return permissionRoutes[p](path);
                } else {
                  return permissionRoutes[p](path);
                }
              })}
              <Route
                key={path}
                exact
                path={path}
                render={props => (
                  <ClientDashboardPage
                    {...props}
                    setMenu={setMenu}
                    setSecondLogo={setSecondLogo}
                    newClient={newClient}
                    isEmployee={isEmployee}
                  />
                )}
              />
              <Route path={`${path}/perfil`} component={Profile} />
              <Route path="" component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </main>
      </div>
    </>
  );
}

export default ClientPage;
