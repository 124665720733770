/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../../NotFoundPage';
import ClientList from '../../../../components/Models/Users/Client/ClientList';
import ClientEdit from '../../../../components/Models/Users/Client/ClientCreateEdit/edit';
import ClientForm from '../../../../components/Models/Users/Client/ClientForm';

function ClientPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ClientList} />
      <Route path={`${path}/crear`} component={ClientForm} />
      <Route path={`${path}/:id`} component={ClientEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default ClientPage;
