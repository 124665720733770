import React, { useState } from 'react';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import Yup from '../../../../utils/yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_BANK_ACCOUNT,
  CREATE_BANK_ACCOUNT,
} from '../../../Models/BankAccount/queries';
import { validationFunctionBankAccount } from './validation';
import {
  edgeToList,
  parseEquivalent,
  getOptions,
} from '../../../../utils/commonFunctions';
import { LIST_BANKS } from '../../../Models/Bank/queries';
import { LIST_ACCOUNT_TYPES } from '../../../Models/AccountType/queries';
import { LIST_CURRENCIES } from '../../../Models/Currency/queries';
import { LIST_FUNDS, GET_FUND } from '../../../Models/Fund/queries';
import { FORM_OPTIONS } from '../../../StepsComponents/constants';

const startValues = {
  bank: '',
  currency: '',
  accountType: '',
  accountNumber: '',
  cci: '',
  aba: '',
  swift: '',
  country: '',
  jointAccount: 'true',
  observations: '',
};

function InnerBankAccountForm(props) {
  const {
    bankAccount,
    id,
    isEdit,
    afterEditCreate = () => {},
    onBankAccountCreated = () => {},
    fundID,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isPeru, setIsPeru] = useState(false);

  const updateBankAccount = useMutation(
    UPDATE_BANK_ACCOUNT,
    getOptions({
      mutationName: 'updateBankAccount',
      modelName: 'bankAccount',
      message: 'Cuenta bancaria actualizada con éxito.',
      completeCallback: () => afterEditCreate(),
      enqueueSnackbar,
    }),
  );
  const createBankAccount = useMutation(
    CREATE_BANK_ACCOUNT,
    getOptions({
      mutationName: 'createBankAccount',
      modelName: 'bankAccount',
      message: 'Cuenta bancaria creada con éxito.',
      completeCallback: (data, errors) => {
        afterEditCreate();
        onBankAccountCreated(data.id);
      },
      enqueueSnackbar,
      update(cache, { data: createBankAccountData }) {
        if (cache.data.data.ROOT_QUERY[`fund({"id":"${fundID}"})`]) {
          const { fund } = cache.readQuery({
            query: GET_FUND,
            variables: { id: fundID },
          });
          fund.accounts.edges.unshift({
            node: createBankAccountData.createBankAccount.bankAccount,
            __typename: 'BankAccountTypeEdge',
          });
          cache.writeQuery({
            query: GET_FUND,
            variables: { id: fundID },
            data: { fund },
          });
        }
      },
    }),
  );

  // SELECT QUERIES
  const {
    loading: BanksLoading,
    error: BanksError,
    data: BanksData,
  } = useQuery(LIST_BANKS);

  const {
    loading: AccountTypesLoading,
    error: AccountTypesError,
    data: AccountTypesData,
  } = useQuery(LIST_ACCOUNT_TYPES);
  const {
    loading: CurrenciesLoading,
    error: CurrenciesError,
    data: CurrenciesData,
  } = useQuery(LIST_CURRENCIES);

  const {
    loading: FundsLoading,
    error: FundsError,
    data: FundsData,
  } = useQuery(LIST_FUNDS);

  if (BanksError) {
    console.error('Client board bank data - Banks', BanksError);
  }
  if (AccountTypesError) {
    console.error('Client board bank data - Accounts', AccountTypesError);
  }
  if (CurrenciesError) {
    console.error('Client board bank data - Currencies', CurrenciesError);
  }
  if (FundsError) {
    console.error('Client board bank data - Funds', FundsError);
  }

  const validationSchemaBankAccount = Yup.object().shape({
    bank: Yup.string().required(),
    currency: Yup.string().required(),
    accountType: Yup.string().required(),
    accountNumber: Yup.string().required(),
    cci: Yup.string().required(),
    country: Yup.string().required(),
    jointAccount: Yup.string().required(),
    swift: Yup.string().when({
      is: () => !isPeru,
      then: Yup.string().required(
        'Este campo es requerido para cuentas extranjeras',
      ),
    }),
  });

  const data = [
    {
      label: 'Banco',
      name: 'bank',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(BanksData, 'listBank'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Tipo de cuenta',
      name: 'accountType',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(AccountTypesData, 'listAccountType'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Moneda',
      name: 'currency',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(CurrenciesData, 'listCurrencies'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'País',
      name: 'country',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.country,
      checkPeru: val => setIsPeru(val),
    },
    {
      label: 'Número de cuenta',
      name: 'accountNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        allowLeadingZeros: true,
        allowNegative: false,
      },
    },
    {
      label: 'Código de cuenta interbancario',
      name: 'cci',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Swift',
      name: 'swift',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'ABA',
      name: 'aba',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: '¿Es una cuenta mancomunada?',
      name: 'jointAccount',
      gridMD: 4,
      data: FORM_OPTIONS.yesNo,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
    },
    {
      label: 'Observaciones',
      name: 'observations',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
      textFieldProps: { multiline: true },
    },
  ];

  function formatBeforeSubmit(values) {
    const formatValues = { ...values };
    formatValues.jointAccount = parseEquivalent(formatValues.jointAccount);
    formatValues.fund = fundID;
    return formatValues;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        formatBeforeSubmit={formatBeforeSubmit}
        validateFunction={validationFunctionBankAccount}
        validateSchema={validationSchemaBankAccount}
        data={data}
        model={bankAccount}
        id={id}
        modelName="Cuenta bancaria"
        routeName=""
        isEdit={isEdit}
        hasCancel
        canBeDeleted={false}
        onCancel={afterEditCreate}
        updateMutation={updateBankAccount}
        createMutation={createBankAccount}
      />
    </>
  );
}

InnerBankAccountForm.propTypes = {
  bankAccount: PropTypes.shape(),
  id: PropTypes.string,
  fundID: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  afterEditCreate: PropTypes.func.isRequired,
  onBankAccountCreated: PropTypes.func.isRequired,
};

InnerBankAccountForm.defaultProps = {
  bankAccount: null,
  id: '',
  isEdit: false,
};

export default InnerBankAccountForm;
