import React, { useState, useEffect } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';

// Styles & Images
import CustomSelect from '../CustomSelect';

function ConditionalSelectsInput(props) {
  const {
    id,
    name,
    label,
    data,
    mapData,
    gridMD,
    items,
    errors,
    initialValue,
  } = props;
  const [itemSet, setItemSet] = useState([]);

  useEffect(() => {
    if (initialValue) {
      const items_tmp = items.find(itemSet => itemSet.value === initialValue);
      items_tmp && setItemSet(items_tmp);
    }
  }, [initialValue]);

  function handleChange(event) {
    const items_tmp = items.find(
      itemSet => itemSet.value === event.target.value
    );
    items_tmp && setItemSet(items_tmp);
  }

  return (
    <>
      <Grid item md={gridMD[0]} xs={12}>
        <CustomSelect
          name={name}
          label={label}
          variant="outlined"
          id={id}
          data={data}
          mapData={mapData}
          onChange={handleChange}
        />
      </Grid>
      {itemSet.selects &&
        itemSet.selects.map(select => (
          <Grid item md={select.gridMD} xs={12}>
            <CustomSelect
              name={select.name}
              label={select.label}
              variant="outlined"
              id={`${select.name}-select`}
              data={select.data ? select.data : []}
              mapData={select.mapData}
              error={errors[select.name]}
            />
          </Grid>
        ))}
    </>
  );
}
ConditionalSelectsInput.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  gridMD: PropTypes.arrayOf(PropTypes.number),
};
ConditionalSelectsInput.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  gridMD: [3, 3],
  defaultOther: true,
  data: [],
};

export default ConditionalSelectsInput;
