import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    padding: '4%',
    textAlign: 'center',
  },

  succes: {
    color: theme.palette.colors.green,
    fontWeight: 400,
  },
  error: {
    color: theme.palette.colors.red,
    fontWeight: 400,
  },
}));
