import React, { useState, useEffect } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// GraphQl
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';

// Others
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import { edgeToList } from '../../../utils/commonFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import { useFormikContext } from 'formik';
import { reorderCountry } from '../PlacesSelects';

const LIST_COUNTRIES = gql`
  {
    listCountries {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;
export const SEARCH_PERU = gql`
  {
    listCountries(code: "PE") {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

function CountrySelect(props) {
  const { id, name, label, error, checkPeru, isStepForm } = props;
  const [peruID, setPeruID] = useState('');
  const { values: formValues } = useFormikContext();

  const { error: peruError, data: peruData, loading: peruLoading } = useQuery(
    SEARCH_PERU
  );
  const {
    error: countryError,
    data: countryData,
    loading: countryLoading,
  } = useQuery(LIST_COUNTRIES);

  if (countryError) {
    console.error('country error', countryError);
  }
  if (peruError) {
    console.error('Peru error', peruError);
  }

  useEffect(() => {
    if (!peruLoading && peruData) {
      setPeruID(peruData.listCountries.edges[0].node.id);
      if (formValues[name] !== '') {
        checkPeru(formValues[name] === peruData.listCountries.edges[0].node.id);
      }
    }
  }, [peruLoading]);

  const handleOnChange = e => checkPeru(e.target.value === peruID);

  return (
    <>
      {!countryLoading ? (
        <CustomSelect
          id={id}
          name={name}
          label={label}
          data={reorderCountry(edgeToList(countryData, 'listCountries'))}
          mapData={{ value: 'id', label: 'name' }}
          error={error}
          onChange={handleOnChange}
          isStepForm={isStepForm}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}
CountrySelect.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checkPeru: PropTypes.func,
};

CountrySelect.defaultProps = {
  checkPeru: isPeru => isPeru,
};

export default CountrySelect;
