import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: theme.spacing(0.5),
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  photoDocuments: {
    display: 'flex',
    flexFlow: 'column',
    '& img': {
      padding: '3%',
    },
  },
  modalContent: {
    padding: '2rem',
    height: '80vh',
    overflowY: 'auto',
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'center',
  },

}));
