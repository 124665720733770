import React, { useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField as MuiTextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormikContext } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import PropTypes from 'prop-types';

import useStyles from './styles';
import { LIST_DOCUMENT_TYPES } from '../../Models/DocumentType/queries';
import { edgeToList } from '../../../utils/commonFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import { GET_CLIENT_BY_DOCUMENT_AND_RAISING } from '../queries';

export function GridClientField(props) {
  const styles = useStyles();
  const { label, value } = props;

  return (
    <Grid container item xs={6}>
      <Grid item xs={4}>
        <p className={styles.textLabel}>{label}:</p>
      </Grid>
      <Grid item xs={6}>
        <MuiTextField
          className={styles.textDisabled}
          disabled
          fullWidth
          variant="outlined"
          value={value}
        />
      </Grid>
    </Grid>
  );
}

GridClientField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function StepTwoForm(props) {
  const styles = useStyles();
  const { repurchaseID } = useParams();
  const { setParticipantInList, closeForm, isEdit } = props;
  let existingList = [];
  if ('data' in props) {
    const { data } = props;
    existingList = data;
  }
  const {
    values: formValues,
    errors: formErrors,
    setFieldValue,
    validateForm,
    setErrors,
  } = useFormikContext();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [invalidUser, setInvalidUser] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [clientInformation, setClientInformation] = useState({
    phone: '',
    cellphone: '',
    communicationCellphone: '',
    communicationEmail: '',
    user: {
      lastName: '',
      maternalSurname: '',
      firstName: '',
      emailValidated: '',
    },
  });

  let stepTwoErrors = [];
  const { data: documentTypesData, loading } = useQuery(LIST_DOCUMENT_TYPES);
  const [getClientInformation, { data: clientData }] = useLazyQuery(
    GET_CLIENT_BY_DOCUMENT_AND_RAISING,
    {
      fetchPolicy: 'no-cache',
      onError: errors => {
        errors.graphQLErrors.map(function({ message }, i) {
          stepTwoErrors.push(message);
        });
        setErrors({ mainUser: stepTwoErrors });
        setClientInformation({
          phone: '',
          cellphone: '',
          communicationCellphone: '',
          communicationEmail: '',
          user: {
            lastName: '',
            maternalSurname: '',
            firstName: '',
            emailValidated: '',
          },
        });
        setInvalidUser(true);
      },
      onCompleted: data => {
        setInvalidUser(false);
        setFieldValue('mainUser', []);
        validateForm();
      },
    },
  );

  useEffect(() => {
    setFieldValue('coparticipants', existingList);
  }, []);

  useEffect(() => {
    if (documentTypesData) {
      const docs = edgeToList(documentTypesData, 'listDocumentTypes');
      setDocumentTypes(docs);
      setSelectedDocumentType(docs[0].id);
    }
  }, [documentTypesData]);

  useEffect(() => {
    if (clientData && clientData.getClientByDocumentAndRepurchase) {
      setClientInformation(clientData.getClientByDocumentAndRepurchase);
      setFieldValue('clientID', clientData.getClientByDocumentAndRepurchase.id);
      setFieldValue(
        'clientName',
        `${clientData.getClientByDocumentAndRepurchase.user.firstName}
        ${clientData.getClientByDocumentAndRepurchase.user.lastName}`,
      );
      setFieldValue(
        'communicationCellphone',
        formValues.communicationCellphone ||
          clientData.getClientByDocumentAndRepurchase.communicationCellphone,
      );
      setFieldValue(
        'communicationEmail',
        formValues.communicationEmail ||
          clientData.getClientByDocumentAndRepurchase.communicationEmail,
      );
    }
    validateForm();
  }, [clientData]);

  useEffect(() => {
    if (
      formValues.documentNumber &&
      ((selectedDocumentType && selectedDocumentType !== '') ||
        (formValues.documentType && formValues.documentType !== ''))
    ) {
      const docs = edgeToList(documentTypesData, 'listDocumentTypes');
      const currentSelectedDocumentType =
        formValues.documentType || selectedDocumentType;
      const nameTypeId = docs.find(
        element => element.id === currentSelectedDocumentType,
      ).name;
      getClientInformation({
        variables: {
          repurchaseId: repurchaseID,
          documentNumber: formValues.documentNumber,
          documentType: nameTypeId,
        },
      });
    }
  }, [
    formValues.documentNumber,
    formValues.documentType,
    selectedDocumentType,
  ]);

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Grid
      container
      justify="center"
      row="true"
      classes={{ root: styles.gridRoot }}
      spacing={3}
    >
      <Grid container item xs={12} className={styles.title}>
        <p>Ingreso de Datos</p>
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={4}>
          <p>Tipo de Documento</p>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={styles.formControl}>
            <Select
              variant="outlined"
              id="documentType"
              labelId="documentType"
              value={formValues.documentType || selectedDocumentType}
              onChange={$ev => {
                setSelectedDocumentType($ev.target.value);
                setFieldValue('documentType', $ev.target.value);
              }}
              autoWidth
            >
              {documentTypes.map(dt => (
                <MenuItem key={dt.id} value={dt.id} className={styles.item}>
                  {dt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={4}>
          <p>Número de Documento</p>
        </Grid>
        <Grid item xs={6}>
          <TextField name="documentNumber" variant="outlined" fullWidth />
        </Grid>
      </Grid>
      {clientInformation ? (
        <>
          <GridClientField
            label="Apellido paterno"
            value={clientInformation.user.lastName}
          />
          <GridClientField
            label="Apellido materno"
            value={clientInformation.user.maternalSurname}
          />
          <GridClientField
            label="Nombres"
            value={clientInformation.user.firstName}
          />
          <GridClientField
            label="Teléfono fijo"
            value={clientInformation.phone}
          />
          <GridClientField
            label="Teléfono Celular"
            value={clientInformation.cellphone}
          />
          <GridClientField
            label="Correo Electrónico"
            value={clientInformation.user.emailValidated}
          />
          <Grid container item xs={11}>
            <Grid item xs={4}>
              <p>Tel. celular para comunicaciones:</p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="communicationCellphone"
                type="number"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={11}>
            <Grid item xs={4}>
              <p>Correo para comunicaciones:</p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="communicationEmail"
                type="email"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>

          {setParticipantInList && (
            <>
              <Grid container item xs={11}>
                <Grid item xs={4}>
                  <p>¿Representante de los copartícipes?</p>
                </Grid>
                <Grid item xs={6}>
                  <Grid container item alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch name="isRepresentative" />
                    </Grid>
                    <Grid item>Sí</Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={11}>
                <Grid item xs={4}>
                  <p>Porcentaje de participación:</p>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="participationPercentage"
                    fullWidth
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </>
          )}
          {invalidUser && formErrors.mainUser && (
            <Grid item xs={12}>
              {formErrors.mainUser.map(errorMessage => (
                <Alert severity="error">{errorMessage}</Alert>
              ))}
            </Grid>
          )}
          {setParticipantInList && (
            <>
              <Grid container direction="row" className={styles.buttons}>
                <Grid container item xs={6} justify="flex-start">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      closeForm();
                      setErrors({});
                    }}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid container item xs={6} justify="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={Object.keys(formErrors).length !== 0}
                    onClick={() => {
                      const participant = {
                        clientID: formValues.clientID,
                        documentType: formValues.documentType,
                        documentNumber: formValues.documentNumber,
                        clientName: formValues.clientName,
                        communicationCellphone:
                          formValues.communicationCellphone,
                        communicationEmail: formValues.communicationEmail,
                        participationPercentage:
                          formValues.participationPercentage,
                        isRepresentative: !!formValues.isRepresentative,
                      };
                      setParticipantInList(participant);
                    }}
                  >
                    {isEdit ? 'Guardar' : 'Añadir'}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}

StepTwoForm.propTypes = {
  setParticipantInList: PropTypes.func,
  closeForm: PropTypes.func,
  isEdit: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
};

StepTwoForm.defaultProps = {
  setParticipantInList: null,
  closeForm: null,
  isEdit: false,
};

export default StepTwoForm;
