import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    marginTop: 30,
    marginBottom: 40,
    borderRadius: theme.spacing(0.5) + 2,
    transition: '0.3s',
    width: '100%',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 4px 1px rgba(0,0,0,.25)',
  },
  cardDark: {
    marginTop: 30,
    marginBottom: 40,
    borderRadius: theme.spacing(0.5) + 2,
    transition: '0.3s',
    width: '100%',
    overflow: 'initial',
    background: '#323232',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 4px 1px rgba(0,0,0,.25)',
  },
  cardHeaderRoot: {
    padding: 15,
    borderRadius: 3,
    marginTop: -20,
    background: `linear-gradient(60deg,${theme.palette.primary.dark},${theme.palette.primary.main})`,
    boxShadow:
      '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 57, 92, 0.4)',
  },
  title: {
    fontSize: '1.125rem',
    color: theme.palette.colors.white,
    textTransform: 'capitalize',
  },
  subtitle: {
    '& span': {
      color: theme.palette.colors.white,
      fontWeight: 600,
      letterSpacing: 1,
    },
  },
  content: {
    padding: '.9375rem 20px',
  },
  flexContent: {
    padding: '.9375rem 20px',
    display: 'flex',
    flexFlow: 'wrap',
  },
}));
