import React from 'react';
import cx from 'clsx';
import { Typography, Grid, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import GetApp from '@material-ui/icons/GetApp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StopIcon from '@material-ui/icons/Stop';

import {
  edgeToList,
  toMoneyRepresentation,
  parseDatesInObject,
} from '../../../utils/commonFunctions';

import useStyles from './styles';
import { REACT_APP_SERVER_LINK } from '../../../utils/constants';
import NumberFormat from 'react-number-format';

function Title(props) {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <StopIcon className={styles.squareBullet} />
      <ListItemText className={styles.listTitle}>{children}</ListItemText>
    </ListItem>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};
const Cell = props => {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <ListItemText className={styles.listCell}>{children}</ListItemText>
    </ListItem>
  );
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
};

function StepOneForm(props) {
  let { repurchase } = props;
  repurchase = parseDatesInObject(repurchase);
  const fund = parseDatesInObject(repurchase.fund);

  const filterDocuments = () => {
    const uniqueFileTypes = [];
    return edgeToList(fund, 'funddocumentSet').filter(document => {
      if (document.state && uniqueFileTypes.indexOf(document.fileType) === -1) {
        uniqueFileTypes.push(document.fileType);
        return true;
      }
      return false;
    });
  };

  const styles = useStyles();
  const currency = `${fund.currency.code}`;
  const documents = filterDocuments();
  const wheels = edgeToList(repurchase, 'repurchasewheelSet').map(wheel =>
    parseDatesInObject(wheel),
  );

  const getIcon = fileType => {
    if (fileType === 'P') {
      return <DescriptionOutlined classes={{ root: styles.documentIcon }} />;
    }
    return <DescriptionOutlined classes={{ root: styles.documentIcon }} />;
  };

  const getName = fileType => {
    if (fileType === 'P') {
      return 'PPT';
    }
    if (fileType === 'R') {
      return 'REGLAMENTO';
    }
    return 'RESUMEN';
  };

  const getFundsType = () => {
    switch (fund.fundType) {
      case 'PR':
        return 'privadas';
      case 'PB':
        return 'públicas';
      default:
        return '';
    }
  };

  return (
    <Grid container justify="center">
      <Grid
        container
        justify="space-evenly"
        classes={{ root: styles.documents }}
      >
        {documents.map(document => (
          <Grid item className={styles.document} key={document.file}>
            {getIcon(document.fileType)}
            {getName(document.fileType)}
            <a
              className={styles.downloadButton}
              href={`${REACT_APP_SERVER_LINK}/media/${document.file}`}
              target="_blank"
            >
              <GetApp className={styles.downloadIcon} />
              <span>Descargar</span>
            </a>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={styles.table}
        direction="row"
        alignContent="flex-start"
      >
        <Grid item xs={12} className={styles.tableHead}>
          <MonetizationOnIcon className={styles.rowIcon} />
          <h3>CANTIDADES Y MONTOS</h3>
        </Grid>
        <Grid item xs={6}>
          <Title>Monto de recompra:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency} {toMoneyRepresentation(repurchase.targetCapitalIncrease)}
          </Cell>
        </Grid>
        {/* <Grid item xs={6}>
          <Title>Inversión mínima por partícipe:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency} {toMoneyRepresentation(fund.minimumInvestment)}{' '}
            (inversiones por montos menores deberán obligarse a completar dicho
            monto en siguientes, ofertas {getFundsType()}).
          </Cell>
        </Grid> */}
        <Grid item xs={6}>
          <Title>Valor nominal de las cuotas</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency}{' '}
            <NumberFormat
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              value={toMoneyRepresentation(repurchase.valueFeeNominal)}
            />
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Valor de recompra de las cuotas</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency}{' '}
            <NumberFormat
              displayType="text"
              thousandSeparator
              decimalScale={10}
              fixedDecimalScale
              value={toMoneyRepresentation(repurchase.valueFee, 10)}
            />
          </Cell>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={cx(styles.table, styles.leftDivider)}
        direction="row"
      >
        <Grid item xs={12} className={styles.tableHead}>
          <EventAvailableIcon className={styles.rowIcon} />
          <h3>FECHAS</h3>
        </Grid>
        <Grid item xs={6}>
          <Title>Fecha de recepción de órdenes de participación:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {repurchase.initialParticipationOrderReceptionDate} al{' '}
            {repurchase.lastParticipationOrderReceptionDate}
          </Cell>
        </Grid>
        {wheels.length ? (
          <>
            <Grid item xs={6}>
              <Title>Ruedas:</Title>
            </Grid>
            <Grid item xs={6} className={styles.wheelDates}>
              <Cell className={styles.wheelDates}>
                {wheels.map(
                  (wheel, idx) =>
                    `Rueda ${idx + 1} ${wheel.dateInitial} al ${
                      wheel.dateEnd
                    }\n`,
                )}
              </Cell>
            </Grid>
            <Grid item xs={6}>
              <Title>Comunicación de adjudicación de cuotas:</Title>
            </Grid>
            <Grid item xs={6} className={styles.wheelDates}>
              <Cell>
                {wheels.map(
                  (wheel, idx) => `Rueda ${idx + 1} ${wheel.dateAward}\n`,
                )}
              </Cell>
            </Grid>{' '}
          </>
        ) : null}
        <Grid item xs={6}>
          <Title>Pago de cuotas:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {repurchase.initialFeePaymentDate} al{' '}
            {repurchase.lastFeePaymentDate}
          </Cell>
        </Grid>
        {/* <Grid item xs={6}>
          <Title>Firma de contrato:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {repurchase.dateInitialContract} al {repurchase.dateEndContract}
          </Cell>
        </Grid> */}
        <Grid item xs={6}>
          <Title>Fecha de emisión de cuotas:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>{repurchase.dateEmissionFee}</Cell>
        </Grid>
        {/* <Grid item xs={6}>
          <Title>Fecha de envío de certificados de participación:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {repurchase.dateInitialCertificateSent} al{' '}
            {repurchase.dateEndCertificateSent}
          </Cell>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

StepOneForm.propTypes = {
  repurchase: PropTypes.shape().isRequired,
};

export default StepOneForm;
