import Yup from '../../../../utils/yup';

export function validationFunctionSafiRelation(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaSafiRelation = Yup.object().shape({
  name: Yup.string().required(),
  typeRelation: Yup.string().required(),
});
