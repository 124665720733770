export const ACTIONS = {
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',
  SET_CLIENT_PERMISSIONS: 'SET_CLIENT_PERMISSIONS',
  REMOVE_CLIENT_PERMISSIONS: 'REMOVE_CLIENT_PERMISSIONS',
};

export function setPermissions(permissions) {
  return {
    type: ACTIONS.SET_PERMISSIONS,
    permissions,
  };
}

export function setUserPermissions(userPermissions) {
  return {
    type: ACTIONS.SET_USER_PERMISSIONS,
    userPermissions,
  };
}

export function setClientPermissions(clientPermissions) {
  return {
    type: ACTIONS.SET_CLIENT_PERMISSIONS,
    clientPermissions,
  };
}

export function removeClientPermissions() {
  return {
    type: ACTIONS.REMOVE_CLIENT_PERMISSIONS,
  };
}
