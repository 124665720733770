import React from 'react';
import CryptoJS from 'crypto-js';

import { getDBIdFromGraphqlId } from 'src/utils/commonFunctions';

const TokenGen = (client, clients, role) => {
  const base64url = source => {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  };

  const token = () => {
    let header = {
      alg: 'HS256',
      typ: 'JWT',
    };

    let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    let encodedHeader = base64url(stringifiedHeader);
    let id = '';
    if (clients && clients.currentUser)
      id = getDBIdFromGraphqlId(clients?.currentUser?.id, 'UserType');
    else id = getDBIdFromGraphqlId(client.user.id, 'UserType');
    let data = {
      id: id,
      documentType: client?.user?.documentType.code,
      documentNumber: client?.user?.documentNumber,
      communicationEmail: client?.user?.email,
      firstName: client?.user?.firstName,
      lastName: client?.user?.lastName,
      maternalSurname: client?.user?.maternalSurname,
      role: role,
    };
    let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    let encodedData = base64url(stringifiedData);
    let secret = 'core2';
    let unsigned_token = encodedHeader + '.' + encodedData;
    let signature = CryptoJS.HmacSHA256(unsigned_token, secret);
    signature = base64url(signature);
    let signedToken = unsigned_token + '.' + signature;

    return signedToken;
  };

  const UserToken = token();

  return UserToken;
};

export default TokenGen;
