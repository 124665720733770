import React from 'react';

import { Typography, Grid, ListItem, ListItemText } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import MUIDataTable from 'mui-datatables';
import cx from 'clsx';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import NumberFormat from 'react-number-format';

import { parseDatesInObject } from '../../../utils/commonFunctions';
import CustomCard from '../../CustomCard';
import { textLabels } from '../../../translations/components/MUIDataTable';
import useStyles from './styles';

function Title(props) {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <StopIcon className={styles.squareBullet} />
      <ListItemText className={styles.listTitle}>{children}</ListItemText>
    </ListItem>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};

function StepFourForm(props) {
  let { repurchase } = props;
  const fund = parseDatesInObject(repurchase.fund);
  const currency = `${fund.currency.code}`;

  const styles = useStyles();
  const { values } = useFormikContext();

  const columns = [
    { name: 'clientName', label: 'Nombre' },
    {
      name: 'participationPercentage',
      label: 'Porcentaje de participación',
      options: {
        customBodyRender: value => `${value} %`,
      },
    },
    {
      name: 'isRepresentative',
      label: 'Representante',
      options: {
        customBodyRender: value => (
          <CheckCircleIcon color={value ? 'secondary' : 'disabled'} />
        ),
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'scroll',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    textLabels,
  };

  return (
    <Grid container justify="center">
      <Grid container item justify="space-around" xs={12}>
        <Grid item xs={12} className={cx(styles.sectionText, styles.tableHead)}>
          <h3>PARTICIPACIÓN DE LA RECOMPRA DE CUOTAS</h3>
        </Grid>
      </Grid>
      <Grid container item justify="center" direction="row" xs={12}>
        <Grid item md={5} xs={12} className={styles.inputContainer}>
          <Title>Monto a recomprar</Title>
        </Grid>
        <Grid
          container
          item
          md={5}
          xs={12}
          alignItems="center"
          className={styles.inputContainer}
        >
          <NumberFormat
            className={styles.text}
            displayType="text"
            prefix={currency + ' '}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            value={values.amountToInvest}
          />
        </Grid>
        <Grid item md={5} xs={12} className={styles.inputContainer}>
          <Title>Número de cuotas</Title>
        </Grid>
        <Grid
          container
          item
          md={5}
          xs={12}
          alignItems="center"
          className={styles.inputContainer}
        >
          <NumberFormat
            className={styles.text}
            displayType="text"
            thousandSeparator
            value={values.feeCount}
          />
        </Grid>
        <Grid item md={5} xs={12} className={styles.inputContainer}>
          <Title>Nombre</Title>
        </Grid>
        <Grid
          container
          item
          md={5}
          xs={12}
          alignItems="center"
          className={styles.inputContainer}
        >
          {values.coparticipants[0].clientName}
        </Grid>
        {/* <Grid item md={5} xs={12} className={styles.inputContainer}>
          <Title>¿Tomará el Servicio de Riesgo Cambiario?</Title>
        </Grid>
        <Grid
          container
          item
          md={5}
          xs={12}
          alignItems="center"
          className={styles.inputContainer}
        >
          <span className={styles.text}>
            {values.riskExchange ? 'Si' : 'No'}
          </span>
        </Grid> */}
      </Grid>
      {/* <Grid container item justify="space-around" xs={12}>
        <Grid item xs={12} className={cx(styles.sectionText, styles.tableHead)}>
          <h3>COPARTÍCIPES</h3>
        </Grid>
        <Grid item xs={12}>
          <CustomCard disableFlex>
            <MUIDataTable
              data={values.coparticipants}
              columns={columns}
              options={options}
            />
          </CustomCard>
        </Grid>
      </Grid> */}
    </Grid>
  );
}

StepFourForm.propTypes = {
  repurchase: PropTypes.shape().isRequired,
};

export default StepFourForm;
