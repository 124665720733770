import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  LIST_CLASS_FUND,
  UPDATE_CLASS_FUND,
  DELETE_CLASS_FUND,
  LIST_FUNDS,
} from '../queries';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import {
  edgeToList,
  getOptions,
  generateColumn,
} from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';

// Styles & Images
import useStyles from './styles';

function CustomToolbar() {
  const history = useHistory();
  const handleClick = () => {
    history.push('/administrador/clases/crear');
  };

  return (
    <Tooltip title="Nueva clase">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function FundClassList() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [classFundList, setClassFundList] = useState([]);

  const [deleteClassFund] = useMutation(
    DELETE_CLASS_FUND,
    getOptions({
      mutationName: 'deleteClassFund',
      message: 'Clase eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_CLASS_FUND }],
    }),
  );
  const { loading, error, data } = useQuery(LIST_CLASS_FUND);

  if (error) {
    console.error('list classes', error);
  }

  useEffect(() => {
    if (data) {
      const parserlistClassFund = FundEdgeToList(data, 'listClassFund');
      setClassFundList(parserlistClassFund);
    }
  }, [data]);

  function FundEdgeToList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.options = {
          id: formatElement.id,
          state: formatElement.state,
        };
        return formatElement;
      });
    }
    return [];
  }

  const [updateClassFund] = useMutation(
    UPDATE_CLASS_FUND,
    getOptions({
      mutationName: 'updateClassFund',
      modelName: 'classFund',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
    }),
  );

  function handleState(e, id, state) {
    updateClassFund({
      variables: {
        id,
        input: {
          state: !state,
        },
      },
    });
  }

  const columns = [
    generateColumn('Clase', 'name'),
    {
      name: 'state',
      label: 'Estado',
      options: {
        sort: false,
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
        customBodyRender: value => {
          const state = value;
          return <p>{state ? 'Activo' : 'Inactivo'} </p>;
        },
      },
    },
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customHeadRender: columnMeta => {
        return (
          <TableCell key={5} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
      customBodyRender: value => {
        const { id, state } = value;
        return (
          <CustomOptions
            id={id}
            model="Clase"
            options={['oedit', 'odelete', 'oenable']}
            extraData={{ oenable: state }}
            extraFunctions={{ oenable: e => handleState(e, id, state) }}
            handleDelete={() => {
              deleteClassFund({ variables: { id } });
              let tmp = classFundList;
              tmp.filter(e => e.id !== id);
              setClassFundList(tmp);
            }}
          />
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `/administrador/clases/${rowData[rowData.length - 1].props.id}`,
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <MUIDataTable
          title="Clases de Fondo"
          data={classFundList}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}

export default FundClassList;
