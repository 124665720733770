import { gql } from 'apollo-boost';

export const LIST_PROMOTERS = gql`
  query listPromoter {
    listPromoter {
      edges {
        node {
          id
          firstName
          lastName
          enabled
          promoterCompanyCommission
          promoterCompanies {
            edges {
              node {
                id
                businessName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROMOTER_INFO = gql`
  query Promoter($id: ID!) {
    promoter(id: $id) {
      id
      firstName
      lastName
      email
      isCoreConsultant
      promoterCompanyCommission
      representativeDocumentType {
        id
        name
      }
      representativeDocumentNumber
      enabled
      address {
        id
        detail
        country {
          id
          name
        }
        county {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
          ubigeo
        }
      }
      promoterCompanies {
        edges {
          node {
            id
            businessName
          }
        }
      }
      bankAccounts {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_PROMOTER = gql`
  mutation createPromoter($input: PromoterInput!) {
    createPromoter(input: $input) {
      promoter {
        id
        firstName
        lastName
        email
        isCoreConsultant
        representativeDocumentType {
          id
          name
        }
        representativeDocumentNumber
        enabled
        address {
          id
          detail
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
            ubigeo
          }
        }
        promoterCompanies {
          edges {
            node {
              id
              businessName
            }
          }
        }
        bankAccounts {
          edges {
            node {
              id
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROMOTER = gql`
  mutation updatePromoter($id: ID!, $input: PromoterUpdateInput!) {
    updatePromoter(id: $id, input: $input) {
      promoter {
        id
        firstName
        lastName
        email
        isCoreConsultant
        representativeDocumentType {
          id
          name
        }
        representativeDocumentNumber
        enabled
        address {
          id
          detail
          country {
            id
            name
          }
          county {
            id
            name
          }
          city {
            id
            name
          }
          district {
            id
            name
            ubigeo
          }
        }
        promoterCompanies {
          edges {
            node {
              id
              businessName
            }
          }
        }
        bankAccounts {
          edges {
            node {
              id
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_PROMOTER = gql`
  mutation deletePromoter($id: ID!) {
    deletePromoter(id: $id) {
      __typename
      promoter {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
