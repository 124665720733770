import React from 'react';

import { DatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

export const defaultDate = moment().format('YYYY-MM-DD');
export const defaultDateTomorrow = moment()
  .add(1, 'days')
  .format('YYYY-MM-DD');

const DatePickerField = ({
  field,
  form,
  customFormat,
  variant,
  ...DatePickerProps
}) => {
  return (
    <DatePicker
      {...DatePickerProps}
      autoOk
      format={customFormat}
      views={field.views}
      name={field.name}
      color="primary"
      value={field.value}
      inputVariant={variant}
      onChange={date => {
        form.setFieldValue(field.name, date.format(customFormat));
      }}
      error={form.errors[field.name] && form.errors[field.name].length > 0}
      helperText={
        form.errors[field.name] && form.errors[field.name].length > 0
          ? form.errors[field.name]
          : null
      }
    />
  );
};

DatePickerField.propTypes = {
  form: PropTypes.shape().isRequired,
  customFormat: PropTypes.string,
  variant: PropTypes.string,
};
DatePickerField.defaultProps = {
  customFormat: 'YYYY-MM-DD',
  variant: 'outlined',
};

export default DatePickerField;
