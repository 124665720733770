import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  gray: {
    backgroundColor: theme.palette.colors.gray,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.gray,
    },
  },
  green: {
    backgroundColor: theme.palette.colors.green,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.green,
    },
  },
  orange: {
    backgroundColor: theme.palette.colors.orange,
    '&:hover': {
      backgroundColor: theme.palette.hoverColors.orange,
    },
  },
  tableTitle: {
    fontWeight: 500,
  },
  stateButton: {
    padding: 0,
    minWidth: 0,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: '4px 4px',
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  greenIcon: {
    color: theme.palette.colors.green,
  },
  grayIcon: {
    color: theme.palette.colors.gray,
  },
}));
