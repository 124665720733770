/*
 * HomePage
 */

import React, { useEffect, useRef, useState } from 'react';

// Material Resources
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

// GraphQl
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Others

import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Yup from '../../utils/yup';

// Styles & Images
import style from '../LoginPage/login.module.css';
import useStyles from './styles';
import goldenLogo from '../../images/brand/CoreCapitalSAFI_LogoRGB_positivo.png';
import PasswordKeyboard from './numericKeyboard';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { errorMessage } from 'src/utils/commonFunctions';

function ActivateAccountPage() {
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
  });
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: '',
  });
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const RESET_PASSWORD = gql`
    mutation changeUserPassword($input: ResetPasswordInput!) {
      changeUserTokenPassword(input: $input) {
        ok
        message
      }
    }
  `;

  const handlePasswordChange = value => {
    setFormData({
      ...formData,
      password: value,
    });
  };

  const handleConfirmPasswordChange = value => {
    setFormData({
      ...formData,
      confirmpassword: value,
    });
  };
  const togglePasswordVisibility = field => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };
  const [resetPasswordMutation, { data: resetData }] = useMutation(
    RESET_PASSWORD,
    {
      onError(error) {
        const { graphQLErrors } = error;
        if (graphQLErrors[0]?.message?.includes('común')) {
          errorMessage(
            'Contraseña no válida. Evita usar números consecutivos o repetidos.',
            enqueueSnackbar,
          );
        } else errorMessage(graphQLErrors, enqueueSnackbar);
      },
      onCompleted(data) {
        enqueueSnackbar('Listo! Cuenta activada con éxito', {
          autoHideDuration: 3000,
          variant: 'success',
        });
        history.push('/');
      },
    },
  );

  useEffect(() => {}, [resetData]);

  const onSubmit = () => {
    if (formData.password !== formData.confirmpassword) {
      errorMessage('Las contraseñas no coinciden.', enqueueSnackbar);
      return;
    }
    setLoading(true);
    resetPasswordMutation({
      variables: {
        input: { password: formData.password, user: id, resetToken: token },
      },
    });
  };
  const inputRef = useRef(null);
  const keyboardRef = useRef(null);

  const inputRef2 = useRef(null);
  const keyboardRef2 = useRef(null);
  const handleClickOutside = event => {
    if (
      keyboardRef.current &&
      !keyboardRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
    if (
      keyboardRef2.current &&
      !keyboardRef2.current.contains(event.target) &&
      inputRef2.current &&
      !inputRef2.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (formData.password.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.confirmpassword.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
      });
    }
  }, [formData.confirmpassword]);
  const handlerOpenKeyboardpassword = () => {
    setFocusedInput('password');
    setFormData({
      ...formData,
      password: '',
    });
  };

  const handlerOpenKeyboardconfirmpass = () => {
    setFocusedInput('confirmpassword');
    setFormData({
      ...formData,
      confirmpassword: '',
    });
  };
  return (
    <div className={style.layoutPassword}>
      <div className={classes.containerPassword}>
        <img src={goldenLogo} alt="logo" style={{ width: '25vh' }} />
        <>
          <div className={classes.form}>
            <div>
              <p className={classes.text}>Active su cuenta</p>
            </div>
            <TextField
              ref={inputRef}
              type={showPassword.password ? 'text' : 'password'}
              label="Ingrese su clave de 6 dígitos"
              sx={{ pointerEvents: 'none' }}
              name="password"
              variant="outlined"
              value={formData.password}
              autoComplete="off"
              onFocus={handlerOpenKeyboardpassword}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => togglePasswordVisibility('password')}
                    >
                      {showPassword.password ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {focusedInput === 'password' && (
              <div ref={keyboardRef} className={classes.main}>
                <PasswordKeyboard handlerPassword={handlePasswordChange} />
              </div>
            )}
            <TextField
              ref={inputRef2}
              type={showPassword.confirmpassword ? 'text' : 'password'}
              sx={{ pointerEvents: 'none' }}
              label="Confirme su clave de 6 dígitos"
              name="confirmpassword"
              variant="outlined"
              value={formData.confirmpassword}
              onFocus={handlerOpenKeyboardconfirmpass}
              autoComplete="off"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        togglePasswordVisibility('confirmpassword')
                      }
                    >
                      {showPassword.confirmpassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {focusedInput === 'confirmpassword' && (
              <div ref={keyboardRef2} className={classes.main}>
                <PasswordKeyboard
                  handlerPassword={handleConfirmPasswordChange}
                />
              </div>
            )}

            <div
              style={{
                width: '100%',
                padding: '1vh',
              }}
            >
              <div className={classes.mssgDiv}>
                <p className={classes.mssgText}>
                  <strong>Recomendación</strong>
                  <br />
                  Tu contraseña debe contener solo dígitos y no puede incluir
                  números consecutivos (como 1234) o repetidos (como 1111).
                  Asegúrate de usar una combinación única para mejorar la
                  seguridad.
                </p>
              </div>
              <Button
                className={classes.btn}
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={onSubmit}
                type="submit"
              >
                Activar Cuenta
              </Button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default ActivateAccountPage;
