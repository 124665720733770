// GraphQl
import { gql } from 'apollo-boost';

export const LIST_DOCUMENT_TYPES = gql`
  {
    listDocumentTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT_TYPE = gql`
  query getDocumentType($id: ID!) {
    documentType(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_DOCUMENT_TYPE = gql`
  mutation createDocumentType($input: DocumentTypeInput!) {
    createDocumentType(input: $input) {
      documentType {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_DOCUMENT_TYPE = gql`
  mutation updateDocumentType($id: ID!, $input: DocumentTypeInput!) {
    updateDocumentType(id: $id, input: $input) {
      documentType {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_DOCUMENT_TYPE = gql`
  mutation deleteDocumentType($id: ID!) {
    deleteDocumentType(id: $id) {
      documentType {
        id
        name
      }
      errors {
        field
        message
      }
    }
  }
`;
