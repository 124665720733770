import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { Link as RouterLink } from 'react-router-dom';
import { getDBIdFromGraphqlId } from '../../utils/commonFunctions';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
} from '@material-ui/core';
import {
  Dashboard as DashboardIcon,
  ListAlt as ListAltIcon,
  Receipt as ReceiptIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Create as CreateIcon,
} from '@material-ui/icons';

import { getClientPermissionsSelector } from '../../store/permissionsStore/selector';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';
import { REACT_APP_LINK_FORM_F2 } from 'src/utils/constants';
import { getClientSelector } from 'src/store/sharedStore/selectors';
import getClientMode from '../../store/utils-store/selectors';

function CustomMenuItem(props) {
  const { url, name, open, icon, reset, tagId } = props;
  const classes = useStyles();
  const history = useHistory();
  const client = useSelector(getClientSelector());
  const [isEmployee, setIsEmployee] = useState(false);

  const base64url = source => {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, '');
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  };

  useEffect(() => {
    if (client && client.isEmployee) {
      setIsEmployee(true);
    }
  }, [client]);

  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const token = () => {
    let header = {
      alg: 'HS256',
      typ: 'JWT',
    };

    let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
    let encodedHeader = base64url(stringifiedHeader);
    let id = '';
    if (clients && clients.currentUser)
      id = getDBIdFromGraphqlId(clients?.currentUser?.id, 'UserType');
    let data = {
      id: id,
      documentType: client?.user?.documentType.code,
      documentNumber: client?.user?.documentNumber,
      communicationEmail: clients?.currentUser?.email,
      firstName: client?.user?.firstName,
      lastName: client?.user?.lastName,
      maternalSurname: client?.user?.maternalSurname,
      role: 'investor',
    };
    let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    let encodedData = base64url(stringifiedData);
    let secret = 'core2';
    let unsigned_token = encodedHeader + '.' + encodedData;
    let signature = CryptoJS.HmacSHA256(unsigned_token, secret);
    signature = base64url(signature);
    let signedToken = unsigned_token + '.' + signature;

    return signedToken;
  };
  const UserToken = token();

  const urlF2 = `${REACT_APP_LINK_FORM_F2}?token=${UserToken}`;

  return (
    <>
      {name === 'Ficha Cliente' && !isEmployee ? (
        <div
          onClick={() => {
            window.location.href = urlF2;
          }}
        >
          <ListItem button key={name} dense>
            {open ? (
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            ) : (
              <Tooltip title={name} placement="right">
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
              </Tooltip>
            )}
            <ListItemText dense={1} primary={name} />
          </ListItem>
        </div>
      ) : (
        <Link
          id={tagId ?? ''}
          underline="none"
          color="textPrimary"
          component={RouterLink}
          to={url}
          onClick={() => {
            if (name === 'Dashboard') reset();
          }}
        >
          <ListItem
            button
            key={name}
            dense
            selected={history.location.pathname == url}
          >
            {open ? (
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
            ) : (
              <Tooltip title={name} placement="right">
                <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
              </Tooltip>
            )}
            <ListItemText dense={1} primary={name} />
          </ListItem>
        </Link>
      )}
    </>
  );
}
CustomMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};
// import { REACT_APP_LINK_FORM_F2 } from '../../utils/constants';
// REACT_APP_LINK_FORM_F2

function ClientMenu(props) {
  const modeClient = useSelector(getClientMode());
  const permissionSections = {
    ficha_cliente: open => (
      <CustomMenuItem
        tagId="ficha_cliente_left_menu"
        key="Ficha Cliente"
        url="/cliente/ficha-cliente"
        name="Ficha Cliente"
        open={open}
        icon={<ListAltIcon color={modeClient ? 'primary' : 'secondary'} />}
      />
    ),
    ficha_colaborador: open => (
      <CustomMenuItem
        tagId="ficha_cliente_left_menu"
        key="Ficha Colaborador"
        url="/cliente/ficha-cliente"
        name="Ficha Colaborador"
        open={open}
        icon={<ListAltIcon color={modeClient ? 'primary' : 'secondary'} />}
      />
    ),
    registro_pagos: open => (
      <CustomMenuItem
        tagId="registro_cliente_left_menu"
        key="Registro de pagos"
        url="/cliente/registro-pagos"
        name="Registro de pagos"
        open={open}
        icon={
          <AccountBalanceWalletIcon
            color={modeClient ? 'primary' : 'secondary'}
          />
        }
      />
    ),
    dashboard: ({ open, resetMenu }) => {
      return (
        <>
          <CustomMenuItem
            tagId="dashboard_left_menu"
            key="Dashboard"
            url="/cliente"
            name="Dashboard"
            open={open}
            icon={
              <DashboardIcon color={modeClient ? 'primary' : 'secondary'} />
            }
            reset={resetMenu}
          />
        </>
      );
    },
    firma_contratos: open => (
      <>
        <CustomMenuItem
          tagId="firma_contratos_left_menu"
          key="Firma de contratos"
          url="/cliente/firma-contratos"
          name="Firma de contratos"
          open={open}
          icon={<ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />}
        />
      </>
    ),
    firma_contratos_mercado_secundario: open => (
      <>
        <CustomMenuItem
          tagId="firma_contratos_sec_left_menu"
          url="/cliente/firma-contratos-mercado-secundario"
          name="Firma de contratos mercado secundario"
          open={open}
          icon={<ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />}
        />
      </>
    ),
    estados_de_cuenta_de_inversiones: open => (
      <>
        <CustomMenuItem
          tagId="estados_de_cuenta_left_menu"
          url="/cliente/estados-de-cuenta-de-inversiones"
          name="Estados de Cuenta"
          open={open}
          icon={<ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />}
        />
      </>
    ),
    documentos: open => (
      <>
        <CustomMenuItem
          tagId="documentos_left_menu"
          url="/cliente/documentos"
          name="Repositorio Personal"
          open={open}
          icon={<ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />}
        />
      </>
    ),
    firmas_cliente: open => (
      <>
        <CustomMenuItem
          tagId="firmas_clientes_left_menu"
          url="/cliente/firmas"
          name="Firmas Pendientes"
          open={open}
          icon={<CreateIcon color={modeClient ? 'primary' : 'secondary'} />}
        />
      </>
    ),
  };

  const { open, menu, resetMenu, setMenu } = props;
  const [orderedMenu, setOrderedMenu] = useState([]);

  const classes = useStyles();
  const clientPermissions = useSelector(getClientPermissionsSelector());

  useEffect(() => {
    const permisions = [];
    if (menu) {
      menu.forEach(item => {
        if (clientPermissions.find(i => i === item)) {
          permisions.push(item);
        }
      });
      setOrderedMenu(permisions);
    }
  }, [clientPermissions, menu]);
  const clientStyle = modeClient ? classes.clientMenuDark : classes.clientMenu;
  return (
    <div className={clientStyle}>
      <React.Fragment key={'dashboard'}>
        {permissionSections['dashboard']({
          open,
          resetMenu,
        })}
      </React.Fragment>
      {orderedMenu.map(p => (
        <React.Fragment key={p}>{permissionSections[p](open)}</React.Fragment>
      ))}
    </div>
  );
}

ClientMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  menu: PropTypes.array.isRequired,
  setMenu: PropTypes.func.isRequired,
  resetMenu: PropTypes.func.isRequired,
};
export default ClientMenu;
