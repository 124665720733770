/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support

// Import all the third party stuff
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import CancelIcon from '@material-ui/icons/Cancel';
import './index.css';
// GraphQl
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

// Others
import {
  REACT_APP_SERVER_LINK,
  NOTIFICATION_MAX_STACK,
} from './utils/constants';
import App from './containers/App';
import theme from './themes/mainTheme';
import themeLight from './themes/mainThemeEmployee';
import themeDark from './themes/mainThemeDark';
import MomentUtils from '@date-io/moment';
import configureStore from './store/configureStore';
import { SessionProvider } from './providers/SessionProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getClientSelector } from './store/sharedStore/selectors';
import getClientMode from './store/utils-store/selectors';
import { getAuthUser } from './utils/storage';

const MOUNT_NODE = document.getElementById('root');
const initialState = {};
const store = configureStore(initialState);

const httpLink = new HttpLink({
  uri: `${REACT_APP_SERVER_LINK}/graphql`,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    /* Todo: Check if we can handle Errors here */
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }

  if (networkError) {
    /* Todo: Add Message on Network Error */
    console.log(`[Network error]: ${networkError}`);
  }
});

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          feed: {
            keyArgs: ['type'],
            merge(existing, incoming, { readField }) {
              const merged = { ...existing };
              incoming.forEach(item => {
                merged[readField('id', item)] = item;
              });
              return merged;
            },
            read(existing) {
              return existing && Object.values(existing);
            },
          },
        },
      },
    },
  }),
  connectToDevTools: true,
});

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const EntryPointApp = () => {
  const user = useSelector(getClientSelector());
  const isModeDark = useSelector(getClientMode());
  const authUser = getAuthUser();
  const isNormalUser = user && authUser.usergroup === 'client_group';

  useEffect(() => {
    if (isModeDark) {
      document.documentElement.style.background = '#2F2F2F';
    } else {
      document.documentElement.style.background = '#f0f3f5';
    }
  }, [isModeDark]);

  useEffect(() => {
    if (isNormalUser) {
      localStorage.setItem('isEmployee', true);
      localStorage.setItem('mode', isModeDark);
      document.body.style.overflow = 'hidden';
    }
  }, [client, isModeDark]);

  return (
    <>
      <ApolloProvider client={client}>
        <SessionProvider>
          <ThemeProvider
            theme={isNormalUser ? (isModeDark ? themeDark : themeLight) : theme}
          >
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={NOTIFICATION_MAX_STACK}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              action={key => (
                <CancelIcon
                  onClick={onClickDismiss(key)}
                  style={{ cursor: 'pointer' }}
                />
              )}
            >
              <BrowserRouter>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </SessionProvider>
      </ApolloProvider>
    </>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <EntryPointApp />
  </Provider>,
  MOUNT_NODE,
);
