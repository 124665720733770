import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  clientFormPage: {
    display: 'flex',
  },
  root: {
    position: 'fixed',
    width: '26%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  rootMenuTree: {
    backgroundColor: theme.palette.colors.white,
    padding: '2%',
  },
  itemRoot: {
    '&:focus > .MuiTreeItem-content': {
      backgroundColor: 'transparent',
    },
  },
  treeItem: {
    backgroundColor: theme.palette.secondary.main,
  },
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  itemComplete: {
    extend: 'iconContainer',
    color: theme.palette.primary.main,
  },
  itemEditing: {
    extend: 'iconContainer',
    color: theme.palette.colors.green,
  },
  itemHasChange: {
    extend: 'iconContainer',
    color: theme.palette.colors.orange,
  },
  itemIncomplete: {
    extend: 'iconContainer',
    color: theme.palette.colors.red,
  },
  itemLabel: {
    fontSize: 16,
  },
  itemLabelDisabled: {
    fontSize: 16,
    color: theme.palette.colors.gray,
  },
  customTreeIcon: {
    fontSize: '1.2rem',
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  treeContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    borderRight: '2px solid',
    borderRightColor: theme.palette.colors.gray,
  },
  forms: {
    paddingLeft: 10,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  simpleForm: {
    width: '100%',
  },
  contractContainer: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: 5,
  },
  floatingButton: {
    position: 'fixed',
    top: 70,
    right: 20,
    zIndex: theme.zIndex.modal,
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    textTransform: 'uppercase',
  },
  buttonSuccess: {
    backgroundColor: theme.palette.colors.green,
    display: 'block',
    margin: theme.spacing(1),
  },
  nextContainer: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: 10,
  },
  inputContainer: {
    paddingBottom: theme.spacing(3),
  },
  explicationContainer: {
    padding: theme.spacing(3),
  },
  // ICON
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  // END MESSAGE
  endMessage: {
    padding: theme.spacing(3),
  },
}));
