import React, { useEffect, useState, useRef } from 'react';

// GraphQl
import { gql } from 'apollo-boost';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

// Material resources
import { Grid, Typography, Button, FormHelperText } from '@material-ui/core';
import { TextField } from 'formik-material-ui';

// Components & Others
import PropTypes from 'prop-types';
import cx from 'clsx';
import MUIRichTextEditor from 'mui-rte';
import { Formik } from 'formik';
import { getOptions } from 'src/utils/commonFunctions';
import { useSnackbar } from 'notistack';
import Yup from 'src/utils/yup';
import { useHistory, useParams } from 'react-router-dom';
import {
  CREATE_CONTRACT_RULES,
  parseAutocomplete,
  CONDITIONS,
} from './constants';
import CustomSelect from 'src/components/FormComponents/CustomSelect';
import {
  UPDATE_CONTRACT_TYPE,
  GET_CONTRACT_TYPE,
  LIST_CONTRACT_TYPES,
  CREATE_CONTRACT_TYPE,
} from '../queries';
import { LIST_FUNDS } from '../../Fund/queries';
import { edgeToList } from '../../../../utils/commonFunctions';
import LoadingIndicator from 'src/components/LoadingIndicator';

// Styles & Images
import useStyles from './styles';

function ContractForm(props) {
  const { id } = props;
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [contractData, setContractData] = useState(null);
  const [contractValue, setContractValue] = useState(null);
  const [anex1Value, setAnex1Value] = useState(null);
  const [anex2Value, setAnex2Value] = useState(null);
  const [formValues, setformValues] = useState(null);

  const contractRef = useRef(null);
  const anex1Ref = useRef(null);
  const anex2Ref = useRef(null);

  const {
    loading: FundsLoading,
    error: FundsError,
    data: FundsData,
  } = useQuery(LIST_FUNDS);

  const [loadContract, { loading, data: queryData }] = useLazyQuery(
    GET_CONTRACT_TYPE,
    {
      variables: {
        id,
      },
    }
  );

  const [updateMutation] = useMutation(
    UPDATE_CONTRACT_TYPE,
    getOptions({
      mutationName: 'updateContractType',
      modelName: 'contractType',
      message: 'Tipo de contrato actualizado.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_CONTRACT_TYPES },
        { query: GET_CONTRACT_TYPE, variables: { id } },
      ],
      completeCallback: () => history.goBack(),
    })
  );
  const [createMutation] = useMutation(
    CREATE_CONTRACT_TYPE,
    getOptions({
      mutationName: 'createContractType',
      modelName: 'contractType',
      message: 'Tipo de contrato creado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_CONTRACT_TYPES }],
      completeCallback: () => history.goBack(),
    })
  );

  useEffect(() => {
    loadContract();
  }, [id]);
  useEffect(() => {
    if (queryData && queryData.contractType) {
      const { contractType } = queryData;
      const tmpData = contractType;

      setContractData(tmpData);
    }
  }, [queryData]);

  const removeEntityFromText = rawText => {
    const parsedText = JSON.parse(rawText);
    parsedText.blocks = parsedText.blocks.map(block => {
      const newBlock = { ...block };
      newBlock.entityRanges = [];
      return newBlock;
    });
    parsedText.entityMap = {};
    return JSON.stringify(parsedText);
  };

  useEffect(() => {
    if (formValues && contractValue && anex1Value && anex2Value) {
      const parsedContract = removeEntityFromText(contractValue);
      const parsedAnex1 = removeEntityFromText(anex1Value);
      const parsedAnex2 = removeEntityFromText(anex2Value);

      if (id !== '') {
        updateMutation({
          variables: {
            input: {
              name: formValues.name,
              condition: CONDITIONS.find(c => c.value === formValues.condition)
                .enum,
              fund: formValues.fund,
              contractText: parsedContract,
              annexText1: parsedAnex1,
              annexText2: parsedAnex2,
            },
            id,
          },
        });
      } else {
        createMutation({
          variables: {
            input: {
              name: formValues.name,
              condition: CONDITIONS.find(c => c.value === formValues.condition)
                .enum,
              fund: formValues.fund,
              contractText: parsedContract,
              annexText1: parsedAnex1,
              annexText2: parsedAnex2,
            },
          },
        });
      }
    }
  }, [formValues, contractValue, anex1Value, anex2Value]);

  const CONTROLS = [
    'title',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'undo',
    'redo',
    'numberList',
    'bulletList',
    'clear',
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    condition: Yup.string().required(),
    fund: Yup.string().required(),
    contractText: Yup.string().required(),
    annexText1: Yup.string().required(),
    annexText2: Yup.string().required(),
  });

  const formInitialValues = {
    name: '',
    condition: '',
    fund: '',
    contractText: '',
    annexText1: '',
    annexText2: '',
  };

  const handleChange = props => {
    const { e, values, field } = props;
    const content = e.getCurrentContent().getPlainText();
    values[field] = content;
  };

  return loading || (id && !contractData) ? (
    <LoadingIndicator />
  ) : (
    <Formik
      initialValues={
        id !== ''
          ? {
              name: contractData.name,
              condition: contractData.condition,
              fund: contractData.fund ? contractData.fund.id : '',
            }
          : formInitialValues
      }
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={(values, { setSubmitting }) => {
        contractRef.current.save();
        anex1Ref.current.save();
        anex2Ref.current.save();
        setformValues(values);
        setSubmitting(false);
      }}
    >
      {({ submitForm, errors, values, setFieldTouched }) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Crear tipo de contrato
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <TextField
                  label="Nombre"
                  name="name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <CustomSelect
                  id="condition"
                  fullWidth
                  label="Condición"
                  name="condition"
                  data={CONDITIONS}
                  error={errors.condition}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={6} className={classes.columnContainer}>
                <CustomSelect
                  id="fund"
                  fullWidth
                  label="Fondo"
                  name="fund"
                  data={edgeToList(FundsData, 'listFunds')}
                  mapData={{ value: 'id', label: 'businessName' }}
                  error={errors.fund}
                />
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.columnContainer}>
              <h3>Contrato</h3>
              <Grid item xs={12} className={classes.editorContainer}>
                <MUIRichTextEditor
                  ref={contractRef}
                  defaultValue={id !== '' && contractData.contractText}
                  autocomplete={{
                    strategies: [
                      {
                        items: parseAutocomplete(true),
                        triggerChar: ':',
                      },
                    ],
                  }}
                  label="Texto del contrato"
                  onSave={data => setContractValue(data)}
                  inlineToolbar
                  controls={CONTROLS}
                  error={errors.contractText}
                  onChange={e => {
                    handleChange({ e, values, field: 'contractText' });
                    setFieldTouched('contractText');
                  }}
                />
              </Grid>
              {errors.contractText !== '' && (
                <FormHelperText error>{errors.contractText}</FormHelperText>
              )}
              <h3>Anexo 1</h3>
              <Grid item xs={12} className={classes.editorContainer}>
                <MUIRichTextEditor
                  ref={anex1Ref}
                  defaultValue={id !== '' && contractData.annexText1}
                  autocomplete={{
                    strategies: [
                      {
                        items: parseAutocomplete(true),
                        triggerChar: ':',
                      },
                    ],
                  }}
                  label="Texto del anexo 1"
                  onSave={data => setAnex1Value(data)}
                  inlineToolbar
                  controls={CONTROLS}
                  error={errors.annexText1}
                  onChange={e => {
                    handleChange({ e, values, field: 'annexText1' });
                    setFieldTouched('annexText1');
                  }}
                />
              </Grid>
              {errors.annexText1 !== '' && (
                <FormHelperText error>{errors.annexText1}</FormHelperText>
              )}
              <h3>Anexo 2</h3>
              <Grid item xs={12} className={classes.editorContainer}>
                <MUIRichTextEditor
                  ref={anex2Ref}
                  defaultValue={id !== '' && contractData.annexText2}
                  autocomplete={{
                    strategies: [
                      {
                        items: parseAutocomplete(true),
                        triggerChar: ':',
                      },
                    ],
                  }}
                  label="Texto del anexo 2"
                  onSave={data => setAnex2Value(data)}
                  inlineToolbar
                  controls={CONTROLS}
                  error={errors.annexText2}
                  onChange={e => {
                    handleChange({ e, values, field: 'annexText2' });
                    setFieldTouched('annexText2');
                  }}
                />
              </Grid>
              {errors.annexText2 !== '' && (
                <FormHelperText error>{errors.annexText2}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} className={classes.columnContainer}>
              <Grid
                item
                xs={12}
                className={cx(classes.infoContainer, classes.editorContainer)}
              >
                <MUIRichTextEditor
                  label="Texto del contrato"
                  readOnly
                  defaultValue={CREATE_CONTRACT_RULES}
                  controls={[]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justify="flex-end" alignItems="center">
              <Grid item>
                <Button
                  onClick={() => history.goBack()}
                  variant="contained"
                  color="secondary"
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={submitForm}
                  variant="contained"
                  color="primary"
                >
                  {id !== '' ? 'Guardar' : 'Crear'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

ContractForm.propTypes = {
  id: PropTypes.string,
};

ContractForm.defaultProps = {
  id: '',
};
export default ContractForm;

export function ContractEdit() {
  const { id } = useParams();

  return <ContractForm id={id} />;
}
