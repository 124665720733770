// GraphQl
import { gql } from 'apollo-boost';

export const LIST_BANK_ACCOUNTS = gql`
  {
    listBankAccount {
      edges {
        node {
          id
          bank {
            id
            name
          }
          currency {
            id
            name
          }
          accountType {
            id
            name
          }
          country {
            id
            name
          }
          accountNumber
          cci
          swift
          aba
          observations
          jointAccount
          otherBank
          isActive
        }
      }
    }
  }
`;

export const LIST_BANK_ACCOUNTS_BY_IDS = gql`
  query listBankAccount($id: [ID]) {
    listBankAccount(id: $id) {
      edges {
        node {
          id
          bank {
            id
            name
          }
          currency {
            id
            name
          }
          accountType {
            id
            name
          }
          country {
            id
            name
          }
          accountNumber
          cci
          swift
          aba
          observations
          jointAccount
          otherBank
          isActive
        }
      }
    }
  }
`;

export const GET_BANK_ACCOUNT = gql`
  query getBankAccount($id: ID!) {
    bankAccount(id: $id) {
      id
      bank {
        id
        name
      }
      currency {
        id
        name
      }
      accountType {
        id
        name
      }
      country {
        id
        name
      }
      accountNumber
      cci
      swift
      aba
      observations
      jointAccount
      otherBank
      isActive
    }
  }
`;

export const CREATE_BANK_ACCOUNT = gql`
  mutation createBankAccount($input: BankAccountInput!) {
    createBankAccount(input: $input) {
      bankAccount {
        id
        bank {
          id
          name
        }
        currency {
          id
          name
        }
        accountType {
          id
          name
        }
        country {
          id
          name
        }
        accountNumber
        cci
        swift
        aba
        observations
        jointAccount
        otherBank
        isActive
      }
    }
  }
`;

export const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($id: ID!, $input: BankAccountInput!) {
    updateBankAccount(id: $id, input: $input) {
      bankAccount {
        id
        bank {
          id
          name
        }
        currency {
          id
          name
        }
        accountType {
          id
          name
        }
        country {
          id
          name
        }
        accountNumber
        cci
        swift
        aba
        observations
        jointAccount
        otherBank
        isActive
      }
    }
  }
`;

export const DELETE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($id: ID!) {
    deleteBankAccount(id: $id) {
      ok
      message
    }
  }
`;
