/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Components & Others
import ContracSignList from '../../../components/Models/ContracSign/List';
import ContracSignForm from '../../../components/Models/ContracSign';
import NotFound from '../../NotFoundPage';

function ContractSignPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ContracSignList} />
      <Route path={`${path}/:id`} component={ContracSignForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default ContractSignPage;
