import Yup from '../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

export const startData = {
  documentType: '',
  documentNumber: '',
  firstName: '',
  lastName: '',
  maternalSurname: '',
  email: '',
  cellphone: '',
  sex: '',
  occupation: '',
  razonSocial: '',
  photoDocumentFront: null,
  photoDocumentBack: null,
};

export const validationSchema = Yup.object().shape({
  documentType: Yup.string().required(),
  documentNumber: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  maternalSurname: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  cellphone: Yup.string(),
  occupation: Yup.string().required(),
  sex: Yup.string().required(),
});

export const formStructure = (isEdit, documentTypes, typeList) => [
  {
    label: 'Nombres',
    name: 'firstName',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Apellido Paterno',
    name: 'lastName',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Apellido Materno',
    name: 'maternalSurname',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Tipo de documento',
    name: 'documentType',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.select,
    data: documentTypes,
    mapData: { value: 'id', label: 'name' },
  },
  {
    label: 'Número de documento',
    name: 'documentNumber',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.number,
  },
  {
    label: 'Email',
    name: 'email',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Celular',
    name: 'cellphone',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Cargo',
    name: 'occupation',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Género',
    name: 'sex',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: [
      { value: 'M', name: 'Masculino' },
      { value: 'F', name: 'Femenino' },
    ],
    mapData: { value: 'value', label: 'name' },
  },
  {
    label: 'Razón Social',
    name: 'razonSocial',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: typeList,
    mapData: { value: 'value', label: 'name' },
  },
  {
    label: 'Foto de Perfil',
    name: 'profilePhoto',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.image,
  },
  {
    label: 'Foto del anverso del documento',
    name: 'photoDocumentFront',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.image,
  },
  {
    label: 'Foto del reverso del documento',
    name: 'photoDocumentBack',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.image,
  },
];
