import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import {
  generateColumn,
  edgeToList,
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import { IconButton, Tooltip, TableCell, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/Backup';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  LIST_S3FILES,
  LIST_TYPES_CHOICES,
  FILE_DOWNLOAD,
  FILE_UPDATE,
} from '../queries';
import PDFViewerModal from '../../../PDFViewerModal';
import DeleteModal from '../../../DeleteModal';

// styles and icons
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import useStyles from './styles';
import LoadingIndicator from '../../../LoadingIndicator';
import { saveAs } from 'file-saver';

function FilesList() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { error: error, data: data, loading: fileLoading } = useQuery(
    LIST_S3FILES,
  );
  const [filesList, setFilesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [url, setUrl] = useState('');
  const [idState, setIdState] = useState('');
  const [isDownload, setIsDownload] = useState(false);

  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES);
  const [downloadFile, { loadingFile, dataFile }] = useMutation(FILE_DOWNLOAD, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (!!data && !isDownload) {
        setUrl(data?.fileDownload?.url);
        setShowModal(true);
      } else {
        setShowModal(false);
        saveAs(data?.fileDownload?.url, data?.fileDownload?.fileName);
        successMessage('Archivo descargado.', enqueueSnackbar);
      }
    },
  });

  useEffect(() => {
    if (!!data) {
      setFilesList(
        edgeToList(data, 'listEmployeeS3Files').filter(
          e => e?.state && e?.isGeneric,
        ),
      );
    }
    if (!!TypesData) {
      setTypeList(TypesData.typesChoicesEmployee);
    }
  }, [data, TypesData]);

  const getUrl = (id, isDownload) => {
    downloadFile({
      variables: { id, isDownload },
    });
  };

  const [fileUpdate] = useMutation(FILE_UPDATE, {
    onCompleted(data) {
      if (!!data && data?.fileUpdateEmployee?.s3file) {
        successMessage('Archivo eliminado.', enqueueSnackbar, {
          persist: true,
        });
      }
    },
  });

  function handleState(id) {
    fileUpdate({
      variables: { id },
    });
  }

  function handleModalClose() {
    setOpenDeleteModal(false);
  }
  function handleModalOK() {
    handleState(idState);
    setOpenDeleteModal(false);
  }

  let columns = [];
  if (filesList && filesList.length > 0) {
    columns = [
      generateColumn('Tipo', 'fileType', {
        sort: true,
        customBodyRender: value => {
          let name;
          typeList.map(item => {
            if (item.value == value) {
              name = item.name;
            }
          });
          return name;
        },
      }),
      generateColumn('Archivo', 'fileName', {
        sort: true,
        customBodyRender: value => {
          return value;
        },
      }),
      generateColumn('Fecha', 'uploadedAt', {
        sort: true,
        customBodyRender: value => moment(value).format('DD-MM-YYYY hh:mm'),
        display: true,
      }),
      {
        name: 'id',
        label: 'Opciones',
        options: {
          sort: false,
          customBodyRender: value => {
            const id = value;
            return (
              <>
                <Tooltip title="Visualizar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(false);
                      getUrl(id, false);
                    }}
                    className={classes.optionButtons}
                  >
                    <PageviewOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(true);
                      getUrl(id, true);
                    }}
                    className={classes.optionButtons}
                  >
                    <CloudDownloadIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setOpenDeleteModal(true);
                      setIdState(id);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
          customHeadRender: columnMeta => {
            return (
              <TableCell key={7} style={{ borderBottom: 'none' }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.tableTitle}
                >
                  {columnMeta.label}
                </Typography>
              </TableCell>
            );
          },
        },
      },
    ];
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    download: true,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Cargar Archivo">
            <IconButton
              color="secondary"
              onClick={() => history.push(`${history.location.pathname}/crear`)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Carga Masiva">
            <IconButton
              color="secondary"
              onClick={() =>
                history.push(`${history.location.pathname}/crearMultiple`)
              }
            >
              <BackupIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  };

  return (
    <>
      {!fileLoading ? (
        <MUIDataTable
          title="Repositorio General"
          data={filesList}
          columns={columns}
          options={options}
        />
      ) : (
        <LoadingIndicator />
      )}
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={loadingFile}
      />
      <DeleteModal
        model={'Archivo'}
        open={openDeleteModal}
        handleClose={handleModalClose}
        handleOK={handleModalOK}
      />
    </>
  );
}

export default FilesList;
