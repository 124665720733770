import React, { useEffect, useState } from 'react';

// Material Resources
import { Grid, Button, InputLabel } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { REACT_APP_SERVER_LINK } from '../../../utils/constants';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../../../utils/commonFunctions';
import CustomModal from '../../CustomModal';
import useStyles from './styles';

const ImageInput = ({ field, form, label, oldImage, maxSize, ...other }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [imageEncode, setImageEncode] = useState();
  const [imageName, setImageName] = useState(oldImage);
  const [showModal, setShowModal] = useState(false);
  const { errors: formErrors } = useFormikContext();

  useEffect(() => {
    form.setFieldValue(field.name, null, false);
  }, [oldImage]);

  const validateSize = fileSize => {
    return fileSize <= maxSize * 1024 * 1024;
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async ({ target }) => {
    if (validateSize(target.files[0].size)) {
      try {
        let name = target.files[0].name;
        const encoded = await toBase64(target.files[0]);
        form.setFieldValue(field.name, encoded, false);
        setImageEncode(encoded);
        setImageName(name);
      } catch (error) {
        console.error(`Image input ${field.name}: ${error}`);
      }
    } else {
      errorMessage(
        { message: `La imagen no debe superar los ${maxSize} MB` },
        enqueueSnackbar,
      );
    }
  };

  const getImage = () => {
    if (!!imageEncode) {
      return imageEncode;
    } else if (!!oldImage) {
      return `${REACT_APP_SERVER_LINK}/media/${oldImage}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <CustomModal
        maxWidth="md"
        showModal={showModal}
        title={label}
        onClosePress={() => setShowModal(false)}
      >
        <div className={classes.modalContent}>
          <img alt={label} src={getImage()} className={classes.image} />
        </div>
        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </Button>
        </div>
      </CustomModal>
      <Grid container spacing={3} className={classes.container}>
        <Grid className={classes.labelContainer} item xs={6}>
          <InputLabel>{label}</InputLabel>
          {imageName && (
            <div>
              <small
                className={classes.cursorPointer}
                onClick={() => setShowModal(true)}
              >
                {imageName}
              </small>
            </div>
          )}
          {formErrors[field.name] && !imageName && (
            <div>
              <small className={classes.errorMessage}>
                {formErrors[field.name]}
              </small>
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            component="label"
            color="secondary"
            name={field.name}
          >
            {(!oldImage && 'Buscar imagen') || 'Cambiar imagen'}
            <input
              type="file"
              accept="image/x-png,image/jpeg/, image/png"
              style={{ display: 'none' }}
              onChange={handleChange}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
ImageInput.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  oldImage: PropTypes.string,
  maxSize: PropTypes.number,
};
ImageInput.defaultProps = {
  maxSize: 5,
};

export default ImageInput;
