import React, { useCallback } from 'react';

// Material Resources
import MuiTextField from '@material-ui/core/TextField';

// Others
import { useFieldToTextField } from 'formik-material-ui';

function InputNumber(props) {
  const customize = useCallback(([, , helpers]) => {
    return {
      onChange: event => {
        const { value } = event.target;
        const reg = /^\d+$/;
        if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
          helpers.setValue(value);
        }
      },
    };
  }, []);
  return <MuiTextField {...useFieldToTextField(props, customize)} />;
}

export default InputNumber;
