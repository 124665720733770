import { gql } from 'apollo-boost';

export const LIST_PARTICIPANT_RAISING = gql`
  query listNotValidatedParticipantRaising {
    listNotValidatedParticipantRaising {
      edges {
        node {
          id
          numFee
          amount
          createdDate
          updatedDate
          validated
          classFund {
            id
            name
          }
          lastParticipation {
            id
            order
            state
            participationPercentage
            coParticipants {
              edges {
                node {
                  participationPercentage
                  client {
                    user {
                      firstName
                      lastName
                      maternalSurname
                      documentNumber
                      isVinculated
                    }
                  }
                }
              }
            }
          }
          raising {
            id
            number
            fund {
              id
              businessName
              currency {
                symbol
              }
            }
          }
          client {
            id
            user {
              firstName
              lastName
              maternalSurname
              documentNumber
              isVinculated
            }
          }
        }
      }
    }
  }
`;

export const APPROVE_VINCULATED_PARTICIPANT = gql`
  mutation approveVinculatedParticipant($orderId: ID!) {
    approveVinculatedParticipant(orderId: $orderId) {
      approved
    }
  }
`;

export const LIST_RELATIVES = gql`
  query listRelatives {
    listRelatives {
      edges {
        node {
          documentNumber
          typeRelation
          clientSet {
            edges {
              node {
                user {
                  firstName
                  lastName
                  maternalSurname
                }
              }
            }
          }
        }
      }
    }
  }
`;
