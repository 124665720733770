import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import CategoryForm from '../CategoryForm';
import { GET_CATEGORY } from '../queries';

function CategoryEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_CATEGORY, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get categories', error);
    history.goBack();
  }

  const formatValue = { ...data.category };
  if (!data.category) {
    history.goBack();
  }

  return <CategoryForm category={formatValue} id={id} isEdit />;
}

CategoryEdit.propTypes = {};

export default CategoryEdit;
