import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { TableCell, Typography, Button } from '@material-ui/core';

import { GET_CLIENT_RELATIVE, DELETE_CLIENT_RELATIVE } from '@queries/Client';
import useStyles from './styles';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomCard from '../../../CustomCard';
import ClientTexts from '../../../FormComponents/ClientTexts';
import {
  edgeToList,
  errorMessage,
  getOptions,
} from '../../../../utils/commonFunctions';
import CustomModal from '../../../CustomModal';
import RelativeForm from './RelativeForm';
import NextStepButton from '../../../FormComponents/NextStepButton';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { RELATION_TYPE } from 'src/components/StepsComponents/PersonalData/RelativeForm';

function PEPRelative(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [currentRelative, setCurrentRelative] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [client, setClient] = useState(null);
  const [relatives, setRelatives] = useState([]);

  const {
    loading: loadingData,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_RELATIVE, {
    variables: { id: clientID },
  });

  const deleteRelativeMutation = useMutation(
    DELETE_CLIENT_RELATIVE,
    getOptions({
      mutationName: 'createRelative',
      modelName: 'relative',
      message: 'Se guardó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_RELATIVE, variables: { id: clientID } },
      ],
      onCompleted: data => {
        const { errors } = data.deleteRelative;
        if (errors) {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    }),
  );

  if (clientError) {
    console.error('Error =>', clientError);
  }

  useEffect(() => {
    if (clientData) {
      setClient(clientData.client);
      const tmpRelatives = edgeToList(clientData.client, 'relatives');
      setRelatives(tmpRelatives);
      if (currentRelative) {
        setCurrentRelative(tmpRelatives.find(v => v.id === currentRelative.id));
      }
    }
  }, [clientData, currentRelative]);

  const columns = [
    {
      name: 'typeRelation',
      label: 'Parentesco',
      options: {
        sort: true,
        customBodyRender: value => {
          return RELATION_TYPE?.find(el => el?.value === value)?.label;
        },
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'fullName',
      label: 'Nombres y Apellidos',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Pariente"
            options={['cedit', 'odelete']}
            handleDelete={() =>
              deleteRelativeMutation[0]({ variables: { id } })
            }
            handleEdit={() => {
              const relative = relatives.filter(v => id === v.id)[0];
              setShowForm(true);
              setCurrentRelative(relative);
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentRelative(null);
            setShowForm(true);
          }}
        >
          Añadir pariente
        </Button>
      );
    },
    onRowClick: rowData => {
      setCurrentRelative(relatives.find(v => rowData[2].props.id === v.id));
      setShowForm(true);
    },
  };

  const closeForm = () => {
    setCurrentRelative(null);
    setShowForm(false);
  };

  return loadingData || !client ? (
    <LoadingIndicator />
  ) : (
    <>
      <CustomCard
        title="PARIENTE PEP"
        content={
          <>
            <ClientTexts text="Son aquellas Personas Naturales o Extranjeras que en los últimos 5 años cumplen o hayan cumplido funciones públicas destacadas o funciones prominentes en una en el territorio nacional o extranjero." />
            {client && (
              <ClientTexts
                text={`Yo, ${client.user.firstName} ${client.user.lastName} ${
                  client.user.maternalSurname
                }, identificado con ${
                  client.user.documentType
                    ? client.user.documentType.name
                    : client.alternativeDocumentType
                } Nro ${
                  client.user.documentNumber
                } declaro que toda la información que se indica líneas abajo en relación a la condición de PEP son ciertas.`}
              />
            )}
            <CustomCard
              subtitle="Parientes"
              disableFlex
              content={
                <MUIDataTable
                  title="Parientes"
                  data={relatives}
                  columns={columns}
                  options={options}
                />
              }
            />
            <NextStepButton
              clientID={clientID}
              nextForm={MENU_ITEMS_ID.ClientTermsConditions}
            />
          </>
        }
      />

      <CustomModal
        title="Pariente PEP"
        showModal={showForm}
        onClosePress={() => closeForm()}
      >
        <RelativeForm
          clientId={client.id}
          relativeData={currentRelative}
          closeForm={() => closeForm()}
          setCurrentRelative={id =>
            setCurrentRelative(relatives.filter(v => v.id === id)[0])
          }
        />
      </CustomModal>
    </>
  );
}

PEPRelative.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default PEPRelative;
