function getLastPart(inputString) {
  if (inputString === 'Fondo Edifica USA Renta Fija') {
    // const parts = inputString.split(' ');
    // return parts.slice(-3).join(' ');
    return 'USA';
  }
  if (inputString === 'Fondo Edifica Global') {
    const parts = inputString.split(' ');
    return parts[parts.length - 1];
  }
  const parts = inputString.split(' ');
  console.log(parts.slice(-2).join(' '));
  return parts.slice(-2).join(' ');
}
export default getLastPart;
