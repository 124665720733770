import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  divider: {
    width: '100%',
    height: 2,
    margin: '10px 0',
  },
  inputContainer: {
    border: '1px solid',
    borderRadius: 18,
    borderColor: theme.palette.primary.main,
    '& .MuiGrid-item': {
      padding: 0,
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
      fontWeight: 300,
    },
    '& .MuiInput-underline::before': {
      border: 'none',
    },
    '& .MuiFormLabel-root': {
      width: '135%',
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 300,
    },
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
    },
    paddingLeft: 14,
    paddingRight: 14,
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  itemGrid: {
    borderBottom: 'none',
  },
  arrowIcon: {
    marginLeft: 5,
    marginRight: 5,
  },
}));
