/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import NewsList from '../../../components/Models/News/NewsList';
import NewsEdit from '../../../components/Models/News/NewsCreateEdit/edit';
import NewsForm from '../../../components/Models/News/NewsForm';

function NewsPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={NewsList} />
      <Route path={`${path}/crear`} component={NewsForm} />
      <Route path={`${path}/:id`} component={NewsEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default NewsPage;
