import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import InstrumentForm from '../InstrumentForm';
import { GET_INSTRUMENT } from '../queries';

function InstrumentEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_INSTRUMENT, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get instruments', error);
    history.goBack();
  }

  const formatValue = { ...data.instrument };
  if (!data.instrument) {
    history.goBack();
  }

  return <InstrumentForm instrument={formatValue} id={id} isEdit />;
}

InstrumentEdit.propTypes = {};

export default InstrumentEdit;
