// eslint-disable-next-line import/prefer-default-export
export const permissionsModules = {
  safi: {
    name: "SAFI's",
    enable: false,
    subModules: {
      safi__safi: { name: 'SAFI', enable: false },
    },
  },
  fondos: {
    name: 'Administrador de fondos',
    enable: false,
    subModules: {
      fondos__fondos: { name: 'Fondos', enable: false },
      fondos__mercado_secundario: { name: 'Mercado secundario', enable: false },
      fondos__instrumentos: { name: 'Instrumentos', enable: false },
      fondos__fuentes: { name: 'Fuente', enable: false },
      fondos__clases: { name: 'Clases', enable: false },
    },
  },
  entidades: {
    name: 'Entidades',
    enable: false,
    subModules: {
      entidades__bancos: { name: 'Bancos', enable: false },
      entidades__empresas_promotoras: {
        name: 'Empresas promotoras',
        enable: false,
      },
    },
  },
  configuracion: {
    name: 'Ajustes',
    enable: false,
    subModules: {
      configuracion__monedas: { name: 'Monedas', enable: false },
      configuracion__tipos_de_cuenta: {
        name: 'Tipos de cuenta',
        enable: false,
      },
      configuracion__cuentas_bancarias: {
        name: 'Cuentas bancarias',
        enable: false,
      },
      configuracion__categorias: { name: 'Categorias', enable: false },
    },
  },
  administrador: {
    name: 'Administrador',
    enable: false,
    subModules: {
      administrador__tipos_de_documento: {
        name: 'Tipos de documento',
        enable: false,
      },
      administrador__estados_civiles: {
        name: 'Estados civiles',
        enable: false,
      },
      administrador__regimenes_patrimoniales: {
        name: 'Regimen patrimonial',
        enable: false,
      },
      administrador__rangos_de_ingresos_mensuales: {
        name: 'Rangos de ingreso mensual',
        enable: false,
      },
      administrador__niveles_de_instruccion: {
        name: 'Niveles de instrucción',
        enable: false,
      },
      administrador__relacion_con_la_safi: {
        name: 'Relación con la SAF',
        enable: false,
      },
    },
  },
  usuarios: {
    name: 'Usuarios',
    enable: false,
    subModules: {
      usuarios__administradores: { name: 'Administradores', enable: false },
      usuarios__clientes: { name: 'Clientes', enable: false },
      usuarios__inversionistas_interesados: {
        name: 'Inversionistas interesados',
        enable: false,
      },
      usuarios__promotores: { name: 'Promotores', enable: false },
    },
  },
  ficha_cliente: {
    name: 'Ficha cliente',
    enable: false,
    subModules: {
      ficha_cliente__puntajes_anexo_a: {
        name: 'Puntajes Anexo A',
        enable: false,
      },
    },
  },
  documentos: {
    name: 'Documentos',
    enable: false,
    subModules: {
      documentos__tipos_contrato: {
        name: 'Tipos de contrato',
        enable: false,
      },
      documentos__declaraciones_juradas: {
        name: 'Declaraciones juradas',
        enable: false,
      },
    },
  },
  cumplimiento: {
    name: 'Cumplimiento',
    enable: false,
    subModules: {
      cumplimiento__aprobacion_laft: {
        name: 'Aprobación LAFT',
        enable: false,
      },
      cumplimiento__aprobacion_vinculados: {
        name: 'Aprobación Vinculados',
        enable: false,
      },
      cumplimiento__inversionistas_desaprobados: {
        name: 'Inversionistas desaprobados',
        enable: false,
      },
    },
  },
  firmas: {
    name: 'Firmas',
    enable: false,
    subModules: {
      firmas__procesos: {
        name: 'Procesos',
        enable: false,
      },
      firmas__firmados: {
        name: 'Firmados',
        enable: false,
      },
    },
  },
  archivos: {
    name: 'Archivos',
    enable: false,
    subModules: {
      archivos__carga: {
        name: 'Carga de Archivos',
        enable: false,
      },
    },
  },
  colaboradores: {
    name: 'Colaboradores',
    enable: false,
    subModules: {
      colaboradores__ficha: {
        name: 'Listado',
        enable: false,
      },
      colaboradores__carga_masivo: {
        name: 'Archivos',
        enable: false,
      },
    },
  },
  rop: {
    name: 'ROP',
    enable: false,
    subModules: {
      rop__rop: { name: 'ROP', enable: false },
    },
  },
  comercial: {
    name: 'Comercial',
    enable: false,
    subModules: {
      comercial__radiografia: {
        name: 'Radiografía',
        enable: false,
      },
      comercial__simulador: {
        name: 'Simulador',
        enable: false,
      },
    },
  },
};
