import React from 'react';

function HelpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="18"
      fill="none"
      viewBox="0 0 16 14"
    >
      <path
        stroke="#292D32"
        d="M14.25 7c0 2.984-2.741 5.5-6.25 5.5S1.75 9.984 1.75 7 4.491 1.5 8 1.5s6.25 2.516 6.25 5.5z"
        opacity="0.1"
      />
      <path
        stroke="#292D32"
        d="M14.75 7c0 3.314-3.022 6-6.75 6s-6.75-2.686-6.75-6S4.272 1 8 1s6.75 2.686 6.75 6z"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.875 4.785A1.583 1.583 0 018 4.333c.828 0 1.5.597 1.5 1.334 0 .622-.48 1.145-1.129 1.292-.2.045-.371.19-.371.374v.334M8 9.667h.008"
      />
    </svg>
  );
}

export default HelpIcon;
