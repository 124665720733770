import { gql } from 'apollo-boost';

export const LIST_PROMOTER_COMPANIES = gql`
  query PromoterCompany {
    listPromoterCompany {
      edges {
        node {
          id
          businessName
          ruc
          enabled
          promoters {
            edges {
              node {
                id
                firstName
                lastName
                enabled
                isCoreConsultant
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROMOTER_COMPANY_INFO = gql`
  query PromoterCompany($id: ID!) {
    promoterCompany(id: $id) {
      id
      businessName
      ruc
      address {
        country {
          id
          name
        }
        city {
          id
          name
        }
        county {
          id
          name
        }
        district {
          id
          name
          ubigeo
        }
        detail
      }
      origin
      quotaPromotionCategory
      personType
      safiType
      bankAccounts {
        edges {
          node {
            id
          }
        }
      }
      enabled
      representativeFirstName
      representativeLastName
      representativeDocumentType {
        id
      }
      representativeDocumentNumber
      representativePhone
      representativeEmail
      contactFirstName
      contactLastName
      contactDocumentType {
        id
      }
      contactDocumentNumber
      contactPhone
      contactEmail
    }
  }
`;

export const CREATE_PROMOTER_COMPANY = gql`
  mutation createPromoterCompany($input: PromoterCompanyInput!) {
    createPromoterCompany(input: $input) {
      __typename
      promoterCompany {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROMOTER_COMPANY = gql`
  mutation updatePromoterCompany(
    $id: ID!
    $input: PromoterCompanyUpdateInput!
  ) {
    updatePromoterCompany(id: $id, input: $input) {
      __typename
      promoterCompany {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_PROMOTER_COMPANY = gql`
  mutation DeletePromoterCompany($id: ID!) {
    deletePromoterCompany(id: $id) {
      __typename
      promoterCompany {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
