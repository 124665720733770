import React, { useEffect } from 'react';

// Material Resources
import { Breadcrumbs, Link, Hidden } from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// Styles & Images
import useStyles from './styles';
import CorePartners from '../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';
import CorePartnersNegativo from '../../images/brand/CorePartners/CorePartners_LogoRGB_negativo.png';
import CoreCapitalPositivo from '../../images/brand/CoreCapital/CoreCapital_positivo.png';
import CoreCapitalNegativo from '../../images/brand/CoreCapital/CoreCapital_negativo_.png';
import { useSelector } from 'react-redux';
import getClientMode from '../../store/utils-store/selectors';
import { getClientSelector } from 'src/store/sharedStore/selectors';
import {
  Dashboard as DashboardIcon,
  ListAlt as ListAltIcon,
  Receipt as ReceiptIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  Create as CreateIcon,
} from '@material-ui/icons';
import { getAuthUser } from 'src/utils/storage';

const PATH_TO_TITLE = {
  // Admin
  safis: "SAFI's",
  fondos: 'Fondos',
  instrumentos: 'Instrumentos',
  fuentes: 'Fuentes de Fondos',
  clases: 'Clases de Fondos',
  bancos: 'Bancos',
  monedas: 'Monedas',
  'tipos-de-cuenta': 'Tipos de cuentas',
  'cuentas-bancarias': 'Cuentas bancarias',
  categorias: 'Categorías',
  'tipos-de-documento': 'Tipos de documento',
  'estados-civiles': 'Estados civiles',
  'regimenes-patrimoniales': 'Regímenes patrimoniales',
  'ingresos-mensuales': 'Rangos de ingresos mensuales',
  'niveles-de-instruccion': 'Niveles de instrucción',
  'relacion-con-la-safi': 'Relación con la SAF',
  clientes: 'Clientes',
  'inversionistas-interesados': 'Inversionistas interesados',
  administradores: 'Administradores',
  'editar-menu': 'Ajustes de usuarios',
  cognito: 'Cógnito',
  'tipos-de-contrato': 'Tipos de contrato',
  'declaraciones-juradas': 'Declaraciones juradas',
  'mercado-secundario': 'Mercado Secundario',
  'aprobacion-laft': 'Aprobación LAFT',
  'aprobacion-vinculados': 'Aprobación Vinculados',
  'inversionistas-desaprobados': 'Inversionistas desaprobados',
  procesos: 'Procesos',
  firmados: 'Firmados',
  carga: 'Carga de archivos',

  // Client
  'ficha-cliente': 'Ficha Colaborador',
  'registro-pagos': 'Registro de Pagos',
  'firma-contratos': 'Firma de Contratos',
  'firma-contratos-mercado-secundario': 'Firma de Contratos mercado secundario',
  'estados-de-cuenta-de-inversiones': 'Estados de Cuenta de Inversiones',
  firmas: 'Firmas',
  documentos: 'Repositorio Personal',
  'documentos-generales': 'Repositorio General',

  // Both
  perfil: 'Perfil',
};

function CustomTitle(props) {
  const modeClient = useSelector(getClientMode());
  const authUser = getAuthUser();
  const TITLE_TO_ICON = {
    // Client
    'ficha-cliente': (
      <ListAltIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    'registro-pagos': (
      <AccountBalanceWalletIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    'firma-contratos': (
      <CreateIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    'firma-contratos-mercado-secundario': (
      <CreateIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    'estados-de-cuenta-de-inversiones': (
      <ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    firmas: <CreateIcon color={modeClient ? 'primary' : 'secondary'} />,
    documentos: <ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />,
    'documentos-generales': (
      <ReceiptIcon color={modeClient ? 'primary' : 'secondary'} />
    ),
    perfil: 'Perfil',
  };
  const { menu, secondLogo } = props;

  const classes = useStyles();
  const location = useLocation();
  const logoSelector = modeClient ? CoreCapitalNegativo : CoreCapitalPositivo;

  const selectClass = classes.logo;
  useEffect(() => {}, []);
  const titleStyle = modeClient ? classes.titleDark : classes.title;

  return (
    <div className={titleStyle}>
      <Hidden mdDown>
        <Breadcrumbs aria-label="breadcrumb">
          {authUser.usergroup !== 'client_group' && (
            <>
              {menu ? (
                <Link
                  className={classes.coreCapitalLink}
                  color="inherit"
                  component={RouterLink}
                  to={`/${location.pathname.split('/').filter(x => x)[0]}`}
                >
                  {menu}
                </Link>
              ) : (
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={`/${location.pathname.split('/').filter(x => x)[0]}`}
                >
                  {/* {secondLogo ? (
                <img src={CoreCapital} alt="logo" className={classes.logo} />
              ) : ( */}

                  <img src={logoSelector} alt="logo" className={selectClass} />
                  {/* )} */}
                </Link>
              )}
            </>
          )}

          <Link
            className={classes.normalLink}
            color="inherit"
            component={RouterLink}
            to={
              location.pathname.split('/').filter(x => x)[1]
                ? `/${location.pathname.split('/').filter(x => x)[0]}/${
                    location.pathname.split('/').filter(x => x)[1]
                  }`
                : location.pathname
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              {authUser.usergroup === 'client_group' &&
                TITLE_TO_ICON[location.pathname.split('/').filter(x => x)[1]]}

              {location.pathname.split('/').filter(x => x)[1]
                ? PATH_TO_TITLE[location.pathname.split('/').filter(x => x)[1]]
                : 'Dashboard'}
            </div>
          </Link>
        </Breadcrumbs>
      </Hidden>
      <Hidden lgUp>
        <Link
          className={classes.normalLink}
          color="inherit"
          component={RouterLink}
          to={
            location.pathname.split('/').filter(x => x)[1]
              ? `/${location.pathname.split('/').filter(x => x)[0]}/${
                  location.pathname.split('/').filter(x => x)[1]
                }`
              : location.pathname
          }
        >
          {location.pathname.split('/').filter(x => x)[1]
            ? PATH_TO_TITLE[location.pathname.split('/').filter(x => x)[1]]
            : 'Dashboard'}
        </Link>
      </Hidden>
    </div>
  );
}

CustomTitle.propTypes = {};
CustomTitle.defaultProps = {
  secondLogo: false,
};

export default CustomTitle;
