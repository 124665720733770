import React, { useState, useEffect } from 'react';

// Material Resources
import { IconButton, Grid, Divider, Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';
import Yup from '../../../../../../utils/yup';

// Styles & Images
import useStyles from './styles';
import {
  successMessage,
  errorMessage,
} from '../../../../../../utils/commonFunctions';
import { SEND_EMAILS_RAISING } from './queries';
import { SEND_QUOTA_VALORIZATION_NOTICE } from '../queries';

function SingleInput(props) {
  const classes = useStyles();

  const { item, hideDelete, handleItemDelete } = props;
  return (
    <>
      <Grid item md={6} xs={12}>
        <TextField
          name={item.name}
          label="Nombre"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item md={hideDelete ? 6 : 5} xs={hideDelete ? 12 : 10}>
        <TextField
          name={item.email}
          label="Email"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item md={1} xs={2}>
        {!hideDelete && (
          <IconButton
            className={classes.minusButton}
            color="secondary"
            onClick={() => handleItemDelete()}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item md={12} xs={12}>
        <Divider />
      </Grid>
    </>
  );
}
SingleInput.propTypes = {
  item: PropTypes.shape.isRequired,
  hideDelete: PropTypes.bool,
  handleItemDelete: PropTypes.func.isRequired,
};
SingleInput.defaultProps = {
  hideDelete: false,
};

function RaisingsIndividualEmails(props) {
  const { raisingID, isQuotaValorizationNotice, onFinish } = props;
  const classes = useStyles();

  const [loadingSend, setLoadingSend] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [sendEmails] = useMutation(SEND_EMAILS_RAISING, {
    onError: error => {
      errorMessage(error, enqueueSnackbar);
      setLoadingSend(false);
    },
    onCompleted: response => {
      const data = response.emailRaisingNotice.sent;
      if (data) {
        successMessage(
          'Aviso de Levantamientos enviado con éxito',
          enqueueSnackbar
        );
      } else {
        errorMessage(
          'Ocurrió un error en el envió, inténtelo mas tarde',
          enqueueSnackbar
        );
      }
      setLoadingSend(false);
    },
  });

  const [sendQuotaValorizationNotice] = useMutation(
    SEND_QUOTA_VALORIZATION_NOTICE,
    {
      onError: error => {
        errorMessage(error, enqueueSnackbar);
        setLoadingSend(false);
      },
      onCompleted: response => {
        const data = response.sendQuotaValorizationNotice.sent;
        if (data) {
          successMessage(
            'Aviso de valorización de cuotas enviado con éxito',
            enqueueSnackbar
          );
        } else {
          errorMessage(
            'Ocurrió un error en el envío, inténtelo mas tarde',
            enqueueSnackbar
          );
        }
        setLoadingSend(false);
      },
    }
  );

  const validationSchema = Yup.object().shape({
    emails: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string()
            .email()
            .required(),
        })
      )
      .required(),
  });

  return (
    <>
      <div className={classes.simpleFormContainer}>
        <Formik
          initialValues={{ emails: [{ name: '', email: '' }] }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setLoadingSend(true);
            if (isQuotaValorizationNotice) {
              sendQuotaValorizationNotice({
                variables: {
                  raisingId: raisingID,
                  recipients: values.emails,
                },
              });
            } else {
              sendEmails({
                variables: {
                  raisingId: raisingID,
                  recipients: values.emails,
                },
              });
            }
            setSubmitting(false);
            onFinish();
          }}
          validateOnblur
          validateOnChange
        >
          {({ submitForm, isSubmitting, values }) => (
            <Form className={classes.simpleForm}>
              <FieldArray
                name="emails"
                render={arrayHelpers => (
                  <Grid container justify="space-around">
                    {values.emails.map((item, index) => (
                      <SingleInput
                        key={index}
                        item={{
                          name: `emails[${index}].name`,
                          email: `emails[${index}].email`,
                        }}
                        hideDelete={values.emails.length === 1}
                        handleItemDelete={() => arrayHelpers.remove(index)}
                      />
                    ))}
                    <IconButton
                      className={classes.minusButton}
                      color="secondary"
                      onClick={() => arrayHelpers.push({ name: '', email: '' })}
                      disabled={loadingSend}
                    >
                      <AddIcon />
                    </IconButton>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={submitForm}
                      disabled={loadingSend}
                    >
                      Enviar
                    </Button>
                  </Grid>
                )}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

RaisingsIndividualEmails.propTypes = {
  raisingID: PropTypes.string.isRequired,
};

export default RaisingsIndividualEmails;
