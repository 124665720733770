import React from 'react';
import cx from 'clsx';
import { Typography, Grid, ListItem, ListItemText } from '@material-ui/core';
import PropTypes from 'prop-types';

import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import GetApp from '@material-ui/icons/GetApp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StopIcon from '@material-ui/icons/Stop';

import {
  edgeToList,
  toMoneyRepresentation,
  parseDatesInObject,
} from '../../../utils/commonFunctions';

import useStyles from './styles';
import { REACT_APP_SERVER_LINK } from '../../../utils/constants';
import NumberFormat from 'react-number-format';

function Title(props) {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <StopIcon className={styles.squareBullet} />
      <ListItemText className={styles.listTitle}>{children}</ListItemText>
    </ListItem>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
};
const Cell = props => {
  const styles = useStyles();
  const { children } = props;
  return (
    <ListItem>
      <ListItemText className={styles.listCell}>{children}</ListItemText>
    </ListItem>
  );
};

Cell.propTypes = {
  children: PropTypes.node.isRequired,
};

function StepOneForm(props) {
  let { raising } = props;
  raising = parseDatesInObject(raising);
  const fund = parseDatesInObject(raising.fund);

  const filterDocuments = () => {
    const uniqueFileTypes = [];
    return edgeToList(fund, 'funddocumentSet').filter(document => {
      if (document.state && uniqueFileTypes.indexOf(document.fileType) === -1) {
        uniqueFileTypes.push(document.fileType);
        return true;
      }
      return false;
    });
  };

  const styles = useStyles();
  const currency = `${fund.currency.code}`;
  const documents = filterDocuments();
  const wheels = edgeToList(raising, 'wheelSet')
    .map(wheel => parseDatesInObject(wheel))
    .filter(wheel => wheel.number == 2);

  const getIcon = fileType => {
    if (fileType === 'P') {
      return <DescriptionOutlined classes={{ root: styles.documentIcon }} />;
    }
    return <DescriptionOutlined classes={{ root: styles.documentIcon }} />;
  };

  const getName = fileType => {
    if (fileType === 'P') {
      return 'PPT';
    }
    if (fileType === 'R') {
      return 'REGLAMENTO';
    }
    return 'RESUMEN';
  };

  const getFundsType = () => {
    switch (fund.fundType) {
      case 'PR':
        return 'privadas';
      case 'PB':
        return 'públicas';
      default:
        return '';
    }
  };

  return (
    <Grid container justify="center">
      <Grid
        container
        justify="space-evenly"
        classes={{ root: styles.documents }}
      >
        {documents.map(document => (
          <Grid item className={styles.document} key={document.file}>
            {getIcon(document.fileType)}
            {getName(document.fileType)}
            <a
              className={styles.downloadButton}
              href={`${REACT_APP_SERVER_LINK}/media/${document.file}`}
              target="_blank"
            >
              <GetApp className={styles.downloadIcon} />
              <span>Descargar</span>
            </a>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={styles.table}
        direction="row"
        alignContent="flex-start"
      >
        <Grid item xs={12} className={styles.tableHead}>
          <MonetizationOnIcon className={styles.rowIcon} />
          <h3>CANTIDADES Y MONTOS</h3>
        </Grid>
        <Grid item xs={6}>
          <Title>Monto de colocación:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency} {toMoneyRepresentation(raising.targetCapitalIncrease)}
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Inversión mínima por partícipe:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency} {toMoneyRepresentation(raising.minimumInvestment)}
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Valor nominal de las cuotas</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency}{' '}
            <NumberFormat
              displayType="text"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              value={toMoneyRepresentation(raising.valueFeeNominal)}
            />
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Valor de colocación de las cuotas</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            {currency}{' '}
            <NumberFormat
              displayType="text"
              thousandSeparator
              decimalScale={10}
              fixedDecimalScale
              value={toMoneyRepresentation(raising.valueFee, 10)}
            />
          </Cell>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        className={cx(styles.table, styles.leftDivider)}
        direction="row"
      >
        <Grid item xs={12} className={styles.tableHead}>
          <EventAvailableIcon className={styles.rowIcon} />
          <h3>FECHAS</h3>
        </Grid>
        <Grid item xs={6}>
          <Title>Fecha de recepción de órdenes de participación:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {raising.initialParticipationOrderReceptionDate} al{' '}
            {raising.lastParticipationOrderReceptionDate}
          </Cell>
        </Grid>
        {wheels.length ? (
          <>
            <Grid item xs={6}>
              <Title>Rueda Única:</Title>
            </Grid>
            <Grid item xs={6} className={styles.wheelDates}>
              <Cell className={styles.wheelDates}>
                {/* {wheels
                  .map(
                    (wheel, idx) => */}
                Del {wheels[0].dateInitial} al {wheels[0].dateEnd}
                {/* )} */}
              </Cell>
            </Grid>
            <Grid item xs={6}>
              <Title>Comunicación de adjudicación de cuotas:</Title>
            </Grid>
            <Grid item xs={6} className={styles.wheelDates}>
              <Cell>
                Del {raising.initialFeeAdjudicationDate} al{' '}
                {raising.finalFeeAdjudicationDate}
              </Cell>
            </Grid>{' '}
          </>
        ) : null}
        <Grid item xs={6}>
          <Title>Pago de cuotas:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {raising.initialFeePaymentDate} al {raising.lastFeePaymentDate}
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Firma de contrato:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {raising.dateInitialContract} al {raising.dateEndContract}
          </Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Fecha de emisión de cuotas:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>{raising.dateEmissionFee}</Cell>
        </Grid>
        <Grid item xs={6}>
          <Title>Fecha de envío de certificados de participación:</Title>
        </Grid>
        <Grid item xs={6}>
          <Cell>
            Del {raising.dateInitialCertificateSent} al{' '}
            {raising.dateEndCertificateSent}
          </Cell>
        </Grid>
      </Grid>
    </Grid>
  );
}

StepOneForm.propTypes = {
  raising: PropTypes.shape().isRequired,
};

export default StepOneForm;
