import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUserPermissionsSelector } from '../../store/permissionsStore/selector';

const PERMISSIONS_ROUTE = {
  '/administrador/safis': 'safi',
  '/administrador/fondos': 'fondos',
  '/administrador/mercado-secundario': 'mercado_secundario',
  '/administrador/instrumentos': 'instrumentos',
  '/administrador/fuentes': 'fuentes',
  '/administrador/clases': 'clases',
  '/administrador/bancos': 'bancos',
  '/administrador/empresas-promotoras': 'empresas_promotoras',
  '/administrador/monedas': 'monedas',
  '/administrador/tipos-de-cuenta': 'tipos_de_cuenta',
  '/administrador/cuentas-bancarias': 'cuentas_bancarias',
  '/administrador/categorias': 'categorias',
  '/administrador/tipos-de-documento': 'tipos_de_documento',
  '/administrador/estados-civiles': 'estados_civiles',
  '/administrador/regimenes-patrimoniales': 'regimenes_patrimoniales',
  '/administrador/ingresos-mensuales': 'rangos_de_ingresos_mensuales',
  '/administrador/niveles-de-instruccion': 'niveles_de_instruccion',
  '/administrador/relacion-con-la-safi': 'relacion_con_la_safi',
  '/administrador/administradores': 'administradores',
  '/administrador/clientes': 'clientes',
  '/administrador/inversionistas-interesados': 'inversionistas_interesados',
  '/administrador/promotores': 'promotores',
  '/administrador/score': 'puntajes_anexo_a',
  '/administrador/tipos-de-contrato': 'tipos_contrato',
  '/administrador/declaraciones-juradas': 'declaraciones_juradas',
  '/administrador/aprobacion-laft': 'aprobacion_laft',
  '/administrador/aprobacion-vinculados': 'aprobacion_vinculados',
  '/administrador/inversionistas-desaprobados': 'inversionistas_desaprobados',
  '/administrador/procesos': 'procesos',
  '/administrador/firmados': 'firmados',
  '/administrador/carga': 'carga',
  '/administrador/ficha-colaborador': 'ficha',
  '/administrador/carga-colaborador': 'carga_masivo',
};

function PermissionsRoute({ component: Component, path, ...rest }) {
  const userPermissions = useSelector(getUserPermissionsSelector());
  const [permissions, setPermissions] = useState(null);

  function parsePermissions() {
    const menuStructure = [];
    userPermissions.forEach(permission => {
      const names = permission.slug.split('__');
      menuStructure.push(names[1]);
    });
    return menuStructure;
  }

  useEffect(() => {
    setPermissions(parsePermissions());
  }, [userPermissions]);

  return (
    <>
      {permissions && (
        <Route
          {...rest}
          path={path}
          render={props =>
            permissions.includes(PERMISSIONS_ROUTE[path]) ? (
              <>
                <Component {...props} />
              </>
            ) : (
              <Component {...props} />
              // <Redirect to="/administrador" />
            )
          }
        />
      )}
    </>
  );
}
PermissionsRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PermissionsRoute;
