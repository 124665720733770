import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, SvgIcon, Hidden, Popover, Fab } from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import {
  Description as DescriptionIcon,
  MenuOpen as MenuOpenIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  getMarriedSelector,
  getSocietySelector,
  getActiveFormSelector,
  getAttorneySelector,
  getNodeListSelector,
  getMissingStepsSelector,
} from './selectors';
import {
  setActiveFormAction,
  setMarriedAction,
  setSocietyAction,
  setAttorneyAction,
  setClientPEPAction,
  setAnnexBAction,
  setSpousePEPAction,
  setRelativePEPAction,
  setVisible,
  setStatus,
  setEdition,
  setIsPeruvian,
  setMissingSteps,
} from './actions';
import useStyles from './styles';
import { MENU_ITEMS_ID, NODE_STATES } from './constants';
import PersonalDataClient from '../../../components/StepsComponents/PersonalData/Client';
import PersonalDataSpouse from '../../../components/StepsComponents/PersonalData/Spouse';
import PersonalDataAttorney from '../../../components/StepsComponents/PersonalData/Attorney';
import LegalDataClient from '../../../components/StepsComponents/LegalData/Client';
import LabourDataClient from '../../../components/StepsComponents/LaborData/Client';
import LegalDataSpouse from '../../../components/StepsComponents/LegalData/Spouse';
import LegalDataAttorney from '../../../components/StepsComponents/LegalData/Attorney';
import LaborDataSpouse from '../../../components/StepsComponents/LaborData/Spouse';
import LaborDataAttorney from '../../../components/StepsComponents/LaborData/Attorney';
import SafiRelationClient from '../../../components/StepsComponents/SafiRelation/Client';
import SafiRelationSpouse from '../../../components/StepsComponents/SafiRelation/Spouse';
import BankDataBankAccountList from '../../../components/StepsComponents/BankData/BankAccountList';
import LoadingIndicator from '../../../components/LoadingIndicator';
import RiskProfileAnnexA from '../../../components/StepsComponents/AnnexA/RiskProfile';
import InvestorKnowledge from '../../../components/StepsComponents/AnnexA/InvestorKnowledge';
import { GET_CLIENT_DATA, LIST_MISSING_STEP } from './queries';
import ClientPEP from '../../../components/StepsComponents/PEP/Client';
import TermsConditions from '../../../components/StepsComponents/TermsConditions';
import PEPRelative from '../../../components/StepsComponents/PEP/Relative';
import SpousePEP from '../../../components/StepsComponents/PEP/Spouse';
import { LIST_COUNTRIES } from '../../../components/FormComponents/PlacesSelects';
import { errorMessage, edgeToList } from '../../../utils/commonFunctions';
import { getClientSelector } from 'src/store/sharedStore/selectors';

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TreeFormat(data) {
  const list = [...data];
  const result = [];
  let parents = [];
  let depth = 0;

  list.forEach(item => {
    const knode = {
      nodeId: item.id,
      label: item.name,
      active: item.active,
      visible: item.visible,
      status: item.status,
      subNodes: [],
      parent: null, // Parent reference for future validation
    };
    if (item.depth > 0) {
      if (item.depth > depth) {
        const parent = parents.slice(-1)[0];
        knode.parent = parent;
        parent.subNodes.push(knode);
        depth = item.depth;
        parents.push(knode);
      } else if (item.depth === depth) {
        parents.pop();
        const parent = parents.slice(-1)[0];
        knode.parent = parent;
        parent.subNodes.push(knode);
        parents.push(knode);
      } else {
        parents = parents.slice(0, item.depth);
        const parent = parents.slice(-1)[0];
        knode.parent = parent;
        parent.subNodes.push(knode);
        depth = item.depth;
        parents.push(knode);
      }
    } else {
      result.push(knode);
      parents = [];
      parents.push(knode);
      depth = 0;
    }
  });
  return result;
}
function TreeNode(props) {
  const { data, setActive } = props;
  const classes = useStyles();

  const state = {
    [NODE_STATES.complete]: classes.itemComplete,
    [NODE_STATES.incomplete]: classes.itemIncomplete,
  };

  return (
    <>
      {data.visible && (
        <TreeItem
          nodeId={data.nodeId}
          label={data.label}
          className={
            data.active && data.subNodes.length === 0 ? classes.treeItem : ''
          }
          classes={{
            root: classes.itemRoot,
            group: classes.group,
            iconContainer: data.subNodes.length === 0 && state[data.status],
            label: classes.itemLabel,
          }}
          onClick={e => data.subNodes.length === 0 && setActive(e, data.nodeId)}
        >
          {data.subNodes.length > 0
            ? data.subNodes.map(node => (
                <TreeNode key={node.nodeId} data={node} setActive={setActive} />
              ))
            : null}
        </TreeItem>
      )}
    </>
  );
}

TreeNode.propTypes = {
  data: PropTypes.shape().isRequired,
  setActive: PropTypes.func.isRequired,
};

const componentsByTag = {
  [MENU_ITEMS_ID.ClientPersonalData]: PersonalDataClient,
  [MENU_ITEMS_ID.ClientLegalData]: LegalDataClient,
  [MENU_ITEMS_ID.ClientLabourData]: LabourDataClient,
  [MENU_ITEMS_ID.ClientSafiRelation]: SafiRelationClient,
  // [MENU_ITEMS_ID.ClientBankData]: BankDataBankAccountList,
  [MENU_ITEMS_ID.SpousePartnerPersonalData]: PersonalDataSpouse,
  [MENU_ITEMS_ID.SpousePartnerLegalData]: LegalDataSpouse,
  [MENU_ITEMS_ID.SpousePartnerLaborData]: LaborDataSpouse,
  [MENU_ITEMS_ID.SpousePartnerSafiRelation]: SafiRelationSpouse,
  [MENU_ITEMS_ID.AttorneyPersonalData]: PersonalDataAttorney,
  [MENU_ITEMS_ID.AttorneyLegalData]: LegalDataAttorney,
  [MENU_ITEMS_ID.AttorneyLabourData]: LaborDataAttorney,
  // [MENU_ITEMS_ID.AnexARiskProfile]: RiskProfileAnnexA,
  // [MENU_ITEMS_ID.InvestorKnowledge]: InvestorKnowledge,
  [MENU_ITEMS_ID.ClientPEP]: ClientPEP,
  [MENU_ITEMS_ID.SpousePEP]: SpousePEP,
  [MENU_ITEMS_ID.RelativePEP]: PEPRelative,
  [MENU_ITEMS_ID.ClientTermsConditions]: TermsConditions,
};

const getClient = data => {
  const client = data.currentUser.clientSet.edges.find(
    node => node.node.isMain === false,
  );
  const [clientEdge] = data.currentUser.clientSet.edges;
  return client ? client.node : clientEdge.node;
};

const getMainClient = data => {
  const client = data.currentUser.clientSet.edges.find(
    node => node.node.isMain === true,
  );
  return client ? client.node : null;
};

function ClientFormPage() {
  const [expanded, setExpanded] = useState(Object.values(MENU_ITEMS_ID));
  const [anchorEl, setAnchorEl] = useState(null);
  const [firstRun, setFirstRun] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const isMarried = useSelector(getMarriedSelector());
  const isSociety = useSelector(getSocietySelector());
  const hasAttorney = useSelector(getAttorneySelector());
  const activeForm = useSelector(getActiveFormSelector());
  const list = useSelector(getNodeListSelector()).toJS();

  const SetListVisibles = useCallback(
    (menuList, menuStatus) => {
      for (let i = 0; i < menuList.length; i += 1) {
        dispatch(setVisible(menuList[i].id, true));
        if (menuList[i].id === menuStatus) {
          return;
        }
        if (firstRun) {
          dispatch(setStatus(menuList[i].id, NODE_STATES.complete));
        }
      }
    },
    [dispatch, firstRun],
  );

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_DATA);

  const {
    loading: countryLoading,
    data: countryData,
    error: countryError,
  } = useQuery(LIST_COUNTRIES);

  const {
    loading: missingStepLoading,
    data: missingStepData,
    error: missingStepError,
  } = useQuery(LIST_MISSING_STEP);

  if (clientError || countryError) {
    errorMessage(
      'Hubo un problema, por favor inténtelo de nuevo.',
      enqueueSnackbar,
    );
  }

  const isPeru = useCallback(
    id => {
      if (
        countryData &&
        countryData.listCountries &&
        countryData.listCountries.edges
      ) {
        return !!countryData.listCountries.edges.find(val => {
          return val.node && val.node.id === id && val.node.code === 'PE';
        });
      }
      return false;
    },
    [countryData],
  );

  useEffect(() => {
    if (!clientLoading && clientData && missingStepData) {
      const client = getClient(clientData);
      const mainClient = getMainClient(clientData);

      if (client.treeStatus === '') {
        dispatch(setActiveFormAction(MENU_ITEMS_ID.ClientPersonalData));
        dispatch(setVisible(MENU_ITEMS_ID.ClientPersonalData, true));
      } else if (mainClient && mainClient.pdfSheet) {
        if (firstRun) {
          dispatch(setActiveFormAction(MENU_ITEMS_ID.ClientTermsConditions));
        }
        SetListVisibles(list, MENU_ITEMS_ID.ClientTermsConditions, true);
      } else {
        if (firstRun) {
          dispatch(setActiveFormAction(client.treeStatus));
        }
        SetListVisibles(list, client.treeStatus);
      }

      // if (client.maritalStatus && client.maritalStatus.spouse) {
      //   dispatch(setMarriedAction(true));
      //   if (client.propertyRegime && client.propertyRegime.society) {
      //     dispatch(setSocietyAction(true));
      // dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerSafiRelation, false));
      //   } else {
      dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerLaborData, false));
      dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerSafiRelation, false));
      //   }
      // } else {
      dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerNode, false));
      dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerPersonalData, false));
      dispatch(setVisible(MENU_ITEMS_ID.SpousePartnerLegalData, false));
      // }

      if (client.assignee) {
        dispatch(setAttorneyAction(true));
      } else {
        dispatch(setVisible(MENU_ITEMS_ID.AttorneyClientNode, false));
        dispatch(setVisible(MENU_ITEMS_ID.AttorneyLegalData, false));
        dispatch(setVisible(MENU_ITEMS_ID.AttorneyLabourData, false));
        dispatch(setVisible(MENU_ITEMS_ID.AttorneyPersonalData, false));
      }
      if (client.pep) {
        dispatch(setClientPEPAction(true));
        dispatch(setAnnexBAction(true));
      } else {
        dispatch(setVisible(MENU_ITEMS_ID.ClientPEP, false));
      }
      // if (client.spousePep) {
      //   dispatch(setSpousePEPAction(true));
      //   dispatch(setAnnexBAction(true));
      // } else {
      dispatch(setVisible(MENU_ITEMS_ID.SpousePEP, false));
      // }
      if (client.relativePep) {
        dispatch(setRelativePEPAction(true));
        dispatch(setAnnexBAction(true));
      } else {
        dispatch(setVisible(MENU_ITEMS_ID.RelativePEP, false));
      }
      if (!client.pep && !client.spousePep && !client.relativePep) {
        dispatch(setAnnexBAction(false));
      }
      if (mainClient && mainClient.pdfSheet) {
        dispatch(setEdition(true));
      }
      if (client.missingSteps) {
        const steps = edgeToList(client, 'missingSteps').map(item => item.step);
        steps.forEach(item => {
          if (item != 'TC') dispatch(setStatus(item, NODE_STATES.incomplete));
        });
        dispatch(setMissingSteps(steps));
      }

      setFirstRun(false);
    }
  }, [
    clientLoading,
    clientData,
    missingStepData,
    dispatch,
    firstRun,
    SetListVisibles,
  ]);

  useEffect(() => {
    if (!clientLoading && clientData && !countryLoading) {
      const client = getClient(clientData);
      if (client.birthplace) {
        dispatch(setIsPeruvian(isPeru(client.birthplace.country.id)));
      }
    }
  }, [countryLoading, clientData, clientLoading, dispatch, isPeru]);

  if (clientLoading || !clientData) {
    return <LoadingIndicator />;
  }

  const client = getClient(clientData);

  const treeList = TreeFormat(list);

  const handleChange = (event, nodes) => {
    event.preventDefault();
    setExpanded(nodes);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const setActiveNode = (_, nodeId) => {
    dispatch(setActiveFormAction(nodeId));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const CurrentComponent = componentsByTag[`${activeForm}`];
  const mode = JSON.parse(localStorage.getItem('mode'));

  const containerStyle = mode
    ? classes.treeContainerDark
    : classes.treeContainer;
  return (
    <Grid container>
      <Hidden lgUp>
        <Fab
          color="primary"
          aria-label="show-tree"
          onClick={handlePopoverOpen}
          className={classes.floatingButton}
        >
          <MenuOpenIcon />
        </Fab>

        <Popover
          id="show-tree"
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <TreeView
            className={classes.rootMenuTree}
            expanded={expanded}
            onNodeToggle={handleChange}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={
              <DescriptionIcon className={classes.customTreeIcon} />
            }
          >
            {treeList &&
              treeList.map(element => (
                <TreeNode
                  key={element.nodeId}
                  data={element}
                  setActive={setActiveNode}
                />
              ))}
          </TreeView>
        </Popover>
      </Hidden>
      <Hidden mdDown>
        <Grid item lg={4} xs={12} className={containerStyle}>
          <TreeView
            className={classes.root}
            expanded={expanded}
            onNodeToggle={handleChange}
            defaultCollapseIcon={
              <MinusSquare className={classes.customTreeIconExpand} />
            }
            defaultExpandIcon={
              <PlusSquare className={classes.customTreeIconExpand} />
            }
            defaultEndIcon={
              <DescriptionIcon className={classes.customTreeIcon} />
            }
          >
            {treeList &&
              treeList.map(element => (
                <TreeNode
                  key={element.nodeId}
                  data={element}
                  setActive={setActiveNode}
                />
              ))}
          </TreeView>
        </Grid>
      </Hidden>
      <Grid item lg={8} xs={12} className={classes.forms}>
        {clientLoading && !clientData ? (
          <LoadingIndicator />
        ) : (
          !clientError && (
            <CurrentComponent
              clientID={client.id}
              isMarried={isMarried}
              hasAttorney={hasAttorney}
              isSociety={isSociety}
            />
          )
        )}
      </Grid>
    </Grid>
  );
}

ClientFormPage.propTypes = {};

export default ClientFormPage;
