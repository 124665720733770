// GraphQl
import { gql } from 'apollo-boost';

export const LIST_VCC_FUND = gql`
  query getFundAccountingFeeValues($id: ID!) {
    fund(id: $id) {
      accountingfeevalueSet {
        edges {
          node {
            id
            date
            value
          }
        }
      }
    }
  }
`;

export const GET_VCC_FUND = gql`
  query getAccountingFeeValue($id: ID!) {
    accountingFeeValue(id: $id) {
      id
      date
      value
      __typename
    }
    __typename
  }
`;

export const CREATE_VCC_FUND = gql`
  mutation createAccountingFeeValue($input: AccountingFeeValueInput!) {
    createAccountingFeeValue(input: $input) {
      accountingFeeValue {
        id
        fund {
          id
        }
        date
        value
      }

      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_VCC_FUND = gql`
  mutation updateAccountingFeeValue(
    $id: ID!
    $input: AccountingFeeValueInput!
  ) {
    updateAccountingFeeValue(id: $id, input: $input) {
      accountingFeeValue {
        id
        date
        value
        __typename
      }
      __typename
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_VCC_FUND = gql`
  mutation deleteAccountingFeeValue($id: ID!) {
    deleteAccountingFeeValue(id: $id) {
      accountingFeeValue {
        id
        __typename
      }
      __typename
      errors {
        field
        message
      }
    }
  }
`;
