import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
// GraphQl
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import LoadingIndicator from '../../../LoadingIndicator';
import {
  LIST_PARTICIPANT_RAISING,
  APPROVE_VINCULATED_PARTICIPANT,
  LIST_RELATIVES,
} from '../queries';
import { LIST_EMPLOYEES } from '../../Employee/queries';
import {
  generateColumn,
  edgeToList,
  getOptions,
  decimalAdjust,
} from '../../../../utils/commonFunctions';
import { TableCell, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import CustomOptions from '../../../CustomOptions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { RELATION_TYPE } from 'src/components/StepsComponents/PersonalData/RelativeForm';

// styles and icons
import useStyles from './styles';

function VinculatedList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [participationList, setParticipationList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [relativeList, setRelativeList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [isEmployee, setIsEmployee] = useState(false);

  const [approveComplianceClient] = useMutation(
    APPROVE_VINCULATED_PARTICIPANT,
    getOptions({
      mutationName: 'approveVinculatedParticipant',
      message: 'Orden Aprobada',
      modelName: 'approved',
      completeCallback: () => getParticipants(),
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PARTICIPANT_RAISING }],
    }),
  );

  const [getParticipants, { error, loading }] = useLazyQuery(
    LIST_PARTICIPANT_RAISING,
    {
      fetchPolicy: 'no-cache',
      onCompleted: dataList => {
        if (dataList) {
          const participationListTmp = edgeToList(
            dataList,
            'listNotValidatedParticipantRaising',
          )
            .filter(({ lastParticipation }) => !!lastParticipation)
            .map(participation => {
              const {
                id,
                lastParticipation,
                createdDate,
                numFee,
                amount,
                client,
                raising,
                classFund,
                validated,
              } = participation;
              setCurrencySymbol(raising.fund.currency.symbol);
              const newParticipation = {
                options: {
                  id: id,
                  state: lastParticipation.state,
                  validated: validated,
                  participantID: client.id,
                },
                fund: raising.fund.businessName,
                order: raising.number,
                clientID: client.id,
                state: lastParticipation.state,
                dateEntry: createdDate,
                quotes: numFee,
                validated: validated,
                classFund: classFund ? classFund.name : '',
                amount: decimalAdjust('ceil', amount, -2),
                participants: [],
                percentajes: [],
                relatives: [],
                employees: [],
              };
              newParticipation.participants.push(client.user);
              newParticipation.percentajes.push(
                lastParticipation.participationPercentage,
              );
              edgeToList(lastParticipation, 'coParticipants').forEach(
                ({ client: subClient, participationPercentage }) => {
                  newParticipation.participants.push(subClient.user);
                  newParticipation.percentajes.push(participationPercentage);
                },
              );
              relativeList.forEach(item => {
                if (item.documentNumber == client.user.documentNumber) {
                  newParticipation.relatives.push({
                    label: RELATION_TYPE.find(
                      element => element.value == item?.typeRelation,
                    )?.label,
                    name:
                      item.clientSet?.edges[0]?.node?.user?.firstName +
                      ' ' +
                      item.clientSet?.edges[0]?.node?.user?.lastName,
                  });
                }
              });
              employeeList.forEach(item => {
                if (item.user.documentNumber == client.user.documentNumber)
                  newParticipation.employees.push({ isEmployee: true });
              });
              return newParticipation;
            });
          setParticipationList(participationListTmp);
        }
      },
    },
  );

  if (error) {
    console.error('list clients', error);
  }

  const {
    loading: loadindRelatives,
    error: errorRelatives,
    data: listRelatives,
  } = useQuery(LIST_RELATIVES);

  const {
    loading: loadindEmployees,
    error: errorEmployees,
    data: listEmployees,
  } = useQuery(LIST_EMPLOYEES);

  useEffect(() => {
    if (listRelatives)
      setRelativeList(edgeToList(listRelatives, 'listRelatives'));
    if (listEmployees)
      setEmployeeList(edgeToList(listEmployees, 'listEmployees'));
    getParticipants();
  }, [listRelatives, listEmployees]);

  const columnOptions = {
    filter: false,
    sort: true,
  };

  const columns = [
    generateColumn('Fondo', 'fund', {
      customBodyRender: value => <span>{value}</span>,
    }),
    generateColumn('Levantamiento', 'order', {
      customBodyRender: value => <span>{value}</span>,
    }),
    generateColumn('Clase', 'classFund'),
    generateColumn('Nombres y Apellidos', 'participants', {
      ...columnOptions,
      customBodyRender: participants => (
        <div className={classes.multilineCell}>
          {participants.map(
            ({ firstName, lastName, maternalSurname, isVinculated }) =>
              isVinculated ? (
                <span>
                  <b>{`${firstName} ${lastName} ${maternalSurname}`}</b> |{' '}
                </span>
              ) : (
                <span>{`${firstName} ${lastName} ${maternalSurname}`}</span>
              ),
          )}
        </div>
      ),
    }),
    generateColumn('Cuotas', 'quotes'),
    generateColumn('Monto', 'amount', {
      ...columnOptions,
      customBodyRender: amount => (
        <>
          {`${currencySymbol} `}
          <NumberFormat
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator
            value={amount}
          />
        </>
      ),
    }),
    generateColumn('Fecha y Hora', 'dateEntry', {
      ...columnOptions,
      customBodyRender: value => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    }),
    generateColumn('Porcentaje', 'percentajes', {
      ...columnOptions,
      customBodyRender: percentajes => (
        <div className={classes.multilineCell}>
          {percentajes.map((percentaje, i) =>
            i > 0 ? (
              <span> | {percentaje}% |</span>
            ) : (
              <span>{percentaje}%</span>
            ),
          )}
        </div>
      ),
    }),
    generateColumn('Persona Vinculada', 'relatives', {
      ...columnOptions,
      customBodyRender: relatives => (
        <div className={classes.multilineCell}>
          {relatives.map(({ label, name }) =>
            label && name ? <span>{`${label}: ${name}`}</span> : <span>-</span>,
          )}
        </div>
      ),
    }),
    generateColumn('Colaborador', 'employees', {
      ...columnOptions,
      customBodyRender: employees => (
        <div className={classes.multilineCell}>
          {employees.map(({ isEmployee }) =>
            isEmployee ? <span>SI</span> : <span>NO</span>,
          )}
        </div>
      ),
    }),
    generateColumn('Opciones', 'options', {
      ...columnOptions,
      customBodyRender: value => {
        const { id } = value;
        return (
          <CustomOptions
            id={id}
            model="Orden"
            options={['oconfirm']}
            handleConfirmOption={() =>
              approveComplianceClient({ variables: { orderId: id } })
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell
            id={columnMeta.index}
            key={columnMeta.index}
            style={{ borderBottom: 'none' }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    }),
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: true,
    download: false,
    textLabels,
  };

  return loadindRelatives ? (
    <LoadingIndicator />
  ) : (
    <>
      <MUIDataTable
        title="Aprobación Vinculados"
        data={participationList}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default VinculatedList;
