/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Route, Switch, useRouteMatch } from 'react-router-dom';

// Components & Others
import ContracSignSecondaryMarketList from 'src/components/Models/ContracSignSecondaryMarket/List';
import ContracSignSecondaryMarketForm from 'src/components/Models/ContracSignSecondaryMarket';
import NotFound from '../../NotFoundPage';

function ContractSignSecondaryMarketPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ContracSignSecondaryMarketList} />
      <Route path={`${path}/:id`} component={ContracSignSecondaryMarketForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default ContractSignSecondaryMarketPage;
