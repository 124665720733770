import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  pdfContaier: {
    height: '80vh',
    width: '100%',
  },
  pdfContaierDark: {
    height: '80vh',
    width: '100%',
    background: '#2F2F2F',
  },
  loadingContainer: {
    padding: '2%',
    textAlign: 'center',
  },
  error: {
    color: theme.palette.colors.red,
    fontWeight: 400,
  },
}));
