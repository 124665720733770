import React from 'react';

function PartnersIcon(props) {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      fill="none"
      viewBox="0 0 30 41"
    >
      <path
        fill={color}
        d="M26.247 33.226l-3.432 3.002 2.783 3.448 3.432-3.002-2.783-3.448zm.87-9.324c-3.694 3.224-8.795 7.692-12.476 10.907-6.371 5.298-14.07-4.23-7.957-9.861l2.731-2.387-2.884-3.574C-8.302 29.052 5.073 47.926 17.523 38.382 21.2 35.17 26.316 30.696 30 27.475l-2.882-3.573zm-12.88-5.56l3.083-2.693C23.114 10.886 19.934.477 12.585.046 8.039-.466 3.675 3.35 3.375 8.1c-.52 3.665 2.058 6.764 4.242 9.292l9.977 12.363 3.432-3-6.788-8.414v.002zm-5.47-6.78c-2.33-2.686-.02-7.31 3.431-6.868 3.476.207 4.982 5.128 2.24 7.382l-3.081 2.694-2.588-3.209h-.001z"
      />
    </svg>
  );
}

export default PartnersIcon;
