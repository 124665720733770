import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router-dom';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { Grid, Button, Typography, Select, MenuItem } from '@material-ui/core';

import SectionDivider from '../../../FormComponents/SectionDivider';
import { validationSchema } from './formInfo';
import { FILES_UPLOAD, LIST_S3FILES } from '../queries';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import useStyles from './styles';
import {
  REACT_APP_SERVER_LINK,
  NOTIFICATION_DURATION,
} from '../../../../utils/constants';
import LoadingIndicator from '../../../LoadingIndicator';

function ProcessFormMultipleFiles(props) {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [filesNamesList, setFilesNamesList] = useState([]);
  const [base64List, setBase64List] = useState([]);
  const [excelFile, setExcelFile] = useState('');
  const [excelFileName, setExcelFileName] = useState('');
  const [limitGlobal, setLimitGlobal] = useState('');
  const [quantityGlobal, setQuantityGlobal] = useState('');
  const [initialValues, setInitialValues] = useState({
    file: '',
    files_names: '',
    excel: '',
  });

  const [uploadFiles, { loadingFile, dataFile }] = useMutation(FILES_UPLOAD, {
    onCompleted(data) {
      if (data && data?.filesUpload?.success) {
        history.goBack();
        enqueueSnackbar('Los archivos fueron cargados exitosamente', {
          autoHideDuration: NOTIFICATION_DURATION,
          variant: 'success',
        });
      }
      if (data && data?.filesUpload?.error != '') {
        history.goBack();
        enqueueSnackbar(data?.filesUpload?.error, {
          autoHideDuration: NOTIFICATION_DURATION,
          variant: 'error',
        });
      }
    },
    onError: error => {
      history.goBack();
      enqueueSnackbar('Error en la carga de archivos: ' + error, {
        autoHideDuration: NOTIFICATION_DURATION,
        variant: 'error',
      });
    },
    enqueueSnackbar,
    refetchQueries: [{ query: LIST_S3FILES }],
  });

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async (e, propsForm) => {
    try {
      let fileList,
        files,
        base64List = [],
        filenames = [];
      fileList = e.target.files;
      files = [...fileList];
      let limit = 0,
        quantity = 0;
      files.map(file => {
        quantity += 1;
        return (limit += file?.size);
      });
      const base64ListPromise = files.map(async file => {
        const encoded = await toBase64(file);
        base64List.push(encoded);
        filenames.push(file?.name);
      });
      await Promise.all(base64ListPromise);
      setLimitGlobal(limit);
      setQuantityGlobal(quantity);
      setFilesNamesList(filenames);
      setBase64List(base64List);
      propsForm.setFieldValue('file', base64List);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeExcel = async (e, propsForm) => {
    try {
      let name = e.target.files[0].name;
      const encoded = await toBase64(e.target.files[0]);
      propsForm.setFieldValue('excel', encoded);
      setExcelFileName(name);
      setExcelFile(encoded);
    } catch (error) {
      console.log('error', error);
    }
  };

  const setSubmitting = async (e, propsForm) => {
    setLoading(true);
    uploadFiles({
      variables: {
        input: {
          files: base64List,
          filesNames: filesNamesList,
          excel: excelFile,
        },
      },
    });
  };

  return (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {propsForm => (
          <Form className={classes.simpleForm}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  Carga Masiva
                </Typography>
                <SectionDivider />
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      component="label"
                      color="primary"
                      name="excel"
                      error={propsForm.errors.excel}
                      required
                    >
                      Seleccionar Excel
                      <input
                        id="excel"
                        name="excel"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={e => handleChangeExcel(e, propsForm)}
                      />
                    </Button>
                    {excelFileName && (
                      <div>
                        <small>{excelFileName}</small>
                      </div>
                    )}
                    <ErrorMessage name="excel">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      component="label"
                      color="primary"
                      name="file"
                      error={propsForm.errors.file}
                      required
                    >
                      Buscar Archivos
                      <input
                        id="file"
                        name="file"
                        type="file"
                        multiple={true}
                        style={{ display: 'none' }}
                        onChange={e => handleChange(e, propsForm)}
                      />
                    </Button>
                    {filesNamesList && (
                      <div>
                        {filesNamesList.map(item => (
                          <>
                            <small>{item}</small>
                            <br />
                          </>
                        ))}
                      </div>
                    )}
                    {limitGlobal / 1000000 > 100 && (
                      <div>
                        <small style={{ color: 'red' }}>
                          El límite es 100 MG, sumas {limitGlobal / 1000000}MG
                        </small>
                      </div>
                    )}
                    {quantityGlobal > 50 && (
                      <div>
                        <small style={{ color: 'red' }}>
                          La cantidad máxima de archivos es de 50, seleccionaste{' '}
                          {quantityGlobal}
                        </small>
                      </div>
                    )}
                    <ErrorMessage name="file">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                  <SectionDivider />
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <a
                        href={`${REACT_APP_SERVER_LINK}/media/archivos.xlsx`}
                        className={classes.cursorPointer}
                        target="_blank"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={loading}
                        >
                          Descargar plantilla
                        </Button>
                      </a>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={e => {
                          setSubmitting(e, propsForm);
                        }}
                        loading={loading}
                        disabled={
                          loading ||
                          limitGlobal / 1000000 > 100 ||
                          quantityGlobal > 50
                        }
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                  {loading && (
                    <Grid item md={12} xs={12}>
                      <p style={{ color: 'orange', fontWeight: 'bolder' }}>
                        Los archivos están siendo cargados...
                        <LoadingIndicator size="sm" />
                      </p>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProcessFormMultipleFiles;
