//import { get } from 'axios';

import { takeLatest, all, put } from 'redux-saga/effects';
//import api from '../../utils/api';
import { GET_LOGIN_DATA } from './constants';
import { setAuthDataAction } from './actions';

export function* loginSubmit(actions) {
  try {
    yield put(
      setAuthDataAction(actions.password, {
        acces: 'UCD',
        name: actions.user,
        id: 4,
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export default function* loginSaga() {
  yield all([takeLatest(GET_LOGIN_DATA, loginSubmit)]);
}
