// GraphQl
import { gql } from 'apollo-boost';

export const LIST_CURRENCIES = gql`
  {
    listCurrencies {
      edges {
        node {
          id
          name
          symbol
          code
        }
      }
    }
  }
`;

export const GET_CURRENCY = gql`
  query getCurrency($id: ID!) {
    currency(id: $id) {
      id
      name
      symbol
      code
    }
  }
`;

export const CREATE_CURRENCY = gql`
  mutation createCurrency($input: CurrencyInput!) {
    createCurrency(input: $input) {
      currency {
        id
        name
        symbol
        code
      }
    }
  }
`;

export const UPDATE_CURRENCY = gql`
  mutation updateCurrency($id: ID!, $input: CurrencyInput!) {
    updateCurrency(id: $id, input: $input) {
      currency {
        id
        name
        symbol
        code
      }
    }
  }
`;

export const DELETE_CURRENCY = gql`
  mutation deleteCurrency($id: ID!) {
    deleteCurrency(id: $id) {
      ok
      message
    }
  }
`;
