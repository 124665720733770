// GraphQl
import { gql } from 'apollo-boost';

export const LIST_ATTACHMENT_RAISING = gql`
  query getRaisingDocuments($id: ID!) {
    raising(id: $id) {
      raisingdocumentSet {
        edges {
          node {
            id
            file
            date
            state
          }
        }
      }
    }
  }
`;

export const GET_ATTACHMENT_RAISING = gql`
  query getRaisingDocument($id: ID!) {
    raisingDocument(id: $id) {
      id
      file
      date
      state
    }
  }
`;

export const CREATE_ATTACHMENT_RAISING = gql`
  mutation createRaisingDocument($input: RaisingDocumentInput!) {
    createRaisingDocument(input: $input) {
      raisingDocument {
        id
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_ATTACHMENT_RAISING = gql`
  mutation updateRaisingDocument($id: ID!, $input: RaisingDocumentInput!) {
    updateRaisingDocument(id: $id, input: $input) {
      raisingDocument {
        id
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_ATTACHMENT_RAISING = gql`
  mutation deleteRaisingDocument($id: ID!) {
    deleteRaisingDocument(id: $id) {
      raisingDocument {
        id
        file
        date
        state
      }
      errors {
        field
        message
      }
    }
  }
`;
