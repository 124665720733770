import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getClientSelector } from 'src/store/sharedStore/selectors';
import {
  IconButton,
  Tooltip,
  TableCell,
  Typography,
  Button,
  Box,
  Grid,
  Checkbox,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/core/Link';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { useSnackbar } from 'notistack';
// Components & Others
import CustomModal from '../../../../components/CustomModal';
import {
  generateColumn,
  getOptions,
  edgeToList,
  parseSignatureState,
  errorMessage,
} from '../../../../utils/commonFunctions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  CURRENT_USER,
  LIST_SIGNATURES,
  SIGN_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  UPDATE_SIGNATURE,
  LIST_SIGNATURES_BY_CLIENT,
} from '../queries';
import { REACT_APP_SERVER_LINK } from '../../../../utils/constants';
import PDFViewerModal from '../../../PDFViewerModal';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';

// styles and icons
import useStyles from './styles';

function SignatureClientList() {
  const history = useHistory();
  const classes = useStyles();
  const client = useSelector(getClientSelector());
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [loadFrame, setLoadFrame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signingUrl, setSigningUrl] = useState('');
  const [signatureList, setSignatureList] = useState([]);
  const [clientId, setClientId] = useState('');
  const [detail, setDetail] = useState('');
  const [distrito, setDistrito] = useState('');
  const [provincia, setProvincia] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [pais, setPais] = useState('');
  const [signedData, setSignedData] = useState({
    isSigned: false,
    date: '',
  });
  const [userType, setUserType] = useState('');
  const [signedUrl, setSignedUrl] = useState('');
  const [showBio, setShowBio] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [signatureId, setSignatureId] = useState('');
  const [divId, setDivId] = useState('');
  const [keynuaToken, setKeynuaToken] = useState('');
  const [openKeynua, setOpenKeynua] = useState(false);
  const [openContractModal, setOpenContractModal] = useState(false);

  const [remuneraciones, setRemuneraciones] = useState(false);
  const [ahorro, setAhorro] = useState(false);
  const [utilidades, setUtilidades] = useState(false);
  const [alquileres, setAlquileres] = useState(false);
  const [ventaAcciones, setVentaAcciones] = useState(false);
  const [ventaBienesInmuebles, setVentaBienesInmuebles] = useState(false);
  const [ventaBienesMuebles, setVentaBienesMuebles] = useState(false);
  const [herencias, setHerencias] = useState(false);
  const [prestamos, setPrestamos] = useState(false);
  const [otros, setOtros] = useState(false);
  const [otrosTexto, setOtrosTexto] = useState('');
  const [explicacion, setExplicacion] = useState('');
  const [url, setUrl] = useState('');
  const [id, setID] = useState('');

  const {
    loading: currentUserLoading,
    error: currentUserError,
    data: currentUserData,
  } = useQuery(CURRENT_USER);

  const { loading: loadindList, error, data: list, refetch } = useQuery(
    LIST_SIGNATURES_BY_CLIENT,
    {
      variables: { clientId: client?.id },
    },
  );

  useEffect(() => {
    if (!!list && !!currentUserData?.currentUser) {
      setUserType(currentUserData?.currentUser?.userType);
      let signatures = edgeToList(list, 'listSigningDocumentsByClient');
      let clientIdTmp;
      signatures = signatures.sort((a, b) => a.createdAt < b.createdAt);
      const resultSignatures = [];
      const finalSignatures = [];
      signatures.forEach(sign => {
        sign.signingProcess.clients.edges.forEach(client => {
          setDetail(client?.node?.address?.detail);
          setDistrito(client?.node?.address?.district?.name);
          setProvincia(client?.node?.address?.city?.name);
          setDepartamento(client?.node?.address?.county?.name);
          setPais(client?.node?.address?.country?.name);

          clientIdTmp = client.node.id;
          resultSignatures.push(sign);
        });
      });
      resultSignatures
        .filter(s => s.signedPdfStatus != 'SIGNED')
        .forEach(s => {
          finalSignatures.push(s);
        });
      setClientId(clientIdTmp);
      setSignatureList(finalSignatures);
    }
  }, [list, currentUserData]);

  const [downloadDocument] = useMutation(
    DOWNLOAD_DOCUMENT,
    {
      onCompleted(data) {
        setLoading(false);
        setSignedUrl(data.downloadDocument?.signingDocument?.signedPdfSheet);
        refetch();
      },
    },
    getOptions({
      mutationName: 'signDocument',
      modelName: 'signingDocument',
      message: 'Se firmó con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_SIGNATURES_BY_CLIENT, variables: { id: client?.id } },
      ],
    }),
  );

  const [signDocument] = useMutation(
    SIGN_DOCUMENT,
    {
      onCompleted(data) {
        setLoading(false);
        setOpenKeynua(true);
      },
    },
    getOptions({
      mutationName: 'signDocument',
      modelName: 'signingDocument',
      message: 'Se firmó con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: LIST_SIGNATURES_BY_CLIENT, variables: { id: client?.id } },
      ],
    }),
  );

  const loadIframe = () => {
    if (!loadFrame) {
      setLoadFrame(true);
    } else {
      if (signatureId) {
        downloadDocument({
          variables: {
            id: signatureId,
          },
        });
      }
      setLoadFrame(false);
      setShowModal(false);
      refetch();
    }
  };

  const loadKeynuaModal = () => {
    const widgetId = Math.floor(Math.random() * 1000);
    if (divId && divId !== '') {
      const element = document.getElementById(divId);
      if (element) element.remove();
    }
    setDivId(widgetId);
    setTimeout(() => {
      const newKeynuaDiv = document.createElement('div');
      newKeynuaDiv.setAttribute('id', widgetId);
      newKeynuaDiv.innerHTML = '';
      document.getElementById('keynuaDiv').appendChild(newKeynuaDiv);
      window.Keynua.run({
        token: keynuaToken,
        widgetContainerId: widgetId,
        uiCallback: ev => console.log({ ev }),
      });
    }, 0);
  };

  useEffect(() => {
    if (keynuaToken && openKeynua) {
      loadKeynuaModal();
    }
  }, [keynuaToken, openKeynua]);

  const handleSendSignClick = values => {
    setShowBio(false);
    if (signatureId) {
      signDocument({
        variables: {
          id: signatureId,
        },
      });
      refetch();
    }
  };

  const updateMutation = useMutation(
    UPDATE_SIGNATURE,
    {
      onCompleted(data) {
        setLoading(false);
        if (data.updateSignature?.keynuaProcess) {
          setKeynuaToken(data.updateSignature.keynuaProcess.token);
          setOpenKeynua(true);
        } else {
          errorMessage('Proceso de Keynua no existe.', enqueueSnackbar);
        }
      },
    },
    getOptions({
      mutationName: 'updateSignature',
      modelName: 'signingProcess',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [
        { query: LIST_SIGNATURES_BY_CLIENT, variables: { id: client?.id } },
      ],
    }),
  );

  const [updateModel, { loading: updateLoading }] = updateMutation;

  let columns = [];
  let keynuaProcess = [];

  if (signatureList && signatureList.length > 0) {
    signatureList.forEach(item => {
      columns = [
        generateColumn('Fecha', 'createdAt', {
          customBodyRender: value => moment(value).format('DD-MM-YYYY'),
        }),
        generateColumn('Documento', 'pdfSheet', {
          customBodyRender: value => {
            let last_position = parseInt(value.lastIndexOf('/')) + 1;
            return value.slice(last_position);
          },
        }),
        generateColumn('Estado', 'signedPdfStatus', {
          customBodyRender: value => {
            return <p>{parseSignatureState(value)}</p>;
          },
        }),
        {
          name: 'id',
          label: 'Opciones',
          options: {
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const id = value;
              const url = tableMeta.rowData[1]
                ? tableMeta.rowData[1]
                : signedUrl;
              keynuaProcess = edgeToList(
                signatureList.find(s => s.id === id).signingProcess,
                'keynuaprocessSet',
              );
              const token = keynuaProcess ? keynuaProcess[0]?.token : '';
              const isContract = signatureList.find(s => s.id === id)
                .signingProcess.isContract
                ? true
                : false;
              return (
                <>
                  <Tooltip title="Visualizar" placement="top" arrow>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={$ev => {
                        $ev.stopPropagation();
                        setShowModal(true);
                        setUrl(
                          `${REACT_APP_SERVER_LINK}/media/${tableMeta.rowData[1]}`,
                        );
                      }}
                      className={classes.optionButtons}
                    >
                      <PageviewOutlinedIcon className={classes.optionIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Descargar" placement="top" arrow>
                    <Link
                      href={`${REACT_APP_SERVER_LINK}/media/${url}`}
                      target="_blank"
                    >
                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.optionButtons}
                      >
                        <CloudDownloadIcon className={classes.optionIcon} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  {tableMeta.rowData[2] === 'BEFORE_SIGNATURE' ||
                  tableMeta.rowData[2] === 'IN_PROCESS' ? (
                    <Tooltip title="Firmar" placement="top" arrow>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.optionButtons}
                        onClick={() => {
                          setKeynuaToken(token);
                          if (isContract && userType != 'CO') {
                            setID(id);
                            setOpenContractModal(true);
                          } else {
                            const tmp = {
                              remuneraciones: false,
                              ahorro: false,
                              utilidades: false,
                              alquileres: false,
                              ventaAcciones: false,
                              ventaBienesInmuebles: false,
                              ventaBienesMuebles: false,
                              herencias: false,
                              otros: false,
                              otrosTexto: '',
                              explicacion: '',
                            };
                            updateModel({ variables: { id: id, input: tmp } });
                          }
                        }}
                      >
                        <EditOutlinedIcon className={classes.optionIcon} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </>
              );
            },
            customHeadRender: columnMeta => {
              return (
                <TableCell key={7} style={{ borderBottom: 'none' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.tableTitle}
                  >
                    {columnMeta.label}
                  </Typography>
                </TableCell>
              );
            },
          },
        },
      ];
    });
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
  };

  return (
    <>
      <MUIDataTable
        title="Firmas Pendientes"
        data={signatureList}
        columns={columns}
        options={options}
        fullWidth
      />
      {keynuaToken != '' && openKeynua ? (
        <CustomModal
          fullWidth
          maxWidth="lg"
          showModal={openKeynua}
          title="Firma"
          onClosePress={() => {
            setOpenKeynua(false);
          }}
          disableFlex
        >
          <div className={classes.modalContent} justify="center">
            <Box id="keynuaDiv" sx={{ flexGrow: 1 }}></Box>
            <Box display="flex" marginTop="20px" justifyContent="end">
              <Button
                variant="contained"
                onClick={() => loadKeynuaModal()}
                color="secondary"
              >
                Actualizar documento
              </Button>
            </Box>
          </div>
        </CustomModal>
      ) : null}

      {openContractModal ? (
        <CustomModal
          fullWidth
          maxWidth="lg"
          showModal={openContractModal}
          title={`ANEXO 2: DECLARACIÓN JURADA DE LICITUD DE ORIGEN Y DESTINO DE LOS FONDOS`}
          onClosePress={() => {
            setOpenContractModal(false);
          }}
        >
          <div className={classes.modalContent}>
            <p>
              {' '}
              Yo, {currentUserData?.currentUser?.firstName}{' '}
              {currentUserData?.currentUser?.lastName}{' '}
              {currentUserData?.currentUser?.maternalSurname}, con DNI N.°{' '}
              {currentUserData?.currentUser?.username}, con domicilio en{' '}
              {detail}, distrito de {distrito}, provincia de {provincia} y
              departamento de {departamento}, país {pais}, en adelante
              “CLIENTE”, de conformidad con lo dispuesto en la Resolución SBS
              N.º 2660-2015, Reglamento de Gestión de Riesgo de Lavado de
              Activos y del Financiamiento del Terrorismo, así como la
              Resolución CONASEV N° 033-2011-EF-94.01.1, Normas para la
              Prevención del Lavado de Activos y Financiamiento del Terrorismo y
              sus normas modificatorias, en carácter de Declaración Jurada
              manifiesto que los fondos provienen de actividades licitas, tanto
              en origen como en destino, según se declaran a continuación (de
              ser necesario, puede marcar más de una alternativa):
            </p>
            <Grid item xs={12}>
              <Checkbox
                checked={remuneraciones}
                onChange={e => setRemuneraciones(e.target.checked)}
              />
              Remuneraciones
              <br></br>
              <Checkbox
                checked={ahorro}
                onChange={e => setAhorro(e.target.checked)}
              />
              Ahorro en Entidades Financieras
              <br></br>
              <Checkbox
                checked={utilidades}
                onChange={e => setUtilidades(e.target.checked)}
              />
              Utilidades/dividendos
              <br></br>
              <Checkbox
                checked={alquileres}
                onChange={e => setAlquileres(e.target.checked)}
              />
              Alquileres de inmuebles
              <br></br>
              <Checkbox
                checked={ventaAcciones}
                onChange={e => setVentaAcciones(e.target.checked)}
              />
              Venta de Acciones
              <br></br>
              <Checkbox
                checked={ventaBienesInmuebles}
                onChange={e => setVentaBienesInmuebles(e.target.checked)}
              />
              Venta de Bienes Inmuebles
              <br></br>
              <Checkbox
                checked={ventaBienesMuebles}
                onChange={e => setVentaBienesMuebles(e.target.checked)}
              />
              Venta de Bienes Muebles
              <br></br>
              <Checkbox
                checked={herencias}
                onChange={e => setHerencias(e.target.checked)}
              />
              Herencias
              <br></br>
              <Checkbox
                checked={prestamos}
                onChange={e => setPrestamos(e.target.checked)}
              />
              Préstamos
              <br></br>
              <Checkbox
                checked={otros}
                onChange={e => setOtros(e.target.checked)}
              />
              Otros
              <br></br>
              <br></br>
              <TextField
                name="otrosTexto"
                label="Especificar (Otros)"
                variant="outlined"
                fullWidth
                onChange={e => setOtrosTexto(e.target.value)}
              />
              <br></br>
              <br></br>
              <TextField
                name="explicacion"
                label="Breve explicación de la(s) fuente(s)"
                variant="outlined"
                fullWidth
                multiline
                onChange={e => setExplicacion(e.target.value)}
              />
            </Grid>
            <br></br>
            <p>
              Debido a lo anteriormente manifestado, autorizo a Core Capital
              Sociedad Administradora de Fondos de Inversiones S.A., a verificar
              por medios propios o contratados, utilizando la información
              disponible en páginas Web e Intranet, la información aquí
              suministrada y exonero a dicha empresa de las responsabilidades a
              que hubiere lugar en caso de comprobarse la procedencia Ilícita de
              capitales o datos falsos, y así dar cumplimiento a lo establecido
              en las Normas Legales.
            </p>
          </div>
          <div className={classes.modalFooter}>
            <Button
              variant="contained"
              color="secondary"
              disabled={false}
              onClick={() => {
                setOpenContractModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={false}
              onClick={() => {
                const tmp = {
                  remuneraciones: remuneraciones,
                  ahorro: ahorro,
                  utilidades: utilidades,
                  alquileres: alquileres,
                  ventaAcciones: ventaAcciones,
                  ventaBienesInmuebles: ventaBienesInmuebles,
                  ventaBienesMuebles: ventaBienesMuebles,
                  herencias: herencias,
                  otros: otros,
                  otrosTexto: otrosTexto,
                  explicacion: explicacion,
                };
                updateModel({ variables: { id: id, input: tmp } });
                setOpenContractModal(false);
                setOtrosTexto('');
                setExplicacion('');
              }}
            >
              Firmar
            </Button>
          </div>
        </CustomModal>
      ) : null}

      {signingUrl !== '' ? (
        <CustomModal
          fullWidth
          maxWidth="lg"
          showModal={showModal}
          title="Firma"
          onClosePress={() => {
            setShowModal(false);
            setLoadFrame(false);
          }}
          disableFlex
        >
          <div className={classes.modalContent}>
            <iframe
              className={classes.modalIframe}
              id="DocuSignFrame"
              title="DocuSignFrame"
              src={`${signingUrl}`}
              onLoad={loadIframe}
            ></iframe>
          </div>
        </CustomModal>
      ) : null}
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={false}
      />
    </>
  );
}

export default SignatureClientList;
