import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  layout: {
    
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      ' linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

    // 'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 76%, rgba(255, 255, 255, 0.00) 100%)',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },
  layoutDark: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
      'linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.40) 100%)',
    // '#212121',
    // 'linear-gradient(180deg, rgba(255, 255, 255, 0.90) 76%, rgba(255, 255, 255, 0.00) 100%)',
    [theme.breakpoints.down('sm')]: {
      // flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },
}));
