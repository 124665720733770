import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

// Material Resources
import { Button } from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
import PermissionsMenu from './PermissionsMenu';
import CustomModal from '../CustomModal';
import LoadingIndicator from '../LoadingIndicator';
import { getPermissionsSelector } from '../../store/permissionsStore/selector';
import { GET_USER, UPDATE_USER } from './queries';
import { permissionsModules } from './permissionsModules';
import { edgeToList, getOptions } from '../../utils/commonFunctions';

// Styles & Images
import useStyles from './styles';

function PermissionsModal(props) {
  const { showModal, dismiss: parentDismiss, user } = props;
  const permissions = useSelector(getPermissionsSelector());
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(
    JSON.parse(JSON.stringify(permissionsModules)),
  );
  const classes = useStyles();
  const { loading, error, data: userPermissions } = useQuery(GET_USER, {
    variables: { id: user.id },
  });

  const dismiss = () => {
    setData(JSON.parse(JSON.stringify(permissionsModules)));
    parentDismiss();
  };

  const [
    updateUserPermissions,
    { data: updatedUserPermissions, loading: updateUserPermissionsLoading },
  ] = useMutation(
    UPDATE_USER,
    getOptions({
      mutationName: 'updateUser',
      modelName: 'user',
      message: 'Permisos actualizados con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: GET_USER, variables: { id: user.id } }],
      completeCallback: (data, errors) => {
        dismiss();
      },
    }),
  );

  if (loading) return '';
  if (error) return '';
  if (updateUserPermissionsLoading) return '';

  const handleUpdateUserPermissions = () => {
    const formData = [];
    const tmpData = { ...data };
    permissions.forEach(permission => {
      const modules = permission.slug.split('__');
      if (!modules[1]) {
        return;
      }
      if (
        tmpData[modules[0]] &&
        tmpData[modules[0]].subModules[permission.slug]?.enable
      ) {
        formData.push(permission.id);
      }
    });
    updateUserPermissions({
      variables: {
        id: user.id,
        input: { frontendPermissions: formData },
      },
    });
  };

  return (
    <CustomModal
      maxWidth="md"
      showModal={showModal}
      title={`Permisos de ${user.firstName} ${user.lastName}`}
      onClosePress={dismiss}
    >
      <div className={classes.modalBody}>
        {!userPermissions ? (
          <LoadingIndicator />
        ) : (
          <PermissionsMenu
            userPermissions={edgeToList(
              userPermissions.user,
              'frontendPermissions',
            )}
            data={{ ...data }}
            setData={setData}
          />
        )}
      </div>
      <div className={classes.modalFooter}>
        <Button
          variant="contained"
          color="secondary"
          disabled={updateUserPermissionsLoading}
          onClick={dismiss}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={updateUserPermissionsLoading}
          onClick={handleUpdateUserPermissions}
        >
          Guardar
        </Button>
      </div>
    </CustomModal>
  );
}

PermissionsModal.propTypes = {
  dismiss: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
};

export default PermissionsModal;
