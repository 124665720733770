import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  mainDiv: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    paddingLeft: '20px',
    paddingTop: '10px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  searchDiv: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.04)',
    background: 'rgba(166, 193, 211, 0.2)',
    boxShadow: ' 1px 4px 4px 0px rgba(41, 45, 50, 0.05)',
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
  },
  input: {
    height: '25px',
    width: '100%',
    outline: 'none',
    background: 'transparent',
    border: 'none',
    paddingLeft: '10px',
    fontFamily: 'Montserrat',
    color: '#515356',
  },
}));
