import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Container, Hidden } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';

import {
  GET_CLIENT_PROPERTY,
  CREATE_CLIENT_PROPERTY,
  UPDATE_CLIENT_PROPERTY,
  DELETE_CLIENT_PROPERTY,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import useStyles from './styles';
import SimpleForm from '../../../Forms/SimpleForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { FORM_OPTIONS } from '../../constants';

export const TYPES = [
  {
    value: 'H',
    label: 'Bienes Inmuebles',
    enum: 'HOUSE',
  },
  {
    value: 'CA',
    label: 'Vehículos',
    enum: 'CAR',
  },
  {
    value: 'S',
    label: 'Ahorros, depósitos e inversiones financieras',
    enum: 'SAVING',
  },
  {
    value: 'CR',
    label: 'Acreencias y obligaciones',
    enum: 'CREDIT',
  },
  {
    value: 'CO',
    label: 'Empresas relacionadas',
    enum: 'COMPANY',
  },
  {
    value: 'P',
    label: 'Propiedad directa o indirecta',
    enum: 'PORPERTY',
  },
  {
    value: 'O',
    label: 'Otros',
    enum: 'OTHER',
  },
];

export const CONDITION_CHOICES = [
  {
    value: 'OH',
    label: 'Propia',
    enum: 'OWN_HOUSE',
  },
  {
    value: 'MH',
    label: 'Sociedad Conyugal',
    enum: 'MARITAL_HOUSE',
  },
  {
    value: 'PTRH',
    label: 'Convivientes',
    enum: 'PARTNER_HOUSE',
  },
  {
    value: 'COPS',
    label: 'Copropiedad de terceros',
    enum: 'COPARTNERSHIP',
  },
  {
    value: 'O',
    label: 'Otros',
    enum: 'OTHER',
  },
];

function PropertyForm(props) {
  const { propertyData, clientId, closeForm, setCurrentProperty } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isHouse, setIsHouse] = useState(false);
  const [isCar, setIsCar] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCredit, setIsCredit] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [isProperty, setIsProperty] = useState(false);
  const [isOther, setIsOther] = useState(false);

  const [propertyId, setPropertyId] = useState(null);
  const startPropertyData = {
    detail: '',
    licensePlate: '',
    entity: '',
    condition: '',
    registrationNumber: '',
    address: '',
    approximateValue: '',
    participation: '',
    startDate: '',
    endDate: '',
  };

  const createPropertyMutation = useMutation(
    CREATE_CLIENT_PROPERTY,
    getOptions({
      mutationName: 'createProperty',
      modelName: 'property',
      message: 'Se guardó Información Patrimonial con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_PROPERTY, variables: { id: clientId } },
      ],
      completeCallback: data => {
        setPropertyId(data.id);
        setCurrentProperty(data.id);
        closeForm();
      },
    }),
  );

  const updatePropertyMutation = useMutation(
    UPDATE_CLIENT_PROPERTY,
    getOptions({
      mutationName: 'updateProperty',
      modelName: 'property',
      message: 'Se actualizó Información Patrimonial con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_PROPERTY, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const deletePropertyMutation = useMutation(
    DELETE_CLIENT_PROPERTY,
    getOptions({
      mutationName: 'deleteProperty',
      modelName: 'property',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_PROPERTY, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  function handleTypeChange(event) {
    event.preventDefault();
    if (event.target.value == 'H') {
      setIsHouse(true);
      setIsCar(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsProperty(false);
      setIsOther(false);
    } else if (event.target.value == 'CA') {
      setIsCar(true);
      setIsHouse(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsProperty(false);
      setIsOther(false);
    } else if (event.target.value == 'S') {
      setIsSaving(true);
      setIsHouse(false);
      setIsCar(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsProperty(false);
      setIsOther(false);
    } else if (event.target.value == 'CR') {
      setIsCredit(true);
      setIsHouse(false);
      setIsCar(false);
      setIsSaving(false);
      setIsCompany(false);
      setIsProperty(false);
      setIsOther(false);
    } else if (event.target.value == 'O') {
      setIsOther(true);
      setIsHouse(false);
      setIsCar(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsProperty(false);
    } else if (event.target.value == 'CO') {
      setIsCompany(true);
      setIsHouse(false);
      setIsCar(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsProperty(false);
      setIsOther(false);
    } else if (event.target.value == 'P') {
      setIsProperty(true);
      setIsHouse(false);
      setIsCar(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsOther(false);
    } else {
      setIsHouse(false);
      setIsCar(false);
      setIsSaving(false);
      setIsCredit(false);
      setIsCompany(false);
      setIsProperty(false);
      setIsOther(false);
    }
  }

  const relativeValidationSchema = Yup.object().shape({
    propertyType: Yup.string().required(),

    approximateValue: Yup.number().when({
      is: () => isHouse || isCar || isSaving || isCredit || isOther,
      then: Yup.number().required(),
    }),

    detail: Yup.string().when({
      is: () => isCar || isSaving || isCredit || isOther,
      then: Yup.string().required(),
    }),

    conditionChoice: Yup.string().when({
      is: () => isHouse,
      then: Yup.string().required(),
    }),

    address: Yup.string().when({
      is: () => isHouse,
      then: Yup.string().required(),
    }),

    registrationNumber: Yup.string().when({
      is: () => isHouse,
      then: Yup.string().required(),
    }),

    carYear: Yup.string().when({
      is: () => isCar,
      then: Yup.string().required(),
    }),

    licensePlate: Yup.string().when({
      is: () => isCar,
      then: Yup.string().required(),
    }),

    entity: Yup.string().when({
      is: () => isSaving || isCredit,
      then: Yup.string().required(),
    }),

    razonSocial: Yup.string().when({
      is: () => isCompany || isProperty,
      then: Yup.string().required(),
    }),

    ruc: Yup.string().when({
      is: () => isCompany || isProperty,
      then: Yup.string().required(),
    }),

    startDate: Yup.date().when({
      is: () => isCompany,
      then: Yup.date().required(),
    }),

    endDate: Yup.date().when({
      is: () => isCompany,
      then: Yup.date().required(),
    }),

    esDirecta: Yup.string().when({
      is: () => isProperty,
      then: Yup.string().required(),
    }),

    participation: Yup.number().when({
      is: () => isProperty,
      then: Yup.number().required(),
    }),
  });

  const data = [
    {
      isField: true,
      label: '* Tipo',
      name: 'propertyType',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: TYPES,
      mapData: { value: 'value', label: 'label' },
      onChange: handleTypeChange,
    },
    {
      isField: true,
      label: 'Tipo de Bien Inmueble',
      name: 'conditionChoice',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: CONDITION_CHOICES,
      mapData: { value: 'value', label: 'label' },
      disabled:
        isCar || isSaving || isCredit || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Detalle (marca y modelo de carro ó tipo de ahorro/acreencia)',
      name: 'detail',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled: isHouse || isCompany || isProperty,
    },
    {
      isField: true,
      label: 'Valor Aproximado',
      name: 'approximateValue',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.number,
      disabled: isCompany || isProperty,
    },
    {
      isField: true,
      label: 'Dirección',
      name: 'address',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled:
        isCar || isSaving || isCredit || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Nº de Partida/Ficha',
      name: 'registrationNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled:
        isCar || isSaving || isCredit || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Año',
      name: 'carYear',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled:
        isHouse || isSaving || isCredit || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Placa',
      name: 'licensePlate',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled:
        isHouse || isSaving || isCredit || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Entidad / Asumida ante',
      name: 'entity',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
      disabled: isHouse || isCar || isCompany || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Razón Social',
      name: 'razonSocial',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled: isHouse || isCar || isSaving || isCredit || isOther,
    },
    {
      isField: true,
      label: 'RUC',
      name: 'ruc',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled: isHouse || isCar || isSaving || isCredit || isOther,
    },
    {
      isField: true,
      label: 'Fecha de Inicio del Cargo',
      name: 'startDate',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
      disabled:
        isHouse || isCar || isSaving || isCredit || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Fecha de Cese del Cargo',
      name: 'endDate',
      dateT: 'year',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
      disabled:
        isHouse || isCar || isSaving || isCredit || isProperty || isOther,
    },
    {
      isField: true,
      label: 'Participación %',
      name: 'participation',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.number,
      disabled:
        isHouse || isCar || isSaving || isCredit || isCompany || isOther,
    },
    {
      isField: true,
      name: 'esDirecta',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.radio,
      data: FORM_OPTIONS.directaIndirecta,
      disabled:
        isHouse || isCar || isSaving || isCredit || isCompany || isOther,
    },
  ];

  const validatePropertyData = () => {};
  const formatBeforeSubmit = v => {
    const newValues = { ...v };
    if (newValues.approximateValue == '') newValues.approximateValue = null;
    if (newValues.participation == '') newValues.participation = null;
    if (newValues.startDate == '') newValues.startDate = null;
    if (newValues.endDate == '') newValues.endDate = null;
    if (newValues.esDirecta == 'true') newValues.esDirecta = true;
    else newValues.esDirecta = false;
    return { ...newValues, client: clientId };
  };

  useEffect(() => {
    if (propertyData) {
      setPropertyId(propertyData?.id);
      if (propertyData?.propertyType) {
        propertyData.propertyType = propertyData?.propertyType;
      }
      if (propertyData?.conditionChoice) {
        propertyData.conditionChoice = propertyData?.conditionChoice;
      }
      if (propertyData?.esDirecta) {
        propertyData.esDirecta = 'true';
      } else propertyData.esDirecta = 'false';

      if (propertyData?.approximateValue) {
        propertyData.approximateValue = propertyData.approximateValue;
      } else propertyData.approximateValue = 0;

      if (propertyData?.participation) {
        propertyData.participation = propertyData.participation;
      } else propertyData.participation = 0;

      if (propertyData?.startDate) {
        propertyData.startDate = propertyData.startDate;
      } else propertyData.startDate = '2000-01-01';

      if (propertyData?.endDate) {
        propertyData.endDate = propertyData.endDate;
      } else propertyData.endDate = '2000-01-01';

      if (propertyData?.propertyType == 'H') {
        setIsHouse(true);
        setIsCar(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsProperty(false);
        setIsOther(false);
      } else if (propertyData?.propertyType == 'CA') {
        setIsCar(true);
        setIsHouse(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsProperty(false);
        setIsOther(false);
      } else if (propertyData?.propertyType == 'S') {
        setIsSaving(true);
        setIsHouse(false);
        setIsCar(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsProperty(false);
        setIsOther(false);
      } else if (propertyData?.propertyType == 'CR') {
        setIsCredit(true);
        setIsHouse(false);
        setIsCar(false);
        setIsSaving(false);
        setIsCompany(false);
        setIsProperty(false);
        setIsOther(false);
      } else if (propertyData?.propertyType == 'O') {
        setIsOther(true);
        setIsHouse(false);
        setIsCar(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsProperty(false);
      } else if (propertyData?.propertyType == 'CO') {
        setIsCompany(true);
        setIsHouse(false);
        setIsCar(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsProperty(false);
        setIsOther(false);
      } else if (propertyData?.propertyType == 'P') {
        setIsProperty(true);
        setIsHouse(false);
        setIsCar(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsOther(false);
      } else {
        setIsHouse(false);
        setIsCar(false);
        setIsSaving(false);
        setIsCredit(false);
        setIsCompany(false);
        setIsProperty(false);
        setIsOther(false);
      }
    }
  }, [propertyData]);

  return (
    <Container>
      <div className={classes.formContainer}>
        <SimpleForm
          model={propertyData}
          initialValues={startPropertyData}
          validateFunction={validatePropertyData}
          validateSchema={relativeValidationSchema}
          data={data}
          modelName="Información Patrimonial"
          hasCancel
          isEdit={!!propertyId}
          id={propertyId}
          routeName=""
          onCancel={closeForm}
          updateMutation={updatePropertyMutation}
          createMutation={createPropertyMutation}
          deleteMutation={deletePropertyMutation}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      </div>
    </Container>
  );
}

PropertyForm.propTypes = {
  propertyData: PropTypes.shape().isRequired,
  clientId: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setCurrentProperty: PropTypes.func.isRequired,
};

export default PropertyForm;
