import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import FundClassForm from '../FunClassForm';
import { GET_CLASS_FUND } from '../queries';
import { parseEquivalent } from '../../../../utils/commonFunctions';

function FundClassEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { loading, error, data } = useQuery(GET_CLASS_FUND, {
    variables: { id },
  });
  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('get classes', error);
    history.goBack();
  }

  const formatValue = { ...data.classFund };
  formatValue.id = data.classFund.id;
  formatValue.name = data.classFund.name;
  formatValue.geographicalFocus = parseEquivalent(
    data.classFund.geographicalFocus,
  );
  formatValue.assetClass = parseEquivalent(data.classFund.assetClass);
  if (!data.classFund) {
    history.goBack();
  }

  return <FundClassForm clase={formatValue} id={id} isEdit />;
}

FundClassEdit.propTypes = {};

export default FundClassEdit;
