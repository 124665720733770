import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  btn: {
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    background: '#fff',
    border: 'none',
    padding: '10px',
    color: '#686565',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    },
  },
  container: {
    position: 'absolute',
    top: '25%',
    left: '6%',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '10px',
    background: '#FFF',
    boxShadow: '1px 2px 4px 0px rgba(0, 0, 0, 0.15)',
    padding: '10px',
  },
  main: {
    position: 'relative',
    zIndex: 999,
  },
  main1: {
    position: 'relative',
    width: '100%',
    zIndex: 9999,
    height: 'fit-content',
  },
}));
