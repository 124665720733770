import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  fundSelect: {
    minWidth: 200,
  },
  modalContainer: {
    padding: '2rem 0',
  },
}));
