import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import BankAccountForm from '../BankAccountForm';
import { parseBool } from '../../../../utils/commonFunctions';
import { GET_BANK_ACCOUNT } from '../../../Models/BankAccount/queries';

function InnerBankAccountEdit(props) {
  const { id, fundID, afterEditCreate } = props;

  const { loading, error, data } = useQuery(GET_BANK_ACCOUNT, {
    variables: { id },
  });

  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('Get inner bank account error: ', error);
  }

  if (!data.bankAccount) {
    return <LoadingIndicator />;
  }

  const formatValue = { ...data.bankAccount };
  formatValue.bank = formatValue.bank.id;
  formatValue.currency = formatValue.currency.id;
  formatValue.accountType = formatValue.accountType.id;
  formatValue.country = formatValue.country.id;
  formatValue.jointAccount = parseBool(formatValue.jointAccount);

  return (
    <BankAccountForm
      bankAccount={formatValue}
      id={id}
      fundID={fundID}
      isEdit
      afterEditCreate={afterEditCreate}
    />
  );
}

InnerBankAccountEdit.propTypes = {
  id: PropTypes.string.isRequired,
  afterEditCreate: PropTypes.func.isRequired,
};

export default InnerBankAccountEdit;
