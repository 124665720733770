/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import FundSourceList from '../../../components/Models/FundSource/FundSourceList';
import FundSourceEdit from '../../../components/Models/FundSource/FundSourceCreateEdit/edit';
import FundSourceForm from '../../../components/Models/FundSource/FundSourceForm';

function FundSourcePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={FundSourceList} />
      <Route path={`${path}/crear`} component={FundSourceForm} />
      <Route path={`${path}/:id`} component={FundSourceEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default FundSourcePage;
