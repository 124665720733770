import { gql } from 'apollo-boost';

export const FILE_UPLOAD = gql`
  mutation fileUpload($input: S3FileInput!) {
    fileUploadEmployee(input: $input) {
      success
    }
  }
`;

export const FILES_UPLOAD = gql`
  mutation filesUpload($input: S3FilesInput!) {
    filesUploadEmployee(input: $input) {
      success
      error
    }
  }
`;

export const FILE_DOWNLOAD = gql`
  mutation fileDownload($id: ID!, $isDownload: Boolean!) {
    fileDownload(id: $id, isDownload: $isDownload) {
      url
      fileName
    }
  }
`;

export const FILE_UPDATE = gql`
  mutation fileUpdate($id: ID!) {
    fileUpdateEmployee(id: $id) {
      s3file {
        id
        state
      }
    }
  }
`;

export const LIST_TYPES_CHOICES = gql`
  query listTypesChoices {
    typesChoicesEmployee {
      name
      value
    }
  }
`;

export const LIST_CLASSIFICATION_CHOICES = gql`
  query listClassificationChoices {
    classificationChoices {
      name
      value
    }
  }
`;

export const LIST_S3FILES = gql`
  query listEmployeeS3Files {
    listEmployeeS3Files {
      edges {
        node {
          id
          state
          isGeneric
          classification
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_S3FILES_PAGINATED = gql`
  query listEmployeeS3Files(
    $search: String
    $classification: String
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
  ) {
    listEmployeeS3FilesPaginated(
      search: $search
      classification: $classification
      first: $first
      last: $last
      before: $beforeCursor
      after: $afterCursor
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          state
          isGeneric
          classification
          client {
            id
            razonSocial
            user {
              id
              firstName
              lastName
            }
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_S3FILES_BYCLIENT = gql`
  query listS3FilesByClient($clientId: ID!) {
    listS3FilesByEmployee(clientId: $clientId) {
      edges {
        node {
          id
          state
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;
