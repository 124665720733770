import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
// Material Resources
import {
  Grid,
  IconButton,
  Tooltip,
  TableCell,
  AppBar,
  Typography,
  Toolbar,
  Box,
  Button,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/core/Link';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

import MUIDataTable from 'mui-datatables';
import {
  generateColumn,
  getOptions,
  edgeToList,
  parseSignatureState,
} from '../../utils/commonFunctions';
import { REACT_APP_SERVER_LINK } from '../../utils/constants';
import CustomModal from '../../components/CustomModal';
import { textLabels } from '../../translations/components/MUIDataTable';
import NotFound from '../NotFoundPage';

import {
  CURRENT_USER,
  LIST_SIGNATURES,
  SIGN_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  LIST_EXTERNAL_SIGNERS,
} from '../../components/Models/Signatures/queries';

// Styles & Images
import useStyles from './styles';
import CorePartners from '../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';

function ExternalPage() {
  const { documentId } = useParams();
  const { signerId } = useParams();
  const { token } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showNotFound, setShowNotFound] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loadFrame, setLoadFrame] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signingUrl, setSigningUrl] = useState('');
  const [signatureList, setSignatureList] = useState([]);
  const [signedUrl, setSignedUrl] = useState('');
  const [showSign, setShowSign] = useState(false);
  const [signatureId, setSignatureId] = useState('');
  const [signatureDocument, setSignatureDocument] = useState([]);
  const [divId, setDivId] = useState('');
  const [keynuaToken, setKeynuaToken] = useState('');
  const [openKeynua, setOpenKeynua] = useState(false);

  const { loading: loadindList, error, data: list, refetch } = useQuery(
    LIST_SIGNATURES,
  );

  const {
    loading: loadindListES,
    errorES,
    data: listExternalSigners,
  } = useQuery(LIST_EXTERNAL_SIGNERS);

  useEffect(() => {
    if (!!listExternalSigners && !!list) {
      let externalSigners = edgeToList(
        listExternalSigners,
        'listExternalSigners',
      );
      const exists = externalSigners.find(ex => ex.token === token);
      if (exists) setShowNotFound(false);

      let signatures = edgeToList(list, 'listSigningDocuments');
      const signingDocument = signatures.filter(s => s.id === documentId);
      setSignatureDocument(signingDocument);
    }
  }, [listExternalSigners, list]);

  const [downloadDocument] = useMutation(
    DOWNLOAD_DOCUMENT,
    {
      onCompleted(data) {
        setLoading(false);
        setSignedUrl(data?.downloadDocument?.signingDocument?.signedPdfSheet);
        refetch();
      },
    },
    getOptions({
      mutationName: 'signDocument',
      modelName: 'signingDocument',
      message: 'Se firmó con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  const loadIframe = () => {
    if (!loadFrame) {
      setLoadFrame(true);
    } else {
      if (signatureId) {
        downloadDocument({
          variables: {
            id: signatureId,
          },
        });
      }
      setLoadFrame(false);
      setShowModal(false);
      refetch();
    }
  };

  const handleRowEditClick = value => {
    setSignatureId(documentId);
    setSignedUrl(signatureDocument[0].signedPdfSheet);
    signDocument({
      variables: {
        id: documentId,
      },
    });
  };

  const [signDocument] = useMutation(
    SIGN_DOCUMENT,
    {
      onCompleted(data) {
        setLoading(false);
        let index;
        data.signDocument.signingDocument.dsUrls.map((item, i) => {
          if (item === signerId) index = i;
        });
        setSigningUrl(
          JSON.parse(
            data.signDocument.signingDocument.dsUrls[index + 1].replaceAll(
              "'",
              '"',
            ),
          ).url,
        );
        setShowModal(true);
      },
    },
    getOptions({
      mutationName: 'signDocument',
      modelName: 'signingDocument',
      message: 'Se firmó con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  const loadKeynuaModal = () => {
    const widgetId = Math.floor(Math.random() * 1000);
    if (divId && divId !== '') {
      const element = document.getElementById(divId);
      if (element) element.remove();
    }
    setDivId(widgetId);
    setTimeout(() => {
      const newKeynuaDiv = document.createElement('div');
      newKeynuaDiv.setAttribute('id', widgetId);
      newKeynuaDiv.innerHTML = '';
      document.getElementById('keynuaDiv').appendChild(newKeynuaDiv);
      window.Keynua.run({
        token: keynuaToken,
        widgetContainerId: widgetId,
        uiCallback: ev => console.log({ ev }),
      });
    }, 0);
  };

  useEffect(() => {
    if (keynuaToken && openKeynua) {
      loadKeynuaModal();
    }
  }, [keynuaToken, openKeynua]);

  let keynuaProcess = [];
  let columns = [
    generateColumn('Fecha', 'createdAt', {
      customBodyRender: value => moment(value).format('DD-MM-YYYY'),
    }),
    generateColumn('Documento', 'pdfSheet', {
      customBodyRender: value => {
        let last_position = parseInt(value.lastIndexOf('/')) + 1;
        return value.slice(last_position);
      },
    }),
    generateColumn('Estado', 'signedPdfStatus', {
      customBodyRender: value => {
        return <p>{parseSignatureState(value)}</p>;
      },
    }),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = value;
          const url = tableMeta.rowData[1] ? tableMeta.rowData[1] : signedUrl;
          keynuaProcess = edgeToList(
            signatureDocument[0].signingProcess,
            'keynuaprocessSet',
          );
          const token = keynuaProcess[0].token;
          return (
            <>
              {tableMeta.rowData[1] && token ? (
                <Tooltip title="Firmar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {
                      setKeynuaToken(token);
                      setOpenKeynua(true);
                    }}
                  >
                    <EditOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </>
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={7} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: false,
    search: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    pagination: false,
    textLabels,
  };

  return showNotFound ? (
    <NotFound />
  ) : (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appToolbar}>
          <div className={classes.toolbarItem}>
            <img src={CorePartners} alt="logo" className={classes.logo} />
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item style={{ width: '70%' }}>
            <MUIDataTable
              title="Documento a firmar"
              data={signatureDocument}
              columns={columns}
              options={options}
            />
          </Grid>
          {keynuaToken != '' && openKeynua ? (
            <CustomModal
              fullWidth
              maxWidth="lg"
              showModal={openKeynua}
              title="Firma"
              onClosePress={() => {
                setOpenKeynua(false);
              }}
              disableFlex
            >
              <div className={classes.modalContent} justify="center">
                <Box id="keynuaDiv" sx={{ flexGrow: 1 }}></Box>
                <Box display="flex" marginTop="20px" justifyContent="end">
                  <Button
                    variant="contained"
                    onClick={() => loadKeynuaModal()}
                    color="secondary"
                  >
                    Actualizar documento
                  </Button>
                </Box>
              </div>
            </CustomModal>
          ) : null}
          {signingUrl !== '' ? (
            <CustomModal
              fullWidth
              maxWidth="lg"
              showModal={showModal}
              title="Firma"
              onClosePress={() => {
                setShowModal(false);
                setLoadFrame(false);
              }}
              disableFlex
            >
              <div>
                <iframe
                  className={classes.modalIframe}
                  id="DocuSignFrame"
                  title="DocuSignFrame"
                  src={`${signingUrl}`}
                  onLoad={loadIframe}
                ></iframe>
              </div>
            </CustomModal>
          ) : null}
        </Grid>
      </div>
    </>
  );
}

export default ExternalPage;
