import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Others
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
// Styles & Images
import useStyles from './styles';

function CustomMultiSelectSearch(props) {
  const {
    id,
    disabled,
    name,
    label,
    data,
    mapData,
    onChange,
    setFieldValue,
    error,
    isStepForm,
    defaultValues,
    formValues,
  } = props;
  const [showError, setShowError] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 16;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (error !== '') {
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (!disabled && defaultValues?.length > 0) {
      setFieldValue('classes', defaultValues);
    }
  }, [defaultValues, disabled]);

  const dataParsed = data?.filter(item => item.state === true);
  const parserClasses = formValues?.classes;

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={error !== '' && showError}
      disabled={disabled}
    >
      <Autocomplete
        disabled={disabled}
        id={id}
        fullWidth
        value={Array.isArray(parserClasses) ? formValues?.classes : []}
        multiple
        onChange={(e, value) => {
          onChange(setFieldValue, {
            target: {
              value: value,
            },
          });
        }}
        options={dataParsed}
        getOptionLabel={option => (option.name ? option.name : '')}
        renderInput={params => (
          <TextField
            value={Array.isArray(parserClasses) ? formValues?.classes : []}
            fullWidth
            {...params}
            variant="outlined"
            label={label}
          />
        )}
      />
      {error !== '' && showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
CustomMultiSelectSearch.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isStepForm: PropTypes.bool,
  error: PropTypes.string,
};
CustomMultiSelectSearch.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  onChange: () => null,
  disabled: false,
  error: '',
  isStepForm: false,
};

export default CustomMultiSelectSearch;
