/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';
// Material Resources
import { Menu, MenuItem } from '@material-ui/core';
// Components & Others
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { delAuthUser } from 'src/utils/storage';
import { removeClient } from 'src/store/sharedStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// Styles & Images
import useStyles from './styles';
import ArrowDwnIcon from './svg/arrowDwn';
import NotificationsIcon from './svg/notificationsIcon';
import Avatar from './avatar';
import PopUpNotificationsDashboard from './notifications/pop-up-notification';
import InvestorNotifications from './notifications';
import getClientMode from 'src/store/utils-store/selectors';
import EventsCard from '../cards/news/type-cards/event';
import ModeIcon from '../cards/svg/modeIcon';
import { setModeClient } from 'src/store/utils-store/actions';

function InvestorProfile() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const modeClient = useSelector(getClientMode());
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleCloseUser = () => {
    setAnchorEl(null);
  };
  const handleProfileUser = () => {
    history.push('/cliente/perfil');
    handleCloseUser();
  };
  const handleLogOut = () => {
    delAuthUser();
    dispatch(removeClient());
    localStorage.clear();
    window.location.href = '/';
  };
  const handleClickUser = event => {
    setAnchorEl(event.currentTarget);
  };

  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [clients]);

  const welcomeStyle = modeClient ? classes.welcomeDark : classes.welcome;
  const nameStyle = modeClient ? classes.nameDark : classes.name;
  const [mode, setMode] = useState(false);
  const handlerMode = state => {
    setMode(state);
    localStorage.setItem('mode', state);
    dispatch(setModeClient(state));
  };
  return (
    <>
      <div
        className={classes.layout}
        style={{ position: 'relative', height: 'fit-content' }}
      >
        <div className={classes.mainDiv}>
          {windowWidth >= 980 && (
            <div className={classes.modeDiv}>
              <div className={classes.modeIcon}>
                <div
                  role="button"
                  tabIndex={0}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handlerMode(!mode)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handlerMode(!mode);
                    }
                  }}
                >
                  <ModeIcon />
                </div>
              </div>
            </div>
          )}
          <div className={classes.profileDiv}>
            <Avatar />
            <div className={classes.welcomeDiv}>
              <div style={{ display: 'flex', gap: '4px' }}>
                <p className={welcomeStyle}>Hola,</p>
                <p className={nameStyle}>
                  {clients && `${clients.currentUser.firstName}`}
                </p>
              </div>
              <div role="button" tabIndex={0} onClick={handleClickUser}>
                <ArrowDwnIcon bg={modeClient ? '#A6C1D3' : '#000'} />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseUser}
              >
                <MenuItem
                  onClick={handleProfileUser}
                  id="cambio_contrasena_menu"
                >
                  Cambio de contraseña
                </MenuItem>
                <MenuItem onClick={handleLogOut} id="salir_menu">
                  Salir
                </MenuItem>
              </Menu>

              {windowWidth <= 980 && (
                <div onClick={() => setOpen(!open)}>
                  <NotificationsIcon bg={modeClient ? '#A6C1D3' : '#000'} />
                  {open && <PopUpNotificationsDashboard />}
                </div>
              )}
            </div>
          </div>
          {windowWidth > 980 && (
            <>
              <div className={classes.profileContent}>
                <InvestorNotifications />
                <EventsCard />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default InvestorProfile;
