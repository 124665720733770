import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(5),
  },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  menuTitle: {
    '& .MuiBox-root': {
      whiteSpace: 'normal',
    },
  },
  menuTitleOpen: {
    '& .MuiBox-root': {
      whiteSpace: 'normal',
      fontWeight: 600,
    },
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
