/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import VinculatedList from '../../../components/Models/Vinculated/VinculatedList';

function VinculatedPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={VinculatedList} />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

VinculatedPage.propTypes = {};

export default VinculatedPage;
