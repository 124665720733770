import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CustomOptions from '../../../CustomOptions';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  LIST_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
} from '../queries';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { generateColumn, getOptions } from '../../../../utils/commonFunctions';

// Styles & Images
import useStyles from './styles';

function CustomToolbar(props) {
  const history = useHistory();
  const handleClick = () => {
    history.push('/administrador/cuentas-bancarias/crear', {
      precedence: props.precedence,
    });
  };

  return (
    <Tooltip title="Nueva cuenta bancaria">
      <IconButton color="secondary" onClick={handleClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}

function BankAccountEdgeToList(data, listName) {
  if (data) {
    return data[listName].edges.map(element => {
      const formatElement = { ...element.node };
      formatElement.bank = formatElement.bank
        ? formatElement.bank.name
        : formatElement.otherBank;
      formatElement.currency = formatElement.currency.name;
      formatElement.accountType = formatElement.accountType.name;
      formatElement.options = {
        id: formatElement.id,
        enabled: formatElement.isActive,
      };
      return formatElement;
    });
  }
  return [];
}

function BankAccountList(props) {
  const { filterList, precedence } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [accountsList, setAccountsList] = useState([]);

  const { loading, error, data } = useQuery(LIST_BANK_ACCOUNTS);

  const [updateBankAccountClient] = useMutation(
    UPDATE_BANK_ACCOUNT,
    getOptions({
      mutationName: 'updateBankAccount',
      modelName: 'bankAccount',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_BANK_ACCOUNTS }],
    })
  );

  useEffect(() => {
    let list = [];
    if (data) {
      if (filterList) {
        list = BankAccountEdgeToList(data, 'listBankAccount').filter(v =>
          filterList.includes(v.id)
        );
      } else {
        list = BankAccountEdgeToList(data, 'listBankAccount');
      }
      setAccountsList(list);
    }
  }, [data, filterList]);

  if (error) {
    console.error('list bank accounts', error);
  }

  function handleEnabled(e, id, enabled) {
    updateBankAccountClient({
      variables: {
        id,
        input: {
          isActive: !enabled,
        },
      },
    });
  }

  const columns = [
    generateColumn('Banco', 'bank'),
    generateColumn('Moneda', 'currency'),
    generateColumn('Tipo de cuenta', 'accountType'),
    generateColumn('Número de cuenta', 'accountNumber'),
    generateColumn('Código de cuenta interbancario', 'cci'),
    {
      name: 'jointAccount',
      label: '¿Es una cuenta mancomunada?',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={5}
              key={5}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          const jointAccount = value;
          return <p>{jointAccount ? 'Si' : 'No'} </p>;
        },
      },
    },
  ];
  columns.push({
    name: 'options',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const { id, enabled } = value;
        return (
          <CustomOptions
            id={id}
            key={id}
            model="Cuenta de Banco"
            options={['cedit', 'oenable']}
            extraData={{ oenable: enabled }}
            extraFunctions={{ oenable: e => handleEnabled(e, id, enabled) }}
            customOption={
              <span>
                <Tooltip
                  title={enabled ? 'Activar cuenta' : 'Descativar cuenta'}
                  placement="top"
                  arrow
                >
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.optionButtons}
                    onClick={() => {}}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              </span>
            }
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell
            id={columnMeta.index}
            key={columnMeta.index}
            style={{ borderBottom: 'none' }}
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `/administrador/cuentas-bancarias/${
          rowData[rowData.length - 1].props.id
        }`
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return <CustomToolbar precedence={precedence} />;
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };
  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <MUIDataTable
          title="Cuentas bancarias"
          data={accountsList}
          columns={columns}
          options={options}
        />
      )}
    </>
  );
}

export default BankAccountList;
