import React, { useState, useEffect } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// GraphQl
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

// Others
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import CustomSelect from '../CustomSelect';
import { edgeToList, errorMessage } from '../../../utils/commonFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import { SEARCH_PERU } from '../CountrySelect';
import { LIST_COUNTIES, reorderCounty } from '../PlacesSelects';

function PeruCountySelect(props) {
  const { id, name, label, error: formError } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { error: peruError, data: peruData, loading: peruLoading } = useQuery(
    SEARCH_PERU
  );

  const [
    getCounties,
    { data: countyData, loading: countyLoading },
  ] = useLazyQuery(LIST_COUNTIES, {
    onError: error => {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
  });

  if (peruError) {
    errorMessage(
      'Ocurrió un error al obtener la información del servidor',
      enqueueSnackbar
    );
  }

  useEffect(() => {
    if (peruData && peruData.listCountries) {
      getCounties({
        variables: { country: peruData.listCountries.edges[0].node.id },
      });
    }
  }, [peruData]);

  return (
    <>
      {!countyData || countyLoading || peruLoading ? (
        <LoadingIndicator size="sm" />
      ) : (
        <CustomSelect
          id={id}
          name={name}
          label={label}
          data={reorderCounty(edgeToList(countyData, 'listCounties'))}
          mapData={{ value: 'id', label: 'name' }}
          error={formError}
        />
      )}
    </>
  );
}
PeruCountySelect.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

PeruCountySelect.defaultProps = {
  error: '',
};

export default PeruCountySelect;
