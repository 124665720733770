import React from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';

// Styles & Images
import NumberFormatInput from '../NumberFormatInput';

function ServiceTimeInputs(props) {
  const { name, nameMonth, label, labelMonth, gridMD } = props;

  return (
    <>
      <Grid item md={gridMD[0]} xs={12}>
        <NumberFormatInput
          name={name}
          label={label}
          id={name}
          variant="outlined"
          fullWidth
          formatprops={{ decimalScale: 0, allowNegative: false }}
        />
      </Grid>
      <Grid item md={gridMD[1]} xs={12}>
        <NumberFormatInput
          label={labelMonth}
          name={nameMonth}
          id={nameMonth}
          variant="outlined"
          fullWidth
          formatprops={{ decimalScale: 0, allowNegative: false }}
        />
      </Grid>
    </>
  );
}
ServiceTimeInputs.propTypes = {
  name: PropTypes.string.isRequired,
  nameMonth: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelMonth: PropTypes.string.isRequired,
  gridMD: PropTypes.arrayOf(PropTypes.number),
};
ServiceTimeInputs.defaultProps = {
  gridMD: [3, 3],
};

export default ServiceTimeInputs;
