// GraphQl
import { gql } from 'apollo-boost';

const UPDATE_TREE_STATUS = gql`
  mutation updateClient($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        treeStatus
        missingSteps {
          edges {
            node {
              id
              step
            }
          }
        }
      }
    }
  }
`;

export default UPDATE_TREE_STATUS;
