import React from 'react'

export default function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#F4FBFF"
        d="M15.23 10.757l-3.77-1.69-.01-.004a1.28 1.28 0 00-1.273.157L8.23 10.88c-1.234-.6-2.508-1.863-3.107-3.08l1.663-1.978a1.28 1.28 0 00.151-1.266v-.01L5.243.771a1.28 1.28 0 00-1.33-.762A4.5 4.5 0 000 4.48C0 10.833 5.168 16 11.52 16a4.5 4.5 0 004.47-3.914 1.28 1.28 0 00-.76-1.33zm-3.71 3.963A10.251 10.251 0 011.28 4.48a3.216 3.216 0 012.79-3.2v.01l1.68 3.76-1.654 1.979a1.28 1.28 0 00-.126 1.318c.725 1.482 2.219 2.964 3.717 3.688a1.28 1.28 0 001.32-.136l1.944-1.66 3.76 1.685h.01a3.217 3.217 0 01-3.2 2.796z"
      ></path>
    </svg>
  );
}
