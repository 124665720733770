import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  editMenu: {
    paddingBottom: '2%',
    paddingTop: '2%',
  },
  users: {
    display: 'flex',
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    fontSize: 16,
    fontWeight: 600,
  },
  userLeftSide: {
    border: `4px solid ${theme.palette.secondary.main}`,
    borderBottom: 'none',
    borderRight: `2px solid ${theme.palette.secondary.main}`,
  },
  userRightSide: {
    border: `4px solid ${theme.palette.secondary.main}`,
    borderBottom: 'none',
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
  },
  usersCenter: {
    borderTop: `4px solid ${theme.palette.secondary.main}`,
    borderLeft: `2px solid ${theme.palette.secondary.main}`,
    borderRight: `2px solid ${theme.palette.secondary.main}`,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.primary.main,
    padding: 20,
    fontSize: 20,
    fontWeight: 600,
  },
  leftTitle: {
    border: `4px solid ${theme.palette.secondary.main}`,
  },
  rightTitle: {
    border: `4px solid ${theme.palette.secondary.main}`,
    borderLeft: 'none',
  },
  leftPanel: {
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
    borderRight: `4px solid ${theme.palette.secondary.main}`,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  rightPanel: {
    borderRight: `4px solid ${theme.palette.secondary.main}`,
    borderBottom: `4px solid ${theme.palette.secondary.main}`,
  },
  content: {
    display: 'flex',
    padding: 20,
    fontSize: 16,
    backgroundColor: theme.palette.colors.lightGray,
  },

  // List
  listButton: {
    padding: 0,
  },
  main: {
    '& .MuiListItem-dense': {
      padding: 0,
    },
    paddingTop: 0,
  },
  mainText: {
    '& .MuiListItemText-primary': {
      fontSize: 16,
    },
  },
  nested: {
    '& .MuiListItem-dense': {
      padding: 0,
    },
    paddingTop: 0,
    paddingLeft: theme.spacing(8),
  },
  nestedText: {
    '& .MuiListItemText-primary': {
      fontSize: 14,
    },
  },
}));
