import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  divider: {
    width: '100%',
    height: 2,
    margin: '10px 0',
  },
  listbox: {
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
  root: {
    '& .MuiInputBase-root': {
      padding: 4,
      paddingLeft: 9,
    },
  },
}));
