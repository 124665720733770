export const fundTypes = [
  { value: 'PRIVATE', label: 'Oferta Privada' },
  { value: 'PUBLIC', label: 'Oferta Pública' },
];

export const incomeTypes = [
  { value: 'VARIABLE', label: 'Renta Variable' },
  { value: 'FIXED', label: 'Renta Fija' },
];

export const tirCalculusTypes = [
  { value: 'FUTURE_FLOW', label: 'Flujo Futuro' },
  { value: 'QUOTE_VALUE', label: 'Valor Cuota' },
  { value: 'FIXED_RATE', label: 'Tasa Fija' },
];

export const geographicalFocusTypes = [
  { value: 'LOCAL', label: 'Local' },
  { value: 'INTERNATIONAL', label: 'Internacional' },
];

export const assetClassTypes = [
  { value: 'REAL_STATE', label: 'Real State' },
  { value: 'PRIVATE_DEBT', label: 'Private Debt' },
  { value: 'VENTURE_CAPITAL', label: 'Venture Capital' },
  { value: 'FUND_OF_FUNDS', label: 'Fund of Funds' },
  { value: 'HEDGE_FUNDS', label: 'Hedge Funds' },
];

export const selectYesNo = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
