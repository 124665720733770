import React, { Fragment } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const SIZES = {
  xs: 20,
  sm: 30,
  md: 50,
  lg: 60,
  xl: 70,
};

const LoadingIndicator = props => {
  const { size, customSize } = props;

  return (
    <CircularProgress
      style={{ display: 'block', margin: 'auto' }}
      size={customSize ? customSize : SIZES[size]}
    />
  );
};

LoadingIndicator.propTypes = {
  size: PropTypes.string,
};
LoadingIndicator.defaultProps = {
  size: 'md',
};

export default LoadingIndicator;
