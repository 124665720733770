import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';

import {
  UPDATE_CLIENT_ASSIGNEE_LEGAL_DATA,
  GET_CLIENT_ASSIGNEE_LEGAL_DATA,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import {
  errorMessage,
  successMessage,
  edgeToList,
} from '../../../../utils/commonFunctions';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import { LIST_MARITAL_STATUS } from '../../../Models/MaritalStatus/queries';
import { LIST_DOCUMENT_TYPES } from '../../../Models/DocumentType/queries';
import LoadingIndicator from '../../../LoadingIndicator';

function LegalDataAttorney(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [startValues, setStartValues] = useState({
    documentType: '',
    alternativeDocumentType: '',
    documentNumber: '',
    nationality: [],
    maritalStatus: '',
  });
  const [assigneeID, setAssigneeID] = useState();
  const [defaultOther, setDefaultOther] = useState(true);

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_ASSIGNEE_LEGAL_DATA, {
    variables: { id: clientID },
  });

  const updateClient = useMutation(UPDATE_CLIENT_ASSIGNEE_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;

        if (client) {
          successMessage(
            'Se guardó datos legales del apoderado.',
            enqueueSnackbar
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_ASSIGNEE_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_ASSIGNEE_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;

        if (client) {
          successMessage(
            'Se guardó datos legales del apoderado parcialmente.',
            enqueueSnackbar
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_ASSIGNEE_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  // SELECT QUERIES
  const { error: MaritalStatusError, data: MaritalStatusData } = useQuery(
    LIST_MARITAL_STATUS
  );
  const { error: DocumentTypesError, data: DocumentTypesData } = useQuery(
    LIST_DOCUMENT_TYPES
  );

  if (clientError) {
    console.error('Legal data assignee - client', clientError);
  }
  if (MaritalStatusError) {
    console.error(
      'Legal data assignee - list marital status',
      MaritalStatusError
    );
  }
  if (DocumentTypesError) {
    console.error(
      'Legal data assignee - list document types',
      DocumentTypesError
    );
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required(),
    alternativeDocumentType: Yup.string().when('documentType', {
      is: 'other',
      then: Yup.string().required(),
    }),
    documentNumber: Yup.string().required(),
    maritalStatus: Yup.string().required(),
    nationality: Yup.array().required(),
  });

  const data = [
    {
      isField: false,
      section: 'Documento de identidad',
      fields: [
        {
          label: '* Tipo de documento',
          name: 'documentType',
          labelText: 'Otro tipo de documento',
          nameText: 'alternativeDocumentType',
          inputVariant: INPUT_VARIANT.otherSelect,
          gridMD: [12, 12],
          data: edgeToList(DocumentTypesData, 'listDocumentTypes').concat([
            { id: 'other', name: 'Otro  documento' },
          ]),
          mapData: { value: 'id', label: 'name' },
          defaultOther,
        },
        {
          label: '* Número de documento',
          name: 'documentNumber',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.text,
        },
      ],
    },
    {
      isField: false,
      section: 'Nacionalidades',
      fields: [
        {
          label: '* Nacionalidad',
          name: 'nationality',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.nationality,
        },
      ],
    },
    {
      isField: true,
      label: '* Estado civil',
      name: 'maritalStatus',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.select,
      data: edgeToList(MaritalStatusData, 'listMaritalStatus'),
      mapData: { value: 'id', label: 'name' },
    },
  ];

  useEffect(() => {
    if (clientError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar
      );
    }
    if (clientData) {
      const { client } = clientData;

      if (client) {
        const { id, __typename, user, ...otherValues } = client.assignee;
        setAssigneeID(id);
        const { id: userId, __typename: userTypename, ...userData } = user;
        const currentData = Object.assign(otherValues, userData);

        if (currentData.documentType && currentData.documentType !== '') {
          currentData.documentType = currentData.documentType
            ? currentData.documentType.id
            : startValues.documentType;
        } else {
          currentData.documentType = 'other';
          setDefaultOther(false);
        }

        currentData.alternativeDocumentType =
          currentData.alternativeDocumentType ||
          startValues.alternativeDocumentType;
        currentData.documentNumber =
          currentData.documentNumber || startValues.documentNumber;

        currentData.nationality = currentData.nationalitySet.edges.map(
          nat => nat.node.nationality
        );
        delete currentData.nationalitySet;
        currentData.maritalStatus = currentData.maritalStatus
          ? currentData.maritalStatus.id
          : startValues.maritalStatus;

        setStartValues({ ...currentData });
      }
    }
  }, [clientData, clientLoading]);

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    if (newValues.documentType === 'other') {
      newValues.documentType = '';
    } else {
      newValues.alternativeDocumentType = '';
    }
    return newValues;
  }

  return (
    <>
      {clientLoading ? (
        <LoadingIndicator />
      ) : (
        <StepsForm
          initialValues={startValues}
          validateFunction={validationFunction}
          validateSchema={validationSchema}
          data={data}
          id={assigneeID}
          mainClientID={clientID}
          nextForm={MENU_ITEMS_ID.AttorneyLabourData}
          title="Datos del apoderado o mandatorio / Datos legales"
          updateMutation={updateClient}
          partialUpdateMutation={partialUpdateClient}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      )}
    </>
  );
}

LegalDataAttorney.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default LegalDataAttorney;
