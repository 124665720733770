import React, { useState, useEffect } from 'react';

// Material Resources
import { Button, Grid } from '@material-ui/core';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Others
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveFormSelector,
  getEditionSelector,
  getMissingStepsSelector,
} from 'src/containers/ModelsPages/ClientForm/selectors';
import { errorMessage, edgeToList } from '../../../utils/commonFunctions';
import {
  setActiveFormAction,
  setStatus,
} from '../../../containers/ModelsPages/ClientForm/actions';
import UPDATE_TREE_STATUS from '../../Forms/StepsForm/queries';
import { NODE_STATES } from 'src/containers/ModelsPages/ClientForm/constants';
import { LIST_MISSING_STEP } from 'src/containers/ModelsPages/ClientForm/queries';

// Styles & Images
import useStyles from './styles';

function NextStepButton(props) {
  const { clientID, nextForm, disabled } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const activeForm = useSelector(getActiveFormSelector());
  const missingSteps = useSelector(getMissingStepsSelector());
  const isEdition = useSelector(getEditionSelector());

  const {
    loading: missingStepLoading,
    data: missingStepData,
    error: missingStepError,
  } = useQuery(LIST_MISSING_STEP);

  const [updateTreeStatus] = useMutation(UPDATE_TREE_STATUS, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        dispatch(setActiveFormAction(nextForm));
        dispatch(setStatus(activeForm, NODE_STATES.complete));
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  return (
    <>
      <Grid container justify="flex-end" className={classes.buttonContainer}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            disabled={disabled}
            onClick={() => {
              const clientMissingSteps = [];
              const steps = [...missingSteps];
              const index = steps.indexOf(activeForm);
              if (index > -1) {
                steps.splice(index, 1);
              }

              if (missingStepData) {
                const allSteps = edgeToList(missingStepData, 'listMissingStep');
                allSteps.forEach(item => {
                  if (steps.find(i => i === item.step)) {
                    clientMissingSteps.push(item.id);
                  }
                });
              }
              updateTreeStatus({
                variables: {
                  id: clientID,
                  input: {
                    treeStatus: nextForm.replace(/-/g, '_'),
                    missingSteps: clientMissingSteps,
                  },
                },
              });
            }}
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
NextStepButton.propTypes = {
  clientID: PropTypes.string.isRequired,
  nextForm: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

NextStepButton.defaultProps = {
  disabled: false,
};

export default NextStepButton;
