import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  mainDiv: {
    padding: '10px',
    width: '100%',
    // minWidth: '90%',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '5px',
    border: '2px solid rgba(0, 0, 0, 0.05)',
    background: '#FFF',
    boxShadow: '1px 2px 1px 0px rgba(166, 193, 211, 0.30)',
    height: '90%',
  },
  mainDivDark: {
    padding: '10px',
    width: '100%',
    // minWidth: '90%',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '5px',
    border: '2px solid rgba(0, 0, 0, 0.05)',
    background: '#323232',
    // boxShadow: '1px 2px 1px 0px rgba(166, 193, 211, 0.30)',
    height: '90%',
  },
  notificationsDiv: {
    width: '80%',
    display: 'flex',
    paddingBottom: '10px',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    alignContent: 'start',
  },
  layout: {
    width: '100%',
    height: '65%',
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  layout2: {
    width: '100%',
    height: '40%',
    minWidth: '50vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  item: {
    width: '95%',
    borderRadius: '2px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: '#FFF',
    boxShadow: '1px 4px 4px 0px rgba(166, 193, 211, 0.30)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '5px',
  },

  itemDark: {
    width: '95%',
    borderRadius: '2px',
    border: '0.5px solid rgba(0, 0, 0, 0.10)',
    background: '#2F2F2F',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '8px',
  },
  title: {
    fontSize: theme.typography.body1,
    fontFamily: 'Montserrat',
    fontWeight: '700',
    color: '#292D32',
    padding: 0,
    margin: 0,
  },
  title1: {
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    color: '#292D32',
    padding: 0,
    margin: 0,
  },
  title1Dark: {
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    fontWeight: '700',
    color: '#ECECEC',
    padding: 0,
    margin: 0,
  },
  titleDark: {
    fontSize: theme.typography.body1,
    fontFamily: 'Montserrat',
    fontWeight: '700',
    color: '#ECECEC',
    padding: 0,
    margin: 0,
  },

  content: {
    fontSize: theme.typography.body2,
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: '#929292',
    padding: 0,
    margin: 0,
  },

  contentDark: {
    fontSize: theme.typography.body2,
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: '#AAAAAA',
    padding: 0,
    margin: 0,
  },

  popUp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4%',
    width: '25%',
    maxHeight: '30vh',
    overflow: 'scroll',
    position: 'absolute',
    padding: '10px',
    top: '12%',
    right: '5%',
    background: '#fff',
    zIndex: 999,
    boxShadow: '0px 4px 4px 0px  rgba(166, 193, 211, 0.50)',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      marginTop: '55%',
    },
  },
  popUpDark: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4%',
    width: '25%',
    maxHeight: '30vh',
    overflow: 'scroll',
    position: 'absolute',
    padding: '10px',
    top: '12%',
    right: '5%',
    background: '#323232',
    zIndex: 999,
    boxShadow: '0px 4px 4px 0px  rgba(166, 193, 211, 0.20)',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      marginTop: '55%',
    },
  },
}));
