import Yup from '../../../../../utils/yup';

export function validationFunctionAdministrator(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaAdministrator = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  maternalSurname: Yup.string().required(),
  email: Yup.string()
    .email()
    .required(),
  dni: Yup.string().length(8, 'El DNI debe tener 8 caracteres'),
});
