import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  form: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
      minHeight: 92,
    },
  },
  formContainer: {
    marginBottom: 20,
  },
}));
