import React, { useEffect, useState } from 'react';

// Material Resources
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Avatar,
  Dialog,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import CustomOptions from '../../../../CustomOptions';
import LoadingIndicator from '../../../../LoadingIndicator';
import CustomColumnHeader from '../../../../TableComponents/CustomColumnHeader';
import {
  getOptions,
  generateColumn,
  edgeToList,
  stringToDate,
  scientificToDecimal,
} from '../../../../../utils/commonFunctions';

// Styles & Images
import useStyles from '../styles';
import CustomSubmodelList from '../../../../InnerModels/CustomSubmodel/CustomSubmodelList';
import Yup from '../../../../../utils/yup';
import { INPUT_VARIANT } from '../../../../Forms/StepsForm/fieldsSelector';
import {
  LIST_VCC_FUND,
  GET_VCC_FUND,
  UPDATE_VCC_FUND,
  CREATE_VCC_FUND,
  DELETE_VCC_FUND,
} from './queries';
import { defaultDate } from '../../../../FormComponents/DatePicker';

function FundVCC(props) {
  const { fundID } = props;
  const classes = useStyles();

  const { loading, error, data } = useQuery(LIST_VCC_FUND, {
    variables: { id: fundID },
  });

  const mutationsData = {
    update: {
      query: UPDATE_VCC_FUND,
      mutationName: 'updateAccountingFeeValue',
      modelName: 'accountingFeeValue',
      message: 'VCC actualizado con éxito.',
      refetchQueries: [{ query: LIST_VCC_FUND, variables: { id: fundID } }],
    },
    create: {
      query: CREATE_VCC_FUND,
      mutationName: 'createAccountingFeeValue',
      modelName: 'accountingFeeValue',
      message: 'VCC creado con éxito.',
      refetchQueries: [{ query: LIST_VCC_FUND, variables: { id: fundID } }],
    },
    delete: {
      query: DELETE_VCC_FUND,
      mutationName: 'deleteAccountingFeeValue',
      modelName: 'accountingFeeValue',
      message: 'VCC eliminado con éxito.',
      refetchQueries: [{ query: LIST_VCC_FUND, variables: { id: fundID } }],
    },
  };

  const columns = [
    {
      name: 'value',
      label: 'Valor',
      options: {
        sort: true,
        customBodyRender: value => scientificToDecimal(value),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'date',
      label: 'Fecha',
      options: {
        sort: true,
        customBodyRender: value =>
          stringToDate(value).toLocaleDateString('es-ES', {
            month: 'long',
            year: 'numeric',
          }),
        customHeadRender: (columnMeta, updateDirection) => (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        ),
      },
    },
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        display: false,
        customBodyRender: value => {
          const id = value;
          return <CustomOptions id={id} model="VCC" options={[]} />;
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  // Form data
  const startValues = {
    value: 0,
    date: defaultDate,
  };
  function validationFunction(values) {
    const errors = {};
    // Custom Validations
    return errors;
  }
  const validationSchema = Yup.object().shape({
    value: Yup.string().required(),
    date: Yup.string().required(),
  });
  const formStructure = [
    {
      label: 'Valor',
      name: 'value',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 10,
        fixedDecimalScale: true,
      },
    },
    {
      label: 'Fecha',
      name: 'date',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
      views: ['month', 'year'],
      customFormat: 'YYYY-MM',
    },
  ];

  function formatQuery(vcc) {
    const formatValue = { ...vcc.accountingFeeValue };
    formatValue.value = formatValue.value.toString();
    return formatValue;
  }

  function formatBeforeSubmit(values) {
    const { ...newValues } = values;
    if (newValues.length <= 7) {
      newValues.date = stringToDate(newValues.date + '-01').toISOString();
    } else {
      newValues.date = stringToDate(newValues.date).toISOString();
    }

    newValues.fund = fundID;
    return newValues;
  }

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <CustomSubmodelList
            submodelName="Valor cuota contable"
            submodelList={
              data && edgeToList(data.fund, 'accountingfeevalueSet')
            }
            columns={columns}
            updateModelFunction={() => {}}
            // Form props
            canBeDeleted={false}
            getQuery={GET_VCC_FUND}
            formatQuery={formatQuery}
            mutationsData={mutationsData}
            startValues={startValues}
            validationSchema={validationSchema}
            validationFunction={validationFunction}
            formStructure={formStructure}
            formatBeforeSubmit={formatBeforeSubmit}
          />
        </>
      )}
    </>
  );
}

FundVCC.propTypes = {
  fundID: PropTypes.string.isRequired,
};

export default FundVCC;
