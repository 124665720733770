import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import cx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  IconButton,
  Tooltip,
  Typography,
  TableCell,
  Button,
} from '@material-ui/core';

import CustomOptions from '../../CustomOptions';
import LoadingIndicator from '../../LoadingIndicator';
import { DELETE_PROMOTER, UPDATE_PROMOTER, LIST_PROMOTERS } from './queries';
import { generateColumn, getOptions } from '../../../utils/commonFunctions';
import { textLabels } from '../../../translations/components/MUIDataTable';

import useStyles from './styles';

import CustomColumnHeader from '../../TableComponents/CustomColumnHeader';

function Promoter(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [promoterList, setPromoterList] = useState([]);

  const [deletePromoter] = useMutation(
    DELETE_PROMOTER,
    getOptions({
      mutationName: 'deletePromoterCompany',
      modelName: 'promoterCompany',
      message: 'Empresa promotora eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTERS }],
    })
  );
  const [updatePromoterState] = useMutation(
    UPDATE_PROMOTER,
    getOptions({
      mutationName: 'updatePromoter',
      modelName: 'promoter',
      message: 'Estado actualizado con éxito.',
      enqueueSnackbar,
      refetchQueries: [{ query: LIST_PROMOTERS }],
    })
  );
  const { loading: listLoading, error: listError, data: listData } = useQuery(
    LIST_PROMOTERS
  );

  function edgeToListPromoter(data) {
    if (data) {
      return data.listPromoter.edges.map(element => {
        const complete = { ...element.node };
        complete.promoterCompanies = complete.promoterCompanies
          ? complete.promoterCompanies.edges.map(comp => {
              return comp.node;
            })
          : [];
        return complete;
      });
    }
    return [];
  }

  useEffect(() => {
    setPromoterList(edgeToListPromoter(listData));
  }, [listData]);

  const changeState = (id, enabled) => {
    updatePromoterState({
      variables: {
        id,
        input: {
          enabled: !enabled,
        },
      },
    });
  };

  const columns = [
    {
      name: 'enabled',
      label: 'Estado',
      options: {
        sort: false,
        customBodyRender: (enabled, row) => {
          return (
            <Tooltip title="Estado" placement="top">
              <Button
                variant="contained"
                size="small"
                className={cx(
                  classes.stateButton,
                  enabled ? classes.orange : classes.gray
                )}
                onClick={$ev => {
                  $ev.stopPropagation();
                  changeState(row.rowData[4], enabled);
                }}
              >
                <CheckCircleIcon />
              </Button>
            </Tooltip>
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={0} style={{ borderBottom: 'none', width: '10px' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
    generateColumn('Nombres', 'firstName'),
    generateColumn('Apellidos', 'lastName'),
    {
      name: 'promoterCompanies',
      label: 'Empresas Promotoras',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          const companies = value;
          return (
            <Typography variant="subtitle1" align="left">
              {companies.map(company => (
                <p key={company.businessName}>{company.businessName}</p>
              ))}
            </Typography>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Promotor"
              options={['oedit', 'odelete']}
              handleDelete={() => {
                deletePromoter({ variables: { id } });
              }}
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={7} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1] && rowData[rowData.length - 1].props) {
      history.push(
        `${history.location.pathname}/${rowData[rowData.length - 1].props.id}`
      );
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => (
      <Tooltip title="Nuevo Promotor">
        <IconButton
          color="secondary"
          onClick={() => history.push(`${history.location.pathname}/crear`)}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    ),
    onRowClick: rowData => handleRowClick(rowData),
  };

  return listLoading ? (
    <LoadingIndicator />
  ) : (
    <MUIDataTable
      title="Promotores"
      data={promoterList}
      columns={columns}
      options={options}
    />
  );
}

export default Promoter;
