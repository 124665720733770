import { makeStyles, fade } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  autocomplete: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
  multilineCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionButtons: {
    minHeight: 30,
    minWidth: 30,
    padding: theme.spacing(0.5),
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
}));
