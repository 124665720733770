import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
// import { Select } from 'formik-material-ui';
import { ErrorMessage } from 'formik';
// Styles & Images
import useStyles from './styles';

function CustomSelectMaterialCoreAdmin(props) {
  // data : Array of elements, example: [{ country_name: "Peru", country_val: "PE" }]
  // mapData: Define which object property use as value and label, example: {value: "country_val", label: "country_name"}
  // mapData default => {value: "value", label: "label"}
  const {
    id,
    disabled,
    name,
    label,
    data,
    mapData,
    onChange,
    error,
    isStepForm,
  } = props;
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [showError, setShowError] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 16;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  useEffect(() => {
    if (error !== '') {
      setShowError(true);
    }
  }, [error]);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={error !== '' && showError}
      disabled={disabled}
    >
      <InputLabel ref={inputLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        labelWidth={labelWidth}
        name={name}
        MenuProps={MenuProps}
        inputProps={{
          id,
          name,
          onChange,
        }}
      >
        {data.map(element => (
          <MenuItem key={element[mapData.value]} value={element[mapData.value]}>
            {element[mapData.label]}
          </MenuItem>
        ))}
      </Select>
      {error !== '' && showError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
CustomSelectMaterialCoreAdmin.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isStepForm: PropTypes.bool,
  error: PropTypes.string,
};
CustomSelectMaterialCoreAdmin.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  onChange: () => null,
  disabled: false,
  error: '',
  isStepForm: false,
};

export default CustomSelectMaterialCoreAdmin;
