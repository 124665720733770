import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_ACCOUNT_TYPE,
  CREATE_ACCOUNT_TYPE,
  DELETE_ACCOUNT_TYPE,
  LIST_ACCOUNT_TYPES,
} from '../queries';
import {
  validationFunctionAccountType,
  validationSchemaAccountType,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function AccountTypeForm(props) {
  const { accountType, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateAccountType = useMutation(
    UPDATE_ACCOUNT_TYPE,
    getOptions({
      mutationName: 'updateAccountType',
      modelName: 'accountType',
      message: 'Tipo de cuenta actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const createAccountType = useMutation(
    CREATE_ACCOUNT_TYPE,
    getOptions({
      mutationName: 'createAccountType',
      modelName: 'accountType',
      message: 'Tipo de cuenta creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createAccountTypeData }) {
        if (cache.data.data.ROOT_QUERY.listAccountType) {
          const { listAccountType } = cache.readQuery({
            query: LIST_ACCOUNT_TYPES,
          });
          listAccountType.edges.unshift({
            // Be careful with types
            __typename: 'AccountTypeTypeEdge',
            node: createAccountTypeData.createAccountType.accountType,
          });
          cache.writeQuery({
            query: LIST_ACCOUNT_TYPES,
            data: { listAccountType },
          });
        }
        history.push('/administrador/tipos-de-cuenta');
      },
    })
  );
  const deleteAccountType = useMutation(
    DELETE_ACCOUNT_TYPE,
    getOptions({
      mutationName: 'deleteAccountType',
      message: 'Tipo de cuenta eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listAccountType) {
          const { listAccountType } = cache.readQuery({
            query: LIST_ACCOUNT_TYPES,
          });
          listAccountType.edges = listAccountType.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_ACCOUNT_TYPES,
            data: { listAccountType },
          });
        }
        history.push('/administrador/tipos-de-cuenta');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionAccountType}
        validateSchema={validationSchemaAccountType}
        data={data}
        model={accountType}
        id={id}
        modelName="tipos de cuenta"
        routeName="/administrador/tipos-de-cuenta"
        isEdit={isEdit}
        updateMutation={updateAccountType}
        createMutation={createAccountType}
        deleteMutation={deleteAccountType}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

AccountTypeForm.propTypes = {
  accountType: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

AccountTypeForm.defaultProps = {
  accountType: null,
  id: '',
  isEdit: false,
};

export default AccountTypeForm;
