/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import FilesList from '../../../components/Models/Files/FilesList';
import ProcessForm from 'src/components/Models/Files/Form';
import ProcessFormMultipleFiles from 'src/components/Models/Files/FormMultipleUpload';

function FilesPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={FilesList} />
      <Route path={`${path}/crear`} component={ProcessForm} />
      <Route
        path={`${path}/crearMultiple`}
        component={ProcessFormMultipleFiles}
      />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

FilesPage.propTypes = {};

export default FilesPage;
