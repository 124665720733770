import React, { useEffect, useMemo } from 'react';
import { gql } from 'apollo-boost';
import * as moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { setAuthUser, getAuthUser, delAuthUser } from '../utils/storage';

const SessionContext = React.createContext();

const REFRESH_TOKEN = gql`
  mutation RefreshtToken {
    refreshToken {
      payload
      refreshExpiresIn
    }
  }
`;

const MINUTE = 60000;

function getInterval(exp) {
  const now = moment();
  const expiresAt = moment.unix(exp);
  const duration = moment.duration(expiresAt.diff(now)).asMilliseconds();

  return duration - MINUTE;
}

function SessionProvider(props) {
  let tokenExpire = null;
  const [getRefreshToken, { data: refreshTokenData }] = useMutation(
    REFRESH_TOKEN,
    {
      onError(error) {
        window.location.href = '/';
        delAuthUser();
        if (tokenExpire) {
          clearTimeout(tokenExpire);
        }
      },
    }
  );

  async function setRefreshToken(payload, force = false) {
    if (tokenExpire !== null) {
      clearTimeout(tokenExpire);
    }
    let interval = 100;
    setAuthUser(payload);
    if (!force) {
      /* If force means that the interval has to be set up now (100ms).
         Used when the user refresh the website
       */
      interval = getInterval(payload.exp);
    }
    if (interval > 0) {
      tokenExpire = setTimeout(() => {
        getRefreshToken();
      }, interval);
    }
  }

  useEffect(() => {
    if (refreshTokenData !== undefined) {
      const {
        refreshToken: { payload, refreshExpiresIn },
      } = refreshTokenData;
      setRefreshToken({ ...payload, refreshExpiresIn });
    }
  }, [refreshTokenData]);

  useEffect(() => {
    const authUser = getAuthUser();
    if (authUser !== null) {
      setRefreshToken(authUser, true);
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{
        setRefreshToken,
      }}
      {...props}
    />
  );
}

const useSession = () => React.useContext(SessionContext);

export { SessionProvider, useSession };
