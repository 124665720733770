import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  formControl: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  label: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    display: 'block',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  other: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  otherInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}));
