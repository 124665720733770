import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import { Checkbox } from 'formik-material-ui';
import { useParams, useHistory } from 'react-router-dom';
import { elementType } from 'prop-types';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Button,
  Typography,
  FormLabel,
  FormHelperText,
  Select,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import SectionDivider from '../../../FormComponents/SectionDivider';
import CustomButton from '../../../FormComponents/CustomButton';
import SimpleForm from '../../../Forms/SimpleForm';
import { validationSchema, startData, extraFormStructure } from './formInfo';
import {
  UPDATE_SIGNATURE,
  CREATE_SIGNATURE,
  LIST_SIGNATURES,
} from '../queries';
import { LIST_CLIENT_USERS } from '../../Users/queries';
import { LIST_FUNDS } from '../../Fund/queries';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import LoadingIndicator from '../../../LoadingIndicator';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  simpleFormContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  labelContainer: {
    alignItems: 'center',
  },
  errorTypo: {
    color: theme.palette.common.red,
    marginTop: '5px',
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
}));

function ProcessForm(props) {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [showNewSignerForm, setShowNewSignerForm] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [fundList, setFundList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileEncode, setFileEncode] = useState();
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [initialValues, setInitialValues] = useState({
    clients: [],
    isBiometricAvailable: false,
    signingFile: '',
    subject: '',
    body: '',
    isDefaultWording: false,
  });
  const [signatories, setSignatories] = useState([]);
  const validate = () => ({});
  const { data: listSignatureProcess } = useQuery(LIST_SIGNATURES);
  const { loading: listClientLoading, error, data } = useQuery(
    LIST_CLIENT_USERS,
  );
  const { error: FundsError, data: FundsData } = useQuery(LIST_FUNDS);

  useEffect(() => {
    if (!!data) {
      setClientList(getUsersByClients(edgeToList(data, 'listClients')));
    }
    if (!!FundsData) {
      setFundList(
        edgeToList(FundsData, 'listFunds').filter(item => item?.state === true),
      );
    }
  }, [data, FundsData]);

  useEffect(() => {
    if (listSignatureProcess && id != 'crear') {
      const listProcess = edgeToList(
        listSignatureProcess,
        'listSigningDocuments',
      );
      const processData = listProcess.find(element => element.id === id);
      const { pdfSheet, signedPdfSheet, signingProcess } = processData;
      const parserClient = edgeToList(signingProcess, 'clients').map(item => {
        return {
          id: item.user.id,
          fullName: item.user.firstName + ' ' + item.user.lastName,
          clientId: item.id,
        };
      });
      const parserExternalSigners = edgeToList(
        signingProcess,
        'externalSigners',
      ).map(item => {
        if (
          item.firstName ||
          item.lastName ||
          item.documentNumber ||
          item.email
        ) {
          setShowNewSignerForm(true);
        }
        return {
          names: item.firstName,
          lastnames: item.lastName,
          documentNumber: item.documentNumber,
          email: item.email,
        };
      });

      setSignatories(parserExternalSigners);
      const last_position_pdf = parseInt(pdfSheet.lastIndexOf('/')) + 1;

      const joinData = {
        clients: parserClient,
        signingFile: pdfSheet?.slice(last_position_pdf),
        isBiometricAvailable: signingProcess.isBiometricAvailable,
        subject: signingProcess.subject,
        isDefaultWording: signingProcess.isDefaultWording,
        body: signingProcess.body,
      };
      setFileName(pdfSheet?.slice(last_position_pdf));
      setInitialValues(joinData);
    }
  }, [listSignatureProcess, id, clientList]);

  const createMutation = useMutation(
    CREATE_SIGNATURE,
    getOptions({
      mutationName: 'createSignature',
      modelName: 'signingProcess',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  const updateMutation = useMutation(
    UPDATE_SIGNATURE,
    getOptions({
      mutationName: 'updateSignature',
      modelName: 'signingProcess',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  function getUsersByClients(clients) {
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user } = element;
      user = {
        ...user,
        clientId: element.id,
        fullName: user.firstName + ' ' + user.lastName,
      };
      return user;
    });
  }

  const [createModel, { loading: createLoading }] = createMutation;
  const [updateModel, { loading: updateLoading }] = updateMutation;

  useEffect(() => {
    if (!createLoading) {
      setLoading(false);
    }
  }, [createLoading]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async (e, propsForm) => {
    try {
      let name = e.target.files[0].name;
      const encoded = await toBase64(e.target.files[0]);
      propsForm.setFieldValue('signingFile', encoded);
      setFileEncode(encoded);
      setFileName(name);
    } catch (error) {
      console.log('error', error);
    }
  };

  const addForm = () => {
    setSignatories([
      ...signatories,
      {
        names: '',
        lastnames: '',
        documentNumber: '',
        email: '',
      },
    ]);
  };

  const handleChangeInput = (e, index) => {
    let result = signatories.map((dato, indexSign) => {
      if (indexSign == index) {
        dato[e.target.id] = e.target.value;
      }
      return dato;
    });

    setSignatories(result);
  };

  const handleDeleteInput = index => {
    const resultDelete = signatories.filter((item, i) => i !== index);
    setSignatories(resultDelete);
  };

  return listClientLoading ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const tmp = values;
          const clientsParser = values.clients.map(item => item.clientId);
          tmp.clients = clientsParser;
          tmp.externalSigners = signatories;
          if (id === 'crear') {
            createModel({ variables: { input: tmp } });
          } else {
            updateModel({ variables: { id: id, input: tmp } });
          }
          history.goBack();
        }}
      >
        {propsForm => (
          <Form className={classes.simpleForm}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  {id === 'crear' ? 'Crear' : 'Editar'} Proceso de Firma
                </Typography>
                <SectionDivider />
                <Grid container alignItems="center">
                  {id === 'crear' && (
                    <>
                      <Grid className={classes.labelContainer} item xs={6}>
                        <InputLabel>Documento</InputLabel>
                        {fileName && (
                          <div>
                            <small>{fileName}</small>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          component="label"
                          color="primary"
                          name="signingFile"
                          error={propsForm.errors.signingFile}
                          required
                        >
                          Buscar Archivo
                          <input
                            id="signingFile"
                            name="signingFile"
                            type="file"
                            accept=".pdf"
                            style={{ display: 'none' }}
                            onChange={e => handleChange(e, propsForm)}
                            disabled={id === 'crear' ? false : true}
                          />
                        </Button>
                        <ErrorMessage name="signingFile">
                          {msg => (
                            <Typography className={classes.errorTypo}>
                              {msg} *
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </>
                  )}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    item
                    xs={6}
                  >
                    <InputLabel id="isContract">Tipo</InputLabel>
                    <Select
                      fullWidth
                      labelId="isContract"
                      name="isContract"
                      variant="outlined"
                      required
                      value={
                        signatories.length > 0
                          ? 'otros'
                          : propsForm.values.isContract
                      }
                      error={propsForm.errors.isContract}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('isContract', value.key);
                        setFileType(value.key);
                      }}
                    >
                      <MenuItem key={'contrato'} value={'contrato'}>
                        Contrato
                      </MenuItem>
                      <MenuItem key={'otros'} value={'otros'}>
                        Otros
                      </MenuItem>
                    </Select>
                    <ErrorMessage name="isContract">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    item
                    xs={6}
                  >
                    <InputLabel id="fund">Fondo</InputLabel>
                    <Select
                      fullWidth
                      labelId="fund"
                      name="fund"
                      variant="outlined"
                      required={true}
                      value={propsForm.values.fund}
                      error={propsForm.errors.fund}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('fund', value.key);
                      }}
                    >
                      {fundList.map(dt => (
                        <MenuItem key={dt.id} value={dt.id}>
                          {dt.businessName}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="fund">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="clients"
                      fullWidth
                      value={propsForm.values.clients}
                      error={propsForm.errors.clients}
                      multiple
                      onChange={(e, value) => {
                        propsForm.setFieldValue('clients', value);
                      }}
                      options={clientList}
                      getOptionLabel={option =>
                        option.fullName ? option.fullName : ''
                      }
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.clients}
                          value={propsForm.values.clients}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Clientes"
                          placeholder="Clientes"
                        />
                      )}
                    />
                    <ErrorMessage name="clients">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <Grid container alignItems="center">
                    <Grid className={classes.labelContainer} item xs={6}>
                      <TextField
                        fullWidth
                        id="subject"
                        label="Asunto"
                        name="subject"
                        value={propsForm.values.subject}
                        disabled={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue('subject', e.target.value)
                        }
                        error={propsForm.errors.subject}
                      />
                      <ErrorMessage name="subject">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        name="isDefaultWording"
                        value={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue(
                            'isDefaultWording',
                            e.target.value,
                          )
                        }
                        disabled={
                          propsForm.values.subject && propsForm.values.body
                        }
                        checked={propsForm.values.isDefaultWording}
                        error={propsForm.errors.isDefaultWording}
                      />
                      <FormLabel component="label" color="primary">
                        Correo por defecto
                      </FormLabel>
                      <ErrorMessage name="isDefaultWording">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid className={classes.labelContainer} item xs={6}>
                      <TextField
                        fullWidth
                        id="body"
                        label="Cuerpo"
                        name="body"
                        value={propsForm.values.body}
                        disabled={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue('body', e.target.value)
                        }
                        error={propsForm.errors.body}
                      />
                      <ErrorMessage name="body">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <SectionDivider />
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={
                          loading ||
                          (signatories.length == 0 &&
                            propsForm.values.clients.length == 0)
                        }
                      >
                        {id === 'crear' ? 'Crear' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          if (showNewSignerForm) setShowNewSignerForm(false);
          else setShowNewSignerForm(true);
        }}
        disabled={fileType == 'contrato'}
      >
        Añadir Nuevos Firmantes
      </Button>

      {showNewSignerForm && (
        <>
          <Grid container justify="flex-end" alignItems="flex-end">
            <AddCircleIcon
              onClick={addForm}
              style={{ color: '#f97a00', cursor: 'pointer' }}
              fontSize="large"
            />
          </Grid>

          {signatories?.map((item, index) => (
            <Grid container key={index}>
              <SectionDivider />
              <Grid container item xs={12} style={{ marginBottom: 10 }}>
                <Grid
                  container
                  xs={12}
                  justify="flex-end"
                  style={{ padding: 5 }}
                >
                  <IconButton
                    color="secondary"
                    aria-label="delete"
                    component="span"
                    onClick={() => handleDeleteInput(index)}
                  >
                    <HighlightOffIcon
                      style={{ color: '#f97a00', fontSize: 20 }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={6} style={{ paddingRight: 10 }}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="names"
                    label="Nombre"
                    value={item.names}
                    onChange={e => handleChangeInput(e, index)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="lastnames"
                    label="Apellidos"
                    value={item.lastnames}
                    onChange={e => handleChangeInput(e, index)}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={6} style={{ paddingRight: 10 }}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="documentNumber"
                    label="Documento"
                    value={item.documentNumber}
                    onChange={e => handleChangeInput(e, index)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    id="email"
                    label="Correo"
                    value={item.email}
                    onChange={e => handleChangeInput(e, index)}
                  />
                </Grid>
              </Grid>
              <SectionDivider />
            </Grid>
          ))}
        </>
      )}
    </div>
  );
}

export default ProcessForm;
