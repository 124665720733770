import { fromJS } from 'immutable';

import {
  SET_ACTIVE_FORM,
  SET_IS_SOCIETY,
  SET_IS_MARRIED,
  SET_HAS_ATTORNEY,
  SET_ANNEX_B,
  SET_VISIBLE,
  INIT_DATA,
  MENU_ITEMS_ID,
  SET_CLIENTPEP,
  SET_SPOUSEPEP,
  SET_RELATIVEPEP,
  SET_STATUS,
  SET_EDITION,
  SET_IS_PERUVIAN,
  SET_MISSING_STEPS,
} from './constants';

// The initial state of the App
const initialState = fromJS({
  activeForm: MENU_ITEMS_ID.ClientPersonalData,
  missingSteps: [],
  married: false,
  society: false,
  attorney: false,
  annexB: false,
  clientPEP: false,
  spousePEP: false,
  relativePEP: false,
  edition: false,
  isPeruvian: false,
  nodeList: INIT_DATA,
});

const setMarried = (isMarried, nodeList) => {
  const list = [...nodeList.toJS()];
  const spouseData = list.findIndex(
    val => val.id === MENU_ITEMS_ID.SpousePartnerNode,
  );

  if (spouseData !== -1) {
    list[spouseData].visible = isMarried;
  }

  return fromJS(list);
};

const setSociety = (isSociety, isMarried, nodeList) => {
  const list = [...nodeList.toJS()];
  const spouseLabourData = list.findIndex(
    val => val.id === MENU_ITEMS_ID.SpousePartnerLaborData,
  );
  const spouseSafiRelation = list.findIndex(
    val => val.id === MENU_ITEMS_ID.SpousePartnerSafiRelation,
  );

  if (spouseLabourData !== -1) {
    list[spouseLabourData].visible = isSociety && isMarried;
  }
  if (spouseSafiRelation !== -1) {
    list[spouseSafiRelation].visible = false;
  }

  return fromJS(list);
};

const setAttorney = (flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const attorneyData = list.findIndex(
    val => val.id === MENU_ITEMS_ID.AttorneyClientNode,
  );
  if (attorneyData) {
    list[attorneyData].visible = flag;
  }

  return fromJS(list);
};

const setAnnexB = (flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const attorneyData = list.findIndex(val => val.id === MENU_ITEMS_ID.AnnexB);
  if (attorneyData) {
    list[attorneyData].visible = flag;
  }
  return fromJS(list);
};

const setClientPEP = (flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const exist = list.findIndex(val => val.id === MENU_ITEMS_ID.ClientPEP);
  if (exist) {
    list[exist].visible = flag;
  }
  return fromJS(list);
};

const setSpousePEP = (flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const exist = list.findIndex(val => val.id === MENU_ITEMS_ID.SpousePEP);
  if (exist) {
    list[exist].visible = flag;
  }
  return fromJS(list);
};

const setRelativePEP = (flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const exist = list.findIndex(val => val.id === MENU_ITEMS_ID.RelativePEP);
  if (exist) {
    list[exist].visible = flag;
  }
  return fromJS(list);
};

const setActive = (nodeId, nodeList) => {
  const list = [...nodeList.toJS()];
  const prev = list.findIndex(n => n.active === true);
  const next = list.findIndex(n => n.id === nodeId);
  if (list[prev]) list[prev].active = false;
  if (list[next]) {
    list[next].active = true;
  }
  return fromJS(list);
};

const setVisible = (nodeId, flag, nodeList) => {
  const list = [...nodeList.toJS()];
  const node = list.findIndex(n => n.id === nodeId);
  list[node].visible = flag;
  return fromJS(list);
};

const setStatus = (nodeId, state, nodeList) => {
  const list = [...nodeList.toJS()];
  const node = list.findIndex(n => n.id === nodeId);
  list[node].status = state;
  return fromJS(list);
};

function clientFormReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_FORM:
      return state
        .set('activeForm', action.formId)
        .set(
          'nodeList',
          fromJS(setActive(action.formId, state.get('nodeList'))),
        );
    case SET_MISSING_STEPS:
      return state.set('missingSteps', action.missingSteps);
    case SET_IS_SOCIETY:
      return state.set('society', action.isSociety);
    case SET_IS_MARRIED:
      return state.set('married', action.isMarried);
    case SET_HAS_ATTORNEY:
      return state.set('attorney', action.hasAttorney);
    case SET_ANNEX_B:
      return state
        .set('nodeList', setAnnexB(action.hasAnnexB, state.get('nodeList')))
        .set('annexB', action.hasAnnexB);
    case SET_CLIENTPEP:
      return state
        .set(
          'nodeList',
          setClientPEP(action.hasClientPEP, state.get('nodeList')),
        )
        .set('clientPEP', action.hasClientPEP);
    case SET_SPOUSEPEP:
      return state
        .set(
          'nodeList',
          setSpousePEP(action.hasSpousePEP, state.get('nodeList')),
        )
        .set('spousePEP', action.hasSpousePEP);
    case SET_RELATIVEPEP:
      return state
        .set(
          'nodeList',
          setRelativePEP(action.hasRelativePEP, state.get('nodeList')),
        )
        .set('relativePEP', action.hasRelativePEP);
    case SET_VISIBLE:
      return state.set(
        'nodeList',
        setVisible(action.nodeId, action.state, state.get('nodeList')),
      );
    case SET_STATUS:
      return state.set(
        'nodeList',
        setStatus(action.nodeId, action.state, state.get('nodeList')),
      );
    case SET_EDITION:
      return state.set('edition', action.edition);
    case SET_IS_PERUVIAN:
      return state.set('isPeruvian', action.isPeruvian);
    default:
      return state;
  }
}

export default clientFormReducer;
