import { createSelector } from 'reselect';

function selectApp(state) {
  return state.get('permissionsStore');
}

function createPermissionsSelector(name) {
  return createSelector(selectApp, appState => appState.get(name));
}

export function getPermissionsSelector() {
  return createPermissionsSelector('permissions');
}

export function getUserPermissionsSelector() {
  return createPermissionsSelector('userPermissions');
}

export function getClientPermissionsSelector() {
  return createPermissionsSelector('clientPermissions');
}
