import Yup from '../../../../utils/yup';
import {
  rucValidator,
  percentageValidator,
  integerCountValidator,
  dateRangeValidator,
} from '../../../Validations';

export function validationFunctionFund(values) {
  const errors = {};
  rucValidator(values.ruc, errors);
  percentageValidator(values.performanceGoal, 'performanceGoal', errors);
  integerCountValidator(values.fundCapitalGoal, 15, 'fundCapitalGoal', errors);
  integerCountValidator(values.nominalFeeValue, 15, 'nominalFeeValue', errors);
  integerCountValidator(
    values.minimumInvestment,
    15,
    'minimumInvestment',
    errors,
  );
  dateRangeValidator(
    'startRegulationAgreementDate',
    'endRegulationAgreementDate',
    values,
    errors,
  );
  return errors;
}

export const validationSchemaFund = Yup.object().shape({
  fundType: Yup.string().required(),
  incomeType: Yup.string(),
  tirCalculus: Yup.string(),
  // fixedRate: Yup.string(),
  businessName: Yup.string()
    .max(150, 'Maximo 150 caracteres')
    .required(),
  phoneNumber: Yup.string().required(),
  address: Yup.string()
    .max(250, 'Maximo 250 caracteres')
    .required(),
  acronym: Yup.string()
    .max(20, 'Maximo 20 caracteres')
    .required(),
  subscriptionRight: Yup.string().required(),
  performanceGoal: Yup.string().required(),
  currency: Yup.string().required(),
  category: Yup.string().required(),
  fundCapitalGoal: Yup.string().required(),
  nominalFeeValue: Yup.string().required(),
  subscribedPaidFees: Yup.string().required(),
  lifetime: Yup.number()
    .required()
    .positive('Debe ser un valor positivo mayor a 0')
    .integer(),
  minimumInvestment: Yup.string().required(),
  hasClasses: Yup.string().required(),
  coParticipation: Yup.string().required(),
  exchangeRateRisk: Yup.string().required(),
  maximumCoParticipants: Yup.number().when('coParticipation', {
    is: true,
    then: Yup.number().required(),
  }),
  allocationMechanisms: Yup.string().required(),
  startRegulationAgreementDate: Yup.string().required(),
  endRegulationAgreementDate: Yup.string().required(),
  stockMarketRepresentative: Yup.string().required(),
});
