/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
// Material Resources

// Components & Others
import { useSelector } from 'react-redux';

// Styles & Images
import useStyles from './styles';
import getClientMode from '../../../../../../store/utils-store/selectors';
import { UPDATE_EMPLOYEE_CLIENT } from 'src/components/Models/Employee/queries';
import { GET_CURRENT_USER } from '@queries/Client';
import { getOptions } from 'src/utils/commonFunctions';
import { useSnackbar } from 'notistack';
import EditIcon from '../svg/editIcon';
import { REACT_APP_SERVER_LINK } from 'src/utils/constants';
import ProfileIcon from '../svg/profileIcon';

function Avatar() {
  const { loading, data: clients, refetch } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const { enqueueSnackbar } = useSnackbar();
  const [clientId, setClientId] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();
  const modeClient = useSelector(getClientMode());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const sizeIcon = windowWidth < 980 ? ['43', '41'] : ['133', '131'];
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [cover, setCover] = useState();
  useEffect(() => {
    if (clients) {
      setProfilePhoto(
        clients?.currentUser?.clientSet.edges[0].node.profilePhoto,
      );
      setClientId(clients.currentUser?.clientSet.edges[0].node.id);
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [clients, shouldRefetch]);
  // layoutDark
  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleFileChange = async (e, propsForm) => {
    try {
      const encoded = await toBase64(e.target.files[0]);
      setCover(encoded);
      updateClientMutation({
        variables: {
          id: clientId,
          input: { profilePhoto: encoded },
        },
      });
      // setShouldRefetch(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const [updateClientMutation] = useMutation(
    UPDATE_EMPLOYEE_CLIENT,
    getOptions({
      mutationName: 'updateClient',
      modelName: 'client',
      completeCallback: () => {
        enqueueSnackbar('Foto actualizada con éxito', { variant: 'success' });
        /* eslint-disable no-console */
        // console.log(getClient);
        // setShouldRefetch(false);
      },
      enqueueSnackbar,
    }),
  );
  const handlerUnpload = () => {
    document.getElementById('fileInput').click();
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      handlerUnpload();
    }
  };

  const selectContentHover = modeClient
    ? classes.contentHoverDark
    : classes.contentHover;
  return (
    <>
      {profilePhoto ? (
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={classes.editCover}
          onClick={handlerUnpload}
          onKeyDown={handleKeyDown}
        >
          {isHovered && (
            <div className={selectContentHover}>
              <EditIcon />
              <p>Subir Foto</p>
              <input
                type="file"
                id="fileInput"
                className={classes.fileInput}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
          )}

          {cover ? (
            <img
              alt="Cover"
              style={{
                minWidth: `${sizeIcon[0]}px`,
                minHeight: `${sizeIcon[0]}px`,
                maxWidth: `${sizeIcon[0]}px`,
                maxHeight: `${sizeIcon[0]}px`,
                borderRadius: '50%',
                border: 'solid 2px #000',
                objectFit: 'cover',
              }}
              src={cover}
            />
          ) : (
            <img
              alt="logo"
              style={{
                minWidth: `${sizeIcon[0]}px`,
                minHeight: `${sizeIcon[0]}px`,
                maxWidth: `${sizeIcon[0]}px`,
                maxHeight: `${sizeIcon[0]}px`,
                borderRadius: '50%',
                border: 'solid 2px #000',
              }}
              src={`${REACT_APP_SERVER_LINK}/media/${profilePhoto}`}
            />
          )}
        </div>
      ) : (
        <div
          role="button"
          tabIndex={0}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className={classes.editCover}
          onClick={handlerUnpload}
          onKeyDown={handleKeyDown}
        >
          {isHovered && (
            <div className={classes.contentHover}>
              <EditIcon />
              <p>Subir Foto</p>
              <input
                type="file"
                id="fileInput"
                className={classes.fileInput}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </div>
          )}
          <ProfileIcon
            w={sizeIcon[0]}
            h={sizeIcon[1]}
            bg={modeClient ? '#A6C1D3' : '#000'}
          />
        </div>
      )}
    </>
  );
}

export default Avatar;
