// GraphQl
import { gql } from 'apollo-boost';

export const LIST_PARTICIPANT_RAISING = gql`
  query listParticipantRaising($repurchase: ID!) {
    listParticipantRaising(repurchase: $repurchase) {
      edges {
        node {
          id
          numFee
          amount
          createdDate
          updatedDate
          classFund {
            id
            name
          }
          lastParticipation {
            id
            order
            state
            participationPercentage
            coParticipants {
              edges {
                node {
                  participationPercentage
                  client {
                    user {
                      firstName
                      lastName
                      maternalSurname
                    }
                  }
                }
              }
            }
          }
          repurchase {
            id
            fund {
              id
              currency {
                symbol
              }
            }
          }
          client {
            id
            user {
              firstName
              lastName
              maternalSurname
            }
          }
        }
      }
    }
  }
`;

export const GET_PARTICIPANT_RAISING = gql`
  query getParticipantRaising($id: ID!) {
    ParticipantRaising(id: $id) {
      id
      name
      numSent
      dateInitial
      dateEnd
      dateAward
      numToSend
      fundInvestors
      fundInvestorsPastWheel
      fundInvestorsOtherFunds
      interestedInvestors
    }
  }
`;

export const UPDATE_PARTICIPANT_RAISING = gql`
  mutation updateParticipantRaisingl(
    $id: ID!
    $input: ParticipantRaisingInput!
  ) {
    updateParticipantRaising(id: $id, input: $input) {
      repurchaseWheel {
        id
        name
        numSent
        dateInitial
        dateEnd
        dateAward
        numToSend
        fundInvestors
        fundInvestorsPastWheel
        fundInvestorsOtherFunds
        interestedInvestors
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_PARTICIPANT_RAISING = gql`
  mutation deleteParticipant($id: ID!) {
    deleteParticipant(id: $id) {
      participant {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const ADJUDICATE_PARTICIPANT_RAISING = gql`
  mutation adjudication($id: ID!) {
    adjudication(id: $id) {
      participation {
        id
        state
      }
    }
  }
`;

export const VERIFY_PARTICIPANT_PAYMENT = gql`
  mutation updateParticipantStatusVerify($id: ID!) {
    updateParticipantStatusVerify(id: $id) {
      participant {
        id
        state
      }
    }
  }
`;

export const PARTICIPANT_RAISING_REPORT = gql`
  query participantRaisingReport($repurchase: ID) {
    participantRepurchaseReport(repurchaseId: $repurchase) {
      csv
      filename
    }
  }
`;

export const GET_PARTICIPANT_CONTRACT = gql`
  query contractPdf($participantId: ID) {
    contractPdf(participantId: $participantId) {
      pdf
      filename
    }
  }
`;

export const REPURCHASE_ORDER = gql`
  query repurchaseOrder($repurchaseId: ID, $clientId: ID) {
    repurchaseOrder(repurchaseId: $repurchaseId, clientId: $clientId) {
      pdf
      filename
    }
  }
`;
