export const CREATE_AFFIDAVIT_RULES = JSON.stringify({
  blocks: [
    {
      key: '970h7',
      text:
        'Para colocar variables dentro del texto escribe dos puntos (:) para activar el autocompletado, escribe los primeros caracteres y recibe sugerencias para seleccionar alguna de las opciones con el formato {{ nombre_de_la_variable }} de  la siguiente lista:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'f4rf5',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8ggof',
      text: '{{ ruc_safi }}: RUC de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2ntq1',
      text: '{{ direccion_safi }}: Dirección de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'amj0e',
      text: '{{ distrito_safi }}: Distrito de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'eoku4',
      text: '{{ provincia_safi }}: Provincia de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'bkvmh',
      text: '{{ nombre_fondo }}: Nombre del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '83v7s',
      text: '{{ ruc_fondo }}: RUC del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3nrq7',
      text: '{{ direccion_fondo }}: Dirección de domicilio del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '204ls',
      text: '{{ nombre_inversionista }}: Nombre del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '24ke2',
      text:
        '{{ tipo_documento_inversionista }}: Tipo de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4kcmo',
      text:
        '{{ numero_documento_inversionista }}: Número de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'd999r',
      text: '{{ direccion_inversionista }}: Domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '7auic',
      text:
        '{{ distrito_inversionista }}: Distrito de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'c374d',
      text:
        '{{ provincia_inversionista }}: Provincia de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2kdgq',
      text:
        '{{ departamento_inversionista }}: Departamento de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '6j5iq',
      text: '{{ pais_inversionista }}: País de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'austv',
      text: '{{ nombre_conyuge }}: Nombre del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8tqn5',
      text: '{{ tipo_documento_conyuge }}: Tipo de documento del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1hihd',
      text: '{{ numero_documento_conyuge }}: Número de documento del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'b81km',
      text:
        '{{ nombre_representante }}: Nombre del representante en la coparticipación ',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2idf2',
      text: '{{ nombre_apoderado }}: Nombre del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8uqjh',
      text: '{{ tipo_documento_apoderado }}: Tipo de documento del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3gjbr',
      text:
        '{{ numero_documento_apoderado }}: Número de documento del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'dggop',
      text:
        '{{ numero_poder_apoderado }}: Número de la partida electronica del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '9o90d',
      text:
        '{{ cuotas_levantamiento }}: Cuotas que ha registrado en el levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'ek7c4',
      text:
        '{{ valor_cuota_nominal_fondo }}: Valor nominal del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'eudn',
      text: '{{ valor_cuota_levantamiento }}: Valor cuota del levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'con9n',
      text:
        '{{ monto_levantamiento }}: Monto a pagar en el levantamiento en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'bjrut',
      text: '{{ banco_fondo }}: Banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '6u26f',
      text: '{{ tipo_cuenta_fondo }}: Tipo de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'lh50',
      text: '{{ tipo_moneda_fondo }}: Tipo de moneda de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'e1m8t',
      text: '{{ numero_cuenta_fondo }}: Número de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '29uni',
      text: '{{ cci_fondo }}: CCI de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '9qnl1',
      text: '{{ swift_fondo }}: SWIFT de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '58h9p',
      text:
        '{{ inversion_minima_fondo }}: Inversión mínima del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8l0gp',
      text:
        '{{ inversion_maxima_fondo }}: Inversión máxima del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '51v5u',
      text: '{{ fecha_emision_contrato }}: Fecha de emisión del contrato',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '210yo',
      text: '{{ numeric_section }}: Lista de Numeros Ordinales',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'cs0y2',
      text: '{{ numeric_subsection }}: Lista Numerica de Subsecciones',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
});

export const CONDITIONS = [
  {
    value: 'N',
    label: 'Persona natural',
    enum: 'NATURAL_PERSON',
  },
  {
    value: 'P',
    label: 'Sociedad de gananciales',
    enum: 'PARTNERSHIP',
  },
  {
    value: 'T',
    label: 'Apoderado',
    enum: 'TUTOR',
  },
];
