import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import {
  GET_SAFI_RELATION_CLIENT,
  UPDATE_CLIENT_SAFI_INFORMATION,
  GET_SAFI_RELATION_SPOUSE,
} from '@queries/Client';
import { LIST_SAFI_RELATIONS } from '@queries/Safi';
import Yup from '../../../../utils/yup';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { LIST_FUNDS } from '../../../Models/Fund/queries';
import {
  edgeToList,
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import {
  setAttorneyAction,
  setAnnexBAction,
  setClientPEPAction,
  setSpousePEPAction,
  setRelativePEPAction,
  setStatus,
  setMissingSteps,
} from '../../../../containers/ModelsPages/ClientForm/actions';
import { FORM_OPTIONS } from '../../constants';
import {
  MENU_ITEMS_ID,
  NODE_STATES,
} from '../../../../containers/ModelsPages/ClientForm/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import {
  getClientPEPSelector,
  getSpousePEPSelector,
  getRelativePEPSelector,
  getMissingStepsSelector,
} from '../../../../containers/ModelsPages/ClientForm/selectors';

import Tooltip from '@material-ui/core/Tooltip';
import ClientTexts from '../../../FormComponents/ClientTexts';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function SafiRelationClient(props) {
  const { hasAttorney, clientID } = props;
  const [pep, setPep] = useState([false, false, false]);
  const [vinculation, setVinculation] = useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const clientPEP = useSelector(getClientPEPSelector());
  const spousePEP = useSelector(getSpousePEPSelector());
  const relativePEP = useSelector(getRelativePEPSelector());
  const missingSteps = useSelector(getMissingStepsSelector());

  useEffect(() => {
    const newPep = [clientPEP, spousePEP, relativePEP];
    setPep(newPep);
  }, [clientPEP, relativePEP, spousePEP]);

  // Click Help
  const handleTooltipClose = () => {
    setOpenHelp(false);
  };

  const handleTooltipOpen = () => {
    setOpenHelp(true);
  };

  // RELATED QUERIES
  const { error: FundsError, data: FundsData } = useQuery(LIST_FUNDS);
  const { error: safiRelationError, data: safiRelationData } = useQuery(
    LIST_SAFI_RELATIONS,
  );
  const {
    error: clientError,
    data: clientData,
  } = useQuery(GET_SAFI_RELATION_CLIENT, { variables: { id: clientID } });
  const {
    error: spouseError,
    data: spousetData,
  } = useQuery(GET_SAFI_RELATION_SPOUSE, { variables: { id: clientID } });

  const [updateClientSpouse] = useMutation(UPDATE_CLIENT_SAFI_INFORMATION);
  const updateClient = useMutation(UPDATE_CLIENT_SAFI_INFORMATION, {
    onError(error) {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors) {
        errorMessage(graphQLErrors, enqueueSnackbar);
      }
      if (networkError) {
        errorMessage(networkError, enqueueSnackbar);
      }
    },
    async onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        if (spousetData && spousetData.client.spouse) {
          await updateClientSpouse({
            variables: {
              id: spousetData.client.spouse.id,
              input: { pep: pep[1], spousePep: pep[0] },
            },
          });
        }
        successMessage('Se guardó Condición PEP.', enqueueSnackbar);
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_SAFI_INFORMATION, {
    onError(error) {
      const { graphQLErrors, networkError } = error;
      if (graphQLErrors) {
        errorMessage(graphQLErrors, enqueueSnackbar);
      }
      if (networkError) {
        errorMessage(networkError, enqueueSnackbar);
      }
    },
    async onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        if (spousetData && spousetData.client.spouse) {
          await updateClientSpouse({
            variables: {
              id: spousetData.client.spouse.id,
              input: { pep: pep[1], spousePep: pep[0] },
            },
          });
        }
        successMessage(
          'Se guardó Condición PEP parcialmente.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  if (FundsError) {
    console.error('Safi relation - Funds', FundsError);
  }

  if (safiRelationError) {
    console.error('Safi relation - safi relations');
  }

  function onAttorneyChange(event) {
    event.preventDefault();
    if (event.target.value && event.target.value === 'true') {
      dispatch(setAttorneyAction(true));
    } else if (event.target.value && event.target.value === 'false') {
      dispatch(setAttorneyAction(false));
    }
    const steps = [...missingSteps];
    if (event.target.value === 'true') {
      steps.push(MENU_ITEMS_ID.AttorneyPersonalData);
      steps.push(MENU_ITEMS_ID.AttorneyLegalData);
      steps.push(MENU_ITEMS_ID.AttorneyLabourData);
    } else {
      let index = steps.indexOf(MENU_ITEMS_ID.AttorneyPersonalData);
      if (index > -1) {
        steps.splice(index, 1);
      }
      index = steps.indexOf(MENU_ITEMS_ID.AttorneyLegalData);
      if (index > -1) {
        steps.splice(index, 1);
      }
      index = steps.indexOf(MENU_ITEMS_ID.AttorneyLabourData);
      if (index > -1) {
        steps.splice(index, 1);
      }
    }
    dispatch(setMissingSteps(steps));
  }

  function onPEPChange(event, indx) {
    event.preventDefault();
    const newPEP = [...pep];

    if (event.target.value && event.target.value === 'true') {
      newPEP[indx] = true;
    } else {
      newPEP[indx] = false;
    }

    const find = newPEP.findIndex(i => i);
    if (find === -1) dispatch(setAnnexBAction(false));
    setPep(newPEP);
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const validationSchema = Yup.object().shape({
    // funds: Yup.array().required(),
    // otherSafiRelation: Yup.string().when('relations', {
    //   is: safiRelationValues => {
    //     if (safiRelationValues.findIndex(i => i === 'other') !== -1)
    //       return true;
    //     return false;
    //   },
    //   then: Yup.string().required(),
    // }),
    // registerNumber: Yup.string().when({
    //   is: () => hasAttorney,
    //   then: Yup.string().required(),
    // }),
    // seatNumber: Yup.string().when({
    //   is: () => hasAttorney,
    //   then: Yup.string().required(),
    // }),
    // departureRegistrationCounty: Yup.string().when({
    //   is: () => hasAttorney,
    //   then: Yup.string().required(),
    // }),
    // relations: Yup.array()
    //   .required()
    //   .test('test_option_no_vinculation', '', function(value) {
    //     //Get id for option No relation
    //     const isType = element => element.typeRelation == 'A_0';
    //     const listV = edgeToList(safiRelationData, 'listSafiRelations');
    //     const idNoVinc = listV.findIndex(isType);
    //     if (value && value.includes(listV[idNoVinc].id)) {
    //       if (value.length > 1) {
    //         return this.createError({
    //           message: `Debe marcar alguna opción o indicar que no tiene vinculación.`,
    //         });
    //       } else {
    //         return true;
    //       }
    //     }
    //     setVinculation(true);
    //     return true;
    //   }),
  });

  let startValues = {
    funds: [],
    otherFund: '',
    hasVinculation: 'false',
    relations: [],
    otherSafiRelation: '',
    institutionalInvestor: 'false',
    pep: 'false',
    spousePep: 'false',
    relativePep: 'false',
    dataProcessingAuthorization: 'false',
    assignee: 'false',
    registerNumber: '',
    seatNumber: '',
    departureRegistrationCounty: '',
  };

  if (clientData) {
    const {
      funds,
      relations,
      otherRelation,
      otherFund,
      user,
      ...currentData
    } = clientData.client;

    currentData.funds = funds.edges
      .filter(val => val.node.state)
      .map(val => val.node.id);
    currentData.relations = relations.edges.map(val => val.node.id);
    currentData.hasVinculation = (currentData.relations.length > 0).toString();

    if (otherRelation) {
      currentData.relations.push('other');
    }

    if (otherFund) {
      currentData.funds.push('otherFund');
    }

    currentData.otherSafiRelation = otherRelation;
    currentData.otherFund = otherFund;
    currentData.institutionalInvestor = (
      currentData.institutionalInvestor || false
    ).toString();
    currentData.spousePep = (currentData.spousePep || false).toString();
    currentData.relativePep = (currentData.relativePep || false).toString();
    currentData.dataProcessingAuthorization = (
      currentData.dataProcessingAuthorization || false
    ).toString();
    currentData.assignee = (!!currentData.assignee).toString();
    currentData.pep = (currentData.pep || false).toString();
    currentData.departureRegistrationCounty = currentData.departureRegistrationCounty
      ? currentData.departureRegistrationCounty.id
      : '';
    startValues = { ...currentData };
  }

  useEffect(() => {
    if (clientData) {
      setVinculation(clientData.client.relations.edges.length > 0);
    }
  }, [clientData]);

  const data = [
    // {
    //   isField: false,
    //   section:
    //     'Sobre su relación con los Fondos de Inversión de Core Capital SAF',
    //   fields: [
    //     {
    //       name: 'fundRelationText',
    //       text:
    //         'A continuación ** indique el nombre de los fondos de inversión en los que posee participaciones (Puedes marcar mas de una opción): ',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'relation',
    //     },
    //     {
    //       label: '',
    //       name: 'funds',
    //       data: edgeToList(FundsData, 'listFunds').filter(
    //         item => item?.state === true,
    //       ),
    //       mapData: { value: 'id', label: 'businessName' },
    //       inputVariant: INPUT_VARIANT.checkbox,
    //       other: false,
    //       otherFund: true,
    //       gridMD: 12,
    //     },
    //   ],
    // },
    // {
    //   isField: false,
    //   section:
    //     'Vinculación con Core Capital SAF o empresas de su Grupo Económico',
    //   fields: [
    //     {
    //       name: 'hasVinculation',
    //       text:
    //         '¿Tiene usted alguno de los siguientes tipos de vinculación con Core Capital SAF o empresas de su Grupo Económico?',
    //       gridMD: 11,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'relation',
    //     },
    //     {
    //       inputVariant: INPUT_VARIANT.node,
    //       node: (
    //         <>
    //           <ClickAwayListener onClickAway={handleTooltipClose}>
    //             <div align="right">
    //               <Tooltip
    //                 PopperProps={{
    //                   disablePortal: true,
    //                 }}
    //                 onClose={handleTooltipClose}
    //                 open={openHelp}
    //                 disableFocusListener
    //                 disableHoverListener
    //                 disableTouchListener
    //                 placement="top"
    //                 title={<ClientTexts type="relation" />}
    //               >
    //                 <IconButton
    //                   variant="contained"
    //                   color="secondary"
    //                   aria-label="close"
    //                   onClick={handleTooltipOpen}
    //                 >
    //                   <HelpIcon fontSize="inherit" />
    //                 </IconButton>
    //               </Tooltip>
    //             </div>
    //           </ClickAwayListener>
    //         </>
    //       ),
    //       alignItems: 'center',
    //       gridMD: 1,
    //       name: 'helpVinculation',
    //     },
    //     {
    //       label: '',
    //       name: 'relations',
    //       data: edgeToList(safiRelationData, 'listSafiRelations'),
    //       mapData: { value: 'id', label: 'name' },
    //       inputVariant: INPUT_VARIANT.checkbox,
    //       other: true,
    //       gridMD: 12,
    //     },
    //     {
    //       name: 'fundRelationText',
    //       text:
    //         'Base Legal: Artículo 7 de la Resolución SMV N° 019-2015-SMV-01.',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'relation',
    //     },
    //   ],
    // },
    // {
    //   isField: false,
    //   section: 'Calidad de Inversionista Institucional',
    //   fields: [
    //     {
    //       name: 'institutionalInvestor',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.radio,
    //       data: FORM_OPTIONS.yesNo,
    //     },
    //     {
    //       name: 'institutionalInvestorText',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'institutionalInvestor',
    //     },
    //   ],
    // },
    {
      isField: false,
      section: 'Persona Expuesta Políticamente (PEP)',
      fields: [
        {
          name: 'pepText',
          text: 'En los 5 últimos años',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.paragraph,
        },
        {
          label: '¿Ha sido o es Persona Expuesta Políticamente (PEP)?',
          name: 'pep',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
          onChange: event => onPEPChange(event, 0),
        },
        // {
        //   label: '¿Su cónyuge ha sido o es PEP?',
        //   name: 'spousePep',
        //   gridMD: 12,
        //   inputVariant: INPUT_VARIANT.radio,
        //   data: FORM_OPTIONS.yesNo,
        //   onChange: event => onPEPChange(event, 1),
        // },
        {
          label:
            '¿Algún pariente ha sido o es PEP? (Cónyuge, Padre, Madre, Hijo, Abuelo, Hermano, Nieto de usted o de su cónyuge)',
          name: 'relativePep',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
          onChange: event => onPEPChange(event, 2),
        },
        {
          name: 'pepTextBot',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.paragraph,
          type: 'pep',
        },
      ],
    },
    // {
    //   isField: false,
    //   section:
    //     '¿Autoriza el tratamiento de sus datos personales para finalidades adicionales?',
    //   fields: [
    //     {
    //       name: 'dataProcessingAuthorization',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.radio,
    //       data: FORM_OPTIONS.yesNo,
    //     },
    //     {
    //       name: 'authorizationText',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //       type: 'dataProcessingAuthorization',
    //     },
    //   ],
    // },
    // {
    //   isField: false,
    //   section: 'Apoderado o mandatorio',
    //   fields: [
    //     {
    //       name: 'attorneyText',
    //       text:
    //         '¿Posee usted algún apoderado o mandatorio para efectos de las gestiones relativas a su participación en los Fondos de Inversión que administra Core Capital SAF S.A.?',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //     },
    //     {
    //       name: 'assignee',
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.radio,
    //       data: FORM_OPTIONS.yesNo,
    //       onChange: onAttorneyChange,
    //     },
    //   ],
    // },
  ];

  if (hasAttorney) {
    data[data.length - 1].fields = data[data.length - 1].fields.concat([
      {
        isField: true,
        label: 'N° de Ficha o Partida Registral del Poder',
        name: 'registerNumber',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        isField: true,
        label: 'N° de Asiento',
        name: 'seatNumber',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        isField: true,
        label: 'Departamento registro partida',
        name: 'departureRegistrationCounty',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.peruCounties,
      },
    ]);
  }

  function formatBeforeSubmit(values) {
    const { otherSafiRelation, otherFund, ...newValues } = values;
    const find = newValues.relations.findIndex(i => i === 'other');
    const findFund = newValues.funds.findIndex(i => i === 'otherFund');

    if (find !== -1) {
      newValues.relations.splice(find, 1);
    }
    if (!vinculation) {
      newValues.relations = [];
    }
    if (findFund !== -1) {
      newValues.funds.splice(find, 1);
    }
    delete newValues.countyRegister;
    delete newValues.hasVinculation;

    dispatch(setClientPEPAction(newValues.pep === 'true'));
    dispatch(setSpousePEPAction(newValues.spousePep === 'true'));
    dispatch(setRelativePEPAction(newValues.relativePep === 'true'));

    newValues.assignee = newValues.assignee === 'true';
    newValues.pep = newValues.pep === 'true';
    newValues.institutionalInvestor =
      newValues.institutionalInvestor === 'true';
    newValues.spousePep = newValues.spousePep === 'true';
    newValues.relativePep = newValues.relativePep === 'true';
    newValues.dataProcessingAuthorization =
      newValues.dataProcessingAuthorization === 'true';
    newValues.otherRelation = otherSafiRelation;
    newValues.otherFund = otherFund;

    const findPEP = pep.findIndex(i => i);
    if (findPEP === -1) {
      dispatch(setAnnexBAction(false));
    } else {
      dispatch(setAnnexBAction(true));
    }

    return newValues;
  }

  return (
    <>
      {clientData && !clientError ? (
        <StepsForm
          initialValues={startValues}
          validateFunction={validationFunction}
          validateSchema={validationSchema}
          id={clientID}
          mainClientID={clientID}
          data={data}
          title="Condición de Persona Políticamente Expuesta (PEP)"
          nextForm={
            clientPEP
              ? MENU_ITEMS_ID.ClientPEP
              : relativePEP
              ? MENU_ITEMS_ID.RelativePEP
              : MENU_ITEMS_ID.ClientTermsConditions
          }
          updateMutation={updateClient}
          partialUpdateMutation={partialUpdateClient}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      ) : (
        <LoadingIndicator />
      )}
    </>
  );
}

SafiRelationClient.propTypes = {
  hasAttorney: PropTypes.bool.isRequired,
  clientID: PropTypes.string.isRequired,
};

export default SafiRelationClient;
