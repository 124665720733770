import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: theme.spacing(0.5),
    '& .MuiButton-label': {
      color: theme.palette.primary.main,
    },
  },
  optionIcon: {
    fontSize: '1.8rem',
  },
  modalIframe: {
    width: '100%',
    height: 800,
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(2),
    },
  },
  buttonSuccess: {
    backgroundColor: theme.palette.colors.green,
    display: 'block',
    margin: theme.spacing(1),
  },
  check: {
    color: theme.palette.colors.white,
  },
  buttonResend: {
    margin: '0 1rem 0 auto',
  },
  modalContent: {
    padding: '2rem',
    width: '100%',
    justifyContent: 'center',
    '& p': {
      textAlign: 'justify',
    },
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      margin: '0 .5rem',
    },
  },
}));
