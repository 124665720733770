import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getClientSelector } from 'src/store/sharedStore/selectors';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';

import useStyles from './styles';
import { textLabels } from '../../../translations/components/MUIDataTable';
import {
  LIST_S3FILES_BY_CLIENT,
  LIST_S3FILES_BY_EMPLOYEE,
  LIST_TYPES_CHOICES,
  LIST_TYPES_CHOICES_EMPLOYEE,
  FILE_DOWNLOAD,
} from '../Files/queries';
import {
  LIST_S3FILES,
  LIST_CLASSIFICATION_CHOICES,
} from '../EmployeeFiles/queries';
import {
  generateColumn,
  edgeToList,
  errorMessage,
  successMessage,
} from '../../../utils/commonFunctions';
import LoadingIndicator from '../../LoadingIndicator';
import PDFViewerModal from '../../PDFViewerModal';
import { saveAs } from 'file-saver';

function Documents(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = useSelector(getClientSelector());
  const [loading, setLoading] = useState(false);
  const { error: errorClient, data: dataClient } = useQuery(
    LIST_S3FILES_BY_CLIENT,
    {
      variables: { clientId: client?.id },
    },
  );
  const { error: errorEmployee, data: dataEmployee } = useQuery(
    LIST_S3FILES_BY_EMPLOYEE,
    {
      variables: { clientId: client?.id },
    },
  );
  const { error: errorGeneric, data: dataGeneric } = useQuery(LIST_S3FILES);
  const [classificationList, setClassificationList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isEmployee, setIsEmployee] = useState(client.isEmployee);
  const [url, setUrl] = useState('');
  const { data: ClassificationData } = useQuery(LIST_CLASSIFICATION_CHOICES);
  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES);
  const {
    loading: TypesLoadingEmployee,
    error: TypesErrorEmployee,
    data: TypesDataEmployee,
  } = useQuery(LIST_TYPES_CHOICES_EMPLOYEE);

  const [downloadFile, { loadingFile, dataFile }] = useMutation(FILE_DOWNLOAD, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (!!data && !isDownload) {
        setUrl(data?.fileDownload?.url);
        setShowModal(true);
      }
      if (!!data && isDownload) {
        setShowModal(false);
        saveAs(data?.fileDownload?.url, data?.fileDownload?.fileName);
        successMessage('Archivo descargado.', enqueueSnackbar);
      }
    },
  });

  useEffect(() => {
    if (!!dataClient && !isEmployee) {
      let dataTmp = [];
      dataTmp = edgeToList(dataClient, 'listS3FilesByClient').filter(
        f => f?.fileType != 'FR',
      );
      setFilesList(dataTmp);
      if (!!TypesData) setTypeList(TypesData.typesChoices);
    } else if (!!dataEmployee && isEmployee) {
      let dataTmp = [];
      dataTmp = edgeToList(dataEmployee, 'listS3FilesByEmployee').filter(
        f => f?.state && !f?.isGeneric && f?.fileType != 'PP',
      );
      setFilesList(dataTmp);
      if (!!TypesDataEmployee)
        setTypeList(TypesDataEmployee.typesChoicesEmployee);
    } else {
      setFilesList([]);
      setTypeList([]);
    }
    if (!!ClassificationData) {
      setClassificationList(ClassificationData.classificationChoices);
    }
  }, [
    dataClient,
    TypesData,
    dataEmployee,
    TypesDataEmployee,
    dataGeneric,
    ClassificationData,
  ]);

  const getUrl = (id, isDownload) => {
    downloadFile({
      variables: { id, isDownload },
    });
  };

  let columns = [];
  if (filesList && filesList.length > 0 && !isEmployee) {
    columns = [
      generateColumn('Fondo', 'fund', {
        customBodyRender: value => {
          if (value) return value?.businessName;
          else return '-';
        },
      }),
      generateColumn('Bono', 'bond', {
        customBodyRender: value => {
          if (value)
            return (
              value?.name +
              ' ' +
              value?.program +
              ' ' +
              value?.release +
              ' ' +
              value?.className
            );
          else return '-';
        },
      }),
      generateColumn('Tipo', 'fileType', {
        customBodyRender: value => {
          let name;
          typeList.map(item => {
            if (item?.value == value) {
              name = item.name;
            }
          });
          return name;
        },
      }),
      generateColumn('Documento', 'fileName', {
        customBodyRender: value => {
          return value;
        },
      }),
      generateColumn('Fecha', 'uploadedAt', {
        customBodyRender: value => moment(value).format('DD-MM-YYYY hh:mm'),
        display: true,
      }),
      {
        name: 'id',
        label: 'Opciones',
        options: {
          sort: false,
          customBodyRender: value => {
            const id = value;
            return (
              <>
                <Tooltip title="Visualizar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(false);
                      getUrl(id, false);
                    }}
                    className={classes.optionButtons}
                  >
                    <PageviewOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(true);
                      getUrl(id, true);
                    }}
                    className={classes.optionButtons}
                  >
                    <CloudDownloadIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
          customHeadRender: columnMeta => {
            return (
              <TableCell key={7} style={{ borderBottom: 'none' }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.tableTitle}
                >
                  {columnMeta.label}
                </Typography>
              </TableCell>
            );
          },
        },
      },
    ];
  }

  if (filesList && filesList.length > 0 && isEmployee) {
    columns = [
      generateColumn('Clasificación', 'classification', {
        customBodyRender: value => {
          return classificationList?.find(item => item.value == value)?.name;
        },
      }),
      generateColumn('Tipo', 'fileType', {
        customBodyRender: value => {
          let name;
          typeList.map(item => {
            if (item?.value == value) {
              name = item.name;
            }
          });
          return name;
        },
      }),
      generateColumn('Documento', 'fileName', {
        customBodyRender: value => {
          return value;
        },
      }),
      generateColumn('Fecha', 'uploadedAt', {
        customBodyRender: value => moment(value).format('DD-MM-YYYY hh:mm'),
        display: true,
      }),
      {
        name: 'id',
        label: 'Opciones',
        options: {
          sort: false,
          customBodyRender: value => {
            const id = value;
            return (
              <>
                <Tooltip title="Visualizar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(false);
                      getUrl(id, false);
                    }}
                    className={classes.optionButtons}
                  >
                    <PageviewOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(true);
                      getUrl(id, true);
                    }}
                    className={classes.optionButtons}
                  >
                    <CloudDownloadIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
          customHeadRender: columnMeta => {
            return (
              <TableCell key={7} style={{ borderBottom: 'none' }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.tableTitle}
                >
                  {columnMeta.label}
                </Typography>
              </TableCell>
            );
          },
        },
      },
    ];
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
  };

  return (
    <div style={{ marginTop: '1.5em' }}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MUIDataTable
            title="Repositorio Personal"
            data={filesList}
            columns={columns}
            options={options}
          />
          <PDFViewerModal
            showModal={showModal}
            onClosePress={() => setShowModal(false)}
            fileURL={url}
            loading={loadingFile}
          />
        </>
      )}
    </div>
  );
}
export default Documents;
