export const GET_ACTIVE_FORM =
  'core-capital/ModelsPages/ClientForm/GET_ACTIVE_FORM';
export const SET_ACTIVE_FORM =
  'core-capital/ModelsPages/ClientForm/SET_ACTIVE_FORM';

export const GET_IS_MARRIED =
  'core-capital/ModelsPages/ClientForm/GET_IS_MARRIED';
export const SET_IS_MARRIED =
  'core-capital/ModelsPages/ClientForm/SET_IS_MARRIED';

export const GET_IS_SOCIETY =
  'core-capital/ModelsPages/ClientForm/GET_IS_SOCIETY';
export const SET_IS_SOCIETY =
  'core-capital/ModelsPages/ClientForm/SET_IS_SOCIETY';

export const GET_HAS_ATTORNEY =
  'core-capital/ModelsPages/ClientForm/GET_HAS_ATTORNEY';
export const SET_HAS_ATTORNEY =
  'core-capital/ModelsPages/ClientForm/SET_HAS_ATTORNEY';

export const GET_ANNEX_B = 'core-capital/ModelsPages/ClientForm/GET_ANNEX_B';
export const SET_ANNEX_B = 'core-capital/ModelsPages/ClientForm/SET_ANNEX_B';

export const GET_CLIENTPEP =
  'core-capital/ModelsPages/ClientForm/GET_CLIENTPEP';
export const SET_CLIENTPEP =
  'core-capital/ModelsPages/ClientForm/SET_CLIENTPEP';

export const GET_SPOUSEPEP =
  'core-capital/ModelsPages/ClientForm/GET_SPOUSEPEP';
export const SET_SPOUSEPEP =
  'core-capital/ModelsPages/ClientForm/SET_SPOUSEPEP';

export const GET_RELATIVEPEP =
  'core-capital/ModelsPages/ClientForm/GET_RELATIVEPEP';
export const SET_RELATIVEPEP =
  'core-capital/ModelsPages/ClientForm/SET_RELATIVEPEP';

export const SET_ACTIVE = 'core-capital/ModelsPages/ClientForm/SET_ACTIVE';
export const SET_VISIBLE = 'core-capital/ModelsPages/ClientForm/SET_VISIBLE';
export const SET_STATUS = 'core-capital/ModelsPages/ClientForm/SET_STATUS';

export const SET_EDITION = 'core-capital/ModelsPages/ClientForm/SET_EDITION';

export const SET_IS_PERUVIAN =
  'core-capital/ModelsPages/ClientForm/SET_IS_PERUVIAN';

export const SET_MISSING_STEPS =
  'core-capital/ModelsPages/ClientForm/SET_MISSING_STEPS';

export const MENU_ITEMS_ID = {
  GeneralDataNode: 'GC',
  ClientPersonalData: 'GC-PED',
  ClientLegalData: 'GC-LED',
  ClientLabourData: 'GC-LAD',
  ClientSafiRelation: 'GC-SAR',
  SpousePartnerNode: 'GC-SP',
  SpousePartnerPersonalData: 'GC-SP-PED',
  SpousePartnerLegalData: 'GC-SP-LED',
  SpousePartnerLaborData: 'GC-SP-LAD',
  SpousePartnerSafiRelation: 'GC-SP-SAR',
  AttorneyClientNode: 'GC-PA',
  AttorneyLegalData: 'GC-PA-LED',
  AttorneyLabourData: 'GC-PA-LAD',
  AttorneyPersonalData: 'GC-PA-PED',
  // ClientBankData: 'BD',
  // AnnexA: 'AA',
  // InvestorKnowledge: 'AA-INV',
  // AnexARiskProfile: 'AA-IRP',
  AnnexB: 'AB',
  ClientPEP: 'AB-CPP',
  SpousePEP: 'AB-SPP',
  RelativePEP: 'AB-RPP',
  ClientTermsConditions: 'TC',
};

export const NODE_STATES = {
  complete: 1,
  incomplete: 2,
};

export const INIT_DATA = [
  {
    name: 'Datos generales del cliente',
    id: MENU_ITEMS_ID.GeneralDataNode,
    depth: 0,
    active: false,
    visible: true,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos personales',
    id: MENU_ITEMS_ID.ClientPersonalData,
    depth: 1,
    active: true,
    visible: true,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos legales',
    id: MENU_ITEMS_ID.ClientLegalData,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos del cónyuge o conviviente',
    id: MENU_ITEMS_ID.SpousePartnerNode,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos personales',
    id: MENU_ITEMS_ID.SpousePartnerPersonalData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos legales',
    id: MENU_ITEMS_ID.SpousePartnerLegalData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos laborales',
    id: MENU_ITEMS_ID.SpousePartnerLaborData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Relación con la SAF',
    id: MENU_ITEMS_ID.SpousePartnerSafiRelation,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos laborales',
    id: MENU_ITEMS_ID.ClientLabourData,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Condición de Persona Políticamente Expuesta (PEP)',
    id: MENU_ITEMS_ID.ClientSafiRelation,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos del apoderado o mandatorio',
    id: MENU_ITEMS_ID.AttorneyClientNode,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos personales',
    id: MENU_ITEMS_ID.AttorneyPersonalData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos legales',
    id: MENU_ITEMS_ID.AttorneyLegalData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  {
    name: 'Datos laborales',
    id: MENU_ITEMS_ID.AttorneyLabourData,
    depth: 2,
    active: false,
    visible: false,
    status: NODE_STATES.incomplete,
  },
  // {
  //   name: 'Datos bancarios',
  //   id: MENU_ITEMS_ID.ClientBankData,
  //   depth: 0,
  //   active: false,
  //   visible: false,
  //   status: NODE_STATES.incomplete,
  // },
  // {
  //   name: 'Anexo A: Conocimiento del cliente',
  //   id: MENU_ITEMS_ID.AnnexA,
  //   depth: 0,
  //   active: false,
  //   visible: false,
  //   status: NODE_STATES.incomplete,
  // },
  // {
  //   name: 'Conocimiento del cliente como inversionista',
  //   id: MENU_ITEMS_ID.InvestorKnowledge,
  //   depth: 1,
  //   active: false,
  //   visible: false,
  //   status: NODE_STATES.incomplete,
  // },
  // {
  //   name: 'Perfil de riesgo del inversionista',
  //   id: MENU_ITEMS_ID.AnexARiskProfile,
  //   depth: 1,
  //   active: false,
  //   visible: false,
  //   status: NODE_STATES.incomplete,
  // },
  {
    name: 'Anexo A',
    id: MENU_ITEMS_ID.AnnexB,
    depth: 0,
    active: false,
    visible: false,
    status: NODE_STATES.complete,
  },
  {
    name: 'Titular PEP',
    id: MENU_ITEMS_ID.ClientPEP,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.complete,
  },
  {
    name: 'Cónyuge PEP',
    id: MENU_ITEMS_ID.SpousePEP,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.complete,
  },
  {
    name: 'Pariente PEP',
    id: MENU_ITEMS_ID.RelativePEP,
    depth: 1,
    active: false,
    visible: false,
    status: NODE_STATES.complete,
  },
  {
    name: 'Términos y condiciones',
    id: MENU_ITEMS_ID.ClientTermsConditions,
    depth: 0,
    active: false,
    visible: false,
    status: NODE_STATES.complete,
  },
];
