import React from 'react';

function BuhoIcon(props) {
  const { color } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="40"
      fill="none"
      viewBox="0 0 30 49"
    >
      <path
        fill={color}
        d="M30 34.17C30 42.358 14.997 49 14.997 49S.247 42.605 0 34.63l.008-17.276h.106c1.348 7.51 14.891 13.38 14.891 13.38s13.263-5.87 14.845-13.38h.146V34.17H30z"
      />
      <path
        fill={color}
        d="M22.488 18.166c1.865 0 3.377-1.494 3.377-3.337 0-1.843-1.512-3.337-3.377-3.337-1.864 0-3.376 1.494-3.376 3.337 0 1.843 1.512 3.337 3.376 3.337zM7.512 18.166c1.865 0 3.377-1.494 3.377-3.337 0-1.843-1.512-3.337-3.377-3.337-1.865 0-3.376 1.494-3.376 3.337 0 1.843 1.511 3.337 3.376 3.337zM17.174 20.314l-2.177 5.978-2.181-5.982c.825-.816 1.53-1.827 1.925-2.953h.518c.14.407.32.795.53 1.165a8.31 8.31 0 001.387 1.794l-.002-.002zM29.357 3.325l-1.37 4.09h-5.493c-3.524 0-6.478 2.4-7.283 5.634h-.43c-.805-3.23-3.758-5.629-7.275-5.635H2L.63 3.324h4.898A15.04 15.04 0 0114.997 0c3.59 0 6.88 1.245 9.462 3.325h4.898z"
      />
    </svg>
  );
}

export default BuhoIcon;
