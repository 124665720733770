export const CREATE_CONTRACT_RULES = JSON.stringify({
  blocks: [
    {
      key: 'fcrf5',
      text:
        'Para colocar variables dentro del texto escribe dos puntos (:) para activar el autocompletado, escribe los primeros caracteres y recibe sugerencias para seleccionar alguna de las opciones con el formato {{ nombre_de_la_variable }} de  la siguiente lista:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2u93l',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'b7108',
      text: '{{ ruc_safi }}: RUC de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2ntq7',
      text: '{{ direccion_safi }}: Dirección de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '55pl8',
      text: '{{ distrito_safi }}: Distrito de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'dcmu5',
      text: '{{ provincia_safi }}: Provincia de domicilio de la SAF',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8s0t4',
      text: '{{ nombre_fondo }}: Nombre del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2ntq9',
      text: '{{ ruc_fondo }}: RUC del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1ntvt',
      text: '{{ direccion_fondo }}: Dirección de domicilio del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '5olna',
      text: '{{ nombre_inversionista }}: Nombre del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'd8u2f',
      text:
        '{{ tipo_documento_inversionista }}: Tipo de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'dfeoj',
      text:
        '{{ numero_documento_inversionista }}: Número de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '9v763',
      text: '{{ direccion_inversionista }}: Domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '25mkb',
      text:
        '{{ distrito_inversionista }}: Distrito de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'slpb',
      text:
        '{{ provincia_inversionista }}: Provincia de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2lejp',
      text:
        '{{ departamento_inversionista }}: Departamento de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'a4btc',
      text: '{{ pais_inversionista }}: País de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1kh0d',
      text: '{{ nombre_conyuge }}: Nombre del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'anhuu',
      text: '{{ tipo_documento_conyuge }}: Tipo de documento del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3bqup',
      text: '{{ numero_documento_conyuge }}: Número de documento del cónyugue',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '55rbn',
      text:
        '{{ nombre_representante }}: Nombre del representante en la coparticipación ',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4t6ho',
      text: '{{ nombre_apoderado }}: Nombre del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2njd2',
      text: '{{ tipo_documento_apoderado }}: Tipo de documento del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4ch7d',
      text:
        '{{ numero_documento_apoderado }}: Número de documento del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3bs0o',
      text:
        '{{ numero_poder_apoderado }}: Número de la partida electronica del apoderado',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'fi85p',
      text: '{{ cuotas_levantamiento }}: Cuotas del levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '9irpr',
      text:
        '{{ valor_cuota_nominal_fondo }}: Valor nominal del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '9e9oe',
      text: '{{ valor_cuota_levantamiento }}: Valor cuota del levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4joic',
      text: '{{ monto_levantamiento }}: Monto del levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4joir',
      text:
        '{{ cuotas_participacion }}: Cuotas que ha registrado el participe en el levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4joiy',
      text:
        '{{ monto_participacion }}: Monto a pagar por el participe en el levantamiento',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'cn8v5',
      text: '{{ banco_fondo }}: Banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'cl1ss',
      text: '{{ tipo_cuenta_fondo }}: Tipo de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8gdnn',
      text: '{{ tipo_moneda_fondo }}: Tipo de moneda de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'e3ppe',
      text: '{{ numero_cuenta_fondo }}: Número de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'eqtlg',
      text: '{{ cci_fondo }}: CCI de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2gm4n',
      text: '{{ swift_fondo }}: SWIFT de cuenta de banco del fondo',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '2pci7',
      text:
        '{{ inversion_minima_fondo }}: Inversión mínima del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'foa9q',
      text:
        '{{ inversion_maxima_fondo }}: Inversión máxima del fondo en números y letras',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '344ro',
      text: '{{ fecha_emision_contrato }}: Fecha de emisión del contrato',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '210yo',
      text: '{{ numeric_section }}: Lista de Numeros Ordinales',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'cs0y2',
      text: '{{ numeric_subsection }}: Lista Numerica de Subsecciones',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'cslh5',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'ema8c',
      text:
        'Para colocar información de todos los copartícipes debes colocar {% for inversionista in coparticipes %} {{ variable }} {% endfor %}, las variables que coloques se repetirán para todos los copartícipes dentro del bloque for / endfor sólo para las variables:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3r4ia',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'ai3kf',
      text: '{{ nombre_inversionista }}: Nombre del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'd1r0n',
      text:
        '{{ tipo_documento_inversionista }}: Tipo de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '4eil0',
      text:
        '{{ numero_documento_inversionista }}: Número de documento del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'a4uo8',
      text: '{{ direccion_inversionista }}: Domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '5nu2m',
      text:
        '{{ distrito_inversionista }}: Distrito de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'fvuoi',
      text:
        '{{ provincia_inversionista }}: Provincia de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '3801l',
      text:
        '{{ departamento_inversionista }}: Departamento de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '5muqc',
      text: '{{ pais_inversionista }}: País de domicilio del inversionista',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'd9ech',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'drjt5',
      text: 'Se pueden colocar en cualquier orden junto con texto.',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'ce0ph',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'dab5j',
      text:
        'Para colocar las preguntas que requieran una respuesta del cliente se pueden usar las siguientes variables:',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '1i00r',
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: '8aj75',
      text:
        '%tratamiento_datos%: Para la autorización del tratamiento de datos.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
    {
      key: 'ei0r9',
      text:
        '%fuentes_fondo%: Para el formulario de elección de origen y destino de los fondos.',
      type: 'unordered-list-item',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
});

const VARIABLES = [
  'ruc_safi',
  'direccion_safi',
  'distrito_safi',
  'provincia_safi',
  'nombre_fondo',
  'ruc_fondo',
  'direccion_fondo',
  'nombre_inversionista',
  'tipo_documento_inversionista',
  'numero_documento_inversionista',
  'direccion_inversionista',
  'distrito_inversionista',
  'provincia_inversionista',
  'departamento_inversionista',
  'pais_inversionista',
  'nombre_conyuge',
  'tipo_documento_conyuge',
  'numero_documento_conyuge',
  'nombre_representante',
  'nombre_apoderado',
  'tipo_documento_apoderado',
  'numero_documento_apoderado',
  'numero_poder_apoderado',
  'cuotas_levantamiento',
  'valor_cuota_nominal_fondo',
  'valor_cuota_levantamiento',
  'monto_levantamiento',
  'banco_fondo',
  'tipo_cuenta_fondo',
  'tipo_moneda_fondo',
  'cci_fondo',
  'swift_fondo',
  'inversion_minima_fondo',
  'inversion_maxima_fondo',
  'fecha_emision_contrato',
  'numeric_section',
  'numeric_subsection',
];

export const parseAutocomplete = (isContract = false) => {
  const autocompleteData = VARIABLES.map(id => {
    return {
      keys: [id],
      value: `{{ ${id} }}`,
      content: id,
    };
  });

  if (isContract) {
    return autocompleteData.concat([
      {
        keys: ['for inversionista in coparticipes'],
        value: '{% for inversionista in coparticipes %}',
        content: '%for inversionista in coparticipes%',
      },
      {
        keys: ['endfor'],
        value: '{% endfor %}',
        content: '%endfor%',
      },
      {
        keys: ['tratamiento_datos'],
        value: '%tratamiento_datos%',
        content: '%tratamiento_datos%',
      },
      {
        keys: ['fuentes_fondo'],
        value: '%fuentes_fondo%',
        content: '%fuentes_fondo%',
      },
    ]);
  }
  return autocompleteData;
};

export const CONDITIONS = [
  {
    value: 'PN',
    label: 'Persona natural',
    enum: 'NATURAL_PERSON',
  },
  {
    value: 'CP',
    label: 'Sociedad de gananciales',
    enum: 'COMMUNITY_OF_PROPERTY',
  },
  {
    value: 'CO',
    label: 'Copartícipes',
    enum: 'COPARTICIPANT',
  },
  {
    value: 'TU',
    label: 'Apoderado',
    enum: 'TUTOR',
  },
  {
    value: 'LP',
    label: 'Persona jurídica',
    enum: 'LEGAL_PERSON',
  },
  {
    value: 'SM',
    label: 'Mercado secundario',
    enum: 'SECONDARY_MARKET',
  },
];
