import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function Date(props) {
  const { date } = props;
  return <span>{moment(date).format('DD/MM/YYYY')}</span>;
}

Date.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Date;
