import { createSelector } from 'reselect';

function selectApp(state) {
  return state.get('utils');
}

function createClientFormSelector(name) {
  return createSelector(selectApp, appState => appState.get(name));
}

export default function getClientMode() {
  return createClientFormSelector('mode');
}
