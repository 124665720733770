/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import EducationLevelList from '../../../components/Models/EducationLevel/EducationLevelList';
import EducationLevelEdit from '../../../components/Models/EducationLevel/EducationLevelCreateEdit/edit';
import EducationLevelForm from '../../../components/Models/EducationLevel/EducationLevelForm';

function EducationLevelPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={EducationLevelList} />
      <Route path={`${path}/crear`} component={EducationLevelForm} />
      <Route path={`${path}/:id`} component={EducationLevelEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

EducationLevelPage.propTypes = {};

export default EducationLevelPage;
