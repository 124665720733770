import { gql } from 'apollo-boost';

export const LIST_CLIENT_CONTRACTS = gql`
  query listParticipantVerifiedPayment {
    listParticipantVerifiedPayment {
      edges {
        node {
          id
          raisingparticipantSet {
            edges {
              node {
                raising {
                  id
                  number
                  acronym
                  fund {
                    id
                    businessName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CONTRACT_BY_PARTICIPANT = gql`
  query contractTypeByParticipant($idParticipant: ID) {
    contractTypeByParticipant(idParticipant: $idParticipant) {
      name
      contractText
      annexText1
      annexText2
      condition
    }
  }
`;

export const GET_CONTRACT_BY_PARTICIPANT_COPARTICIPANTS = gql`
  query contractTypeByParticipant($idParticipant: ID) {
    contractTypeByParticipant(idParticipant: $idParticipant) {
      annexText2
    }
  }
`;

export const GET_SWORN_DECLARATION_BY_FUND = gql`
  query clientTypeSwornDeclaration($idParticipant: ID) {
    clientTypeSwornDeclaration(idParticipant: $idParticipant) {
      text
    }
  }
`;

export const HAS_SWORN_DECLARATION = gql`
  query hasSwornDeclaration($fundId: ID) {
    hasSwornDeclaration(fundId: $fundId) {
      result
    }
  }
`;

export const GET_CONTRACT_METADATA = gql`
  query participant($id: ID) {
    participant(id: $id) {
      id
      state
      dateContractEmission
      client {
        id
        user {
          username
          firstName
        }
      }
      coParticipants {
        edges {
          node {
            id
          }
        }
      }
      raisingparticipantSet {
        edges {
          node {
            raising {
              id
              number
              acronym
              fundType
              fund {
                id
                businessName
                fundType
              }
            }
          }
        }
      }
    }
  }
`;

export const SAVE_ELECTRONIC_CONTRACT_DATA = gql`
  mutation saveElectronicContractData(
    $participants: [ParticipantContractInput]
  ) {
    saveElectronicContractData(participants: $participants) {
      participant {
        id
        state
      }
    }
  }
`;

export const CONFIRM_CONTRACT_SIGNATURE = gql`
  mutation signElectronicContract($key: String!, $participantId: ID!) {
    signElectronicContract(key: $key, participantId: $participantId) {
      participant {
        id
        state
        dateContractEmission
        electronicContract {
          dsSigningUrl
        }
      }
    }
  }
`;

export const CREATE_SWORN_DECLARATION_SIGNATURE = gql`
  mutation createSwornDeclarationSignature($participantId: ID!) {
    createSwornDeclarationSignature(participantId: $participantId) {
      created
    }
  }
`;

export const CONFIRM_SWORN_DECLARATION_SIGNATURE = gql`
  mutation signSwornDeclaration($key: String!, $participantId: ID!) {
    signSwornDeclaration(key: $key, participantId: $participantId) {
      participant {
        id
        state
      }
    }
  }
`;

export const MANAGE_PARTICIPANT_DOCUMENTS = gql`
  mutation ManageParticipantDocuments($participantId: ID!) {
    manageParticipantDocuments(participantId: $participantId) {
      sent
      __typename
    }
  }
`;
