import React from 'react';

// Material Resources
import { Button } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';
import LoadingIndicator from '../../LoadingIndicator';

function CustomButton({ loading, ...props }) {
  return (
    <Button {...props}>
      {loading ? (
        <>
          <LoadingIndicator size="xs" />
          {props.children}
        </>
      ) : (
        props.children
      )}
    </Button>
  );
}

CustomButton.propTypes = {
  loading: PropTypes.bool,
};

CustomButton.defaultProps = {
  loading: false,
};

export default CustomButton;
