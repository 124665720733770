import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';

import SimpleForm from '../../../Forms/SimpleForm';
import {
  GET_EMPLOYEE_INFO,
  LIST_EMPLOYEES,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_CLIENT,
  LIST_RAZON_SOCIAL,
} from '../queries';
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_DOCUMENT_TYPES } from '../../DocumentType/queries';
import { formStructure, validationSchema, startData } from './formInfo';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import { parseEquivalent } from '../../../../utils/commonFunctions';

function EmployeeForm(props) {
  const { id } = useParams();
  const isEdit = id !== 'crear';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [investorData, setInvestorData] = useState(null);
  const [initData, setInitData] = useState(startData);
  const [saveClientData, setSaveClientData] = useState({});
  const [typeList, setTypeList] = useState([]);

  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_RAZON_SOCIAL);

  // RELATED QUERIES
  const [loadInvestor, { called, loading, data: InvestorData }] = useLazyQuery(
    GET_EMPLOYEE_INFO,
    {
      variables: {
        id,
      },
    },
  );
  const {
    loading: documentTypesLoading,
    error: documentTypesError,
    data: documentTypesData,
  } = useQuery(LIST_DOCUMENT_TYPES);
  if (documentTypesError) {
    console.error(
      'Interested Investor - list document types',
      documentTypesError,
    );
  }

  // Client mutations
  const [updateClientMutation, { loading: loadingUpdate }] = useMutation(
    UPDATE_EMPLOYEE_CLIENT,
    getOptions({
      mutationName: 'updateClient',
      modelName: 'client',
      message: investorData
        ? 'Colaborador actualizado.'
        : 'Colaborador creado.',
      refetchQueries: [{ query: LIST_EMPLOYEES }],
      completeCallback: () => history.goBack(),
      enqueueSnackbar,
    }),
  );

  // User mutations
  const updateMutation = useMutation(
    UPDATE_EMPLOYEE,
    getOptions({
      mutationName: 'updateUser',
      modelName: 'user',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        updateClientMutation({
          variables: {
            id,
            input: saveClientData,
          },
        });
      },
    }),
  );

  const createMutation = useMutation(
    CREATE_EMPLOYEE,
    getOptions({
      mutationName: 'createUser',
      modelName: 'user',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        updateClientMutation({
          variables: {
            id: data.clientSet.edges[0].node.id,
            input: saveClientData,
          },
        });
      },
    }),
  );
  // const deleteMutation = useMutation(
  //   DELETE_USER_INTERESTED_INVESTORS,
  //   getOptions({
  //     mutationName: 'deleteUser',
  //     modelName: 'user',
  //     message: 'Inversionista interesado eliminado con éxito.',
  //     enqueueSnackbar,
  //     refetchQueries: [{ query: LIST_EMPLOYEES }],
  //     completeCallback: () => history.goBack(),
  //   }),
  // );

  useEffect(() => loadInvestor(), [id]);

  useEffect(() => {
    if (!!TypesData) setTypeList(TypesData.razonSocialChoices);
    if (InvestorData && InvestorData.client) {
      let complete = { ...InvestorData.client };
      delete complete.user;
      complete = { ...complete, ...InvestorData.client.user };

      if (complete.documentType && complete.documentType !== '') {
        complete.documentType = complete.documentType
          ? complete.documentType.id
          : startData.documentType;
      } else {
        complete.documentType = 'other';
      }
      complete.userType = 'COLABORADOR';
      setInvestorData(complete);
    }
  }, [InvestorData, TypesData]);

  function formatBeforeSubmit(values) {
    const { userType, ...newValues } = { ...values };

    newValues.firstName = newValues.firstName.trim();
    newValues.lastName = newValues.lastName.trim();
    newValues.maternalSurname = newValues.maternalSurname.trim();
    newValues.documentNumber = newValues.documentNumber.trim();
    newValues.sex = parseEquivalent(newValues.sex);

    const userValues = {};
    userValues.firstName = newValues.firstName;
    userValues.lastName = newValues.lastName;
    userValues.maternalSurname = newValues.maternalSurname;
    userValues.email = newValues.email;
    userValues.documentNumber = newValues.documentNumber;
    userValues.documentType = newValues.documentType;
    userValues.userType = 'COLABORADOR';
    if (!isEdit) {
      userValues.isInterestedInvestor = false;
      userValues.isStaff = false;
      userValues.isEmployee = true;
    }
    if (newValues?.photoDocumentFront?.length < 30)
      delete newValues.photoDocumentFront;
    if (newValues?.photoDocumentBack?.length < 30)
      delete newValues.photoDocumentBack;
    delete newValues.photoDocumentsUrl;
    delete newValues.address;
    delete newValues.birthplace;
    setSaveClientData(newValues);
    return userValues;
  }
  const validate = () => ({});

  return loading && documentTypesLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <Grid container>
        <SimpleForm
          model={investorData}
          initialValues={initData}
          validateFunction={validate}
          validateSchema={validationSchema}
          formatBeforeSubmit={formatBeforeSubmit}
          data={formStructure(
            isEdit,
            edgeToList(documentTypesData, 'listDocumentTypes'),
            typeList,
          )}
          modelName="Colaboradores"
          hasCancel
          isEdit={isEdit}
          id={investorData && investorData.id}
          onCancel={() => history.goBack()}
          canBeDeleted
          updateMutation={updateMutation}
          createMutation={createMutation}
          // deleteMutation={deleteMutation}
        />
      </Grid>
    </>
  );
}

export default EmployeeForm;
