/**
 * ReturnDocusignPage
 *
 * This is the page that we show when the user returns from DocuSign.
 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ReturnDocusignPage() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress size={60} />
    </div>
  );
}
