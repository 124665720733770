/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import ProcessList from '../../../components/Models/Signatures/ProcessList';
import ProcessForm from '../../../components/Models/Signatures/Form';

function ProcessPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ProcessList} />
      <Route path={`${path}/:id`} component={ProcessForm} />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

ProcessPage.propTypes = {};

export default ProcessPage;
