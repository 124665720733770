import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(2),
    },
  },
  buttonSuccess: {
    backgroundColor: theme.palette.colors.green,
    display: 'block',
    margin: theme.spacing(1),
  },
  check: {
    color: theme.palette.colors.white,
  },
  buttonResend: {
    margin: '0 1rem 0 auto',
  },
  modalContent: {
    padding: '2rem',
  },
  modalFooter: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paragraph: {
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    padding: 5,
    margin: 0,
    fontSize: '1rem',
  },
}));
