// GraphQl
import { gql } from 'apollo-boost';

export const MASIVE_MAIL_PSC = gql`
  mutation emailPrefetentialSubscriptionCertificate($raisingId: ID!) {
    emailPrefetentialSubscriptionCertificate(raisingId: $raisingId) {
      sent
      __typename
    }
  }
`;
