import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  GET_CLIENT_SPOUSE_LEGAL_DATA,
  UPDATE_CLIENT_SPOUSE_LEGAL_DATA,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import {
  edgeToList,
  parseEquivalent,
  parseBool,
  successMessage,
  errorMessage,
} from '../../../../utils/commonFunctions';
import { LIST_DOCUMENT_TYPES } from '../../../Models/DocumentType/queries';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import LoadingIndicator from '../../../LoadingIndicator';
import { FORM_OPTIONS } from '../../constants';

function LegalDataSpouse(props) {
  const { isSociety, clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [startValues, setStartValues] = useState({
    documentType: '',
    alternativeDocumentType: '',
    documentNumber: '',
    domiciled: 'true',
    countryAddress: '',
    usPerson: 'true',
    nationality: [],
  });
  const [spouseID, setSpouseID] = useState();
  const [defaultOther, setDefaultOther] = useState(true);

  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_CLIENT_SPOUSE_LEGAL_DATA, {
    variables: { id: clientID },
  });

  const updateClient = useMutation(UPDATE_CLIENT_SPOUSE_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;
        if (client) {
          successMessage(
            'Se guardó datos legales del cónyuge.',
            enqueueSnackbar,
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_SPOUSE_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_SPOUSE_LEGAL_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      if (data.updateClient) {
        const { client, errors } = data.updateClient;

        if (client) {
          successMessage(
            'Se guardó datos legales del cónyuge parcialmente.',
            enqueueSnackbar,
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      }
    },
    refetchQueries: [
      { query: GET_CLIENT_SPOUSE_LEGAL_DATA, variables: { id: clientID } },
    ],
  });

  // SELECT QUERIES
  const { error: DocumentTypesError, data: DocumentTypesData } = useQuery(
    LIST_DOCUMENT_TYPES,
  );

  if (DocumentTypesError) {
    console.error(
      'Legal data spouse - list document types',
      DocumentTypesError,
    );
  }

  const data = [
    {
      isField: false,
      section: 'Documento de identidad',
      fields: [
        {
          label: '* Tipo de documento',
          name: 'documentType',
          labelText: 'Otro tipo de documento',
          nameText: 'alternativeDocumentType',
          inputVariant: INPUT_VARIANT.otherSelect,
          gridMD: [12, 12],
          data: edgeToList(DocumentTypesData, 'listDocumentTypes').concat([
            { id: 'other', name: 'Otro  documento' },
          ]),
          mapData: { value: 'id', label: 'name' },
          defaultOther,
        },
        {
          label: '* Número de documento',
          name: 'documentNumber',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.text,
        },
      ],
    },
    {
      isField: false,
      section: 'Nacionalidades',
      fields: [
        {
          label: '* Nacionalidad',
          name: 'nationality',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.nationality,
        },
      ],
    },
  ];

  useEffect(() => {
    if (clientError) {
      errorMessage(
        'Hubo un error, por favor inténtelo de nuevo.',
        enqueueSnackbar,
      );
    }
    if (clientData) {
      const { client } = clientData;

      if (client.spouse) {
        const { id, __typename, user, ...otherValues } = client.spouse;
        setSpouseID(id);
        const { id: userId, __typename: userTypename, ...userData } = user;
        const currentData = Object.assign(otherValues, userData);

        if (currentData.documentType && currentData.documentType !== '') {
          currentData.documentType = currentData.documentType
            ? currentData.documentType.id
            : startValues.documentType;
        } else {
          currentData.documentType = 'other';
          setDefaultOther(false);
        }

        currentData.alternativeDocumentType =
          currentData.alternativeDocumentType ||
          startValues.alternativeDocumentType;
        currentData.documentNumber =
          currentData.documentNumber || startValues.documentNumber;
        currentData.domiciled =
          currentData.domiciled !== null
            ? parseBool(currentData.domiciled)
            : startValues.domiciled;
        currentData.countryAddress = currentData.countryAddress
          ? currentData.countryAddress.id
          : startValues.countryAddress;
        currentData.usPerson =
          currentData.usPerson !== null
            ? parseBool(currentData.usPerson)
            : startValues.usPerson;

        currentData.nationality = currentData.nationalitySet.edges.map(
          nat => nat.node.nationality,
        );

        delete currentData.nationalitySet;

        setStartValues({ ...currentData });
      }
    }
  }, [clientData, clientLoading]);

  if (isSociety) {
    data.splice(
      1,
      0,
      ...[
        // {
        //   isField: false,
        //   section: 'Calidad de domicilio (fines tributarios)',
        //   fields: [
        //     {
        //       name: 'domiciled',
        //       gridMD: 12,
        //       inputVariant: INPUT_VARIANT.radio,
        //       data: [
        //         { value: 'true', label: 'Domiciliado' },
        //         { value: 'false', label: 'No domiciliado' },
        //       ],
        //     },
        //     {
        //       name: 'domiciledText',
        //       gridMD: 12,
        //       inputVariant: INPUT_VARIANT.paragraph,
        //       type: 'domiciled',
        //     },
        //   ],
        // },
        {
          isField: true,
          label: '* País de residencia',
          name: 'countryAddress',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.country,
        },
        // {
        //   isField: false,
        //   section: '¿Es usted una "US PERSON"?',
        //   fields: [
        //     {
        //       name: 'usPerson',
        //       gridMD: 12,
        //       inputVariant: INPUT_VARIANT.radio,
        //       data: FORM_OPTIONS.yesNo,
        //     },
        //     {
        //       name: 'usPersonText',
        //       gridMD: 12,
        //       inputVariant: INPUT_VARIANT.paragraph,
        //       type: 'usPerson',
        //     },
        //   ],
        // },
      ],
    );
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required(),
    alternativeDocumentType: Yup.string().when('documentType', {
      is: 'other',
      then: Yup.string().required(),
    }),
    documentNumber: Yup.string().required(),
    nationality: Yup.array().required(),
    countryAddress: Yup.string().when({
      is: () => isSociety,
      then: Yup.string().required(),
    }),
  });

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    newValues.domiciled = parseEquivalent(newValues.domiciled);
    newValues.usPerson = parseEquivalent(newValues.usPerson);
    if (newValues.documentType === 'other') {
      newValues.documentType = '';
    } else {
      newValues.alternativeDocumentType = '';
    }
    return newValues;
  }

  return (
    <>
      {clientLoading ? (
        <LoadingIndicator />
      ) : (
        <StepsForm
          initialValues={startValues}
          validateFunction={validationFunction}
          validateSchema={validationSchema}
          data={data}
          id={spouseID}
          mainClientID={clientID}
          title="Datos del cónyuge o apoderado / Datos legales"
          nextForm={
            isSociety
              ? MENU_ITEMS_ID.SpousePartnerLaborData
              : MENU_ITEMS_ID.ClientLabourData
          }
          updateMutation={updateClient}
          partialUpdateMutation={partialUpdateClient}
          formatBeforeSubmit={formatBeforeSubmit}
        />
      )}
    </>
  );
}

LegalDataSpouse.propTypes = {
  isSociety: PropTypes.bool.isRequired,
  clientID: PropTypes.string.isRequired,
};

export default LegalDataSpouse;
