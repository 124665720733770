import React, { useEffect, useState } from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../../LoadingIndicator';
import ClientForm from '../ClientForm';
import { GET_USER } from '../../queries';

function ClientEdit() {
  const { id } = useParams();
  const history = useHistory();
  const [formatValue, setFormatValue] = useState({});

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id },
  });

  useEffect(() => {
    if (data) {
      const formatData = { ...data.user };
      delete formatData.__typename;
      setFormatValue(formatData);
    }
  }, [data]);

  if (error) {
    console.error('get user', error);
    history.goBack();
  }

  if (loading) return <LoadingIndicator />;

  return <ClientForm client={formatValue} id={id} isEdit />;
}

ClientEdit.propTypes = {};

export default ClientEdit;
