import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    display: 'flex',
    minHeight: '20vh',
    maxHeight: '40%',
    background:
      'linear-gradient(180deg, rgba(166, 193, 211, 0.80) 0%, #A6C1D3 100%)',
    borderRadius: '10px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: '15vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '10vh',
      maxHeight: 'none',
      flexDirection: 'column',
    },
  },

  sliderContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.5s ease-in-out',
    justifyContent: 'start',
    height: '100%',
    padding: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    height: '100%',
    gap: '1px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px',
      alignItems: 'start',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '2px',
    },
  },
  lightText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '300',
    fontSize: '1.525rem',
    color: '#292D32',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  boldText: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 700,
    fontSize: '1.225rem',
    color: '#000',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  description: {
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: '500',
    fontSize: '0.75rem',
    color: '#292D32',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.65rem',
    },
  },
  arrowContainer: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      bottom: '5px',
      right: '5px',
    },
  },
  arrowButton: {
    backgroundColor: 'transparent',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    color: '#292D32',
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  buttonDiv: {
    paddingTop: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '2px',
    },
  },
  buttom: {
    background: '#000',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  buhoDiv: {
    display: 'flex',
    minHeight: '5vh',
    width: '100%',
    justifyContent: 'start',
    alignItems: 'end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
    },
  },
}));
