import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NotFound from '../../NotFoundPage';
import Employee from '../../../components/Models/Employee';
import EmployeeForm from '../../../components/Models/Employee/Form';

/**
 * @component
 */
function EmployeePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={Employee} />
      <Route path={`${path}/:id`} component={EmployeeForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default EmployeePage;
