import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { useSnackbar } from 'notistack';
import { Checkbox } from 'formik-material-ui';
import { useParams, useHistory } from 'react-router-dom';
import { elementType } from 'prop-types';

import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import {
  Grid,
  Button,
  Typography,
  FormLabel,
  FormHelperText,
  Select,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import SectionDivider from '../../../FormComponents/SectionDivider';
import CustomButton from '../../../FormComponents/CustomButton';
import SimpleForm from '../../../Forms/SimpleForm';
import { validationSchema, startData, extraFormStructure } from './formInfo';
import {
  UPDATE_SIGNATURE,
  CREATE_SIGNATURE,
  LIST_SIGNATURES,
} from '../queries';
import { LIST_TYPES_CHOICES_EMPLOYEE } from '../../Files/queries';
import { LIST_EMPLOYEES } from '../../Employee/queries';
import { LIST_CLASSIFICATION_CHOICES } from '../../EmployeeFiles/queries';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';
import LoadingIndicator from '../../../LoadingIndicator';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  simpleFormContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  labelContainer: {
    alignItems: 'center',
  },
  errorTypo: {
    color: theme.palette.common.red,
    marginTop: '5px',
    fontSize: '10px',
    fontWeight: 300,
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
}));

function ProcessForm(props) {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [typeList, setTypeList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);
  const [initialValues, setInitialValues] = useState({
    clients: [],
    isBiometricAvailable: false,
    signingFile: '',
    subject: '',
    body: '',
    isDefaultWording: false,
  });
  const [signatories, setSignatories] = useState([]);

  const { data: ClassificationData } = useQuery(LIST_CLASSIFICATION_CHOICES);
  const { data: listSignatureProcess } = useQuery(LIST_SIGNATURES);
  const { loading: listClientLoading, error, data } = useQuery(LIST_EMPLOYEES);
  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES_EMPLOYEE);
  useEffect(() => {
    if (!!data) {
      setClientList(getUsersByClients(edgeToList(data, 'listEmployees')));
    }
    if (!!TypesData) {
      setTypeList(TypesData.typesChoicesEmployee);
    }
    if (!!ClassificationData) {
      setClassificationList(ClassificationData.classificationChoices);
    }
  }, [data, TypesData, ClassificationData]);

  useEffect(() => {
    if (listSignatureProcess && id != 'crear') {
      const listProcess = edgeToList(
        listSignatureProcess,
        'listSigningDocuments',
      );
      const processData = listProcess.find(element => element.id === id);
      const { pdfSheet, signedPdfSheet, signingProcess } = processData;
      const parserClient = edgeToList(signingProcess, 'clients').map(item => {
        return {
          id: item.user.id,
          fullName: item.user.firstName + ' ' + item.user.lastName,
          clientId: item.id,
        };
      });
      const last_position_pdf = parseInt(pdfSheet.lastIndexOf('/')) + 1;

      const joinData = {
        clients: parserClient,
        signingFile: pdfSheet?.slice(last_position_pdf),
        isBiometricAvailable: signingProcess.isBiometricAvailable,
        subject: signingProcess.subject,
        isDefaultWording: signingProcess.isDefaultWording,
        body: signingProcess.body,
      };
      setFileName(pdfSheet?.slice(last_position_pdf));
      setInitialValues(joinData);
    }
  }, [listSignatureProcess, id, clientList]);

  const createMutation = useMutation(
    CREATE_SIGNATURE,
    getOptions({
      mutationName: 'createSignature',
      modelName: 'signingProcess',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  const updateMutation = useMutation(
    UPDATE_SIGNATURE,
    getOptions({
      mutationName: 'updateSignature',
      modelName: 'signingProcess',
      enqueueSnackbar,
      completeCallback: () => history.goBack(),
      refetchQueries: [{ query: LIST_SIGNATURES }],
    }),
  );

  function getUsersByClients(clients) {
    clients.filter(element => !element.user.isStaff && element.user.isActive);
    return clients.map(element => {
      let { user } = element;
      user = {
        ...user,
        clientId: element.id,
        fullName: user.firstName + ' ' + user.lastName,
      };
      return user;
    });
  }

  const [createModel, { loading: createLoading }] = createMutation;
  const [updateModel, { loading: updateLoading }] = updateMutation;

  useEffect(() => {
    if (!createLoading) {
      setLoading(false);
    }
  }, [createLoading]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async (e, propsForm) => {
    try {
      let name = e.target.files[0].name;
      const encoded = await toBase64(e.target.files[0]);
      propsForm.setFieldValue('signingFile', encoded);
      setFileName(name);
    } catch (error) {
      console.log('error', error);
    }
  };

  const addForm = () => {
    setSignatories([
      ...signatories,
      {
        names: '',
        lastnames: '',
        documentNumber: '',
        email: '',
      },
    ]);
  };

  const handleChangeInput = (e, index) => {
    let result = signatories.map((dato, indexSign) => {
      if (indexSign == index) {
        dato[e.target.id] = e.target.value;
      }
      return dato;
    });

    setSignatories(result);
  };

  const handleDeleteInput = index => {
    const resultDelete = signatories.filter((item, i) => i !== index);
    setSignatories(resultDelete);
  };

  return listClientLoading ? (
    <LoadingIndicator />
  ) : (
    <div className={classes.simpleFormContainer}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const tmp = values;
          tmp.isContract = 'otros';
          const clientsParser = values.clients.map(item => item.clientId);
          tmp.clients = clientsParser;
          tmp.externalSigners = [];
          createModel({ variables: { input: tmp } });
          history.goBack();
        }}
      >
        {propsForm => (
          <Form className={classes.simpleForm}>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  {id === 'crear' ? 'Crear' : 'Editar'} Proceso de Firma
                </Typography>
                <SectionDivider />
                <Grid container alignItems="center">
                  {id === 'crear' && (
                    <>
                      <Grid className={classes.labelContainer} item xs={6}>
                        <InputLabel>Documento</InputLabel>
                        {fileName && (
                          <div>
                            <small>{fileName}</small>
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          variant="contained"
                          component="label"
                          color="primary"
                          name="signingFile"
                          error={propsForm.errors.signingFile}
                          required
                        >
                          Buscar Archivo
                          <input
                            id="signingFile"
                            name="signingFile"
                            type="file"
                            accept=".pdf"
                            style={{ display: 'none' }}
                            onChange={e => handleChange(e, propsForm)}
                            disabled={id === 'crear' ? false : true}
                          />
                        </Button>
                        <ErrorMessage name="signingFile">
                          {msg => (
                            <Typography className={classes.errorTypo}>
                              {msg} *
                            </Typography>
                          )}
                        </ErrorMessage>
                      </Grid>
                    </>
                  )}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    item
                    xs={6}
                  >
                    <InputLabel id="8">Clasificación</InputLabel>
                    <Select
                      fullWidth
                      labelId="fileClassification"
                      name="fileClassification"
                      variant="outlined"
                      required
                      value={propsForm.values.fileClassification}
                      error={propsForm.errors.fileClassification}
                      onChange={(e, value) => {
                        propsForm.setFieldValue(
                          'fileClassification',
                          value.key,
                        );
                      }}
                    >
                      {classificationList.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="fileClassification">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                    item
                    xs={6}
                  >
                    <InputLabel id="fileType">Tipo</InputLabel>
                    <Select
                      fullWidth
                      labelId="fileType"
                      name="fileType"
                      variant="outlined"
                      required
                      value={propsForm.values.fileType}
                      error={propsForm.errors.fileType}
                      onChange={(e, value) => {
                        propsForm.setFieldValue('fileType', value.key);
                      }}
                    >
                      {typeList.map(item => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="fileType">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      id="clients"
                      fullWidth
                      value={propsForm.values.clients}
                      error={propsForm.errors.clients}
                      multiple
                      onChange={(e, value) => {
                        propsForm.setFieldValue('clients', value);
                      }}
                      options={clientList}
                      getOptionLabel={option =>
                        option.fullName ? option.fullName : ''
                      }
                      renderInput={params => (
                        <TextField
                          error={propsForm.errors.clients}
                          value={propsForm.values.clients}
                          fullWidth
                          {...params}
                          variant="outlined"
                          label="Colaboradores"
                          placeholder="Colaboradores"
                        />
                      )}
                    />
                    <ErrorMessage name="clients">
                      {msg => (
                        <Typography className={classes.errorTypo}>
                          {msg} *
                        </Typography>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <Grid container alignItems="center">
                    <Grid className={classes.labelContainer} item xs={6}>
                      <TextField
                        fullWidth
                        id="subject"
                        label="Asunto"
                        name="subject"
                        value={propsForm.values.subject}
                        disabled={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue('subject', e.target.value)
                        }
                        error={propsForm.errors.subject}
                      />
                      <ErrorMessage name="subject">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={4}>
                      <Checkbox
                        name="isDefaultWording"
                        value={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue(
                            'isDefaultWording',
                            e.target.value,
                          )
                        }
                        disabled={
                          propsForm.values.subject && propsForm.values.body
                        }
                        checked={propsForm.values.isDefaultWording}
                        error={propsForm.errors.isDefaultWording}
                      />
                      <FormLabel component="label" color="primary">
                        Correo por defecto
                      </FormLabel>
                      <ErrorMessage name="isDefaultWording">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid className={classes.labelContainer} item xs={6}>
                      <TextField
                        fullWidth
                        id="body"
                        label="Cuerpo"
                        name="body"
                        value={propsForm.values.body}
                        disabled={propsForm.values.isDefaultWording}
                        onChange={e =>
                          propsForm.setFieldValue('body', e.target.value)
                        }
                        error={propsForm.errors.body}
                      />
                      <ErrorMessage name="body">
                        {msg => (
                          <Typography className={classes.errorTypo}>
                            {msg} *
                          </Typography>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                  <SectionDivider />
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        onClick={history.goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled={
                          loading || propsForm.values.clients.length == 0
                        }
                      >
                        {id === 'crear' ? 'Crear' : 'Guardar'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProcessForm;
