import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import {
  generateColumn,
  edgeToList,
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import {
  IconButton,
  Input,
  Button,
  Tooltip,
  TableCell,
  Typography,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/Add';
import BackupIcon from '@material-ui/icons/Backup';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  LIST_S3FILES,
  LIST_TYPES_CHOICES,
  FILE_DOWNLOAD,
  FILE_UPDATE,
} from '../queries';
import PDFViewerModal from '../../../PDFViewerModal';
import DeleteModal from '../../../DeleteModal';

// styles and icons
import RefreshIcon from '@material-ui/icons/Refresh';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import useStyles from './styles';
import { saveAs } from 'file-saver';

function FilesList() {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [filesList, setFilesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [url, setUrl] = useState('');
  const [idState, setIdState] = useState('');
  const [pageInfo, setPageInfo] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [listLength, setListLength] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchTextInput, setSearchTextInput] = useState('');

  const { loading: fileLoading, error, data, fetchMore, refetch } = useQuery(
    LIST_S3FILES,
    {
      variables: {
        search: searchText ? searchText : null,
        first: 10,
        afterCursor: null,
        last: null,
        beforeCursor: null,
      },
    },
  );

  const {
    loading: TypesLoading,
    error: TypesError,
    data: TypesData,
  } = useQuery(LIST_TYPES_CHOICES);
  const [downloadFile, { loadingFile, dataFile }] = useMutation(FILE_DOWNLOAD, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      if (!!data && !isDownload) {
        setUrl(data?.fileDownload?.url);
        setShowModal(true);
      } else {
        setShowModal(false);
        saveAs(data?.fileDownload?.url, data?.fileDownload?.fileName);
        successMessage('Archivo descargado.', enqueueSnackbar);
      }
    },
  });

  useEffect(() => {
    if (!!data) {
      setTotalCount(data.listS3Files.totalCount);
      setPageInfo(data.listS3Files.pageInfo);
      setFilesList(edgeToList(data, 'listS3Files'));
      setListLength(edgeToList(data, 'listS3Files').length);
    }
    if (!!TypesData) {
      setTypeList(TypesData.typesChoices);
    }
  }, [data, TypesData]);

  const getUrl = (id, isDownload) => {
    downloadFile({
      variables: { id, isDownload },
    });
  };

  const [fileUpdate] = useMutation(FILE_UPDATE, {
    onCompleted(data) {
      if (!!data && data?.fileUpdate?.s3file) {
        successMessage('Archivo eliminado.', enqueueSnackbar);
      }
    },
  });

  function handleState(id) {
    fileUpdate({
      variables: { id },
    });
  }

  function handleModalClose() {
    setOpenDeleteModal(false);
  }
  function handleModalOK() {
    handleState(idState);
    setOpenDeleteModal(false);
    setPageLength(10);
    setSearchText('');
    refetch();
  }

  let columns = [];
  if (filesList && filesList.length > 0) {
    columns = [
      generateColumn('Cliente', 'client', {
        sort: true,
        customBodyRender: value => {
          if (value)
            return value?.user?.firstName + ' ' + value?.user?.lastName;
          else return '-';
        },
      }),
      generateColumn('Cliente Externo', 'externalSigner', {
        sort: true,
        customBodyRender: value => {
          if (value) return value?.firstName + ' ' + value?.lastName;
          else return '-';
        },
      }),
      generateColumn('Fondo', 'fund', {
        sort: true,
        customBodyRender: value => {
          if (value) return value?.businessName;
          else return '-';
        },
      }),
      generateColumn('Bono', 'bond', {
        sort: true,
        customBodyRender: value => {
          if (value)
            return (
              value?.name +
              ' ' +
              value?.program +
              ' ' +
              value?.release +
              ' ' +
              value?.className
            );
          else return '-';
        },
      }),
      generateColumn('Tipo', 'fileType', {
        sort: true,
        customBodyRender: value => {
          let name;
          typeList.map(item => {
            if (item.value == value) {
              name = item.name;
            }
          });
          return name;
        },
      }),
      generateColumn('Archivo', 'fileName', {
        sort: true,
        customBodyRender: value => {
          return value;
        },
      }),
      generateColumn('Fecha', 'uploadedAt', {
        sort: true,
        customBodyRender: value => moment(value).format('DD-MM-YYYY hh:mm'),
        display: true,
      }),
      {
        name: 'id',
        label: 'Opciones',
        options: {
          sort: false,
          customBodyRender: value => {
            const id = value;
            return (
              <>
                <Tooltip title="Visualizar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(false);
                      getUrl(id, false);
                    }}
                    className={classes.optionButtons}
                  >
                    <PageviewOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setIsDownload(true);
                      getUrl(id, true);
                    }}
                    className={classes.optionButtons}
                  >
                    <CloudDownloadIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar" placement="top" arrow>
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={$ev => {
                      $ev.stopPropagation();
                      setOpenDeleteModal(true);
                      setIdState(id);
                    }}
                  >
                    <DeleteOutlineOutlinedIcon className={classes.optionIcon} />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
          customHeadRender: columnMeta => {
            return (
              <TableCell key={7} style={{ borderBottom: 'none' }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.tableTitle}
                >
                  {columnMeta.label}
                </Typography>
              </TableCell>
            );
          },
        },
      },
    ];
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    download: true,
    filter: false,
    download: false,
    pagination: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Cargar Archivo">
            <IconButton
              color="secondary"
              onClick={() => history.push(`${history.location.pathname}/crear`)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Carga Masiva">
            <IconButton
              color="secondary"
              onClick={() =>
                history.push(`${history.location.pathname}/crearMultiple`)
              }
            >
              <BackupIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refrescar">
            <IconButton
              color="secondary"
              onClick={e => {
                setPageLength(10);
                setSearchText('');
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customSearchRender: () => {
      return (
        <div>
          <Input
            autoFocus
            className={classes.searchInput}
            variant="outlined"
            type="text"
            onChange={e => {
              setSearchTextInput(e.target.value);
            }}
          ></Input>
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={() => {
              setSearchText(searchTextInput);
            }}
          >
            {' '}
            Buscar{' '}
          </Button>
        </div>
      );
    },
    onSearchClose: () => {
      setPageLength(10);
      setSearchText('');
      refetch();
    },
  };

  return (
    <>
      <MUIDataTable
        title={searchText.length > 0 ? 'Resultados: ' + searchText : 'Archivos'}
        data={filesList}
        columns={columns}
        options={options}
      />
      <div className={classes.buttonContainer}>
        {listLength < pageLength && pageLength > totalCount ? (
          <div className={classes.paginationText}>
            {totalCount} de {totalCount}
          </div>
        ) : (
          <div className={classes.paginationText}>
            {pageLength} de {totalCount}
          </div>
        )}
        {pageLength - listLength > 0 && listLength != totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              if (pageLength > totalCount) setPageLength(pageLength - 10);
              else setPageLength(pageLength - listLength);
              if (pageInfo?.startCursor) {
                fetchMore({
                  variables: {
                    beforeCursor: pageInfo.startCursor,
                    last: 10,
                    afterCursor: null,
                    first: null,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              }
            }}
          >
            {' '}
            Anterior{' '}
          </div>
        ) : (
          <span></span>
        )}
        {pageLength <= totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              setPageLength(pageLength + listLength);
              fetchMore({
                variables: {
                  afterCursor: pageInfo?.endCursor,
                  first: 10,
                  last: null,
                  beforeCursor: null,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return fetchMoreResult;
                },
              });
            }}
          >
            {' '}
            Siguiente{' '}
          </div>
        ) : (
          <span></span>
        )}
      </div>
      <PDFViewerModal
        showModal={showModal}
        onClosePress={() => setShowModal(false)}
        fileURL={url}
        loading={loadingFile}
      />
      <DeleteModal
        model={'Archivo'}
        open={openDeleteModal}
        handleClose={handleModalClose}
        handleOK={handleModalOK}
      />
    </>
  );
}

export default FilesList;
