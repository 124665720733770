import React, { useState, useEffect } from 'react';

// Others
import PropTypes from 'prop-types';
import MUIRichTextEditor from 'mui-rte';
import clsx from 'clsx';

// Styles & Images
import useStyles from './styles';

function ReadOnlyEditor(props) {
  const { text } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.infoContainer, classes.editorContainer)}>
      <MUIRichTextEditor
        readOnly
        defaultValue={text}
        controls={[]}
        customControls={[
          {
            name: 'numberCList',
            type: 'inline',
            inlineStyle: {
              display: 'inline-block',
              width: '3em',
              verticalAlign: 'top',
            },
          },
          {
            name: 'alphaCList',
            type: 'inline',
            inlineStyle: {
              display: 'inline-block',
              width: 'calc(100% - 3em)',
            },
          },
        ]}
      />
    </div>
  );
}

ReadOnlyEditor.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ReadOnlyEditor;
