import React, { useState } from 'react';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import Yup from '../../../../utils/yup';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm from '../../../Forms/SimpleForm';
import { getOptions } from '../../../../utils/commonFunctions';

function CustomSubmodelForm(props) {
  const {
    model,
    id,
    isEdit,
    canBeDeleted,
    submodelName,
    afterEditCreate,
    mutationsData,
    startValues,
    validationSchema,
    validationFunction,
    formStructure,
    formatBeforeSubmit,
    updateModelFunction,
    buttonLoader,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const updateSubmodelMutation = useMutation(
    mutationsData.update.query,
    getOptions({
      mutationName: mutationsData.update.mutationName,
      modelName: mutationsData.update.modelName,
      message: mutationsData.update.message,
      completeCallback: () => afterEditCreate(),
      enqueueSnackbar,
      update: mutationsData.update.update,
      refetchQueries: mutationsData.update.refetchQueries,
    })
  );
  const createSubmodelMutation = useMutation(
    mutationsData.create.query,
    getOptions({
      mutationName: mutationsData.create.mutationName,
      modelName: mutationsData.create.modelName,
      message: mutationsData.create.message,
      completeCallback: (data, errors) => {
        afterEditCreate();
        updateModelFunction(data.id);
      },
      enqueueSnackbar,
      update: mutationsData.create.update,
      refetchQueries: mutationsData.create.refetchQueries,
    })
  );
  const deleteSubmodelMutation = useMutation(
    mutationsData.delete.query,
    getOptions({
      mutationName: mutationsData.delete.mutationName,
      modelName: mutationsData.delete.modelName,
      message: mutationsData.delete.message,
      completeCallback: () => afterEditCreate(),
      enqueueSnackbar,
      update: mutationsData.delete.update,
      refetchQueries: mutationsData.delete.refetchQueries,
    })
  );

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        formatBeforeSubmit={formatBeforeSubmit}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        data={formStructure}
        model={model}
        id={id}
        modelName={submodelName}
        routeName=""
        isEdit={isEdit}
        canBeDeleted={canBeDeleted}
        hasCancel
        onCancel={afterEditCreate}
        updateMutation={updateSubmodelMutation}
        createMutation={createSubmodelMutation}
        deleteMutation={deleteSubmodelMutation}
        buttonLoader={buttonLoader}
      />
    </>
  );
}

CustomSubmodelForm.propTypes = {
  model: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
  buttonLoader: PropTypes.bool,
  afterEditCreate: PropTypes.func.isRequired,
  mutationsData: PropTypes.shape().isRequired,
  startValues: PropTypes.shape().isRequired,
  validationSchema: PropTypes.shape().isRequired,
  validationFunction: PropTypes.func.isRequired,
  formStructure: PropTypes.array.isRequired,
  formatBeforeSubmit: PropTypes.func.isRequired,
  updateModelFunction: PropTypes.func.isRequired,
};

CustomSubmodelForm.defaultProps = {
  model: null,
  id: '',
  isEdit: false,
  canBeDeleted: true,
};

export default CustomSubmodelForm;
