import { GET_LOGIN_DATA, SET_LOGIN_DATA } from './constants';

export function setAuthDataAction(token, user) {
  return {
    type: SET_LOGIN_DATA,
    token,
    user,
  };
}
export function getAuthDataAction(user, password) {
  return {
    type: GET_LOGIN_DATA,
    user,
    password,
  };
}
