import React, { useEffect, useRef, useState } from 'react';

// Material Resources
import {
  Button,
  InputAdornment,
  IconButton,
  TextField,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

// GraphQl
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Others

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

// Styles & Images
import useStyles from './styles';
import style from '../LoginPage/login.module.css';
import goldenLogo from '../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';
import BackArrowIcon from '../LoginPage/assets/BackArrowIcon';
import Yup from '../../utils/yup';
import { successMessage, errorMessage } from '../../utils/commonFunctions';
import PasswordKeyboard from './numericKeyboard';

const SET_EMAIL_STEP = 0;

function ExternalSignerPage(props) {
  const { id, token } = props.match.params;
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmpassword: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const RESET_PASSWORD = gql`
    mutation changeUserTokenPassword(
      $user: ID!
      $resetToken: String!
      $password: String!
    ) {
      changeUserTokenPassword(
        input: { user: $user, resetToken: $resetToken, password: $password }
      ) {
        ok
        message
      }
    }
  `;

  const togglePasswordVisibility = field => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };
  const [changeUserTokenPasswordMutation] = useMutation(RESET_PASSWORD, {
    onCompleted() {
      setActiveStep(activeStep + 1);
      successMessage('Contraseña cambiada correctamente.', enqueueSnackbar);
      setLoading(false);
      setTimeout(() => {
        history.push('/');
      }, 3000);
    },
    onError(error) {
      const { graphQLErrors } = error;
      if (graphQLErrors[0]?.message?.includes('común')) {
        errorMessage(
          'Contraseña no válida. Evita usar números consecutivos o repetidos.',
          enqueueSnackbar,
        );
      } else errorMessage(graphQLErrors, enqueueSnackbar);
      setLoading(false);
    },
  });

  const handlerRedirect = () => {
    history.push('/');
  };

  const [focusedInput, setFocusedInput] = useState(null);

  const handlePasswordChange = value => {
    setFormData({
      ...formData,
      password: value,
    });
  };

  const handleConfirmPasswordChange = value => {
    setFormData({
      ...formData,
      confirmpassword: value,
    });
  };

  const onSubmit = () => {
    if (formData.password !== formData.confirmpassword) {
      errorMessage('Las contraseñas no coinciden.', enqueueSnackbar);
      return;
    }

    setLoading(true);
    changeUserTokenPasswordMutation({
      variables: {
        password: formData.password,
        user: id,
        resetToken: token,
      },
    });
  };

  const inputRef = useRef(null);
  const keyboardRef = useRef(null);

  const inputRef2 = useRef(null);
  const keyboardRef2 = useRef(null);
  const handleClickOutside = event => {
    if (
      keyboardRef.current &&
      !keyboardRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
    if (
      keyboardRef2.current &&
      !keyboardRef2.current.contains(event.target) &&
      inputRef2.current &&
      !inputRef2.current.contains(event.target)
    ) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (formData.password.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        password: false,
      });
    }
  }, [formData.password]);

  useEffect(() => {
    if (formData.confirmpassword.length === 6) {
      setFocusedInput(null);
      setShowPassword({
        ...showPassword,
        confirmpassword: false,
      });
    }
  }, [formData.confirmpassword]);

  const handlerOpenKeyboardpassword = () => {
    setFocusedInput('password');
    setFormData({
      ...formData,
      password: '',
    });
  };

  const handlerOpenKeyboardconfirmpass = () => {
    setFocusedInput('confirmpassword');
    setFormData({
      ...formData,
      confirmpassword: '',
    });
  };

  return (
    <div className={style.layoutPassword}>
      <div className={classes.containerPassword}>
        <img src={goldenLogo} alt="logo" style={{ width: '25vh' }} />

        {activeStep === SET_EMAIL_STEP ? (
          <>
            <div className={classes.form}>
              <div className={classes.backtoLogin}>
                <div
                  className={classes.backToLoginAction}
                  onClick={handlerRedirect}
                >
                  <BackArrowIcon />
                  <p className={classes.bactoLogin}>Volver a Iniciar Sesion</p>
                </div>
              </div>
              <TextField
                sx={{ pointerEvents: 'none' }}
                ref={inputRef}
                type={showPassword.password ? 'text' : 'password'}
                label="Ingrese su nueva clave de 6 dígitos"
                name="password"
                variant="outlined"
                value={formData.password}
                onFocus={handlerOpenKeyboardpassword}
                autoComplete="off"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => togglePasswordVisibility('password')}
                      >
                        {showPassword.password ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {focusedInput === 'password' && (
                <div ref={keyboardRef} className={classes.main}>
                  <PasswordKeyboard handlerPassword={handlePasswordChange} />
                </div>
              )}
              <TextField
                ref={inputRef2}
                type={showPassword.confirmpassword ? 'text' : 'password'}
                label="Confirme su clave de 6 dígitos"
                name="confirmpassword"
                sx={{ pointerEvents: 'none' }}
                variant="outlined"
                value={formData.confirmpassword}
                onFocus={handlerOpenKeyboardconfirmpass}
                autoComplete="off"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          togglePasswordVisibility('confirmpassword')
                        }
                      >
                        {showPassword.confirmpassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {focusedInput === 'confirmpassword' && (
                <div ref={keyboardRef2} className={classes.main}>
                  <PasswordKeyboard
                    handlerPassword={handleConfirmPasswordChange}
                  />
                </div>
              )}
              <div className={classes.mssgDiv}>
                <p className={classes.mssgText}>
                  <strong>Recomendación</strong>
                  <br />
                  Tu contraseña debe tener 6 dígitos, no consecutivos ni
                  repetidos. Asegúrate de que sea una combinación única.
                </p>
              </div>
              <div
                style={{
                  width: '100%',
                  padding: '1vh',
                }}
              >
                <Button
                  className={classes.btn}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  onClick={onSubmit}
                  type="submit"
                >
                  Actualizar Contraseña
                </Button>
              </div>
            </div>
          </>
        ) : (
          <p className={classes.successMessage}>
            Su contraseña ha sido cambiada correctamente.
          </p>
        )}
      </div>
    </div>
  );
}

ExternalSignerPage.propTypes = {};

export default ExternalSignerPage;
