import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  stepsFormContainer: {
    paddingLeft: '6%',
    paddingRight: '6%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  simpleForm: {
    width: '100%',
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  subTitle: {
    fontWeight: 600,
  },
  subsection: {
    backgroundColor: theme.palette.colors.lightGray,
    borderRadius: 4,
    paddingBottom: 10,
    paddingTop: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  paragraph: {
    textAlign: 'justify',
    padding: 10,
    margin: 0,
    whiteSpace: 'pre-wrap',
  },
  modalContainer: {
    padding: 10,
  },
  formText: {
    fontSize: '0.9rem',
  },

  btnMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '10px',
    },
  },
}));
