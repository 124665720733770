import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  radiosContainer: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    '& .MuiFormGroup-root': {
      flexWrap: 'nowrap',
    },
  },
}));
