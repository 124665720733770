/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import MaritalStatusList from '../../../components/Models/MaritalStatus/MaritalStatusList';
import MaritalStatusEdit from '../../../components/Models/MaritalStatus/MaritalStatusCreateEdit/edit';
import MaritalStatusForm from '../../../components/Models/MaritalStatus/MaritalStatusForm';

function MaritalStatusPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={MaritalStatusList} />
      <Route path={`${path}/crear`} component={MaritalStatusForm} />
      <Route path={`${path}/:id`} component={MaritalStatusEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default MaritalStatusPage;
