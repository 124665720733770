import React, { useState, useEffect } from 'react';

// Material Resources
import { Grid, Button, Typography } from '@material-ui/core';

// GraphQl
import { useMutation, useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_FUND,
  CREATE_FUND,
  DELETE_FUND,
  LIST_FUNDS,
  DELETE_INSTRUMENT_FUND,
  GET_INSTRUMENT_FUND,
  GET_FUND,
} from '../queries';
import {
  fundTypes,
  incomeTypes,
  tirCalculusTypes,
  selectYesNo,
} from '../constants';
import { validationFunctionFund, validationSchemaFund } from './validation';
import { LIST_CURRENCIES } from '../../Currency/queries';
import { LIST_CATEGORIES } from '../../Category/queries';
import {
  edgeToList,
  parseEquivalent,
  getOptions,
  stringToDate,
} from '../../../../utils/commonFunctions';

import InnerBankDataBankAccountList from '../../../InnerModels/BankAccount/BankAccountList';
import CustomSubmodelList from '../../../InnerModels/CustomSubmodel/CustomSubmodelList';
import {
  instrumentColumns,
  instrumentFormStructure,
  instrumentValidationSchema,
  instrumentValidationFunction,
  instrumentMutationsData,
  instrumentStartValues,
} from './submodels';
import { LIST_INSTRUMENTS } from '../../Instrument/queries';

// Styles & Images
import useStyles from './styles';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { defaultDate } from '../../../FormComponents/DatePicker';

const startValues = {
  logo: null,
  state: false,
  hasClasses: 'true',
  fundType: '',
  incomeType: '',
  tirCalculus: '',
  // fixedRate: 0,
  ruc: '',
  phoneNumber: '',
  businessName: '',
  acronym: '',
  address: '',
  subscriptionRight: 'false',
  performanceGoal: 0,
  currency: '',
  category: '',
  fundCapitalGoal: 0,
  nominalFeeValue: 0,
  subscribedPaidFees: 0,
  lifetime: 1,
  minimumInvestment: 0,
  coParticipation: 'false',
  exchangeRateRisk: 'false',
  allocationMechanisms: '',
  startRegulationAgreementDate: defaultDate,
  endRegulationAgreementDate: defaultDate,
  assemblyDelegationDate: defaultDate,
  createdAt: defaultDate,
  endedAt: defaultDate,
  stockMarketRepresentative: '',
};

function FundForm(props) {
  const { fund, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateFund = useMutation(
    UPDATE_FUND,
    getOptions({
      mutationName: 'updateFund',
      modelName: 'fund',
      message: 'Fondo actualizado con éxito.',
      enqueueSnackbar,
    }),
  );
  const createFund = useMutation(
    CREATE_FUND,
    getOptions({
      mutationName: 'createFund',
      modelName: 'fund',
      message: 'Fondo creado con éxito.',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        history.push(`/administrador/fondos`);
      },
      update(cache, { data: createFundData }) {
        if (cache.data.data.ROOT_QUERY.listFunds) {
          const { listFunds } = cache.readQuery({ query: LIST_FUNDS });
          listFunds.edges.unshift({
            // Be careful with types
            __typename: 'FundTypeEdge',
            node: createFundData.createFund.fund,
          });
          cache.writeQuery({
            query: LIST_FUNDS,
            data: { listFunds },
          });
        }
      },
    }),
  );
  const deleteFund = useMutation(
    DELETE_FUND,
    getOptions({
      mutationName: 'deleteFund',
      modelName: 'fund',
      message: 'Fondo eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listFunds) {
          const { listFunds } = cache.readQuery({ query: LIST_FUNDS });
          listFunds.edges = listFunds.edges.filter(e => e.node.id !== id);
          cache.writeQuery({
            query: LIST_FUNDS,
            data: { listFunds },
          });
        }
        history.push('/administrador/fondos');
      },
    }),
  );

  // SELECT QUERIES
  const {
    loading: CategoriesLoading,
    error: CategoriesError,
    data: CategoriesData,
  } = useQuery(LIST_CATEGORIES);
  const {
    loading: CurrenciesLoading,
    error: CurrenciesError,
    data: CurrenciesData,
  } = useQuery(LIST_CURRENCIES);
  const {
    loading: InstrumentsLoading,
    error: InstrumentsError,
    data: InstrumentsData,
  } = useQuery(LIST_INSTRUMENTS);

  if (CategoriesError) {
    console.error('Fund - list categories', CategoriesError);
  }
  if (CurrenciesError) {
    console.error('Fund - list currencies', CurrenciesError);
  }

  const data = [
    {
      label: 'Tipo de fondo',
      name: 'fundType',
      gridMD: 3,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: fundTypes,
    },
    {
      label: 'Nombre de la compañía',
      name: 'businessName',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Siglas',
      name: 'acronym',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'RUC',
      name: 'ruc',
      gridMD: 3,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      label: 'Teléfono',
      name: 'phoneNumber',
      gridMD: 2,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Dirección',
      name: 'address',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Logo',
      name: 'logo',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.image,
    },
    {
      label: 'Derecho de subcripción preferente',
      name: 'subscriptionRight',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: selectYesNo,
    },
    {
      label: 'Rendimineto objetivo',
      name: 'performanceGoal',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: { decimalScale: 0, suffix: '%' },
    },
    {
      label: 'Categoría',
      name: 'category',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(CategoriesData, 'listCategories'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Moneda',
      name: 'currency',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(CurrenciesData, 'listCurrencies'),
      mapData: { value: 'id', label: 'name' },
    },
    {
      label: 'Capital objetivo del fondo',
      name: 'fundCapitalGoal',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    {
      label: 'Valor cuota nominal',
      name: 'nominalFeeValue',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    {
      label: 'Cuotas suscritas y pagadas',
      name: 'subscribedPaidFees',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      label: 'Tiempo de vida (Meses)',
      name: 'lifetime',
      gridMD: 3,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        decimalScale: 0,
      },
    },
    {
      label: 'Inversión mínima',
      name: 'minimumInvestment',
      gridMD: 3,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    {
      label: '¿Coparticipación?',
      name: 'coParticipation',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: selectYesNo,
    },
    {
      label: 'Número máximo de copartícipes',
      name: 'maximumCoParticipants',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
    },
    {
      label: '¿Riesgo cambiario?',
      name: 'exchangeRateRisk',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: selectYesNo,
    },
    {
      gridMD: 6,
      inputVariant: INPUT_VARIANT.dateRange,
      fieldData: {
        startDate: {
          label: 'Fecha de acuerdo del reglamento inicial',
          name: 'startRegulationAgreementDate',
        },
        endDate: {
          label: 'Fecha de acuerdo del reglamento final',
          name: 'endRegulationAgreementDate',
        },
      },
    },
    {
      gridMD: 6,
      inputVariant: INPUT_VARIANT.dateRange,
      fieldData: {
        startDate: {
          label: 'Fecha de creación',
          name: 'createdAt',
        },
        endDate: {
          label: 'Fecha de liquidación',
          name: 'endedAt',
        },
      },
    },
    {
      label: 'Mecanismo de colocación',
      name: 'allocationMechanisms',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Habilitar Clases de Fondo',
      name: 'hasClasses',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: selectYesNo,
    },
    {
      label: 'Representante Bursátil',
      name: 'stockMarketRepresentative',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Fecha de delegación de la asamblea',
      name: 'assemblyDelegationDate',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.date,
    },
    {
      label: 'Tipo de renta',
      name: 'incomeType',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: incomeTypes,
    },
    {
      label: 'Cálculo TIR',
      name: 'tirCalculus',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: tirCalculusTypes,
    },
    {
      label: 'Tasa Fija',
      name: 'fixedRate',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  ];

  function updateFundBankAccounts(accountID) {
    if (fund) {
      const accounts = fund.accounts.edges.map(account => account.node.id);
      accounts.push(accountID);
      const [updateFunction] = updateFund;
      updateFunction({
        variables: {
          id,
          input: { accounts },
        },
      });
    }
  }

  const deleteInstrument = useMutation(
    DELETE_INSTRUMENT_FUND,
    getOptions({
      mutationName: 'deleteInstrumentFund',
      modelName: 'instrumentFund',
      message: 'Instrumento eliminado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createInstrumentData }) {
        if (cache.data.data.ROOT_QUERY[`fund({"id":"${id}"})`]) {
          const { fund: newFund } = cache.readQuery({
            query: GET_FUND,
            variables: { id },
          });
          newFund.instrumentfundSet.edges = newFund.instrumentfundSet.edges.filter(
            e =>
              e.node.id !==
              createInstrumentData.deleteInstrumentFund.instrumentFund.id,
          );
          cache.writeQuery({
            query: GET_FUND,
            variables: { id },
            data: { fund: newFund },
          });
        }
      },
    }),
    {},
  );

  function formatQuery(fund) {
    const formatValue = { ...fund };

    if (formatValue.id) {
      formatValue.startRegulationAgreementDate = stringToDate(
        formatValue.startRegulationAgreementDate,
      );
      formatValue.endRegulationAgreementDate = stringToDate(
        formatValue.endRegulationAgreementDate,
      );
      formatValue.assemblyDelegationDate = stringToDate(
        formatValue.assemblyDelegationDate,
      );
      formatValue.createdAt = stringToDate(formatValue.createdAt);
      formatValue.endedAt = stringToDate(formatValue.endedAt);

      formatValue.fundCapitalGoal = formatValue.fundCapitalGoal.toString();
      formatValue.nominalFeeValue = formatValue.nominalFeeValue.toString();
      formatValue.minimumInvestment = formatValue.minimumInvestment.toString();
      formatValue.fixedRate = formatValue.fixedRate
        ? formatValue.fixedRate.toString()
        : null;
      formatValue.performanceGoal = formatValue.performanceGoal.toString();
    }

    return formatValue.id ? formatValue : fund;
  }

  function formatBeforeSubmit(values) {
    const { logo, ...newValues } = { ...values };
    newValues.hasClasses = parseEquivalent(newValues.hasClasses);
    newValues.subscriptionRight = parseEquivalent(newValues.subscriptionRight);
    newValues.coParticipation = parseEquivalent(newValues.coParticipation);
    newValues.exchangeRateRisk = parseEquivalent(newValues.exchangeRateRisk);
    if (logo !== null) {
      newValues.logo = logo;
    }
    newValues.startRegulationAgreementDate = stringToDate(
      newValues.startRegulationAgreementDate,
    ).toISOString();
    newValues.endRegulationAgreementDate = stringToDate(
      newValues.endRegulationAgreementDate,
    ).toISOString();
    newValues.assemblyDelegationDate = stringToDate(
      newValues.assemblyDelegationDate,
    ).toISOString();
    newValues.createdAt = stringToDate(newValues.createdAt).toISOString();
    newValues.endedAt = stringToDate(newValues.endedAt).toISOString();
    delete newValues.instrumentfundSet;

    const accounts = values.accounts?.edges.map(element => {
      return element.node.id;
    });
    newValues.accounts = accounts;
    return newValues;
  }
  function instrumentFormatBeforeSubmit(values) {
    const newValues = { ...values };

    newValues.fund = id;
    return newValues;
  }
  function instrumentEdgeToList(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        const formatElement = { ...element.node };
        formatElement.instrument = formatElement.instrument.name;
        return formatElement;
      });
    }
    return [];
  }

  return (
    <>
      <Grid>
        <SimpleForm
          initialValues={startValues}
          validateFunction={validationFunctionFund}
          validateSchema={validationSchemaFund}
          formatBeforeSubmit={formatBeforeSubmit}
          data={data}
          model={formatQuery(fund)}
          id={id}
          modelName="Fondo"
          routeName=""
          isEdit={isEdit}
          updateMutation={updateFund}
          createMutation={createFund}
          deleteMutation={deleteFund}
          hasCancel
          onCancel={history.goBack}
        />
      </Grid>
      <br />
      <br />
      <Grid>
        {isEdit && (
          <>
            <InnerBankDataBankAccountList
              accountIdList={edgeToList(fund, 'accounts').map(ac => ac.id)}
              fundID={id}
              onBankAccountCreated={updateFundBankAccounts}
            />
            <br />
            <br />
            <CustomSubmodelList
              submodelName="Instrumento"
              submodelList={instrumentEdgeToList(fund, 'instrumentfundSet')}
              columns={instrumentColumns(deleteInstrument)}
              updateModelFunction={() => {}}
              // Form props
              getQuery={GET_INSTRUMENT_FUND}
              formatQuery={instrument => {
                const formatValue = { ...instrument.instrumentFund };
                formatValue.instrument = formatValue.instrument.id;
                return formatValue;
              }}
              mutationsData={instrumentMutationsData(id)}
              startValues={instrumentStartValues}
              validationSchema={instrumentValidationSchema}
              validationFunction={instrumentValidationFunction}
              formStructure={instrumentFormStructure(
                edgeToList(InstrumentsData, 'listInstrument'),
              )}
              formatBeforeSubmit={instrumentFormatBeforeSubmit}
            />
          </>
        )}
      </Grid>
    </>
  );
}

FundForm.propTypes = {
  fund: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

FundForm.defaultProps = {
  fund: null,
  id: '',
  isEdit: false,
};

export default FundForm;
