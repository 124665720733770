import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import NotFound from '../../NotFoundPage';
import PromoterBusiness from '../../../components/Models/PromoterComponent';
import PromoterCompanyForm from '../../../components/Models/PromoterComponent/Form';
import InterestedInvestor from '../../../components/Models/InterestedInvestor';
import InterestedInvestorForm from '../../../components/Models/InterestedInvestor/Form';

/**
 * @component
 */
function InterestedInvestorPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={InterestedInvestor} />
      <Route path={`${path}/:id`} component={InterestedInvestorForm} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default InterestedInvestorPage;
