import Yup from '../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

export const startData = {
  promoterCompanies: [],
  firstName: '',
  lastName: '',
  country: '',
  county: {},
  city: '',
  district: '',
  detail: '',
  representativeDocumentType: '',
  representativeDocumentNumber: '',
  email: '',
  promoterCompanyCommission: false,
  isCoreConsultant: false,
};

export const validationSchema = Yup.object().shape({
  promoterCompanies: Yup.array().required(),
  country: Yup.string().required(),

  detail: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  representativeDocumentType: Yup.string().required(),
  representativeDocumentNumber: Yup.number().required(),
  promoterCompanyCommission: Yup.bool().required(),
  isCoreConsultant: Yup.bool().required(),
  email: Yup.string()
    .email()
    .required(),
});

export const formStructure = (promoterCompanies, documentTypes) => [
  {
    isField: false,
    section: 'Organización',
    gridMD: 12,
    fields: [
      {
        label: '',
        name: 'promoterCompanies',
        data: promoterCompanies,
        mapData: { value: 'id', label: 'businessName' },
        inputVariant: INPUT_VARIANT.checkbox,
        other: false,
        gridMD: 12,
      },
    ],
  },
  {
    label: 'Tipo de documento',
    name: 'representativeDocumentType',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 6,
    data: documentTypes,
    mapData: { value: 'id', label: 'name' },
  },
  {
    label: 'Número de documento',
    name: 'representativeDocumentNumber',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.number,
  },
  {
    label: 'Nombres',
    name: 'firstName',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Apellidos',
    name: 'lastName',
    gridMD: 6,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Email',
    name: 'email',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Places',
    name: 'address',
    inputVariant: INPUT_VARIANT.places,
    gridMD: [12, 6, 6, 6],
    ubigeo: 6,
    indicators: {
      country: { name: 'country', label: 'País' },
      county: { name: 'county', label: 'Región' },
      city: { name: 'city', label: 'Provincia' },
      district: {
        name: 'district',
        label: 'Distrito',
      },
      ubigeo: { name: 'ubigeo', label: 'Ubigeo' },
    },
  },
  {
    label: 'Dirección',
    name: 'detail',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Comisión de empresa promotora',
    name: 'promoterCompanyCommission',
    inputVariant: INPUT_VARIANT.singleCheckbox,
    gridMD: 6,
  },
  {
    label: 'Asesor de Core',
    name: 'isCoreConsultant',
    inputVariant: INPUT_VARIANT.singleCheckbox,
    gridMD: 6,
  },
];

export const bankAccountValidationSchema = Yup.object().shape({});

export const bankAccountFormStructure = [];
