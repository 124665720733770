import Yup from './../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

export const ORIGIN = {
  NATIONAL: {
    id: 'N',
    name: 'Nacional',
    form: 'NATIONAL',
  },
  INTERNATIONAL: {
    id: 'I',
    name: 'Internacional',
    form: 'INTERNATIONAL',
  },
};
export const QUOTA_PROMOTION_CATEGORY = {
  EXTERN: {
    id: 'E',
    name: 'Externo',
    form: 'EXTERNAL',
  },
  SAFI: {
    id: 'S',
    name: 'SAFI',
    form: 'SAFI',
  },
};
export const PERSON_TYPE = {
  NATURAL: {
    id: 'N',
    name: 'Persona Natural',
    form: 'NATURAL',
  },
  LEGAL: {
    id: 'L',
    name: 'Persona Jurídica',
    form: 'LEGAL',
  },
};
export const SAFI_TYPE = {
  CROWDFUNDING_PLATFORM: {
    id: 'C',
    name: 'Plataforma de crowdfunding',
    form: 'CROWDFUNDING_PLATFORM',
  },
  INTERN_PROMOTERS: {
    id: 'I',
    name: 'Promotores internos',
    form: 'INTERN_PROMOTERS',
  },
  MULTIPLE_ADS: {
    id: 'A',
    name: 'Múltiples avisos (páginas web, correos)',
    form: 'MULTIPLE_ADS',
  },
};

export const startData = {
  ruc: '',
  businessName: '',
  country: '',
  county: {},
  city: '',
  district: '',
  detail: '',
  origin: '',
  quotaPromotionCategory: '',
  personType: '',
  safiType: '',
  representativeFirstName: '',
  representativeLastName: '',
  representativeDocumentType: '',
  representativeDocumentNumber: '',
  representativePhone: '',
  representativeEmail: '',
  contactFirstName: '',
  contactLastName: '',
  contactDocumentType: '',
  contactDocumentNumber: '',
  contactPhone: '',
  contactEmail: '',
};

export const validationSchema = Yup.object().shape({
  ruc: Yup.string()
    .matches(/^[0-9]{11}$/, 'El ruc debe tener 11 dígitos.')
    .required(),
  businessName: Yup.string().required(),
  country: Yup.string().required(),
  city: Yup.string().required(),
  district: Yup.string().required(),
  detail: Yup.string().required(),
  origin: Yup.string().required(),
  quotaPromotionCategory: Yup.string().required(),
  personType: Yup.string().when('quotaPromotionCategory', {
    is: QUOTA_PROMOTION_CATEGORY.EXTERN.id,
    then: Yup.string().required(),
  }),
  safiType: Yup.string().when('quotaPromotionCategory', {
    is: QUOTA_PROMOTION_CATEGORY.SAFI.id,
    then: Yup.string().required(),
  }),
  representativeFirstName: Yup.string().required(),
  representativeLastName: Yup.string().required(),
  representativeDocumentType: Yup.string().required(),
  representativeDocumentNumber: Yup.number().required(),
  representativePhone: Yup.string().required(),
  representativeEmail: Yup.string().required(),
  contactFirstName: Yup.string().required(),
  contactLastName: Yup.string().required(),
  contactDocumentType: Yup.string().required(),
  contactDocumentNumber: Yup.number().required(),
  contactPhone: Yup.string().required(),
  contactEmail: Yup.string().required(),
});

export const formStructure = (documentTypes, initialQuotaPromotionCategory) => [
  {
    label: 'RUC',
    name: 'ruc',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.number,
  },
  {
    label: 'Razón Social',
    name: 'businessName',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Places',
    name: 'address',
    inputVariant: INPUT_VARIANT.places,
    gridMD: [12, 6, 6, 6],
    ubigeo: 6,
    indicators: {
      country: { name: 'country', label: 'País' },
      county: { name: 'county', label: 'Región' },
      city: { name: 'city', label: 'Provincia' },
      district: {
        name: 'district',
        label: 'Distrito',
      },
      ubigeo: { name: 'ubigeo', label: 'Ubigeo' },
    },
  },
  {
    label: 'Dirección',
    name: 'detail',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.text,
  },
  {
    label: 'Origen',
    name: 'origin',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 12,
    data: Object.values(ORIGIN),
    mapData: { value: 'id', label: 'name' },
  },
  {
    isField: false,
    section: 'Promoción de Cuotas',
    gridMD: 12,
    fields: [
      {
        label: 'Categoría',
        name: 'quotaPromotionCategory',
        inputVariant: INPUT_VARIANT.conditionalSelects,
        gridMD: [12],
        data: Object.values(QUOTA_PROMOTION_CATEGORY),
        mapData: { value: 'id', label: 'name' },
        initialValue: initialQuotaPromotionCategory,
        items: [
          {
            value: QUOTA_PROMOTION_CATEGORY.EXTERN.id,
            selects: [
              {
                label: 'Tipo de Persona',
                name: 'personType',
                gridMD: 12,
                data: Object.values(PERSON_TYPE),
                mapData: { value: 'id', label: 'name' },
              },
            ],
          },
          {
            value: QUOTA_PROMOTION_CATEGORY.SAFI.id,
            selects: [
              {
                label: 'Tipo de SAFI',
                name: 'safiType',
                gridMD: 12,
                data: Object.values(SAFI_TYPE),
                mapData: { value: 'id', label: 'name' },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    isField: false,
    section: 'Representante',
    gridMD: 12,
    fields: [
      {
        label: 'Nombres',
        name: 'representativeFirstName',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Apellidos',
        name: 'representativeLastName',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Tipo de documento',
        name: 'representativeDocumentType',
        inputVariant: INPUT_VARIANT.select,
        gridMD: 4,
        data: documentTypes,
        mapData: { value: 'id', label: 'name' },
      },
      {
        label: 'Número de documento',
        name: 'representativeDocumentNumber',
        gridMD: 8,
        inputVariant: INPUT_VARIANT.number,
      },
      {
        label: 'Teléfono',
        name: 'representativePhone',
        gridMD: 4,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Correo',
        name: 'representativeEmail',
        gridMD: 8,
        inputVariant: INPUT_VARIANT.text,
      },
    ],
  },
  {
    isField: false,
    section: 'Contacto',
    gridMD: 12,
    fields: [
      {
        label: 'Nombres',
        name: 'contactFirstName',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Apellidos',
        name: 'contactLastName',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Tipo de documento',
        name: 'contactDocumentType',
        inputVariant: INPUT_VARIANT.select,
        gridMD: 4,
        data: documentTypes,
        mapData: { value: 'id', label: 'name' },
      },
      {
        label: 'Número de documento',
        name: 'contactDocumentNumber',
        gridMD: 8,
        inputVariant: INPUT_VARIANT.number,
      },
      {
        label: 'Teléfono',
        name: 'contactPhone',
        gridMD: 4,
        inputVariant: INPUT_VARIANT.text,
      },
      {
        label: 'Correo',
        name: 'contactEmail',
        gridMD: 8,
        inputVariant: INPUT_VARIANT.text,
      },
    ],
  },
];

export const bankAccountValidationSchema = Yup.object().shape({});

export const bankAccountFormStructure = [];
