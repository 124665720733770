import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    notFoundPage: {
      height: '100vh',
      '& h2': {
        textAlign: 'center',
        color: theme.palette.secondary.main,
      },
      '& h1': {
        fontSize: '2.5rem',
        textAlign: 'center',
      },
    },
    header: {
      height: 200,
      backgroundColor: theme.palette.primary.light,
    },
    image: {
      margin: 25,
      height: 100,
      alignSelf: 'center',
    },
    // new not found
    main: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    img: {
      height: ' 50px',
      marginBottom: '10px',
      alignSelf: 'center',
    },
    oops: {
      fontWeight: 700,
      fontSize: '90px',
      textAlign: 'start',
      color: '#000',
      margin: 0,
    },
    subTitle: {
      fontWeight: 500,
      fontSize: '20px',
      textAlign: 'start',
      color: '#353535',
      margin: 0,
    },
    oopsDark: {
      fontWeight: 700,
      fontSize: '90px',
      textAlign: 'start',
      color: '#dddddd',
      margin: 0,
    },
    subTitleDark: {
      fontWeight: 600,
      fontSize: '25px',
      textAlign: 'start',
      color: '#BEBEBE',
      margin: 0,
    },
    messageDark: {
      fontWeight: 600,
      fontSize: '15px',
      textAlign: 'start',
      color: '#BEBEBE',
      margin: 0,
    },
    messageLight: {
      fontWeight: 600,
      fontSize: '15px',
      textAlign: 'start',
      color: '#353535',
      margin: 0,
    },
  }),
);
