// GraphQl
import { gql } from 'apollo-boost';

export const GET_REPORT_EXCEL = gql`
  {
    clientsExcel {
      xlsx
      filename
    }
  }
`;

export const LIST_USERS = gql`
  query listUsers($userType: String) {
    listUsers(userType: $userType) {
      edges {
        node {
          id
          firstName
          lastName
          maternalSurname
          isStaff
          isActive
          email
          userType
          documentType {
            id
          }
          documentNumber
        }
      }
    }
  }
`;

export const LIST_USERS_PROMOTERS = gql`
  query listUsersPromoters($userTypes: [String]) {
    listUsers(userType_In: $userTypes) {
      edges {
        node {
          id
          firstName
          lastName
          maternalSurname
          isStaff
          isActive
          email
          userType
          documentType {
            id
          }
          documentNumber
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      maternalSurname
      isStaff
      email
      userType
      documentType {
        id
      }
      documentNumber
      clientSet {
        edges {
          node {
            id
            state
            isMain
            enviarComunicacion
            nombreComunicacion
            nombreCanalActual
            nombreCanalOrigen
            correoIntermediario
            canalActual
            canalOrigen
            promoter {
              id
            }
            promoterCompany {
              id
            }
            coreConsultant {
              id
            }
          }
        }
      }
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        email
        documentType {
          id
        }
        documentNumber
        clientSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        email
        documentType {
          id
        }
        documentNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        email
        documentType {
          id
        }
        documentNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_CLIENT_INFO = gql`
  query client($id: ID!) {
    client(id: $id) {
      id
      pdfSheet
      signedPdfStatus
      lastSignatureDate
      urlRiskReport
      photoDocumentFront
      photoDocumentBack
      photoDocumentsUrl
      core2Uuid
      promoter {
        id
        firstName
        lastName
      }
      coreConsultant {
        id
        firstName
        lastName
      }
      promoterCompany {
        id
        businessName
      }
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        isActive
        email
        userType
        documentType {
          id
          code
        }
        documentNumber
      }
    }
  }
`;

export const LIST_CLIENT_USERS = gql`
  query ListClientsUsers(
    $search: String
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
  ) {
    listClients(
      state: "client"
      search: $search
      first: $first
      last: $last
      before: $beforeCursor
      after: $afterCursor
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          pdfSheet
          signedPdfStatus
          lastSignatureDate
          urlRiskReport
          photoDocumentFront
          photoDocumentBack
          photoDocumentsUrl
          core2Uuid
          enviarComunicacion
          nombreComunicacion
          nombreCanalActual
          nombreCanalOrigen
          correoIntermediario
          canalActual
          canalOrigen
          promoter {
            id
            firstName
            lastName
          }
          coreConsultant {
            id
            firstName
            lastName
          }
          promoterCompany {
            id
            businessName
          }
          user {
            id
            firstName
            lastName
            maternalSurname
            isStaff
            isActive
            email
            userType
            documentType {
              id
              code
            }
            documentNumber
          }
        }
      }
    }
  }
`;

export const LIST_CLIENT_USERS_ALL = gql`
  query ListClientsUsers {
    listClients {
      edges {
        node {
          id
          pdfSheet
          signedPdfStatus
          lastSignatureDate
          urlRiskReport
          photoDocumentFront
          photoDocumentBack
          photoDocumentsUrl
          promoter {
            id
            firstName
            lastName
          }
          coreConsultant {
            id
            firstName
            lastName
          }
          promoterCompany {
            id
          }
          user {
            id
            firstName
            lastName
            maternalSurname
            isStaff
            isActive
            email
            userType
            documentType {
              id
            }
            documentNumber
          }
        }
      }
    }
  }
`;

export const LIST_REJECTED_CLIENT_USERS = gql`
  query ListClientsUsers {
    listClients(state: "rejected") {
      edges {
        node {
          id
          user {
            id
            firstName
            lastName
            maternalSurname
            isStaff
            isActive
            email
            userType
            documentType {
              id
            }
            documentNumber
          }
          urlRiskReport
          state
          promoter {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const UDPATE_CLIENT_INTERESTED_INVESTORS = gql`
  mutation updateClientInterestedInvestor($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          firstName
          lastName
          maternalSurname
          email
          isActive
          documentType {
            id
            name
          }
          documentNumber
        }
        promoterCompany {
          id
          businessName
        }
        promoter {
          id
          firstName
          lastName
        }
        alternativeDocumentType
        cellphone
        riskConsultingMessage
        state
        isRiskConsulted
        leadsChannel
        leadsChannelDetail
        leadsRange
        leadsProducts {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const LIST_PROMOTERS = gql`
  query listPromoter {
    listPromoter {
      edges {
        node {
          id
          firstName
          lastName
          enabled
          isCoreConsultant
        }
      }
    }
  }
`;

export const LIST_CANAL_ACTUAL = gql`
  query canalesActual {
    canalesActual {
      name
      value
    }
  }
`;

export const LIST_CANAL_ORIGEN = gql`
  query canalesOrigen {
    canalesOrigen {
      name
      value
    }
  }
`;
