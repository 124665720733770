import { gql } from 'apollo-boost';

export const LIST_CLIENT_SECONDARY_MARKET_CONTRACTS = gql`
  query listSecondaryMarketVerified {
    listSecondaryMarketVerified {
      edges {
        node {
          id
          registrationDate
          fund {
            id
            businessName
          }
        }
      }
    }
  }
`;

export const GET_CONTRACT_BY_SECONDARY_MARKET = gql`
  query contractTypeBySecondaryMarket($secondaryMarketId: ID) {
    contractTypeBySecondaryMarket(secondaryMarketId: $secondaryMarketId) {
      name
      contractText
      annexText1
      annexText2
      condition
    }
  }
`;

export const GET_CONTRACT_BY_SECONDARY_MARKET_COPARTICIPANTS = gql`
  query contractTypeBySecondaryMarket($secondaryMarketId: ID) {
    contractTypeBySecondaryMarket(secondaryMarketId: $secondaryMarketId) {
      annexText2
    }
  }
`;

export const GET_CONTRACT_SECONDARY_MARKET_METADATA = gql`
  query secondaryMarket($id: ID) {
    secondaryMarket(id: $id) {
      id
      state
      registrationDate
      feeSaleValue
      contractSigningDate
      fund {
        id
        businessName
        currency {
          symbol
          code
        }
      }
      buyer {
        edges {
          node {
            id
            participationPercentage
            client {
              id
              user {
                id
                firstName
                lastName
                maternalSurname
              }
            }
          }
        }
      }
      seller {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
              maternalSurname
            }
          }
        }
      }
    }
  }
`;

export const GET_SWORN_DECLARATION_BY_SECONDARY_MARKET = gql`
  query clientTypeSecondaryMarketSwornDeclaration($secondaryMarketId: ID) {
    clientTypeSecondaryMarketSwornDeclaration(
      secondaryMarketId: $secondaryMarketId
    ) {
      text
    }
  }
`;

export const SAVE_SECONDARY_MARKET_CONTRACT_DATA = gql`
  mutation saveSecondaryMarketContractData(
    $id: ID
    $isDataTreatment: Boolean!
    $clients: [SecondaryMarketContractInput]
  ) {
    saveSecondaryMarketContractData(
      id: $id
      isDataTreatment: $isDataTreatment
      clients: $clients
    ) {
      secondaryMarket {
        id
        state
      }
    }
  }
`;

export const CONFIRM_SECONDARY_MARKET_CONTRACT_SIGNATURE = gql`
  mutation signSecondaryMarketContract(
    $key: String!
    $secondaryMarketId: ID!
    $clientId: ID!
  ) {
    signSecondaryMarketContract(
      key: $key
      secondaryMarketId: $secondaryMarketId
      clientId: $clientId
    ) {
      secondaryMarket {
        id
        state
        contractSigningDate
      }
    }
  }
`;

export const CREATE_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE = gql`
  mutation createSecondaryMarketSwornDeclarationSignature(
    $secondaryMarketId: ID!
  ) {
    createSecondaryMarketSwornDeclarationSignature(
      secondaryMarketId: $secondaryMarketId
    ) {
      created
    }
  }
`;

export const CONFIRM_SECONDARY_MARKET_SWORN_DECLARATION_SIGNATURE = gql`
  mutation signSecondaryMarketSwornDeclaration($key: String!, $fundId: ID!) {
    signSecondaryMarketSwornDeclaration(key: $key, fundId: $fundId) {
      secondaryMarket {
        id
        state
      }
    }
  }
`;
