import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  icon: {
    fontSize: 350,
  },
  tableTitle: {
    fontWeight: 500,
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: '4px 4px',
  },
}));
