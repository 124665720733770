/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import PropertyRegimeList from '../../../components/Models/PropertyRegime/PropertyRegimeList';
import PropertyRegimeEdit from '../../../components/Models/PropertyRegime/PropertyRegimeCreateEdit/edit';
import PropertyRegimeForm from '../../../components/Models/PropertyRegime/PropertyRegimeForm';

function PropertyRegimePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={PropertyRegimeList} />
      <Route path={`${path}/crear`} component={PropertyRegimeForm} />
      <Route path={`${path}/:id`} component={PropertyRegimeEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default PropertyRegimePage;
