import React, { useState, useEffect } from 'react';

// Material Resources
import { Grid } from '@material-ui/core';

// GraphQl
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';

// Others
import PropTypes from 'prop-types';
import CustomSelect from '../CustomSelect';
import LoadingIndicator from '../../LoadingIndicator';

// Styles & Images
import useStyles from './styles';

const LIST_CLIENT_SHEET_TEXTS = gql`
  {
    listClientSheetTexts {
      edges {
        node {
          id
          relation
          domiciled
          institutionalInvestor
          pep
          usPerson
          dataProcessingAuthorization
          termsAndConditions
        }
      }
    }
  }
`;

function ClientTexts(props) {
  const { type, text } = props;
  const classes = useStyles();

  const [
    getTexts,
    { error: textsError, data: textsData, loading: textsLoading },
  ] = useLazyQuery(LIST_CLIENT_SHEET_TEXTS);

  useEffect(() => {
    if (text === '') getTexts();
  }, []);
  if (textsLoading) return <LoadingIndicator />;
  if (textsError) {
    console.error('client sheet texts', textsError);
  }

  return (
    <>
      {textsData ? (
        <p className={classes.paragraph}>
          {textsData.listClientSheetTexts.edges[0].node[type]}
        </p>
      ) : (
        <p className={classes.paragraph}>{text}</p>
      )}
    </>
  );
}
ClientTexts.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

ClientTexts.defaultProps = {
  type: '',
  text: '',
};

export default ClientTexts;
