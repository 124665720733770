import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import LoadingIndicator from '../../../../../LoadingIndicator';
import RepurchaseForm from '../../Repurchases/RepurchaseForm';
import { GET_RAISING_FUND } from '../../Repurchases/queries';
import { errorMessage } from '../../../../../../utils/commonFunctions';

function CreateEdit() {
  const { id } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, data } = useQuery(GET_RAISING_FUND, {
    variables: { id },
  });
  if (loading) {
    return <LoadingIndicator />;
  }
  if (error) {
    errorMessage({ message: 'Error al obtener recompra.' }, enqueueSnackbar, {
      callback: history.goBack(),
    });
  }
  const { repurchase } = data;
  if (!repurchase) {
    history.goBack();
  }

  return <RepurchaseForm repurchase={repurchase} id={id} isEdit />;
}

export default CreateEdit;
