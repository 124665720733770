import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import { useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import uniqBy from 'lodash.uniqby';

import { LIST_CLIENT_PAYMENTS } from './queries';
import PaymentEdit from './PaymentCreateEdit/edit';
import CustomCard from '../../CustomCard';
import CustomModal from '../../CustomModal';
import Money from '../../Money';
import Date from '../../Date';
import {
  generateColumn,
  edgeToList,
  errorMessage,
} from '../../../utils/commonFunctions';
import { textLabels } from '../../../translations/components/MUIDataTable';
import { getClientSelector } from '../../../store/sharedStore/selectors';
import LoadingIndicator from '../../LoadingIndicator';
import useClasses from './styles';

const LINKED_PAYMENT = 'LP';

function PaymentList() {
  const [paymentsList, setPaymentsList] = useState([]);
  const [fundToFilter, setFundToFilter] = useState('');
  const [filteredPaymentsList, setFilteredPaymentsList] = useState([]);
  const [funds, setFunds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const client = useSelector(getClientSelector());
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useClasses();
  const [
    getClientPayments,
    { data: paymentsData, loading, error: paymentsError },
  ] = useLazyQuery(LIST_CLIENT_PAYMENTS);

  const parsePaymentsList = useCallback(
    list =>
      list.map(payment => {
        setFunds(f =>
          uniqBy([...f, payment.participant.wheel.raising.fund], 'id')
        );
        return {
          ...payment,
          amount: {
            amount: payment.amount,
            currency: payment.participant.wheel.raising.fund.currency.symbol,
          },
          bankName: payment.bank.name,
          fundName: payment.participant.wheel.raising.fund.businessName,
          raisingNumber: payment.participant.wheel.raising.number,
          options: {
            id: payment.id,
            state: payment.participant.state,
          },
        };
      }),
    []
  );

  useEffect(() => {
    if (paymentsData) {
      const list = edgeToList(paymentsData, 'listPayments');
      setPaymentsList(parsePaymentsList(list));
    }
    if (paymentsError) {
      errorMessage(paymentsError, enqueueSnackbar);
    }
  }, [paymentsData, paymentsError, enqueueSnackbar, parsePaymentsList]);

  useEffect(() => {
    if (fundToFilter) {
      setFilteredPaymentsList(
        paymentsList.filter(p => p.fundName === fundToFilter)
      );
    } else {
      setFilteredPaymentsList(paymentsList);
    }
  }, [fundToFilter, paymentsList]);

  useEffect(() => {
    if (client) {
      getClientPayments({ variables: { clientId: client.id } });
    }
  }, [client, getClientPayments]);

  const columnOptions = {
    filter: false,
    sort: false,
  };

  const listColumns = [
    generateColumn('Fondo', 'fundName', columnOptions),
    generateColumn('Levantamiento', 'raisingNumber', columnOptions),
    generateColumn('Fecha', 'date', {
      ...columnOptions,
      customBodyRender: value => {
        return <Date date={value} />;
      },
    }),
    generateColumn('Monto', 'amount', {
      ...columnOptions,
      customBodyRender: value => {
        return <Money currency={value.currency} amount={value.amount} />;
      },
    }),
    generateColumn(
      'Número de operación',
      'operationNumber',
      false,
      columnOptions
    ),
    generateColumn('Banco', 'bankName', columnOptions),
    generateColumn('', 'options', { display: false }),
  ];

  const handleRowClick = (rowData, rowMeta) => {
    if (rowData[rowData.length - 1].state === LINKED_PAYMENT) {
      setSelectedID(rowData[rowData.length - 1].id);
      setShowModal(true);
    } else {
      errorMessage('Este pago no puede ser editado.', enqueueSnackbar);
    }
  };

  const listOptions = {
    responsive: 'standard',
    selectableRows: 'none',
    filter: false,
    print: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <FormControl className={classes.fundSelect}>
            <InputLabel id="fund-filter-label">Fondo</InputLabel>
            <Select
              labelId="fund-filter-label"
              id="fund-filter"
              value={fundToFilter}
              onChange={event => setFundToFilter(event.target.value)}
            >
              <MenuItem value="">Ninguno</MenuItem>
              {funds.map(f => (
                <MenuItem key={f.id} value={f.businessName}>
                  {f.businessName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Registrar Nuevo Pago">
            <IconButton
              color="secondary"
              onClick={() => history.push(`${history.location.pathname}/crear`)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    onRowClick: rowData => {
      handleRowClick(rowData);
    },
  };

  function hideModal() {
    setShowModal(false);
  }

  return (
    <CustomCard
      disableFlex
      showHeader={false}
      content={
        loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <MUIDataTable
              options={listOptions}
              title="Registro de Pagos"
              columns={listColumns}
              data={filteredPaymentsList}
            />
            <CustomModal
              showModal={showModal}
              title="Editar Registro de Pago"
              onClosePress={hideModal}
            >
              <div className={classes.modalContainer}>
                <PaymentEdit id={selectedID} updateCallback={hideModal} />
              </div>
            </CustomModal>
          </>
        )
      }
    />
  );
}

export default PaymentList;
