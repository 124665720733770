import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    '& a': {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& .MuiBreadcrumbs-ol': {
      alignItems: 'center',
    },
    '& .MuiTypography-colorTextSecondary': {
      color: theme.palette.primary.contrastText,
      fontSize: 20,
      alignItems: 'center',
    },

    '& .MuiBreadcrumbs-separator': {
      color: theme.palette.secondary.contrastText,
      fontWeight: 700,
      alignItems: 'center',
    },
  },
  titleDark: {
    color: '#FFF',
    fontWeight: 600,
    fontSize: 20,
    fontFamily: 'Montserrat',
    '& .MuiTypography-colorTextSecondary': {
      color: '#FFF',
      fontSize: 20,
      alignItems: 'start',
    },
    '& .MuiBreadcrumbs-ol': {
      alignItems: 'start',
    },
    '& .MuiBreadcrumbs-separator': {
      color: '#FFF',
      fontWeight: 700,
      alignItems: 'start',
    },
  },
  coreCapitalLink: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: 'uppercase',
  },
  normalLink: {
    fontWeight: 500,
    letterSpacing: 1,
    fontSize: 12,
  },
  logo: {
    height: 27,
    position: 'relative',
    paddingLeft: '10px',
  },
  logoCore: {
    height: 55,
    position: 'relative',
    paddingLeft: '10px',
  },
}));
