import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
  GET_CLIENT_HOLDER_INFORMATION,
  CREATE_CLIENT_PARTICIPATION,
  UPDATE_CLIENT_PARTICIPATION,
  DELETE_CLIENT_PARTICIPATION,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import { getOptions } from '../../../../utils/commonFunctions';
import CreateEditTable from '../../../CreateEditTable';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

const validationSchema = Yup.object().shape({
  businessName: Yup.string().required(),
  ruc: Yup.string()
    .matches(/^[0-9]{11}$/, 'El ruc debe tener 11 dígitos.')
    .required(),
  participationPercentage: Yup.number()
    .min(25, 'Debe ser un valor mayor a 25.')
    .max(100, 'Debe ser un valor menor a 100.')
    .required(),
});

function ParticipationTable(props) {
  const { clientId, data: participationData } = props;
  const { enqueueSnackbar } = useSnackbar();

  const createParticipation = useMutation(
    CREATE_CLIENT_PARTICIPATION,
    getOptions({
      mutationName: 'createClientParticipation',
      modelName: 'participation',
      message: 'Se creó la participación con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_HOLDER_INFORMATION, variables: { id: clientId } },
      ],
    }),
  );
  const updateParticipation = useMutation(
    UPDATE_CLIENT_PARTICIPATION,
    getOptions({
      mutationName: 'updateClientParticipation',
      modelName: 'participation',
      message: 'Participación actualizada con éxito.',
      enqueueSnackbar,
    }),
  );
  const deleteParticipation = useMutation(
    DELETE_CLIENT_PARTICIPATION,
    getOptions({
      mutationName: 'deleteClientParticipation',
      modelName: 'participation',
      message: 'Participación eliminada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_HOLDER_INFORMATION, variables: { id: clientId } },
      ],
    }),
  );

  function formatBeforeSubmit(values) {
    const formatValues = { ...values };
    formatValues.relation = 'HOLDER';
    formatValues.client = clientId;
    return formatValues;
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const formStructure = [
    {
      label: 'Razón Social',
      name: 'businessName',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      label: 'RUC',
      name: 'ruc',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.number,
    },
    {
      label: '% de participación',
      name: 'participationPercentage',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.number,
    },
  ];

  const startValuesParser = values => {
    if (!values) {
      return {
        businessName: '',
        ruc: '',
        participationPercentage: '',
      };
    }
    return values;
  };

  return (
    <CreateEditTable
      table={{
        columns: [
          { label: 'Razón Social', name: 'businessName' },
          { label: 'RUC', name: 'ruc' },
          { label: '% de participación', name: 'participationPercentage' },
        ],
        data: participationData,
      }}
      mutations={{
        create: createParticipation,
        update: updateParticipation,
        _delete: deleteParticipation,
      }}
      strings={{
        tableName: 'Participación',
        modelName: 'Participación',
        createButtonText: 'Añadir participación',
      }}
      form={{
        startValuesParser,
        validationFunction,
        validationSchema,
        formatBeforeSubmit,
        structure: formStructure,
      }}
    />
  );
}

ParticipationTable.propTypes = {
  clientId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ParticipationTable;
