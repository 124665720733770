import { makeStyles, fade, zIndex } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  clientFormPage: {
    display: 'flex',
  },
  root: {
    position: 'fixed',
    width: '21%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
  },
  rootMenuTree: {
    backgroundColor: theme.palette.colors.withe,
    padding: '2%',
  },
  itemRoot: {
    '&:focus > .MuiTreeItem-content': {
      backgroundColor: 'transparent',
    },
  },
  treeItem: {
    backgroundColor: theme.palette.secondary.light,
  },
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  itemComplete: {
    extend: 'iconContainer',
    color: theme.palette.primary.dark,
  },
  itemEditing: {
    extend: 'iconContainer',
    color: theme.palette.colors.green,
  },
  itemHasChange: {
    extend: 'iconContainer',
    color: theme.palette.colors.orange,
  },
  itemIncomplete: {
    extend: 'iconContainer',
    color: theme.palette.colors.red,
  },
  itemLabel: {
    fontSize: 16,
  },
  customTreeIcon: {
    fontSize: '1.2rem',
  },
  customTreeIconExpand: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
  treeContainer: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    borderRight: '2px solid',
    borderRightColor: theme.palette.colors.gray,
  },
  treeContainerDark: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    borderRight: '2px solid',
    borderRightColor: 'rgba(102, 102, 102, 0.04)',
    color: '#fff',
    background:
      'linear-gradient(150deg, rgba(50, 50, 50, 0.4) 76%, rgba(255, 255, 255, 0.00) 100%)',
  },
  forms: {
    paddingLeft: 10,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  floatingButton: {
    position: 'fixed',
    top: 70,
    right: 20,
    zIndex: theme.zIndex.modal,
  },
  layoutEmployee: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:
    ' linear-gradient(180deg, rgba(255, 255, 255, 0.90) 27.49%, rgba(255, 255, 255, 0.00) 65%)',

      [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },
  layoutEmployeeDark: {
    padding: '10px',
    display: 'flex',
    width: '86vw',
    height: '80vh',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: ' 2px',
    border: '1px solid rgba(0, 0, 0, 0.10)',
    overflow: 'scroll',
    background:'linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0.40) 100%)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      overflow: 'scroll',
      padding: '0px',
      justifyContent: 'space-between',
    },
  },
}));
