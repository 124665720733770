import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    sectionText: {
      paddingTop: 25,
    },
    inputContainer: {
      paddingTop: 15,
    },
    text: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    table: {
      marginTop: 40,
    },
    tableHead: {
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      '& h3': {
        fontWeight: 600,
        fontSize: 18,
        textTransform: 'uppercase',
      },
    },
    rowIcon: {
      height: 50,
      width: 'auto',
      color: theme.palette.secondary.light,
    },
    squareBullet: {
      color: theme.palette.secondary.light,
    },
    listTitle: {
      '& .MuiTypography-root': {
        fontWeight: 500,
        lineHeight: 0,
      },
    },
    leftDivider: {
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.secondary.main,
      borderLeftStyle: 'solid',
    },
    greenIcon: {
      color: theme.palette.colors.green,
    },
  })
);
