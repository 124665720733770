import React from 'react';

// Styles & Images
import useStyles from './styles';

function SectionDivider() {
  const classes = useStyles();
  return <div className={classes.divider} />;
}

export default SectionDivider;
