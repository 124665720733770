import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  password: {
    '& .MuiButton-label': {
      fontSize: 12,
      fontWeight: 'normal',
      padding: 3,
      textTransform: 'none',
    },
  },
  loginPage: {
    display: 'flex',
    height: '100vh',
    padding: '5%',
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    alignItems: 'center',
    justifyContent: 'center',
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.dark} 90%)`,

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
    },
    '& .MuiInputBase-input': {
      minHeight: '50px',

      color: theme.palette.primary.contrastText,
    },
    '& .MuiInputBase-root': {
      color: theme.palette.primary.dark,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.dark,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.primary.dark,
    },
  },

  card: {
    border: '8px solid',
    borderRadius: 0,
    borderColor: theme.palette.secondary.main,
    background: theme.palette.primary,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 160,
    width: '75%',
  },
  errorMessage: {
    color: theme.palette.colors.error,
    fontWeight: 600,
  },
  restorePassword: {
    borderRadius: 5,
    '& .MuiButton-label': {
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  img: {
    height: ' 50px',
    marginBottom: '10px',
    alignSelf: 'center',
  },
}));
