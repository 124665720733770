import React from 'react';

function ModeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="19"
      fill="none"
      viewBox="0 0 21 19"
    >
      <mask id="path-1-inside-1_40_2425" fill="#fff">
        <path d="M10 18.677c2.652.002 5.197-.961 7.074-2.678 1.877-1.717 2.934-4.048 2.937-6.48.003-2.43-1.048-4.763-2.922-6.484-1.873-1.72-4.415-2.69-7.067-2.692L10.01 9.51 10 18.677z" />
      </mask>
      <path
        stroke="#8E9193"
        strokeOpacity="0.71"
        strokeWidth="2"
        d="M10 18.677c2.652.002 5.197-.961 7.074-2.678 1.877-1.717 2.934-4.048 2.937-6.48.003-2.43-1.048-4.763-2.922-6.484-1.873-1.72-4.415-2.69-7.067-2.692L10.01 9.51 10 18.677z"
        mask="url(#path-1-inside-1_40_2425)"
      />
      <mask id="path-2-inside-2_40_2425" fill="#fff">
        <path d="M10.103.386c-2.652-.012-5.2.943-7.085 2.655C1.134 4.75.07 7.079.057 9.51c-.012 2.431 1.03 4.767 2.896 6.494 1.867 1.727 4.405 2.704 7.058 2.715l.046-9.167.046-9.166z" />
      </mask>
      <path
        fill="#8E9193"
        stroke="#8E9193"
        strokeWidth="2"
        d="M10.103.386c-2.652-.012-5.2.943-7.085 2.655C1.134 4.75.07 7.079.057 9.51c-.012 2.431 1.03 4.767 2.896 6.494 1.867 1.727 4.405 2.704 7.058 2.715l.046-9.167.046-9.166z"
        mask="url(#path-2-inside-2_40_2425)"
      />
    </svg>
  );
}

export default ModeIcon;
