import React from 'react';

// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_CLASS_FUND,
  CREATE_CLASS_FUND,
  DELETE_CLASS_FUND,
  LIST_CLASS_FUND,
} from '../queries';
import { LIST_FUNDS } from '../../Fund/queries';
import {
  validationFunctionInstrument,
  validationSchemaInstrument,
} from './validation';
import { geographicalFocusTypes, assetClassTypes } from '../../Fund/constants';
import { edgeToList, getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
  minimumTir: 0,
  maximumTir: 0,
};

function FundClassForm(props) {
  const { clase, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, data: listFunds } = useQuery(LIST_FUNDS);

  const updateClassFund = useMutation(
    UPDATE_CLASS_FUND,
    getOptions({
      mutationName: 'updateClassFund',
      modelName: 'classFund',
      message: 'Clase actualizada con éxito.',
      enqueueSnackbar,
    }),
  );
  const createClassFund = useMutation(
    CREATE_CLASS_FUND,
    getOptions({
      mutationName: 'createClassFund',
      modelName: 'classFund',
      message: 'Clase creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createClassFundData }) {
        if (cache.data.data.ROOT_QUERY.listClassFund) {
          const { listClassFund } = cache.readQuery({
            query: LIST_CLASS_FUND,
          });
          listClassFund.edges.unshift({
            // Be careful with types
            __typename: 'ClassFundTypeEdge',
            node: createClassFundData.createClassFund.classFund,
          });
          cache.writeQuery({
            query: LIST_CLASS_FUND,
            data: { listClassFund },
          });
        }
        history.push('/administrador/clases');
      },
    }),
  );
  const deleteClassFund = useMutation(
    DELETE_CLASS_FUND,
    getOptions({
      mutationName: 'deleteClassFund',
      message: 'ClassFund eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listClassFund) {
          const { listClassFund } = cache.readQuery({
            query: LIST_CLASS_FUND,
          });
          listClassFund.edges = listClassFund.edges.filter(
            e => e.node.id !== id,
          );
          cache.writeQuery({
            query: LIST_CLASS_FUND,
            data: { listClassFund },
          });
        }
        history.push('/administrador/clases');
      },
    }),
  );

  const data = [
    {
      label: 'Clase',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Foco Geográfico',
      name: 'geographicalFocus',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: geographicalFocusTypes,
    },
    {
      label: 'Asset Class',
      name: 'assetClass',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: assetClassTypes,
    },
    {
      label: 'TIR Mínimo',
      name: 'minimumTir',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
    {
      label: 'TIR Máximo',
      name: 'maximumTir',
      gridMD: 6,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.formatNumber,
      formatprops: {
        thousandSeparator: true,
        decimalScale: 2,
        fixedDecimalScale: true,
      },
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionInstrument}
        validateSchema={validationSchemaInstrument}
        data={data}
        model={clase}
        id={id}
        modelName="Clases"
        routeName="/administrador/clases"
        isEdit={isEdit}
        updateMutation={updateClassFund}
        createMutation={createClassFund}
        deleteMutation={deleteClassFund}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

export default FundClassForm;
