/*
 * HomePage
 */

import React, { useState } from 'react';
import style from '../LoginPage/login.module.css';
// Material Resources
import { Button, Grid, InputAdornment } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

// GraphQl
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

// Others
import { Formik, Form, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import { useSnackbar } from 'notistack';

// Styles & Images
import useStyles from './styles';
import goldenLogo from '../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';

import Yup from '../../utils/yup';
import { successMessage, errorMessage } from '../../utils/commonFunctions';
import BackArrowIcon from '../LoginPage/assets/BackArrowIcon';
const SET_EMAIL_STEP = 0;

function SendRecoverMailPage(props) {
  const { handlerRecoverPass } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const SEND_MAIL = gql`
    mutation resetPassword($usermail: String!) {
      resetPassword(input: { email: $usermail }) {
        ok
        message
      }
    }
  `;

  const [resetPasswordMutation] = useMutation(SEND_MAIL, {
    onCompleted() {
      setActiveStep(activeStep + 1);
      successMessage(
        'El correo ha sido enviado correctamente.',
        enqueueSnackbar,
      );
      handlerRecoverPass()
      setLoading(false);
    },
    onError() {
      errorMessage(
        'No existe un usuario asociado a este correo.',
        enqueueSnackbar,
      );
      setLoading(false);
    },
  });

  return (
    <>
      <div
        style={{
          zIndex: 999,
        }}
      >
        <Formik
          initialValues={{
            usermail: '',
          }}
          validationSchema={Yup.object().shape({
            usermail: Yup.string()
              .required('Este campo es obligatorio')
              .matches(
                /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                'Ingrese una dirección de correo válida',
              ),
          })}
          onSubmit={values => {
            setLoading(true);
            values.usermail = values.usermail.toLowerCase();
            resetPasswordMutation({
              variables: {
                usermail: values.usermail,
              },
            });
          }}
        >
          {({ submitForm }) => (
            <>
              <Form>
                <div onClick={handlerRecoverPass} className={classes.formdiv}>
                  <BackArrowIcon />
                  <p>Volver a Iniciar Sesion</p>
                </div>
                {activeStep === SET_EMAIL_STEP && (
                  <>
                    <TextField
                      name="usermail"
                      label="Email"
                      type="text"
                      placeholder="Ingrese su correo"
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={loading}
                      fullWidth
                      inputProps={{
                        pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* 
<ErrorMessage name="usermail">
            {msg => <p className={classes.errorText}>{msg}</p>}
          </ErrorMessage> */}
                    <Button
                      style={{
                        width: '100%',
                        marginBottom: 10,
                        textTransform: 'capitalize',
                        borderRadius: '8px',
                        background: '#007DFA',
                        height: '35px',

                        textAlign: 'center',
                      }}
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      onClick={submitForm}
                      type="submit"
                      id="send_forgot_password_button"
                    >
                      Enviar
                    </Button>
                  </>
                )}
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}

// SendRecoverMailPage.propTypes = {};

export default SendRecoverMailPage;
