import { createSelector } from 'reselect';

function selectApp(state) {
  return state.get('sharedStore');
}

function createClientFormSelector(name) {
  return createSelector(selectApp, appState => appState.get(name));
}

export function getPromoterCompanyBankAccountIdSelector() {
  return createClientFormSelector('promoterCompanyBankAccountId');
}

export function getPromoterBankAccountIdSelector() {
  return createClientFormSelector('promoterBankAccountId');
}

export function getClientSelector() {
  return createClientFormSelector('client');
}

export function getPromoterSelector() {
  return createClientFormSelector('promoter');
}


