import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_INSTRUMENT,
  CREATE_INSTRUMENT,
  DELETE_INSTRUMENT,
  LIST_INSTRUMENTS,
} from '../queries';
import {
  validationFunctionInstrument,
  validationSchemaInstrument,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function InstrumentForm(props) {
  const { instrument, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateInstrument = useMutation(
    UPDATE_INSTRUMENT,
    getOptions({
      mutationName: 'updateInstrument',
      modelName: 'instrument',
      message: 'Instrumento actualizado con éxito.',
      enqueueSnackbar,
    })
  );
  const createInstrument = useMutation(
    CREATE_INSTRUMENT,
    getOptions({
      mutationName: 'createInstrument',
      modelName: 'instrument',
      message: 'Instrumento creado con éxito.',
      enqueueSnackbar,
      update(cache, { data: createInstrumentData }) {
        if (cache.data.data.ROOT_QUERY.listInstrument) {
          const { listInstrument } = cache.readQuery({
            query: LIST_INSTRUMENTS,
          });
          listInstrument.edges.unshift({
            // Be careful with types
            __typename: 'InstrumentTypeEdge',
            node: createInstrumentData.createInstrument.instrument,
          });
          cache.writeQuery({
            query: LIST_INSTRUMENTS,
            data: { listInstrument },
          });
        }
        history.push('/administrador/instrumentos');
      },
    })
  );
  const deleteInstrument = useMutation(
    DELETE_INSTRUMENT,
    getOptions({
      mutationName: 'deleteInstrument',
      message: 'Instrumento eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listInstrument) {
          const { listInstrument } = cache.readQuery({
            query: LIST_INSTRUMENTS,
          });
          listInstrument.edges = listInstrument.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_INSTRUMENTS,
            data: { listInstrument },
          });
        }
        history.push('/administrador/instrumentos');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionInstrument}
        validateSchema={validationSchemaInstrument}
        data={data}
        model={instrument}
        id={id}
        modelName="Instrumentos"
        routeName="/administrador/instrumentos"
        isEdit={isEdit}
        updateMutation={updateInstrument}
        createMutation={createInstrument}
        deleteMutation={deleteInstrument}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

InstrumentForm.propTypes = {
  instrument: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

InstrumentForm.defaultProps = {
  instrument: null,
  id: '',
  isEdit: false,
};

export default InstrumentForm;
