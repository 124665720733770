import React from 'react'

export default function SvgLogin() {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="100"
          fill="none"
          viewBox="0 0 68 115"
        >
          <path
            fill="#F4FBFF"
            d="M1 56.43l33 18.81 33-18.81V18.81L34 0 1 18.81v37.62z"
          ></path>
          <path
            stroke="#F97A00"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M1 94.05l33 18.81 33-18.81V56.43L34 37.62 1 56.43v37.62z"
          ></path>
        </svg>
      );
}
