import Yup from '../../../../utils/yup';

export function validationFunctionFundSource(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaFundSource = Yup.object().shape({
  name: Yup.string().required(),
});
