// GraphQl
import { gql } from 'apollo-boost';

export const LIST_NEWS = gql`
  {
    listNews {
      edges {
        node {
          id
          newsImage
          state
          newsType
          title
          subtitle
          description
          hasButton
          buttonTitle
          hasLink
          link
        }
      }
    }
  }
`;

export const GET_NEWS = gql`
  query getNews($id: ID!) {
    news(id: $id) {
      id
      newsImage
      state
      newsType
      title
      subtitle
      description
      hasButton
      buttonTitle
      hasLink
      link
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation createNews($input: NewsInput!) {
    createNews(input: $input) {
      news {
        id
        newsImage
        state
        newsType
        title
        subtitle
        description
        hasButton
        buttonTitle
        hasLink
        link
      }
    }
  }
`;

export const UPDATE_NEWS = gql`
  mutation updateNews($id: ID!, $input: NewsInput!) {
    updateNews(id: $id, input: $input) {
      news {
        id
        newsImage
        state
        newsType
        title
        subtitle
        description
        hasButton
        buttonTitle
        hasLink
        link
      }
    }
  }
`;

export const DELETE_NEWS = gql`
  mutation deleteNews($id: ID!) {
    deleteNews(id: $id) {
      ok
      message
    }
  }
`;
