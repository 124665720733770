import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Material Resources
import { Button, Typography, TextField } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { successMessage } from '../../utils/commonFunctions';
import useStyles from './styles';

function ShareLink(props) {
  const { title, link } = props;

  const classes = useStyles();
  const textField = useRef();
  const { enqueueSnackbar } = useSnackbar();

  function copy() {
    navigator.clipboard.writeText(link);
    successMessage('Link copiado.', enqueueSnackbar);
  }

  return (
    <div className={classes.container}>
      <Typography variant="body2" style={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <TextField
        className={classes.linkField}
        value={link}
        type="text"
        variant="outlined"
        size="small"
        color="primary"
        inputRef={textField}
        disabled
        InputProps={{
          readOnly: true,
        }}
      />
      <Button
        onClick={copy}
        variant="contained"
        color="secondary"
        className={classes.shareButton}
      >
        Copiar
      </Button>
    </div>
  );
}

ShareLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string.isRequired,
};

ShareLink.defaultProps = {
  title: 'Compartir link',
};

export default ShareLink;
