import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  // sendRecoverMailPage: {
  //   display: 'flex',
  //   height: '100vh',
  //   padding: '5%',
  //   [theme.breakpoints.down('md')]: {
  //     padding: 10,
  //   },
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, ${theme.palette.primary.main} 90%)`,

  //   '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
  //     borderColor: theme.palette.primary.main,
  //     borderWidth: 2,
  //   },
  //   '& .MuiInputBase-input': {
  //     color: theme.palette.primary.contrastText,
  //   },
  //   '& .MuiInputBase-root': {
  //     color: theme.palette.primary.dark,
  //   },

  //   '& .MuiOutlinedInput-notchedOutline': {
  //     borderColor: theme.palette.primary.dark,
  //   },
  //   '& .MuiOutlinedInput-root': {
  //     '&:hover': {
  //       borderColor: theme.palette.primary.main,
  //     },
  //   },
  //   '& .MuiFormLabel-root': {
  //     color: theme.palette.primary.dark,
  //   },
  // },

  card: {
    border: '8px solid',
    borderRadius: 0,
    borderColor: theme.palette.secondary.main,
    background: theme.palette.primary,
    textAlign: 'center',
  },
  logo: {
    maxHeight: 160,
    width: '75%',
  },
  errorMessage: {
    color: theme.palette.colors.error,
    fontWeight: 600,
  },
  successMessage: {
    fontWeight: 600,
  },

  formdiv: {
    width: '100%',
    display: 'flex',
    gap: '1vh',
    padding: '0.5vh',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#525659',
    fontWeight: '500',
    fontSize: '1.7vh',
    cursor: 'pointer',
    zIndex: 999,
  },
}));
