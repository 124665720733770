import { gql } from 'apollo-boost';

export const DOWNLOAD_CLIENT_SHEET_MUTATION = gql`
  mutation downloadClientSheetMutation($clientId: ID!) {
    downloadClientSheet(clientId: $clientId) {
      sent
      __typename
    }
  }
`;
