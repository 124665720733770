/*
 * HomePage
 */

import React, { useState } from 'react';

// Material Resources

// Components & Others
// Styles & Images
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import Banners from './banners';
import SearchInput from './search';
import ModeIcon from './svg/modeIcon';
import MoreInfoIcon from './svg/helpIcon2';
import { useDispatch, useSelector } from 'react-redux';
import { setModeClient } from 'src/store/utils-store/actions';
import getClientMode from '../../../../../store/utils-store/selectors';
import SectionsCardsEmployee from './sections-card/view';

function EmployeeCards(props) {
  const history = useHistory();
  const [mode, setMode] = useState(false);
  const cards = [
    {
      section: 'Repositorios',
      cards: [
        { title: 'Repositorio General', path: '/cliente/documentos-generales' },
        { title: 'Repositorio Personal', path: '/cliente/documentos' },
      ],
      path: '',
    },
    {
      section: 'Documentos',
      cards: [
        { title: 'Ficha Colaborador', path: '/cliente/ficha-cliente' },
        { title: 'Firmas Pendientes', path: '/cliente/firmas' },
      ],
      path: '',
    },
    // {
    //   title: 'Eventos',
    //   cards: ['Calendario'],   history.push('/cliente/firmas');
    // },
    // {
    //   title: 'Credenciales',
    //   cards: ['Accesos'],
    // },
  ];
  const { setMenu, setSecondLogo, newClient, isEmployee } = props;
  const modeClient = useSelector(getClientMode());
  const classes = useStyles();
  const dispatch = useDispatch();
  const handlerMode = state => {
    setMode(state);
    localStorage.setItem('mode', state);
    dispatch(setModeClient(state));
  };

  const contentBannerStyle = modeClient
    ? classes.contentBannersDark
    : classes.contentBanners;
  return (
    <>
      <div className={classes.layout}>
        <div className={classes.modeDiv}>
          <div className={classes.modeIcon}>
            <div
              role="button"
              tabIndex={0}
              style={{ cursor: 'pointer' }}
              onClick={() => handlerMode(!mode)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handlerMode(!mode);
                }
              }}
            >
              <ModeIcon />
            </div>
          </div>
        </div>

        <Banners />
        <SectionsCardsEmployee />
      </div>
    </>
  );
}

export default EmployeeCards;
