const formatErrorName = (fieldName: '') => {
  let messageField = fieldName;
  if (fieldName === 'allocationMechanisms') {
    messageField = 'El campo Mecanismo de Colocación debe estar lleno.';
  }
  if (fieldName === 'classes') {
    messageField = 'Debe elegir por lo menos una Clase.';
  }
  return messageField;
};

const methods = {
  formatErrorName,
};

export default methods;
