import React from 'react';

function LogoSMV() {
  return (
    <img
      src="https://corecapital.com.pe/images/logo_smv_autorizado.svg"
      width="180"
      height="75"
    />
  );
}

export default LogoSMV;
