/*
 * HomePage
 */
import style from './login.module.css';
import React, { useState, useEffect } from 'react';

// Material Resources
import { Button, FormControl, InputLabel, MenuItem } from '@material-ui/core';

// GraphQl
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { useQuery } from '@apollo/react-hooks';

// Others
import { Formik, Form } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useSession } from '../../providers/SessionProvider';
import SvgLogin from './assets/SvgLogin';
import EmailIcon from './assets/EmailIcon';
import PhoneIcon from './assets/PhoneIcon';
// Styles & Images
import useStyles from './styles';
import CorePartnersLogo from '../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';

import TestingFormLogin from './formLogin/testingLoginForm';

function TestingLoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const { setRefreshToken } = useSession();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const { userLoading, data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        payload
        refreshExpiresIn
      }
    }
  `;

  const [
    loginMutation,
    { data: loginData, error: loginError, client },
  ] = useMutation(LOGIN, {
    onCompleted(data) {
      const {
        tokenAuth: { payload, refreshExpiresIn },
      } = data;
      setRefreshToken({ ...payload, refreshExpiresIn });
      client.resetStore();
      setLoading(false);
    },
    onError(error) {
      console.error(error);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (loginData) {
      const {
        tokenAuth: { payload },
      } = loginData;
      if (payload.usergroup === 'admin_group') {
        history.push('/administrador');
      } else if (payload.usergroup === 'client_group') {
        if (user && user.currentUser)
          payload.userFullName = `${user?.currentUser?.firstName} ${user?.currentUser?.lastName}`;
        window.dataLayer.push({ event: 'Login Intranet', info: payload });
        history.push('/cliente');
      }
    }
  }, [loginData, user]);

  const linkPassword = () => {
    history.push('/enviar-mail-contrasena');
  };

  return (
    <div className={style.layout}>
      <div className={style.container}>
        <div className={style.divImgSvg}>
          <SvgLogin />
        </div>

        <div className={style.divImg}>
          <div style={{ width: '70%' }}>
            <p className={style.title}>
              Estamos encantados de tenerte nuevamente con nosotros.{' '}
            </p>
            {/* <p className={style.title}>
            Bienvenido, nos alegra tenerte de vuelta.{' '}
          </p> */}
            <p className={style.subTitle}>
              ¡Gracias por ser parte de nuestra comunidad en línea!
            </p>
          </div>
          <div className={style.divHelp}>
            <p
              style={{ color: '#0B315E', fontWeight: '600', fontSize: '1.8vh' }}
            >
              ¿Necesitas Ayuda ?
            </p>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1vh' }}
            >
              <div className={style.divIcons}>
                <PhoneIcon />

                <a
                  style={{ color: '#FFFF' }}
                  href="mailto:customerservice@corepartners.com.pe"
                >
                  customerservice@corepartners.com.pe
                </a>
              </div>
              <div className={style.divIcons}>
                <EmailIcon className={style.divIcons} />
                <p style={{ margin: '0' }}>(511) 748 4547 </p>
              </div>
            </div>
          </div>
        </div>
        <div className={style.divForm}>
          <div style={{ height: '20vh' }}>
            <img
              src={CorePartnersLogo}
              alt="logo"
              style={{ width: '30vh' }}
              className={classes.logo}
            />
          </div>

          <TestingFormLogin />
        </div>
      </div>
    </div>
  );
}

TestingLoginPage.propTypes = {};

export default TestingLoginPage;
