/*
 * HomePage
 */

import React, { useEffect, useState } from 'react';

// Material Resources

// Components & Others

// Styles & Images
import useStyles from './styles';
import getClientMode from 'src/store/utils-store/selectors';
import { useSelector } from 'react-redux';
import LogoSMV from './svg/logo_smv';
import LogoCoreCapital from './svg/LogoCoreCapital';
import LogoEdifica from './svg/LogoEdifica';
import LogoNegativoSMV from './svg/logo_smv__negativo';
import LogoCoreCapitalNegativo from './svg/logo_core_negativo';
import LogoGroupBlanco from './svg/logo_group _blanco_01.png';
import LogoGroupNegro from './svg/logo_group_negro_01.png';
function Footer() {
  const classes = useStyles();
  const modeClient = useSelector(getClientMode());
  const legalContent = modeClient
    ? classes.legalcontentDark
    : classes.legalcontent;
  const copyrightText = modeClient ? classes.copyrightDark : classes.copyright;
  const main = modeClient ? classes.mainDark : classes.main;
  const adviceText = modeClient ? classes.adviceTextDark : classes.adviceText;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const logoSmv = modeClient ? LogoGroupBlanco : LogoGroupNegro;
  const logoCoreCap = modeClient ? (
    <LogoCoreCapitalNegativo />
  ) : (
    <LogoCoreCapital />
  );
  return (
    <>
      {windowWidth > 1153 && (
        <>
          <div className={main}>
            <div className={classes.container}>
              <div className={classes.legalContainer}>
                <div className={classes.legalContainer1}>
                  <h1 className={copyrightText}>CORE Capital © 2024</h1>
                  <div className={classes.legalPdfContainer}>
                    <div>
                      <a
                        className={legalContent}
                        href="https://corecapital.com.pe/docs/CORE_Capital_Politica_de_Privacidad.pdf"
                        target="_blank"
                        rel="noopener"
                      >
                        Políticas de Privacidad
                      </a>
                    </div>
                    <div>
                      {/* <p className={legalContent}>Términos y condiciones</p> */}
                      <a
                        className={legalContent}
                        href="https://corecapital.com.pe/docs/CORE_Capital_Terminos_y_Condiciones_de_Uso.pdf"
                        target="_blank"
                        rel="noopener"
                      >
                        Términos &amp; Condiciones
                      </a>
                    </div>
                    <div>
                      <a
                        className={legalContent}
                        href="https://intranet.corecapital.com.pe"
                        target="_blank"
                        rel="noopener"
                      >
                        Servicio al Inversionista
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className={classes.legalAdviceContainer}>
                  <p className={adviceText}>
                    La supervisión de SMV no implica que esta recomiende o
                    garantice la inversión efectuada en un Fondo de inversión.
                    Core Capital Group pertenece al Grupo Económico Edifica.
                  </p>
                </div> */}
              </div>
            </div>

            <div className={classes.containerLogos}>
              <div>
                {modeClient ? (
                  <img src={LogoGroupBlanco} style={{ height: '30px' }} />
                ) : (
                  <img src={LogoGroupNegro} style={{ height: '30px' }} />
                )}
              </div>
              {/* <div>{logoCoreCap}</div> */}
              <div>
                <LogoEdifica />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Footer;
