import React, { useState, useEffect } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  Dialog,
  Toolbar,
  Typography,
  IconButton,
  useMediaQuery,
  Grid,
  ListItemText,
  ListItem,
  List,
  ListItemSecondaryAction,
  Checkbox,
  Collapse,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import useStyles from './styles';

const mockData = [
  {
    name: "SAFI's",
    enable: false,
    models: [
      { name: 'SAFI', enable: false, crud: [false, false, true, false] },
    ],
  },
  {
    name: 'Administrador de fondos',
    enable: true,
    models: [
      { name: 'Fondos', enable: true, crud: [true, true, true, true] },
      { name: 'Instrumentos', enable: true, crud: [true, true, true, true] },
      {
        name: 'Fuentes de Fondos',
        enable: true,
        crud: [true, true, true, true],
      },
    ],
  },
  {
    name: 'Entidades',
    enable: true,
    models: [
      { name: 'Bancos', enable: true, crud: [true, true, true, true] },
      {
        name: 'Empresas promotoras',
        enable: true,
        crud: [true, true, true, true],
      },
    ],
  },
  {
    name: 'Ajustes',
    enable: true,
    models: [
      { name: 'Monedas', enable: true, crud: [true, true, true, true] },
      { name: 'Tipos de cuenta', enable: true, crud: [true, true, true, true] },
      {
        name: 'Cuentas bancarias',
        enable: true,
        crud: [true, true, true, true],
      },
      { name: 'Categorias', enable: true, crud: [true, true, true, true] },
    ],
  },
  {
    name: 'Administrador',
    enable: true,
    models: [
      {
        name: 'Tipos de documento',
        enable: true,
        crud: [true, true, true, true],
      },
      { name: 'Estados civiles', enable: true, crud: [true, true, true, true] },
      {
        name: 'Regimen patrimonial',
        enable: true,
        crud: [true, true, true, true],
      },
      {
        name: 'Rangos de ingreso mensual',
        enable: true,
        crud: [true, true, true, true],
      },
      {
        name: 'Niveles de instrucción',
        enable: true,
        crud: [true, true, true, true],
      },
      {
        name: 'Relación con la SAF',
        enable: true,
        crud: [true, true, true, true],
      },
    ],
  },
  {
    name: 'Usuarios',
    enable: true,
    models: [
      { name: 'Administradores', enable: true, crud: [true, true, true, true] },
      { name: 'Clientes', enable: true, crud: [true, true, true, true] },
      {
        name: 'Inversionistas interesados',
        enable: true,
        crud: [true, true, true, true],
      },
      { name: 'Promotores', enable: true, crud: [true, true, true, true] },
    ],
  },
  {
    name: 'Ficha cliente',
    enable: false,
    models: [
      {
        name: 'Puntajes Anexo A',
        enable: false,
        crud: [false, false, true, false],
      },
    ],
  },
];

function CrudCheckBoxes(props) {
  const classes = useStyles();
  const {
    model,
    parent,
    enable,
    crud,
    handleInnerCheck,
    handleInnerCrudCheck,
  } = props;

  const [open, setOpen] = useState(false);
  const crudOperations = [
    { name: 'Crear', enable: crud[0] },
    { name: 'Editar', enable: crud[1] },
    { name: 'Ver', enable: crud[2] },
    { name: 'Eliminar', enable: crud[3] },
  ];

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCheck = (e, name) => {
    let id;
    switch (name) {
      case 'Crear':
        id = 0;
        break;
      case 'Editar':
        id = 1;
        break;
      case 'Ver':
        id = 2;
        break;
      case 'Eliminar':
        id = 3;
        break;
      default:
        id = 0;
    }
    handleInnerCrudCheck(parent, model, id, e.target.checked);
  };

  const handleCheckAll = e => {
    handleInnerCheck(parent, model, e.target.checked);
  };

  return (
    <>
      <ListItem button>
        {open ? (
          <IconButton
            variant="contained"
            className={classes.listButton}
            onClick={handleOpen}
          >
            <ExpandLess />
          </IconButton>
        ) : (
          <IconButton
            variant="contained"
            className={classes.listButton}
            onClick={handleOpen}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
        <Checkbox onChange={handleCheckAll} checked={enable} />
        <ListItemText
          primary={model}
          className={classes.mainText}
          onClick={handleOpen}
        />
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" className={classes.nested}>
          {crudOperations.map(operation => (
            <ListItem>
              <Checkbox
                checked={operation.enable}
                onChange={e => handleCheck(e, operation.name)}
              />
              <ListItemText
                primary={operation.name}
                className={classes.nestedText}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

function EditMenu(props) {
  const { userGroup } = props;
  const classes = useStyles();
  const [data, setData] = useState(mockData);

  const handleCheck = (e, name) => {
    const options = [...data];
    const element = options.find(opt => opt.name === name);
    const enable = e.target.checked;
    if (element) {
      element.enable = enable;
      element.models.forEach(m => {
        m.enable = enable;
        m.crud = [enable, enable, enable, enable];
      });
    }
    setData(options);
  };

  const handleInnerCheck = (name, model, enable) => {
    const options = [...data];
    const element = options.find(opt => opt.name === name);
    if (element) {
      const mod = element.models.find(m => m.name === model);
      mod.enable = enable;
      mod.crud = [enable, enable, enable, enable];
      element.enable = element.models.every(opt => opt.enable);
    }
    setData(options);
  };

  const handleInnerCrudCheck = (name, model, crudID, enable) => {
    const options = [...data];
    const element = options.find(opt => opt.name === name);
    if (element) {
      const mod = element.models.find(m => m.name === model);
      if (mod) {
        mod.crud[crudID] = enable;
        mod.enable = mod.crud.every(opt => opt);
      }
      element.enable = element.models.every(opt => opt.enable);
    }
    setData(options);
  };

  return (
    <div className={classes.editMenu}>
      <Grid container>
        <Grid container xs={12}>
          <Grid
            item
            xs={3}
            className={`${classes.users} ${classes.userLeftSide}`}
          >
            <div>
              <Checkbox checked />
              Administradores
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            className={`${classes.users} ${classes.usersCenter}`}
          >
            <div>
              <Checkbox checked={false} />
              Clientes
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            className={`${classes.users} ${classes.usersCenter}`}
          >
            <div>
              <Checkbox checked={false} />
              Inversionistas interesados
            </div>
          </Grid>
          <Grid
            item
            xs={3}
            className={`${classes.users} ${classes.userRightSide}`}
          >
            <div>
              <Checkbox checked={false} />
              Promotores
            </div>
          </Grid>
        </Grid>
        <Grid item xs={6} className={`${classes.title} ${classes.leftTitle}`}>
          Menu
        </Grid>
        <Grid item xs={6} className={`${classes.title} ${classes.rightTitle}`}>
          Submenu
        </Grid>

        {data.map(element => (
          <>
            <Grid
              item
              xs={6}
              className={`${classes.content} ${classes.leftPanel}`}
            >
              <Grid item xs={12}>
                <Checkbox
                  checked={element.enable}
                  onChange={e => handleCheck(e, element.name)}
                />
                {element.name}
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              className={`${classes.content} ${classes.rightPanel}`}
            >
              <List dense className={classes.main}>
                {element.models.map(value => {
                  return (
                    <CrudCheckBoxes
                      model={value.name}
                      parent={element.name}
                      crud={value.crud}
                      enable={value.enable}
                      handleInnerCheck={handleInnerCheck}
                      handleInnerCrudCheck={handleInnerCrudCheck}
                    />
                  );
                })}
              </List>
            </Grid>
          </>
        ))}
      </Grid>
    </div>
  );
}

EditMenu.propTypes = {
  userGroup: PropTypes.string.isRequired,
};

EditMenu.defaultProps = {};

export default EditMenu;
