export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const NOTIFICATION_DURATION = 5000;
export const NOTIFICATION_MAX_STACK = 10;
export const { REACT_APP_SERVER_LINK } = process.env;
export const { REACT_APP_APIKEY } = process.env;
export const { REACT_APP_AUTHDOMAIN } = process.env;
export const { REACT_APP_PROJECTID } = process.env;
export const { REACT_APP_STORAGEBUCKET } = process.env;
export const { REACT_APP_APPID } = process.env;
export const { REACT_APP_LINK_FORM_F2 } = process.env;
export const { REACT_APP_MESSAGINGSENDERID } = process.env;
export const { REACT_APP_COLLECTION_PREFIX } = process.env;
export const { REACT_APP_ROP_URL } = process.env;
export const { REACT_APP_ASAMBLEA } = process.env;
export const { REACT_APP_COMERCIAL } = process.env;
export const { REACT_APP_SIMULADOR } = process.env;
export const { REACT_APP_BASE_URL_BACKEND_INVESTOR } = process.env;
export const { REACT_APP_BASE_URL_BACKEND_INVESTOR_API_KEY } = process.env;
