import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  LIST_CATEGORIES,
} from '../queries';
import {
  validationFunctionCategory,
  validationSchemaCategory,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
};

function CategoryForm(props) {
  const { category, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateCategory = useMutation(
    UPDATE_CATEGORY,
    getOptions({
      mutationName: 'updateCategory',
      modelName: 'category',
      message: 'Categoría actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const createCategory = useMutation(
    CREATE_CATEGORY,
    getOptions({
      mutationName: 'createCategory',
      modelName: 'category',
      message: 'Categoría creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createCategoryData }) {
        if (cache.data.data.ROOT_QUERY.listCategories) {
          const { listCategories } = cache.readQuery({
            query: LIST_CATEGORIES,
          });
          listCategories.edges.unshift({
            // Be careful with types
            __typename: 'CategoryTypeEdge',
            node: createCategoryData.createCategory.category,
          });
          cache.writeQuery({
            query: LIST_CATEGORIES,
            data: { listCategories },
          });
        }
        history.push('/administrador/categorias');
      },
    })
  );
  const deleteCategory = useMutation(
    DELETE_CATEGORY,
    getOptions({
      mutationName: 'deleteCategory',
      message: 'Categoría eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listCategories) {
          const { listCategories } = cache.readQuery({
            query: LIST_CATEGORIES,
          });
          listCategories.edges = listCategories.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_CATEGORIES,
            data: { listCategories },
          });
        }
        history.push('/administrador/categorias');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionCategory}
        validateSchema={validationSchemaCategory}
        data={data}
        model={category}
        id={id}
        modelName="Categorías"
        routeName="/administrador/categorias"
        isEdit={isEdit}
        updateMutation={updateCategory}
        createMutation={createCategory}
        deleteMutation={deleteCategory}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

CategoryForm.propTypes = {
  category: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

CategoryForm.defaultProps = {
  category: null,
  id: '',
  isEdit: false,
};

export default CategoryForm;
