import Yup from '../../../../utils/yup';
import { rucValidator } from '../../../Validations';

export function validationFunctionInstrument(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaInstrument = Yup.object().shape({
  name: Yup.string().required(),
});
