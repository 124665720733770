import React, { useState } from 'react';

import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { TableCell, Typography, Button } from '@material-ui/core';

import useStyles from './styles';
import CustomModal from '../CustomModal';
import SimpleForm from '../Forms/SimpleForm';
import CustomOptions from '../CustomOptions';
import CustomColumnHeader from '../TableComponents/CustomColumnHeader';
import { textLabels } from '../../translations/components/MUIDataTable';

/**
 * Component to list data. It also edit and delete each row as
 * well as create new information.
 *
 * @component
 * @param {object} props
 */
function CreateEditTable(props) {
  const {
    table: { columns: tableColumns },
    mutations: { _delete, update, create },
    strings: { tableName, modelName, createButtonText },
    form: {
      startValuesParser,
      validationFunction,
      validationSchema,
      formatBeforeSubmit,
      structure,
    },
  } = props;

  let {
    table: { data: tableData },
  } = props;

  const [instanceID, setInstanceID] = useState(null);
  const [instance, setInstance] = useState(startValuesParser());
  const [isEdit, setIsEdit] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const classes = useStyles();

  const columns = tableColumns.map(v => ({
    name: v.name,
    label: v.label,
    options: {
      sort: true,
      customHeadRender: (columnMeta, updateDirection) => {
        return (
          <CustomColumnHeader
            id={columnMeta.index}
            key={columnMeta.index}
            columnMeta={columnMeta}
            updateDirection={updateDirection}
          />
        );
      },
    },
  }));
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model={modelName}
            options={['cedit', 'odelete']}
            handleDelete={() => {
              const [makeDelete] = _delete;
              makeDelete({ variables: { id } });
              tableData = tableData.filter(e => e.id !== id);
            }}
            handleEdit={() => {
              const selectedInstance = tableData.filter(v => id === v.id)[0];
              setInstanceID(id);
              setInstance(startValuesParser(selectedInstance));
              setIsEdit(true);
              setShowForm(true);
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setInstance(startValuesParser());
          setShowForm(true);
        }}
      >
        {createButtonText}
      </Button>
    ),
    onRowClick: rowData => {
      const selectedInstance = tableData.filter(
        v => rowData[rowData.length - 1].props.id === v.id
      )[0];
      setInstanceID(selectedInstance.id);
      setInstance(startValuesParser(selectedInstance));
      setIsEdit(true);
      setShowForm(true);
    },
  };

  const closingTasks = () => {
    setInstance(startValuesParser());
    setShowForm(false);
    setIsEdit(false);
  };

  return (
    <>
      <MUIDataTable
        title={tableName || ''}
        data={tableData}
        columns={columns}
        options={options}
      />
      <CustomModal
        title={modelName || ''}
        showModal={showForm}
        onClosePress={() => closingTasks()}
      >
        <SimpleForm
          id={instanceID}
          initialValues={instance}
          validateFunction={validationFunction}
          validateSchema={validationSchema}
          data={structure}
          modelName={modelName || ''}
          hasCancel
          isEdit={isEdit}
          routeName=""
          onCancel={() => closingTasks()}
          updateMutation={update}
          createMutation={create}
          deleteMutation={_delete}
          formatBeforeSubmit={formatBeforeSubmit}
          afterCloseEvents={closingTasks}
        />
      </CustomModal>
    </>
  );
}

CreateEditTable.propTypes = {
  table: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }).isRequired
    ),
    data: PropTypes.array.isRequired,
  }).isRequired,
  mutations: PropTypes.shape({
    _delete: PropTypes.array.isRequired,
    update: PropTypes.array.isRequired,
    create: PropTypes.array.isRequired,
  }).isRequired,
  strings: PropTypes.shape({
    tableName: PropTypes.string,
    modelName: PropTypes.string,
    createButtonText: PropTypes.string,
  }).isRequired,
  form: PropTypes.shape({
    structure: PropTypes.array.isRequired,
    startValuesParser: PropTypes.func.isRequired,
    validationFunction: PropTypes.func.isRequired,
    formatBeforeSubmit: PropTypes.func.isRequired,
    validationSchema: PropTypes.shape().isRequired,
  }).isRequired,
};

export default CreateEditTable;
