import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';

import { GET_CLIENT_HOLDER_INFORMATION } from '@queries/Client';
import useStyles from './styles';
import CustomCard from '../../../CustomCard';
import ClientPEPPublicFunctions from './publicFunctions';
import ParticipationTable from './participation';
import ClientTexts from '../../../FormComponents/ClientTexts';
import LoadingIndicator from '../../../LoadingIndicator';
import { edgeToList } from '../../../../utils/commonFunctions';
import NextStepButton from '../../../FormComponents/NextStepButton';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import {
  getSpousePEPSelector,
  getRelativePEPSelector,
} from '../../../../containers/ModelsPages/ClientForm/selectors';

function ClientPEP(props) {
  const { clientID } = props;
  const classes = useStyles();
  const [participations, setParticipations] = useState([]);
  const [publicFunctions, setPublicFunctions] = useState([]);
  const [client, setClient] = useState(null);
  const { clientLoading, data: clientData } = useQuery(
    GET_CLIENT_HOLDER_INFORMATION,
    {
      variables: { id: clientID },
    },
  );

  const spousePEP = useSelector(getSpousePEPSelector());
  const relativePEP = useSelector(getRelativePEPSelector());

  useEffect(() => {
    if (clientData) {
      setClient(clientData.client);
      setParticipations(edgeToList(clientData.client, 'holderParticipations'));
      setPublicFunctions(
        edgeToList(clientData.client, 'holderPublicFunctions'),
      );
    }
  }, [clientData]);

  if (clientLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.stepsFormContainer}>
      <CustomCard
        title="TITULAR PEP"
        content={
          <>
            <ClientTexts text="Son aquellas Personas Naturales o Extranjeras que en los últimos 5 años cumplen o hayan cumplido funciones públicas destacadas o funciones prominentes en una en el territorio nacional o extranjero." />
            {client && (
              <ClientTexts
                text={`Yo, ${client.user.firstName} ${client.user.lastName} ${
                  client.user.maternalSurname
                }, identificado con ${
                  client.user.documentType
                    ? client.user.documentType.name
                    : client.alternativeDocumentType
                } Nro ${
                  client.user.documentNumber
                } declaro que toda la información que se indica líneas abajo en relación a la condición de PEP son ciertas.`}
              />
            )}
            <CustomCard
              subtitle="Funciones públicas"
              disableFlex
              content={
                <>
                  <ClientTexts text="Datos de las funciones públicas desempeñadas en los últimos 5 años" />
                  <ClientPEPPublicFunctions
                    clientId={clientID}
                    data={publicFunctions}
                  />
                </>
              }
            />
            <CustomCard
              subtitle="Participación"
              disableFlex
              content={
                <>
                  <ClientTexts text="Respecto del PEP. ¿En qué personas jurídicas o entes jurídicos mantiene una participación igual o superior al 25%?" />
                  <ParticipationTable
                    clientId={clientID}
                    data={participations}
                  />
                </>
              }
            />
            <NextStepButton
              clientID={clientID}
              nextForm={
                relativePEP
                  ? MENU_ITEMS_ID.RelativePEP
                  : MENU_ITEMS_ID.ClientTermsConditions
              }
            />
          </>
        }
      />
    </div>
  );
}

ClientPEP.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default ClientPEP;
