// GraphQl
import { gql } from 'apollo-boost';

export const LIST_SWORNDECLARATIONS = gql`
  query listTypeSwornDeclaration {
    listTypeSwornDeclaration {
      edges {
        node {
          id
          name
          condition
          fund {
            id
            businessName
          }
        }
      }
    }
  }
`;

export const GET_SWORNDECLARATION = gql`
  query typeSwornDeclaration($id: ID) {
    typeSwornDeclaration(id: $id) {
      id
      name
      condition
      text
      fund {
        id
        businessName
      }
    }
  }
`;

export const CREATE_SWORNDECLARATION = gql`
  mutation createTypeSwornDeclaration($input: TypeSwornDeclarationInput!) {
    createTypeSwornDeclaration(input: $input) {
      typeSwornDeclaration {
        id
        name
        condition
        text
        fund {
          id
          businessName
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_SWORNDECLARATION = gql`
  mutation updateTypeSwornDeclaration(
    $id: ID!
    $input: TypeSwornDeclarationInput!
  ) {
    updateTypeSwornDeclaration(id: $id, input: $input) {
      typeSwornDeclaration {
        id
        name
        condition
        text
        fund {
          id
          businessName
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_SWORNDECLARATION = gql`
  mutation deleteTypeSwornDeclaration($id: ID!) {
    deleteTypeSwornDeclaration(id: $id) {
      typeSwornDeclaration {
        id
      }
    }
  }
`;
