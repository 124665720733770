import { gql } from 'apollo-boost';

// TypeRelation was added to identify the No link option
export const LIST_SAFI_RELATIONS = gql`
  query listRelations {
    listSafiRelations {
      edges {
        node {
          id
          name
          typeRelation
        }
      }
    }
  }
`;
