import React from 'react';
import CoreA1 from '../../svg/CoreA1_negativo.png';
function LogoCoreCapital() {
  return (
    <div
      style={{
        borderRadius: '5px',
        border: '0.5px solid #000',
      }}
    >
      <div
        style={{
          padding: '3px',
        }}
      >
        <img src={CoreA1} width="80" height="20" alt="CORE Capital SAF" />
      </div>
    </div>
  );
}

export default LogoCoreCapital;
