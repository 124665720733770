import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  simpleFormContainer: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
  },
  title: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  simpleForm: {
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  formText: {
    fontSize: '0.9rem',
  },
  extraNodeContainer: {
    width: '100%',
    '& .MuiPaper-root': {
      width: '100%',
    },
  },
}));
