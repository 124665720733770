import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    documents: {
      paddingTop: 25,
    },
    document: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 30px',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 600,
      lineHeight: 2,
    },
    documentIcon: {
      height: 100,
      width: 'auto',
      color: theme.palette.secondary.contrastText,
    },
    downloadIcon: {
      color: theme.palette.secondary.main,
      height: 18,
    },
    downloadButton: {
      height: 30,
      width: 125,
      fontWeight: 500,
      borderRadius: 30,
      fontSize: '0.85rem',
      background: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    table: {
      marginTop: 40,
    },
    tableHead: {
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      '& h3': {
        fontWeight: 600,
        fontSize: 18,
        margin: 0,
      },
    },
    rowIcon: {
      height: 70,
      width: 'auto',
      color: theme.palette.secondary.contrastText,
    },
    squareBullet: {
      color: theme.palette.secondary.light,
    },
    listTitle: {
      '& .MuiTypography-root': {
        fontWeight: 500,
        lineHeight: 0,
      },
    },
    listCell: {
      '& .MuiTypography-root': {
        fontWeight: 500,
        lineHeight: 0,
      },
    },
    leftDivider: {
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.secondary.main,
      borderLeftStyle: 'solid',
    },
    stepLabel: {
      color: theme.palette.secondary.main,
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    wheelDates: {
      whiteSpace: 'pre-line',
    },
  })
);
