import { gql } from 'apollo-boost';

// eslint-disable-next-line import/prefer-default-export
export const LIST_ALL_CLIENT_CONTRACTS = gql`
  query listParticipantVerifiedPayment($id: ID!) {
    listParticipantVerifiedPayment {
      edges {
        node {
          id
          raisingparticipantSet {
            edges {
              node {
                raising {
                  id
                  number
                  fund {
                    id
                    businessName
                  }
                }
              }
            }
          }
        }
      }
    }
    listSecondaryMarketVerified {
      edges {
        node {
          id
          registrationDate
          fund {
            id
            businessName
          }
        }
      }
    }
    hasPendingSignatures(id: $id)
  }
`;

export const LIST_PENDINGS = gql`
  query listPendings($id: ID!) {
    hasPendingSignatures(id: $id)
    hasNewFiles(id: $id)
  }
`;
