import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import LoadingIndicator from '../../../LoadingIndicator';
import {
  generateColumn,
  edgeToList,
  parseSignatureState,
} from '../../../../utils/commonFunctions';
import { IconButton, Tooltip, TableCell, Typography } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Link from '@material-ui/core/Link';

import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import { LIST_SIGNATURES } from '../queries';
import { REACT_APP_SERVER_LINK } from '../../../../utils/constants';

// styles and icons
import useStyles from './styles';

function SignatureList() {
  const classes = useStyles();
  const [signatureList, setSignatureList] = useState([]);

  const { loading: loadindList, error, data: list, refetch } = useQuery(
    LIST_SIGNATURES,
  );

  useEffect(() => {
    if (!!list) {
      let signatures = edgeToList(list, 'listSigningDocuments');
      signatures = signatures.sort((a, b) => a.createdAt < b.createdAt);
      const resultProcess = signatures.filter(
        process =>
          process.signedPdfStatus === 'SIGNED' ||
          process.signedPdfStatus === 'SIGNING_ERROR' ||
          process.signedPdfStatus === 'DOWNLOAD_ERROR',
      );
      setSignatureList(resultProcess);
    }
    refetch();
  }, [list]);

  let columns = [];

  if (signatureList && signatureList.length > 0) {
    signatureList.forEach(item => {
      columns = [
        generateColumn('Fecha', 'createdAt', {
          customBodyRender: value => moment(value).format('DD-MM-YYYY'),
        }),
        generateColumn('Documento', 'signedPdfSheet', {
          customBodyRender: value => {
            let last_position = parseInt(value.lastIndexOf('/')) + 1;
            return value.slice(last_position);
          },
        }),
        generateColumn('Clientes', 'id', {
          customBodyRender: value => {
            let result = '';
            signatureList
              .filter(process => process.id == value)
              .forEach(sign => {
                if (sign.signingProcess.clients.edges.length > 0) {
                  sign.signingProcess.clients.edges.forEach(client => {
                    result +=
                      client.node.user.firstName +
                      ' ' +
                      client.node.user.lastName +
                      ', ';
                  });
                }
                if (sign.signingProcess.externalSigners.edges.length > 0) {
                  sign.signingProcess.externalSigners.edges.forEach(
                    external => {
                      result +=
                        external.node.firstName +
                        ' ' +
                        external.node.lastName +
                        ', ';
                    },
                  );
                }
              });
            return result;
          },
        }),
        generateColumn('Estado', 'signedPdfStatus', {
          customBodyRender: value => {
            return <p>{parseSignatureState(value)}</p>;
          },
        }),
        {
          name: 'id',
          label: 'Opciones',
          options: {
            sort: false,
            customBodyRender: (value, tableMeta) => {
              const id = value;
              return (
                <>
                  <Tooltip title="Descargar" placement="top" arrow>
                    <Link
                      href={`${REACT_APP_SERVER_LINK}/media/${tableMeta.rowData[1]}`}
                      target="_blank"
                    >
                      <IconButton
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.optionButtons}
                      >
                        <CloudDownloadIcon className={classes.optionIcon} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </>
              );
            },
            customHeadRender: columnMeta => {
              return (
                <TableCell key={7} style={{ borderBottom: 'none' }}>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.tableTitle}
                  >
                    {columnMeta.label}
                  </Typography>
                </TableCell>
              );
            },
          },
        },
      ];
    });
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
  };

  return (
    <>
      <MUIDataTable
        title="Firmados"
        data={signatureList}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default SignatureList;
