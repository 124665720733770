/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SecondaryMarketList from '../../../components/Models/SecondaryMarket';
import SecondaryMarketForm, {
  SecondaryMarketEdit,
} from '../../../components/Models/SecondaryMarket/Form';
import NotFound from '../../NotFoundPage';

function SecondaryMarketPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SecondaryMarketList} />
      <Route path={`${path}/crear`} component={SecondaryMarketForm} />
      <Route path={`${path}/:id`} component={SecondaryMarketEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default SecondaryMarketPage;
