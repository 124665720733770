import React, { useEffect, useState } from 'react';

// Material Resources
import { Grid, Button, InputLabel } from '@material-ui/core';

// Components & Others
import PropTypes from 'prop-types';
import { REACT_APP_SERVER_LINK } from '../../../utils/constants';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../../../utils/commonFunctions';
import useStyles from './styles';

const FileInput = ({
  field,
  form,
  label,
  oldFile,
  accept,
  maxSize,
  ...other
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [fileEncode, setFileEncode] = useState();
  const [fileName, setFileName] = useState(oldFile);

  useEffect(() => {
    form.setFieldValue(field.name, null);
  }, [oldFile]);

  const validateSize = fileSize => {
    if (maxSize === 0) {
      return true;
    } else {
      return fileSize <= maxSize * 1024 * 1024;
    }
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleChange = async ({ target }) => {
    if (validateSize(target.files[0].size)) {
      try {
        let name = target.files[0].name;
        const encoded = await toBase64(target.files[0]);
        form.setFieldValue(field.name, encoded);
        setFileEncode(encoded);
        setFileName(name);
      } catch (error) {
        console.error(`File input ${field.name}: ${error}`);
      }
    } else {
      errorMessage(
        { message: `El archivo no debe superar los ${maxSize} MB` },
        enqueueSnackbar
      );
    }
  };

  return (
    <>
      <Grid container spacing={3} className={classes.container}>
        <Grid className={classes.labelContainer} item xs={6}>
          <InputLabel>{label}</InputLabel>
          {fileName && (
            <div>
              {oldFile && !fileEncode ? (
                <a
                  href={`${REACT_APP_SERVER_LINK}/media/${oldFile}`}
                  className={classes.cursorPointer}
                  target="_blank"
                >
                  {fileName}
                </a>
              ) : (
                <small>{fileName}</small>
              )}
            </div>
          )}
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            component="label"
            color="primary"
            name={field.name}
          >
            {(!oldFile && 'Buscar archivo') || 'Cambiar archivo'}
            <input
              type="file"
              accept={accept}
              style={{ display: 'none' }}
              onChange={handleChange}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
FileInput.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  oldFile: PropTypes.string,
  maxSize: PropTypes.number,
};
FileInput.defaultProps = {
  maxSize: 0,
};

export default FileInput;
