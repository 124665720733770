import React, { useState, useEffect } from 'react';

// Material Resources
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

// Others
import PropTypes from 'prop-types';

// Styles & Images
import useStyles from './styles';

function DeleteModal(props) {
  const { model, open, handleClose, handleOK } = props;
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="dialog-delete"
      maxWidth="xs"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
        className={classes.deleteModal}
        id="dialog-delete"
      >{`Eliminar ${model}`}</DialogTitle>
      <DialogContent dividers>
        <p>¿Estás seguro de esta acción?</p>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Cancelar
        </Button>
        <Button onClick={handleOK} variant="contained" color="primary">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleOK: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteModal;
