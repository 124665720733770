import React from 'react';

// GraphQl
import { useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../Forms/SimpleForm';
import {
  UPDATE_CURRENCY,
  CREATE_CURRENCY,
  DELETE_CURRENCY,
  LIST_CURRENCIES,
} from '../queries';
import {
  validationFunctionCurrency,
  validationSchemaCurrency,
} from './validation';
import { getOptions } from '../../../../utils/commonFunctions';

const startValues = {
  name: '',
  code: '',
  symbol: '',
};

function CurrencyForm(props) {
  const { currency, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateCurrency = useMutation(
    UPDATE_CURRENCY,
    getOptions({
      mutationName: 'updateCurrency',
      modelName: 'currency',
      message: 'Moneda actualizada con éxito.',
      enqueueSnackbar,
    })
  );
  const createCurrency = useMutation(
    CREATE_CURRENCY,
    getOptions({
      mutationName: 'createCurrency',
      modelName: 'currency',
      message: 'Moneda creada con éxito.',
      enqueueSnackbar,
      update(cache, { data: createCurrencyData }) {
        if (cache.data.data.ROOT_QUERY.listCurrencies) {
          const { listCurrencies } = cache.readQuery({
            query: LIST_CURRENCIES,
          });
          listCurrencies.edges.unshift({
            // Be careful with types
            __typename: 'CurrencyTypeEdge',
            node: createCurrencyData.createCurrency.currency,
          });
          cache.writeQuery({
            query: LIST_CURRENCIES,
            data: { listCurrencies },
          });
        }
        history.push('/administrador/monedas');
      },
    })
  );
  const deleteCurrency = useMutation(
    DELETE_CURRENCY,
    getOptions({
      mutationName: 'deleteCurrency',
      message: 'Moneda eliminada con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listCurrencies) {
          const { listCurrencies } = cache.readQuery({
            query: LIST_CURRENCIES,
          });
          listCurrencies.edges = listCurrencies.edges.filter(
            e => e.node.id !== id
          );
          cache.writeQuery({
            query: LIST_CURRENCIES,
            data: { listCurrencies },
          });
        }
        history.push('/administrador/monedas');
      },
    })
  );

  const data = [
    {
      label: 'Nombre',
      name: 'name',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Símbolo',
      name: 'symbol',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Código',
      name: 'code',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
  ];

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        validateFunction={validationFunctionCurrency}
        validateSchema={validationSchemaCurrency}
        data={data}
        model={currency}
        id={id}
        modelName="Monedas"
        routeName="/administrador/monedas"
        isEdit={isEdit}
        updateMutation={updateCurrency}
        createMutation={createCurrency}
        deleteMutation={deleteCurrency}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

CurrencyForm.propTypes = {
  currency: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

CurrencyForm.defaultProps = {
  currency: null,
  id: '',
  isEdit: false,
};

export default CurrencyForm;
