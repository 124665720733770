import Yup from '../../../../utils/yup';
import { rucValidator } from '../../../Validations';

export function validationFunctionMaritalStatus(values) {
  const errors = {};
  // Custom Validations
  return errors;
}

export const validationSchemaMaritalStatus = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Maximo 50 caracteres')
    .required(),
});
