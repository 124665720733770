// GraphQl
import { gql } from 'apollo-boost';

// eslint-disable-next-line import/prefer-default-export
export const GET_PARTICIPANT_WHEELS_DATA = gql`
  query getParticipantWheelsData($clientID: ID) {
    listParticipant(client: $clientID) {
      edges {
        node {
          id
          numFee
          participationPercentage
          isRiskExchange
          isRepresentative
          client {
            id
            communicationEmail
            user {
              firstName
              lastName
              maternalSurname
              documentType {
                name
              }
              documentNumber
            }
          }
          wheel {
            id
            number
            raising {
              id
            }
          }
          coParticipants {
            edges {
              node {
                id
                numFee
                participationPercentage
                isRiskExchange
                isRepresentative
                client {
                  id
                  communicationEmail
                  user {
                    firstName
                    lastName
                    maternalSurname
                    documentType {
                      name
                    }
                    documentNumber
                  }
                }
              }
            }
          }
          paymentSet {
            edges {
              node {
                id
                amount
                operationNumber
                bank {
                  name
                }
                voucherPhoto
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_PARTICIPANT = gql`
  mutation updateParticipant($id: ID, $input: ParticipantInput!) {
    updateParticipant(id: $id, input: $input) {
      participant {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;
