import React, { useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField as MuiTextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { edgeToList } from '../../../../../utils/commonFunctions';
import LoadingIndicator from '../../../../LoadingIndicator';

import { LIST_DOCUMENT_TYPES } from 'src/components/Models/DocumentType/queries';

import useStyles from './styles';
import { GridClientField } from 'src/components/Cognito/StepTwoForm';
import { GET_CLIENT_BY_DOCUMENT_AND_RAISING } from 'src/components/Cognito/queries';
import { GET_CLIENT_BY_DOCUMENT } from '../../queries';

function AddParticpant(props) {
  const styles = useStyles();
  const { readOnly, setParticipantInList, closeForm } = props;
  const { values: formValues, setFieldValue } = useFormikContext();

  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState('DNI');
  const [clientInformation, setClientInformation] = useState({
    phone: '',
    cellphone: '',
    communicationCellphone: '',
    communicationEmail: '',
    user: {
      lastNameValidated: '',
      maternalSurname: '',
      firstNameValidated: '',
      emailValidated: '',
    },
  });

  const { data: documentTypesData, loading } = useQuery(LIST_DOCUMENT_TYPES);
  const [getClientInformation, { data: clientData }] = useLazyQuery(
    GET_CLIENT_BY_DOCUMENT,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (documentTypesData) {
      const docs = edgeToList(documentTypesData, 'listDocumentTypes');
      setDocumentTypes(docs);
      setSelectedDocumentType(docs[0].id);
    }
  }, [documentTypesData]);

  useEffect(() => {
    if (clientData) {
      setClientInformation(clientData.getClientByDocument);

      setFieldValue('clientID', clientData.getClientByDocument.id);
      setFieldValue(
        'clientName',
        `${clientData.getClientByDocument.user.firstName}
        ${clientData.getClientByDocument.user.lastName}`
      );
      setFieldValue(
        'communicationCellphone',
        clientData.getClientByDocument.communicationCellphone
      );
      setFieldValue(
        'communicationEmail',
        clientData.getClientByDocument.communicationEmail
      );
    }
  }, [clientData]);

  useEffect(() => {
    if (formValues.documentNumber && formValues.documentNumber > 7) {
      getClientInformation({
        variables: {
          documentNumber: formValues.documentNumber,
        },
      });
    }
  }, [formValues.documentNumber]);

  return loading ? (
    <LoadingIndicator />
  ) : (
    <Grid
      container
      justify="center"
      row="true"
      classes={{ root: styles.gridRoot }}
      spacing={3}
    >
      <Grid container item xs={12} className={styles.title}>
        <p>Ingreso de Datos</p>
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={4}>
          <p>Tipo de Documento</p>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={styles.formControl}>
            <Select
              variant="outlined"
              id="documentType"
              labelId="documentType"
              disabled={readOnly}
              value={selectedDocumentType}
              onChange={$ev => setSelectedDocumentType($ev.target.value)}
              autoWidth
            >
              {documentTypes.map(dt => (
                <MenuItem key={dt.id} value={dt.id} className={styles.item}>
                  {dt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={4}>
          <p>Número de Documento</p>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="documentNumber"
            variant="outlined"
            disabled={readOnly}
            fullWidth
          />
        </Grid>
      </Grid>
      {clientInformation ? (
        <>
          <GridClientField
            label="Apellido paterno"
            value={clientInformation.user.lastName}
          />
          <GridClientField
            label="Apellido materno"
            value={clientInformation.user.maternalSurname}
          />
          <GridClientField
            label="Nombres"
            value={clientInformation.user.firstName}
          />
          <GridClientField
            label="Teléfono fijo"
            value={clientInformation.phone}
          />
          <GridClientField
            label="Teléfono Celular"
            value={clientInformation.cellphone}
          />
          <GridClientField
            label="Correo Electrónico"
            value={clientInformation.user.emailValidated}
          />
          <Grid container item xs={11}>
            <Grid item xs={4}>
              <p>Tel. celular para comunicaciones:</p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="communicationCellphone"
                type="number"
                fullWidth
                variant="outlined"
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid container item xs={11}>
            <Grid item xs={4}>
              <p>Correo para comunicaciones:</p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="communicationEmail"
                type="email"
                fullWidth
                variant="outlined"
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <>
            <Grid container item xs={11}>
              <Grid item xs={4}>
                <p>¿Representante de los copartícipes?</p>
              </Grid>
              <Grid item xs={6}>
                <Grid container item alignItems="center" spacing={1}>
                  <Grid item>No</Grid>
                  <Grid item>
                    <Switch disabled={readOnly} name="isRepresentative" />
                  </Grid>
                  <Grid item>Sí</Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={11}>
              <Grid item xs={4}>
                <p>Porcentaje de participación:</p>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="participationPercentage"
                  fullWidth
                  type="number"
                  disabled={readOnly}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justify="flex-end"
              direction="row"
              spacing={2}
              className={styles.buttons}
            >
              <Button color="primary" variant="contained" onClick={closeForm}>
                {readOnly ? 'Atras' : 'Cancelar'}
              </Button>

              {!readOnly && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    const participantData = {
                      clientID: formValues.clientID,
                      documentNumber: formValues.documentNumber,
                      clientName: formValues.clientName,
                      communicationCellphone: formValues.communicationCellphone,
                      communicationEmail: formValues.communicationEmail,
                      participationPercentage:
                        formValues.participationPercentage,
                      isRepresentative: !!formValues.isRepresentative,
                    };

                    setParticipantInList(participantData);
                    closeForm();
                  }}
                >
                  Confirmar
                </Button>
              )}
            </Grid>
          </>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}

AddParticpant.propTypes = {
  closeForm: PropTypes.func,
  setParticipantInList: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

AddParticpant.defaultProps = {
  closeForm: null,
  readOnly: false,
};

export default AddParticpant;
