import React, { useCallback } from 'react';

// Material Resources
import MuiTextField from '@material-ui/core/TextField';

// Components & Others
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { useFieldToTextField } from 'formik-material-ui';
import { scientificToDecimal } from '../../../utils/commonFunctions';

function CustomNumberFormat(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      value={scientificToDecimal(other.value)}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

CustomNumberFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatInput(props) {
  const { formatprops } = props;
  if (formatprops.prefix) {
    formatprops.prefix += ' ';
  }

  const customize = useCallback(([, , helpers]) => {
    return {
      onChange: event => {
        const { value } = event.target;
        if (value) {
          helpers.setValue(value);
        }
      },
    };
  }, []);
  return (
    <MuiTextField
      {...useFieldToTextField(props, customize)}
      InputProps={{
        inputComponent: CustomNumberFormat,
        inputProps: formatprops,
      }}
    />
  );
}

NumberFormatInput.propTypes = {
  formatprops: PropTypes.shape().isRequired,
};

export default NumberFormatInput;
