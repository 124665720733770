import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  customColumnHeader: {
    borderBottom: 'none',
    '& .MuiTypography-root': {
      display: 'flex',
    },
  },
  tableTitle: {
    fontWeight: 500,
  },
}));
