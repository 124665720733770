import React from 'react';
import useStyles from './styles';
import HelpIcon from '../svg/helpIcon';
import ProgressCircle from './progress-circle';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';

import { useHistory } from 'react-router-dom';
import getClientMode from 'src/store/utils-store/selectors';
import { useSelector } from 'react-redux';
const textCompletePercentageBanner = [
  {
    id: 3,
    title: ' ¡Gracias por llenar tu Ficha Colaborador!',
    content:
      'Si has modificado tu información, haz clic en "Actualizar ficha" para que tus datos se mantengan correctos y al día.',

    action: '/cliente/ficha-cliente',
  },
];

const textPercentageBanner = [
  {
    id: 2,
    title: ' ¡Continúa para finalizar tu Ficha Colaborador!',
    content: '',
    action: '/cliente/ficha-cliente',
  },
];

const textWelcomeBanner = [
  {
    id: 1,
    title: '¡Bienvenido a Core Capital! ',
    content:
      'Queremos asegurarnos de que tengas la mejor experiencia posible. Por favor, dedica unos minutos a completar tu Ficha Colaborador',
    action: '/cliente/ficha-cliente',
  },
];

function Banners() {
  const classes = useStyles();
  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const modeClient = useSelector(getClientMode());
  const history = useHistory();
  const percentage =
    clients?.currentUser?.clientSet.edges[0].node.completitionPercentage;

  const contentBannerStyleWithIcon = modeClient
    ? classes.contentBannersDarkIcon
    : classes.contentBannersIcon;
  const contentBannerStyleNoIcon = modeClient
    ? classes.contentBannersDark
    : classes.contentBanners;

  const bannerIcon =
    percentage > 0 && percentage < 100
      ? contentBannerStyleNoIcon
      : contentBannerStyleWithIcon;

  return (
    <>
      <div className={classes.bannerDiv}>
        <div className={bannerIcon}>
          <div className={classes.mainDiv}>
            {percentage > 0 && percentage < 100 && (
              <>
                {textPercentageBanner.map(item => {
                  return (
                    <>
                      <div className={classes.divProgress}>
                        <div className={classes.devTextProgress}>
                          <p className={classes.title}>{item.title}</p>
                          <p className={classes.content}>{item.content}</p>
                          <div className={classes.btnDiv}>
                            <div
                              role="button"
                              onClick={() => history.push(item.action)}
                              tabIndex={0}
                              className={classes.btn}
                              onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  history.push(item.action);
                                }
                              }}
                            >
                              Continuar Ficha
                            </div>
                          </div>
                        </div>

                        <ProgressCircle percentage={percentage} />
                      </div>
                    </>
                  );
                })}
              </>
            )}
            {percentage == 100 && (
              <>
                {textCompletePercentageBanner.map(item => {
                  return (
                    <>
                      <div className={classes.bannerDivText}>
                        <p className={classes.title}>{item.title}</p>
                        <p className={classes.content}>{item.content}</p>
                        <div className={classes.btnDiv}>
                          <div
                            role="button"
                            onClick={() => {
                              window.location.href = item.action;
                            }}
                            tabIndex={0}
                            className={classes.btn}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                window.location.href = item.action;
                              }
                            }}
                            className={classes.btn}
                          >
                            Actualizar Ficha
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
            {percentage == 0 && (
              <>
                {textWelcomeBanner.map(item => {
                  return (
                    <>
                      <div className={classes.bannerDivText}>
                        <p className={classes.title}>{item.title}</p>
                        <p className={classes.content}>{item.content}</p>
                        <div className={classes.btnDiv}>
                          <div
                            role="button"
                            onClick={() => {
                              window.location.href =item.action;
                            }}
                            tabIndex={0}
                            className={classes.btn}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                window.location.href = item.action;
                              }
                            }}
                            className={classes.btn}
                          >
                            Completar Ficha
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Banners;
