import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  simpleFormContainer: {
    padding: '1rem',
    '& .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  minusButton: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: 10,
  },
}));
