import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  adminMenu: {
    '& .MuiTypography-root': {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.contrastText,
      whiteSpace: 'normal',
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },
  },
  optionButtons: {
    margin: theme.spacing(1),
    minHeight: 30,
    minWidth: 30,
    padding: '4px 4px',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
