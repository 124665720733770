import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  dashboardPage: {},
  logo: {
    width: 180,
  },
  employeebg: {
    background: '#f0f3f5',
  },
  employeeLayout: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#FFF',
  },
}));
