import React, { useState, useEffect, useRef } from 'react';
import PasswordKeyboard from './numericKeyboard';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { useSession } from 'src/providers/SessionProvider';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import { gql } from 'apollo-boost';
import { Visibility, VisibilityOff } from '@material-ui/icons';
// import useStyles from './styles';
function NumericFormLogin(props) {
  const { documentNumber, handlerRecoverPass } = props;
  const inputRef = useRef(null);
  const keyboardRef = useRef(null);
  const [focusInput, setFocusInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { setRefreshToken } = useSession();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const { userLoading, data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        payload
        refreshExpiresIn
      }
    }
  `;

  const [
    loginMutation,
    { data: loginData, error: loginError, client },
  ] = useMutation(LOGIN, {
    onCompleted(data) {
      const {
        tokenAuth: { payload, refreshExpiresIn },
      } = data;
      setRefreshToken({ ...payload, refreshExpiresIn });
      client.resetStore();
      setLoading(false);
    },
    onError(error) {
      console.error(error);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (loginData) {
      const {
        tokenAuth: { payload },
      } = loginData;
      if (payload.usergroup === 'admin_group') {
        history.push('/administrador');
      } else if (payload.usergroup === 'client_group') {
        if (user && user.currentUser)
          payload.userFullName = `${user?.currentUser?.firstName} ${user?.currentUser?.lastName}`;
        window.dataLayer.push({ event: 'Login Intranet', info: payload });
        history.push('/cliente');
      }
    }
  }, [loginData, user]);

  const linkPassword = () => {
    handlerRecoverPass();
  };

  const onSubmit = () => {
    setLoading(true);
    loginMutation({
      variables: {
        username: documentNumber,
        password: formData.password,
      },
    });
  };

  const handlerPassword = value => {
    setFormData({
      ...formData,
      password: value,
    });
  };
  
  useEffect(() => {
    if (formData.password.length === 6) {
      setFocusInput(false);
      setShowPassword(false);
    }
  }, [formData.password]);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClickOutside = event => {
    if (
      keyboardRef.current &&
      !keyboardRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setFocusInput(false);
      setShowPassword(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlerOpenKeyboard = () => {
    setFocusInput(true);
    setFormData({
      ...formData,
      password: '',
    });
  };
  return (
    <>
      <TextField
        ref={inputRef}
        type={showPassword ? 'text' : 'password'}
        label="Clave de 6 dígitos"
        sx={{ pointerEvents: 'none' }}
        name=""
        variant="outlined"
        value={formData.password}
        onFocus={handlerOpenKeyboard}
        fullWidth
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {focusInput && (
        <div ref={keyboardRef} className={classes.main}>
          <PasswordKeyboard handlerPassword={handlerPassword} />
        </div>
      )}

      <Button
        className={classes.btn}
        variant="contained"
        color="secondary"
        disabled={loading}
        onClick={onSubmit}
        type="submit"
      >
        Iniciar Sesión
      </Button>

      <div className={classes.divResetPass}>
        <p
          id="forgot_password_link"
          onClick={linkPassword}
          className={classes.forgetPass}
        >
          {' '}
          ¿Olvidaste tu contraseña?
        </p>
      </div>
      {loginError && (
        <p className={classes.errorText}>Credenciales incorrectas</p>
      )}
    </>
  );
}

export default NumericFormLogin;
