import {
  SET_ACTIVE_FORM,
  SET_IS_SOCIETY,
  SET_IS_MARRIED,
  SET_HAS_ATTORNEY,
  SET_ANNEX_B,
  SET_VISIBLE,
  SET_CLIENTPEP,
  SET_SPOUSEPEP,
  SET_RELATIVEPEP,
  SET_STATUS,
  SET_EDITION,
  SET_IS_PERUVIAN,
  SET_MISSING_STEPS,
} from './constants';

export function setActiveFormAction(formId) {
  return {
    type: SET_ACTIVE_FORM,
    formId,
  };
}
export function setMarriedAction(isMarried) {
  return {
    type: SET_IS_MARRIED,
    isMarried,
  };
}
export function setSocietyAction(isSociety) {
  return {
    type: SET_IS_SOCIETY,
    isSociety,
  };
}
export function setAttorneyAction(hasAttorney) {
  return {
    type: SET_HAS_ATTORNEY,
    hasAttorney,
  };
}
export function setAnnexBAction(hasAnnexB) {
  return {
    type: SET_ANNEX_B,
    hasAnnexB,
  };
}
export function setClientPEPAction(hasClientPEP) {
  return {
    type: SET_CLIENTPEP,
    hasClientPEP,
  };
}
export function setSpousePEPAction(hasSpousePEP) {
  return {
    type: SET_SPOUSEPEP,
    hasSpousePEP,
  };
}
export function setRelativePEPAction(hasRelativePEP) {
  return {
    type: SET_RELATIVEPEP,
    hasRelativePEP,
  };
}
export function setVisible(nodeId, state) {
  return {
    type: SET_VISIBLE,
    nodeId,
    state,
  };
}
export function setStatus(nodeId, state) {
  return {
    type: SET_STATUS,
    nodeId,
    state,
  };
}
export function setEdition(edition) {
  return {
    type: SET_EDITION,
    edition,
  };
}
export function setIsPeruvian(isPeruvian) {
  return {
    type: SET_IS_PERUVIAN,
    isPeruvian,
  };
}
export function setMissingSteps(missingSteps) {
  return {
    type: SET_MISSING_STEPS,
    missingSteps,
  };
}
