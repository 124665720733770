/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import BankAccountList from '../../../components/Models/BankAccount/BankAccountList';
import BankAccountEdit from '../../../components/Models/BankAccount/BankAccountCreateEdit/edit';
import BankAccountForm from '../../../components/Models/BankAccount/BankAccountForm';

function BankAccountPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={BankAccountList} />
      <Route path={`${path}/crear`} component={BankAccountForm} />
      <Route path={`${path}/:id`} component={BankAccountEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

export default BankAccountPage;
