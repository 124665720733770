/*
 * HomePage
 */

import React from 'react';

// Material Resources

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';

import SwornDeclarationList from '../../../components/Models/SwornDeclaration/SwornDeclarationList';
import SwornDeclarationForm, {
  SwornDeclarationEdit,
} from '../../../components/Models/SwornDeclaration/SwornDeclarationForm';

function SwornDeclarationPage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SwornDeclarationList} />
      <Route path={`${path}/crear`} component={SwornDeclarationForm} />
      <Route path={`${path}/:id`} component={SwornDeclarationEdit} />
      <Route path="" component={NotFound} />
    </Switch>
  );
}

SwornDeclarationPage.propTypes = {};

export default SwornDeclarationPage;
