import * as Yup from 'yup';

import {
  defaultDate,
  defaultDateTomorrow,
} from '../../../../../FormComponents/DatePicker';
import { INPUT_VARIANT } from '../../../../../Forms/StepsForm/fieldsSelector';

export const startValues = {
  targetCapitalIncrease: 0,
  valueFee: 0,
  valueFeeNominal: 0,
  fees: 0,
  missingFees: 0,
  initialFeePaymentDate: defaultDate,
  lastFeePaymentDate: defaultDateTomorrow,
  dateEmissionFee: defaultDate,
  repurchaseNoticeDate: defaultDate,
  initialParticipationOrderReceptionDate: defaultDate,
  lastParticipationOrderReceptionDate: defaultDateTomorrow,
  initialFeeAdjudicationDate: defaultDate,
  finalFeeAdjudicationDate: defaultDateTomorrow,
  valorizationDate: defaultDate,
  importantFactDate: defaultDate,
};

export const validationSchema = Yup.object().shape({
  targetCapitalIncrease: Yup.number().required(),
  valueFee: Yup.number().required(),
  valueFeeNominal: Yup.number().required(),
  dateEmissionFee: Yup.string().required(),
  repurchaseNoticeDate: Yup.string().required(),
  initialParticipationOrderReceptionDate: Yup.string().required(),
  lastParticipationOrderReceptionDate: Yup.string().required(),
  initialFeeAdjudicationDate: Yup.string().required(),
  finalFeeAdjudicationDate: Yup.string().required(),
  valorizationDate: Yup.string().required(),
  initialFeePaymentDate: Yup.string().required(),
  lastFeePaymentDate: Yup.string().required(),
});

export const formStructure = [
  {
    label: 'Capital objetivo aumento',
    name: 'targetCapitalIncrease',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.formatNumber,
    formatprops: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  {
    label: 'Valor cuota nominal',
    name: 'valueFeeNominal',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.formatNumber,
    formatprops: {
      thousandSeparator: true,
      decimalScale: 2,
      fixedDecimalScale: true,
    },
  },
  {
    label: 'Valor cuota',
    name: 'valueFee',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.formatNumber,
    formatprops: {
      thousandSeparator: true,
      decimalScale: 10,
      fixedDecimalScale: true,
    },
  },
  {
    label: 'Cuotas',
    name: 'fees',
    readOnly: true,
    gridMD: 4,
    inputVariant: INPUT_VARIANT.number,
  },
  {
    label: 'Cuotas faltantes',
    name: 'missingFees',
    readOnly: true,
    gridMD: 4,
    inputVariant: INPUT_VARIANT.number,
  },
  {
    label: 'Fecha de emisión de cuotas',
    name: 'dateEmissionFee',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.date,
  },
  {
    label: 'Fecha de aviso de recompra',
    name: 'repurchaseNoticeDate',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.date,
  },
  {
    label: 'Fecha de valorización',
    name: 'valorizationDate',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.date,
  },
  {
    label: 'Fecha de hecho de importancia',
    name: 'importantFactDate',
    gridMD: 4,
    inputVariant: INPUT_VARIANT.date,
  },
  {
    gridMD: 12,
    inputVariant: INPUT_VARIANT.dateRange,
    fieldData: {
      startDate: {
        label: 'Fecha de pago de cuotas inicial',
        name: 'initialFeePaymentDate',
      },
      endDate: {
        label: 'Fecha de pago de cuotas final',
        name: 'lastFeePaymentDate',
      },
    },
  },
  {
    gridMD: 12,
    inputVariant: INPUT_VARIANT.dateRange,
    fieldData: {
      startDate: {
        label: 'Fecha de la recepción de ordenes participación inicial',
        name: 'initialParticipationOrderReceptionDate',
      },
      endDate: {
        label: 'Fecha de la recepción de ordenes participación final',
        name: 'lastParticipationOrderReceptionDate',
      },
    },
  },
  {
    gridMD: 12,
    inputVariant: INPUT_VARIANT.dateRange,
    fieldData: {
      startDate: {
        label: 'Fecha de adjudicación de cuotas inicial',
        name: 'initialFeeAdjudicationDate',
      },
      endDate: {
        label: 'Fecha de adjudicación de cuotas final',
        name: 'finalFeeAdjudicationDate',
      },
    },
  },
];
