import { gql } from 'apollo-boost';

export const LIST_PROMOTERS = gql`
  query listPromoter {
    listPromoter {
      edges {
        node {
          id
          firstName
          lastName
          enabled
          isCoreConsultant
        }
      }
    }
  }
`;

export const LIST_LEADS_CHANNELS = gql`
  query listLeadsChannels {
    leadsChannels {
      name
      value
    }
  }
`;

export const LIST_LEADS_CHANNELS_DETAIL = gql`
  query listLeadsChannelsDetail {
    leadsChannelsDetail {
      name
      value
    }
  }
`;

export const LIST_LEADS_RANGES = gql`
  query listLeadsRanges {
    leadsRanges {
      name
      value
    }
  }
`;

export const LIST_LEADS_PRODUCTS = gql`
  query listLeadsProducts {
    leadsProducts {
      name
      value
    }
  }
`;

export const LIST_CANAL_ACTUAL = gql`
  query canalesActual {
    canalesActual {
      name
      value
    }
  }
`;

export const LIST_CANAL_ORIGEN = gql`
  query canalesOrigen {
    canalesOrigen {
      name
      value
    }
  }
`;

export const LIST_INTERESTED_INVESTORS = gql`
  query listClientsInterestedInvestors(
    $search: String
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
  ) {
    listClients(
      state: "interested_investor"
      search: $search
      first: $first
      last: $last
      before: $beforeCursor
      after: $afterCursor
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          photoDocumentFront
          photoDocumentBack
          photoDocumentsUrl
          user {
            id
            firstName
            lastName
            maternalSurname
            email
            isActive
            isEmailAutogenerated
            documentType {
              id
              name
            }
            documentNumber
          }
          promoterCompany {
            id
            businessName
          }
          promoter {
            id
            firstName
            lastName
          }
          coreConsultant {
            id
            firstName
            lastName
          }
          interestFunds {
            edges {
              node {
                id
                name
              }
            }
          }
          alternativeDocumentType
          cellphone
          riskConsultingMessage
          state
          isRiskConsulted
          leadsChannel
          leadsChannelDetail
          leadsRange
          urlRiskReport
        }
      }
    }
  }
`;

export const GET_INTERESTED_INVESTOR_INFO = gql`
  query interestedInvestor($id: ID!) {
    client(id: $id) {
      id
      sex
      photoDocumentFront
      photoDocumentBack
      photoDocumentsUrl
      enviarComunicacion
      nombreComunicacion
      nombreCanalActual
      nombreCanalOrigen
      correoIntermediario
      canalActual
      canalOrigen
      user {
        id
        firstName
        lastName
        maternalSurname
        email
        userType
        documentType {
          id
          name
        }
        documentNumber
      }
      promoterCompany {
        id
        businessName
      }
      promoter {
        id
        firstName
        lastName
      }
      coreConsultant {
        id
        firstName
        lastName
      }
      alternativeDocumentType
      cellphone
      leadsChannel
      leadsChannelDetail
      leadsRange
      leadsProducts {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_USER_INTERESTED_INVESTORS = gql`
  mutation createUserInterestedInvestor($input: UserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        email
        isActive
        documentType {
          id
          name
        }
        documentNumber
        clientSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_USER_INTERESTED_INVESTORS = gql`
  mutation updateUserInterestedInvestor($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        email
        documentType {
          id
        }
        documentNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UDPATE_CLIENT_INTERESTED_INVESTORS = gql`
  mutation updateClientInterestedInvestor($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          firstName
          lastName
          maternalSurname
          email
          isActive
          documentType {
            id
            name
          }
          documentNumber
        }
        sex
        promoterCompany {
          id
          businessName
        }
        promoter {
          id
          firstName
          lastName
        }
        coreConsultant {
          id
          firstName
          lastName
        }
        enviarComunicacion
        nombreComunicacion
        nombreCanalActual
        nombreCanalOrigen
        correoIntermediario
        canalActual
        canalOrigen
        alternativeDocumentType
        cellphone
        riskConsultingMessage
        state
        isRiskConsulted
        leadsChannel
        leadsChannelDetail
        leadsRange
        leadsProducts {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_USER_INTERESTED_INVESTORS = gql`
  mutation deleteUserInvestor($id: ID!) {
    deleteUser(id: $id) {
      user {
        id
        firstName
        lastName
        maternalSurname
        email
        isActive
        documentType {
          id
          name
        }
        documentNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SEND_CREDENTIALS = gql`
  mutation sendCredentials($id: ID!) {
    sendCredentials(id: $id) {
      client {
        id
      }
      errors {
        field
        message
      }
    }
  }
`;

export const LIST_LEAD_PRODUCTS = gql`
  {
    listLeadProducts {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
