import Yup from '../../../../utils/yup';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';

export const startData = {
  signingFile: '',
  clients: [],
  isBiometricAvailable: false,
  isContract: '',
};

export const validationSchema = Yup.object().shape({
  signingFile: Yup.string().required(),
  clients: Yup.array(),
  isBiometricAvailable: Yup.bool().required(),
  isContract: Yup.string().required(),
});

export const formStructure = clients => [
  {
    label: 'Documento',
    name: 'signingFile',
    gridMD: 12,
    inputVariant: INPUT_VARIANT.file,
  },
  {
    label: 'Clientes',
    name: 'clients',
    inputVariant: INPUT_VARIANT.select,
    gridMD: 12,
    data: clients,
    mapData: { value: 'clientId', label: 'fullName' },
  },
  {
    label: 'Biometría',
    name: 'isBiometricAvailable',
    inputVariant: INPUT_VARIANT.singleCheckbox,
    gridMD: 6,
  },
];

export const extraFormStructure = clients => [
  {
    label: 'Nombres',
    name: 'names',
    inputVariant: INPUT_VARIANT.text,
    gridMD: 6,
  },
  {
    label: 'Apellidos',
    name: 'lastnames',
    inputVariant: INPUT_VARIANT.text,
    gridMD: 6,
  },
  {
    label: 'Documento',
    name: 'document_number',
    inputVariant: INPUT_VARIANT.text,
    gridMD: 6,
  },
  {
    label: 'Correo',
    name: 'email',
    inputVariant: INPUT_VARIANT.text,
    gridMD: 6,
  },
];
