import React from 'react';

// GraphQl
import { useQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import LoadingIndicator from '../../../LoadingIndicator';
import BankAccountForm from '../CustomSubmodelForm';
import { parseBool } from '../../../../utils/commonFunctions';
import CustomSubmodelForm from '../CustomSubmodelForm';

function CustomSubmodelEdit(props) {
  const {
    id,
    afterEditCreate,
    getQuery,
    formatQuery,
    submodelName,
    //Form props
    mutationsData,
    startValues,
    canBeDeleted,
    validationSchema,
    validationFunction,
    formStructure,
    formatBeforeSubmit,
    buttonLoader,
  } = props;

  const history = useHistory();

  const { loading, error, data } = useQuery(getQuery, {
    variables: { id },
  });

  if (loading) return <LoadingIndicator />;
  if (error) {
    console.error('Get inner submodel error: ', error);
  }

  if (!data) {
    return <LoadingIndicator />;
  }

  const formatValue = formatQuery(data);

  return (
    <CustomSubmodelForm
      model={formatValue}
      id={id}
      isEdit
      canBeDeleted={canBeDeleted}
      mainID={id}
      buttonLoader={buttonLoader}
      submodelName={submodelName}
      afterEditCreate={afterEditCreate}
      mutationsData={mutationsData}
      startValues={startValues}
      validationSchema={validationSchema}
      validationFunction={validationFunction}
      formStructure={formStructure}
      formatBeforeSubmit={formatBeforeSubmit}
    />
  );
}

CustomSubmodelEdit.propTypes = {
  id: PropTypes.string.isRequired,
  afterEditCreate: PropTypes.func.isRequired,
  getQuery: PropTypes.shape().isRequired,
  formatQuery: PropTypes.func.isRequired,
  buttonLoader: PropTypes.bool,
};

CustomSubmodelEdit.defaultProps = {
  canBeDeleted: true,
};

export default CustomSubmodelEdit;
