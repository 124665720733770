// Material Resources
import React, { useState, useEffect, useCallback, useRef } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { Grid, Button } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// GraphQl
import { useMutation, useLazyQuery, useQuery } from '@apollo/react-hooks';

// Others
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Webcam from 'react-webcam';
import CustomModal from '../CustomModal';
import CustomSelectMaterialCore from '../FormComponents/CustomSelectMaterialCore';
import { useSnackbar } from 'notistack';
import { successMessage, errorMessage } from '../../utils/commonFunctions';
import LoadingIndicator from '../LoadingIndicator';
import { CREATE_VIDEO_VERIFICATION } from './queries';
import {
  REACT_APP_APIKEY,
  REACT_APP_AUTHDOMAIN,
  REACT_APP_PROJECTID,
  REACT_APP_STORAGEBUCKET,
  REACT_APP_APPID,
  REACT_APP_MESSAGINGSENDERID,
  REACT_APP_COLLECTION_PREFIX,
} from '../../utils/constants';

// Styles & Images
import useStyles from './styles';
import face_example from '../../images/face_example.gif';
import mainTheme from '../../themes/mainTheme';

// Import the functions you need from the SDKs you need
import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: REACT_APP_APIKEY,
  authDomain: REACT_APP_AUTHDOMAIN,
  projectId: REACT_APP_PROJECTID,
  storageBucket: REACT_APP_STORAGEBUCKET,
  appId: REACT_APP_APPID,
  messagingSenderId: REACT_APP_MESSAGINGSENDERID,
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

function BiometricModal(props) {
  const { onClosePress, showModal, setShowModal, clientID } = props;
  const [nextStep, setNextStep] = useState(false);
  const [verify, setVerify] = useState(true);
  const [verifyButton, setVerifyButton] = useState(false);
  const [devices, setDevices] = useState([]);
  const [facingMode, setFacingMode] = useState('user');
  const [deviceMain, setMainDevice] = useState('');
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [urlG, setUrlG] = useState('');
  const [capture, setCapture] = useState(false);
  const [encodedVideo, setEncodedVideo] = useState(null);
  const [videoVerificationId, setVideoVerificationId] = useState(null);
  const [videoVerificationLoading, setVideoVerificationLoading] = useState(
    false,
  );
  const [codec, setCodec] = useState('video/webm');
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const [createVideoVerification] = useMutation(CREATE_VIDEO_VERIFICATION, {
    onError: error => {
      errorMessage(error, enqueueSnackbar);
      setVideoVerificationLoading(false);
    },
    onCompleted: data => {
      const { videoVerification, errors } = data.createVideoVerification;
      if (videoVerification) {
        successMessage('Verificando información.', enqueueSnackbar);
        setVideoVerificationId(videoVerification.id);
      } else if (errors) {
        errors.map(function({ message }) {
          const errorMessages = JSON.parse(message.replace(/'/g, '"'));
          errorMessages.forEach(function(errorMsge) {
            errorMessage(errorMsge, enqueueSnackbar);
          });
        });
        setVideoVerificationLoading(false);
      }
    },
  });

  const facingType = [
    { value: 'user', label: 'Frontal' },
    { value: 'environment', label: 'Posterior' },
  ];
  const classes = useStyles();

  const renderTime = ({ remainingTime }) => {
    return (
      <div className={classes.timer}>
        <h1>{remainingTime}</h1>
      </div>
    );
  };

  const handleStartCaptureClick = useCallback(() => {
    let curCodec = codec;
    if (!MediaRecorder.isTypeSupported(curCodec)) {
      if (MediaRecorder.isTypeSupported('video/webm')) {
        setCodec('video/webm');
        curCodec = 'video/webm';
      } else {
        return;
      }
    }
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: curCodec,
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => prev.concat(data));
      }
    },
    [setRecordedChunks],
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  useEffect(() => {
    if (recordedChunks.length) {
      const blobVideo = new Blob(recordedChunks, { type: codec });
      const url = URL.createObjectURL(blobVideo);
      async function createEncodedVideo() {
        const encoded = await toBase64(blobVideo);
        setEncodedVideo(encoded);
      }
      createEncodedVideo();
      setUrlG(url);
      setCapture(true);
      setVerify(false);
    }
  }, [recordedChunks]);

  const handleDevices = useCallback(
    mediaDevices =>
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices],
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (videoVerificationId) {
      const unsub = db
        .collection(`${REACT_APP_COLLECTION_PREFIX}_video-verifications`)
        .doc(clientID)
        .collection('videoverifications')
        .doc(videoVerificationId)
        .onSnapshot(doc => {
          let documentData = doc.data();
          if (documentData !== undefined && 'active' in documentData) {
            const is_active = documentData.active;
            const status = documentData.status;
            const badMatiStates = ['rejected', 'reviewNeeded'];
            if (is_active) {
              onClosePress();
              setShowModal(false);
              setVideoVerificationLoading(false);
              successMessage(
                'Verificación biométrica correcta.',
                enqueueSnackbar,
              );
            } else if (!is_active && badMatiStates.includes(status)) {
              setShowModal(false);
              errorMessage(
                'La verificación biométrica no fue correcta, vuelva a intentarlo',
                enqueueSnackbar,
              );
            }
          }
        });

      return () => unsub();
    }
  }, [videoVerificationId]);

  const videoConstraints = {
    facingMode: facingMode,
    deviceId: deviceMain,
  };

  const handleOnChange = e => setFacingMode(e.target.value);
  const handleOnChangeDevice = e => setMainDevice(e.target.value);

  return (
    <CustomModal
      fullWidth
      maxWidth="md"
      showModal={showModal}
      title="Verificación Biométrica"
      onClosePress={() => {
        setShowModal(false);
        setNextStep(false);
        setVerify(true);
      }}
      disableFlex
      hideScroll
    >
      <div className={classes.modalContent} justify="center">
        {!nextStep ? (
          <Grid container>
            <Grid container justify="center">
              <PhotoCameraIcon fontSize="large" color="secondary" />
              <CheckIcon fontSize="large" color="primary" />
            </Grid>
            <Grid container justify="center">
              <h2>A continuación necesitamos grabar un video corto.</h2>
            </Grid>
            <Grid container justify="center">
              <h3>Por favor habilita el acceso</h3>
            </Grid>
            <Grid container justify="flex-end" alignItems="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setNextStep(true);
                }}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center">
            <Grid
              container
              item
              md={6}
              xs={12}
              alignItems="center"
              className={classes.select}
            >
              <CustomSelectMaterialCore
                id={'Camera'}
                name={'Camera'}
                label={'Cámara'}
                data={facingType}
                onChange={handleOnChange}
              />
            </Grid>
            {devices.length > 1 && (
              <Grid
                container
                item
                md={6}
                xs={12}
                alignItems="center"
                className={classes.select}
              >
                <CustomSelectMaterialCore
                  id={'Device'}
                  name={'Device'}
                  label={'Dispositivo'}
                  data={devices}
                  onChange={handleOnChangeDevice}
                  mapData={{ value: 'deviceId', label: 'label' }}
                />
              </Grid>
            )}
            <Grid
              container
              item
              md={8}
              xs={12}
              alignItems="center"
              className={classes.video}
            >
              {capture ? (
                <video className={classes.webcam} controls>
                  <source src={urlG} type={codec} />
                </video>
              ) : (
                <Webcam
                  audio={false}
                  videoConstraints={videoConstraints}
                  className={classes.webcam}
                  ref={webcamRef}
                />
              )}
            </Grid>
            <Grid container justify="center">
              {capture ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setCapture(false);
                    setRecordedChunks([]);
                    window.URL.revokeObjectURL(urlG);
                    setUrlG('');
                    setVerify(true);
                    setVerifyButton(false);
                  }}
                >
                  Regresar
                </Button>
              ) : (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    handleStartCaptureClick();
                    setTimeout(() => {
                      handleStopCaptureClick();
                    }, 5000);
                  }}
                  disabled={capturing}
                >
                  Capturar
                </Button>
              )}
            </Grid>

            {capturing && (
              <Grid className={classes.timerWrapper} container justify="center">
                <CountdownCircleTimer
                  isPlaying
                  duration={5}
                  colors={mainTheme.palette.primary.main}
                  size={80}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </Grid>
            )}
            {!capture && (
              <Grid container justify="center">
                <h3>
                  Cuando se inicie la grabación, por favor haz dos círculos con
                  tu nariz.
                </h3>
                <h3>Se hará una grabación de 5 segundos.</h3>
                <Grid container justify="center">
                  <img src={face_example} className={classes.image} />
                </Grid>
              </Grid>
            )}
            {!verify ? (
              <Grid
                className={classes.video}
                container
                justify="flex-end"
                alignItems="center"
              >
                <Button
                  color="secondary"
                  variant="contained"
                  disabled={verifyButton}
                  onClick={() => {
                    successMessage(
                      'Iniciando verificación biométrica.',
                      enqueueSnackbar,
                    );
                    createVideoVerification({
                      variables: {
                        input: {
                          video: encodedVideo,
                          client: clientID,
                        },
                      },
                    });
                    setVideoVerificationLoading(true);
                    setVerifyButton(true);
                  }}
                >
                  Verificar
                </Button>
                {videoVerificationLoading ? (
                  <Grid item md={2} xs={2}>
                    <LoadingIndicator size="sm" />
                  </Grid>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        )}
      </div>
    </CustomModal>
  );
}

export default BiometricModal;
