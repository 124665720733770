import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  linkField: {
    '& .MuiOutlinedInput-root': {
      minWidth: '350px',
      color: 'rgba(46, 60, 97, 1)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 8,
    },
    '& .MuiOutlinedInput-input': {
      fontWeight: 200,
      padding: '7px 7px',
    },
    marginRight: '10px',
  },
  shareButton: {
    borderRadius: 8,
    padding: '8px 16px',
  },
  container: {
    paddingLeft: '5%',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1%',
      paddingRight: '1%',
    },
    margin: '10px 0px',
  },
}));
