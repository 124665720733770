import React from 'react';

function TrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#686565"
        fillRule="evenodd"
        d="M10.829 0H9.17C7.835 0 7.31.088 6.77.263c-.54.177-.99.442-1.355.8-.365.357-.616.728-.98 1.731l-.225.62H1.25c-.332 0-.65.103-.884.286-.234.183-.366.431-.366.69 0 .259.132.507.366.69.235.183.552.286.884.286h.704l.879 10.976c.102 1.287.153 1.931.508 2.42.312.43.784.776 1.354.992C5.341 20 6.169 20 7.822 20h4.356c1.653 0 2.48 0 3.127-.246.57-.216 1.042-.562 1.354-.992.355-.488.406-1.132.509-2.422l.878-10.974h.704c.331 0 .65-.103.884-.286.234-.183.366-.431.366-.69 0-.258-.132-.507-.366-.69a1.447 1.447 0 00-.884-.285h-2.959l-.225-.62c-.365-1.004-.616-1.375-.981-1.733A3.417 3.417 0 0013.23.263C12.691.088 12.164 0 10.829 0zm2.362 3.415l-.031-.088c-.254-.7-.363-.883-.53-1.048a.882.882 0 00-.365-.216c-.25-.082-.505-.112-1.436-.112H9.17c-.931 0-1.187.03-1.436.112a.882.882 0 00-.365.216c-.169.165-.276.348-.53 1.048l-.032.088h6.383zm-6.179 3.42a1.16 1.16 0 00-.356.065.967.967 0 00-.297.167.73.73 0 00-.193.243.589.589 0 00-.059.282l.372 8.288c.01.193.119.374.3.505.182.13.422.2.67.194.246-.007.48-.09.65-.23.169-.14.26-.328.253-.52l-.37-8.288a.675.675 0 00-.298-.51 1.1 1.1 0 00-.671-.196zm5.01.706a.679.679 0 01.301-.504c.182-.13.422-.2.668-.193.247.007.48.089.65.229.17.14.261.327.255.52l-.371 8.287a.679.679 0 01-.3.505c-.182.13-.423.2-.67.194a1.07 1.07 0 01-.65-.23.65.65 0 01-.254-.52l.372-8.288z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default TrashIcon;
