// GraphQl
import { gql } from 'apollo-boost';

export const LIST_CONTRACT_TYPES = gql`
  query listContractType {
    listContractType {
      edges {
        node {
          id
          name
          condition
          fund {
            id
            businessName
          }
        }
      }
    }
  }
`;

export const GET_CONTRACT_TYPE = gql`
  query contractType($id: ID) {
    contractType(id: $id) {
      id
      name
      condition
      fund {
        id
        businessName
      }
      contractText
      annexText1
      annexText2
    }
  }
`;

export const CREATE_CONTRACT_TYPE = gql`
  mutation createContractType($input: ContractTypeInput!) {
    createContractType(input: $input) {
      contractType {
        id
        name
        condition
        fund {
          id
          businessName
        }
        contractText
        annexText1
        annexText2
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_CONTRACT_TYPE = gql`
  mutation updateContractType($id: ID!, $input: ContractTypeInput!) {
    updateContractType(id: $id, input: $input) {
      contractType {
        id
        name
        condition
        fund {
          id
          businessName
        }
        contractText
        annexText1
        annexText2
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DELETE_CONTRACT_TYPE = gql`
  mutation deleteContractType($id: ID!) {
    deleteContractType(id: $id) {
      contractType {
        id
      }
    }
  }
`;
