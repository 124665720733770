/*
 * HomePage
 */

import React from 'react';

// Others
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// Components & Others
import NotFound from '../../NotFoundPage';
import SignatureList from '../../../components/Models/Signatures/SignatureList';

function SignaturePage() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={SignatureList} />
      <Route exact path={path} component={NotFound} />
    </Switch>
  );
}

SignaturePage.propTypes = {};

export default SignaturePage;
