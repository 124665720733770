import { gql } from 'apollo-boost';

export const LIST_EMPLOYEES = gql`
  query listEmployees {
    listEmployees {
      edges {
        node {
          id
          state
          occupation
          pdfSheet
          signedPdfStatus
          lastSignatureDate
          urlRiskReport
          photoDocumentFront
          photoDocumentBack
          photoDocumentsUrl
          core2Uuid
          razonSocial
          promoter {
            id
            firstName
            lastName
          }
          coreConsultant {
            id
            firstName
            lastName
          }
          promoterCompany {
            id
            businessName
          }
          user {
            id
            firstName
            lastName
            maternalSurname
            isStaff
            isActive
            email
            userType
            documentType {
              id
              code
            }
            documentNumber
          }
        }
      }
    }
  }
`;

export const GET_EMPLOYEE_INFO = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      user {
        id
        firstName
        lastName
        maternalSurname
        email
        # isEmailAutogenerated
        documentNumber
        documentType {
          id
          name
        }
      }
      razonSocial
      sex
      occupation
      birthday
      birthplace {
        id
        country {
          id
          name
        }
        county {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
        }
      }
      address {
        id
        country {
          id
          name
        }
        county {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
        }
        detail
      }
      phone
      cellphone
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation createUserInterestedInvestor($input: UserInput!) {
    createUser(input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        email
        isActive
        documentType {
          id
          name
        }
        documentNumber
        clientSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_EMPLOYEE = gql`
  mutation updateUserInterestedInvestor($id: ID!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      user {
        id
        firstName
        lastName
        maternalSurname
        isStaff
        email
        documentType {
          id
        }
        documentNumber
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_CLIENT = gql`
  mutation updateClientInterestedInvestor($id: ID!, $input: ClientInput!) {
    updateClient(id: $id, input: $input) {
      client {
        id
        user {
          id
          firstName
          lastName
          maternalSurname
          email
          isActive
          documentType {
            id
            name
          }
          documentNumber
        }
        sex
        promoterCompany {
          id
          businessName
        }
        promoter {
          id
          firstName
          lastName
        }
        coreConsultant {
          id
          firstName
          lastName
        }
        alternativeDocumentType
        cellphone
        riskConsultingMessage
        state
        isRiskConsulted
        leadsChannel
        leadsChannelDetail
        leadsRange
        leadsProducts {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const LIST_S3FILES = gql`
  query listEmployeeSheets {
    listEmployeeSheets {
      edges {
        node {
          id
          state
          isGeneric
          client {
            id
            user {
              id
              firstName
              lastName
            }
          }
          fileType
          fileName
          generatedFileName
          uploadedAt
        }
      }
    }
  }
`;

export const LIST_RAZON_SOCIAL = gql`
  query razonSocialChoices {
    razonSocialChoices {
      name
      value
    }
  }
`;
