import React from 'react';

function MoreInfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        stroke="#292D32"
        strokeOpacity="0.71"
        strokeWidth="33.333"
        d="M3.833 9.938c0-5.605 4.492-7.73 6.917-7.73s6.917 2.125 6.917 7.73c0 5.604-4.492 7.729-6.917 7.729s-6.917-2.125-6.917-7.73z"
        opacity="0.1"
      />
      <path
        stroke="#292D32"
        strokeOpacity="0.71"
        strokeWidth="2"
        d="M20.5 9.938c0 4.936-4.365 8.937-9.75 8.937S1 14.874 1 9.937C1 5.002 5.365 1 10.75 1s9.75 4.001 9.75 8.938z"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.71"
        strokeWidth="2"
        d="M9.125 6.637a2.25 2.25 0 011.625-.672c1.197 0 2.167.89 2.167 1.986 0 .927-.694 1.706-1.63 1.925-.29.068-.537.284-.537.558v.496M10.75 13.91h.01"
      />
    </svg>
  );
}

export default MoreInfoIcon;
