import { gql } from 'apollo-boost';

const paymentFragment = gql`
  fragment Payment on PaymentType {
    id
    participant {
      state
      id
    }
    date
    amount
    operationNumber
    bank {
      id
      name
    }
    voucherPhoto
    status
  }
`;

export const GET_PAYMENT = gql`
  ${paymentFragment}
  query getPayment($id: ID!) {
    payment(id: $id) {
      ...Payment
      participant {
        id
      }
    }
  }
`;

export const LIST_CLIENT_PAYMENTS = gql`
  ${paymentFragment}
  query listClientPayments($clientId: ID!) {
    listPayments(participant_Client: $clientId) {
      edges {
        node {
          ...Payment
          participant {
            id
            wheel {
              id
              number
              raising {
                id
                number
                fund {
                  id
                  businessName
                  currency {
                    symbol
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PAYMENT = gql`
  ${paymentFragment}
  mutation createPayment($input: PaymentInput!) {
    createPayment(input: $input) {
      errors {
        field
        message
      }
      payment {
        ...Payment
      }
    }
  }
`;

export const UPDATE_PAYMENT = gql`
  ${paymentFragment}
  mutation updatePayment($id: ID!, $input: PaymentInput!) {
    updatePayment(id: $id, input: $input) {
      payment {
        ...Payment
      }
      errors {
        field
        message
      }
    }
  }
`;
