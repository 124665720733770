import { fromJS } from 'immutable';

import { ACTIONS } from './actions';

// The initial state of the App
const initialState = fromJS({
  mode: false,
});

function UtilsStoreReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SET_MODE:
      localStorage.setItem('mode', action.state);
      return state.set('mode', action.state);
    default:
      return state;
  }
}

export default UtilsStoreReducer;
