import React from 'react';

// Material Resources
import {
  Typography,
  Divider,
  Tooltip,
  Collapse,
  List,
  MenuItem,
  ListItemIcon,
  Box,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

// React Admin Resources

// Others
import PropTypes from 'prop-types';

// Styles & Images
import useStyles from './styles';

function SubMenu(props) {
  const { handleToggle, sidebarIsOpen, isOpen, name, icon, children } = props;

  const classes = useStyles();

  const header = (
    <MenuItem button onClick={handleToggle}>
      <ListItemIcon color="secondary" className={classes.icon}>
        {isOpen ? <ExpandMore color="secondary" /> : icon}
      </ListItemIcon>
      <Typography
        variant="inherit"
        className={isOpen ? classes.menuTitleOpen : classes.menuTitle}
      >
        <Box letterSpacing={0}>{name}</Box>
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider className={classes.divider} />
      </Collapse>
    </>
  );
}

SubMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  handleToggle: PropTypes.func.isRequired,
  sidebarIsOpen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
SubMenu.defaultProps = {
  sidebarIsOpen: false,
};
export default SubMenu;
