import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import { LIST_SAFI_RELATIONS } from '@queries/Safi';
import {
  GET_SAFI_RELATION_SPOUSE,
  UPDATE_CLIENT_SAFI_INFORMATION,
} from '@queries/Client';
import Yup from '../../../../utils/yup';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import { FORM_OPTIONS } from '../../constants';
import {
  edgeToList,
  errorMessage,
  successMessage,
} from '../../../../utils/commonFunctions';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import LoadingIndicator from '../../../LoadingIndicator';

import Tooltip from '@material-ui/core/Tooltip';
import ClientTexts from '../../../FormComponents/ClientTexts';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

function SafiRelationSpouse(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [vinculation, setVinculation] = useState(false);
  const [openHelp, setOpenHelp] = React.useState(false);

  // Click Help
  const handleTooltipClose = () => {
    setOpenHelp(false);
  };

  const handleTooltipOpen = () => {
    setOpenHelp(true);
  };

  // RELATED QUERIES
  const { error: safiRelationError, data: safiRelationData } = useQuery(
    LIST_SAFI_RELATIONS,
  );
  const {
    loading: clientLoading,
    error: clientError,
    data: clientData,
  } = useQuery(GET_SAFI_RELATION_SPOUSE, { variables: { id: clientID } });
  const updateClient = useMutation(UPDATE_CLIENT_SAFI_INFORMATION, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó Relación con SAF de cónyuge.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  const partialUpdateClient = useMutation(UPDATE_CLIENT_SAFI_INFORMATION, {
    onError(error) {
      errorMessage(error, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage(
          'Se guardó Relación con SAF de cónyuge parcialmente.',
          enqueueSnackbar,
        );
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  if (safiRelationError) {
    console.error('Safi relation - safi relations');
  }

  if (clientError) {
    console.error('get client - safi relations');
  }

  useEffect(() => {
    if (clientData) {
      setVinculation(
        clientData.client.spouse.relations.edges.length > 0 ||
          !!clientData.client.spouse.otherRelation,
      );
    }
  }, [clientData]);

  if (clientLoading) return <LoadingIndicator />;

  function onHasVinculationChange(event) {
    event.preventDefault();
    if (event.target.value) {
      setVinculation(event.target.value === 'true');
    }
  }

  function validationFunction() {
    const errors = {};
    return errors;
  }

  const validationSchema = Yup.object().shape({
    relations: Yup.array()
      .required()
      .test('test_option_no_vinculation', '', function(value) {
        //Get id for option No relation
        const isType = element => element.typeRelation == 'A_0';
        const listV = edgeToList(safiRelationData, 'listSafiRelations');
        const idNoVinc = listV.findIndex(isType);

        if (value && value.includes(listV[idNoVinc].id)) {
          if (value.length > 1) {
            return this.createError({
              message: `Debe marcar alguna opcion o indicar que no tiene vinculacion.`,
            });
          } else {
            return true;
          }
        }
        setVinculation(true);
        return true;
      }),
  });

  let startValues = {
    relations: [],
    hasVinculation: 'false',
    otherSafiRelation: '',
    institutionalInvestor: 'false',
    pep: 'false',
    spousePep: 'false',
    relativePep: 'false',
    dataProcessingAuthorization: 'false',
  };

  const { client } = clientData;
  const spouse = client ? client.spouse : null;
  if (!spouse) {
    return <h1>No existe conyuge</h1>;
  }
  if (spouse) {
    const { relations, otherRelation, user, ...currentData } = spouse;
    currentData.relations = relations.edges.map(val => val.node.id);

    currentData.hasVinculation = (
      currentData.relations.length > 0 || !!otherRelation
    ).toString();

    if (otherRelation) {
      currentData.relations.push('other');
    }
    currentData.otherSafiRelation = otherRelation;
    currentData.institutionalInvestor = (
      currentData.institutionalInvestor || false
    ).toString();
    currentData.spousePep = (currentData.spousePep || false).toString();
    currentData.relativePep = (currentData.relativePep || false).toString();
    currentData.dataProcessingAuthorization = (
      currentData.dataProcessingAuthorization || false
    ).toString();
    currentData.pep = (currentData.pep || false).toString();
    startValues = { ...currentData };
  }

  const data = [
    {
      isField: false,
      section:
        'Vinculación con Core Capital SAF o empresas de su Grupo Económico',
      fields: [
        {
          name: 'hasVinculation',
          text:
            '¿Tiene usted alguno de los siguientes tipos de vinculación con Core Capital SAF o empresas de su Grupo Económico?',
          gridMD: 11,
          inputVariant: INPUT_VARIANT.paragraph,
          type: 'relation',
        },
        {
          inputVariant: INPUT_VARIANT.node,
          node: (
            <>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div align="right">
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openHelp}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top"
                    title={<ClientTexts type="relation" />}
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      aria-label="close"
                      onClick={handleTooltipOpen}
                    >
                      <HelpIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </>
          ),
          alignItems: 'center',
          gridMD: 1,
          name: 'helpVinculation',
        },
        {
          label: '',
          name: 'relations',
          data: edgeToList(safiRelationData, 'listSafiRelations'),
          mapData: { value: 'id', label: 'name' },
          inputVariant: INPUT_VARIANT.checkbox,
          other: true,
          gridMD: 12,
        },
        {
          name: 'fundRelationText',
          text:
            'Base Legal: Artículo 7 de la Resolución SMV N° 019-2015-SMV-01.',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.paragraph,
          type: 'relation',
        },
      ],
    },
    {
      isField: false,
      section: 'Calidad de Inversionista Institucional',
      fields: [
        {
          name: 'institutionalInvestor',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
        },
        {
          name: 'institutionalInvestorText',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.paragraph,
          type: 'institutionalInvestor',
        },
      ],
    },
    {
      isField: false,
      section:
        '¿Autoriza el tratamiento de sus datos personales para finalidades adicionales?',
      fields: [
        {
          name: 'dataProcessingAuthorization',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
        },
        {
          name: 'authorizationText',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.paragraph,
          type: 'dataProcessingAuthorization',
        },
      ],
    },
  ];

  function formatBeforeSubmit(values) {
    const { otherSafiRelation, ...newValues } = values;
    const find = newValues.relations.findIndex(i => i === 'other');
    if (find !== -1) {
      newValues.relations.splice(find, 1);
    }
    if (!vinculation) {
      newValues.relations = [];
    }
    delete newValues.hasVinculation;
    newValues.pep = newValues.pep === 'true';
    newValues.institutionalInvestor =
      newValues.institutionalInvestor === 'true';
    newValues.spousePep = newValues.spousePep === 'true';
    newValues.relativePep = newValues.relativePep === 'true';
    newValues.dataProcessingAuthorization =
      newValues.dataProcessingAuthorization === 'true';
    newValues.otherRelation = otherSafiRelation;

    return newValues;
  }

  return (
    <>
      <StepsForm
        initialValues={startValues}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        id={spouse.id}
        mainClientID={clientID}
        data={data}
        title="Datos del cónyuge o apoderado / Relación con la SAF"
        nextForm={MENU_ITEMS_ID.ClientLabourData}
        updateMutation={updateClient}
        partialUpdateMutation={partialUpdateClient}
        formatBeforeSubmit={formatBeforeSubmit}
      />
    </>
  );
}

SafiRelationSpouse.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default SafiRelationSpouse;
